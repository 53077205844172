/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSameDay, parseISO } from 'date-fns';
import { isEmpty, first, debounce, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';

import { getCropBlockVintageEntityData } from '../../../redux/selectors';
import useUserResponsiveLayout from '../../../hooks/useUserResponsiveLayout';

export const useNoteImageUpdateHook = () => {
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { userFullName } = useUserResponsiveLayout();

  const [noteImage, setNoteImage] = React.useState(null);
  const [isReadyVintage, setIsReadyVintage] = React.useState(0);
  const [isReadyPhenology, setIsReadyPhenology] = React.useState(0);
  const [formData, setFormData] = React.useState(null);
  const [isInProgressNotesImage, setIsInProgressNotesImage] =
    React.useState(false);

  const cropBlockVintageEntityData = useSelector(getCropBlockVintageEntityData);
  const cropBlockPhenologyEntityData = useSelector(
    VineaNovaSelectors.getCropBlockPhenologyEntityData,
  );

  const {
    isLoading: isNotesLoading,
    hasError: hasNotesError,
    isLoaded: isNotesLoaded,
    error: notesError,
  } = useSelector(VineaNovaSelectors.getIdentityNoteImageEntityMeta);

  React.useEffect(() => {
    if (isInProgressNotesImage) {
      if (!isNotesLoading && isNotesLoaded) {
        if (hasNotesError) {
          enqueueSnackbar(t('Image Upload Error'), { variant: 'Error' });
          // setErrorInSubmitVintage(vintageError);
        }
        setIsInProgressNotesImage(false);
      }
    }
  }, [
    isNotesLoading,
    hasNotesError,
    isNotesLoaded,
    isInProgressNotesImage,
    enqueueSnackbar,
    t,
  ]);

  const fetchCropBlockVintage = React.useCallback(cbvData => {
    dispatchAPI(
      VineaNovaActions.api.v1.cropBlockVintage.get.request({
        queryParams: {
          // TODO: replace block id
          VineyardBlockID: cbvData.vineyardBlockId,
          VintageID: cbvData.vintageId,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCropPhenology = React.useCallback(cbvData => {
    dispatchAPI(
      VineaNovaActions.api.v1.cropBlockPhenology.get.request({
        queryParams: {
          // TODO: replace block id
          VineyardBlockID: cbvData.vineyardBlockId,
          VintageID: cbvData.vintageId,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCropBlockNotes = React.useCallback(cbvData => {
    dispatchAPI(
      VineaNovaActions.api.v1.cropBlockNotes.get.request({
        queryParams: {
          // TODO: replace block id
          VineyardBlockID: cbvData.vineyardBlockId,
          VintageID: cbvData.vintageId,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounceFetchCropBlockVintage = debounce(fetchCropBlockVintage, 500);

  const debouncefetchCropPhenology = debounce(fetchCropPhenology, 500);

  const debouncefetchCropBlockNotes = debounce(fetchCropBlockNotes, 500);

  const uploadNotesImage = React.useCallback((noteId, image, cbvData) => {
    if (!isEmpty(image)) {
      // eslint-disable-next-line no-undef
      const formUploadData = new FormData();
      formUploadData.append('image', image.raw);
      dispatchAPI(
        VineaNovaActions.api.v1.identityNoteImage.put.request({
          queryParams: { cropNoteId: noteId },
          postBody: formUploadData,
          options: {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: formUploadData,
          },
        }),
      );
      setIsInProgressNotesImage(true);
      debounceFetchCropBlockVintage(cbvData);
      debouncefetchCropPhenology(cbvData);
      debouncefetchCropBlockNotes(cbvData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const insertNotesImage = React.useCallback(
    (measurementCategory, image, cbvData) => {
      if (!isEmpty(image)) {
        // eslint-disable-next-line no-undef
        const formUploadData = new FormData();
        formUploadData.append('image', image.raw);
        dispatchAPI(
          VineaNovaActions.api.v1.measurementNoteImage.post.request({
            queryParams: {
              MeasurementID: cbvData?.id,
              VineyardBlockID: cbvData?.vineyardBlockId,
              VintageID: cbvData?.vintageId,
              ModifiedBy: userFullName,
              MeasurementCategory: measurementCategory,
            },
            postBody: formUploadData,
            options: {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formUploadData,
            },
          }),
        );
        setIsInProgressNotesImage(true);
        debounceFetchCropBlockVintage(cbvData);
        debouncefetchCropPhenology(cbvData);
        debouncefetchCropBlockNotes(cbvData);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  React.useEffect(() => {
    if (
      !isEmpty(cropBlockVintageEntityData) &&
      !isEmpty(formData) &&
      !isNil(cropBlockVintageEntityData) &&
      Array.isArray(cropBlockVintageEntityData) &&
      cropBlockVintageEntityData.length > 0 &&
      isReadyVintage
    ) {
      const foundVintageRow = first(
        cropBlockVintageEntityData.filter(
          f =>
            f.note === formData.note &&
            f.vineyardBlockId === formData.vineyardBlockId &&
            f.vintageId === formData.vintageId &&
            f.measureValue1 === Number(formData.measureValue1) &&
            isSameDay(
              parseISO(f.measureDate),
              parseISO(formData.measureDate),
            ) &&
            !f.noteImage,
        ),
      );
      if (foundVintageRow.cropNoteID) {
        uploadNotesImage(foundVintageRow.cropNoteID, noteImage, formData);
      } else {
        insertNotesImage('Vintage', noteImage, formData);
      }
      setIsReadyVintage(false);
      setFormData(null);
      setNoteImage(null);
    }
  }, [
    cropBlockVintageEntityData,
    isReadyVintage,
    uploadNotesImage,
    formData,
    noteImage,
  ]);

  React.useEffect(() => {
    if (
      !isEmpty(cropBlockPhenologyEntityData) &&
      !isNil(cropBlockPhenologyEntityData) &&
      !isEmpty(formData) &&
      Array.isArray(cropBlockPhenologyEntityData) &&
      cropBlockPhenologyEntityData.length > 0 &&
      isReadyPhenology
    ) {
      const foundVintageRow = first(
        cropBlockPhenologyEntityData.filter(
          f =>
            (f.note === formData.note &&
              f.vineyardBlockId === formData.vineyardBlockId &&
              f.vintageId === formData.vintageId &&
              formData.phenologyMeasureTypeID === 1 &&
              f.phenologyMeasureOptionID ===
                formData.phenologyMeasureOptionID) ||
            (formData.phenologyMeasureTypeID > 1 &&
              f.phenologyMeasureValue1 ===
                Number(formData.phenologyMeasureValue1) &&
              isSameDay(
                parseISO(f.phenologyMeasureDate),
                parseISO(formData.phenologyMeasureDate),
              ) &&
              !f.noteImage),
        ),
      );

      if (foundVintageRow.cropNoteID) {
        uploadNotesImage(foundVintageRow.cropNoteID, noteImage, formData);
      } else {
        insertNotesImage('Phenology', noteImage, formData);
      }
      setIsReadyPhenology(false);
      setFormData(null);
      setNoteImage(null);
    }
  }, [
    cropBlockPhenologyEntityData,
    isReadyPhenology,
    uploadNotesImage,
    formData,
    noteImage,
  ]);

  return {
    setNoteImage,
    setFormData,
    setIsReadyVintage,
    setIsReadyPhenology,
  };
};
