/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import {
  countBy,
  entries,
  flow,
  head,
  last,
  maxBy,
  minBy,
  partialRight,
  get,
  isEmpty,
  isEqual,
  filter,
  isNull,
  some,
  isNil,
} from 'lodash';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Alert from '@mui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { ErrorBoundary } from 'react-error-boundary';

import {
  getLkpSampleReqStatus,
  areLookupsLoading,
  getSampleHeadersData,
  getSampleSetById,
  getSamplingUsersList,
  getUserPreferences,
} from '../../redux/selectors';
import {
  actionTypes,
  reducers,
  commonFieldSelectOption,
  dateFormat,
  viewDateFormat,
} from '../../constants';
import { SampleHeadersGridResults } from './GridResults/SampleHeadersGridResults';
import { IdentitySamplingHeader } from '../../components/SamplingComponents';
import { VineaButton } from '../../components/VineaButton';
import { StatusDrawer } from './StatusDrawer';
import useLocationListener from '../../hooks/useLocationListener';
import { AssignmentDrawer } from './AssignmentDrawer';
import logger from '../../utils/winstonLogger';
import { SampleSizeDrawer } from './SampleSizeDrawer';
import { syncValidator } from '../../utils/validator';
import { UpdateSampleSizeSchema } from './validations';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';

export const VineyardSampleDetailsContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { id: pathParam } = useParams();
  const { state: locationState = {} } = useLocation();
  const locationObj = useLocationListener();

  const dataGridColumnsPreferences = useSelector(state =>
    getUserPreferences(state),
  );
  const [deleteActionTriggered, setDeleteActionTriggered] =
    React.useState(false);
  const [updateActionTriggered, setUpdateActionTriggered] =
    React.useState(false);
  const [assignActionTriggered, setAssignActionTriggered] =
    React.useState(false);
  const [deassignActionTriggered, setDeassignActionTriggered] =
    React.useState(false);
  const [updateSampleSizeActionTriggered, setUpdateSampleSizeActionTriggered] =
    React.useState(false);

  const [triggerHideAllWarnings, setTriggerHideAllWarnings] =
    React.useState(false);
  const [keepWarnings, setKeepWarnings] = React.useState(false);

  const [statusHasChanges, setStatusHasChanges] = React.useState(false);

  const [selectedRow, setSelectedRow] = React.useState({});
  const [sampleStatusID, setSampleStatusID] = React.useState(0);
  const [assignmentData, setAssignmentData] = React.useState({
    assignedToPersonIDs: [],
    dueDate: null,
    sampleInstructions: null,
  });
  const [sampleSizeData, setSampleSizeData] = React.useState({
    recommendedSampleSize: null,
  });
  const [assignmentOperation, setAssignmentOperation] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [assignmentDrawerOpen, setAssignmentDrawerOpen] = React.useState(false);
  const [sampleSizeDrawerOpen, setSampleSizeDrawerOpen] = React.useState(false);
  const [sampleHeaderBlocksData, setSampleHeaderBlocksData] = React.useState(
    [],
  );
  const [samplingUsersIsLoadingState, setSamplingUsersIsLoadingState] =
    React.useState(false);
  const [assignmentTrigger, setAssignmentTrigger] = React.useState(false);
  const [sampleSelectionWarning, setSampleSelectionWarning] =
    React.useState(null);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [locationKeys, setLocationKeys] = React.useState([]);
  const [closedWarningStatus, setClosedWarningStatus] = React.useState(null);
  const [assignmentSelectionWarning, setAssignmentSelectionWarning] =
    React.useState(false);
  const [sampleSizeUneditableWarning, setSampleSizeUneditableWarning] =
    React.useState(false);
  const [sampleSizeStatusWarning, setSampleSizeStatusWarning] =
    React.useState(false);

  const [sampleStatusOptions, setSampleStatusOptions] = React.useState(
    commonFieldSelectOption,
  );
  const isLoadingLookups = useSelector(areLookupsLoading);
  const sampleHeadersData = useSelector(getSampleHeadersData);
  const lkpSampleHeaderStatus = useSelector(getLkpSampleReqStatus);
  const samplingUsers = useSelector(getSamplingUsersList);
  const sampleInformation = useSelector(state =>
    getSampleSetById(state, pathParam),
  );
  const {
    isLoading: samplingUsersIsLoading,
    isLoaded: samplingUsersIsLoaded,
    hasError: samplingUsersHasError,
  } = useSelector(state => state.entities.samplingUsers);

  const {
    isLoading: assignIsLoading,
    isLoaded: assignIsLoaded,
    hasError: assignHasError,
  } = useSelector(state => state.entities.assignSampleHeaders);

  const {
    isLoading: deAssignIsLoading,
    isLoaded: deAssignIsLoaded,
    hasError: deAssignHasError,
    error: deAssignError,
  } = useSelector(state => state.entities.deAssignSampleHeaders);

  const {
    isLoading: sampleHeaderStatusIsLoading,
    isLoaded: sampleHeaderStatusIsLoaded,
    hasError: sampleHeaderStatusHasError,
  } = useSelector(state => state.entities.sampleHeaderStatus);

  const {
    isLoading: sampleSizeIsLoading,
    isLoaded: sampleSizeIsLoaded,
    hasError: sampleSizeHasError,
  } = useSelector(state => state.entities.recommendedSampleSize);

  const {
    isLoading: sampleHeaderIsLoading,
    isLoaded: sampleHeaderIsLoaded,
    hasError: sampleHeaderHasError,
    data: sampleHeaderData,
    error: sampleHeaderError,
  } = useSelector(state => state.entities.sampleHeader);

  const {
    isLoading: sampleSetIsLoading,
    isLoaded: sampleSetIsLoaded,
    hasError: sampleSetHasError,
  } = useSelector(VineaNovaSelectors.getSampleSetEntityMeta);
  const [updateApiStatus, setUpdateApiStatus] = React.useState(false);

  const { basicSettings } = useSelector(state => getUserPreferences(state));

  const getMostCommon = array =>
    flow(countBy, entries, partialRight(maxBy, last), head)(array);

  React.useEffect(() => {
    if (isEmpty(locationState)) {
      navigate(`/samples/sampling`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [maxCompletionDate, setMaxCompletionDate] = React.useState(null);
  const [minSentDate, setMinSentDate] = React.useState(null);

  React.useEffect(() => {
    const completionDates = filter(
      sampleHeadersData,
      f => f.requestCompletionDate !== null,
    ).map(header => new Date(header.requestCompletionDate));
    const sentDates = filter(
      sampleHeadersData,
      f => f.requestSentDate !== null,
    ).map(header => new Date(header.requestSentDate));
    setSampleHeaderBlocksData(sampleHeadersData);
    setMaxCompletionDate(maxBy(completionDates));
    setMinSentDate(minBy(sentDates));
  }, [sampleHeadersData, sampleHeaderBlocksData]);

  React.useEffect(() => {
    if (deleteActionTriggered) {
      if (!sampleHeaderIsLoading && sampleHeaderIsLoaded) {
        if (sampleHeaderHasError && !isEmpty(sampleHeaderError)) {
          const { data: { error } = [] } = sampleHeaderError;
          const { summary = '' } = error[0];
          setClosedWarningStatus(summary);
        } else {
          // refetch the data again
          enqueueSnackbar(t('Success'), { variant: 'Success' });
        }
        dispatchAPI(
          VineaNovaActions.api.v1.sampleHeaders.get.request({
            queryParams: {
              SampleSetID: pathParam,
            },
          }),
        );

        setSelectionModel([]);
        setDeleteActionTriggered(false);
        setKeepWarnings(true);
      }
    }
  }, [
    sampleHeaderIsLoading,
    sampleHeaderIsLoaded,
    sampleHeaderHasError,
    sampleHeaderData,
    sampleHeaderError,
    deleteActionTriggered,
    dispatchAPI,
    pathParam,
    enqueueSnackbar,
    t,
  ]);

  React.useEffect(() => {
    if (updateActionTriggered) {
      if (!sampleHeaderStatusIsLoading && sampleHeaderStatusIsLoaded) {
        if (sampleHeaderStatusHasError) {
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else {
          const { sampleSetData = [] } = locationState;
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          dispatchAPI(
            VineaNovaActions.api.v1.sampleHeaders.get.request({
              queryParams: {
                SampleSetID: pathParam,
              },
            }),
          );
          dispatchAPI(
            VineaNovaActions.api.v1.sampleSets.post.request({
              postBody: {
                ...sampleSetData,
              },
            }),
          );
        }
        setSelectionModel([]);
        setUpdateActionTriggered(false);
        setKeepWarnings(true);
      }
    }
  }, [
    sampleHeaderStatusIsLoading,
    sampleHeaderStatusIsLoaded,
    sampleHeaderStatusHasError,
    updateActionTriggered,
    enqueueSnackbar,
    t,
    locationState,
    dispatchAPI,
    pathParam,
  ]);

  React.useEffect(() => {
    if (assignActionTriggered) {
      if (!assignIsLoading && assignIsLoaded) {
        if (assignHasError) {
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          dispatchAPI(
            VineaNovaActions.api.v1.sampleHeaders.get.request({
              queryParams: {
                SampleSetID: pathParam,
              },
            }),
          );
        }
        setSelectionModel([]);
        setAssignActionTriggered(false);
        setAssignmentOperation(null);
        setKeepWarnings(true);
      }
    }
  }, [
    assignIsLoading,
    assignIsLoaded,
    assignHasError,
    assignActionTriggered,
    enqueueSnackbar,
    t,
    dispatchAPI,
    pathParam,
  ]);

  React.useEffect(() => {
    if (deassignActionTriggered) {
      if (!deAssignIsLoading && deAssignIsLoaded) {
        if (deAssignHasError && !isEmpty(deAssignError)) {
          const { data: { error } = [] } = deAssignError;
          const { summary = '' } = error[0];
          setClosedWarningStatus(summary);
        } else {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          dispatchAPI(
            VineaNovaActions.api.v1.sampleHeaders.get.request({
              queryParams: {
                SampleSetID: pathParam,
              },
            }),
          );
        }
        setSelectionModel([]);
        setDeassignActionTriggered(false);
        setAssignmentOperation(null);
        setKeepWarnings(true);
      }
    }
  }, [
    deAssignIsLoading,
    deAssignIsLoaded,
    deAssignHasError,
    deAssignError,
    deassignActionTriggered,
    enqueueSnackbar,
    t,
    dispatchAPI,
    pathParam,
  ]);

  React.useEffect(() => {
    if (updateSampleSizeActionTriggered) {
      if (!sampleSizeIsLoading && sampleSizeIsLoaded) {
        if (sampleSizeHasError) {
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          dispatchAPI(
            VineaNovaActions.api.v1.sampleHeaders.get.request({
              queryParams: {
                SampleSetID: pathParam,
              },
            }),
          );
        }
        setSelectionModel([]);
        setUpdateSampleSizeActionTriggered(false);
        setKeepWarnings(true);
        setSampleSizeDrawerOpen(false);
      }
    }
  }, [
    updateSampleSizeActionTriggered,
    sampleSizeIsLoading,
    sampleSizeIsLoaded,
    sampleSizeHasError,
    enqueueSnackbar,
    t,
    dispatchAPI,
    pathParam,
  ]);

  React.useEffect(() => {
    if (!isEqual(locationKeys, locationObj)) {
      setLocationKeys(locationObj);
      dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
      dispatchAPI(VineaNovaActions.api.v1.sampleBlockVintages.get.cleardata());
      dispatchAPI(
        VineaNovaActions.api.v1.sampleHeaders.get.request({
          queryParams: {
            SampleSetID: pathParam,
          },
        }),
      );
    }
  }, [pathParam, dispatchAPI, locationObj, locationKeys]);

  React.useEffect(() => {
    let inputSampleHeaderIDs;
    if (assignmentOperation === 'Assign') inputSampleHeaderIDs = [];
    else if (assignmentOperation === 'DeAssign')
      inputSampleHeaderIDs = selectionModel;
    if (!isNull(assignmentOperation)) {
      dispatchAPI(
        VineaNovaActions.api.v1.samplingUsers.post.request({
          postBody: {
            BusinessUnitID: basicSettings.businessUnitID,
            sampleHeaderIDs: inputSampleHeaderIDs,
          },
        }),
      );
    }
  }, [assignmentOperation, dispatchAPI, selectionModel]);

  const hideAllWarnings = React.useCallback(() => {
    setClosedWarningStatus(null);
    setAssignmentSelectionWarning(false);
    setSampleSelectionWarning(null);
    setSampleSizeUneditableWarning(false);
    setSampleSizeStatusWarning(false);
  }, []);

  React.useEffect(() => {
    if (triggerHideAllWarnings) {
      if (!keepWarnings) {
        hideAllWarnings();
      } else {
        setKeepWarnings(false);
      }
      setTriggerHideAllWarnings(false);
    }
  }, [
    triggerHideAllWarnings,
    setTriggerHideAllWarnings,
    keepWarnings,
    setKeepWarnings,
    setClosedWarningStatus,
    setAssignmentSelectionWarning,
    setSampleSelectionWarning,
    hideAllWarnings,
  ]);

  React.useEffect(() => {
    if (assignmentTrigger && samplingUsersIsLoading) {
      setSamplingUsersIsLoadingState(true);
    }
    if (
      samplingUsersIsLoadingState &&
      !samplingUsersIsLoading &&
      !samplingUsersHasError &&
      samplingUsersIsLoaded
    ) {
      setSamplingUsersIsLoadingState(false);
      setAssignmentTrigger(false);
      setAssignmentDrawerOpen(true);
    }
  }, [
    assignmentTrigger,
    samplingUsersHasError,
    samplingUsersIsLoaded,
    samplingUsersIsLoading,
    samplingUsersIsLoadingState,
  ]);

  const handleOnStatusChange = event => {
    const {
      target: { value: statusId },
    } = event;
    const { id } = selectedRow;
    setSampleStatusID(statusId);
    const newStatus = get(
      lkpSampleHeaderStatus.find(st => st.id === statusId),
      'value',
      '',
    );
    const newSampleInfo = sampleHeaderBlocksData.map(f => {
      return {
        ...f,
        sampleRequestStatusID: f.id === id ? statusId : f.sampleRequestStatusID,
        status: f.id === id ? newStatus : f.status,
        hasUpdated: f.id === id ? true : f.hasUpdated,
      };
    });
    setSampleHeaderBlocksData(newSampleInfo);
    setSelectedRow({});
    setStatusHasChanges(true);
  };

  const handleOnRowClick = evt => {
    navigate(`/samples/sampling/${pathParam}/sampledetails/${evt.id}`);
  };

  const handleOnCellClick = cellProps => {
    const { row, field } = cellProps;
    if (field === '__check__') {
      setSelectedRow(row);
    }
  };

  const handleOnCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleOnAssignmentDrawerClose = () => {
    setAssignmentData({
      assignedToPersonIDs: [],
      dueDate: null,
      sampleInstructions: null,
    });
    setAssignmentOperation(null);
    setAssignmentDrawerOpen(false);
  };

  const handleOnSampleSizeDrawerClose = () => {
    setSampleSizeDrawerOpen(false);
  };

  const handleOnAssignSamplers = name => {
    hideAllWarnings();

    const requestStatus = selectionModel.map(id => {
      const currentStatus = get(
        sampleHeadersData.find(st => st.id === id),
        'sampleRequestStatusID',
        '',
      );
      return currentStatus;
    });

    const assignedInfo = head(
      selectionModel.map(selectedId => {
        const selectedSample = sampleHeadersData.find(
          sample => sample.id === selectedId,
        );
        return selectedSample;
      }),
    );
    setAssignmentData({
      assignedToPersonIDs: assignedInfo?.assignedToIds
        ?.split(',')
        .filter(a => !!a),
      dueDate: assignedInfo.requestDueDate,
      sampleInstructions: assignedInfo.sampleInstructions,
    });
    logger.info(assignedInfo);
    logger.debug('DATA');
    logger.debug(assignedInfo);
    setAssignmentOperation(name);
    if (requestStatus.includes(3) || requestStatus.includes(4)) {
      setAssignmentSelectionWarning(true);
      setAssignmentOperation(null);
    } else setAssignmentTrigger(true);
  };

  const handleOnSetSampleSize = () => {
    hideAllWarnings();

    const selectedRows = selectionModel.map(id =>
      sampleHeadersData.find(s => s.id === id),
    );

    const statuses = selectedRows.map(s => s.sampleRequestStatusID);
    if (statuses.includes(3) || statuses.includes(4)) {
      setSampleSizeStatusWarning(true);
    } else if (selectedRows.some(s => s.defaultSampleSize === 1)) {
      setSampleSizeUneditableWarning(true);
    } else {
      const mostCommonSampleSize = getMostCommon(
        selectedRows
          .map(row => row?.recommendedSampleSize)
          .filter(s => s !== null && s !== undefined),
      );
      setSampleSizeData({
        recommendedSampleSize: mostCommonSampleSize,
      });
      setSampleSizeDrawerOpen(true);
    }
  };

  // const populateAssignmentData = () => {
  //   const selectedRows = selectionModel.map(id => {
  //     return sampleHeadersData.find(st => st.id === id);
  //   });

  //   if (!selectedRows.isEmpty) {
  //     const firstDueDate = selectedRows[0].requestDueDate;
  //     const selectedDueDate = selectedRows.every(
  //       v => v.requestDueDate === firstDueDate,
  //     )
  //       ? firstDueDate
  //       : null;

  //     const firstSampleInstructions = selectedRows[0].sampleInstructions;
  //     const selectedSampleInstructions = selectedRows.every(
  //       v => v.sampleInstructions === firstSampleInstructions,
  //     )
  //       ? firstSampleInstructions
  //       : null;

  //     setAssignmentData({
  //       ...assignmentData,
  //       dueDate: selectedDueDate,
  //       sampleInstructions: selectedSampleInstructions,
  //     });
  //   }
  // };

  const handleOnAddBlocks = () => {
    navigate(`/samples/sampling/${pathParam}/addblocks`);
  };

  const generateSampleOptions = sampleArray => {
    return sampleArray.map(sampleID => {
      return {
        id: sampleID,
        key: sampleID,
        value: get(
          lkpSampleHeaderStatus.find(st => st.id === sampleID),
          'value',
          '',
        ),
      };
    });
  };

  const generateSampleStatusOptions = requestStatus => {
    if (requestStatus === 3) return generateSampleOptions([2, 4]);
    if (requestStatus === 4) return generateSampleOptions([2, 3]);
    if (requestStatus === 2) return generateSampleOptions([3, 4]);
    return generateSampleOptions([2, 3, 4]);
  };

  const handleOnUpdateBulkStatus = () => {
    hideAllWarnings();

    const requestStatus = selectionModel.map(id => {
      const currentStatus = get(
        sampleHeadersData.find(st => st.id === id),
        'sampleRequestStatusID',
        '',
      );
      const newStatus = get(
        lkpSampleHeaderStatus.find(st => st.id === currentStatus),
        'id',
        '',
      );
      return newStatus;
    });

    const actualCounts = filter(sampleHeadersData, sample => selectionModel.includes(get(sample, 'id')));

    const selectionStatus = requestStatus.every(v => v === requestStatus[0]);
    if (!selectionStatus) {
      setSampleSelectionWarning(`You cannot update the status for selected sample
        requests as their statuses are not the same`);
} else if(some(actualCounts, sample => isNil(sample.actualSampleSize) || sample.actualSampleSize === 0)) {
      setSampleSelectionWarning(`You cannot update the status for selected Sample
      requests as one or more of the selected samples has no sample data`);
    } else{
      const statusOptions = generateSampleStatusOptions(requestStatus[0]);
      setSampleStatusOptions(statusOptions);
      setSampleStatusID(0);
      setDrawerOpen(true);
      setStatusHasChanges(false);
    }
  };

  const handleOnUpdateStatusSave = () => {
    let sampleSizeStatus = true;
    if (sampleStatusID === 3 || sampleStatusID === 4) {
      const actualSampleData = filter(selectionModel, id =>
        isNull(
          get(
            sampleHeadersData.find(st => st.id === id),
            'actualSampleSize',
            '',
          ),
        ),
      );
      sampleSizeStatus = isEmpty(actualSampleData);
    }
    if (sampleSizeStatus) {
      dispatchAPI(
        VineaNovaActions.api.v1.sampleHeaderStatus.put.request({
          postBody: {
            SampleHeaderIDs: selectionModel,
            SampleRequestStatusID: sampleStatusID,
          },
        }),
      );
    } else {
      setSampleSelectionWarning(
        'Cannot Update Sample Header Status to Closed or Complete as one or all of the selected sample headers do not contain any data',
      );
    }
    setDrawerOpen(false);
    setUpdateActionTriggered(true);
  };

  const handleOnAssignmentStatusSave = () => {


    if (assignmentOperation === 'Assign') {
      dispatchAPI(
        VineaNovaActions.api.v1.assignSampleHeaders.post.request({
          postBody: {
            SampleHeaderIDs: selectionModel,
            assignedToPersonIDs: assignmentData.assignedToPersonIDs,
            DueDate: assignmentData.dueDate || format(new Date(), dateFormat),
            // sampleInstructions: assignmentData.sampleInstructions,
          },
        }),
      );
      setAssignActionTriggered(true);
    } else if (assignmentOperation === 'DeAssign') {
      dispatchAPI(
        VineaNovaActions.api.v1.deAssignSampleHeaders.post.request({
          postBody: {
            SampleHeaderIDs: selectionModel,
            assignedToPersonIDs: assignmentData.assignedToPersonIDs,
          },
        }),
      );
      setDeassignActionTriggered(true);
    }
    setAssignmentDrawerOpen(false);
    setAssignmentData({
      assignedToPersonIDs: [],
      dueDate: null,
      sampleInstructions: null,
    });
  };

  const handleOnSampleSizeSave = () => {
    const validationErrors = syncValidator(UpdateSampleSizeSchema)(
      sampleSizeData,
    );
    setSampleSizeData({
      ...sampleSizeData,
      errors: validationErrors,
    });

    if (isEmpty(validationErrors)) {
      dispatchAPI(
        VineaNovaActions.api.v1.recommendedSampleSize.put.request({
          postBody: {
            SampleHeaderIDs: selectionModel,
            Size: sampleSizeData.recommendedSampleSize,
          },
        }),
      );
      setUpdateSampleSizeActionTriggered(true);
    }
  };

  const handleOnDeleteBulk = () => {
    hideAllWarnings();

    if (!isEmpty(lkpSampleHeaderStatus)) {
      dispatchAPI(
        VineaNovaActions.api.v1.sampleHeader.delete.request({
          postBody: {
            SampleHeaderIDs: selectionModel,
          },
        }),
      );
      setDeleteActionTriggered(true);
    }
  };

  const handleOnAssignmentChange = event => {
    let assignToData;
    const {
      target: { name, checked, value },
    } = event;
    if (name === 'effectiveFromDate') {
      assignToData = {
        ...assignmentData,
        dueDate: value,
      };
    } else if (name === 'sampleInstructions') {
      assignToData = {
        ...assignmentData,
        sampleInstructions: value,
      };
    } else {
      let personID;
      if (checked) {
        personID = [...assignmentData.assignedToPersonIDs, name];
      } else {
        personID = filter(assignmentData.assignedToPersonIDs, f => f !== name);
      }
      assignToData = {
        ...assignmentData,
        assignedToPersonIDs: personID,
      };
    }
    setAssignmentData({ ...assignToData, hasChanges: true });
  };

  const handleOnSampleSizeDataChange = event => {
    const {
      target: { name, value },
    } = event;

    setSampleSizeData({ ...sampleSizeData, [name]: value, hasChanges: true });
  };

  const handleOnDeleteSampleSet = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.sampleSet.delete.request({
        postBody: {
          id: pathParam,
        },
      }),
    );
    setUpdateApiStatus(true);
  };
  useEffect(() => {
    if (updateApiStatus && sampleSetIsLoaded && !sampleSetHasError) {
      enqueueSnackbar('Sample set deleted successfully.', {
        variant: 'success',
      });
      setUpdateApiStatus(false);
      navigate(-1);
    } else if (updateApiStatus && sampleSetIsLoaded && sampleSetHasError) {
      enqueueSnackbar('Sample set deletion failed.', { variant: 'error' });
      setUpdateApiStatus(false);
    }
  }, [
    updateApiStatus,
    sampleSetIsLoading,
    sampleSetHasError,
    sampleSetIsLoaded,
  ]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      data-testid="sampling-details-container"
    >
      <ErrorBoundary
        FallbackComponent={({ ...props }) => (
          <Box sx={{ height: '160px' }}>
            <ErrorBoundaryFallback {...props} />
          </Box>
        )}
      >
        <IdentitySamplingHeader
          displayName="2020/21 Sample buds per vine"
          sampleInfo={{
            ...sampleInformation,
            actualStartDate: minSentDate
              ? format(minSentDate, viewDateFormat)
              : null,
            lastSampleDate: maxCompletionDate
              ? format(maxCompletionDate, viewDateFormat)
              : null,
          }}
        />
      </ErrorBoundary>

      <Box
        mx={1}
        my={2}
        display="flex"
        justifyContent="flex-end"
        data-testid="action-buttons"
      >
        <VineaButton
          color="secondary"
          variant="text"
          onClick={() => handleOnAssignSamplers('Assign')}
          sx={{
            marginRight: theme.spacing(3),
          }}
          minWidth={100}
          disabled={isEmpty(selectionModel)}
        >
          {t('Assign')}
        </VineaButton>
        <VineaButton
          color="secondary"
          variant="text"
          onClick={() => handleOnAssignSamplers('DeAssign')}
          sx={{
            marginRight: theme.spacing(3),
          }}
          minWidth={100}
          disabled={isEmpty(selectionModel)}
        >
          {t('De-Assign')}
        </VineaButton>
        <VineaButton
          color="secondary"
          variant="text"
          onClick={handleOnSetSampleSize}
          sx={{
            marginRight: theme.spacing(3),
          }}
          minWidth={100}
          disabled={isEmpty(selectionModel)}
        >
          {t('Set Sample Size')}
        </VineaButton>
        <VineaButton
          color="secondary"
          variant="text"
          sx={{
            marginRight: theme.spacing(3),
          }}
          minWidth={100}
          onClick={handleOnUpdateBulkStatus}
          disabled={isEmpty(selectionModel)}
        >
          {t('Update Status')}
        </VineaButton>
        <VineaButton
          color="secondary"
          variant="text"
          sx={{
            marginRight: theme.spacing(3),
          }}
          minWidth={100}
          onClick={handleOnDeleteBulk}
          disabled={isEmpty(selectionModel)}
        >
          {t('Delete')}
        </VineaButton>
        {sampleHeadersData.length === 0 && (
          <VineaButton
            color="secondary"
            variant="contained"
            sx={{
              marginRight: theme.spacing(3),
            }}
            minWidth={100}
            onClick={handleOnDeleteSampleSet}
          >
            {t('Delete Sample Set')}
          </VineaButton>
        )}
        <VineaButton
          color="secondary"
          variant="contained"
          sx={{
            marginRight: theme.spacing(3),
          }}
          minWidth={100}
          onClick={handleOnAddBlocks}
          disabled={!isEmpty(selectionModel)}
        >
          {t('Add sample request')}
        </VineaButton>
      </Box>
      <Box display="flex" flex={1} sx={{ height: '100%', pb: 3, px: 3 }}>
        <Grid
          container
          spacing={0}
          data-testid="organisation-results"
          direction="column"
        >
          <Grid
            item
            xs={12}
            sx={{
              flex: 1,
            }}
          >
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                height: '100%',
              }}
            >
              <Grid container xs={12} direction="column">
                <Box px={2}>
                  {!isNull(closedWarningStatus) && (
                    <Collapse in={!!closedWarningStatus}>
                      <Alert
                        variant="outlined"
                        severity="warning"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setClosedWarningStatus(null);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {closedWarningStatus}
                      </Alert>
                    </Collapse>
                  )}
                  {!isNull(sampleSelectionWarning) && (
                    <Collapse in={!isNull(sampleSelectionWarning)}>
                      <Alert
                        variant="outlined"
                        severity="warning"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setSampleSelectionWarning(null);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {t(sampleSelectionWarning)}
                      </Alert>
                    </Collapse>
                  )}
                  {assignmentSelectionWarning && (
                    <Collapse in={assignmentSelectionWarning}>
                      <Alert
                        variant="outlined"
                        severity="warning"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setAssignmentSelectionWarning(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {t(
                          'You cannot Assign/DeAssign for selected samples with  complete/closed statuses',
                        )}
                      </Alert>
                    </Collapse>
                  )}
                  {sampleSizeUneditableWarning && (
                    <Collapse in={sampleSizeUneditableWarning}>
                      <Alert
                        variant="outlined"
                        severity="warning"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setSampleSizeUneditableWarning(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {t(
                          'You cannot set the sample size of selected samples where the Recommended Sample Size is uneditable ',
                        )}
                      </Alert>
                    </Collapse>
                  )}
                  {sampleSizeStatusWarning && (
                    <Collapse in={sampleSizeStatusWarning}>
                      <Alert
                        variant="outlined"
                        severity="warning"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setSampleSizeStatusWarning(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {t(
                          'You cannot set the sample size of selected samples with complete/closed statuses',
                        )}
                      </Alert>
                    </Collapse>
                  )}
                </Box>
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <SampleHeadersGridResults
                    sampleHeadersData={sampleHeaderBlocksData}
                    isLoading={isLoadingLookups}
                    handleOnRowClick={handleOnRowClick}
                    handleOnCellClick={handleOnCellClick}
                    onSelectionModelChange={newSelectionModel => {
                      setSelectionModel(newSelectionModel);
                      setTriggerHideAllWarnings(true);
                    }}
                    actionType="SAMPLE_SET_DATA_GRID_UPDATE"
                    gridColumnState={
                      dataGridColumnsPreferences.sampleSetDataGrid
                    }
                    selectionModel={selectionModel}
                    initialState={{
                      sorting: {
                        sortModel: [
                          { field: 'vineyardName', sort: 'asc' },
                          { field: 'blockFullName', sort: 'asc' },
                        ],
                      },
                    }}
                  />
                </ErrorBoundary>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <StatusDrawer
        open={drawerOpen}
        onClose={handleOnCloseDrawer}
        statusId={sampleStatusID}
        statusOptions={sampleStatusOptions}
        handleOnStatus={handleOnStatusChange}
        handleOnSave={handleOnUpdateStatusSave}
        hasChanges={statusHasChanges}
      />
      <AssignmentDrawer
        open={assignmentDrawerOpen}
        onClose={handleOnAssignmentDrawerClose}
        statusId={sampleStatusID}
        samplingUsers={samplingUsers}
        handleOnChange={handleOnAssignmentChange}
        handleOnSave={handleOnAssignmentStatusSave}
        formdata={assignmentData}
        assignmentOperation={assignmentOperation}
      />
      <SampleSizeDrawer
        open={sampleSizeDrawerOpen}
        onClose={handleOnSampleSizeDrawerClose}
        statusId={sampleStatusID}
        samplingUsers={samplingUsers}
        handleOnChange={handleOnSampleSizeDataChange}
        handleOnSave={handleOnSampleSizeSave}
        formdata={sampleSizeData}
      />
    </div>
  );
};
