import React from 'react';

// Vinea MUI Components
import { useJobActivityHook } from '../hooks/useJobActivityHook';
import { ManageJobActivityDrawer } from '../ManageJobActivityDrawer';

export type AddJobActivityDrawerProps = {
  isOpen: boolean;
  handleOnClose: (drawerType: number | string) => void;
  jobID: number;
};

export type ValidationErrors = {
  activityCode?: string;
  jobActivityDescription?: string;
  contractedRate?: string;
  payrollRate?: string;
  effectiveFrom?: string;
  effectiveTo?: string;
  isDefault?: false;
};

export function AddJobActivityDrawer({
  isOpen,
  handleOnClose,
  jobID,
}: AddJobActivityDrawerProps) {
  const {
    jobActivityDrawerOpen,
    isAddDrawer,
    handleOnCloseDrawer,
    handleOnSave,
    handleOnChange,
    handleOnChangeCheckbox,
    handleOnDeleteActivityRate,
    jobActivityRateObject,
    validationErrors,
    filteredActivityOptions,
    rowSpecialAttributeOptions,
  } = useJobActivityHook(jobID, isOpen, handleOnClose);

  return (
    <>
      <ManageJobActivityDrawer
        isOpen={jobActivityDrawerOpen}
        isNew={isAddDrawer}
        handleOnClose={handleOnCloseDrawer}
        //@ts-ignore
        handleOnSave={handleOnSave}
        handleOnChange={handleOnChange}
        handleOnChangeCheckbox={handleOnChangeCheckbox}
        handleOnDelete={handleOnDeleteActivityRate}
        jobActivityData={jobActivityRateObject}
        identityActivityOptions={filteredActivityOptions}
        rowSpecialAttributeOptions={rowSpecialAttributeOptions}
        validationErrors={validationErrors}
      />
    </>
  );
}
