import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledTypography = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 500,
  overflowWrap: 'break-word',
  whiteSpace: 'break-spaces',
  wordBreak: 'break-word',
  wordWrap: 'break-word',
  textAlign: 'left',
}));

export const RenderDataGridHeader = params => {
  const { t } = useTranslation();
  const {
    colDef: { headerName },
  } = params;
  return (
    <StyledTypography component="div" data-testid="gridheaderlabel">
      {t(headerName)}
    </StyledTypography>
  );
};

RenderDataGridHeader.propTypes = {
  params: PropTypes.shape({
    colDef: PropTypes.shape({
      headerName: PropTypes.string,
    }),
  }),
};

RenderDataGridHeader.defaultProps = {
  params: {},
};
