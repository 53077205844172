export const PAGE_LOOKSUPS = {
  DASHBOARD_LOOKUPS: 'DASHBOARD_LOOKUPS',
  HOMEPAGE: 'HOMEPAGE',
  IDENTITY_LOOKUPS: 'IDENTITY_LOOKUPS',
  IDENTITY_ACTIVITY_LOOKUPS: 'IDENTITY_ACTIVITY_LOOKUPS',
  IDENTITY_DETAILS_LOOKUPS: 'IDENTITY_DETAILS_LOOKUPS',
  BLOCK_LOOKUPS: 'BLOCK_LOOKUPS',
  SAMPLING_TRACKING_LOOKUPS: 'SAMPLING_TRACKING_LOOKUPS',
  CONSUMABLE_LOOKUPS: 'CONSUMABLE_LOOKUPS',
  VEHICLE_LOOKUPS: 'VEHICLE_LOOKUPS',
  SENSORS_LOOKUPS: 'SENSORS_LOOKUPS',
  BLOCK_MAPS_LOOKUPS: 'BLOCK_MAPS_LOOKUPS',
  USER_LOOKUPS: 'USER_LOOKUPS',
  DATA_CONNECTION_LOOKUPS: 'DATA_CONNECTION_LOOKUPS',
  ACTIVITY_PROGRESS_LOOKUPS: 'ACTIVITY_PROGRESS_LOOKUPS',
  PLANS_LOOKUPS: 'PLANS_LOOKUPS',
  PLAN_ACTIVITIES_LOOKUPS: 'PLAN_ACTIVITIES_LOOKUPS',
  VINEYARD_EXPLORER_LOOKUPS: 'VINEYARD_EXPLORER_LOOKUPS',
  USER_SETTINGS_LOOKUPS: 'USER_SETTINGS_LOOKUPS',
  JOB_LOOKUPS: 'JOB_LOOKUPS',
};

export const PageType = {
  HOME: 'HOME',
  DASHBOARD: 'DASHBOARD',
  VINEYARD: 'VINEYARD',
};
