import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { StyledListItemText } from './IdentityDetailsExt3';
import { IdentityTypeIds } from '../../constants';

export default function IdentityDetailsExt2({ identityOverviewDetails }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    isPersonIdentity,
    ethnicity,
    nationality,
    gender,
    email,
    plantedArea,
    totalArea,
    identityTypeID,
    isPrimary,
    phone,
    preferredContact,
    displayName,
    siteType,
    consumableType,
    capacity,
    apiKey,
    connectionType,
    isPlannable,
    activityResourceType,
    activityResourceUnit,
    activityGroup,
    planName,
    planType,
    planStatus,
    planVintage,
    supplyContractPrimaryContact,
    supplyContractEmail,
    supplyContractDateSigned,
    supplyContractAreaLastChanged,
    supplyContractDateEnded,
    jobTypeMapValue,
    jobQuantityTypeMapValue,
    jobWorkRecordLevelMapValue,
    jobWorkRecordUnitTypeMapValue,
    vehicleMakeMapValue,
    vehicleModel,
    vehicleTypeMapValue,
    registrationNo,
    fleetNumber,
    vehicleCapacity,
  } = identityOverviewDetails;
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        paddingTop: 0,
        paddingBottom: 0,
        '& .MuiListItem-root': {
          alignItems: 'flex-start',
        },
      }}
      dense
    >
      {isPersonIdentity && (
        <>
          <ListItem>
            <StyledListItemText id="total-area">
              <Typography variant="body2">{`${t('Nationality')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="nationality">
                {nationality}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="planted-area">
              <Typography variant="body2">{`${t('Gender')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="gender">
                {gender}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="ethnicity">
              <Typography variant="body2">{`${t('Ethnicity')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="ethnicity">
                {ethnicity}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="email">
              <Typography variant="body2">{`${t('Email')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="email">
                {email}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.VINEYARD && (
        <>
          <ListItem>
            <StyledListItemText id="site-type">
              <Typography variant="body2">{`${t('Site Type')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="siteType">
                {siteType}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="total-area">
              <Typography variant="body2">{`${t('Total Area')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="totalarea">
                {totalArea}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="planted-area">
              <Typography variant="body2">
                {`${t('Total Planted Area')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="plantedarea"
              >
                {plantedArea}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="email">
              <Typography variant="body2">{`${t('Email')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="email">
                {email}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.SERVICE_PROVIDER && (
        <>
          <ListItem>
            <StyledListItemText id="isPrimary">
              <Typography variant="body2">
                {`${t('Primary Service Provider')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="service-provider"
              >
                {isPrimary ? 'Yes' : 'No'}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="phone">
              <Typography variant="body2">
                {`${t('Primary Phone')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="phone">
                {phone}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="email">
              <Typography variant="body2">
                {`${t('Primary Email')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="email">
                {email}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.DATA_CONNECTION && (
        <>
          <ListItem>
            <StyledListItemText id="isPrimary">
              <Typography variant="body2">
                {`${t('Connection User Name')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="service-provider"
              >
                {displayName}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="phone">
              {connectionType === 'GIS Services' ? (
                <Typography variant="body2">
                  {`${t('Connection URL')}:`}
                </Typography>
              ) : (
                <Typography variant="body2">
                  {`${t('Connection Token')}:`}
                </Typography>
              )}
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="phone">
                {connectionType === 'GIS Services' ? '**********' : apiKey}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.ORGANISATION && (
        <>
          <ListItem>
            <StyledListItemText id="primaryphone">
              <Typography variant="body2">
                {`${t('Primary Phone')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="businessunit"
              >
                {phone}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="primarycontact">
              <Typography variant="body2">
                {`${t('Primary Contact')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="phone">
                {preferredContact}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.CONSUMABLE && (
        <>
          <ListItem>
            <StyledListItemText id="consumable-name">
              <Typography variant="body2">
                {`${t('Consumable Name')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="consumable-name"
              >
                {displayName}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="consumable-type">
              <Typography variant="body2">
                {`${t('Consumable Type')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="consumable-type"
              >
                {consumableType}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {!isEmpty(planName) && (
        <>
          <ListItem>
            <StyledListItemText id="plan-name">
              <Typography variant="body2">{`${t('Plan Name')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="plan-id">
                {planName}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="plan-type">
              <Typography variant="body2">{`${t('Plan Type')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="plan-type">
                {planType}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="plan-status">
              <Typography variant="body2">{`${t('Plan Status')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="plan-status"
              >
                {planStatus}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="plan-vintage">
              <Typography variant="body2">{`${t('Plan Vintage')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="plan-vintage"
              >
                {planVintage || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.ACCOMMODATION && (
        <>
          <ListItem>
            <StyledListItemText id="accommodation-name">
              <Typography variant="body2">
                {`${t('Accommodation Name')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="accommodation-name"
              >
                {displayName}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="accommodation-capacity">
              <Typography variant="body2">
                {`${t('Accommodation Capacity')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="accommodation-capacity"
              >
                {capacity}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.ACTIVITY && (
        <>
          <ListItem>
            <StyledListItemText id="activity-code">
              <Typography variant="body2">
                {`${t('Activity Group')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="activity-code"
              >
                {activityGroup}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="activity-plannable">
              <Typography variant="body2">{`${t('Plannable')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="activity-plannable"
              >
                {isPlannable ? 'Yes' : 'No'}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="activity-unit-type">
              <Typography variant="body2">{`${t(
                'Resource Unit Type',
              )}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="activity-unit-type"
              >
                {activityResourceUnit}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="activity-resource-type">
              <Typography variant="body2">
                {`${t('Resource Type')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="activity-resource-type"
              >
                {activityResourceType}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.SUPPLY_CONTRACT && (
        <>
          <ListItem>
            <StyledListItemText id="supplycontract-primarycontact">
              <Typography variant="body2">
                {`${t('Primary Contact')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="supplycontract-primarycontact"
              >
                {supplyContractPrimaryContact}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="supplycontract-email">
              <Typography variant="body2">{`${t('Email')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="supplycontract-email"
              >
                {supplyContractEmail}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="supplycontract-datesigned">
              <Typography variant="body2">{`${t('Date Signed')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="supplycontract-datesigned"
              >
                {supplyContractDateSigned}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="supplycontract-arealastchange">
              <Typography variant="body2">
                {`${t('Last Change of Area')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="supplycontract-arealastchange"
              >
                {supplyContractAreaLastChanged}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="supplycontract-dateEnded">
              <Typography variant="body2">{`${t('Date Ended')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="supplycontract-dateended"
              >
                {supplyContractDateEnded}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.JOB && (
        <>
          <ListItem>
            <StyledListItemText id="site-type">
              <Typography variant="body2">{`${t('Site Type')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="siteType">
                {siteType}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="jobTypeMapValue">
              <Typography variant="body2">{`${t('Job Type')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="jobTypeMapValue"
              >
                {jobTypeMapValue}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="jobWorkRecordLevelMapValue">
              <Typography variant="body2">
                {`${t('Work Record Level')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="jobWorkRecordLevelMapValue"
              >
                {jobWorkRecordLevelMapValue || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="jobQuantityTypeMapValue">
              <Typography variant="body2">
                {`${t('Quantity Type')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="jobQuantityTypeMapValue"
              >
                {jobQuantityTypeMapValue || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="jobWorkRecordUnitTypeMapValue">
              <Typography variant="body2">{`${t('Unit Type')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="jobWorkRecordUnitTypeMapValue"
              >
                {jobWorkRecordUnitTypeMapValue || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.VEHICLE && (
        <>
          <ListItem>
            <StyledListItemText id="vehicleMakeMapValue">
              <Typography variant="body2">{`${t('Make')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="vehicleMakeMapValue"
                title={vehicleMakeMapValue || ''}
              >
                {vehicleMakeMapValue}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="vehicleModel">
              <Typography variant="body2">{`${t('Model')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="vehicleModel"
              >
                {vehicleModel}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="vehicleTypeMapValue">
              <Typography variant="body2">{`${t('Type')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="vehicleTypeMapValue"
              >
                {vehicleTypeMapValue}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="registrationNo">
              <Typography variant="body2">
                {`${t('Registration No')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="registrationNo"
              >
                {registrationNo || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="fleetNumber">
              <Typography variant="body2">{`${t('Fleet Number')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="fleetNumber"
              >
                {fleetNumber || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="vehicleCapacity">
              <Typography variant="body2">{`${t(
                'Vehicle Capacity',
              )}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="vehicleCapacity"
              >
                {vehicleCapacity || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
    </List>
  );
}

IdentityDetailsExt2.propTypes = {
  identityOverviewDetails: PropTypes.shape({
    id: PropTypes.number,
    identityTypeID: PropTypes.number,
    isPersonIdentity: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    knownAs: PropTypes.string,
    displayName: PropTypes.string,
    plantedArea: PropTypes.string,
    totalArea: PropTypes.string,
    birthDate: PropTypes.string,
    ethnicity: PropTypes.string,
    nationality: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    subregion: PropTypes.string,
    address: PropTypes.string,
    roles: PropTypes.string,
    businessUnit: PropTypes.string,
    isPrimary: PropTypes.bool,
    password: PropTypes.string,
    token: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    preferredContact: PropTypes.string,
    isPlannable: PropTypes.bool,
    activityResourceType: PropTypes.string,
    activityWorkunit: PropTypes.string,
    planName: PropTypes.string,
    supplyContractPrimaryContact: PropTypes.string,
    supplyContractEmail: PropTypes.string,
    supplyContractDateSigned: PropTypes.string,
    supplyContractAreaLastChanged: PropTypes.string,
    supplyContractDateEnded: PropTypes.string,
    jobName: PropTypes.string,
    jobDescription: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    jobTypeID: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    jobStatusID: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    invoiceMethodID: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    quantityTypeID: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    workRecordLevelID: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    workRecordUnitTypeID: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    invoiceToIdentityID: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    poNumber: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    jobTypeMapValue: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    jobQuantityTypeMapValue: PropTypes.oneOf([
      PropTypes.number,
      PropTypes.string,
    ]),
    jobWorkRecordLevelMapValue: PropTypes.oneOf([
      PropTypes.number,
      PropTypes.string,
    ]),
    jobWorkRecordUnitTypeMapValue: PropTypes.oneOf([
      PropTypes.number,
      PropTypes.string,
    ]),
    jobStatusMapValue: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  }),
};

IdentityDetailsExt2.defaultProps = {
  identityOverviewDetails: {},
};
