import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { MetaDataType } from '../interfaces/jobInterfaces';

export interface LookupType {
  id: number;
  key: number;
  value: string;
}

export interface JobActivity {
  activityCode: string;
  id: number;
  jobID: number;
  activityID: number;
  jobActivityDescription: string;
  isDefault: boolean;
  canDeleteActivity?: boolean;
  rowAttributeID: number;
  contractedRate: number;
  payrollRate: number;
  ts: string;
}

export interface JobActivityRate {
  id: number;
  jobActivityID: number;
  effectiveFrom: string;
  effectiveTo: string;
  contractedRate: number;
  payrollRate: number;
  isGSTApplicable: boolean;
  gstRate: number;
  ts: string;
}

export interface FetchJobDataProps {
  jobId?: number;
  jobActivityId?: number;
  jobActivityRateId?: number;
  jobBlockID?: number;
}
export const useFetchJobData = ({
  jobId,
  jobActivityId,
  jobActivityRateId,
  jobBlockID,
}: FetchJobDataProps) => {
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const jobActivity = useSelector(
    VineaNovaSelectors.getJobActivitiesEntityData,
  );
  const jobActivityRate: any[] = useSelector(
    VineaNovaSelectors.getGetJobActivityRatesEntityData,
  );
  const {
    isLoading: jobActivityRateLoading,
    isLoaded: jobActivityRateLoaded,
    hasError: jobActivityRateHasError,
    error: jobActivityRateError,
  } = useSelector(
    VineaNovaSelectors.getJobActivityRateEntityMeta,
  ) as MetaDataType;

  const blocksForJob = useSelector(VineaNovaSelectors.getBlocksForJobEntity);

  const rowsByJobBlock = useSelector(
    VineaNovaSelectors.getRowsByJobBlockEntity,
  );
  const jobBlock = useSelector(VineaNovaSelectors.getJobBlockEntity);
  const identityJob = useSelector(VineaNovaSelectors.getIdentityJobEntity);
  const dispatchAPI = useDispatch();

  const jobActivityRateMemo = React.useMemo(() => {
    if (jobActivityRate) {
      return jobActivityRate.map((rate: JobActivityRate) => ({
        // @ts-ignore
        id: rate.jobActivityRateID,
        ...rate,
      }));
    }
    return [];
  }, [jobActivityRate]);

  useEffect(() => {
    if (jobId) {
      dispatchAPI(
        VineaNovaActions.api.v1.jobActivities.get.request({
          queryParams: {
            jobID: jobId,
          },
        }),
      );
      dispatchAPI(
        VineaNovaActions.api.v1.getJobActivityRates.get.request({
          queryParams: {
            jobID: jobId,
          },
        }),
      );
      dispatchAPI(
        VineaNovaActions.api.v1.blocksForJob.get.request({
          queryParams: {
            jobID: jobId,
          },
        }),
      );
      dispatchAPI(
        VineaNovaActions.api.v1.identityJob.get.request({
          queryParams: {
            IdentityID: jobId,
          },
        }),
      );
      setRefreshTrigger(false);
    }

    if (jobBlockID) {
      dispatchAPI(
        VineaNovaActions.api.v1.rowsByJobBlock.get.request({
          queryParams: {
            JobBlockID: jobBlockID,
          },
        }),
      );
      dispatchAPI(
        VineaNovaActions.api.v1.jobBlock.get.request({
          queryParams: {
            JobBlockID: jobBlockID,
          },
        }),
      );
      setRefreshTrigger(false);
    }
  }, [
    dispatchAPI,
    jobId,
    jobActivityId,
    jobActivityRateId,
    jobBlockID,
    refreshTrigger,
  ]);

  return {
    jobActivity,
    jobActivityRate: jobActivityRateMemo,
    blocksForJob,
    rowsByJobBlock,
    identityJob,
    jobBlock,
    jobActivityRateLoading,
    jobActivityRateLoaded,
    jobActivityRateHasError,
    jobActivityRateError,
    refreshTrigger,
    setRefreshTrigger,
  };
};
