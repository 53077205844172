import * as Yup from 'yup';
import { isValid } from 'date-fns';

export const EditSupplyContractBlockSchema = Yup.object().shape({
  id: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  supplyContractID: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  vineyardBlockID: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  contractedArea: Yup.string()
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Must be a number')
    .required('Required'),
  contractedWeightPerAreaUnit: Yup.string()
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Must be a number')
    .required('Required'),
  fromDate: Yup.string()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
});

export const AddSupplyContractBlockSchema = Yup.object().shape({
  supplyContractID: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  vineyardBlockID: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  contractedArea: Yup.string()
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Must be a number')
    .required('Required'),
  contractedWeightPerAreaUnit: Yup.string()
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Must be a number')
    .required('Required'),
  fromDate: Yup.string()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
});
