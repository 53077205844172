/* eslint-disable react/prop-types */
import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { TabPanel, TabView } from '../../components/TabView';
import { VintageDetailsContainer } from './Vintages/VintageDetailsContainer';
import { PhenologyDetails } from './Phenology/PhenologyDetails';
import { OtherAttributesContainer } from './OtherAttributes/OtherAttributesContainer';
import { NotesListContainer } from './Notes/NotesListContainer';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { dataGridOtherAttributeTrackingColumns } from './gridColumns';

const StyledComponentRoot = styled('div')(() => ({
  width: '100%',
}));

const StyledRoot = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: '100%',
}));

export const TrackingDetails = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const trackURLMap = {
    0: t('vintage'),
    1: t('phenology'),
    2: t('notes'),
    3: t('attributes'),
  };

  const { setIsLocked } = useTabChangeLock();

  const handleonTabChange = newValue => {
    setValue(newValue);
    setIsLocked(true);
    handleURLChange(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const handleURLChange = newValue => {
    navigate(`/samples/tracking/${trackURLMap[newValue]}`);
  };

  React.useEffect(() => {
    // navigate to tab based on url params
    if (pathname === '/samples/tracking') {
      navigate('/samples/tracking/vintage', { replace: true });
      setValue(0);
    } else if (pathname === '/samples/tracking/phenology') {
      setValue(1);
    } else if (pathname === '/samples/tracking/notes') {
      setValue(2);
    } else if (pathname === '/samples/tracking/attributes') {
      setValue(3);
    }
  }, [navigate, pathname]);

  return (
    <StyledComponentRoot data-testid="vineyad-details">
      <StyledRoot data-testid="vineyardidentity">
        <TabView
          tabHeaders={[
            t('Vintage'),
            t('Phenology'),
            t('Notes'),
            t('Attributes'),
          ]}
          onTabChange={handleonTabChange}
          onSwipe={handleChangeIndex}
          value={value}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            data-testid="tabpanel-overview"
          >
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <VintageDetailsContainer />
            </ErrorBoundary>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            data-testid="tabpanel-blocks"
          >
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <PhenologyDetails />
            </ErrorBoundary>
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            dir={theme.direction}
            data-testid="tabpanel-blockmaps"
          >
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <NotesListContainer />
            </ErrorBoundary>
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            dir={theme.direction}
            data-testid="tabpanel-blocks"
          >
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <OtherAttributesContainer
                dataColumns={dataGridOtherAttributeTrackingColumns}
              />
            </ErrorBoundary>
          </TabPanel>
        </TabView>
      </StyledRoot>
    </StyledComponentRoot>
  );
};
