import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';

export interface FetchActivitytDataProps {
  activityID?: number | null;
  defaultRateDate?: string | null;
}

export const useFetchActivityData = ({
  activityID = null,
  defaultRateDate = null,
}: FetchActivitytDataProps) => {
  const identityActivity = useSelector(
    VineaNovaSelectors.getIdentityActivityEntityData,
  );

  const dispatchAPI = useDispatch();

  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.identityActivity.get.request({
        queryParams: {
          IdentityID: activityID,
          DefaultRateDate: defaultRateDate,
        },
      }),
    );
  }, [dispatchAPI, activityID, defaultRateDate]);

  return {
    identityActivity,
  };
};
