import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { endsWith } from 'lodash';
import Loader from '../../components/Loader/Loader';
import { WithAppContainer } from '../common';

const SampleDetailsContainer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (endsWith(pathname, '/sampledetails')) {
      const newPath = pathname.replace('/sampledetails', '');
      navigate(newPath);
    }
  }, [navigate, pathname]);

  return (
    <WithAppContainer>
      <Loader />
    </WithAppContainer>
  );
};

SampleDetailsContainer.propTypes = {};

export default SampleDetailsContainer;
