/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { VineaTreeViewComponent } from '../Treeview';


export const SensorBlocksTransferList = ({
  allBlocks = [],
  selectedBlockIDs = [],
  isLoading = false,
  groups = [],
  onChange,
}) => {
  const { t } = useTranslation();

  const [leftSelected, setLeftSelected] = React.useState([]);
  const [rightSelected, setRightSelected] = React.useState([]);
  const [leftBlocks, setLeftBlocks] = React.useState([]);
  const [rightBlocks, setRightBlocks] = React.useState([]);

  const rightHeader = 'Selected Blocks';
  const leftHeader = 'Available Blocks';

  const handleOnChange = React.useCallback(
    selectedValues => {
      const getIds = selectedValues.reduce((acc, val) => {
        const { blockVintageID } = val;
        acc.push(blockVintageID);
        return acc;
      }, []);
      const target = {
        value: getIds,
        name: 'blockVintageIDs',
      };
      onChange({ target });
    },
    [onChange],
  );

  const handleAllRight = () => {
    const newValues = rightBlocks.concat(leftBlocks);
    setLeftSelected([]);
    handleOnChange(newValues);
  };

  const handleCheckedRight = () => {
    const copyToRight = leftBlocks.filter(f =>
      leftSelected.includes(f.blockVintageID),
    );
    const newValues = rightBlocks.concat(copyToRight);
    setLeftSelected([]);
    handleOnChange(newValues);
  };

  const handleCheckedLeft = () => {
    const rightRemoved = rightBlocks.filter(
      f => !rightSelected.includes(f.blockVintageID),
    );
    setRightSelected([]);
    handleOnChange(rightRemoved);
  };

  const handleAllLeft = () => {
    setRightSelected([]);
    handleOnChange([]);
  };

  const handleLeftNodeSelect = nodeIds => {
    setLeftSelected(nodeIds);
  };

  const handleRightNodeSelect = nodeIds => {
    setRightSelected(nodeIds);
  };

  React.useEffect(() => {
    if (!isLoading) {
      const left = allBlocks.filter(
        f => !selectedBlockIDs.includes(f.blockVintageID),
      );
      const right = allBlocks.filter(f =>
        selectedBlockIDs.includes(f.blockVintageID),
      );
      setLeftBlocks(left);
      setRightBlocks(right);
    }
  }, [allBlocks, selectedBlockIDs, isLoading]);

  const customList = side => (
    <Box>
      {side === 'left' && (
        <Typography variant="body1">{t(`${leftHeader}`)}</Typography>
      )}
      {side === 'right' && (
        <Typography variant="body1">{t(`${rightHeader}`)}</Typography>
      )}
      <Paper
        sx={{
          width: '100%',
          height: 330,
          overflow: 'auto',
          border: '1px solid rgba(0,0,0,0.23)',
        }}
        data-testid={`simpletransferlist-${side}`}
      >
        <Box
          sx={{
            padding: '1, 2',
          }}
        >
          <VineaTreeViewComponent
            groups={groups}
            blocks={side === 'left' ? leftBlocks : rightBlocks}
            onNodeSelect={
              side === 'left' ? handleLeftNodeSelect : handleRightNodeSelect
            }
            selected={side === 'left' ? leftSelected : rightSelected}
            isLoading={isLoading}
            side={side}
          />
        </Box>
      </Paper>
    </Box>
  );

  return (
    <Grid
      container
      spacing={2}
      justify="flex-start"
      alignItems="center"
      sx={{ margin: 'auto' }}
    >
      <Grid item sx={{ flex: 1 }}>{customList('left')}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            aria-label="move all right"
            sx={{
              margin: '0.5, 0',
            }}
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftSelected.length === 0}
            aria-label="move selected right"
            sx={{
              margin: '0.5, 0',
            }}
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightSelected.length === 0}
            aria-label="move selected left"
            sx={{
              margin: '0.5, 0',
            }}
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            aria-label="move all left"
            sx={{
              margin: '0.5, 0',
            }}
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 1 }}>{customList('right')}</Grid>
    </Grid>
  );
};

SensorBlocksTransferList.propTypes = {
  allBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      vintageBlockID: PropTypes.number.isRequired,
    }),
  ).isRequired,
  selectedBlockIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  isLoading: PropTypes.bool.isRequired,
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};
