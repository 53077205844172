import React from 'react';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const StyledGreenCheckbox = styled(Checkbox)(() => ({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
    paddingTop: 0,
  },
  checked: {},
}));

export const GreenCheckbox = (props: CheckboxProps) => (
  <StyledGreenCheckbox {...props} />
);
