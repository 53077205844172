/* eslint-disable react/prop-types */
import * as React from 'react';
import {
  useGridRootProps,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  // gridPageSelector,
  // gridPageCountSelector,
  // useGridSelector,
} from '@mui/x-data-grid-pro';
import { Box, Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FilterTextDescription } from '../DashboardComponents/FilterTextDescription';
import { VineaButton } from '../VineaButton';

export const CustomToolbar = React.forwardRef(() => {
  const { t } = useTranslation();
  const rootProps = useGridRootProps();
  const {
    hasSearch = true,
    handleOnAdvancedSearch,
    advancedSearch,
    selectedFilterValuesText,
    excludeBusinessUnit,
    hasButton = false,
    buttonText = 'Add',
    onButtonClick,
  } = rootProps.componentsProps;

  const handleOnFilterClick = () => {
    handleOnAdvancedSearch();
  };

  const handleOnButtonClick = () => {
    onButtonClick();
  };

  return (
    <GridToolbarContainer
      sx={{ gap: 4, justifyContent: 'space-between', padding: 2 }}
      data-testid="grid-toolbar"
    >
      {hasSearch ? (
        <GridToolbarQuickFilter
          id="grid-quick-filter"
          data-testid="grid-quick-filter"
        />
      ) : (
        <Box sx={{ flexGrow: 1 }} />
      )}

      {advancedSearch && (
        <Tooltip title={t('Filters')}>
          <Stack
            direction="row"
            onClick={handleOnFilterClick}
            sx={{ cursor: 'pointer', alignItems: 'center' }}
            data-testid="grid-toolbar-stack"
          >
            <FilterTextDescription
              selectedValuesText={selectedFilterValuesText}
              isLarge
              iconColour="primary"
              showBlankValues={false}
              excludeBusinessUnit={excludeBusinessUnit}
            />
          </Stack>
        </Tooltip>
      )}
      {hasButton && (
        <VineaButton
          variant="text"
          color="secondary"
          sx={{
            border: '0.5px solid #E0E0E0',
          }}
          onClick={handleOnButtonClick}
          data-testid="btn-add"
        >
          {t(buttonText)}
        </VineaButton>
      )}
    </GridToolbarContainer>
  );
});
