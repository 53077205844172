import { RenderDataGridHeader } from '../Grid';

export const filterDataGridColumns = [
  {
    field: 'activityCode',
    headerName: 'Activity',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
];
