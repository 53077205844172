import * as Yup from 'yup';

export const UserSchema = Yup.object().shape({
  workEmail: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  firstName: Yup.string()
    .max(30, 'First Name too Long')
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  lastName: Yup.string()
    .max(30, 'Last Name too Long')
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  businessUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please choose a business unit')
    .nullable()
    .required('Required'),
  isUserActive: Yup.boolean().nullable().required('Required'),
  isVineaAdmin: Yup.boolean().nullable(),
  identityId: Yup.number().nullable(),
});
