import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

const MuiListItemLink = ({ icon, text, to }) => {
  return (
    <li>
      <ListItem button>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <Link component={RouterLink} to={to} underline="always">
          {text}
        </Link>
      </ListItem>
    </li>
  );
};

MuiListItemLink.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.string,
  to: PropTypes.string.isRequired,
};

MuiListItemLink.defaultProps = {
  icon: null,
  text: null,
};

export default MuiListItemLink;
