/* eslint-disable react/prop-types */
import React from 'react';
import { GridWithFilter } from '../../../components/Grid';
import { sampleHeaderGridColumns } from './sampleHeadersGridColumns';

export const SampleHeadersGridResults = ({
  handleOnRowClick,
  handleOnCellClick,
  isLoading = false,
  sampleHeadersData = [],
  ...props
}) => {
  const [sampleData, setSampleData] = React.useState([]);

  React.useEffect(() => {
    setSampleData(sampleHeadersData);
  }, [sampleHeadersData]);

  const gridXData = { columns: sampleHeaderGridColumns, rows: sampleData };

  return (
    <GridWithFilter
      data={gridXData}
      onRowClick={handleOnRowClick}
      onCellClick={handleOnCellClick}
      rowHeight={30}
      loading={isLoading}
      checkboxSelection
      disableSelectionOnClick
      advancedSearch={false}
      {...props}
    />
  );
};
