import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Unauthorized from '../../components/Unauthorized/Unauthorized';
import {
  getUserEmail,
  getUserDisplayName,
  getAuthenticationText,
  getOrganisationStatus,
} from '../../redux/selectors/authenticationSelector';
import withAuthProvider from '../../hooks/AuthProvider';
import { getAllUsers } from '../../redux/selectors';
import {
  apiTypes,
  sagaActionTypes,
  reducers,
  dateFormat,
  vineaDetails,
} from '../../constants';
import { isNil, some } from 'lodash';

const UnauthorizedContainer = ({
  text,
  email,
  displayName,
  organisationStatus,
  logout,
}) => {
  const today = format(new Date(), dateFormat);
  const dispatchAPI = useDispatch();
  const { isActive } = organisationStatus;
  const { data: existingUsers } = useSelector(getAllUsers);
  const userExists = some(existingUsers, { email: email }) ? true : false;

  // Create new inactive user if user doesn't exist and organisation is valid
  React.useEffect(() => {
    if (isActive && !userExists && !isNil(email)) {
      var [firstName, ...rest] = displayName.split(' ');
      var lastName = rest.join(' ');
      const data = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        created: today,
        isUserActive: false,
        isVineaAdmin: false,
        identityId: null,
        userPersonDto: {},
      };
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.user,
          methodType: apiTypes.POST,
        },
      });
    }
  }, [isActive, existingUsers, email, displayName, userExists, dispatchAPI]);

  React.useEffect(() => {
    dispatchAPI({
      type: sagaActionTypes.SEARCH_USERS,
      name: reducers.searchUsers,
      payload: {
        searchEmail: '',
        searchIsActive: false,
        searchIsAdmin: false,
        searchName: '',
      },
    });
  }, []);

  return <Unauthorized message={text} logout={logout} />;
};

UnauthorizedContainer.propTypes = {
  text: PropTypes.shape({
    line1: PropTypes.string.isRequired,
    line2: PropTypes.string.isRequired,
  }).isRequired,
  email: PropTypes.string,
  displayName: PropTypes.string,
  organisationStatus: PropTypes.object,
  logout: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  text: getAuthenticationText(state),
  email: getUserEmail(state),
  displayName: getUserDisplayName(state),
  organisationStatus: getOrganisationStatus(state),
});

export default connect(mapStateToProps)(
  withAuthProvider(UnauthorizedContainer),
);
