/* eslint-disable no-undef */
import React from 'react';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

export const FeatureInDevelopment = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Paper elevation={0}>
      <Box py={2} px={3}>
        <Box display="flex" alignItems="center">
          <NewReleasesIcon />
          <Typography
            color="textPrimary"
            sx={{
              paddingLeft: theme.spacing(1),
            }}
          >
            {t('This feature is in development. Please contact admin')}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
