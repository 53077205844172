import React from 'react';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { useDispatch, useSelector } from 'react-redux';

export const useUserHistoryHook = () => {
  const dispatchAPI = useDispatch();
  const userIdentityId =
    useSelector(state => state.userAccess.data?.licensedUserIdentityID) || null;
  /// use selector get user history
  const userHistoryData = useSelector(
    VineaNovaSelectors.getUserHistoryEntityData,
  );

  React.useEffect(() => {
    // Fetch user history from API
    dispatchAPI(
      VineaNovaActions.api.v1.userHistory.get.request({
        queryParams: { userIdentityID: userIdentityId },
      }),
    );
  }, [dispatchAPI, userIdentityId]);

  const fetchUserHistory = React.useCallback(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.userHistory.get.request({
        queryParams: { userIdentityID: userIdentityId },
      }),
    );
  }, [dispatchAPI, userIdentityId]);

  // update userHistory api
  const updateUserHistory = React.useCallback(
    data => {
      dispatchAPI(
        VineaNovaActions.api.v1.userHistory.post.request({
          postBody: {
            ...data,
          },
        }),
      );
    },
    [dispatchAPI],
  );

  return {
    history: userHistoryData,
    fetchUserHistory,
    updateUserHistory,
  };
};
