import { useCallback, useEffect, useState } from 'react';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { useDispatch } from 'react-redux';

import { useFetchJobData } from './useFetchJobData';
import { filter, get, has, isEmpty, isNil } from 'lodash';
import { syncValidator } from '../../../utils/validator';
import { JobActivityRateSchema } from '../validations';
import { isBefore, isAfter, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useJobActivityRatesHook = (
  jobID: number,
  jobActivityId: number,
) => {
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const noErrors = {
    effectiveFrom: false,
    effectiveTo: false,
    contractedRate: false,
    payrollRate: false,
    isDefault: false,
  };
  //@ts-ignore
  const [apiTriggered, setApiTriggered] = useState(false);
  const [isRateDrawerOpen, setIsRateDrawerOpen] = useState(false);
  const [isNewRate, setIsNewRate] = useState(false);
  const [selectedActivityRate, setSelectedActivityRate] = useState({});
  const [activityRateValidationErrors, setActivityRateValidationErrors] =
    useState({
      ...noErrors,
    });

  const {
    jobActivity,
    jobActivityRate,
    jobActivityRateLoading,
    jobActivityRateLoaded,
    jobActivityRateHasError,
    jobActivityRateError,
    setRefreshTrigger,
  } = useFetchJobData({
    jobId: Number(jobID),
    jobActivityId: Number(jobActivityId),
    jobActivityRateId: 0,
  });

  const onRowClick = (event: any) => {
    const { row } = event;
    setSelectedActivityRate(row);
    setIsRateDrawerOpen(true);
    setIsNewRate(false);
  };

  const onButtonClick = () => {
    setIsRateDrawerOpen(true);
    setIsNewRate(true);
    setSelectedActivityRate({ jobActivityID: jobActivityId });
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    //@ts-ignore
    setSelectedActivityRate({
      ...selectedActivityRate,
      [name]: value,
    });
  };

  const handleOnChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { checked, name },
    } = event;
    //@ts-ignore
    setSelectedActivityRate({
      ...selectedActivityRate,
      [name]: checked,
    });
  };

  // Cannot have overlapping active from or to dates with other rates on this job activity
  const isBetweenDates = useCallback(
    (fromDate: any, toDate: any) => {
      let validationError = {};
      filter(jobActivityRate, (rate: any) => {
        return (
          rate.id !== 0 &&
          rate.jobActivityID === jobActivityId &&
          rate.jobActivityRateID !==
            get(selectedActivityRate, 'jobActivityRateID')
        );
      }).map((rate: any) => {
        if (
          rate.jobActivityID === jobActivityId &&
          rate.jobActivityRateID !==
            get(selectedActivityRate, 'jobActivityRateID')
        ) {
          if (
            isBefore(
              parseISO(get(rate, 'effectiveFrom', '')),
              parseISO(fromDate),
            )
          ) {
            if (
              isAfter(
                parseISO(get(rate, 'effectiveTo', '')),
                parseISO(fromDate),
              )
            ) {
              validationError = {
                effectiveFrom:
                  'Active From date is between other active from and active to dates',
              };
            } else if (isEmpty(get(rate, 'effectiveTo'))) {
              validationError = {
                effectiveFrom:
                  'Invalid active from and to dates, as another rate has no active to date',
              };
            }
          } else {
            if (isEmpty(toDate)) {
              validationError = {
                effectiveFrom:
                  'Invalid active from and to dates, as another rate has no active to date',
              };
            } else if (
              isBefore(
                parseISO(get(rate, 'effectiveFrom', '')),
                parseISO(toDate),
              )
            ) {
              validationError = {
                effectiveTo:
                  'Active To date is between other active from and active to dates',
              };
            }
          }
        }
      });

      return validationError;
    },
    [jobActivityRate, selectedActivityRate],
  );

  const handleOnSave = () => {
    const saveData = {
      ...selectedActivityRate,
      contractedRate: Number(get(selectedActivityRate, 'contractedRate', 0)),
      payrollRate: Number(get(selectedActivityRate, 'payrollRate', 0)),
    };
    let validationErrors = syncValidator(JobActivityRateSchema)(saveData);

    if (isEmpty(validationErrors)) {
      //check effectiveFrom and effectiveTo dates
      // aren't between existing effectiveFrom and effectiveTo dates
      validationErrors = isBetweenDates(
        get(saveData, 'effectiveFrom', ''),
        get(saveData, 'effectiveTo', ''),
      );

      if (isEmpty(validationErrors) && !isNil(get(saveData, 'effectiveTo'))) {
        const isToDateBeforeFromDate =
          has(saveData, 'effectiveTo') &&
          has(saveData, 'effectiveFrom') &&
          isBefore(
            parseISO(get(saveData, 'effectiveTo', '')),
            parseISO(get(saveData, 'effectiveFrom', '')),
          );
        if (isToDateBeforeFromDate) {
          validationErrors = {
            effectiveTo: 'Active To date is before Active From date',
          };
        } else {
          setSelectedActivityRate({
            effectiveTo: new Date(get(saveData, 'effectiveTo', '')),
            ...saveData,
          });
        }
      }
    }
    if (isEmpty(validationErrors)) {
      if (isNewRate) {
        dispatchAPI(
          VineaNovaActions.api.v1.jobActivityRate.post.request({
            postBody: {
              jobActivityID: jobActivityId,
              effectiveFrom: new Date(get(saveData, 'effectiveFrom', '')),
              effectiveTo: get(saveData, 'effectiveTo', ''),
              contractedRate: Number(get(saveData, 'contractedRate', 0)),
              payrollRate: Number(get(saveData, 'payrollRate', 0)),
              isGSTApplicable: get(saveData, 'isGSTApplicable', false),
              gstRate: Number(get(saveData, 'gstRate', 0)),
            },
          }),
        );
      } else {
        dispatchAPI(
          VineaNovaActions.api.v1.jobActivityRate.put.request({
            postBody: {
              id: get(saveData, 'jobActivityRateID'),
              jobActivityID: jobActivityId,
              effectiveFrom: new Date(get(saveData, 'effectiveFrom', '')),
              effectiveTo: get(saveData, 'effectiveTo', ''),
              contractedRate: Number(get(saveData, 'contractedRate', 0)),
              payrollRate: Number(get(saveData, 'payrollRate', 0)),
              isGSTApplicable: get(saveData, 'isGSTApplicable', false),
              gstRate: Number(get(saveData, 'gstRate', 0)),
            },
          }),
        );
      }
      setIsRateDrawerOpen(false);
      setSelectedActivityRate({});
      setApiTriggered(true);
    }
    setActivityRateValidationErrors(validationErrors);
  };

  const handleOnDelete = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.jobActivityRate.delete.request({
        queryParams: {
          ID: get(selectedActivityRate, 'jobActivityRateID'),
        },
      }),
    );
    setApiTriggered(true);
  };

  const handleOnCloseDrawer = () => {
    setIsRateDrawerOpen(false);
    setActivityRateValidationErrors({ ...noErrors });
  };

  useEffect(() => {
    if (apiTriggered) {
      if (jobActivityRateLoaded && !jobActivityRateLoading) {
        if (jobActivityRateHasError) {
          enqueueSnackbar(t('Error'), { variant: 'error' });
          setErrorInSubmit(jobActivityRateError);
        } else {
          setRefreshTrigger(true);
          enqueueSnackbar(t('Success'), { variant: 'success' });
          setIsRateDrawerOpen(false);
        }
        setApiTriggered(false);
      }
    }
  }, [jobActivityRateLoaded, jobActivityRateLoading, apiTriggered]);

  return {
    jobActivity,
    jobActivityRate,
    jobActivityRateLoading,
    isRateDrawerOpen,
    selectedActivityRate,
    isNewRate,
    activityRateValidationErrors,
    onRowClick,
    handleOnCloseDrawer,
    setIsRateDrawerOpen,
    handleOnDeleteActivityRate: handleOnDelete,
    handleOnSaveActivityRate: handleOnSave,
    handleOnChangeActivityRate: handleOnChange,
    handleOnChangeActivityRateCheckbox: handleOnChangeCheckbox,
    onAddRateButtonClick: onButtonClick,
  };
};
