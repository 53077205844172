import { format, parseISO } from 'date-fns';
import { first, get, orderBy } from 'lodash';
import { dateFormat } from '../constants';

export const useIdentityContactMethodHook = () => {
  // Grabs the first primary contact method if there is one
  // and if no primary contact method exists, it grabs the first contact method
  const getContactMethod = (dataContact: any, contactIDs: Number[]) => {
    const contactMethod = get(
      first(
        orderBy(
          dataContact.filter(
            (value: any) =>
              contactIDs.includes(value.contactMethodTypeID) &&
              (value.effectiveToDate === null ||
                format(parseISO(value.effectiveToDate), dateFormat) >=
                  format(new Date(), dateFormat)),
          ),
          [
            con => con.isPrimaryContact !== true,
            con => (con.isPrimaryContact === true ? 0 : 1),
          ],
        ),
      ),
      'contactMethodDetails',
      '',
    );

    return contactMethod;
  };

  return {
    getContactMethod,
  };
};
