import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import times from 'lodash/times';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { isBefore, parse } from 'date-fns';
import { CommonGridData } from './CommonGridData';
import { VineaButton } from '../VineaButton';
import { GreenSwitch } from '../Switch';
import { IdentityTypeIds, commonGridHeaderNames } from '../../constants';
import { getPathByIdentityTypeId } from '../../hooks/useIdentityTypeId';
import useIdentityTypeScreenNameHook from '../../hooks/useIdentityTypeScreenNameHook';

export const VineaCommonGrid = ({
  heading,
  buttonText = 'Manage',
  colHeaders,
  manage = () => {},
  tableData,
  isLoading,
  hasManageOption,
  hasActiveToggle = true,
  onRowClick = () => {},
  tableHeight = 300,
  identityTypeId = 0,
  onCheckboxClick = rowID => {},
  identityId = 0,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showActive, setShowActive] = React.useState(true);
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();

  let colData = [];
  if (!isEmpty(tableData)) {
    const classificationFormattedData =
      heading === 'Classifications'
        ? tableData
            .filter(f => {
              if (!showActive) return true;
              return f.isActive === true;
            })
            .map(da => ({
              col1: da.group,
              col2: da.classification,
              col3: da.fromTo,
            }))
        : [];
    const contactMethodFormattedData =
      heading === 'Contact Information'
        ? tableData
            .filter(f => {
              if (!showActive) return true;
              return f.isActive === true;
            })
            .map(da => ({
              col1: da.contactMethodType,
              col2: da.contactMethodDetails,
              col3: da.fromTo,
            }))
        : [];

    const referenceFormattedData =
      heading === 'References'
        ? tableData
            .filter(f => {
              if (!showActive) return true;
              return f.isActive === true;
            })
            .map(da => ({
              col1: da.referenceType,
              col2: da.referenceValue,
              col3: da.fromTo,
            }))
        : [];
    const rolesFormattedData =
      heading === 'Roles'
        ? tableData
            .filter(f => {
              if (!showActive) return true;
              return f.isActive === true;
            })
            .map(da => ({
              col1: da.roleType,
              col2: da.fromTo,
            }))
        : [];
    const relationshipFormatterData =
      heading === 'Relationships'
        ? tableData
            .filter(f => {
              if (!showActive) return true;
              return f.isActive === true;
            })
            .map(da => ({
              col1: da.relatesTo,
              col2: da.comment,
              col3: da.fromTo,
              name: da.relatesTo,
              link: getPathByIdentityTypeId(
                identityTypeId === da.dependentIdentityTypeID
                  ? da.ownerIdentityTypeID
                  : da.dependentIdentityTypeID,
                Number(identityId) === Number(da.dependentIdentityID)
                  ? da.ownerIdentityID
                  : da.dependentIdentityID,
                vineyardTypeScreenName,
              ),
            }))
        : [];

    const supplyContractBlocksFormatterData =
      heading === commonGridHeaderNames.SUPPLY_CONTRACT_BLOCKS
        ? tableData.map(da => ({
            id: da.id,
            col1: da.vineyard,
            col2: da.block,
            col3: da.area,
            col4: da.tha,
            col5: { value: da.contractedTonnes, dataType: 'string' },
            col6: da.fromTo,
          }))
        : [];

    const workerPayPeriodsFormatterData =
      heading === commonGridHeaderNames.WORKER_PAY_PERIODS
        ? tableData.map(da => ({
            id: da.id,
            col1: da.payrollStatus,
            col2: da.records,
            col3: da.payPeriod,
          }))
        : [];

    const workerWorkRecordsFormatterData =
      heading === commonGridHeaderNames.WORKER_WORK_RECORDS
        ? tableData.map(da => ({
            id: da.id,
            col1: da.type || ' ',
            col2: da.date || ' ',
            col3: da.block || ' ',
            col4: da.referenceType || ' ',
            col5: { value: da.referenceValue, dataType: 'string' },
            col6: da.unitType || ' ',
            col7: da.quantity || ' ',
            col8: da.job || ' ',
            col9: da.payrollPeriodID || ' ',
            col10: da.notes || ' ',
          }))
        : [];

    const jobWizardAcivityFormatterData =
      heading === commonGridHeaderNames.JOB_ACTIVITIES &&
      identityTypeId !== IdentityTypeIds.JOB
        ? tableData.map(da => ({
            jobActivityRateID: da.jobActivityRateID,
            col1: da.jobActivityCode,
            col2: da.jobActivityDescription,
            // Business Requirement to show always show 4 decimal places for rates
            col3: `$${da.contractedRate.toFixed(4)}` || ' ',
            col4: `$${da.payrollRate.toFixed(4)}` || ' ',
            col5: { value: da.isDefault, dataType: 'string' },
            col6: da.effectiveFrom,
            col7: da.effectiveTo,
          }))
        : [];

    const jobSupervisorFormatterData =
      heading === commonGridHeaderNames.JOB_SUPERVISORS
        ? tableData
            .filter(f => {
              if (!showActive) return true;
              return (
                isEmpty(get(f, 'toDate')) ||
                isBefore(
                  new Date(),
                  parse(get(f, 'toDate'), 'dd/MM/yyyy', new Date()),
                )
              );
            })
            .map(da => ({
              id: da.relationshipID,
              col1: da.supervisorName,
              col2: da.fromDate,
              col3: da.toDate || ' ',
            }))
        : [];

    const jobEventFormattedData =
      heading === commonGridHeaderNames.JOB_EVENTS &&
      identityTypeId === IdentityTypeIds.JOB
        ? tableData.map(da => ({
            id: da.id,
            col1: da.jobEventAction,
            col2: da.jobEventDate,
          }))
        : [];

    const jobActivitiesFormattedData =
      heading === commonGridHeaderNames.JOB_ACTIVITIES &&
      identityTypeId === IdentityTypeIds.JOB
        ? tableData.map(da => ({
            id: da.jobActivityID,
            col1: da.activityCode,
            col2: da.jobActivityDescription,
            // Business Requirement to show always show 4 decimal places for rates
            col3: da.rowAttribute || ' ',
            col4: `$${da.contractedRate.toFixed(4)}` || ' ',
            col5: {
              value: `$${da.payrollRate.toFixed(4)}`,
              dataType: 'string',
            },
            col6: da.isDefault ? 'YES' : 'NO',
          }))
        : [];

    const jobBlocksFormattedData =
      heading === commonGridHeaderNames.JOB_BLOCKS &&
      identityTypeId === IdentityTypeIds.JOB
        ? tableData.map(da => ({
            id: da.id,
            col1: da.vineyardName || ' ',
            col2: da.blockName || ' ',
            col3: da.selectedUnits || ' ',
            col4: da.completedUnits || '0',
            col5: {
              value: da.status || ' ',
              dataType: 'string',
            },
          }))
        : [];

    const jobBlockRowsFormattedData =
      heading === commonGridHeaderNames.JOB_BLOCK_ROWS &&
      identityTypeId === IdentityTypeIds.JOB
        ? tableData.map(da => ({
            jobBlockRowID: da.jobBlockRowID,
            vineyardBlockRowID: da.vineyardBlockRowID,
            isCheckable: da.canDelete,
            col1: da.rowNumber || ' ',
            col2: da.variety || ' ',
            col3: da.blockRowUnits || '0',
            col4: da.selectedUnits || '0',
            col5: { value: da.selected || 0, dataType: 'boolean' },
            col6: da.notes || ' ',
            col7: da.isSplit ? 'YES' : 'NO',
          }))
        : [];

    const vineyardBlockRowsFormattedData =
      heading === commonGridHeaderNames.VINEYARD_BLOCK_ROWS &&
      identityTypeId === IdentityTypeIds.VINEYARD
        ? tableData.map(da => ({
            id: da.id,
            col1: da?.rowNumber || ' ',
            col2: da?.plantedUnits || ' ',
            col3: da?.actualUnits || ' ',
            col4: da?.postCount || ' ',
            col5: { value: da?.rowLength || 0, dataType: 'string' },
            col6: da?.rowSpecialAttribute || ' ',
            col7: da?.rowComments || ' ',
          }))
        : [];

    const createIdentitySimilarIdentitiesData =
      heading === commonGridHeaderNames.SIMILAR_IDENTITIES && tableData
        ? tableData.map(da => ({
            id: da.IdentityID,
            col1: da?.IdentityID || ' ',
            col2: da?.IdentityName || ' ',
            col3: da?.businessUnit || ' ',
            col4: da?.status || ' ',
          }))
        : [];
    colData = [
      ...contactMethodFormattedData,
      ...classificationFormattedData,
      ...referenceFormattedData,
      ...rolesFormattedData,
      ...relationshipFormatterData,
      ...supplyContractBlocksFormatterData,
      ...workerPayPeriodsFormatterData,
      ...workerWorkRecordsFormatterData,
      ...jobWizardAcivityFormatterData,
      ...jobSupervisorFormatterData,
      ...jobEventFormattedData,
      ...jobActivitiesFormattedData,
      ...jobBlocksFormattedData,
      ...jobBlockRowsFormattedData,
      ...vineyardBlockRowsFormattedData,
      ...createIdentitySimilarIdentitiesData,
    ];
  }

  if (colData.length > 0 && colData.length < 9) {
    const numberOfRows = 9 - colData.length;
    times(numberOfRows, () => {
      colData.push({
        col1: ' ',
        col2: ' ',
        col3: ' ',
        col4: ' ',
      });
    });
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
      data-testid="box-parent-vineacommongrid"
    >
      <Grid container spacing={0} data-testid="grid-parent-vineacommongrid">
        <Grid item xs={12} data-testid="grid-tableheader-vineacommongrid">
          <Paper elevation={0} data-testid="paper-tableheader-vineacommongrid">
            <Box
              display="flex"
              flex={1}
              flexDirection="row"
              justifyContent="space-between"
              p={2}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" color="textPrimary">
                  {t(heading)}
                </Typography>
              </Box>

              <Box>
                <Box display="inline-flex">
                  {hasActiveToggle && (
                    <FormControlLabel
                      control={
                        <GreenSwitch
                          checked={showActive}
                          onChange={() => setShowActive(active => !active)}
                          name="activeOnly"
                        />
                      }
                      label={
                        <Typography variant="caption">
                          {t('Active only')}
                        </Typography>
                      }
                    />
                  )}
                  {hasManageOption && (
                    <VineaButton
                      variant="text"
                      color="secondary"
                      sx={{
                        marginRight: theme.spacing(1),
                        border: '0.5px solid #E0E0E0',
                      }}
                      onClick={manage}
                      data-testid="btn-add"
                    >
                      {t(buttonText)}
                    </VineaButton>
                  )}
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} data-testid="grid-data-vineacommongrid">
          <Paper elevation={0} data-testid="paper-data-vineacommongrid">
            <CommonGridData
              colHeaders={colHeaders}
              data={colData}
              isLoading={isLoading}
              onRowClick={onRowClick}
              onCheckboxClick={onCheckboxClick}
              tableHeight={tableHeight}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

VineaCommonGrid.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  manage: PropTypes.func.isRequired,
  colHeaders: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  hasManageOption: PropTypes.bool,
};

VineaCommonGrid.defaultProps = {
  colHeaders: [],
  tableData: [],
  isLoading: false,
  hasManageOption: true,
};
