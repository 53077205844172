/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled as muiStyled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { CollapseHorizontal } from '../CollapseHorizontal';
import { VineaAutoComplete } from '../ComboBox';
import { SearchGridResults } from '../../containers/Samples/GridResults/SearchGridResults';
import { VineaButton } from '../VineaButton';
import { VineaTextField } from '../TextField';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';

const FlexBox = muiStyled(Box)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
}));

const FlexItemBox = muiStyled(Box)(() => ({
  flex: 1,
  '& > *': {
    flex: 1,
    display: 'flex',
  },
  '& > .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const FilterScreen = ({
  filterType,
  onFilterToggle,
  isFilterOpen,
  search,
  generateFilterData,
  lookupVintages = [],
  lookupRegions = [],
  lookupMeasure = [],
  lookupVintageLatestId,
  selectedRegionId,
  onUpdateFilterData,
  searchFilterData,
  vineyardList = [],
  sampleData = [],
  className,
  handleOnBlockRowClick,
  firstFilterInputRef,
  // url = '',
}) => {
  const { t } = useTranslation();
  const [vintageIDError, setVintageIDError] = useState('');
  const [regionIDError, setRegionIDError] = useState('');
  const [latestVintageId, setLatestVintageId] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const {
    vintageID = 0,
    regionID = 0,
    measureID = 0,
    createdBy = '',
    vineyardID = 0,
  } = searchFilterData || {};

  const getLkpText = (id, lkp) =>
    id === 0 ? [] : [lkp?.find(v => v?.id === id)?.value];

  // map from each filter name to a friendly name/value to show on screen
  const nameMap = {
    vintageID: {
      name: 'Vintages',
      getValueText: v => getLkpText(v, lookupVintages),
    },
    regionID: {
      name: 'Regions',
      getValueText: v => getLkpText(v, lookupRegions),
    },
    measureID: {
      name: 'Measure',
      getValueText: v => getLkpText(v, lookupMeasure),
    },
    createdBy: { name: 'Created By', getValueText: v => [v] },
  };

  useEffect(() => {
    if (lookupVintageLatestId !== 0 && selectedRegionId !== 0) {
      const data = {
        vintageID: lookupVintageLatestId,
        regionID: selectedRegionId,
        measureID: null,
        createdBy: null,
      };
      if (data.vintageID === null || data.vintageID === 0) {
        setVintageIDError('Required');
      } else if (data.regionID === null || data.regionID === 0) {
        setRegionIDError('Required');
      } else {
        setVintageIDError('');
        setRegionIDError('');
        search(data, getFilterText(data));
      }
    }
  }, [lookupVintageLatestId, selectedRegionId]);

  useEffect(() => {
    if (!latestVintageId && !selectedRegion) {
      onUpdateFilterData({
        ...searchFilterData,
        vintageID: lookupVintageLatestId,
        regionID: selectedRegionId,
      });
      setSelectedRegion(selectedRegionId);
      setLatestVintageId(lookupVintageLatestId);
    }
  }, [
    onUpdateFilterData,
    searchFilterData,
    lookupVintageLatestId,
    selectedRegionId,
    setLatestVintageId,
    latestVintageId,
    selectedRegion,
  ]);

  const handleOnSelectVintages = evt => {
    const evtValue = evt.target.value;
    onUpdateFilterData({ ...searchFilterData, vintageID: evtValue });
  };

  const handleOnSelectRegion = evt => {
    const evtValue = evt.target.value;
    onUpdateFilterData({ ...searchFilterData, regionID: evtValue });
  };

  const handleOnSelectMeasure = evt => {
    const evtValue = evt.target.value;
    onUpdateFilterData({ ...searchFilterData, measureID: evtValue });
  };

  const handleOnSelectVineyard = evt => {
    const evtValue = evt.target.value;
    onUpdateFilterData({ ...searchFilterData, vineyardID: evtValue });
    generateFilterData(evtValue);
  };

  const handleOnCreatedBy = evt => {
    const evtValue = evt.target.value;
    onUpdateFilterData({ ...searchFilterData, createdBy: evtValue });
  };

  const getFilterText = data =>
    Object.keys(data)?.map(filterName => {
      const { name, getValueText } = get(nameMap, [filterName], {});
      return name
        ? {
            filterName: name,
            values: getValueText(data[filterName]),
          }
        : {
            filterName,
            values: [data[filterName]],
          };
    });

  const handleOnSearch = () => {
    // null is required to fetch all records
    const data = {
      vintageID: vintageID || lookupVintageLatestId,
      regionID: regionID || selectedRegionId,
      measureID: measureID || null,
      createdBy: createdBy || null,
    };

    if (data.vintageID === null || data.vintageID === 0) {
      setVintageIDError('Required');
    } else if (data.regionID === null || data.regionID === 0) {
      setRegionIDError('Required');
    } else {
      setVintageIDError('');
      setRegionIDError('');
      search(data, getFilterText(data));
    }
  };

  const handleOnClearSearch = () => {
    onUpdateFilterData({
      vintageID: 0,
      regionID: 0,
      measureID: 0,
      createdBy: '',
    });
    const data = {
      vintageID: vintageID || lookupVintageLatestId,
      regionID: selectedRegionId,
      measureID: null,
      createdBy: null,
    };
    search(data, getFilterText(data));
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{
        pb: 3,
        minHeight: '500px',
        '& .MuiCollapse-horizontal': {
          height: '100%',
        },
        '& .MuiCollapse-wrapper': {
          flex: 1,
        },
        '& .MuiInputLabel-outlined': {
          zIndex: 0,
        },
        '& .MuiCollapse-wrapperInner': {
          width: '100%',
        },
      }}
      data-testid="filter-screen"
    >
      <Grid item xs={12} style={{ height: '100%' }}>
        <Paper
          elevation={2}
          style={{ height: '100%' }}
          sx={{
            '& .MuiCollapse-horizontal': {
              height: '100%',
            },
            '& .MuiCollapse-wrapper': {
              flex: 1,
            },
            '& .MuiInputLabel-outlined': {
              zIndex: 0,
            },
            '& .MuiCollapse-wrapperInner': {
              width: '100%',
            },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!isFilterOpen && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                flex: 1,
                '&  .MuiIconButton-root': {
                  flex: 1,
                },
              }}
            >
              <Box
                flex={1}
                flexDirection="row"
                justifyContent="flex-start"
                display="flex"
              >
                <div />
                <div>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      onFilterToggle();
                    }}
                    onMouseDown={() => {}}
                    size="large"
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </div>
              </Box>
            </Box>
          )}
          <>
            <CollapseHorizontal
              orientation="horizontal"
              collapsedSize={48}
              in={isFilterOpen}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="space-between"
                sx={{ height: '100%' }}
              >
                {!!isFilterOpen && (
                  <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{ height: '100%' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      <Box
                        pl={2}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          noWrap
                          data-testid="header-text"
                        >
                          {t('Filters')}
                        </Typography>

                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            onFilterToggle();
                          }}
                          onMouseDown={() => {}}
                          size="large"
                        >
                          <ChevronLeftIcon />
                        </IconButton>
                      </Box>

                      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                        <Box sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
                          <FlexItemBox
                            py={2}
                            px={2}
                            data-testid="searchsamples"
                          >
                            <FormControl error={!!vintageIDError} margin="none">
                              <VineaAutoComplete
                                value={vintageID || lookupVintageLatestId}
                                onChange={handleOnSelectVintages}
                                inlineLabel
                                options={lookupVintages}
                                labelVariant="body1"
                                label={t(nameMap.vintageID.name)}
                                name={t(nameMap.vintageID.name)}
                                displayLabel
                                inputProps={{
                                  name: 'referenceTypeID',
                                }}
                                inputRef={firstFilterInputRef}
                              />
                              {vintageIDError && (
                                <FormHelperText id="component-error-text">
                                  {vintageIDError}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </FlexItemBox>
                        </Box>
                        <Box
                          py={2}
                          px={2}
                          sx={{
                            '& > *': {
                              display: 'flex',
                            },
                            '& > .MuiFormControl-root': {
                              minWidth: 350,
                            },
                          }}
                          data-testid="regions"
                        >
                          <FormControl error={!!regionIDError} margin="none">
                            <VineaAutoComplete
                              value={regionID || selectedRegionId}
                              onChange={handleOnSelectRegion}
                              inlineLabel
                              options={lookupRegions}
                              labelVariant="body1"
                              label={t(nameMap.regionID.name)}
                              name={t(nameMap.regionID.name)}
                              displayLabel
                              inputProps={{
                                name: 'RegionsID',
                              }}
                            />
                            {regionIDError && (
                              <FormHelperText id="component-error-text">
                                {regionIDError}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                        {filterType === 'Sampling' && (
                          <Box
                            py={2}
                            px={2}
                            sx={{
                              '& > *': {
                                display: 'flex',
                              },
                              '& > .MuiFormControl-root': {
                                minWidth: 250,
                              },
                            }}
                            data-testid="measure"
                          >
                            <VineaAutoComplete
                              value={measureID}
                              onChange={handleOnSelectMeasure}
                              inlineLabel
                              options={lookupMeasure}
                              labelVariant="body1"
                              label={t(nameMap.measureID.name)}
                              name={t(nameMap.measureID.name)}
                              displayLabel
                              inputProps={{
                                name: 'measureID',
                              }}
                            />
                          </Box>
                        )}
                        {filterType === 'Tracking' && (
                          <Box
                            py={2}
                            px={2}
                            sx={{
                              '& > *': {
                                display: 'flex',
                              },
                              '& > .MuiFormControl-root': {
                                minWidth: 250,
                              },
                            }}
                            data-testid="measure"
                          >
                            <VineaAutoComplete
                              value={vineyardID}
                              onChange={handleOnSelectVineyard}
                              inlineLabel
                              options={vineyardList}
                              labelVariant="body1"
                              label={t('Vineyard')}
                              name={t('Vineyard')}
                              displayLabel
                              inputProps={{
                                name: 'vineyardID',
                              }}
                            />
                          </Box>
                        )}
                        {filterType === 'Sampling' && (
                          <Box
                            py={2}
                            px={2}
                            sx={{
                              '& > *': {
                                display: 'flex',
                              },
                              '& > .MuiFormControl-root': {
                                minWidth: 350,
                              },
                            }}
                            data-testid="createdby"
                          >
                            <VineaTextField
                              id="outlined-search"
                              label={t(nameMap.createdBy.name)}
                              type="search"
                              variant="outlined"
                              size="small"
                              margin="dense"
                              value={createdBy}
                              onChange={handleOnCreatedBy}
                            />
                          </Box>
                        )}
                        {filterType === 'Tracking' && (
                          <Box display="flex" flex={1} ml={2}>
                            <Paper
                              sx={{ width: '100%' }}
                              data-grid="paper-grid"
                            >
                              <SearchGridResults
                                filterType={filterType}
                                className={className}
                                handleOnRowClick={handleOnBlockRowClick}
                                handleOnCellClick={handleOnSearch}
                                isLoading={false}
                                samplesData={sampleData}
                                hideFooter
                              />
                            </Paper>
                          </Box>
                        )}
                        {(filterType === 'Sampling' ||
                          filterType === 'Tracking') && (
                          <FlexBox
                            py={2}
                            px={2}
                            sx={{ height: '100%', alignItems: 'flex-start' }}
                            data-testid="control-buttons"
                          >
                            <VineaButton
                              aria-label="clear"
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              data-testid="filter-clear"
                              color="secondary"
                              variant="text"
                              onClick={handleOnClearSearch}
                            >
                              {t('Clear')}
                            </VineaButton>
                            <Box px={1} py={1} />
                            <VineaButton
                              aria-label="Search"
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              data-testid="filter-search"
                              color="secondary"
                              variant="outlined"
                              onClick={handleOnSearch}
                            >
                              {t('Search')}
                            </VineaButton>
                          </FlexBox>
                        )}
                      </ErrorBoundary>
                    </Box>
                  </Box>
                )}
              </Box>
            </CollapseHorizontal>
          </>
        </Paper>
      </Grid>
    </Grid>
  );
};

FilterScreen.propTypes = {
  onFilterToggle: PropTypes.func.isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
  lookupVintages: PropTypes.any,
  lookupRegions: PropTypes.any,
  lookupMeasure: PropTypes.any,
  lookupVintageLatestId: PropTypes.number,
  selectedRegionId: PropTypes.number,
  onUpdateFilterData: PropTypes.func.isRequired,
  searchFilterData: PropTypes.any,
  filterType: PropTypes.string,
  vineyardList: PropTypes.any,
  sampleData: PropTypes.any,
  className: PropTypes.any,
  generateFilterData: PropTypes.func,
  handleOnBlockRowClick: PropTypes.func,
  firstFilterInputRef: PropTypes.object.isRequired,
};

FilterScreen.defaultProps = {
  lookupVintages: [],
  lookupRegions: [],
  lookupMeasure: [],
  lookupVintageLatestId: 0,
  selectedRegionId: 0,
  searchFilterData: null,
  filterType: 'Sampling',
  vineyardList: [],
  sampleData: [],
  className: '',
  generateFilterData: f => f,
  handleOnBlockRowClick: f => f,
};

export default FilterScreen;
