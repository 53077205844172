/**
 * id = tabpanelcontentwrapper is used by Dashborad.jsx page for styling
 * className = tabpanel is used by Dashboard.jsx for styling
 */
import React from 'react';
import Box from '@mui/material/Box';

type TabPanelProps = {
  children: React.ReactNode;
  index: string | number;
  value: string | number;
  dir: string;
  dataTestId: string;
};

const TabPanel = ({
  children,
  value,
  index,
  dir,
  dataTestId,
  ...restProps
}: TabPanelProps) => {
  return (
    <Box
      role="tabpanel"
      className="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      dir={dir}
      data-testid={dataTestId}
      {...restProps}
    >
      {value === index && (
        <Box
          p={3}
          id="tabpanelcontentwrapper"
          data-testid={`wrapper-${dataTestId}`}
          height="100%"
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
// };

// TabPanel.defaultProps = {
//   children: null,
// };
export default TabPanel;
