/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
import React from 'react';
// import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { isEmpty, lowerCase } from 'lodash';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

function a11yProps(index: string | number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledRoot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  height: '100%',
  flexWrap: 'wrap',
}));

// values: { xxs: 0, xs: 576, sm: 768, md: 992, lg: 1200,  xl: 1920, },

type TabViewProps = {
  tabHeaders: string[];
  children: React.ReactNode;
  onTabChange: (value: string) => React.ReactNode;
  onSwipe: (value: string | number) => React.ReactNode;
  value?: number;
  dataTestId?: string;
  fixedHeight?: boolean;
  swipeableStyles?: Record<string, any>;
};

const TabView = ({
  tabHeaders,
  children,
  onTabChange,
  onSwipe,
  value,
  dataTestId,
  fixedHeight = false,
  swipeableStyles = {},
}: TabViewProps) => {
  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    // setValue(newValue);
    onTabChange(newValue);
  };

  const handleChangeIndex = (index: string | number) => {
    //     setValue(index);
    onSwipe(index);
  };

  if (!Array.isArray(tabHeaders) || isEmpty(tabHeaders)) {
    return null;
  }

  return (
    <StyledRoot data-testid="tab-view-root">
      <AppBar
        position="static"
        color="default"
        sx={{
          // @ts-ignore
          backgroundColor: theme.palette.white.main,
          zIndex: 1,
          width: '100%',
        }}
        data-testid={dataTestId}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="full width tabs example"
          TabIndicatorProps={{
            style: { transition: 'none' },
          }}
          data-testid={lowerCase(
            `tabs-${dataTestId?.replace(/[^A-Z0-9]/gi, '')}`,
          )}
        >
          {tabHeaders.map((val, index) => (
            <Tab
              label={val}
              key={val}
              {...a11yProps(index)}
              data-testid={lowerCase(
                `tab-${val.replace(/[^A-Z0-9]/gi, '').trim()}`,
              )}
            />
          ))}
        </Tabs>
      </AppBar>
      {/* @ts-ignore */}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={
          fixedHeight ? { minHeight: '500px' } : { flex: 1, ...swipeableStyles }
        }
        data-testid={lowerCase(
          `swipe-${dataTestId?.replace(/[^A-Z0-9]/gi, '')}`,
        )}
      >
        {children}
      </SwipeableViews>
    </StyledRoot>
  );
};

// TabView.propTypes = {
//   tabHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
//   value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   onTabChange: PropTypes.func.isRequired,
//   onSwipe: PropTypes.func.isRequired,
//   children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
// };

// TabView.defaultProps = {
//   value: 0,
// };

export default TabView;
