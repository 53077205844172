import { isValid, format, parse } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import omit from 'lodash/omit';
import { isEmpty, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import first from 'lodash/first';
import get from 'lodash/get';
import {
  VineaHooks,
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { dateFormat } from '../../../constants';
import {
  getVineyardsbyRegionID,
  getSelectedVintageBlockValue,
  getCropBlockVintageEntityData,
  getLkpMeasure,
  getLkpMeasureGroup,
  getLkpPhenologyMeasure,
  getLkpPhenologyMeasureOption,
  getLkpPhenologyMeasureSet,
  getLkpCropSettingOption,
  getLkpCropSettingInstance,
  getLookupVintage,
} from '../../../redux/selectors';
import logger from '../../../utils/winstonLogger';

import { useNoteImageUpdateHook } from './useNoteImageUpdateHook';

export const useTrackingHook = () => {
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [vintageApiLoading, setVintageApiLoading] = useState(false);
  const [phenologyApiLoading, setPhenologyApiLoading] = useState(false);
  const [notesApiLoading, setNotesApiLoading] = useState(false);

  const [actionCropSetttingsTriggered, setActionCropSettings] = useState(false);
  const [errorInSubmitVintage, setErrorInSubmitVintage] = useState(false);
  const [errorInSubmitPhenology, setErrorInSubmitPhenology] = useState(false);
  const [errorInSubmitBlockNote, setErrorInSubmitBlockNote] = useState(false);
  const [phenologyNoteImage, setPhenologyNoteImage] = useState(null);
  const [latestVintageIdValue, setLatestVintageIdValue] = useState(0);
  const [vintageNoteImage, setVintageNoteImage] = useState(null);
  const [cropNoteId, setCropNoteId] = useState(null);
  const [deleteInProgress, setDeleteInProgress] = useState(null);
  const [deleteActionTriggered, setDeleteActionTriggered] =
    React.useState(false);

  const { setNoteImage, setFormData, setIsReadyVintage, setIsReadyPhenology } =
    useNoteImageUpdateHook();

  const handleOnDeleteBulk = selectionModel => {
    dispatchAPI(
      VineaNovaActions.api.v1.cropBlockSetting.delete.request({
        postBody: {
          IDs: selectionModel,
        },
      }),
    );
    setActionCropSettings(true);
  };

  // Selectors
  const { data: searchFilterData = {} } = useSelector(
    state => state.trackingSearchFilter,
  );

  // vintage meta data
  const {
    isLoading: isVintageLoading,
    hasError: hasVintageError,
    isLoaded: isVintageLoaded,
    error: vintageError,
  } = useSelector(VineaNovaSelectors.getCropBlockVintageEntityMeta);

  // phenology meta data
  const {
    isLoading: isPhenologyLoading,
    hasError: hasPhenologyError,
    isLoaded: isPhenologyLoaded,
    error: phenologyError,
  } = useSelector(VineaNovaSelectors.getCropBlockPhenologyEntityMeta);

  // block notes meta data
  const {
    isLoading: isBlockNotesLoading,
    isLoaded: hasBlockNotesLoaded,
    hasError: hasBlockNotesError,
    error: blockNotesError,
  } = useSelector(VineaNovaSelectors.getCropBlockNotesEntityMeta);

  // Crop block settings
  const {
    isLoading: isCropBlockSettingLoading,
    hasError: hasCropBlockSettingError,
    isLoaded: isCropBlockSettingLoaded,
    error: cropBlockSettingError,
  } = useSelector(VineaNovaSelectors.getCropBlockSettingEntityMeta);

  const cropBlockSettingEntityData = useSelector(
    VineaNovaSelectors.getCropBlockSettingEntityData,
  );

  const cropBlockVintageEntityData = useSelector(getCropBlockVintageEntityData);
  const cropBlockPhenologyEntityData = useSelector(
    VineaNovaSelectors.getCropBlockPhenologyEntityData,
  );

  const allLookupVintages = useSelector(getLookupVintage);
  const lkpPhenologyMeasure = useSelector(getLkpPhenologyMeasure);
  const lkpPhenologyMeasureSet = useSelector(getLkpPhenologyMeasureSet);
  const lkpPhenologyMeasureOptions = useSelector(getLkpPhenologyMeasureOption);
  const lkpCropSettingInstance = useSelector(getLkpCropSettingInstance);
  const lkpCropSettingOption = useSelector(getLkpCropSettingOption);

  const lkpMeasures = useSelector(getLkpMeasure);
  const lkpMeasureGroup = useSelector(getLkpMeasureGroup);
  // const lkpMeasureInstance = useSelector(
  //   VineaNovaSelectors.getlookupMeasureInstanceEntityData,
  // );
  const { data: lkpMeasureInstance } = VineaHooks.useFetchlookupMeasureInstance(
    {},
  );
  const vintageBlockSelected = useSelector(getSelectedVintageBlockValue);
  const {
    subRegionID = null,
    vintageID = latestVintageIdValue,
    vineyardBlockId = null,
  } = searchFilterData;

  const vineyardList = useSelector(state =>
    getVineyardsbyRegionID(state, subRegionID),
  );

  logger.debug('idVineyardBlocksSelector');
  logger.debug('lkpMeasureInstance');

  // UseEffects

  React.useEffect(() => {
    if (!isEmpty(allLookupVintages)) {
      const activeVintage = first(allLookupVintages.filter(f => f.isActive));
      const activeVintageID = get(activeVintage, 'id', 0);
      setLatestVintageIdValue(activeVintageID);
      logger.debug('vintage', activeVintage);
    }
  }, [allLookupVintages]);

  React.useEffect(() => {
    if (vintageApiLoading) {
      if (!isVintageLoading && isVintageLoaded) {
        if (hasVintageError) {
          enqueueSnackbar(t('Error'), { variant: 'Error' });
          setErrorInSubmitVintage(vintageError);
        } else {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          setNoteImage(vintageNoteImage);
          setIsReadyVintage(true);
        }
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockVintage.get.request({
            queryParams: {
              // TODO: replace block id
              VineyardBlockID: vineyardBlockId,
              VintageID: vintageID,
            },
          }),
        );

        // fetch notes data when vintage data refreshes
        handleOnFetchNotesData(vineyardBlockId);

        setVintageApiLoading(false);
        setDeleteInProgress(false);
      }
    }
  }, [
    isVintageLoading,
    hasVintageError,
    isVintageLoaded,
    enqueueSnackbar,
    t,
    dispatchAPI,
    setVintageApiLoading,
    vintageApiLoading,
    setErrorInSubmitVintage,
    vintageError,
    vineyardBlockId,
    vintageID,
    handleOnUploadNoteImage,
    vintageNoteImage,
    cropBlockVintageEntityData,
    setNoteImage,
    cropNoteId,
    setIsReadyVintage,
    handleOnFetchNotesData,
  ]);

  React.useEffect(() => {
    if (phenologyApiLoading) {
      if (!isPhenologyLoading && isPhenologyLoaded) {
        if (hasPhenologyError) {
          enqueueSnackbar(t('Error'), { variant: 'Error' });
          setErrorInSubmitVintage(phenologyError);
        } else {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          if (!deleteInProgress) {
            setNoteImage(phenologyNoteImage);
            setIsReadyPhenology(true);
          }
        }
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockPhenology.get.request({
            queryParams: {
              // TODO: replace block id
              VineyardBlockID: vineyardBlockId,
              VintageID: vintageID,
            },
          }),
        );

        // fetch notes data when phenology refreshes
        handleOnFetchNotesData(vineyardBlockId);

        setPhenologyApiLoading(false);
      }
    }
  }, [
    isPhenologyLoading,
    hasPhenologyError,
    isPhenologyLoaded,
    enqueueSnackbar,
    t,
    dispatchAPI,
    setPhenologyApiLoading,
    searchFilterData,
    phenologyApiLoading,
    setErrorInSubmitVintage,
    phenologyError,
    vineyardBlockId,
    vintageID,
    handleOnUploadNoteImage,
    phenologyNoteImage,
    cropNoteId,
    setIsReadyPhenology,
    setNoteImage,
    deleteInProgress,
    handleOnFetchNotesData,
  ]);

  React.useEffect(() => {
    if (notesApiLoading) {
      if (!isBlockNotesLoading && hasBlockNotesLoaded) {
        if (hasBlockNotesError) {
          enqueueSnackbar(t('Error'), { variant: 'Error' });
          setErrorInSubmitBlockNote(blockNotesError);
        } else {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
        }
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockNotes.get.request({
            queryParams: {
              // TODO: replace block id
              VineyardBlockID: vineyardBlockId,
              VintageID: vintageID,
            },
          }),
        );

        handleOnFetchPhenologyData(vineyardBlockId);
        handleOnFetchVintageData(vineyardBlockId);

        setNotesApiLoading(false);
      }
    }
  }, [
    t,
    dispatchAPI,
    enqueueSnackbar,
    notesApiLoading,
    setNotesApiLoading,
    isBlockNotesLoading,
    hasBlockNotesLoaded,
    hasBlockNotesError,
    blockNotesError,
    vineyardBlockId,
    vintageID,
    handleOnFetchPhenologyData,
    handleOnFetchVintageData,
  ]);

  React.useEffect(() => {
    if (actionCropSetttingsTriggered) {
      if (!isCropBlockSettingLoading && isCropBlockSettingLoaded) {
        if (hasCropBlockSettingError) {
          enqueueSnackbar(t('Error'), { variant: 'Error' });
          setErrorInSubmitVintage(cropBlockSettingError);
        } else {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
        }
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockSetting.get.request({
            queryParams: {
              // TODO: replace block id
              VineyardBlockID: vineyardBlockId,
              VintageID: vintageID,
            },
          }),
        );
        setActionCropSettings(false);
      }
    }
  }, [
    isCropBlockSettingLoading,
    hasCropBlockSettingError,
    isCropBlockSettingLoaded,
    enqueueSnackbar,
    t,
    dispatchAPI,
    setActionCropSettings,
    actionCropSetttingsTriggered,
    setErrorInSubmitVintage,
    cropBlockSettingError,
    vineyardBlockId,
    vintageID,
  ]);

  const phenologyMeasureValidate = data => {
    const { phenologyMeasureOptionID = 0 } = data;
    if (phenologyMeasureOptionID < 1 || !phenologyMeasureOptionID) {
      const { phenologyMeasureOptionID: MOID, ...updatedMeasureData } = data;
      return updatedMeasureData;
    }
    return data;
  };
  // fetch specific block vintages;
  const handleOnFetchPhenologyData = React.useCallback(
    paramVineyardBlockId => {
      if (paramVineyardBlockId || vineyardBlockId) {
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockPhenology.get.request({
            queryParams: {
              // TODO: replace block id
              VineyardBlockID: paramVineyardBlockId || vineyardBlockId,
              VintageID: vintageID,
            },
          }),
        );
      }
    },
    [dispatchAPI, vintageID, vineyardBlockId],
  );

  const handleOnFetchNotesData = React.useCallback(
    paramVineyardBlockId => {
      if (paramVineyardBlockId || vineyardBlockId) {
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockNotes.get.request({
            queryParams: {
              VineyardBlockID: paramVineyardBlockId || vineyardBlockId,
              VintageID: vintageID,
            },
          }),
        );
      }
    },
    [dispatchAPI, vintageID, vineyardBlockId],
  );

  // fetch specific block vintages;
  const handleOnFetchVintageData = React.useCallback(
    paramVineyardBlockId => {
      if (paramVineyardBlockId || vineyardBlockId) {
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockVintage.get.request({
            queryParams: {
              // TODO: replace block id
              VineyardBlockID: paramVineyardBlockId || vineyardBlockId,
              VintageID: vintageID,
            },
          }),
        );
      }
    },
    [dispatchAPI, vintageID, vineyardBlockId],
  );

  // fetch specific cropblock settings;
  const handleOnFetchOtherDetailsData = React.useCallback(
    paramVineyardBlockId => {
      if (paramVineyardBlockId || vineyardBlockId) {
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockSetting.get.request({
            queryParams: {
              // TODO: replace block id
              VineyardBlockID: paramVineyardBlockId || vineyardBlockId,
              VintageID: vintageID,
            },
          }),
        );

        setDeleteActionTriggered(false);
      }
    },
    [dispatchAPI, vintageID, vineyardBlockId, deleteActionTriggered],
  );

  /**
   * update or insert crop block vintage record
   */
  const handleOnUpdateVintageData = React.useCallback(
    data => {
      const { isAdd, cropNoteID = 0, imageDeleteId } = data;
      let { note = '', noteImage } = data;
      if ((isNil(note) || note === '') && !isNil(noteImage)) {
        note = 'Image attached';
      }
      /** This form data will be used later to attach notes image */
      const updateData = {
        ...data,
        note,
        measureDate: isValid(parse(data.measureDate, dateFormat, new Date()))
          ? format(parse(data.measureDate, dateFormat, new Date()), dateFormat)
          : null,
      };

      if (imageDeleteId) {
        handleOnDeleteNotesImage(imageDeleteId);
      }

      setFormData(updateData);
      if (!isAdd) {
        setCropNoteId(cropNoteID);
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockVintage.put.request({
            postBody: {
              // TODO: replace block id
              ...omit(updateData, [
                'errors',
                'isEdit',
                'isValuePair',
                'hasChanges',
              ]),
            },
          }),
        );
      } else {
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockVintage.post.request({
            postBody: {
              // TODO: replace block id
              ...omit(updateData, [
                'errors',
                'isEdit',
                'isAdd',
                'vineyardID',
                'isValuePair',
                'hasChanges',
              ]),
            },
          }),
        );
      }
      setVintageApiLoading(true);
    },
    [dispatchAPI, setFormData, setVintageApiLoading],
  );

  /**
   * update or insert crop block vintage record
   */
  const handleOnUpdatePhenologyData = React.useCallback(
    data => {
      const { isAdd, cropNoteID = 0, deleteImageId } = data;
      let { note = '', noteImage } = data;
      if ((isNil(note) || note === '') && !isNil(noteImage)) {
        note = 'Image attached';
      }
      const updateData = phenologyMeasureValidate({
        ...data,
        note,
        measureDate: isValid(parse(data.measureDate, dateFormat, new Date()))
          ? format(parse(data.measureDate, dateFormat, new Date()), dateFormat)
          : null,
      });
      setFormData(updateData);

      if (deleteImageId) {
        handleOnDeleteNotesImage(deleteImageId);
      }

      if (!isAdd) {
        setCropNoteId(cropNoteID);
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockPhenology.put.request({
            postBody: {
              // TODO: replace block id
              ...omit(updateData, ['errors', 'isEdit', 'hasChanges']),
            },
          }),
        );
      } else {
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockPhenology.post.request({
            postBody: {
              // TODO: replace block id
              ...omit(updateData, [
                'errors',
                'isEdit',
                'isAdd',
                'vineyardID',
                'hasChanges',
              ]),
            },
          }),
        );
      }
      setPhenologyApiLoading(true);
    },
    [dispatchAPI, setFormData, setPhenologyApiLoading],
  );

  /**
   * update or insert crop block vintage record
   */
  const handleOnUpdateNotesData = React.useCallback(
    (data, isAdd) => {
      let { note = '', noteImage } = data;

      if ((isNil(note) || note === '') && !isNil(noteImage)) {
        note = 'Image attached';
      }

      const updateData = {
        ...data,
        note,
        measureDate: isValid(parse(data.measureDate, dateFormat, new Date()))
          ? format(parse(data.measureDate, dateFormat, new Date()), dateFormat)
          : null,
      };
      if (!isAdd) {
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockNotes.put.request({
            postBody: { ...omit(updateData, ['hasChanges, isEditable']) },
          }),
        );
      } else {
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockNotes.post.request({
            postBody: { ...omit(updateData, ['hasChanges, isEditable']) },
          }),
        );
      }
      setNotesApiLoading(true);
    },
    [dispatchAPI, setNotesApiLoading],
  );

  const handleOnDeleteCropBlockVintage = data => {
    const { id, ts } = data;
    dispatchAPI(
      VineaNovaActions.api.v1.cropBlockVintage.delete.request({
        postBody: {
          id,
          ts,
        },
      }),
    );
    setVintageApiLoading(true);
    setDeleteInProgress(true);
    setNotesApiLoading(true);
  };

  const handleOnDeleteCropBlockNote = data => {
    const { id, ts } = data;
    dispatchAPI(
      VineaNovaActions.api.v1.cropBlockNotes.delete.request({
        postBody: {
          id,
          ts,
        },
      }),
    );
    setNotesApiLoading(true);
  };

  const handleOnDeleteCropBlockPhenology = data => {
    const { id, ts } = data;
    dispatchAPI(
      VineaNovaActions.api.v1.cropBlockPhenology.delete.request({
        postBody: {
          id,
          ts,
        },
      }),
    );
    setPhenologyApiLoading(true);
  };

  const handleOnUploadNoteImage = React.useCallback(
    (noteId, image) => {
      if (!isEmpty(image)) {
        // eslint-disable-next-line no-undef
        const formData = new FormData();
        formData.append('image', image.raw);
        logger.info('processing image', noteId);
        dispatchAPI(
          VineaNovaActions.api.v1.identityNoteImage.put.request({
            queryParams: { cropNoteId: noteId },
            postBody: formData,
            options: {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formData,
            },
          }),
        );
        logger.info('processing image completed', noteId);
        setPhenologyNoteImage(null);
        setVintageNoteImage(null);
      }
    },
    [dispatchAPI],
  );

  /**
   * update or insert crop block vintage record
   */
  const handleOnUpdateCropSettingsData = React.useCallback(
    data => {
      const { isAdd } = data;
      const updateData = {
        ...data,
      };
      if (!isAdd) {
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockSetting.put.request({
            postBody: {
              // TODO: replace block id
              ...omit(updateData, ['errors', 'isEdit', 'hasChanges']),
            },
          }),
        );
      } else {
        dispatchAPI(
          VineaNovaActions.api.v1.cropBlockSetting.post.request({
            postBody: {
              // TODO: replace block id
              ...omit(updateData, [
                'errors',
                'isEdit',
                'isAdd',
                'vineyardID',
                'hasChanges',
              ]),
            },
          }),
        );
      }
      setActionCropSettings(true);
    },
    [dispatchAPI],
  );

  /** delete notes api image */
  const handleOnDeleteNotesImage = React.useCallback(
    imageDeleteId => {
      dispatchAPI(
        VineaNovaActions.api.v1.identityNoteImage.delete.request({
          postBody: {
            // TODO: replace block id
            id: imageDeleteId, // cropNoteId
          },
        }),
      );
    },
    [dispatchAPI],
  );

  // return hooks data
  return {
    vineyardList,
    searchFilterData,
    handleOnFetchPhenologyData,
    handleOnFetchVintageData,
    handleOnFetchNotesData,
    cropBlockVintageEntityData,
    cropBlockPhenologyEntityData,
    vintageBlockSelected,
    handleOnUpdateVintageData,
    errorInSubmit: errorInSubmitVintage,
    setErrorInSubmit: setErrorInSubmitVintage,
    lkpMeasures,
    lkpMeasureGroup,
    lkpMeasureInstance,
    errorInSubmitPhenology,
    setErrorInSubmitPhenology,
    handleOnUpdatePhenologyData,
    lkpPhenologyMeasure,
    lkpPhenologyMeasureOptions,
    lkpPhenologyMeasureSet,
    phenologyError,
    handleOnDeleteCropBlockVintage,
    handleOnDeleteCropBlockPhenology,
    isLoadingData:
      isVintageLoading || isPhenologyLoading || isBlockNotesLoading,
    phenologyNoteImage,
    vintageNoteImage,
    setPhenologyNoteImage,
    setVintageNoteImage,
    handleOnUploadNoteImage,
    handleOnFetchOtherDetailsData,
    lkpCropSettingOption,
    lkpCropSettingInstance,
    handleOnUpdateCropSettingsData,
    cropBlockSettingEntityData,
    handleOnDeleteNotesImage,
    errorInSubmitBlockNote,
    handleOnUpdateNotesData,
    handleOnDeleteCropBlockNote,
    handleOnDeleteBulk,
  };
};
