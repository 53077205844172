// import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

const httpBackend = new HttpBackend(null, {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
});

i18n
  .use(httpBackend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    keySeparator: false,
    ns: ['translation', 'common'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

export default i18n;
