import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Box, FormControl, FormHelperText, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { VineaAutoComplete } from '../ComboBox';
import { Datepicker } from '../Datepicker';
import { FieldLabelInput } from '../TextField';
import { VineaButton } from '../VineaButton';
import { viewDateFormat } from '../../constants';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 250,
  '& .makeStyles-root-150 .MuiOutlinedInput-adornedEnd': {
    minWidth: '250px',
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 250,
  '& .makeStyles-root-150 .MuiOutlinedInput-adornedEnd': {
    minWidth: '250px',
  },
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

export const RelationshipCreate = ({
  t = f => f,
  formdata = {},
  onChange,
  onBlur,
  relatesToList = {},
  relatedIdentityOptions = {},
  onSave = f => f,
  onCancel = f => f,
  duplicateWarning,
}) => {
  const theme = useTheme();
  const handleOnChange = event => {
    if (onChange) {
      onChange(event, formdata);
    }
  };

  const handleOnBlur = event => {
    if (onBlur) {
      onBlur(event, formdata);
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: '16px',
        }}
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <StyledFormControl
              margin="none"
              error={!!formdata.errors.relatesTo}
            >
              <VineaAutoComplete
                value={formdata.relatesToID}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                options={relatesToList}
                labelVariant="body1"
                label={t('Relationship From')}
                name={t('Relationship From')}
                inputProps={{
                  name: 'relatesTo',
                }}
              />
              {formdata.errors.relatesTo && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.relatesTo}
                </FormHelperText>
              )}
            </StyledFormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledFormControl
              margin="none"
              error={!!formdata.errors.ownerIdentityID}
            >
              <VineaAutoComplete
                value={
                  formdata.direction === 'Owner'
                    ? formdata.dependentIdentityID
                    : formdata.ownerIdentityID
                }
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                inlineLabel={false}
                options={relatedIdentityOptions}
                labelVariant="body1"
                label={t('Dependent/Owner')}
                name={t('Dependent/Owner')}
                inputProps={{
                  name: 'parent',
                }}
              />
              {formdata.errors.ownerIdentityID && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.ownerIdentityID}
                </FormHelperText>
              )}
            </StyledFormControl>
          </Grid>

          {formdata.isAdditionalText && (
            <Grid item xs={12}>
              <StyledFormControl
                margin="none"
                error={!!formdata.errors.relationshipName}
              >
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="relationshipName"
                  name="relationshipName"
                  label={formdata.additionalText}
                  size="small"
                  inlineLabel
                  value={formdata.relationshipName}
                  onChange={handleOnChange}
                />
                {formdata.errors.relationshipName && (
                  <FormHelperText id="component-error-text">
                    {formdata.errors.relationshipName}
                  </FormHelperText>
                )}
              </StyledFormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <StyledFieldLabelInput
              id="comment"
              name="comment"
              label={t('Comments')}
              size="small"
              inlineLabel
              value={formdata.comment}
              onChange={handleOnChange}
              multiline
              rowsMax={4}
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledFormControl
              margin="none"
              error={!!formdata.errors.effectiveFromDate}
            >
              <Datepicker
                sx={{
                  marginTop: theme.spacing(1),
                  minWidth: 250,
                  '& .makeStyles-root-150 .MuiOutlinedInput-adornedEnd': {
                    minWidth: '250px',
                  },
                }}
                disablePast={false}
                id="effectivefrom"
                inputFormat={viewDateFormat}
                placeholder="DD/MM/YYYY"
                variant="outlined"
                size="small"
                value={formdata.effectiveFromDate}
                onChange={handleOnChange}
                name="effectiveFromDate"
                label={t('Effective From')}
                inputProps={{
                  'data-testid': 'effectiveFromDate',
                  'data-name': 'effectiveFromDate',
                }}
              />
              {formdata.errors.effectiveFromDate && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.effectiveFromDate}
                </FormHelperText>
              )}
            </StyledFormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledFormControl
              margin="none"
              error={!!formdata.errors.effectiveToDate}
            >
              <Datepicker
                sx={{
                  marginTop: theme.spacing(1),
                  minWidth: 250,
                  '& .makeStyles-root-150 .MuiOutlinedInput-adornedEnd': {
                    minWidth: '250px',
                  },
                }}
                disablePast={false}
                id="effectiveto"
                inputFormat={viewDateFormat}
                placeholder="DD/MM/YYYY"
                variant="outlined"
                size="small"
                value={formdata.effectiveToDate}
                onChange={handleOnChange}
                name="effectiveToDate"
                label={t('Effective To')}
                inputProps={{
                  'data-testid': 'effectiveToDate',
                  'data-name': 'effectiveToDate',
                }}
              />
            </StyledFormControl>
            {formdata.errors.effectiveToDate && (
              <FormHelperText id="component-error-text">
                {formdata.errors.effectiveToDate}
              </FormHelperText>
            )}
            <Grid>
              {duplicateWarning && (
                <Alert severity="warning">
                  You cannot create a duplicate record. Please, update details
                  or reuse the existing one.
                </Alert>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            spacing={2}
            sx={{
              display: 'flex',
              flex: '1',
            }}
          >
            <Box
              sx={{
                marginRight: theme.spacing(2),
              }}
            >
              <VineaButton
                color="success"
                onClick={() => {
                  onSave(null);
                }}
                variant="contained"
                minWidth={100}
                disabled={duplicateWarning || !isEmpty(formdata.errors)}
              >
                {t('Save')}
              </VineaButton>
            </Box>
            <Box>
              <VineaButton
                onClick={onCancel}
                variant="outlined"
                color="secondary"
                minWidth={100}
              >
                {t('Cancel')}
              </VineaButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

RelationshipCreate.propTypes = {
  t: PropTypes.func.isRequired,
  duplicateWarning: PropTypes.bool.isRequired,
  formdata: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    identityID: PropTypes.number,
    relatesToID: PropTypes.number,
    relatesTo: PropTypes.number,
    relationshipName: PropTypes.string,
    relationshipTypeID: PropTypes.number,
    relationshipFrom: PropTypes.string,
    relationshipType: PropTypes.string,
    ownerIdentityID: PropTypes.number,
    ownerName: PropTypes.string,
    dependentIdentityID: PropTypes.number,
    dependentName: PropTypes.string,
    comment: PropTypes.string,
    isDisplayOnPanel: PropTypes.bool,
    effectiveFromDate: PropTypes.string,
    effectiveToDate: PropTypes.string,
    isEditMode: PropTypes.bool,
    methodType: PropTypes.string,
    isActive: PropTypes.bool,
    dependent: PropTypes.string,
    direction: PropTypes.string,
    isAdditionalText: PropTypes.bool,
    additionalText: PropTypes.string,
    errors: PropTypes.string,
    ts: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  relatesToList: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.number,
    value: PropTypes.string,
    relationshipTypeID: PropTypes.number,
  }),
  relationshipTypeList: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.number,
    value: PropTypes.string,
  }),
  relatedIdentityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

RelationshipCreate.defaultProps = {
  formdata: {},
  onSave: f => f,
  onCancel: f => f,
  onBlur: f => f,
  onChange: f => f,
  relationshipTypeList: {},
  relatesToList: {},
};
