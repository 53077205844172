import { createSelector } from 'reselect';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { isEmpty, keyBy } from 'lodash';

//const getAllReports = VineaNovaSelectors.getReportsEntityData;
const getAllReports = VineaNovaSelectors.getReportFiltersEntityData;

export const getAllReportsSelector = createSelector(
  [getAllReports],
  allReports => {
    if (isEmpty(allReports)) return [];

    return allReports.map(report => ({
      ...report,
      filters:
        report?.filters?.map(filter => ({
          ...filter,
          filterOptions:
            filter?.filterOptions?.map(option => ({
              ...option,
              key: option.id,
              value: option.filterDescription,
            })) || [],
        })) || [],
      defaultFilters: keyBy(
        report?.filters?.map(
          ({ filterParameter, filterOptions, reportFilterID }) => ({
            filterParameter,
            value: isEmpty(filterOptions) ? null : [filterOptions[0]?.id],
            reportFilterID: reportFilterID,
          }),
        ),
        'filterParameter',
      ),
    }));
  },
);
