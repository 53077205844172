/* eslint-disable react/prop-types */
import React from 'react';
import { GridWithFilter } from '../../../components/Grid';
import { dataGridPhenologyColumn } from '../gridColumns';

export const PhenologyGridView = ({
  handleOnRowClick,
  handleOnCellClick,
  isLoading = false,
  data = [],
  className = '',
  ...props
}) => {
  const gridXData = {
    columns: dataGridPhenologyColumn,
    rows: data,
  };

  return (
    <GridWithFilter
      data={gridXData}
      onRowClick={handleOnRowClick}
      onCellClick={handleOnCellClick}
      rowHeight={30}
      hideFooterSelectedRowCount
      autoPageSize
      loading={isLoading}
      className={className}
      // advancedSearch
      {...props}
    />
  );
};
