import React, { useState, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/lab/Skeleton';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { find, get, isEmpty } from 'lodash';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';

import {
  viewDateFormat,
  dateFormat,
  IdentityTypeIds,
  sagaActionTypes,
  reducers,
  apiTypes,
  vineaDetails,
} from '../../../constants';
import { FieldLabelInput } from '../../../components/TextField';
import { VineaAlert } from '../../../components/VineaAlert';
import { VineaButton } from '../../../components/VineaButton';
import { SplitButton } from '../../../components/SplitButton';
import { DiscardDialog } from '../../../components/Dialog';
import { Datepicker } from '../../../components/Datepicker';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { syncValidator } from '../../../utils/validator';
import { DeactivationSchema } from '../validations';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { useIdentityStatus } from '../../../hooks/useIdentityStatus';
import { getAllUsers } from '../../../redux/selectors';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

const StyledFormControlBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 350,
  width: 350,
  '& .MuiFormControl-root': {
    minWidth: 350,
  },
}));

const ManageStatus = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id: pathParam } = useParams();
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();

  const identityTypeId = useIdentityTypeId(vineyardTypeScreenName);
  const deactivate = useIdentityStatus();
  const { data: allUsers } = useSelector(state => getAllUsers(state));
  const personUser = useMemo(
    () => find(allUsers, { identityId: Number(pathParam) }),
    [allUsers],
  );

  const { isLoaded: detailsLoaded, data: { displayName = '' } = {} } =
    useSelector(state => state.entities.identityDetails);

  const {
    isLoaded: statusLoaded,
    isLoading: statusLoading,
    hasError: statusHasError,
  } = useSelector(VineaNovaSelectors.getIdentityStatusEntityMeta);

  const lkpInactiveReason =
    useSelector(VineaNovaSelectors.getGetLookUpEntityData) || {};

  const options = lkpInactiveReason
    .filter(e => e.identityTypeID === identityTypeId)
    .map(e => ({
      id: e.id,
      key: e.id,
      value: e.inactiveReason,
    }));

  const [data, setData] = useState({
    deactivateFromDate: format(new Date(), dateFormat),
    deactivationReason: null,
    comments: null,
    errors: {},
  });

  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [dataHasChanges, setDataHasChanges] = useState(false);
  const [triggerClose, setTriggerClose] = useState(false);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [triggerUpdateUser, setTriggerUpdateUser] = useState(false);

  useEffect(() => {
    if (pathParam) {
      dispatchAPI(
        VineaNovaActions.api.v1.getLookUp.get.request({
          queryParams: {
            LookUpName: 'InactiveReason',
          },
        }),
      );
    }
  }, [pathParam]);

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;

    setData({ ...data, [name]: value });
    setDataHasChanges(true);
  };

  const handleActivationChange = (_event, index) => {
    // we only need to validate if we're deactivating
    if (deactivate) {
      const validationErrors = syncValidator(DeactivationSchema)(data);

      setData({
        ...data,
        errors: validationErrors,
      });

      if (!isEmpty(validationErrors)) {
        return;
      }
    }

    dispatchAPI(
      VineaNovaActions.api.v1.identityStatus.post.request({
        postBody: {
          IdentityID: pathParam,
          IdentityTypeID: identityTypeId,
          InactiveDate: deactivate ? data.deactivateFromDate : null,
          IdentityInactiveReasonID: deactivate ? data.deactivationReason : null,
          InactiveComment: data.comments,
        },
      }),
    );
    setTriggerSubmit(true);

    // the '& CLOSE' button was pressed
    if (index === 0) {
      setTriggerClose(true);
    }
  };

  const handleOnPressCancel = () => {
    if (dataHasChanges) {
      setDiscardDialogOpen(true);
    } else {
      handleOnConfirmCancel();
    }
  };

  const handleOnConfirmCancel = () => {
    navigate(-1);
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  useEffect(() => {
    if (triggerSubmit) {
      if (!statusLoading && statusLoaded) {
        if (statusHasError) {
          enqueueSnackbar(t('Error'), { variant: 'Error' });
          setErrorInSubmit(true);
        } else {
          setDataHasChanges(false);
          if (identityTypeId === IdentityTypeIds.PERSON && deactivate)
            setTriggerUpdateUser(true);
          enqueueSnackbar(t('Success'), { variant: 'Success' });
        }

        setTriggerSubmit(false);

        if (triggerClose) {
          handleOnConfirmCancel();
          setTriggerClose(false);
        }
      }
    }
  }, [
    triggerClose,
    triggerSubmit,
    statusLoading,
    statusLoaded,
    statusHasError,
    setTriggerClose,
    handleOnConfirmCancel,
  ]);

  useEffect(() => {
    if (triggerUpdateUser && !isEmpty(personUser)) {
      const data = {
        id: get(personUser, 'id'),
        firstName: get(personUser, 'firstName'),
        lastName: get(personUser, 'lastName'),
        isUserActive: false,
        isVineaAdmin: false,
        identityId: Number(pathParam),
        userSettings: '',
        userPersonDto: {
          identityID: Number(pathParam),
          knownAs: get(personUser, 'firstName'),
          businessUnitID: get(personUser, 'defaultBusinessUnitID'),
        },
      };
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.user,
          methodType: apiTypes.PUT,
        },
      });
      setTriggerUpdateUser(false);
    }
  }, [triggerUpdateUser]);

  useEffect(() => {
    dispatchAPI({
      type: sagaActionTypes.SEARCH_USERS,
      name: reducers.searchUsers,
      payload: {
        searchName: '',
        searchEmail: '',
        searchIsActive: true,
        searchIsAdmin: false,
      },
    });
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: theme.spacing(2),
      }}
    >
      {!detailsLoaded && (
        <Box width={350} mx={2}>
          <Box my={2}>
            <Skeleton variant="rect" width={350} height={90} />
          </Box>
          <Box my={2}>
            <Skeleton variant="rect" width={350} height={40} />
          </Box>
          <Box my={2}>
            <Skeleton variant="rect" width={350} height={40} />
          </Box>
          <Box my={2}>
            <Skeleton variant="rect" width={350} height={80} />
          </Box>
          <Box my={3}>
            <Skeleton variant="rect" width={350} height={50} />
          </Box>
        </Box>
      )}
      {detailsLoaded && (
        <Grid container spacing={2} data-testid="grid-details">
          <Grid item xs={12}>
            <Box mb={2}>
              <VineaAlert
                isOpen={errorInSubmit}
                onExit={() => setErrorInSubmit(false)}
                alertType="error"
              />
            </Box>
            <Box
              ml={2}
              sx={{ display: 'flex', width: 350, alignItems: 'center' }}
            >
              <Box>
                <Typography variant="h6">
                  {`${t(
                    deactivate ? 'Deactivate' : 'Activate',
                  )} ${displayName}`}
                </Typography>
                <Typography variant="caption" style={{ lineHeight: '8px' }}>
                  {t(
                    'Deactivated items are excluded from reporting and workflows. When reactivated, the item will be visible and retain its previous configurations and data.',
                  )}
                </Typography>
              </Box>
            </Box>

            {deactivate && (
              <>
                <StyledFormControlBox mt={5}>
                  <Datepicker
                    value={data.deactivateFromDate}
                    placeholder={viewDateFormat}
                    name="deactivateFromDate"
                    label={t('Deactivate From')}
                    onChange={handleChange}
                    error={data.errors.deactivateFromDate}
                  />
                </StyledFormControlBox>
                <StyledFormControlBox mt={2}>
                  <VineaAutoComplete
                    value={data.deactivationReason}
                    onChange={handleChange}
                    inlineLabel
                    options={options}
                    label={t('Deactivation Reason')}
                    name="deactivationReason"
                    displayLabel
                    error={data.errors.deactivationReason}
                    inputProps={{
                      name: 'deactivationReason',
                    }}
                  />
                </StyledFormControlBox>
              </>
            )}
            <StyledFormControlBox mt={1}>
              <FieldLabelInput
                name="comments"
                label={t('Comments')}
                size="small"
                inlineLabel
                multiline
                rows={5}
                maxRows={10}
                value={data.comments}
                onChange={handleChange}
              />
            </StyledFormControlBox>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
              }}
            >
              <Box mx={2} mt={3}>
                <SplitButton
                  color={deactivate ? 'error' : 'success'}
                  onClick={handleActivationChange}
                  variant="contained"
                  minWidth={130}
                  data-testid="btnsuccess"
                  options={[
                    deactivate ? 'DEACTIVATE & CLOSE' : 'REACTIVATE & CLOSE',
                    deactivate ? 'DEACTIVATE' : 'REACTIVATE',
                  ]}
                />
              </Box>
              <Box mx={2} mt={3}>
                <VineaButton
                  variant="outlined"
                  color="secondary"
                  onClick={handleOnPressCancel}
                >
                  {t('Close')}
                </VineaButton>
              </Box>
            </Grid>
            <DiscardDialog
              open={discardDialogOpen}
              onClose={handleOnDialogClose}
              handleSaveChanges={() => {
                setDiscardDialogOpen(false);
                handleActivationChange(undefined, 0);
              }}
              handleDiscardChanges={handleOnConfirmCancel}
            />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default ManageStatus;
