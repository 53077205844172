import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { parseISO, format as dateFnsFormat } from 'date-fns';
import { IdentityTypeIds, viewDateFormat } from '../../constants';

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  flex: 'none',
  marginRight: theme.spacing(3),
}));

export default function IdentityDetailsExt3({ identityOverviewDetails }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    isPersonIdentity,
    birthDate,
    language,
    address,
    subregion,
    phone,
    identityTypeID,
    payrollSystem,
    connectionType,
    provider,
    lastUpdatedDate,
    activityWorkUnit,
    activityCostingUnit,
    activityDuration,
    businessUnit,
    planStartDate,
    planEndDate,
    planName,
    activityColor,
    supplyContractPhone,
    supplyContractAddress,
    supplyContractCurrentContractedArea,
    supplyContractSubRegions,
    supplyContractContractedVineyards,
    poNumber,
    jobInvoiceMethodMapValue,
    jobInvoiceToIdentity,
    jobLatestEvent,
    scheduledStartDate,
    tonnageWeight,
    tareWeight,
    vin,
    gpS_ID,
    hubOdometerNo,
    formattedDateOfPurchase,
  } = identityOverviewDetails;
  return (
    <List
      sx={{
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        paddingTop: 0,
        paddingBottom: 0,
        '& .MuiListItem-root': {
          alignItems: 'flex-start',
        },
      }}
      dense
    >
      {isPersonIdentity && (
        <>
          <ListItem>
            <StyledListItemText id="identitydob">
              <Typography variant="body2">
                {`${t('Date of Birth')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="dob">
                {birthDate}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="Address">
              <Typography variant="body2">{`${t('Language(s)')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="language">
                {language}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="phone">
              <Typography variant="body2">{`${t('Phone')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="phone">
                {phone}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {!isEmpty(planName) && (
        <>
          <ListItem>
            <StyledListItemText id="accommodation-businesUnit">
              <Typography variant="body2">
                {`${t('Business Unit')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                align="left"
                data-testid="accommodation-businessUnit"
              >
                {businessUnit}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="plan-start">
              <Typography variant="body2">{`${t('Start Date')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="plan-start">
                {planStartDate}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="plan-end">
              <Typography variant="body2">{`${t('End Date')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" align="left" data-testid="plan-end">
                {planEndDate}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.VINEYARD && (
        <>
          <ListItem>
            <StyledListItemText id="subregion">
              <Typography variant="body2">{`${t('Sub Region')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="subregion">
                {subregion}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="Address">
              <Typography variant="body2">{`${t('Address')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="address">
                {address}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="phone">
              <Typography variant="body2">{`${t('Phone')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="phone">
                {phone}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.ORGANISATION && (
        <>
          <ListItem>
            <StyledListItemText id="subregion">
              <Typography variant="body2">{`${t('Sub Region')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="subregion">
                {subregion}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="Address">
              <Typography variant="body2">{`${t('Address')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="address">
                {address}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.SERVICE_PROVIDER && (
        <>
          <ListItem>
            <ListItemText id="payroll">
              <Typography
                variant="body2"
                sx={{
                  flex: 'none',
                  marginRight: theme.spacing(3),
                }}
              >
                {`${t('Payroll System')}:`}
              </Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="payroll">
                {payrollSystem}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText id="Address">
              <Typography
                variant="body2"
                sx={{
                  flex: 'none',
                  marginRight: theme.spacing(3),
                }}
              >
                {`${t('Primary Address')}:`}
              </Typography>
            </ListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="address">
                {address}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.DATA_CONNECTION && (
        <>
          <ListItem>
            <StyledListItemText id="payroll">
              <Typography variant="body2">
                {`${t('Connection Type')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="payroll">
                {connectionType}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="Address">
              <Typography variant="body2">
                {`${t('Last Updated Date')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="address">
                {lastUpdatedDate
                  ? dateFnsFormat(parseISO(lastUpdatedDate), viewDateFormat)
                  : ''}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.ACTIVITY && (
        <>
          <ListItem>
            <StyledListItemText id="activityWorkUnit">
              <Typography variant="body2">
                {`${t('Activity Work Unit')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="workUnit">
                {activityWorkUnit}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="activityCostingUnit">
              <Typography variant="body2">
                {`${t('Activity Cost Unit')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="costingUnit">
                {activityCostingUnit}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="activityDuration">
              <Typography variant="body2">
                {`${t('Duration Unit')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="durationUnit">
                {activityDuration}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="activityDuration">
              <Typography variant="body2">{`${t('Color')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="activityColor">
                {activityColor}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.SUPPLY_CONTRACT && (
        <>
          <ListItem>
            <StyledListItemText id="supplycontract-phone">
              <Typography variant="body2">{`${t('Phone')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="supplycontract-phone">
                {supplyContractPhone}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="supplycontract-address">
              <Typography variant="body2">{`${t('Address')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="supplycontract-address">
                {supplyContractAddress}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="supplycontract-contractedarea">
              <Typography variant="body2">
                {`${t('Current Contracted area')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                data-testid="supplycontract-contractedarea"
              >
                {supplyContractCurrentContractedArea}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="supplycontract-subregions">
              <Typography variant="body2">{`${t(
                'Sub Region(s)',
              )}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                data-testid="supplycontract-subregions"
              >
                {supplyContractSubRegions}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="supplycontract-vineyards">
              <Typography variant="body2">{`${t(
                'Contracted Vineyards',
              )}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                data-testid="supplycontract-vineyards"
              >
                {supplyContractContractedVineyards}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}

      {identityTypeID === IdentityTypeIds.JOB && (
        <>
          <ListItem>
            <StyledListItemText id="jobInvoiceMethodMapValue">
              <Typography variant="body2">
                {`${t('Invoice Method')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography
                variant="body1"
                data-testid="jobInvoiceMethodMapValue"
              >
                {jobInvoiceMethodMapValue}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="scheduledStartDate">
              <Typography variant="body2">
                {`${t('Scheduled Start Date')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="scheduledStartDate">
                {scheduledStartDate}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="poNumber">
              <Typography variant="body2">
                {`${t('Purchase Order')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="poNumber">
                {poNumber}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="jobInvoiceToIdentity">
              <Typography variant="body2">{`${t('Invoice To')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="jobInvoiceToIdentity">
                {jobInvoiceToIdentity}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="jobLatestEvent">
              <Typography variant="body2">{`${t('Latest Event')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="jobLatestEvent">
                {jobLatestEvent}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
      {identityTypeID === IdentityTypeIds.VEHICLE && (
        <>
          <ListItem>
            <StyledListItemText id="tonnageWeight">
              <Typography variant="body2">
                {`${t('Tonnage Weight')}:`}
              </Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="tonnageWeight">
                {tonnageWeight || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="tareWeight">
              <Typography variant="body2">{`${t('Tare Weight')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="tareWeight">
                {tareWeight || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="vin">
              <Typography variant="body2">{`${t('VIN')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="vin">
                {vin || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="gpS_ID">
              <Typography variant="body2">{`${t('GPS ID')}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="gpS_ID">
                {gpS_ID || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="hubOdometerNo">
              <Typography variant="body2">{`${t(
                'Hub Odometer No',
              )}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="hubOdometerNo">
                {hubOdometerNo || 'N/A'}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <StyledListItemText id="formattedDateOfPurchase">
              <Typography variant="body2">{`${t(
                'Date Of Purchase',
              )}:`}</Typography>
            </StyledListItemText>
            <ListItemText>
              <Typography variant="body1" data-testid="formattedDateOfPurchase">
                {formattedDateOfPurchase}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
    </List>
  );
}

IdentityDetailsExt3.propTypes = {
  identityOverviewDetails: PropTypes.shape({
    id: PropTypes.number,
    identityTypeID: PropTypes.number,
    isPersonIdentity: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    knownAs: PropTypes.string,
    displayName: PropTypes.string,
    plantedArea: PropTypes.string,
    totalArea: PropTypes.string,
    birthDate: PropTypes.string,
    ethnicity: PropTypes.string,
    nationality: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    subregion: PropTypes.string,
    address: PropTypes.string,
    roles: PropTypes.string,
    language: PropTypes.string,
    phone: PropTypes.string,
    connectionType: PropTypes.string,
    provider: PropTypes.string,
    lastUpdatedDate: PropTypes.string,
    isPrimary: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    payrollSystem: PropTypes.string,
    supplyContractPhone: PropTypes.string,
    supplyContractAddress: PropTypes.string,
    supplyContractCurrentContractedArea: PropTypes.string,
    supplyContractSubRegions: PropTypes.string,
    supplyContractContractedVineyards: PropTypes.string,
  }),
};

IdentityDetailsExt3.defaultProps = {
  identityOverviewDetails: {},
};
