/* eslint-disable */
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { find, get, isNil, map } from 'lodash';
import { GridWithFilter, RenderDataGridHeader } from '../../components/Grid';
import { IdentityTypeIds } from '../../constants';
import { formatAsViewDateFormat } from '../../constants/formatter';
import {
  areLookupsLoading,
  getAuthenticationResult,
  getUserPreferences,
  getGrowerSubRegion,
} from '../../redux/selectors';

const RenderCell = params => {
  const { value } = params;
  return value;
};

const personColumnHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'string',
    width: 100,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'name',
    headerName: 'Full Name',
    flex: 1.3,
    width: 200,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'knownAs',
    headerName: 'Known As',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 2,
    height: 50,
    hideable: false,
    renderCell: RenderCell,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'businessUnit',
    headerName: 'Business Unit',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
];

const orgColumnHeaders = [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    flex: 1.3,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'knownAs',
    headerName: 'Known As',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'subRegion',
    headerName: 'Region',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 2,
    height: 50,
    renderCell: RenderCell,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'businessUnit',
    headerName: 'Business Unit',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'roles',
    headerName: 'Roles',
    width: 100,
    renderHeader: RenderDataGridHeader,
  },
];

const vineyardColumnHeaders = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 200,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'knownAs',
    headerName: 'known As',
    flex: 1,
    minWidth: 0,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'subRegion',
    headerName: 'Sub-Region',
    flex: 1,
    minWidth: 0,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    minWidth: 0,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 0,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 1,
    minWidth: 0,
    height: 50,
    renderCell: RenderCell,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'businessUnit',
    headerName: 'Business Unit',
    flex: 1,
    minWidth: 0,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'primaryContact',
    headerName: 'Primary Contact',
    flex: 1,
    maxWidth: 100,
    minWidth: 0,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'totalArea',
    headerName: 'Total Area',
    type: 'number',
    flex: 1,
    maxWidth: 100,
    minWidth: 0,
    textAlignRight: true,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'plantedArea',
    headerName: 'Planted',
    type: 'number',
    textAlignRight: true,
    flex: 1,
    maxWidth: 100,
    minWidth: 0,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'numberOfBlocks',
    headerName: 'Blocks',
    type: 'number',
    textAlignRight: true,
    flex: 1,
    maxWidth: 100,
    minWidth: 0,
    renderHeader: RenderDataGridHeader,
  },
];

const siteColumnHeaders = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 200,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'knownAs',
    headerName: 'known As',
    flex: 1,
    minWidth: 0,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'siteType',
    headerName: 'Site Type',
    flex: 1,
    minWidth: 0,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'subRegion',
    headerName: 'Sub-Region',
    flex: 1,
    minWidth: 0,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    minWidth: 0,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 0,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 1,
    minWidth: 0,
    height: 50,
    renderCell: RenderCell,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'businessUnit',
    headerName: 'Business Unit',
    flex: 1,
    minWidth: 0,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'primaryContact',
    headerName: 'Primary Contact',
    flex: 1,
    maxWidth: 100,
    minWidth: 0,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'totalArea',
    headerName: 'Total Area',
    type: 'number',
    flex: 1,
    maxWidth: 100,
    minWidth: 0,
    textAlignRight: true,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'plantedArea',
    headerName: 'Planted',
    type: 'number',
    textAlignRight: true,
    flex: 1,
    maxWidth: 100,
    minWidth: 0,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'numberOfBlocks',
    headerName: 'Blocks',
    type: 'number',
    textAlignRight: true,
    flex: 1,
    maxWidth: 100,
    minWidth: 0,
    renderHeader: RenderDataGridHeader,
  },
];

const serviceProviderColumnHeaders = [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    flex: 1.3,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'knownAs',
    headerName: 'Known As',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'subRegion',
    headerName: 'Region',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 2,
    height: 50,
    renderCell: RenderCell,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'businessUnit',
    headerName: 'Business Unit',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'isPrimary',
    headerName: 'Primary Provider',
    type: 'number',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'payrollSystem',
    headerName: 'Payroll System',
    type: 'string',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
];

const jobColumnHeaders = [
  {
    field: 'id',
    headerName: 'Job ID',
    flex: 0.5,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'name',
    headerName: 'Job Name',
    width: 200,
    flex: 2,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'jobType',
    headerName: 'Type',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'jobStatus',
    headerName: 'Status',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'location',
    headerName: 'Location',
    flex: 1,
    height: 50,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'payer',
    headerName: 'Payer',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    type: 'string',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    type: 'string',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
];

const jobSiteColumnHeaders = [
  {
    field: 'id',
    headerName: 'Job ID',
    flex: 0.5,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'name',
    headerName: 'Job Name',
    width: 200,
    flex: 2,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'siteType',
    headerName: 'Site',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'jobType',
    headerName: 'Type',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'jobStatus',
    headerName: 'Status',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'location',
    headerName: 'Location',
    flex: 1,
    height: 50,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'payer',
    headerName: 'Payer',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    type: 'string',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    type: 'string',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
];

const dataConnectionColumnHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'string',
    width: 100,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'name',
    headerName: 'Connection Name',
    type: 'string',
    width: 200,
    resizable: false,
    textAlignRight: true,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'providerType',
    headerName: 'Data Provider Type',
    type: 'string',
    width: 250,
    textAlignRight: true,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'dataProviderName',
    headerName: 'Provider',
    type: 'string',
    width: 250,
    textAlignRight: true,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lastActive',
    headerName: 'Last Active',
    type: 'string',
    width: 250,
    textAlignRight: true,
    renderHeader: RenderDataGridHeader,
  },
];

const consumableColumnHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1.3,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1.3,
    width: 200,
    resizable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'consumableDescription',
    headerName: 'Description',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'consumableType',
    headerName: 'Type',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
];

const accommodationColumnHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1.3,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1.3,
    width: 200,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'capacity',
    headerName: 'Capacity',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
];

const activityColumnHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1.3,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'activityCode',
    headerName: 'Activity Code',
    flex: 1.3,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'activityDescription',
    headerName: 'Description',
    flex: 1.3,
    width: 200,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'activityGroup',
    headerName: 'Activity Group',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'workUnit',
    headerName: 'Work Unit',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'resourceUnit',
    headerName: 'Resource Unit Type',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'velocityDuration',
    headerName: 'Duration Unit',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'payrollCode',
    headerName: 'Payroll Code',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'accountCode',
    headerName: 'Account Code',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'costCentre',
    headerName: 'Cost Centre',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
];

const vehicleColumnHeaders = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1.3,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'vehicleType',
    headerName: 'Type',
    flex: 1.3,
    width: 200,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'vehicleMake',
    headerName: 'Make',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'registrationNo',
    headerName: 'Registration No.',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'vehicleCapacity',
    headerName: ' Capacity',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
];

export const IdentitySearchResults = ({
  identities = {},
  identityTypeID,
  filterText,
  onFilterOpen,
  vineyardTypeScreenName = 'vineyard',
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const userPreferences = useSelector(state => getUserPreferences(state));
  const { filterPanelCollapsed } = userPreferences.basicSettings;
  const { data = [], isLoading } = identities;
  // Check if worker page
  const { pathname } = useLocation();
  const isWorker = pathname.includes('worker');

  // Media Quries for Responsive Columns
  const xlDown = useMediaQuery('(min-width:1400px)');
  const lDown = useMediaQuery('(max-width:1400px)');
  const lUp = useMediaQuery(theme.breakpoints.up('lg'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const lgUp = useMediaQuery(theme.breakpoints.up('md'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('sm'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [lColumns, setlColumns] = React.useState({});
  const [lgColumns, setLgColumns] = React.useState({});
  const [mdColumns, setMdColumns] = React.useState({});
  const [lColumnsPresent, setlColumnsPresent] = React.useState(false);
  const [lgColumnsPresent, setLgColumnsPresent] = React.useState(false);
  const [mdColumnsPresent, setMdColumnsPresent] = React.useState(false);

  const [dispatchActionType, setDispatchActionType] = React.useState('');
  const [gridColumnState, setGridColumnState] = React.useState({});
  const [visiblePreferences, setVisiblePreferences] = React.useState({});
  const payrollSystemLookup = useSelector(
    VineaNovaSelectors.getlookupPayrollSystemEntityData,
  );

  const subRegionLookup = useSelector(getGrowerSubRegion);

  const rows = React.useMemo(() => {
    if (identityTypeID === IdentityTypeIds.SERVICE_PROVIDER) {
      return map(data, option => ({
        ...option,
        isPrimary: option.isPrimary ? 'Yes' : 'No',
        payrollSystem: get(
          find(payrollSystemLookup, f => f?.id === option?.payrollSystemID),
          'payrollSystem',
          '',
        ),
      }));
    }

    if (identityTypeID === IdentityTypeIds.VINEYARD) {
      return map(data, option => ({
        ...option,
        subRegion: get(
          find(subRegionLookup, f => f?.id === option?.subRegionID),
          'growingSubRegion',
          '',
        ),
      }));
    }

    if (identityTypeID === IdentityTypeIds.DATA_CONNECTION) {
      return map(data, option => ({
        ...option,
        lastActive: formatAsViewDateFormat(option?.lastActive),
      }));
    }

    return data || [];
  }, [data, identityTypeID, payrollSystemLookup, subRegionLookup]);

  const visibleColumnSize = {
    lColumSize: {
      notFlterPanelCollapsed: 7,
      filterPanelCollapsed: 9,
    },
    lgColumnSize: {
      notFlterPanelCollapsed: 4,
      filterPanelCollapsed: 6,
    },
    mdColumnSize: {
      notFlterPanelCollapsed: 3,
      filterPanelCollapsed: 4,
    },
    smColumnSize: { notFlterPanelCollapsed: 2, filterPanelCollapsed: 3 },
  };

  const reduceNoOfColumns = (
    noOfColumns,
    visibleColumns,
    setSizeColumns,
    setColumnsPresentState,
  ) => {
    const newVisibleColumns = visibleColumns
      .slice(noOfColumns)
      .reduce((acc, key) => ({ ...acc, [key]: false }), {});
    if (setSizeColumns) {
      setSizeColumns({ ...gridColumnState, ...newVisibleColumns });
      setColumnsPresentState(true);
    }
    setGridColumnState({ ...gridColumnState, ...newVisibleColumns });
    dispatchAPI({
      type: dispatchActionType,
      payload: { ...gridColumnState, ...newVisibleColumns },
    });
  };

  const increaseNoOfColumns = (columns, lColumnsPresent) => {
    if (lColumnsPresent) {
      setGridColumnState(columns);
      dispatchAPI({
        type: dispatchActionType,
        payload: columns,
      });
    }
  };

  React.useEffect(() => {
    if (identityTypeID === IdentityTypeIds.PERSON) {
      setDispatchActionType('PERSON_GRID_COLUMN_UPDATE');
      setGridColumnState(userPreferences.personDataGrid);
      setVisiblePreferences(userPreferences.personDataGrid);
    } else if (identityTypeID === IdentityTypeIds.VINEYARD) {
      if (vineyardTypeScreenName === 'site') {
        setDispatchActionType('SITE_GRID_COLUMN_UPDATE');
        setGridColumnState(userPreferences.siteDataGrid);
        setVisiblePreferences(userPreferences.siteDataGrid);
      } else {
        setDispatchActionType('VINEYARD_GRID_COLUMN_UPDATE');
        setGridColumnState(userPreferences.vineyardDataGrid);
        setVisiblePreferences(userPreferences.vineyardDataGrid);
      }
    } else if (identityTypeID === IdentityTypeIds.ORGANISATION) {
      setDispatchActionType('ORGANISATION_GRID_COLUMN_UPDATE');
      setGridColumnState(userPreferences.organisationDataGrid);
      setVisiblePreferences(userPreferences.organisationDataGrid);
    } else if (identityTypeID === IdentityTypeIds.SERVICE_PROVIDER) {
      setDispatchActionType('SERVICE_PROVIDER_GRID_COLUMN_UPDATE');
      setGridColumnState(userPreferences.serviceProviderDataGrid);
      setVisiblePreferences(userPreferences.serviceProviderDataGrid);
    } else if (identityTypeID === IdentityTypeIds.JOB) {

      if (vineyardTypeScreenName === 'site') {
        setDispatchActionType('JOB_SITE_GRID_COLUMN_UPDATE');
        setGridColumnState(userPreferences.siteDataGrid);
        setVisiblePreferences(userPreferences.siteDataGrid);
      } else {
        setDispatchActionType('JOB_GRID_COLUMN_UPDATE');
        setGridColumnState(userPreferences.jobDataGrid);
        setVisiblePreferences(userPreferences.jobDataGrid);
      }


    } else if (identityTypeID === IdentityTypeIds.ACTIVITY) {
      setDispatchActionType('ACTIVITY_GRID_COLUMN_UPDATE');
      setGridColumnState(userPreferences.activityDataGrid);
      setVisiblePreferences(userPreferences.activityDataGrid);
    } else if (identityTypeID === IdentityTypeIds.DATA_CONNECTION) {
      setDispatchActionType('SENSORS_GRID_COLUMN_UPDATE');
      setGridColumnState(userPreferences.sensorsDataGrid);
    } else if (identityTypeID === IdentityTypeIds.VEHICLE) {
      setDispatchActionType('VEHICLE_GRID_COLUMN_UPDATE');
      setGridColumnState(userPreferences.vehicleDataGrid);
      setVisiblePreferences(userPreferences.vehicleDataGrid);
    }
  }, [identityTypeID]);

  React.useEffect(() => {
    if (xlDown) {
      dispatchAPI({
        type: dispatchActionType,
        payload: visiblePreferences,
      });
    }
    if (lDown && !lColumnsPresent && !isNil(gridColumnState)) {
      const visibleColumns = Object.keys(gridColumnState);
      const visibleColumnsLength = Object.keys(visibleColumns).length;
      if (filterPanelCollapsed) {
        if (
          visibleColumnsLength >
          visibleColumnSize.lColumSize.filterPanelCollapsed
        ) {
          reduceNoOfColumns(
            visibleColumnSize.lColumSize.filterPanelCollapsed,
            visibleColumns,
            setlColumns,
            setlColumnsPresent,
          );
        }
      } else {
        if (
          visibleColumnsLength >
          visibleColumnSize.lColumSize.notFlterPanelCollapsed
        ) {
          reduceNoOfColumns(
            visibleColumnSize.lColumSize.notFlterPanelCollapsed,
            visibleColumns,
            setlColumns,
            setlColumnsPresent,
          );
        }
      }
    } else if (lDown && lColumnsPresent) {
      increaseNoOfColumns(lColumns, lColumnsPresent);
      setlColumnsPresent(false);
    }
    if (lgDown && !lgColumnsPresent) {
      const visibleColumns = Object.keys(gridColumnState);
      const visibleColumnsLength = Object.keys(visibleColumns).length;
      if (filterPanelCollapsed) {
        if (
          visibleColumnsLength >
          visibleColumnSize.lgColumnSize.filterPanelCollapsed
        ) {
          reduceNoOfColumns(
            visibleColumnSize.lgColumnSize.filterPanelCollapsed,
            visibleColumns,
            setLgColumns,
            setLgColumnsPresent,
          );
        }
      } else {
        if (
          visibleColumnsLength >
          visibleColumnSize.lgColumnSize.notFlterPanelCollapsed
        ) {
          reduceNoOfColumns(
            visibleColumnSize.lgColumnSize.notFlterPanelCollapsed,
            visibleColumns,
            setLgColumns,
            setLgColumnsPresent,
          );
        }
      }
    } else if (lgUp && lgColumnsPresent) {
      increaseNoOfColumns(lgColumns);
      setLgColumnsPresent(false);
    }
    if (mdDown && !mdColumnsPresent) {
      const visibleColumns = Object.keys(gridColumnState);
      const visibleColumnsLength = Object.keys(visibleColumns).length;
      if (filterPanelCollapsed) {
        if (
          visibleColumnsLength >
          visibleColumnSize.mdColumnSize.filterPanelCollapsed
        ) {
          reduceNoOfColumns(
            visibleColumnSize.mdColumnSize.filterPanelCollapsed,
            visibleColumns,
            setMdColumns,
            setMdColumnsPresent,
          );
        }
      } else {
        if (
          visibleColumnsLength >
          visibleColumnSize.mdColumnSize.notFlterPanelCollapsed
        ) {
          reduceNoOfColumns(
            visibleColumnSize.mdColumnSize.notFlterPanelCollapsed,
            visibleColumns,
            setMdColumns,
            setMdColumnsPresent,
          );
        }
      }
    }
    if (mdUp && mdColumnsPresent) {
      increaseNoOfColumns(mdColumns);
      setMdColumnsPresent(false);
    }
    if (smDown) {
      const visibleColumns = Object.keys(gridColumnState);
      const visibleColumnsLength = Object.keys(visibleColumns).length;
      if (filterPanelCollapsed) {
        if (visibleColumnsLength > 3) {
          reduceNoOfColumns(3, visibleColumns);
        }
      } else {
        if (visibleColumnsLength > 2) {
          reduceNoOfColumns(2, visibleColumns);
        }
      }
    }
  }, [
    xlDown,
    lDown,
    lUp,
    lgDown,
    lgUp,
    mdDown,
    mdUp,
    smDown,
    filterPanelCollapsed,
  ]);

  let gridDataColums = [];
  if (identityTypeID === IdentityTypeIds.ORGANISATION)
    gridDataColums = orgColumnHeaders;
  if (identityTypeID === IdentityTypeIds.CONSUMABLE)
    gridDataColums = consumableColumnHeaders;
  if (identityTypeID === IdentityTypeIds.ACCOMMODATION)
    gridDataColums = accommodationColumnHeaders;
  if (identityTypeID === IdentityTypeIds.PERSON)
    gridDataColums = personColumnHeaders;
  if (identityTypeID === IdentityTypeIds.VINEYARD) {
    if (vineyardTypeScreenName === 'site') gridDataColums = siteColumnHeaders;
    else gridDataColums = vineyardColumnHeaders;
  }
  if (identityTypeID === IdentityTypeIds.SERVICE_PROVIDER)
    gridDataColums = serviceProviderColumnHeaders;
  if (identityTypeID === IdentityTypeIds.JOB) {
    if (vineyardTypeScreenName === 'site')
      gridDataColums = jobSiteColumnHeaders;
    else gridDataColums = jobColumnHeaders;
  }
  if (identityTypeID === IdentityTypeIds.DATA_CONNECTION)
    gridDataColums = dataConnectionColumnHeaders;
  if (identityTypeID === IdentityTypeIds.ACTIVITY)
    gridDataColums = activityColumnHeaders;
  if (identityTypeID === IdentityTypeIds.VEHICLE)
    gridDataColums = vehicleColumnHeaders;
  const areLookupLoading = useSelector(areLookupsLoading);
  const { isLoading: authenticationIsLoading } = useSelector(
    getAuthenticationResult,
  );

  const gridXData = { columns: gridDataColums, rows };

  const handleOnClick = evt => {
    if (identityTypeID === IdentityTypeIds.PERSON && !isWorker) {
      navigate(`/person/${evt.id}`);
    } else if (identityTypeID === IdentityTypeIds.PERSON && isWorker) {
      navigate(`/worker/${evt.id}`);
    } else if (identityTypeID === IdentityTypeIds.VINEYARD) {
      navigate(`/organisation/${vineyardTypeScreenName}s/${evt.id}`);
    } else if (identityTypeID === IdentityTypeIds.SERVICE_PROVIDER) {
      navigate(`/organisation/serviceproviders/${evt.id}`);
    } else if (identityTypeID === IdentityTypeIds.JOB) {
      navigate(`/job/${evt.id}`);
    } else if (identityTypeID === IdentityTypeIds.DATA_CONNECTION) {
      navigate(`/connections/${evt.id}`);
    } else if (identityTypeID === IdentityTypeIds.CONSUMABLE) {
      navigate(`/consumable/${evt.id}`);
    } else if (identityTypeID === IdentityTypeIds.ACCOMMODATION) {
      navigate(`/accommodation/${evt.id}`);
    } else if (identityTypeID === IdentityTypeIds.ORGANISATION) {
      navigate(`/organisation/other-organisations/${evt.id}`);
    } else if (identityTypeID === IdentityTypeIds.ACTIVITY) {
      navigate(`/activity/${evt.id}`);
    } else if (identityTypeID === IdentityTypeIds.VEHICLE) {
      navigate(`/vehicle/${evt.id}`);
    }
  };

  return (
    <Grid
      container
      spacing={0}
      data-testid="organisation-results"
      direction="column"
      pr={2}
      pb={2}
    >
      <Grid
        item
        xs={12}
        sx={{
          flex: 1,
        }}
      >
        <Paper
          sx={{
            width: '100%',
            height: '100%',
            overflowX: 'auto',
            '& .MuiDataGrid-cell': {
              padding: '0 5px',
            },
            '& .MuiDataGrid-colCell': {
              padding: '0 5px',
            },
          }}
        >
          <GridWithFilter
            data={gridXData}
            onRowClick={handleOnClick}
            rowHeight={30}
            loading={areLookupLoading || isLoading || authenticationIsLoading}
            headerHeight={56}
            actionType={dispatchActionType}
            gridColumnState={gridColumnState}
            handleOnAdvancedSearch={onFilterOpen}
            advancedSearch={true}
            selectedFilterValuesText={filterText}
            resetPagination={isLoading}
            excludeBusinessUnit={
              identityTypeID === IdentityTypeIds.ACTIVITY ? true : false
            }
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

IdentitySearchResults.propTypes = {
  identityTypeID: PropTypes.number,
  identities: PropTypes.shape({
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    hasError: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        primaryContact: PropTypes.string,
        roles: PropTypes.string,
        name: PropTypes.string,
        knownAs: PropTypes.string,
        phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        email: PropTypes.string,
        address: PropTypes.string,
        businessUnit: PropTypes.string,
      }),
    ),
  }),
};

IdentitySearchResults.defaultProps = {
  identities: {},
  identityTypeID: 0,
};
