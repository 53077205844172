/* eslint-disable react/prop-types */
import { isEmpty } from 'lodash';
import React from 'react';
import { GridWithFilter } from '../../../components/Grid';
import { dataGridHeaderColumns, filterDataGridColumns } from './gridColumns';

export const SearchGridResults = ({
  handleOnRowClick,
  handleOnCellClick,
  isLoading = false,
  samplesData = [],
  filterType = '',
  className = '',
  hideFooter = false,
  gridColumnState,
  actionType,
  filterText,
  onFilterOpen,
  ...props
}) => {
  const [sampleData, setSampleData] = React.useState([]);
  React.useEffect(() => {
    if (isEmpty(samplesData)) setSampleData([]);
    else setSampleData(samplesData);
  }, [samplesData]);

  const gridXData = {
    columns:
      filterType === 'Tracking' ? filterDataGridColumns : dataGridHeaderColumns,
    rows: sampleData,
  };

  return (
    <GridWithFilter
      data={gridXData}
      onRowClick={handleOnRowClick}
      onCellClick={handleOnCellClick}
      gridColumnState={gridColumnState}
      actionType={actionType}
      rowHeight={30}
      autoPageSize
      loading={isLoading}
      className={className}
      hideFooter={hideFooter}
      handleOnAdvancedSearch={onFilterOpen}
      advancedSearch
      selectedFilterValuesText={filterText}
      {...props}
    />
  );
};
