import { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format, isValid, parse, isBefore } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import shortId from 'shortid';

import { filter, find, isNil, isNull } from 'lodash';
import get from 'lodash/get';
import { getLookupClassificationGroupsByOrgId } from '../../../redux/selectors';
import { ClassificationSchema } from '../validations';
import { syncValidator } from '../../../utils/validator';
import { validDateFormat } from '../../../utils/DateUtil';
import {
  reducers,
  sagaActionTypes,
  vineaDetails,
  actionTypes,
  apiTypes,
  dateFormat,
} from '../../../constants';
import logger from '../../../utils/winstonLogger';

const useIdClassificationHook = (
  identityTypeId,
  identityId = null,
  identityRoleTypeIDs = [],
) => {
  // state
  const [identityClassifications, setIdentityClassifications] = useState([]);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [seqToSave, setSeqToSave] = useState(null);
  const [previous, setPrevious] = useState([]);
  const [formLoadingStatus, setFormLoadingStatus] = useState(false);
  const [alert, setAlert] = useState('');

  // dispatches;
  const dispatchAPI = useDispatch();
  const { id: pathParamIdentityId = identityId } = useParams();

  // selectors
  const lkpClassificationsGroupByOrgId = useSelector(state =>
    getLookupClassificationGroupsByOrgId(state, identityTypeId),
  );

  const classificationGroupOptions = useMemo(() => {
    if (isEmpty(lkpClassificationsGroupByOrgId)) return [];

    const options = filter(lkpClassificationsGroupByOrgId, classification => {
      if (
        !get(classification, 'isRoleSpecific', false) ||
        get(classification, 'roleTypeID', 0) === 0
      )
        return true;
      return identityRoleTypeIDs.includes(
        get(classification, 'roleTypeID', null),
      );
    });

    return options;
  }, [lkpClassificationsGroupByOrgId, identityRoleTypeIDs]);

  const {
    isLoading,
    hasError,
    isLoaded,
    data: classificationsData,
  } = useSelector(state => state.identityClassifications);

  const {
    isLoading: formLoading,
    hasError: formError,
    isLoaded: formLoaded,
    data: formWriteData,
  } = useSelector(state => state.formWrite);

  useEffect(() => {
    if (formLoading && actionTriggered) setFormLoadingStatus(true);
    if (
      !formLoading &&
      formLoaded &&
      !isEmpty(formWriteData) &&
      !formError &&
      formLoadingStatus
    ) {
      setFormLoadingStatus(false);
      // dispatchAPI({
      //   type: sagaActionTypes.REFRESH_PAGE_DATA,
      //   payload: {
      //     refreshPage: true,
      //   },
      // });
    }
  }, [
    formLoading,
    actionTriggered,
    formLoaded,
    formWriteData,
    formError,
    formLoadingStatus,
    dispatchAPI,
  ]);

  // useEffects
  useEffect(() => {
    if (!isLoading && isLoaded && !hasError) {
      logger.debug('classificationsData');
      // logger.debug(classificationsData);
      const newRows = classificationsData.map(f => ({
        id: f.id,
        classificationID: f.classificationID,
        classificationGroupID: f.classificationGroupID,
        group: f.classificationGroup,
        name: f.classification,
        effectiveFromDate: f.effectiveFromDate,
        effectiveToDate: f.effectiveToDate,
        isEditMode: false,
        methodType: f.methodType || apiTypes.PUT,
        ts: f.ts,
        errors: {},
      }));
      setIdentityClassifications(newRows);
    } else setIdentityClassifications([]);
  }, [classificationsData, isLoading, hasError, isLoaded]);

  const onAddNew = useCallback(() => {
    const newRows = identityClassifications.concat([
      {
        id: shortId.generate(),
        classificationID: 0,
        classificationGroupID: 0,
        groupId: 0,
        name: '',
        effectiveFromDate: format(new Date(), dateFormat),
        effectiveToDate: null,
        isEditMode: true,
        errors: {},
        methodType: apiTypes.POST,
        ts: null,
      },
    ]);
    setIdentityClassifications(newRows);
  }, [identityClassifications]);

  /** changes to the row data */
  const handleOnChangeRowData = useCallback(
    (e, rowdata) => {
      const {
        target: { value, name },
      } = e;

      const { id } = rowdata;
      const isFromDateModified = name === 'effectiveFromDate';

      const newRowsNameValue = identityClassifications.map(row => {
        if (row.id === id) {
          const { warning, ...restCols } = row;
          return {
            ...restCols,
            effectiveToDate: isFromDateModified ? null : row.effectiveToDate,
            [name]: value,
          };
        }
        return row;
      });

      let newRows = newRowsNameValue.map(row => {
        if (id === row.id) {
          const { errors, ...restCols } = row;
          const validationErrors = syncValidator(ClassificationSchema)(row);
          let newRowValue = {};

          if (!isEmpty(validationErrors)) {
            newRowValue = {
              ...restCols,
              errors: validationErrors,
            };
          } else {
            newRowValue = {
              ...restCols,
              errors: [],
            };
          }

          return newRowValue;
        }
        return row;
      });
      const currentRowData = newRows.find(f => f.id === id);

      const duplicateRows = newRows.filter(
        f =>
          f.classificationID === currentRowData.classificationID &&
          f.classificationGroupID === currentRowData.classificationGroupID &&
          f.id !== currentRowData.id &&
          ((f.effectiveToDate >= currentRowData.effectiveFromDate &&
            f.effectiveFromDate <= currentRowData.effectiveFromDate &&
            currentRowData.effectiveFromDate !== null &&
            f.effectiveToDate !== null &&
            f.effectiveFromDate !== '') ||
            (f.effectiveFromDate <= currentRowData.effectiveFromDate &&
              f.effectiveToDate === null &&
              currentRowData.effectiveFromDate !== null) ||
            (f.effectiveFromDate >= currentRowData.effectiveFromDate &&
              currentRowData.effectiveToDate === null &&
              currentRowData.effectiveFromDate !== null) ||
            (f.effectiveToDate >= currentRowData.effectiveToDate &&
              f.effectiveFromDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate !== null &&
              f.effectiveFromDate !== null &&
              f.effectiveToDate !== null) ||
            (f.effectiveFromDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate === null) ||
            (f.effectiveFromDate >= currentRowData.effectiveFromDate &&
              f.effectiveToDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate !== null &&
              currentRowData.effectiveFromDate !== null &&
              f.effectiveFromDate !== null &&
              f.effectiveToDate !== null)),
      );
      // update validation errors
      const hasDuplicateRowValue = duplicateRows.length > 0;
      if (hasDuplicateRowValue) {
        newRows = identityClassifications.map(row => {
          if (id === row.id) {
            return {
              ...currentRowData,
              warning:
                'You cannot create a duplicate record. Please, update details or reuse the existing one.',
            };
          }
          return row;
        });
      }

      if (name === 'classificationGroupID') {
        newRows = newRows.map(row => {
          if (row.id === id) {
            const groupName = get(
              classificationGroupOptions.find(f => f.id === value),
              'value',
              null,
            );
            return { ...row, group: groupName };
          }
          return row;
        });
      }

      if (name && ['effectiveFromDate', 'effectiveToDate'].includes(name)) {
        const currentRow = newRows.find(f => f.id === id);
        const { effectiveFromDate, effectiveToDate } = currentRow || {};
        const fromDate = isValid(validDateFormat(effectiveFromDate))
          ? validDateFormat(effectiveFromDate)
          : null;
        const toDate = isValid(validDateFormat(effectiveToDate))
          ? validDateFormat(effectiveToDate)
          : null;
        const isBeforeStartDate = toDate && isBefore(toDate, fromDate);
        newRows = newRows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              errors: isBeforeStartDate ? { effectiveToDate: true } : {},
            };
          }
          return row;
        });
      }
      // final update
      setIdentityClassifications(newRows);
    },
    [identityClassifications, classificationGroupOptions],
  );

  const onToggleEditMode = useCallback(
    (id, rows) => {
      setPrevious(identityClassifications);
      setIdentityClassifications(() => {
        return rows.map(row => {
          if (row.id === id) {
            return { ...row, isEditMode: !row.isEditMode };
          }
          return row;
        });
      });
    },
    [identityClassifications],
  );

  const handleRevert = useCallback(
    id => {
      let newRows;
      const currentRow = find(identityClassifications, f => f.id === id);
      if (isNull(currentRow.ts)) {
        newRows = identityClassifications.filter(f => f.id !== id);
        logger.debug('newRows');
        logger.debug(newRows);
      } else {
        newRows = identityClassifications.map(row => {
          if (row.id === id) {
            const prevState = find(previous, f => f.id === id);
            return {
              ...row,
              classificationID: prevState.classificationID,
              group: prevState.group,
              classificationGroupID: prevState.classificationGroupID,
              effectiveFromDate: prevState.effectiveFromDate,
              effectiveToDate: prevState.effectiveToDate,
              warning: '',
            };
          }
          return row;
        });
        logger.debug('newRows');
        logger.debug(newRows);
      }
      setIdentityClassifications(newRows);
      onToggleEditMode(id, newRows);
    },
    [identityClassifications, onToggleEditMode, previous],
  );

  const handleOnSaveRow = id => {
    const dataToSave = identityClassifications.find(f => f.id === id);
    const validationErrors = syncValidator(ClassificationSchema)(dataToSave);

    if (!isEmpty(dataToSave.errors)) {
      logger.error('cannot save duplicate data');
    } else if (!isEmpty(validationErrors)) {
      const newRows = identityClassifications.map(row => {
        if (row.id === id) {
          return { ...row, errors: validationErrors };
        }
        return row;
      });
      setIdentityClassifications(newRows);
    } else {
      let data = {};
      if (dataToSave.methodType === apiTypes.POST) {
        data = {
          IdentityID: pathParamIdentityId, // TODO: remove later
          ClassificationID: dataToSave.classificationID,
          effectiveFromDate: dataToSave.effectiveFromDate,
          effectiveToDate: dataToSave.effectiveToDate,
        };
      } else {
        data = {
          ID: id,
          IdentityID: pathParamIdentityId, // TODO: remove later
          ClassificationID: dataToSave.classificationID,
          effectiveFromDate: dataToSave.effectiveFromDate,
          effectiveToDate: dataToSave.effectiveToDate,
          ts: dataToSave.ts,
        };
      }
      dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.classification,
          methodType: dataToSave.methodType,
        },
      });

      setSeqToSave(id);
      setActionTriggered(true);
      if (dataToSave.methodType === 'PUT') {
        setAlert('Update');
      } else {
        setAlert('Created');
      }
    }
  };

  const handleOnDeleteRow = useCallback(
    id => {
      const selectedRecord = find(identityClassifications, f => f.id === id);
      if (isEmpty(selectedRecord.ts) || isNull(selectedRecord.ts)) {
        const updatedRows = identityClassifications.filter(f => f.id !== id);
        setIdentityClassifications(updatedRows);
      } else {
        const data = {
          ID: id,
          ts: selectedRecord.ts,
        };
        dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.classification,
            methodType: apiTypes.DELETE,
          },
        });

        setActionTriggered(true);
        setAlert('Delete');
      }
    },
    [dispatchAPI, identityClassifications],
  );

  return {
    classificationGroupOptions,
    identityId: pathParamIdentityId,
    identityClassifications,
    setIdentityClassifications,
    onAddNew,
    isLoadingIdClassification: isLoading,
    hasErrorIdClassification: hasError,
    handleOnChangeRowData,
    onToggleEditMode,
    handleOnSaveRow,
    handleRevert,
    setSeqToSave,
    setActionTriggered,
    actionTriggered,
    seqToSave,
    alert,
    handleOnDeleteRow,
  };
};

export { useIdClassificationHook };
