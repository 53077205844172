import React from 'react';
import { VineaDialog } from '.';

type DeleteDialogProps = {
  dialogHeader: string;
  dialogContent: string;
  dialogActionTrigger: any;
  open: boolean;
  onClose: any;
  rowId: number;
};

export function DeleteDialog({
  dialogContent = 'This action is irreversible',
  dialogActionTrigger,
  onClose,
  ...restProps
}: DeleteDialogProps) {
  return (
    <VineaDialog
      dialogContent={dialogContent}
      primaryAction={dialogActionTrigger}
      secondaryAction={onClose}
      onClose={onClose}
      primaryText="Delete"
      primaryColour="error"
      {...restProps}
    />
  );
}
