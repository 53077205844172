import * as Yup from 'yup';
import { isValid, parse, isBefore } from 'date-fns';

const dateFormat = 'yyyy-MM-dd';

export const EditActivitySchema = Yup.object().shape({
  activityCode: Yup.string()
    .max(50, 'Activity code cannot be longer than 50 characters')
    .required('Required'),
  planActivityDescription: Yup.string().required('Required'),
  activityID: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  colourHexCode: Yup.string()
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Colour is required.')
    .required('Required'),
  costingUnitID: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  estimatedCost: Yup.string()
    .matches(/^\d+\.?\d*$/)
    .required('Required'),
  estimatedDuration: Yup.string()
    .matches(/^\d+\.?\d*$/)
    .required('Required'),
  id: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  locked: Yup.string()
    .matches(/^(true|false)$/)
    .required('Required'),
  planID: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  resourceUnitID: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  sortSequence: Yup.string().matches(/^[0-9]*$/, 'Must be a number'),
  targetEndDate: Yup.string()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
  targetStartDate: Yup.string()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
  totalWorkUnits: Yup.string()
    .matches(/^\d+\.?\d*$/)
    .required('Required'),
  unitCost: Yup.string()
    .matches(/^\d+\.?\d*$/)
    .required('Required'),
  velocity: Yup.string()
    .matches(/^\d+\.?\d*$/)
    .required('Required'),
  velocityDurationID: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  workIntensity: Yup.string()
    .matches(/^\d+\.?\d*$/)
    .required('Required'),
  workUnitID: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
});

export const AddActivitySchema = Yup.object().shape({
  planActivityDescription: Yup.string().required('Required'),
  id: Yup.string()
    .matches(/^[0-9]+$/, 'Select one activity')
    .required('Select one activity'),
  targetEndDate: Yup.string()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
  targetStartDate: Yup.string()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
});

const currentDate = new Date();
export const PlanSchema = Yup.object().shape({
  planName: Yup.string()
    .max(200, 'Plan Name too Long')
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .required('Required'),
  planDescription: Yup.string()
    .max(1000, 'Description too Long')
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .required('Required'),
  planTypeID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select Plan Type')
    .required('Required'),
  planStatusID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select Plan Status')
    .required('Required'),
  planVintageID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select Plan Vintage')
    .required('Required'),
  planStartDate: Yup.date()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : new Date(1900, 1, 1)))
    .typeError('Invalid Date')
    .min(
      Yup.ref('yearStart'),
      'Plan start date cannot be earlier than vintage start date',
    )
    .max(Yup.ref('yearEnd'), 'Plan start cannot be later than vintage end date')
    .required('Required'),
  planEndDate: Yup.date()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : new Date(1900, 1, 1)))
    .typeError('Invalid Date')
    .min(currentDate.toDateString())
    .max(
      Yup.ref('yearEnd'),
      'Plan end date cannot be later than vintage end date',
    )
    .when('planStartDate', (planStartDate, schema) => {
      console.log('stdate', planStartDate);
      return (
        planStartDate &&
        schema.min(
          planStartDate,
          'Plan start date must be earlier than plan end date',
        )
      );
    })
    .required('Required'),
  locked: Yup.boolean().nullable(),
  businessUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select Business Unit')
    .required('Required'),
});
