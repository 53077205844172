/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import wcmatch from 'wildcard-match';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ErrorBoundary } from 'react-error-boundary';
import { CollapseHorizontal } from '../../components/CollapseHorizontal';
import { VineaTextField } from '../../components/TextField';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { FormControlLabel } from '@mui/material';
import { GreenSwitch } from '../../components/Switch';
import { isEmpty, isNil } from 'lodash';

const BlocksFilter = ({
  isFilterOpen,
  onFilterToggle,
  selectedBlocks,
  handleOnBlockSelect,
  handleOnMultipleBlockSelect,
  allBlocksData,
}) => {
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(isFilterOpen);
  const [searchedBlocks, setSearchedBlocks] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [activeOnly, setActiveOnly] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  // Hide and show filter panel based on filter toggle
  useEffect(() => {
    onFilterToggle(showFilter);
  }, [showFilter, onFilterToggle]);

  // If search key changes or active only is toggled, filter the blocks list
  useEffect(() => {
    filterBlocksList(searchKey);
  }, [allBlocksData, searchKey, activeOnly]);

  const handleOnSearch = e => {
    setSearchKey(e.target.value);
  };

  // Handle toggling of active only and select all checkboxes
  const handleToggle = e => {
    if (e.target.name === 'activeOnly') {
      setActiveOnly(e.target.checked);
    } else if (e.target.name === 'selectAll') {
      setCheckAll(e.target.checked);
      if (e.target.checked) {
        filterBlocksList('**');
      } else {
        handleOnMultipleBlockSelect([]);
      }
    }
  };

  // Set searched blocks based on search value and active only toggle
  const filterBlocksList = searchKeyValue => {
    let blocks = allBlocksData;
    if (!isNil(searchKeyValue) && searchKeyValue.length > 0) {
      blocks = allBlocksData.filter(block =>
        wcmatch('*' + searchKeyValue + '*')(block.blockFullName),
      );
    }
    if (activeOnly && !isEmpty(blocks) && !isNil(blocks)) {
      blocks = blocks.filter(block => block.isActive === true);
    }

    setSearchedBlocks(blocks);
    handleOnMultipleBlockSelect(blocks);
  };

  return (
    <Paper
      elevation={2}
      data-testid="blocksFilter-collapse-paper"
      sx={{
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .MuiCollapse-horizontal': {
          height: '100%',
          width: '100px',
        },
        '& .MuiCollapse-wrapper': {
          flex: 1,
        },
        '& .MuiInputLabel-outlined': {
          zIndex: 0,
        },
        '& .MuiCollapse-wrapperInner': {
          width: '100%',
        },
      }}
    >
      {!isFilterOpen && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flex: 1,
            '&  .MuiIconButton-root': {
              flex: 1,
            },
          }}
        >
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="flex-start"
            display="flex"
          >
            <div />
            <div>
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowFilter(true);
                }}
                onMouseDown={() => {}}
                size="large"
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
          </Box>
        </Box>
      )}
      <CollapseHorizontal
        orientation="horizontal"
        collapsedSize={48}
        in={showFilter}
        data-testid="collapse-horizontal"
        style={{ height: '100%' }}
      >
        {!!showFilter && (
          <Stack data-testid="collapse-show-filter-box" sx={{ height: '100%' }}>
            <Stack
              pl={2}
              direction="row"
              sx={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Typography variant="subtitle2" noWrap data-testid="header-text">
                {t('Blocks Search')}
              </Typography>

              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowFilter(false)}
                onMouseDown={() => {}}
                size="large"
              >
                <ChevronLeftIcon />
              </IconButton>
            </Stack>
            <Stack
              px={2}
              direction="row"
              sx={{ justifyContent: 'space-between', pt: 2 }}
              data-testid="control-buttons"
            >
              {' '}
              <FormControlLabel
                control={
                  <GreenSwitch
                    checked={activeOnly}
                    onChange={handleToggle}
                    name="activeOnly"
                  />
                }
                label={
                  <Typography variant="caption">{t('Active Only')}</Typography>
                }
              />
              <FormControlLabel
                control={
                  <GreenSwitch
                    checked={checkAll}
                    onChange={handleToggle}
                    name="selectAll"
                  />
                }
                label={
                  <Typography variant="caption">{t('Select All')}</Typography>
                }
              />
            </Stack>
            <div>&nbsp;</div>
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <Box mb={2} px={2} data-testid="search-blocks">
                <FormControl variant="outlined" sx={{ width: '250px' }}>
                  <VineaTextField
                    id="outlined-search-id-name"
                    label={t('Search')}
                    type="search"
                    variant="outlined"
                    size="small"
                    value={searchKey}
                    onChange={e => handleOnSearch(e)}
                  />
                </FormControl>
              </Box>
              <Stack
                sx={{
                  height: '100%',
                  overflowY: 'auto',
                }}
              >
                {!isEmpty(searchedBlocks) &&
                  !isNil(searchedBlocks) &&
                  searchedBlocks.map(block => (
                    <FormControl>
                      <MenuItem key={block.id} value={block.blockFullName}>
                        <Checkbox
                          checked={selectedBlocks.some(
                            blocks => blocks.id === block.id,
                          )}
                          onClick={() => {
                            handleOnBlockSelect(block);
                          }}
                        />
                        <ListItemText primary={block.blockFullName} />
                      </MenuItem>
                    </FormControl>
                  ))}
              </Stack>
            </ErrorBoundary>
          </Stack>
        )}
      </CollapseHorizontal>
    </Paper>
  );
};

export default BlocksFilter;
