import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import IdentityDetailsExt1 from './IdentityDetailsExt1';
import IdentityDetailsExt2 from './IdentityDetailsExt2';
import IdentityDetailsExt3 from './IdentityDetailsExt3';
import logger from '../../utils/winstonLogger';

const rootStyle = () =>
  css`
  display: 'flex',
  flexGrow: 1,
  `;

const RootDiv = styled.div`
  ${rootStyle};
`;

export const IdentityDetails = ({
  handleEdit,
  identityOverviewDetails,
  identityStatus,
  isLoading = false,
  saveCroppedImage,
  imageDataUrl,
  onDeleteProfileImage,
  updateFavouriteIdentity,
  favIdentities = [],
}) => {
  const theme = useTheme();
  logger.debug(
    'IdentityDetails - identityOverviewDetails:',
    identityOverviewDetails,
  );
  return (
    <RootDiv>
      <Grid container>
        {isLoading && (
          <Box width="100%" display="inline-flex" px={1}>
            <Skeleton variant="rectangular" width={210} height={100} />
            <Box width="100%" px={1}>
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="100%" height={20} />
            </Box>
          </Box>
        )}
        {!isLoading && (
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              sx={{ display: 'flex', flexGrow: 1 }}
            >
              <Paper
                elevation={0}
                sx={{
                  padding: theme.spacing(1),
                  height: '100%',
                  display: 'flex',
                  flex: 1,
                }}
              >
                <Grid item xs={12} sx={{ flexGrow: 1 }}>
                  <IdentityDetailsExt1
                    identityOverviewDetails={identityOverviewDetails}
                    onClick={handleEdit}
                    identityStatus={identityStatus}
                    saveCroppedImage={saveCroppedImage}
                    imageDataUrl={imageDataUrl}
                    onDeleteProfileImage={onDeleteProfileImage}
                    updateFavouriteIdentity={updateFavouriteIdentity}
                    favIdentities={favIdentities}
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7} data-testid="identity-details-2">
              <Paper
                elevation={0}
                sx={{
                  padding: theme.spacing(1),
                  height: '100%',
                }}
              >
                <Grid container direction="row">
                  <Grid item xs={6} display="flex">
                    <IdentityDetailsExt2
                      identityOverviewDetails={identityOverviewDetails}
                    />
                  </Grid>
                  <Grid item xs={6} display="flex">
                    <IdentityDetailsExt3
                      identityOverviewDetails={identityOverviewDetails}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </RootDiv>
  );
};

IdentityDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  identityStatus: PropTypes.bool.isRequired,
  identityOverviewDetails: PropTypes.shape({
    id: PropTypes.number,
    identityTypeID: PropTypes.number,
    isPersonIdentity: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    knownAs: PropTypes.string,
    displayName: PropTypes.string,
    plantedArea: PropTypes.string,
    totalArea: PropTypes.string,
    birthDate: PropTypes.string,
    ethnicity: PropTypes.string,
    nationality: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    subregion: PropTypes.string,
    address: PropTypes.string,
    roles: PropTypes.string,
    name: PropTypes.string,
    planName: PropTypes.string,
    description: PropTypes.string,
    planType: PropTypes.string,
    status: PropTypes.string,
    businessUnit: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    supplyContractPrimaryContact: PropTypes.string,
    supplyContractEmail: PropTypes.string,
    supplyContractDateSigned: PropTypes.string,
    supplyContractAreaLastChanged: PropTypes.string,
    supplyContractDateEnded: PropTypes.string,
    supplyContractPhone: PropTypes.string,
    supplyContractAddress: PropTypes.string,
    supplyContractCurrentContractedArea: PropTypes.string,
    supplyContractSubRegions: PropTypes.string,
    supplyContractContractedVineyards: PropTypes.string,
  }),
  saveCroppedImage: PropTypes.func.isRequired,
  imageDataUrl: PropTypes.string,
  onDeleteProfileImage: PropTypes.func.isRequired,
  updateFavouriteIdentity: PropTypes.func.isRequired,
  favIdentities: PropTypes.arrayOf(PropTypes.shape({})),
};

IdentityDetails.defaultProps = {
  identityOverviewDetails: {},
  imageDataUrl: null,
};
