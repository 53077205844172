import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

const getUserData = state => state.userAccess.data;
const getIsLoading = state => state.userAccess.isLoading;

export const getLicenseActiveStatus = createSelector([getUserData], data => {
  const { isLicenseActive = {} } = data;
  return isLicenseActive;
});
export const getApiLoadingStatus = createSelector(
  [getUserData, getIsLoading],
  (data, isLoading) => {
    if (isLoading === false) {
      return !isEmpty(data);
    }
    return false;
  },
);
