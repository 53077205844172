/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { VineaButton } from '../VineaButton';

interface IIdentitySubMenuProps {
  onNewIdentityClick: React.MouseEventHandler<HTMLButtonElement>;
  newButtonText: string;
  newButtonDisabled: boolean;
  showExport: boolean;
  showImport: boolean;
  onExportClick: () => void;
}

export const IdentitySubMenu: React.FC<IIdentitySubMenuProps> = ({
  onNewIdentityClick,
  onExportClick = (f: any) => f,
  newButtonText = 'New',
  newButtonDisabled = true,
  showExport = true,
  showImport = true,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box mr={1}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
        }}
      >
        {showExport && (
          <Box mr={1}>
            <VineaButton
              // @ts-ignore
              sx={{
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
              }}
              aria-label="language"
              aria-controls="simple-menu"
              aria-haspopup="true"
              data-testid="export"
              color="secondary"
              variant="text"
              onClick={onExportClick}
            >
              {t('Export')}
            </VineaButton>
          </Box>
        )}
        {showImport && (
          <Box mr={1}>
            <VineaButton
              // @ts-ignore
              sx={{
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
              }}
              aria-label="language"
              aria-controls="simple-menu"
              aria-haspopup="true"
              data-testid="import"
              color="secondary"
              variant="text"
            >
              {t('Import')}
            </VineaButton>
          </Box>
        )}
        <VineaButton
          sx={{
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
          }}
          aria-label="language"
          aria-controls="simple-menu"
          aria-haspopup="true"
          data-testid="new"
          color="secondary"
          variant="contained"
          // @ts-ignore
          onClick={onNewIdentityClick}
          disabled={newButtonDisabled}
        >
          {t(newButtonText)}
        </VineaButton>
      </Box>
    </Box>
  );
};
