/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CreateNewEntities = ({ label, testId, children }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid container spacing={2} data-testid="grid-details">
      <Grid item xs={12}>
        <Paper elevation={0} data-testid={`${testId}-repeater-paper`}>
          <Box sx={{ marginLeft: theme.spacing(4) }}>
            <Typography
              variant="h6"
              color="textPrimary"
              sx={{ paddingTop: theme.spacing(2) }}
            >
              {t(label)}
            </Typography>
          </Box>
        </Paper>
        <Paper
          elevation={0}
          data-testid={`${testId}-paper-v2`}
          sx={{
            marginTop: theme.spacing(1),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
          }}
        >
          <Grid container spacing={2} data-testid={`${testId}-gridcontainer`}>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CreateNewEntities;
