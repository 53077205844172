import React from 'react';
import Box from '@mui/material/Box';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import { Pill } from '../../../components/Pills';

export const RenderEditButton = params => {
  const {
    colDef: { onEditClick },
    row: { id, isActive } = {},
  } = params || {};

  const handleOnEditClick = () => {
    onEditClick(id);
  };

  return (
    <Box
      display="flex"
      flex="1"
      alignItems="center"
      justifyContent="flex-start"
    >
      {!isActive && <div />}
      {isActive && (
        <>
          <IconButton
            color="primary"
            aria-label="edit vintages"
            component="button"
            onClick={handleOnEditClick}
            sx={{
              padding: 0,
            }}
            size="large"
          >
            <ModeEditOutlineOutlinedIcon />
          </IconButton>
          <Pill
            label="Current"
            size="small"
            color="primary"
            boxProps={{ ml: 18 }}
          />
        </>
      )}
    </Box>
  );
};
