import Switch from '@mui/material/Switch';
import pink from '@mui/material/colors/pink';
import { styled } from '@mui/material/styles';

const PinkSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    color: pink[300],
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[300],
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[800],
  },
}));

export { PinkSwitch };
