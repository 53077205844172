// reducers/uiReducer.js
const snackbarReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SNACKBAR_MESSAGE':
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.message,
      };
    case 'SNACKBAR_CLEAR':
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
      };
    default:
      return state;
  }
};

export default snackbarReducer;
