import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { IdentityTypeIds, LicenseeTypeIDs } from '../constants';
import { useParams } from 'react-router';
import { useIdentityTypeId } from './useIdentityTypeId';

export interface EntityType {
  data: any;
  isLoading: boolean;
  isLoaded: boolean;
  hasError: boolean;
}

const useIdentityTypeScreenNameHook = (isWorker: boolean = false) => {
  const { licenseeTypeID } = useSelector(
    //@ts-ignore
    state => state.userAccess.data,
  ) as any;
  // const licenseeTypeID = 4;


  const vineyardTypeScreenName = useMemo(() => {
    if (
      licenseeTypeID === LicenseeTypeIDs.WINE_GROWER ||
      licenseeTypeID === LicenseeTypeIDs.CONTRACTOR_VINEYARDS_ONLY
    )
      return 'vineyard';
    else if (licenseeTypeID === LicenseeTypeIDs.FRUIT_GROWER) return 'orchard';
    else if (
      licenseeTypeID === LicenseeTypeIDs.CONTRACTOR_VINEYARDS_AND_ORCHARDS
    )
      return 'site';
    else return 'vineyard';
  }, [licenseeTypeID]);

  const { id: identityID } = useParams();
  const identityTypeID = useIdentityTypeId(vineyardTypeScreenName);

  const identityBaseURL = useMemo(() => {
    if (identityTypeID === IdentityTypeIds.PERSON && isWorker)
      return `worker/${identityID}`;
    else if (identityTypeID === IdentityTypeIds.PERSON && !isWorker)
      return `person/${identityID}`;
    else if (identityTypeID === IdentityTypeIds.ORGANISATION)
      return `organisation/other-organisations/${identityID}`;
    else if (identityTypeID === IdentityTypeIds.VINEYARD)
      return `organisation/${vineyardTypeScreenName}s/${identityID}`;
    else if (identityTypeID === IdentityTypeIds.SERVICE_PROVIDER)
      return `organisation/serviceproviders/${identityID}`;
    else if (identityTypeID === IdentityTypeIds.JOB) return `job/${identityID}`;
    else if (identityTypeID === IdentityTypeIds.DATA_CONNECTION)
      return `connections/${identityID}`;
    else if (identityTypeID === IdentityTypeIds.VEHICLE)
      return `vehicle/${identityID}`;
    else if (identityTypeID === IdentityTypeIds.ACCOMMODATION)
      return `accommodation/${identityID}`;
    else if (identityTypeID === IdentityTypeIds.CONSUMABLE)
      return `consumable/${identityID}`;
    else if (identityTypeID === IdentityTypeIds.ACTIVITY)
      return `activity/${identityID}`;
    else if (identityTypeID === IdentityTypeIds.SUPPLY_CONTRACT)
      return `supplycontract/${identityID}`;
  }, [identityID, identityTypeID, isWorker, vineyardTypeScreenName]);

  return { vineyardTypeScreenName, identityBaseURL, licenseeTypeID };
};

export default useIdentityTypeScreenNameHook;
