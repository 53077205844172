import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { isEqual, get, omit, isEmpty, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { FilterScreen } from '../../components/FilterScreen';
import { IdentitySubMenu } from '../../components/IdentitySubMenu';
import {
  getLookupReferencesByOrgId,
  getLookupClassificationGroupsByOrgId,
  getLkpRoleTypeIdentityType,
  areLookupsLoaded,
  getUserPreferences,
  getBusinessUnit,
} from '../../redux/selectors';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import {
  sagaActionTypes as Types,
  reducers,
  actionTypes,
  sagaActionTypes,
  IdentityRoleTypes,
  IdentityTypeIds,
} from '../../constants';
import useLocationListener from '../../hooks/useLocationListener';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { IdentitySearchResults } from '../VineyardIdentity/IdentitySearchResults';
import theme from '../../theme';

const RootDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: '100%',
}));

interface StyledBoxProps {
  theme: any;
  isFilterOpen: boolean;
}

interface LookupPropType {
  key: any;
  value: any;
  id: number;
}

const StyledBox = styled(Box)<StyledBoxProps>(({ theme, isFilterOpen }) => ({
  ...(isFilterOpen && {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flex: '0.4',
    },
    [theme.breakpoints.up('md')]: {
      flex: '0.3',
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0.18',
    },
  }),
  ...(!isFilterOpen && { display: 'flex', flex: 0 }),
}));

const WorkerSearch: React.FC = () => {
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const newLocationKeys = useLocationListener();
  const { t } = useTranslation();

  const identityTypeId = IdentityTypeIds.PERSON;

  const [locationKeys, setLocationKeys] = useState<string[]>([]);
  const { setIsLocked, isLocked } = useTabChangeLock();

  const [filterText, setFilterText] =
    useState<Array<{ filterName: string; values: string[] }>>();
  const firstFilterInputRef = useRef<HTMLInputElement | null>(null);

  const allIdentities = useSelector(VineaNovaSelectors.getSearchIdentityEntity);
  const lkpReferences = useSelector(state =>
    //@ts-ignore
    getLookupReferencesByOrgId(state, identityTypeId),
  );
  const lkpClassificationsGroup = useSelector(state =>
    //@ts-ignore
    getLookupClassificationGroupsByOrgId(state, identityTypeId),
  );
  const { basicSettings } = useSelector(state => getUserPreferences(state));
  const { filterPanelCollapsed, searchFilterBusinessUnitID } = basicSettings;

  const { data: personSearchFilterData = {} } = useSelector(
    (state: any) => state.personSearchFilter,
  );

  const filterData = useMemo(() => {
    // const { businessUnitID, ...rest } = personSearchFilterData;
    if (
      !isNil(searchFilterBusinessUnitID) &&
      searchFilterBusinessUnitID !== 0
    ) {
      return {
        ...personSearchFilterData,
        businessUnitID: searchFilterBusinessUnitID,
      };
    } else {
      return personSearchFilterData;
    }
  }, [personSearchFilterData]);

  const lkpclassifications = useSelector(
    VineaNovaSelectors.getlookupClassificationEntityData,
  );

  const isLookupsLoaded = useSelector(areLookupsLoaded);
  const lkpBusinessUnit = useSelector(state => getBusinessUnit(state));
  const lkpRoleType = useSelector(state =>
    //@ts-ignore
    getLkpRoleTypeIdentityType(state, identityTypeId),
  );

  // map from each filter name to a friendly name/value to show on screen
  const filterNameMap = React.useMemo(() => {
    const getLkpText = (id: number, lkp: any[]) =>
      id === 0 ? [] : [lkp?.find(v => v?.id === id)?.value];

    return {
      referenceTypeID: {
        name: 'References',
        getValueText: (v: any) => getLkpText(v, lkpReferences),
      },
      classificationTypeID: {
        name: 'Classification Name',
        getValueText: (v: any) => getLkpText(v, lkpclassifications as any[]),
      },
      businessUnitID: {
        name: 'Business Unit',
        getValueText: (v: any) => getLkpText(v, lkpBusinessUnit || []),
      },
      name: { name: 'Name', getValueText: (v: any) => [v] },
      refValue: { name: 'Reference Value', getValueText: (v: any) => [v] },
      findNonActive: { name: 'Active only', getValueText: (v: any) => [!v] },
      roleTypeID: {
        name: 'Role',
        getValueText: (v: any) => getLkpText(v, lkpRoleType),
      },
      activityCode: { name: 'Activity Code', getValueText: (v: any) => [v] },
    };
  }, [lkpBusinessUnit, lkpReferences, lkpRoleType, lkpclassifications]);

  const defaultFilterValues = React.useMemo(
    () => ({
      businessUnitID: basicSettings.businessUnitID,
      findNonActive: false,
    }),
    [basicSettings.businessUnitID],
  );

  /** Dispatches */
  const onSearchIdentities = React.useCallback(
    (searchParams: any) => {
      dispatchAPI(
        VineaNovaActions.api.v1.searchIdentity.post.request({
          postBody: {
            Name: searchParams?.name || null,
            ReferenceTypeID: searchParams?.referenceTypeID || null,
            RefValue: searchParams?.refValue || null,
            ClassificationTypeID: searchParams?.classificationTypeID || null,
            FindNonActive: !!searchParams?.findNonActive,
            RoleTypeID: IdentityRoleTypes.WORKER,
            BusinessUnitID: searchParams?.businessUnitID,
            IdentityTypeID: IdentityTypeIds.PERSON,
          },
        }),
      );

      const newFilterText = Object.keys(
        omit(searchParams, ['classificationGroupID']),
      )?.map(filterName => {
        const { name, getValueText } = get(filterNameMap, [filterName], {});
        return name
          ? { filterName: name, values: getValueText(searchParams[filterName]) }
          : { filterName, values: [searchParams[filterName]] };
      });
      setFilterText(newFilterText);
    },
    [dispatchAPI, filterNameMap, identityTypeId],
  );

  const onUpdateFilterData = React.useCallback(
    (data: any) => {
      dispatchAPI({
        type: actionTypes.updateData,
        name: reducers.personSearchFilter,
        payload: data,
      });

      dispatchAPI({
        type: 'BASIC_SETTINGS_UPDATE',
        payload: {
          ...basicSettings,
          searchFilterBusinessUnitID: data.businessUnitID,
        },
      });
    },
    [dispatchAPI],
  );

  const handleOnFilterToggle = () => {
    dispatchAPI({
      type: 'BASIC_SETTINGS_UPDATE',
      payload: {
        ...basicSettings,
        filterPanelCollapsed: !filterPanelCollapsed,
      },
    });
  };

  const handleOnFilterOpen = () => {
    if (filterPanelCollapsed) {
      dispatchAPI({
        type: 'BASIC_SETTINGS_UPDATE',
        payload: {
          ...basicSettings,
          filterPanelCollapsed: false,
        },
      });
    } else if (firstFilterInputRef.current) {
      firstFilterInputRef.current?.focus();
    }
  };

  const handleOnNewWorker = () => {
    navigate('/worker/newworker');
  };

  const handleOnExport = () => {
    dispatchAPI({ type: sagaActionTypes.EXPORT_EXCEL_REPORT });
  };

  useEffect(() => {
    if (isLocked) {
      setIsLocked(false);
    }
    if (!isEqual(locationKeys, newLocationKeys) && isLookupsLoaded) {
      setLocationKeys(newLocationKeys as unknown as string[]);
      let initialFilterData = {};
      // set defaults when first visiting (overridden if visited again)
      if (isEmpty(filterData)) {
        initialFilterData = { ...defaultFilterValues };
      } else {
        initialFilterData = { ...defaultFilterValues, ...filterData };
      }
      onSearchIdentities(initialFilterData);
      onUpdateFilterData(initialFilterData);

      dispatchAPI({
        type: Types.ADD_IDENTITIES,
        payload: {
          IdentityTypeID: IdentityTypeIds.PERSON,
        },
      });
    }
  }, [
    defaultFilterValues,
    dispatchAPI,
    filterData,
    isLocked,
    isLookupsLoaded,
    locationKeys,
    newLocationKeys,
    onSearchIdentities,
    onUpdateFilterData,
    setIsLocked,
  ]);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      data-testid="identity-search-parent-container"
      height="100%"
    >
      <Paper
        elevation={0}
        data-testid="identity-search-paper"
        sx={{
          padding: 1,
          marginBottom: 3,
        }}
      >
        <IdentitySubMenu
          onNewIdentityClick={handleOnNewWorker}
          newButtonText={t('New Worker')}
          onExportClick={handleOnExport}
          newButtonDisabled={false}
          showExport={true} // Add the missing showExport property
          showImport={true} // Add the missing showImport property
        />
      </Paper>
      <RootDiv data-testid="identity-search-rootdiv">
        <StyledBox isFilterOpen={!filterPanelCollapsed} theme={theme}>
          {/* @ts-ignore */}
          <FilterScreen
            isFilterOpen={!filterPanelCollapsed}
            onFilterToggle={handleOnFilterToggle}
            search={onSearchIdentities}
            lkpReferences={lkpReferences}
            lkpClassificationsGroup={lkpClassificationsGroup}
            lkpclassifications={
              lkpclassifications as LookupPropType[] | undefined
            }
            identityTypeId={IdentityTypeIds.PERSON}
            lkpBusinessUnit={lkpBusinessUnit || undefined}
            lkpRoleType={lkpRoleType}
            filterData={filterData}
            onUpdateFilterData={onUpdateFilterData}
            firstFilterInputRef={firstFilterInputRef}
            nameMap={filterNameMap}
            defaultFilterValues={defaultFilterValues}
          />
        </StyledBox>
        <Box display="flex" flex={1} ml={2} pb={1}>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <IdentitySearchResults
              identities={allIdentities as {} | undefined}
              identityTypeID={IdentityTypeIds.PERSON}
              filterText={filterText}
              onFilterOpen={handleOnFilterOpen}
            />
          </ErrorBoundary>
        </Box>
      </RootDiv>
    </Box>
  );
};

export default WorkerSearch;
