import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

const formWrite = state => state.formWrite;

export const getFormWriteData = createSelector([formWrite], formResponse => {
  const { isLoading, isLoaded, hasError, data, error } = formResponse;
  const { id = null } = data;
  return {
    isLoading,
    hasError,
    isLoaded,
    referenceId: id,
    error,
  };
});

export const getTimeStamp = createSelector([formWrite], formResponse => {
  const { isLoaded, hasError, data } = formResponse;
  if (isLoaded && !hasError && !isEmpty(data)) {
    return data.ts;
  }
  return null;
});
