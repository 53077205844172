import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { Outlet, useNavigate } from 'react-router';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router';
import useUserResponsiveLayout from '../hooks/useUserResponsiveLayout';
import { landingPagesList } from '../constants';
import useIdentityTypeScreenNameHook from '../hooks/useIdentityTypeScreenNameHook';

export const ResponsiveLayout = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  // hooks;
  const { isBelowLaptop, isAllowedOnTablet } = useUserResponsiveLayout();
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  const location = useLocation();
  const isTabletDevice = isBelowLaptop;
  const whiteListedTabUserRoutes = [
    '/',
    '/login',
    '/home',
    '/dashboard',
    '/maps/activity-progress',
    `/maps/${vineyardTypeScreenName}-explorer`,
  ];

  const isAppRouteDenied =
    isAllowedOnTablet && !whiteListedTabUserRoutes.includes(location.pathname);

  const deniedText = isAllowedOnTablet
    ? t('Vinea Nova is configured for limited access on your device.')
    : t('Vinea Nova is not currently configured for your device.');

  useEffect(() => {
    if (isTabletDevice) {
      enqueueSnackbar(deniedText, { variant: 'info' });
      navigate(`/${landingPagesList[2].path}`);
    }
  }, [isTabletDevice, t, enqueueSnackbar]);

  if (
    isMobileDevice ||
    (isBelowLaptop && isAppRouteDenied) ||
    (isBelowLaptop && !isAllowedOnTablet)
  ) {
    return (
      <Paper
        elevation={0}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box py={2}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <NewReleasesIcon fontSize="large" />
            <Typography color="textPrimary" variant="h6">
              {deniedText}
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  }
  return <Outlet />;
};
