import React, { useMemo, useReducer } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO, format, isValid } from 'date-fns';
import Box from '@mui/material/Box';
import { useTheme, styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/lab/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { filter, get, isEmpty, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';

import { VineaAlert } from '../../../components/VineaAlert';
import { Datepicker } from '../../../components/Datepicker';
import { FieldLabelInput } from '../../../components/TextField';
import {
  getBusinessUnit,
  getFormWriteData,
  getLkpEthnicityList,
  getLkpGenderList,
  getLkpLanguageList,
  getLkpNationalityList,
  getLkpSalutationList,
} from '../../../redux/selectors';
import {
  addGenericReducer,
  personState,
  types,
} from '../../VineyardIdentity/stateReducers';
import { syncValidator } from '../../../utils/validator';
import { PersonSchema } from '../../VineyardIdentity/validations';
import {
  apiTypes,
  reducers,
  sagaActionTypes,
  vineaDetails,
  viewDateFormat,
  dateFormat,
} from '../../../constants';
import logger from '../../../utils/winstonLogger';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { SplitButton } from '../../../components/SplitButton';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { VineaButton } from '../../../components/VineaButton';
import { DiscardDialog } from '../../../components/Dialog';
import { statechecker } from '../../../utils/statecomparator';
import {
  IdentityActivationSwitch,
  DeleteIdentityAlert,
  DeleteIdentityButton,
} from '../../../components/IdentityComponents';
import { useIdentityStatus } from '../../../hooks/useIdentityStatus';

const StyledFormControl = styled(FormControl)(() => ({
  width: '350px',
  minWidth: '250px',
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(() => ({
  width: '350px',
  minWidth: '250px',
}));

const ManagePersonDetails = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [formdata, dispatch] = useReducer(addGenericReducer, personState);
  const formWriteData = useSelector(state => state.formWrite).data;
  const {
    hasError: hasErrorFormSubmit,
    isLoaded,
    isLoading,
  } = useSelector(getFormWriteData);
  // const formWrite = useSelector(state => state.formWrite);
  const genderList = useSelector(state => getLkpGenderList(state));
  const ethnicList = useSelector(state => getLkpEthnicityList(state));
  const nationalityList = useSelector(state => getLkpNationalityList(state));
  const languageList = useSelector(state => getLkpLanguageList(state));
  const salutationList = useSelector(state => getLkpSalutationList(state));
  const businessUnitList = useSelector(state => getBusinessUnit(state));
  const identityTypeID = useIdentityTypeId();
  const [apiTriggerStatus, setTriggerStatus] = React.useState(true);
  const [errorInSubmit, setErrorInSubmit] = React.useState(false);
  const [warningInSubmit, setWarningInSubmit] = React.useState(false);
  const [updateApiStatus, setUpdateApiStatus] = React.useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = React.useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);
  const [formWriteReloadState, setFormWriteReloadState] = React.useState(false);
  const [saveIndex, setSaveIndex] = React.useState(0);
  const [deleteHasFailed, setDeleteHasFailed] = React.useState(false);

  const [isDeleting, setIsDeleting] = React.useState(false);
  const {
    isLoading: personIsLoading,
    isLoaded: personIsLoaded,
    hasError: personHasError,
  } = useSelector(VineaNovaSelectors.getIdentityPersonEntityMeta);

  const isActive = useIdentityStatus();
  const { id: pathParam } = useParams();
  // Check if worker page
  const { pathname } = useLocation();
  const isWorker = pathname.includes('worker');
  const baseURL = isWorker ? 'worker' : 'person';

  const { data: rolesData = [] } = useSelector(
    state => state.entities.identityRole,
  );
  const activeRoles = useMemo(() => {
    return filter(rolesData, role => isEmpty(get(role, 'effectiveToDate')));
  }, [rolesData]);

  const handleComponentChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({
      type: types.UPDATE,
      payload: { [name]: value === 0 ? null : value },
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleOnBlur = () => {
    const validationErrors = syncValidator(PersonSchema)(formdata);

    dispatch({
      type: types.ERROR,
      payload:
        !isEmpty(formdata.errors) && !isEmpty(validationErrors)
          ? validationErrors
          : {},
    });
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  const handleClose = () => {
    if (!saveButtonStatus) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleSave = (event, index) => {
    setSaveIndex(index);
    const validationErrors = syncValidator(PersonSchema)(formdata);
    const instanceData = formWriteData;
    if (!isEmpty(validationErrors)) {
      dispatch({ type: types.ERROR, payload: validationErrors });
    } else if (
      instanceData.salutationID === formdata.salutationID &&
      instanceData.firstName === formdata.firstName &&
      instanceData.middleName === formdata.middleName &&
      instanceData.lastName === formdata.lastName &&
      instanceData.knownAs === formdata.knownAs &&
      instanceData.birthDate === formdata.birthDate &&
      instanceData.genderID === formdata.genderID &&
      instanceData.ethnicityID === formdata.ethnicityID &&
      instanceData.nationalityID === formdata.nationalityID &&
      instanceData.preferredLanguageID === formdata.preferredLanguageID &&
      instanceData.secondaryLanguageID === formdata.secondaryLanguageID &&
      instanceData.businessUnitID === formdata.businessUnitID
    ) {
      setWarningInSubmit(true);
    } else {
      const data = {
        id: pathParam,
        identityTypeID,
        salutationID: formdata.salutationID || null,
        firstName: formdata.firstName,
        middleName: formdata.middleName,
        lastName: formdata.lastName,
        knownAs: formdata.knownAs,
        genderID: formdata.genderID,
        ethnicityID: formdata.ethnicityID || null,
        nationalityID: formdata.nationalityID || null,
        preferredLanguageID: formdata.preferredLanguageID || null,
        secondaryLanguageID: formdata.secondaryLanguageID || null,
        businessUnitID: formdata.businessUnitID || null,
        roleTypeID: null,
        preferredContactMethodTypeID: null,
        birthDate: formdata.birthDate,
        ts: formWriteData.ts,
      };
      setWarningInSubmit(false);
      setErrorInSubmit(false);
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: { data, name: vineaDetails.person, methodType: apiTypes.PUT },
      });
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
      setUpdateApiStatus(true);
    }
  };

  const handleOnToggleActive = () => {
    navigate(`/${baseURL}/${pathParam}/managestatus`);
  };

  const handleOnConfirmDelete = () => {
    if (pathParam) {
      setIsDeleting(true);
      dispatchAPI(
        VineaNovaActions.api.v1.identityPerson.delete.request({
          queryParams: {
            IdentityID: pathParam,
          },
        }),
      );
    }
  };

  const handleManageRoles = () => {
    navigate(`/${baseURL}/${pathParam}/manageroles`);
  };

  React.useEffect(() => {
    if (!hasErrorFormSubmit && isLoaded && updateApiStatus) {
      enqueueSnackbar('Success!', { variant: 'success' });
      if (saveIndex === 0) navigate(`/${baseURL}/${pathParam}`);
      else if (formWriteReloadState && saveIndex === 1) {
        setFormWriteReloadState(false);
        dispatchAPI({
          type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
          payload: { id: pathParam, name: reducers.person },
        });
      }
    } else if (hasErrorFormSubmit && isLoaded && updateApiStatus) {
      setErrorInSubmit(true);
      enqueueSnackbar(t('Error'), { variant: 'Error' });
    }
  }, [
    hasErrorFormSubmit,
    isLoaded,
    updateApiStatus,
    enqueueSnackbar,
    t,
    navigate,
    saveIndex,
    pathParam,
    dispatchAPI,
    formWriteReloadState,
  ]);

  React.useEffect(() => {
    if (
      !isLoading &&
      isLoaded &&
      !isEmpty(formWriteData) &&
      !hasErrorFormSubmit
    ) {
      const birthDate = isValid(parseISO(formWriteData.birthDate))
        ? format(parseISO(formWriteData.birthDate), dateFormat)
        : formWriteData.birthDate;
      const stubFormWriteData = { ...formWriteData, birthDate };
      const stubFormData = omit(formdata, ['errors', 'status']);
      if (statechecker(stubFormData, stubFormWriteData)) {
        setSaveButtonStatus(true);
      } else setSaveButtonStatus(false);
    }
  }, [isLoading, isLoaded, formWriteData, hasErrorFormSubmit, formdata]);

  React.useEffect(() => {
    if (apiTriggerStatus && !isEmpty(formWriteData)) {
      const instanceData = formWriteData;
      dispatch({
        type: types.UPDATE,
        payload: {
          salutationID: instanceData.salutationID,
          firstName: instanceData.firstName,
          lastName: instanceData.lastName,
          knownAs: instanceData.knownAs,
          middleName: instanceData.middleName,
          birthDate: isValid(parseISO(instanceData.birthDate))
            ? format(parseISO(instanceData.birthDate), dateFormat)
            : instanceData.birthDate,
          genderID: instanceData.genderID,
          nationalityID: instanceData.nationalityID,
          ethnicityID: instanceData.ethnicityID,
          preferredLanguageID: instanceData.preferredLanguageID,
          secondaryLanguageID: instanceData.secondaryLanguageID,
          businessUnitID: instanceData.businessUnitID,
          status: true,
        },
      });
      setTriggerStatus(false);
      logger.debug('After Update');
    }
  }, [apiTriggerStatus, formWriteData]);

  React.useEffect(() => {
    if (isDeleting && !personIsLoading && personIsLoaded) {
      if (personHasError) {
        setDeleteHasFailed(true);
      } else {
        navigate(`/${baseURL}`);
      }

      setIsDeleting(false);
    }
  }, [
    personIsLoaded,
    personIsLoading,
    personHasError,
    isDeleting,
    setIsDeleting,
  ]);

  return (
    <Paper
      elevation={0}
      data-testid="manageperson-detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details-manageperson">
        <Grid item xs={12}>
          <VineaAlert
            isOpen={!!errorInSubmit}
            onExit={() => setErrorInSubmit(false)}
          />
          <DeleteIdentityAlert
            isOpen={deleteHasFailed}
            onExit={() => setDeleteHasFailed(false)}
            identityName="person"
          />
          <Box px={2}>
            {warningInSubmit && (
              <Collapse in={warningInSubmit}>
                <Alert
                  variant="standard"
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarningInSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Warning: Nothing to Update
                </Alert>
              </Collapse>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item className={classNames.formBoxControl}>
              <Box mx={2} mt={3}>
                <StyledFormControl error={!!formdata.errors.salutationID}>
                  <VineaAutoComplete
                    value={formdata.salutationID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    inlineLabel={false}
                    options={salutationList}
                    labelVariant="body1"
                    id="salutation"
                    name={t('Title')}
                    label={t('Title')}
                    inputProps={{
                      name: 'salutationID',
                    }}
                  />
                  {formdata.errors.salutationID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.salutationID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <StyledFormControl>
                  <FieldLabelInput
                    autoComplete="off"
                    id="firstname"
                    name="firstName"
                    label={t('First Name')}
                    size="small"
                    inlineLabel
                    value={formdata.firstName}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    error={formdata.errors.firstName}
                    helperText={formdata.errors.firstName}
                  />
                </StyledFormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <StyledFormControl>
                  <FieldLabelInput
                    id="middlename"
                    label={t('Middle Name')}
                    name="middleName"
                    size="small"
                    inlineLabel
                    value={formdata.middleName}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    error={formdata.errors.middleName}
                    helperText={formdata.errors.middleName}
                  />
                </StyledFormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <StyledFormControl>
                  <FieldLabelInput
                    id="lastname"
                    label={t('Last Name')}
                    name="lastName"
                    size="small"
                    inlineLabel
                    value={formdata.lastName}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    error={formdata.errors.lastName}
                  />
                </StyledFormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <StyledFieldLabelInput
                  id="knownas"
                  label={t('Known As')}
                  name="knownAs"
                  size="small"
                  inlineLabel
                  value={formdata.knownAs}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.knownAs}
                  helperText={formdata.errors.knownAs}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <StyledFormControl
                  error={!!formdata.errors.birthDate}
                  data-testid="date-formcontrol"
                >
                  <Datepicker
                    disablePast={false}
                    id="dateofbirth"
                    margin="dense"
                    disableFuture
                    fullWidth
                    label={t('Date of Birth')}
                    inputFormat={viewDateFormat}
                    value={formdata.birthDate}
                    onChange={handleComponentChange}
                    name="birthDate"
                  />
                  {formdata.errors.birthDate && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.birthDate}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <StyledFormControl error={!!formdata.errors.genderID}>
                  <VineaAutoComplete
                    value={formdata.genderID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    options={genderList}
                    labelVariant="body1"
                    inlineLabel={false}
                    id="gender"
                    name={t('Gender')}
                    label={t('Gender')}
                    inputProps={{
                      name: 'genderID',
                    }}
                  />
                  {formdata.errors.genderID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.genderID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <StyledFormControl error={!!formdata.errors.nationalityID}>
                  <VineaAutoComplete
                    value={formdata.nationalityID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    options={nationalityList}
                    labelVariant="body1"
                    id="nationality"
                    name={t('Nationality')}
                    label={t('Nationality')}
                    inlineLabel={false}
                    inputProps={{
                      name: 'nationalityID',
                    }}
                  />
                  {formdata.errors.nationalityID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.nationalityID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <StyledFormControl error={!!formdata.errors.ethnicityID}>
                  <VineaAutoComplete
                    value={formdata.ethnicityID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    options={ethnicList}
                    labelVariant="body1"
                    id="ethnicity"
                    name={t('Ethnicity')}
                    label={t('Ethnicity')}
                    inlineLabel={false}
                    inputProps={{
                      name: 'ethnicityID',
                    }}
                  />
                  {formdata.errors.ethnicityID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.ethnicityID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <StyledFormControl
                  error={!!formdata.errors.preferredLanguageID}
                >
                  <VineaAutoComplete
                    value={formdata.preferredLanguageID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    options={languageList}
                    labelVariant="body1"
                    id="primarylanguage"
                    name={t('Primary Language')}
                    inlineLabel={false}
                    label={t('Primary Language')}
                    inputProps={{
                      name: 'preferredLanguageID',
                    }}
                  />
                  {formdata.errors.preferredLanguageID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.preferredLanguageID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <StyledFormControl
                  error={!!formdata.errors.secondaryLanguageID}
                >
                  <VineaAutoComplete
                    value={formdata.secondaryLanguageID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    options={languageList}
                    labelVariant="body1"
                    id="secondarylanguage"
                    name={t('Secondary Language')}
                    label={t('Secondary Language')}
                    inlineLabel={false}
                    inputProps={{
                      name: 'secondaryLanguageID',
                    }}
                    error={!!formdata.errors.secondaryLanguageID}
                  />
                  {formdata.errors.secondaryLanguageID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.secondaryLanguageID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <StyledFormControl error={!!formdata.errors.businessUnitID}>
                  <VineaAutoComplete
                    value={formdata.businessUnitID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    options={businessUnitList}
                    labelVariant="body1"
                    id="businessunit"
                    name={t('Business Unit')}
                    label={t('Business Unit')}
                    inlineLabel={false}
                    inputProps={{
                      name: 'businessUnitID',
                    }}
                  />
                  {formdata.errors.businessUnitID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.businessUnitID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Box ml={1} mb={2} style={{ flexDirection: 'column' }}>
          <Box
            m={2}
            sx={{
              width: '350px',
              minWidth: '250px',
            }}
          >
            <div>{t('Current Role')}</div>
            <div>
              <div>
                <ol>
                  {!isEmpty(activeRoles) &&
                    Array.isArray(activeRoles) &&
                    activeRoles.map(item => (
                      <li key={item.id}>{item.roleType}</li>
                    ))}
                </ol>
              </div>
              <div>
                <VineaButton
                  data-testid="BtnManageRoles"
                  autoFocus
                  onClick={handleManageRoles}
                  variant="text"
                  color="secondary"
                  textTransform="capitalize"
                >
                  {t('Manage Roles')}
                </VineaButton>
              </div>
            </div>
          </Box>

          <Box
            sx={{
              width: '350px',
              minWidth: '250px',
            }}
            my={2}
            mr={4}
          >
            <IdentityActivationSwitch
              handleOnToggleActive={handleOnToggleActive}
              isActive={isActive}
              identityName="person"
            />
          </Box>

          <Box my={2}>
            <DeleteIdentityButton
              handleOnDelete={handleOnConfirmDelete}
              identityName="person"
              isDisabled={deleteHasFailed}
            />
          </Box>
        </Box>

        <DiscardDialog
          open={discardDialogOpen}
          onClose={handleOnDialogClose}
          handleSaveChanges={handleSave}
          handleDiscardChanges={handleCancel}
        />

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
          }}
        >
          <Box mx={2}>
            <SplitButton
              color="success"
              onClick={handleSave}
              variant="contained"
              minWidth={130}
              disabled={saveButtonStatus}
            />
          </Box>
          <Box mx={2}>
            <VineaButton
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              {t('Close')}
            </VineaButton>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ManagePersonDetails;
