import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { FieldLabelInput } from '../../components/TextField';
import { VineaButton } from '../../components/VineaButton';
import { DiscardDialog } from '../../components/Dialog';
import { Datepicker } from '../../components/Datepicker';
import { formatDate } from '../../constants/formatter';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { viewDateFormat } from '../../constants';
import { FormControlLabel, InputAdornment } from '@mui/material';
//@ts-ignore
import { GreenCheckbox } from '../../components/CheckBox';

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

const StyledCloseDiv = styled('div')(() => ({
  paddingLeft: 10,
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
  display: 'flex',
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  minWidth: 150,
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

export function EditJobActivityRateDrawer({
  isOpen,
  isNew,
  handleOnClose,
  handleOnSave,
  handleOnChange,
  handleOnChangeCheckbox,
  handleOnDelete,
  jobActivityRateData = {},
  validationErrors = {},
}: {
  isOpen: boolean;
  isNew: boolean;
  handleOnClose: () => void;
  handleOnSave: () => void;
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnDelete: () => void;
  jobActivityRateData: any;
  validationErrors: any;
}) {
  const { t } = useTranslation();
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const drawerHeader = isNew ? 'Add Rate' : 'Edit Rate';
  useEffect(() => {
    validationErrors = {};
  }, [isOpen]);

  const toggleDrawer = (event: React.KeyboardEvent) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const onSave = () => {
    handleOnSave();
  };

  const handleDelete = () => {
    handleOnDelete();
  };

  const handleOnDiscard = () => {
    handleOnClose();
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={handleOnClickAway}
      >
        <Box sx={{ width: 380 }} role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography sx={{ padding: 2 }} variant="body1">
                {t(drawerHeader)}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOnDiscard}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />
            <Box my={2}>
              <StyledFormControl data-testid="createjobactivitydrawerpayrollrate">
                <StyledFieldLabelInput
                  type="number"
                  autoComplete="off"
                  id="payrollRate"
                  name="payrollRate"
                  label={t('Payroll Rate')}
                  inlineLabel
                  value={jobActivityRateData?.payrollRate}
                  onChange={handleOnChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={validationErrors.payrollRate}
                  fullWidth
                  onBlur={undefined}
                  classes={undefined}
                  disabled={undefined}
                  rows={undefined}
                  sx={undefined}
                />
              </StyledFormControl>
              <StyledFormControl data-testid="createjobactivitydrawercontractedrate">
                <StyledFieldLabelInput
                  type="number"
                  autoComplete="off"
                  id="contractedRate"
                  name="contractedRate"
                  label={t('Contracted Rate')}
                  inlineLabel
                  value={jobActivityRateData?.contractedRate}
                  onChange={handleOnChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={validationErrors.contractedRate}
                  fullWidth
                  onBlur={undefined}
                  classes={undefined}
                  rows={undefined}
                  sx={undefined}
                  disabled={undefined}
                />
              </StyledFormControl>
              <StyledFormControl
                data-testid="createjobactivitydatepickerformcontroleffectivefrom"
                error={validationErrors?.effectiveFrom}
              >
                <Datepicker
                  id="effectiveFrom"
                  label={t('Active From')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  name="effectiveFrom"
                  value={jobActivityRateData?.effectiveFrom || null}
                  onChange={handleOnChange}
                  inputProps={{
                    'data-id': 'effectiveFrom',
                    'data-name': 'effectiveFrom',
                  }}
                  fullWidth
                />
                {validationErrors.effectiveFrom && (
                  <FormHelperText id="component-error-text">
                    {validationErrors.effectiveFrom}
                  </FormHelperText>
                )}
              </StyledFormControl>
              <StyledFormControl
                data-testid="createjobactivitydatepickerformcontroleffectiveto"
                error={validationErrors?.effectiveTo}
              >
                <Datepicker
                  id="effectiveTo"
                  label={t('Active To')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  name="effectiveTo"
                  value={jobActivityRateData?.effectiveTo || null}
                  onChange={handleOnChange}
                  inputProps={{
                    'data-id': 'effectiveTo',
                    'data-name': 'effectiveTo',
                  }}
                  fullWidth
                />
                {validationErrors.effectiveTo && (
                  <FormHelperText id="component-error-text">
                    {validationErrors.effectiveTo}
                  </FormHelperText>
                )}
              </StyledFormControl>
              <StyledFormControl>
                <FormControlLabel
                  label="GST Applicable"
                  control={
                    <GreenCheckbox
                      checked={jobActivityRateData?.isGSTApplicable}
                      onChange={handleOnChangeCheckbox}
                      inputProps={{
                        'aria-label': 'primary checkbox',
                        name: 'isGSTApplicable',
                      }}
                    />
                  }
                />
              </StyledFormControl>
              {jobActivityRateData?.isGSTApplicable && (
                <StyledFormControl data-testid="createjobactivitydrawerpayrollrate">
                  <StyledFieldLabelInput
                    type="number"
                    autoComplete="off"
                    id="gstRate"
                    name="gstRate"
                    label={t('GST Rate')}
                    inlineLabel
                    value={jobActivityRateData?.gstRate}
                    onChange={handleOnChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={validationErrors.payrollRate}
                    fullWidth
                    onBlur={undefined}
                    classes={undefined}
                    disabled={undefined}
                    rows={undefined}
                    sx={undefined}
                  />
                </StyledFormControl>
              )}
            </Box>
            <StyledButtonControlDiv>
              <div>
                <VineaButton
                  color="success"
                  onClick={onSave}
                  variant="contained"
                  minWidth={150}
                >
                  {t('Save')}
                </VineaButton>
              </div>
              <StyledCloseDiv>
                <VineaButton
                  color="secondary"
                  onClick={handleOnDiscard}
                  variant="outlined"
                  minWidth={150}
                >
                  {t('Close')}
                </VineaButton>
              </StyledCloseDiv>
              {!isNew && (
                <StyledCloseDiv>
                  <VineaButton
                    color="error"
                    onClick={handleDelete}
                    variant="text"
                    minWidth={150}
                  >
                    {t('Delete')}
                  </VineaButton>
                </StyledCloseDiv>
              )}
            </StyledButtonControlDiv>
          </ErrorBoundary>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={() => setDiscardDialogOpen(false)}
            handleSaveChanges={handleOnDiscardSaveChanges}
            handleDiscardChanges={handleOnDiscardConfirm}
          />
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
}
