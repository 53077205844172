import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VineaButton } from '../VineaButton';
import { GreenSwitch } from '../Switch';
import GridWithFilter from './GridWithFilter';
import { RenderDataGridHeader } from './RenderDataGridHeader';
import { GridNoRowMessage } from './GridNoRowMessage';

export const DefaultRateGridComponent = ({
  heading,
  add,
  hasAddOption,
  tableData,
  onRowClick,
  //   isLoading,
  gridColumnState,
  activityWorkUnit,
  activityCostingUnit,
  activityDuration,
  activityWorkUnitSingle,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showActive, setShowActive] = React.useState(true);

  const tableDataFormattedData = tableData.map(da => ({
    id: da.id,
    effectiveFromDate: da.effectiveFromDate,
    effectiveToDate: da.effectiveToDate,
    internalWorkCost: da.internalWorkCost,
    targetVelocity: da.targetVelocity,
    defaultChargeOutRate: da.defaultChargeOutRate,
    defaultCostRate: da.defaultCostRate,
  }));

  function getTargetVelocityValue(params) {
    return `${params.row.targetVelocity} ${activityWorkUnit}/${activityDuration}`;
  }

  function getCostRateValue(params) {
    return `${params.row.defaultCostRate} ${activityCostingUnit}/${activityWorkUnitSingle}`;
  }

  function getCostOutValue(params) {
    return `${params.row.defaultChargeOutRate} ${activityCostingUnit}/${activityWorkUnitSingle}`;
  }

  const gridDataColums = [
    {
      field: 'effectiveFromDate',
      headerName: 'Effective From',
      minwidth: '150px',
      flex: 0.1,
      resizable: false,
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'effectiveToDate',
      headerName: 'Effective To',
      minwidth: '150px',
      flex: 0.1,
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'targetVelocity',
      headerName: 'Target Velocity',
      hideable: false,
      flex: 0.1,
      renderHeader: RenderDataGridHeader,
      valueGetter: getTargetVelocityValue,
    },
    {
      field: 'defaultChargeOutRate',
      headerName: 'Charge Rate',
      hideable: false,
      flex: 0.1,
      renderHeader: RenderDataGridHeader,
      valueGetter: getCostOutValue,
    },
    {
      field: 'defaultCostRate',
      headerName: 'Cost Rate',
      hideable: false,
      flex: 0.1,
      renderHeader: RenderDataGridHeader,
      valueGetter: getCostRateValue,
    },
  ];

  const gridXData = { columns: gridDataColums, rows: tableDataFormattedData };

  const NoRowsOverlayMessage = () => (
    <Box mt={10} data-testid="blockgrid-norowmessage">
      <GridNoRowMessage message="Sorry, there were no records found." />
    </Box>
  );

  return (
    <Stack data-testid="blockgrid-component" sx={{ height: '100%' }}>
      <Paper elevation={0} data-testid="detail-info">
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          p={2}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h6" color="textPrimary">
              {heading}
            </Typography>
          </Box>

          <Box>
            <Box display="inline-flex">
              {hasAddOption && (
                <VineaButton
                  variant="contained"
                  color="secondary"
                  sx={{
                    marginRight: theme.spacing(1),
                  }}
                  onClick={add}
                  data-testid="btn-add"
                >
                  {t('Add Rate')}
                </VineaButton>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>

      <Paper elevation={0} data-testid="detail-info" sx={{ height: '100%' }}>
        <GridWithFilter
          data={gridXData}
          rowHeight={30}
          headerHeight={56}
          noRowsOverlay={NoRowsOverlayMessage}
          actionType="ACTIVITY_DEFAULT_RATE_GRID_COLUMN_UPDATE"
          gridColumnState={gridColumnState}
          advancedSearch={false}
          onRowClick={onRowClick}
        />
      </Paper>
    </Stack>
  );
};

DefaultRateGridComponent.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  manage: PropTypes.func.isRequired,

  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  // isLoading: PropTypes.bool,
  hasManageOption: PropTypes.bool,
  add: PropTypes.func,
  hasAddOption: PropTypes.bool,
};

DefaultRateGridComponent.defaultProps = {
  tableData: [],
  hasManageOption: true,
  add: f => f,
  hasAddOption: false,
};

function areEqual(prevProps, nextProps) {
  const px = pick(prevProps, ['pathParamId', 'tableData']);
  const nx = pick(nextProps[('pathParamId', 'tableData')]);
  return isEqual(px, nx);
}

export const DefaultRateGrid = React.memo(DefaultRateGridComponent, areEqual);
