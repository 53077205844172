// eslint-disable-next-line no-unused-vars
import React from 'react';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

export const StyledListItem = styled(ListItem)(({ theme, isActive }) => ({
  fontSize: '1.5rem',
  '& .MuiListItemText-primary, .MuiListItemIcon-root': {
    // @ts-ignore
    color: theme.palette.icon,
  },
  ...(isActive && {
    '& .MuiListItemText-primary, .MuiListItemIcon-root': {
      // @ts-ignore
      color: theme.palette.primary.contrastText,
    },
  }),
}));
