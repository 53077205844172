/* eslint-disable spaced-comment */
/* eslint-disable react/require-default-props */
import React from 'react';
import Button from '@mui/material/Button';

type ButtonColour =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'info'
  | 'error';

type VineaButtonProps = {
  children: React.ReactNode | Function | string | any;
  color: ButtonColour;
  isExtraSmall?: boolean;
  minWidth?: string | number;
  className?: string | Function | any;
  // eslint-disable-next-line no-undef
  onClick: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
};

const VineaButton = ({
  children,
  className,
  minWidth,
  ...props
}: VineaButtonProps) => {
  return (
    <Button {...props} component="button">
      {children}
    </Button>
  );
};

export default VineaButton;
