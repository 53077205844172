/**
 * Reducer action types
 */

import shortid from 'shortid';
import { format } from 'date-fns';
import { apiTypes, dateFormat } from '../../constants';

export const types = {
  RECEIVE: 'RECEIVE',
  UPDATE: 'UPDATE',
  ERROR: 'ERROR',
  INITIAL: 'INITIAL',
};

/**
 * Initial state for Add / Edit Vintage Screen;
 */
const today = new Date();
const dateValue = format(today, dateFormat);

export const classificationState = {
  data: [
    {
      classificationGroupID: 0,
      classificationID: 0,
      effectiveFromDate: dateValue,
      effectiveToDate: null,
      errors: {},
      sequenceId: 0,
    },
  ],
};

export const vineyardState = {
  vineyardName: null,
  vineyardKnownAs: null,
  totalVineyardArea: 0,
  plantedArea: 0,
  subRegionID: null,
  businessUnitID: null,
  errors: {},
};

export const accommodationState = {
  accommodationName: null,
  capacity: 0,
  businessUnitID: 0,
  errors: {},
};

export const consumableState = {
  consumableName: null,
  consumableDescription: null,
  consumableTypeID: null,
  businessUnitID: null,
  errors: {},
};

export const planState = {
  planName: '',
  planDescription: '',
  planTypeID: 0,
  planStatusID: 0,
  isLocked: false,
  planStartDate: '',
  planEndDate: '',
  planVintageID: 1,
  durationID: 1,
  blocks: [],
  businessUnitID: 0,
  errors: {},
};

export const jobState = {
  identityTypeID: 0,
  businessUnitID: 0,
  jobTypeID: 0,
  jobStatusID: 0,
  jobName: null,
  jobDescription: null,
  invoiceMethodID: 0,
  quantityTypeID: 0,
  workRecordLevelID: 0,
  workRecordUnitTypeID: 0,
  invoiceToIdentityID: 0,
  siteTypeID: null,
  poNumber: null,
  createdDate: '2023-02-22T03:18:03.1066667+00:00',
  ts: 'AAAAAAAJ9R8=',
  errors: {},
};

export const activityState = {
  activityCode: '',
  activityDescription: '',
  activityIsPlannable: false,
  activityResourceUnitId: 0,
  activityWorkUnitID: 0,
  activityCostingUnitID: 0,
  activityVelocityDurationID: 0,
  activityGroupID: 0,
  activitytBusinessUnitID: 0,
  errors: {},
  colourHexCode: '#000000',
};

export const manageActivityState = {
  activityCode: '',
  activityDescription: '',
  activityGroupID: 0,
  costingUnitID: 0,
  createdDate: '',
  id: 0,
  identityTypeID: 25,
  isPlannable: false,
  resourceUnitID: 0,
  ts: '',
  velocityDurationID: 0,
  workUnitID: 0,
  colourHexCode: '#000000',
  errors: {},
};

export const organisationState = {
  organisationName: null,
  organisationKnownAs: null,
  businessUnitID: 0,
  errors: {},
};

export const contactDetailsState = {
  email: null,
  phoneNo: null,
  url: null,
  address1: null,
  address2: null,
  suburb: null,
  postcode: null,
  country: null,
  hasDifferentAddress: false,
  mailaddress1: null,
  mailaddress2: null,
  mailsuburb: null,
  mailpostCode: null,
  mailcountry: null,
  mailemail: null,
  mailphoneNo: null,
  mailurl: null,
  errors: {},
  mailErrors: {},
};

export const referenceState = {
  data: [
    {
      referenceName: null,
      referenceType: 0,
      effectiveFrom: dateValue,
      effectiveTo: null,
      errors: {},
      sequenceId: 0,
    },
  ],
};

export const personState = {
  salutationID: 0,
  firstName: null,
  lastName: null,
  knownAs: null,
  middleName: null,
  birthDate: null,
  genderID: 0,
  nationalityID: 0,
  ethnicityID: 0,
  preferredLanguageID: 0,
  secondaryLanguageID: 0,
  businessUnitID: 0,
  errors: {},
  status: false,
};

export const userState = {
  firstName: '',
  lastName: '',
  businessUnitID: 0,
  isUserActive: false,
  isVineaAdmin: false,
  identityId: 0,
  workEmail: '',
  errors: {},
};

export const relationshipState = {
  id: shortid.generate(),
  identityID: null,
  relatesTo: '',
  relatesToID: 0,
  relationshipFrom: '',
  relationshipName: null,
  relationshipTypeID: 0,
  relationshipType: '',
  ownerIdentityID: null,
  ownerName: '',
  dependentIdentityID: null,
  dependentName: '',
  comment: '',
  isAdditionalText: null,
  additionalText: '',
  isDisplayOnPanel: null,
  effectiveToDate: null,
  isEditMode: true,
  methodType: apiTypes.POST,
  isActive: false,
  direction: '',
  errors: {},
  warning: null,
  ts: null,
};

export const serviceproviderState = {
  serviceProviderName: '',
  serviceProviderKnownAs: '',
  payrollSystemID: 0,
  businessUnitID: 0,
  isPrimary: false,
  errors: {},
};

export const dataConnectionState = {
  name: '',
  businessUnitID: 0,
  connectionDescription: '',
  dataProviderID: 0,
  providerTypeID: 0,
  ts: null,
  password: '',
  errors: {},
};

export const supplyContractState = {
  contractName: '',
  businessUnitID: 0,
  supplyContractTypeID: 0,
  purchasingPartyIdentityID: 0,
  supplyingPartyIdentityID: 0,
  primaryContactID: 0,
  dateSigned: '',
  dateEnded: '',
  ts: null,
  errors: {},
};

export const addGenericReducer = (state, action) => {
  const { payload, name = null, type } = action;
  switch (type) {
    case types.RECEIVE:
      return {
        ...state,
        ...payload,
      };
    case types.UPDATE:
      return {
        ...state,
        ...payload,
      };
    case types.ERROR:
      return {
        ...state,
        errors: payload,
      };
    case types.INITIAL:
      if (name === 'relationship') {
        return relationshipState;
      }
      return state;
    default:
      return state;
  }
};
