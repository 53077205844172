import {
  searchIdentity,
  searchUsers,
  commonAddIdentity,
  commonFetchIdentity,
  commonUpdateIdentity,
  getAddressDetails,
  searchSamples,
  fetchIdentityTypeService,
  searchPlans,
  searchSupplyContracts,
} from '../../services/fetchCommonService';
import { sagaActionTypes as Types } from '../../constants';

export const commonActionService = actionType => {
  switch (actionType) {
    case Types.FETCH_ADDRESS:
      return getAddressDetails;
    case Types.SEARCH_IDENTITIES:
      return searchIdentity;
    case Types.SEARCH_SAMPLES:
      return searchSamples;
    case Types.SEARCH_USERS:
      return searchUsers;
    case Types.SEARCH_PLAN:
      return searchPlans;
    case Types.SEARCH_SUPPLY_CONTRACT:
      return searchSupplyContracts;
    case Types.FETCH_ALL_IDENTITIES:
    case Types.FETCH_SPECIFIC_IDENTITY:
    case Types.FETCH_VINEYARD_BLOCKS:
    case Types.FETCH_IDENTITY_VALUES:
    case Types.FETCH_BLOCK_VINTAGES:
    case Types.FETCH_VINEYARD_BLOCK_ROWS:
    case Types.FETCH_IDENTITY_NOTES:
    case Types.FETCH_SAMPLE_HEADERS:
    case Types.FETCH_SAMPLE_BLOCK_VINTAGES:
      return commonFetchIdentity;
    case Types.FORM_SUBMIT:
      return commonAddIdentity;
    case Types.UPDATE_IDENTITIES:
      return commonUpdateIdentity;
    case Types.FETCH_IDENTITIES:
      return fetchIdentityTypeService;
    default:
      return searchIdentity;
  }
};
