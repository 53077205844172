/* eslint-disable no-undef */
import { AppConfigurationClient } from '@azure/app-configuration';
import { useMemo, useState } from 'react';
import logger from '../utils/winstonLogger';

/**
 * Retrieves the specified feature flag from Azure App Configuration.
 * @param {string} flagKey App Config Feature Flag key
 * @returns the feature flag for the specified key
 */
const useFeatureFlag = (flagKey = '') => {
  const [enabled, setEnabled] = useState(undefined);
  const isProd = window.location.href.includes('myvinea');
  logger.debug(process.env.APPCONFIG_CONN);
  // TODO: Add Connection String
  useMemo(async () => {
    if (!flagKey || !flagKey.toString().trim().length) {
      logger.error('A feature flag key must be supplied.');
    } else {
      try {
        const client = new AppConfigurationClient(
          process.env.REACT_APP_APPCONFIG_CONN,
        );
        const result = await client.getConfigurationSetting({
          key: `.appconfig.featureflag/${flagKey.toString().trim()}`,
          label: isProd ? 'Production' : 'Test',
        });
        if (result && typeof result === 'object') {
          logger.debug(
            `Feature: ${JSON.parse(result.value).id} ${
              JSON.parse(result.value).enabled
            }`,
          );
          setEnabled(JSON.parse(result.value).enabled);
        }
      } catch (error) {
        logger.error(error);
      }
    }
  }, [flagKey, isProd]);

  return enabled;
};

/**
 * Retrieves the specified configuration from Azure App Configuration.
 * @param {string} configKey App Config Key
 * @returns the configuration for the specified key
 */
const useConfiguration = (configKey = '') => {
  const [config, setConfig] = useState('');
  // TODO: Add Connection String

  useMemo(async () => {
    if (!configKey || !configKey.toString().trim().length) {
      logger.error('A config key must be supplied.');
    } else {
      try {
        const client = new AppConfigurationClient(
          process.env.REACT_APP_APPCONFIG_CONN,
        );
        const result = await client.getConfigurationSetting({
          key: configKey.toString().trim(),
        });
        if (result) {
          logger.debug(`Config: ${result.key} ${result.value} `);
          setConfig(result.value);
        }
      } catch (error) {
        logger.error(error);
      }
    }
  }, [configKey]);

  return { config };
};

export { useFeatureFlag, useConfiguration };
