// @ts-ignore
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import head from 'lodash/head';
import filter from 'lodash/filter';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { styled, useTheme } from '@mui/material/styles';
import { AzureMapsProvider } from 'react-azure-maps';
import { wktToGeoJSON } from '@terraformer/wkt';
import { ErrorBoundary } from 'react-error-boundary';

import { PAGE_LOOKSUPS } from '../../constants';
import BlocksFilter from '../BlocksMap/BlocksFilter';
import AzureMaps from '../../components/MapsComponent/VineaMapController';
import LookupLayout from '../../layouts/LookupLayout';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { find, isNil, map } from 'lodash';
import { getSupplyContractBlockGeometries } from '../../redux/selectors';

const RootDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: '100%',
  '& .react-swipeable-view-container': { height: '100%' },
}));

const SupplyContractBlocksMap = () => {
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const { id } = useParams();
  const supplyContractID = useMemo(() => {
    return !isNil(id) ? parseInt(id) : 0;
  }, [id]);

  const [isFilterOpen, setFilter] = useState(true);
  const [selectedBlocks, setSelectedBlocks] = useState<any[]>([]);

  // Selector Data
  const blocksGeometryData: any = useSelector(getSupplyContractBlockGeometries);

  let vineyardID = get(
    find(blocksGeometryData, { supplyContractID: supplyContractID }),
    'vineyardID',
  );

  const vineyardGeometry: any = useSelector(
    VineaNovaSelectors.getVineyardGeometryEntity,
  );

  const vineyardGeometryMeta: any = useSelector(
    VineaNovaSelectors.getVineyardGeometryEntityMeta,
  );

  const supplyContractBlocksGeometryMeta: any = useSelector(
    VineaNovaSelectors.getSupplyContractBlocksGeometryEntityMeta,
  );

  const handleOnfilterToggle = (val: any) => {
    setFilter(val);
  };
  const handleOnBlockSelect = (val: any) => {
    const selected = selectedBlocks.findIndex(block => val.id === block.id);
    if (selected === -1) {
      setSelectedBlocks([...selectedBlocks, val]);
    } else {
      const newSelected = selectedBlocks.filter(
        (block: any) => val.id !== block.id,
      );
      setSelectedBlocks(newSelected);
    }
  };
  const handleOnMultipleBlockSelect = (val: any) => {
    setSelectedBlocks(val);
  };

  const vineyardGeometryWkt = React.useMemo(() => {
    if (vineyardGeometryMeta.isLoading) {
      return '';
    }
    if (isEmpty(vineyardGeometry) || isEmpty(vineyardGeometry.data)) {
      return '';
    }
    const currentVineyard = vineyardGeometry.data?.filter(
      (vineyard: any) => vineyard.id === Number(vineyardID),
    );
    const geometryData = isEmpty(get(head(currentVineyard), 'geometry', null))
      ? ''
      : wktToGeoJSON(currentVineyard[0].geometry);
    return {
      type: 'Feature',
      geometry: geometryData,
    };
  }, [vineyardGeometryMeta.isLoading, vineyardGeometry, vineyardID]);

  const allBlockFeatures: any = React.useMemo(() => {
    if (isEmpty(blocksGeometryData)) {
      return [];
    }

    const allFeatures = blocksGeometryData.map((eachBlock: any) => {
      return {
        type: 'Feature',
        properties: {
          id: eachBlock.id,
          labelName: get(eachBlock, 'blockFullName', ''),
        },
        geometry: wktToGeoJSON(eachBlock.geometry),
      };
    });

    const allBlocksgeoJson = {
      type: 'FeatureCollection',
      features: allFeatures,
    };
    return allBlocksgeoJson;
  }, [blocksGeometryData]);

  const selectedBlockFeatures = React.useMemo(() => {
    if (
      isEmpty(allBlockFeatures) ||
      !supplyContractBlocksGeometryMeta.isLoaded ||
      supplyContractBlocksGeometryMeta.isLoading
    ) {
      return [];
    }
    const selectedFeatures = allBlockFeatures.features.filter((feature: any) =>
      selectedBlocks.some(e => e.id === feature.properties.id),
    );
    const selectedgeoJson = {
      type: 'FeatureCollection',
      features: selectedFeatures,
    };
    return selectedgeoJson;
  }, [selectedBlocks, allBlockFeatures]);

  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.supplyContractBlocksGeometry.get.request({
        queryParams: {
          SupplyContractID: supplyContractID,
        },
      }),
    );

    dispatchAPI(
      VineaNovaActions.api.v1.vineyardBlocksGeometry.get.request({
        queryParams: {
          VineyardID: vineyardID,
        },
      }),
    );

    dispatchAPI(
      VineaNovaActions.api.v1.vineyardGeometry.get.request({
        queryParams: {
          VineyardID: vineyardID,
        },
      }),
    );
  }, [dispatchAPI, vineyardID, supplyContractID]);

  return (
    <LookupLayout
      id={supplyContractID}
      lookups={[PAGE_LOOKSUPS.BLOCK_MAPS_LOOKUPS]}
    >
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        data-testid="blockMap-tab"
        sx={{ height: '100%' }}
      >
        <RootDiv data-testid="blockMap-container">
          <Box
            sx={{
              pb: 3,
              ...(isFilterOpen && {
                display: 'flex',
                [theme.breakpoints.down('md')]: {
                  flex: '0.4',
                },
                [theme.breakpoints.up('md')]: {
                  flex: '0.3',
                },
                [theme.breakpoints.up('lg')]: {
                  flex: '0.18',
                },
              }),
              ...(!isFilterOpen && { display: 'flex', flex: 0 }),
            }}
          >
            <BlocksFilter
              isFilterOpen={isFilterOpen}
              onFilterToggle={handleOnfilterToggle}
              selectedBlocks={selectedBlocks}
              handleOnBlockSelect={handleOnBlockSelect}
              handleOnMultipleBlockSelect={handleOnMultipleBlockSelect}
              allBlocksData={blocksGeometryData}
            />
          </Box>
          <Box
            display="flex"
            flex={1}
            ml={2}
            pb={3}
            data-testid="vinea-block-maps"
          >
            {supplyContractBlocksGeometryMeta.isLoading && (
              <div>Loading...</div>
            )}
            {!supplyContractBlocksGeometryMeta.isLoading && (
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <AzureMapsProvider>
                  <AzureMaps
                    allGeoJson={allBlockFeatures}
                    seletedGeoJson={selectedBlockFeatures}
                    vineyardID={vineyardID}
                    vineyardGeometry={vineyardGeometryWkt}
                  />
                </AzureMapsProvider>
              </ErrorBoundary>
            )}
          </Box>
        </RootDiv>
      </Box>
    </LookupLayout>
  );
};

export default SupplyContractBlocksMap;
