import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { isEqual, get, omit, isEmpty, isNil, capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  VineaNovaSelectors,
  VineaNovaActions,
} from 'vineanova-redux-artifacts';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { IdentitySubMenu } from '../../components/IdentitySubMenu';
import { FilterScreen } from '../../components/FilterScreen';
import { IdentitySearchResults } from './IdentitySearchResults';
import {
  getLookupReferencesByOrgId,
  getLookupClassificationGroupsByOrgId,
  getBusinessUnit,
  getJobStatus,
  getLkpRoleTypeIdentityType,
  areLookupsLoaded,
  getUserPreferences,
  getSiteType,
} from '../../redux/selectors';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import {
  sagaActionTypes as Types,
  reducers,
  actionTypes,
  IdentityTypeIds,
  sagaActionTypes,
} from '../../constants';
import { useIdentityTypeId } from '../../hooks/useIdentityTypeId';
import useLocationListener from '../../hooks/useLocationListener';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import useIdentityTypeScreenNameHook from '../../hooks/useIdentityTypeScreenNameHook';

const RootDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: '50%',
}));

const StyledBox = styled(Box)(({ theme, isFilterOpen }) => ({
  ...(isFilterOpen && {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flex: '0.4',
    },
    [theme.breakpoints.up('md')]: {
      flex: '0.3',
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0.18',
    },
  }),
  ...(!isFilterOpen && { display: 'flex', flex: 0 }),
}));

const reducerMap = {
  [IdentityTypeIds.VINEYARD]: reducers.vineyardSearchFilter,
  [IdentityTypeIds.PERSON]: reducers.personSearchFilter,
  [IdentityTypeIds.ORGANISATION]: reducers.organisationSearchFilter,
  [IdentityTypeIds.SERVICE_PROVIDER]: reducers.serviceProviderSearchFilter,
  [IdentityTypeIds.JOB]: reducers.jobSearchFilter,
  [IdentityTypeIds.DATA_CONNECTION]: reducers.connectionSearchFilter,
  [IdentityTypeIds.CONSUMABLE]: reducers.consumableSearchFilter,
  [IdentityTypeIds.VEHICLE]: reducers.vehicleSearchFilter,
  [IdentityTypeIds.ACCOMMODATION]: reducers.accommodationSearchFilter,
  [IdentityTypeIds.ACTIVITY]: reducers.activitySearchFilter,
};

const selectorMap = {
  [IdentityTypeIds.PERSON]: state => state.personSearchFilter,
  [IdentityTypeIds.VINEYARD]: state => state.vineyardSearchFilter,
  [IdentityTypeIds.ORGANISATION]: state => state.organisationSearchFilter,
  [IdentityTypeIds.SERVICE_PROVIDER]: state =>
    state.serviceProviderSearchFilter,
  [IdentityTypeIds.JOB]: state => state.jobSearchFilter,
  [IdentityTypeIds.DATA_CONNECTION]: state => state.connectionSearchFilter,
  [IdentityTypeIds.CONSUMABLE]: state => state.consumableSearchFilter,
  [IdentityTypeIds.VEHICLE]: state => state.vehicleSearchFilter,
  [IdentityTypeIds.ACCOMMODATION]: state => state.accommodationSearchFilter,
  [IdentityTypeIds.ACTIVITY]: state => state.activitySearchFilter,
};

const IdentitySearch = () => {
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const newLocationKeys = useLocationListener();
  const { t } = useTranslation();
  const { vineyardTypeScreenName, licenseeTypeID } =
    useIdentityTypeScreenNameHook();
  const identityTypeId = useIdentityTypeId(vineyardTypeScreenName);

  const [locationKeys, setLocationKeys] = useState([]);
  const { setIsLocked, isLocked } = useTabChangeLock();

  const [filterText, setFilterText] = React.useState();
  const firstFilterInputRef = React.useRef();

  /** Selectors */

  const allIdentities = useSelector(VineaNovaSelectors.getSearchIdentityEntity);
  const jobIdentities = useSelector(VineaNovaSelectors.getSearchJobsEntity);

  const lkpReferences = useSelector(state =>
    getLookupReferencesByOrgId(state, identityTypeId),
  );
  const lkpClassificationsGroup = useSelector(state =>
    getLookupClassificationGroupsByOrgId(state, identityTypeId),
  );
  const { basicSettings } = useSelector(state => getUserPreferences(state));
  const {
    filterPanelCollapsed,
    businessUnitID: defaultBusinessUnitID,
    searchFilterBusinessUnitID,
  } = basicSettings;

  const createIdentityEnabled = useMemo(() => {
    if (isNil(defaultBusinessUnitID) || defaultBusinessUnitID === 0)
      return false;
    return true;
  }, [defaultBusinessUnitID]);

  const { data: searchFilterData = {} } = useSelector(
    selectorMap[identityTypeId],
  );

  const filterData = useMemo(() => {
    if (
      !isNil(searchFilterBusinessUnitID) &&
      searchFilterBusinessUnitID !== 0
    ) {
      return {
        ...searchFilterData,
        businessUnitID: searchFilterBusinessUnitID,
      };
    } else {
      return searchFilterData;
    }
  }, [searchFilterData]);

  const lkpclassifications = useSelector(
    VineaNovaSelectors.getlookupClassificationEntityData,
  );

  const isLookupsLoaded = useSelector(areLookupsLoaded);
  const lkpBusinessUnit = useSelector(state => getBusinessUnit(state));
  const lkpSiteType = useSelector(state => getSiteType(state));
  const lkpJobStatus = useSelector(state => getJobStatus(state));

  const lkpRoleType = useSelector(state =>
    getLkpRoleTypeIdentityType(state, identityTypeId),
  );

  // map from each filter name to a friendly name/value to show on screen
  const filterNameMap = React.useMemo(() => {
    const getLkpText = (id, lkp) =>
      id === 0 ? [] : [lkp?.find(v => v?.id === id)?.value];

    return {
      referenceTypeID: {
        name: 'References',
        getValueText: v => getLkpText(v, lkpReferences),
      },
      classificationTypeID: {
        name: 'Classification Name',
        getValueText: v => getLkpText(v, lkpclassifications),
      },
      businessUnitID: {
        name: 'Business Unit',
        getValueText: v => getLkpText(v, lkpBusinessUnit),
      },
      name: { name: 'Name', getValueText: v => [v] },
      refValue: { name: 'Reference Value', getValueText: v => [v] },
      findNonActive: { name: 'Active only', getValueText: v => [!v] },
      roleTypeID: {
        name: 'Role',
        getValueText: v => getLkpText(v, lkpRoleType),
      },
      activityCode: { name: 'Activity Code', getValueText: v => [v] },
      jobName: { name: 'Job Name', getValueText: v => [v] },
      jobID: { name: 'Job ID', getValueText: v => [v] },
      statusID: {
        name: 'Status',
        getValueText: v => getLkpText(v, lkpJobStatus),
      },
      siteTypeID: {
        name: 'Site Type',
        getValueText: v => getLkpText(v, lkpSiteType),
      },
    };
  }, [
    lkpBusinessUnit,
    lkpReferences,
    lkpRoleType,
    lkpclassifications,
    lkpJobStatus,
    lkpSiteType,
  ]);

  const defaultFilterValues = React.useMemo(
    () => ({
      businessUnitID: basicSettings.businessUnitID,
      findNonActive: false,
    }),
    [basicSettings.businessUnitID],
  );

  /** Dispatches */
  const onSearchIdentities = React.useCallback(
    searchParams => {
      if (identityTypeId !== IdentityTypeIds.JOB) {
        dispatchAPI(
          VineaNovaActions.api.v1.searchIdentity.post.request({
            postBody: {
              Name:
                identityTypeId === IdentityTypeIds.ACTIVITY
                  ? searchParams?.activityCode
                  : searchParams?.name || null,
              ReferenceTypeID: searchParams?.referenceTypeID || null,
              RefValue: searchParams?.refValue || null,
              ClassificationTypeID: searchParams?.classificationTypeID || null,
              FindNonActive: !!searchParams?.findNonActive,
              RoleTypeID: searchParams?.roleTypeID || null,
              ...(identityTypeId !== IdentityTypeIds.ACTIVITY && {
                BusinessUnitID: searchParams?.businessUnitID,
              }),
              IdentityTypeID: identityTypeId,
              SiteTypeID: searchParams?.siteTypeID || null,
            },
          }),
        );
      } else {
        dispatchAPI(
          VineaNovaActions.api.v1.searchJobs.post.request({
            postBody: {
              JobName: searchParams?.jobName || null,
              JobID: searchParams?.jobID || null,
              BusinessUnitID: searchParams?.businessUnitID,
              SiteTypeID:
                get(searchParams, 'siteTypeID', 0) > 0
                  ? searchParams?.siteTypeID
                  : null,
              StatusID: searchParams?.statusID || null,
              FindNonActive: !!searchParams?.findNonActive,
            },
          }),
        );
      }

      const newFilterText = Object.keys(
        omit(searchParams, ['classificationGroupID']),
      )?.map(filterName => {
        const { name, getValueText } = get(filterNameMap, [filterName], {});
        return name
          ? { filterName: name, values: getValueText(searchParams[filterName]) }
          : { filterName, values: [searchParams[filterName]] };
      });
      setFilterText(newFilterText);
    },
    [dispatchAPI, filterNameMap, identityTypeId],
  );

  const onUpdateFilterData = React.useCallback(
    data => {
      dispatchAPI({
        type: actionTypes.updateData,
        name: reducerMap[identityTypeId],
        payload: data,
      });
      dispatchAPI({
        type: 'BASIC_SETTINGS_UPDATE',
        payload: {
          ...basicSettings,
          searchFilterBusinessUnitID: data.businessUnitID,
        },
      });
    },
    [dispatchAPI, identityTypeId],
  );

  const handleOnFilterToggle = () => {
    dispatchAPI({
      type: 'BASIC_SETTINGS_UPDATE',
      payload: {
        ...basicSettings,
        filterPanelCollapsed: !filterPanelCollapsed,
      },
    });
  };

  const handleOnFilterOpen = () => {
    if (filterPanelCollapsed) {
      dispatchAPI({
        type: 'BASIC_SETTINGS_UPDATE',
        payload: {
          ...basicSettings,
          filterPanelCollapsed: false,
        },
      });
    } else if (firstFilterInputRef.current) {
      firstFilterInputRef.current?.focus();
    }
  };

  const handleOnNewOrganisation = () => {
    if (identityTypeId === IdentityTypeIds.VINEYARD)
      navigate(
        `/organisation/${vineyardTypeScreenName}s/new${vineyardTypeScreenName}`,
      );
    else if (identityTypeId === IdentityTypeIds.PERSON)
      navigate('/person/newperson');
    else if (identityTypeId === IdentityTypeIds.SERVICE_PROVIDER)
      navigate('/organisation/serviceproviders/newserviceprovider');
    else if (identityTypeId === IdentityTypeIds.JOB) navigate('/job/newjob');
    else if (identityTypeId === IdentityTypeIds.DATA_CONNECTION)
      navigate('/connections/newconnection');
    else if (identityTypeId === IdentityTypeIds.ORGANISATION)
      navigate(`/organisation/other-organisations/neworganisation`);
    else if (identityTypeId === IdentityTypeIds.CONSUMABLE)
      navigate(`/consumable/newconsumable`);
    else if (identityTypeId === IdentityTypeIds.ACCOMMODATION)
      navigate('/accommodation/newaccommodation');
    else if (identityTypeId === IdentityTypeIds.ACTIVITY)
      navigate('/activity/newactivity');
    else if (identityTypeId === IdentityTypeIds.VEHICLE)
      navigate('/vehicle/newvehicle');
  };

  const getNewButtonText = () => {
    if (identityTypeId === IdentityTypeIds.VINEYARD)
      return `Add ${capitalize(vineyardTypeScreenName)}`;
    if (identityTypeId === IdentityTypeIds.PERSON) return 'Add Person';
    if (identityTypeId === IdentityTypeIds.CONSUMABLE) return 'Add Consumable';
    if (identityTypeId === IdentityTypeIds.ACCOMMODATION)
      return 'Add Accommodaton';
    if (identityTypeId === IdentityTypeIds.SERVICE_PROVIDER)
      return 'Add Service Provider';
    if (identityTypeId === IdentityTypeIds.JOB) return 'Add Job';
    if (identityTypeId === IdentityTypeIds.DATA_CONNECTION)
      return 'Add Data Connection';
    if (identityTypeId === IdentityTypeIds.ACTIVITY) return 'Add Activity';
    if (identityTypeId === IdentityTypeIds.VEHICLE)
      return 'Add Vehicle/Equipment';

    return 'Add Organisation';
  };

  const handleOnExport = () => {
    dispatchAPI({ type: sagaActionTypes.EXPORT_EXCEL_REPORT });
  };

  useEffect(() => {
    if (isLocked) {
      setIsLocked(false);
    }
    if (!isEqual(locationKeys, newLocationKeys) && isLookupsLoaded) {
      setLocationKeys(newLocationKeys);
      let initialFilterData = {};
      // set defaults when first visiting (overridden if visited again)
      if (isEmpty(filterData)) {
        initialFilterData = { ...defaultFilterValues };
      } else {
        initialFilterData = { ...defaultFilterValues, ...filterData };
      }
      onSearchIdentities(initialFilterData);
      onUpdateFilterData(initialFilterData);

      dispatchAPI({
        type: Types.ADD_IDENTITIES,
        payload: {
          identityTypeId,
        },
      });
    }
  }, [
    defaultFilterValues,
    dispatchAPI,
    filterData,
    identityTypeId,
    isLocked,
    isLookupsLoaded,
    locationKeys,
    newLocationKeys,
    onSearchIdentities,
    onUpdateFilterData,
    setIsLocked,
  ]);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      data-testid="identity-search-parent-container"
      height="100%"
    >
      <Paper
        elevation={0}
        data-testid="identity-search-paper"
        sx={{
          padding: 1,
          marginBottom: 3,
        }}
      >
        <IdentitySubMenu
          onNewIdentityClick={handleOnNewOrganisation}
          newButtonText={t(getNewButtonText())}
          onExportClick={handleOnExport}
          newButtonDisabled={!createIdentityEnabled}
        />
      </Paper>
      <RootDiv data-testid="identity-search-rootdiv">
        <StyledBox isFilterOpen={!filterPanelCollapsed}>
          <FilterScreen
            isFilterOpen={!filterPanelCollapsed}
            onFilterToggle={handleOnFilterToggle}
            search={onSearchIdentities}
            lkpReferences={lkpReferences}
            lkpClassificationsGroup={lkpClassificationsGroup}
            lkpclassifications={lkpclassifications}
            identityTypeId={identityTypeId}
            lkpBusinessUnit={lkpBusinessUnit}
            lkpRoleType={lkpRoleType}
            lkpJobStatus={lkpJobStatus}
            lkpSiteType={lkpSiteType}
            filterData={filterData}
            onUpdateFilterData={onUpdateFilterData}
            firstFilterInputRef={firstFilterInputRef}
            nameMap={filterNameMap}
            licenseeTypeID={licenseeTypeID}
            defaultFilterValues={defaultFilterValues}
          />
        </StyledBox>
        <Box display="flex" flex={1} ml={2} pb={1}>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <IdentitySearchResults
              identities={
                identityTypeId === IdentityTypeIds.JOB
                  ? jobIdentities
                  : allIdentities
              }
              identityTypeID={identityTypeId}
              filterText={filterText}
              onFilterOpen={handleOnFilterOpen}
              vineyardTypeScreenName={vineyardTypeScreenName}
            />
          </ErrorBoundary>
        </Box>
      </RootDiv>
    </Box>
  );
};

IdentitySearch.propTypes = {};

IdentitySearch.defaultProps = {};

export default IdentitySearch;
