import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { filter, initial, isEmpty, isEqual, map, sortBy } from 'lodash';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { VineaButton } from '../../components/VineaButton';
import withFeatureFlagHOC from '../../hooks/withFeatureFlagHOC';
import useDebounce from '../../hooks/useDebounce';
import { PlanBlocksTransfer } from '../../components/Plan';
import { featureFlags, dateFormat } from '../../constants';
import {
  getUserPreferences,
  getAllPlanBlocksSelector,
  getAvailablePlanBlocksSelector,
  getIsLoadingAvailablePlanBlocks,
} from '../../redux/selectors';
import { useSnackbar } from 'notistack';

// eslint-disable-next-line react/prop-types
const PlanBlocksComponent = ({ pathParamId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatchAPI = useDispatch();
  const theme = useTheme();
  const { basicSettings } = useSelector(state => getUserPreferences(state));

  const {
    isLoading: planBlocksIsLoading,
    isLoaded: planBlocksIsLoaded,
    hasError: planBlocksHasError,
  } = useSelector(VineaNovaSelectors.getPlanBlocksEntityMeta);

  const {
    isLoading: deleteIsLoading,
    isLoaded: deleteIsLoaded,
    hasError: deleteHasError,
  } = useSelector(VineaNovaSelectors.getBulkPlanBlocksEntityMeta);
  const availablePlanBlocksLoading = useSelector(
    getIsLoadingAvailablePlanBlocks,
  );
  const [data, setData] = React.useState({ filterPanelCollapsed: false });

  // We have selected blocks default to initial
  // Selected values will be updated by user clicking arrows in transfer list
  // When clicking save, will compare selected values with initial values
  const initialRightBlocks = useSelector(getAllPlanBlocksSelector);
  const initialLeftBlocks = useSelector(getAvailablePlanBlocksSelector);
  const [newRightBlocks, setNewRightBlocks] = useState(
    map(initialRightBlocks, 'vineyardBlockID'),
  );
  const [newLeftBlocks, setNewLeftBlocks] = useState(initialLeftBlocks);
  //const [hasChanges, setHasChanges] = useState(false);

  const hasChanges = useMemo(() => {
    const initialBlocks = sortBy(map(initialRightBlocks, 'vineyardBlockID'));
    const newBlocks = sortBy(newRightBlocks);
    return !isEqual(initialBlocks, newBlocks);
  }, [newRightBlocks]);

  const [apiTrigger, setApiTrigger] = useState(false);
  const apiTriggerDebounce = useDebounce(apiTrigger, 100);
  const [deleteApiTrigger, setDeleteApiTrigger] = useState(false);
  const deleteApiTriggerDebounce = useDebounce(deleteApiTrigger, 100);

  const [allVineyards, setAllVineyards] = useState([]);

  const handleOnSave = () => {
    if (hasChanges) {
      // Add all blocks that are in initialLeftBlocks and are now in RightBlocks
      const blocksToAdd = initialLeftBlocks
        .filter(b => newRightBlocks.some(s => s === b.vineyardBlockID))
        .map(b => {
          return {
            VineyardBlockID: b.vineyardBlockID,
            PlanID: Number(pathParamId),
            PlanBlockNote: 'Added from UI',
            ActiveFromDate: format(new Date(), dateFormat),
            ActiveToDate: null,
          };
        });

      // Remove all blocks that are in initialRightBlocks and are not now in rightBlocks
      const planBlocksToRemove = initialRightBlocks
        .filter(b => !newRightBlocks.some(s => s === b.vineyardBlockID))
        .map(b => {
          return b.id;
        });

      if (!isEmpty(blocksToAdd)) {
        dispatchAPI(
          VineaNovaActions.api.v1.planBlocks.post.request({
            postBody: {
              planBlocks: blocksToAdd,
            },
          }),
        );
        setApiTrigger(true);
      }
      if (!isEmpty(planBlocksToRemove)) {
        dispatchAPI(
          VineaNovaActions.api.v1.bulkPlanBlocks.delete.request({
            postBody: {
              iDs: planBlocksToRemove,
            },
          }),
        );

        setDeleteApiTrigger(true);
      }
    }
  };

  const handleOnChange = evt => {
    const {
      target: { value, name },
    } = evt;
    setNewRightBlocks(value);
    console.log('handleOnChangeCheck', value, name);
  };

  const handleOnCancel = () => {
    setApiTrigger(true);
  };

  // Call plan blocks API
  const setBlocksData = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.planBlocks.get.request({
        queryParams: {
          planID: pathParamId,
        },
      }),
    );
    dispatchAPI(
      VineaNovaActions.api.v1.getAvailablePlanBlocks.get.request({
        queryParams: {
          planID: pathParamId,
        },
      }),
    );
    // setUpdatePlanBlocksCalled(false);
  };

  // Set Available Plan Blocks and Selected Plan Blocks
  React.useEffect(() => {
    let vineyardNames = [];
    const names = newRightBlocks
      .concat(newLeftBlocks)
      .concat(initialRightBlocks);
    map(names, block => {
      const alreadyExists = vineyardNames.some(e => e === block.vineyardName);
      if (!alreadyExists) {
        vineyardNames.push(block.vineyardName);
      }
    });
    setAllVineyards(vineyardNames);
  }, [newLeftBlocks, newRightBlocks, initialRightBlocks]);

  // Set data
  React.useEffect(() => {
    setData(basicSettings);
  }, [basicSettings]);

  // Set available plan blocks
  useEffect(() => {
    setNewLeftBlocks(initialLeftBlocks);
  }, [initialLeftBlocks]);

  // Get blocks on initial load
  useEffect(() => {
    setBlocksData();
  }, []);

  useEffect(() => {
    if (!planBlocksIsLoading && planBlocksIsLoaded && apiTriggerDebounce) {
      if (!planBlocksHasError) {
        // @ts-ignore
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        setBlocksData();
      } else {
        // @ts-ignore
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }
      setApiTrigger(false);
    }
  }, [apiTriggerDebounce, planBlocksIsLoading, planBlocksIsLoaded]);

  useEffect(() => {
    if (!deleteIsLoading && deleteIsLoaded && deleteApiTriggerDebounce) {
      if (!deleteHasError) {
        // @ts-ignore
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        setBlocksData();
      } else {
        // @ts-ignore
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }
      setDeleteApiTrigger(false);
    }
  }, [deleteApiTriggerDebounce, deleteIsLoading, deleteIsLoaded]);

  return (
    <Paper data-testid="plan-blocks-paper" sx={{ height: '100%', padding: 3 }}>
      <Stack>
        <Box>
          <Typography variant="body2">{t('Add Blocks to Plan')}</Typography>
        </Box>
        <Box>
          <PlanBlocksTransfer
            availableBlocks={newLeftBlocks}
            currentBlocks={initialRightBlocks}
            isLoading={availablePlanBlocksLoading}
            groups={allVineyards}
            width={500}
            onChange={handleOnChange}
          />
        </Box>
        <Box m={4}>
          <VineaButton
            color="success"
            onClick={handleOnSave}
            variant="contained"
            sx={{
              marginRight: theme.spacing(1),
              minWidth: 150,
            }}
            minWidth={100}
            disabled={!hasChanges}
          >
            {t('Save')}
          </VineaButton>
          <VineaButton
            variant="outlined"
            color="secondary"
            sx={{
              marginLeft: theme.spacing(1),
              minWidth: 150,
            }}
            onClick={handleOnCancel}
            disabled={!hasChanges}
          >
            {t('Discard')}
          </VineaButton>
        </Box>
      </Stack>
    </Paper>
  );
};

function pathNameAreEqual(prevProps, nextProps) {
  return isEqual(prevProps.pathParamId, nextProps.pathParamId);
}

const PlanBlocks = React.memo(PlanBlocksComponent, pathNameAreEqual);
export default withFeatureFlagHOC(featureFlags.PLANBLOCKS)(PlanBlocks);
