import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { ErrorBoundary } from 'react-error-boundary';

import { DiscardDialog } from '../../components/Dialog';
import { VineaButton } from '../../components/VineaButton';
import { VineaTextField } from '../../components/TextField';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

const StyledDrawerControlDiv = styled('div')(() => ({
  width: 350,
}));

export const SampleSizeDrawer = ({
  open,
  onClose,
  handleOnSave,
  handleOnChange,
  formdata,
}) => {
  const theme = useTheme();
  const [state, setState] = React.useState(open);
  const { t } = useTranslation();

  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(null);

  React.useEffect(() => {
    setState(open);
  }, [open]);

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      setState(prevState => !prevState);
      onClose();
    }
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnDiscard = () => {
    const { hasChanges } = formdata;
    if (hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    onClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  const list = () => (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleOnClickAway}>
      <StyledDrawerControlDiv>
        <Box
          flex={1}
          flexDirection="row"
          justifyContent="flex-end"
          display="flex"
        >
          <IconButton
            aria-label="toggle sidebar"
            onClick={handleOnDiscard}
            onMouseDown={() => {}}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <div
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Typography
            sx={{
              padding: theme.spacing(2),
            }}
            variant="subtitle2"
          >
            {t('Update Recommended Sample Size')}
          </Typography>

          <Divider />
        </div>
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <Box p={2} my={2}>
            <FormControl
              sx={{ width: '100%' }}
              error={formdata?.errors?.recommendedSampleSize}
            >
              <VineaTextField
                fullWidth
                autoComplete="off"
                label={t('Recommended Sample Size')}
                name="recommendedSampleSize"
                value={formdata?.recommendedSampleSize}
                onChange={handleOnChange}
              />
              {formdata?.errors?.recommendedSampleSize && (
                <FormHelperText id="component-error-text">
                  {formdata?.errors?.recommendedSampleSize}
                </FormHelperText>
              )}
            </FormControl>
          </Box>

          <StyledButtonControlDiv>
            <div>
              <VineaButton
                color="success"
                onClick={handleOnSave}
                variant="contained"
                minWidth={100}
              >
                {t('Save')}
              </VineaButton>
            </div>
          </StyledButtonControlDiv>
        </ErrorBoundary>
        <DiscardDialog
          open={discardDialogOpen}
          onClose={() => setDiscardDialogOpen(false)}
          handleSaveChanges={handleOnDiscardSaveChanges}
          handleDiscardChanges={handleOnDiscardConfirm}
        />
      </StyledDrawerControlDiv>
    </ClickAwayListener>
  );

  return (
    <Drawer anchor="right" open={state} onClose={toggleDrawer}>
      {list('right')}
    </Drawer>
  );
};

SampleSizeDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  // handleOnChange: PropTypes.func.isRequired,
  // samplingUsers: PropTypes.objectOf(
  //   PropTypes.shape({
  //     id: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  //     key: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  //     value: PropTypes.string,
  //   }),
  // ).isRequired,
  formdata: PropTypes.objectOf(
    PropTypes.shape({
      recommendedSampleSize: PropTypes.number,
    }),
  ).isRequired,
};
