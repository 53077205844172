/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import window from 'global';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { isEmpty, isNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTheme, styled } from '@mui/material/styles';
import splashImage from '../../assets/vineasplash.jpg';
import { getAuthenticationResult } from '../../redux/selectors/authenticationSelector';
import { getApiLoadingStatus } from '../../redux/selectors/userAccessSelector';
import { getUserPreferences } from '../../redux/selectors';
import logger from '../../utils/winstonLogger';
import { reducers, sagaActionTypes, landingPagesList } from '../../constants';
import { ThreeCogs } from '../../components/ThreeCogs';
import withAuthProvider from '../../hooks/AuthProvider';
import { LandingHeader } from '../../components/Header';

const StyledRootDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.primary.contrastText,
}));

const StyledFlexRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  '&+&': {
    marginTop: theme.spacing(2),
  },
  justifyContent: 'flex-end',

  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(1),
  },
  [theme.breakpoints.up('lg')]: {
    marginRight: theme.spacing(2),
  },
}));

const StyledFlexColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

const Landing = ({
  login,
  isAuthenticated,
  userAuthorization,
  authenticationResult,
  userSettings,
  urlReRoute,
  fetchAllIdentities,
}) => {
  const [isLoginTriggered, setLoginTriggered] = useState(false);
  const theme = useTheme();
  const { isLoading, isLoaded, isUserAllowed } = authenticationResult;
  const { basicSettings } = userSettings;
  const [spinnerActive, setSpinnerActive] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  logger.debug(location);
  // logger.debug('are transalations loaded');
  const goToVinea = ev => {
    ev.preventDefault();
    // eslint-disable-next-line no-undef
    window.location.href = 'https://www.vinea.co.nz/';
  };
  useEffect(() => {
    if (isAuthenticated && !isLoginTriggered) {
      userAuthorization();
      setLoginTriggered(true);
      setSpinnerActive(true);
    } else if (isLoginTriggered && !isLoading && isLoaded && isUserAllowed) {
      enqueueSnackbar('Login Success!', { variant: 'success' });
      fetchAllIdentities();
      setTimeout(() => {
        logger.debug('reroute url', urlReRoute);
        if (!isEmpty(basicSettings))
          if (urlReRoute === '/' || !urlReRoute) {
            if (
              !isNumber(basicSettings.landingPage) ||
              basicSettings.landingPage === null
            ) {
              navigate('/dashboard');
            } else {
              navigate(`/${landingPagesList[basicSettings.landingPage].path}`);
            }
          } else {
            navigate(urlReRoute);
          }
      }, 300);
    } else if (isLoginTriggered && !isLoading && isLoaded && !isUserAllowed) {
      setTimeout(() => {
        navigate(`/unauthorized`);
      }, 100);
    }
  }, [
    navigate,
    enqueueSnackbar,
    userAuthorization,
    isLoading,
    isLoaded,
    isUserAllowed,
    urlReRoute,
    isAuthenticated,
    setLoginTriggered,
    isLoginTriggered,
    login,
    fetchAllIdentities,
    basicSettings,
  ]);

  return (
    <Grid container data-tesid="landingpagegrid">
      <Grid item xs={12}>
        <StyledRootDiv data-tesid="landingpageappbar">
          <LandingHeader login={login} />
        </StyledRootDiv>
        <Paper
          sx={{
            backgroundImage: `url(${splashImage})`,
            /* Center and scale the image nicely */
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '88vh',
            flex: 1,
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={9} md={7}>
              <StyledFlexRow>
                <StyledFlexRow data-testid="row1">
                  <StyledFlexColumn data-testid="content">
                    <Box mt={5} mx={5} data-testid="title1">
                      <Typography
                        variant="h4"
                        noWrap
                        sx={{
                          color: theme.palette.white.main,
                        }}
                      >
                        {t('landingpage.title1')}
                      </Typography>
                    </Box>
                    <Box mt={1} mx={5} data-testid="subtitle1">
                      <Typography
                        variant="h2"
                        sx={{
                          color: theme.palette.white.main,
                        }}
                        gutterBottom
                      >
                        {t('landingpage.subtitle1')}
                      </Typography>
                    </Box>
                    <Box mt={1} mx={5}>
                      <Typography
                        variant="h5"
                        component="p"
                        sx={{
                          color: theme.palette.white.main,
                        }}
                      >
                        {t('landingpage.subtitle2')}
                      </Typography>
                    </Box>
                    <Box mt={1} mx={5}>
                      <Box mt={5}>
                        <Button
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={goToVinea}
                          data-testid="btn-learnmore"
                        >
                          {t('landingpage.learnmore')}
                        </Button>
                      </Box>
                    </Box>
                  </StyledFlexColumn>
                </StyledFlexRow>
              </StyledFlexRow>
            </Grid>
          </Grid>
          {spinnerActive && (
            <Box
              display="flex"
              flex="1"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <ThreeCogs />
              <Typography
                variant="body2"
                sx={{ flexGrow: 1, color: theme.palette.primary.contrastText }}
              >
                Logging into Vinea Nova ...
              </Typography>
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

Landing.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  urlReRoute: PropTypes.string,
  userAuthorization: PropTypes.func.isRequired,
  authenticationResult: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      isSale: PropTypes.string,
    }),
    isUserAllowed: PropTypes.bool,
  }).isRequired,
  fetchAllIdentities: PropTypes.func.isRequired,
};

Landing.defaultProps = {
  urlReRoute: null,
};

const mapStateToProps = state => ({
  authenticationResult: getAuthenticationResult(state),
  userAccessValues: getApiLoadingStatus(state),
  userSettings: getUserPreferences(state),
});

const mapDispatchToProps = dispatch => ({
  userAuthorization: () => dispatch({ type: sagaActionTypes.USER_LOGIN }),
  fetchAllLookups: () =>
    dispatch(VineaNovaActions.api.v1.fetchAllLookups.request()),
  fetchAllIdentities: () => {
    dispatch({
      type: sagaActionTypes.FETCH_ALL_IDENTITIES,
      name: reducers.repository,
      payload: { name: reducers.repository },
    });
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthProvider,
)(Landing);
