// @ts-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Stack,
  Typography,
  FormControl,
  Box,
  FormHelperText,
  Alert,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import isEmpty from 'lodash/isEmpty';
import { VineaNovaActions, VineaHooks } from 'vineanova-redux-artifacts';
import { AzureMapsProvider } from 'react-azure-maps';
import { wktToGeoJSON } from '@terraformer/wkt';
import { ErrorBoundary } from 'react-error-boundary';

import AzureMaps from '../../components/MapsComponent/ActivityProgressMapController';
import { VineaAutoComplete } from '../../components/ComboBox';
import { VineaButton } from '../../components/VineaButton';
import { IdentityTypeIds } from '../../constants';
import {
  getUserPreferences,
  getLookupVintage,
  getBusinessUnitDashboardEnabled,
  getGrowerSubRegionWithAll,
} from '../../redux/selectors';
import { ActivityProgressSearchSchema } from './validations';
import { syncValidator } from '../../utils/validator';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { filter, find, first, get, isNil, map, max, sortBy } from 'lodash';
import { useFetchSearchIdentity } from '../../hooks/useFetchSearchIdentity';
import { MultiSelectCheckbox } from '../../components/MultiSelectCheckbox';

interface SelectOption {
  id: number;
  key: number;
  value: string;
}

const ActivityProgressMap = () => {
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();

  const { basicSettings = {} } = useSelector(state =>
    getUserPreferences(state),
  );
  const lkpBusinessUnit = useSelector(state =>
    getBusinessUnitDashboardEnabled(state),
  );
  const { businessUnitID: defaultBusinessUnitID, searchFilterBusinessUnitID } =
    basicSettings;
  const lkpVintage = useSelector(getLookupVintage);
  const latestVintageID = max(map(lkpVintage, 'id'));
  const latestVintageFromDate = find(lkpVintage, {
    id: latestVintageID,
  })?.fromDate;
  const lkpSubRegion = useSelector(getGrowerSubRegionWithAll);
  const [subRegionOptions, setSubRegionOptions] = useState(lkpSubRegion);
  const { data: lkpActivityGroup } = VineaHooks.useFetchlookupActivityGroup({});
  const [activityGroupOptions, setActivityGroupOptions] =
    useState<SelectOption[]>();
  const [noResultsMessage, setNoResultsMessage] = useState<string | null>(null);

  const [searchParameters, setSearchParameters] = useState({
    businessUnitID: !isNil(searchFilterBusinessUnitID)
      ? searchFilterBusinessUnitID
      : defaultBusinessUnitID,
    growingSubRegionID: get(first(lkpSubRegion), 'id', 0),
    fromDate: latestVintageFromDate,
    activityGroupIDs: [],
    errors: {
      growingSubRegionID: null,
      activityGroupIDs: null,
      businessUnitID: null,
    },
  });

  const {
    data: regionActivityProgress,
    isLoading: activityProgressIsLoading,
    isLoaded: activityProgressIsLoaded,
  }: any = useSelector(
    (state: any) => state.entities.subRegionActivityProgress,
  );

  const {
    basicSettings: { defaultRegionID },
  }: { basicSettings: { defaultRegionID: number } } = useSelector(
    (state: any) => getUserPreferences(state),
  );

  const defaultSubRegionID = get(
    find(lkpSubRegion, {
      growingRegionID: defaultRegionID,
    }),
    0,
  );

  const { searchIdentity: searchVineyards } = useFetchSearchIdentity({
    identityTypeID: IdentityTypeIds.VINEYARD,
    findNonActive: true,
  });

  const vineyardsGeoJson = useMemo(
    () => ({
      type: 'FeatureCollection',
      features:
        regionActivityProgress?.vineyards
          ?.filter(({ vineyardGeometry }: any) => vineyardGeometry)
          ?.map(({ vineyardGeometry, vineyardID }: any) => ({
            type: 'Feature',
            properties: { id: vineyardID },
            geometry: wktToGeoJSON(vineyardGeometry),
          })) || [],
    }),
    [regionActivityProgress],
  );

  const allBlocksGeoJson = useMemo(
    () => ({
      type: 'FeatureCollection',
      features:
        regionActivityProgress?.vineyards?.flatMap(
          ({ blocks }: any) =>
            blocks
              ?.filter(({ blockGeometry }: any) => blockGeometry)
              ?.map(({ blockGeometry, percentComplete, ...rest }: any) => ({
                type: 'Feature',
                geometry: wktToGeoJSON(blockGeometry),
                properties: {
                  ...rest,
                  percentComplete,
                  labelText: `${Number(percentComplete)}%`,
                },
              })) || [],
        ) || [],
    }),
    [regionActivityProgress],
  );

  // We are calling a block "not started" if there has been no work done on it
  // We don't actually know if they ever intend to start work on it
  const notStartedBlocksGeoJson = useMemo(
    () => ({
      ...allBlocksGeoJson,
      features: allBlocksGeoJson.features.filter(
        ({ properties: { percentComplete } }: any) => percentComplete === null,
      ),
    }),
    [allBlocksGeoJson],
  );

  // A block is "in progress" if any work records have been created for it
  // This might result in 0% complete if these records were for hours, rather than vines
  const inProgressBlocksGeoJson = useMemo(
    () => ({
      ...allBlocksGeoJson,
      features: allBlocksGeoJson.features.filter(
        ({ properties: { percentComplete } }: any) =>
          percentComplete !== null &&
          Number(percentComplete) >= 0 &&
          Number(percentComplete) < 100,
      ),
    }),
    [allBlocksGeoJson],
  );

  const completedBlocksGeoJson = useMemo(
    () => ({
      ...allBlocksGeoJson,
      features: allBlocksGeoJson.features.filter(
        ({ properties: { percentComplete } }: any) =>
          Number(percentComplete) === 100,
      ),
    }),
    [allBlocksGeoJson],
  );

  const handleSearch = useCallback(() => {
    const validationErrors = syncValidator(ActivityProgressSearchSchema)(
      searchParameters,
    );

    setSearchParameters({
      ...searchParameters,
      errors: validationErrors,
    });

    if (isEmpty(validationErrors)) {
      dispatchAPI(
        VineaNovaActions.api.v1.subRegionActivityProgress.get.request({
          queryParams: {
            GrowingSubRegionID: searchParameters?.growingSubRegionID,
            FromDate: searchParameters?.fromDate,
            ActivityGroupIDs: searchParameters?.activityGroupIDs.join(','),
            BusinessUnitID: searchParameters?.businessUnitID,
          },
        }),
      );
    }
  }, [searchParameters, dispatchAPI]);

  const handleChange = (
    evt: React.ChangeEvent<{ name: string; value: any }>,
  ) => {
    const {
      target: { name, value },
    } = evt;

    setSearchParameters({
      ...searchParameters,
      [name]: value,
    });

    if (name === 'businessUnitID') {
      dispatchAPI({
        type: 'BASIC_SETTINGS_UPDATE',
        payload: {
          ...basicSettings,
          searchFilterBusinessUnitID: value,
        },
      });
    }
  };

  const handleActivityGroupChange = (activityGroupIDs: number[]) => {
    setSearchParameters({
      ...searchParameters,
      //@ts-ignore
      activityGroupIDs: activityGroupIDs,
    });
  };

  useEffect(() => {
    if (defaultSubRegionID) {
      setSearchParameters({
        ...searchParameters,
        growingSubRegionID: defaultSubRegionID,
      });
    }

    return () =>
      dispatchAPI(
        VineaNovaActions.api.v1.subRegionActivityProgress.get.cleardata(),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRegionID]);

  useEffect(() => {
    if (!activityProgressIsLoading && activityProgressIsLoaded) {
      if (isEmpty(regionActivityProgress?.vineyards)) {
        setNoResultsMessage(t('No results for the search parameters provided'));
      }

      const vineyardsWithNoGeometry = regionActivityProgress?.vineyards?.filter(
        ({ vineyardGeometry }: any) => !vineyardGeometry,
      );

      const blocksWithNoGeometry = regionActivityProgress?.vineyards?.flatMap(
        ({ blocks }: any) =>
          blocks?.filter(({ blockGeometry }: any) => !blockGeometry),
      );

      if (!isEmpty(vineyardsWithNoGeometry) || !isEmpty(blocksWithNoGeometry)) {
        let message = '';

        if (!isEmpty(vineyardsWithNoGeometry)) {
          const vineyardNamesString = vineyardsWithNoGeometry
            ?.map(({ vineyardName }: any) => vineyardName)
            ?.join(', ');
          message += `${t(
            'Vineyard geometry data is missing for',
          )} ${vineyardNamesString}. `;
        }

        if (!isEmpty(blocksWithNoGeometry)) {
          const blockNamesString = blocksWithNoGeometry
            ?.map(({ blockName }: any) => blockName)
            ?.join(', ');
          message += `${t(
            'Block geometry data is missing for',
          )} ${blockNamesString}. `;
        }

        setNoResultsMessage(message);
      }
    } else {
      setNoResultsMessage(null);
    }
  }, [
    regionActivityProgress,
    activityProgressIsLoaded,
    activityProgressIsLoading,
    t,
  ]);

  // Filter sub regions based on selected business unit
  useEffect(() => {
    const selectedBusinessUnit = get(
      find(lkpBusinessUnit, { id: searchParameters?.businessUnitID }),
      'value',
    );
    if (!isEmpty(searchVineyards) && !isNil(selectedBusinessUnit)) {
      const validSubRegionIDs = map(
        filter(searchVineyards, { businessUnit: selectedBusinessUnit }),
        'subRegionID',
      );
      const validSubRegions = sortBy(
        filter(
          lkpSubRegion,
          subRegion =>
            validSubRegionIDs.includes(subRegion.id) || subRegion.id === 0,
        ),
        'value',
      );
      setSubRegionOptions(validSubRegions);

      const defaultSubRegionID = get(first(validSubRegions), 'id', 0);
      setSearchParameters({
        ...searchParameters,
        growingSubRegionID: defaultSubRegionID,
      });
    }
  }, [searchVineyards, searchParameters?.businessUnitID]);

  // Set activity group options
  useEffect(() => {
    if (!isEmpty(lkpActivityGroup) && !isEmpty(lkpVintage)) {
      const latestVintage = find(lkpVintage, { id: latestVintageID }).fromDate;
      const activityGroupOptions = sortBy(
        map(
          filter(
            lkpActivityGroup,
            a => get(a, 'lastPieceworkDate', 0) >= latestVintage,
          ),
          act => {
            return {
              id: act.id,
              key: act.id,
              value: act.activityGroup,
            };
          },
        ),
        'value',
      );

      setActivityGroupOptions(activityGroupOptions);
    }
  }, [lkpActivityGroup, lkpVintage]);

  return (
    <Stack
      direction="row"
      sx={{ width: '100%', height: '100%' }}
      data-testid="vinea-block-maps"
    >
      <Paper sx={{ width: '350px', padding: 2 }} elevation={2}>
        {/* @ts-ignore */}
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <Typography variant="subtitle2">
            {t('Activity Progress (By Vine Only)')}
          </Typography>

          <Stack sx={{ rowGap: 3, marginTop: 3 }}>
            <FormControl sx={{ flex: 1, display: 'flex' }}>
              <VineaAutoComplete
                value={latestVintageID || 0}
                disabled={true}
                inlineLabel={false}
                options={lkpVintage}
                labelVariant="body1"
                label={t('Vintage')}
                name={t('Vintage')}
                id="vintageID"
                inputProps={{
                  name: 'planVintageID',
                }}
                fullWidth={false}
              />
            </FormControl>
            <FormControl
              sx={{
                minWidth: 250,
              }}
              error={!!searchParameters?.errors?.businessUnitID}
              margin="none"
            >
              <VineaAutoComplete
                value={searchParameters?.businessUnitID}
                onChange={handleChange}
                inlineLabel={false}
                options={lkpBusinessUnit || []}
                labelVariant="body1"
                label={t('Business Unit')}
                name={t('Business Unit')}
                id="businessUnitID"
                inputProps={{
                  name: 'businessUnitID',
                }}
                fullWidth={false}
              />
              {searchParameters?.errors?.businessUnitID && (
                <FormHelperText id="component-error-text">
                  {searchParameters?.errors?.businessUnitID}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={{ flex: 1, display: 'flex' }}
              error={!!searchParameters?.errors?.activityGroupIDs}
            >
              <VineaAutoComplete
                label={t('Sub-Region')}
                value={searchParameters?.growingSubRegionID}
                onChange={handleChange}
                options={subRegionOptions}
                labelVariant="body1"
                name="growingSubRegionID"
                inputProps={{ name: 'growingSubRegionID' }}
                fullWidth={false}
              />
              {searchParameters?.errors?.growingSubRegionID && (
                <FormHelperText>
                  {searchParameters?.errors?.growingSubRegionID}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl sx={{ flex: 1, display: 'flex' }}>
              <MultiSelectCheckbox
                label={t('Activity Group')}
                value={get(searchParameters, 'activityGroupIDs') || []}
                onChange={handleActivityGroupChange}
                options={activityGroupOptions}
                name="activityGroupIDs"
                inputProps={{ name: 'activityGroupIDs' }}
                inputRef={undefined}
              />
              {searchParameters?.errors?.activityGroupIDs && (
                <FormHelperText id="component-error-text">
                  {searchParameters?.errors?.activityGroupIDs}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>

          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
            <VineaButton
              variant="outlined"
              color="secondary"
              onClick={handleSearch}
            >
              {t('Search')}
            </VineaButton>
          </Box>
        </ErrorBoundary>
      </Paper>
      <Stack sx={{ width: '100%' }}>
        {/* @ts-ignore */}
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          {noResultsMessage && (
            <Alert
              variant="standard"
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setNoResultsMessage(null)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {noResultsMessage}
            </Alert>
          )}
          <AzureMapsProvider>
            <AzureMaps
              vineyardsGeoJson={vineyardsGeoJson}
              notStartedBlocksGeoJson={notStartedBlocksGeoJson}
              inProgressBlocksGeoJson={inProgressBlocksGeoJson}
              completedBlocksGeoJson={completedBlocksGeoJson}
            />
          </AzureMapsProvider>
        </ErrorBoundary>
      </Stack>
    </Stack>
  );
};

export { ActivityProgressMap };
