/* eslint-disable react/prop-types */
import React from 'react';
import { GridWithFilter } from '../../../components/Grid';
import { dataGridVintageColumn } from '../gridColumns';

export const SearchGridResults = ({
  handleOnRowClick,
  handleOnCellClick,
  isLoading = false,
  data = [],
  className = '',
  ...props
}) => {
  /* React.useEffect(() => {
    if (isEmpty(data)) setGridData([]);
    else setGridData(data);
  }, [data]); */

  const gridXData = {
    columns: dataGridVintageColumn,
    rows: data,
  };

  console.log('gridxdata', gridXData);
  console.log('gridxdata', props);

  return (
    <GridWithFilter
      data={gridXData}
      onRowClick={handleOnRowClick}
      onCellClick={handleOnCellClick}
      rowHeight={30}
      hideFooterSelectedRowCount
      autoPageSize
      loading={isLoading}
      className={className}
      getRowClassName={params => {
        return `vinea-xgrid--${params.getValue(params.id, 'isEditable')}`;
      }}
      // advancedSearch
      {...props}
    />
  );
};
