/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItemText, Checkbox, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { VineaTextField } from '../TextField';

export const MultiSelectCheckbox = ({
  value,
  onChange,
  label,
  options,
  inputRef,
  ...restProps
}) => {
  // Note: AutoComplete expects values as objects e.g. {id:0, key:0, value:'Name'}
  // API expects values as just an ID, so we need to map between the two
  const selectedValues = value.map(v => options.find(o => o.id === v));

  const handleChange = (event, val) => {
    onChange(val.map(v => v.id));
  };

  // const renderOption = val => {
  //   return (
  //     <>
  //       <Checkbox
  //         checked={selectedValues.some(other => val.id === other.id)}
  //         color="secondary"
  //       />
  //       <ListItemText primary={val.value} />
  //     </>
  //   );
  // };

  // const renderTags = selected => {
  //   console.log('renderTagsNote', selected);
  //   const nameList = selected.map(v => v.value);
  //   return (
  //     <Box ml={1} my={1}>
  //       {nameList.join(', ')}
  //     </Box>
  //   );
  // };

  return (
    <>
      <Autocomplete
        labelId="demo-simple-select-label"
        data-testid="multiselectcheckbox"
        value={selectedValues}
        multiline={false}
        onChange={handleChange}
        getOptionLabel={val => val?.value || ''}
        multiple
        renderInput={params => (
          <VineaTextField {...params} inputRef={inputRef} label={label} />
        )}
        // renderTags={renderTags(selectedValues)}
        disableCloseOnSelect
        options={options.filter(o => o.id !== 0)}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Checkbox
                checked={selectedValues.some(other => option.id === other.id)}
                color="secondary"
              />
              <ListItemText primary={option.value} />
            </li>
          );
        }}
        {...restProps}
      />
    </>
  );
};

MultiSelectCheckbox.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array]),
  className: PropTypes.string,
  inputRef: PropTypes.object,
};

MultiSelectCheckbox.defaultProps = {
  value: [],
  onChange: () => {},
  label: '',
  options: [],
  className: '',
  inputRef: undefined,
};
