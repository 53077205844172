import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { VineaButton } from '../VineaButton';

type VineaDialogProps = {
  open: boolean;
  dialogHeader: string;
  dialogContent?: string;
  onClose: any;
  rowId?: number;
  primaryAction: any;
  secondaryAction: any;
  primaryText?: string;
  secondaryText?: string;
  primaryColour?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'info'
    | 'error';
};

export function VineaDialog({
  open,
  dialogHeader,
  dialogContent,
  primaryAction,
  onClose,
  secondaryAction = onClose,
  primaryText,
  secondaryText,
  primaryColour,
  rowId,
}: VineaDialogProps) {
  const handleClick = () => {
    primaryAction(rowId);
    onClose();
  };
  return (
    <div>
      <Dialog
        data-testid="AlertDialog"
        open={open}
        onClose={onClose}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        color="primary"
      >
        <DialogTitle id="alert-dialog-title">{dialogHeader}</DialogTitle>
        <DialogContent style={{ minWidth: 350 }}>
          <DialogContentText id="alert-dialog-description">
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <VineaButton
            data-testid="BtnCancel"
            onClick={secondaryAction}
            color="secondary"
            variant="text"
            autoFocus
          >
            {secondaryText}
          </VineaButton>
          <VineaButton
            data-testid="BtnDelete"
            onClick={handleClick}
            color={primaryColour}
            variant="contained"
            autoFocus
          >
            {primaryText}
          </VineaButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

VineaDialog.defaultProps = {
  dialogContent: 'Are you sure you want to proceed?',
  primaryText: 'Continue',
  secondaryText: 'Cancel',
  primaryColour: 'secondary',
  rowId: 0,
};
