const initialState = {
  selectedLanguage: 'en',
};

function languageSelectorReducer(state = initialState, action) {
  switch (action.type) {
    case 'LANGUAGE_CHANGE':
      return {
        selectedLanguage: action.payload,
      };
    default:
      return state;
  }
}

export default languageSelectorReducer;
