import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { StyledListItem } from './StyledListItem';

interface IListItemProps {
  children: any;
  to: string;
  toolTip: string;
  primaryText: string;
}

const SideBarListItem: React.FC<IListItemProps> = ({
  children,
  to,
  toolTip,
  primaryText,
}) => {
  const { t } = useTranslation('translation');
  const { pathname } = useLocation();

  const isActive = React.useMemo(() => {
    return pathname.startsWith(to);
  }, [to, pathname]);

  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <StyledListItem
        // @ts-ignore
        isActive={isActive}
        //  className={getIconClassName('setting')}
      >
        <Tooltip disableInteractive title={t(toolTip).toString()}>
          <ListItemIcon>{children}</ListItemIcon>
        </Tooltip>

        <ListItemText primary={t(primaryText)} />
      </StyledListItem>
    </Link>
  );
};

export default SideBarListItem;
