import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { commonFieldSelectOption } from '../../constants';

export const orderedLookupList = data => {
  if (data && !isEmpty(data)) {
    const orderedData = orderBy(data, ['id'], ['desc']);
    const appendedList = commonFieldSelectOption.concat(orderedData);
    return appendedList;
  }
  return data;
};
