import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { VineaButton } from '../VineaButton';

type DiscardDialogProps = {
  open: boolean;
  onClose: any;
  handleSaveChanges: any;
  handleDiscardChanges: any;
};

export function DiscardDialog({
  open,
  onClose,
  handleSaveChanges,
  handleDiscardChanges,
}: DiscardDialogProps) {
  const { t } = useTranslation();

  const onSave = (e: React.MouseEvent) => {
    handleSaveChanges(e, 0);
  };

  return (
    <div>
      <Dialog
        data-testid="AlertDialog"
        open={open}
        onClose={onClose}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        color="primary"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Discard unsaved changes?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              'You have modified this work item. Please either discard or save the changes you have made',
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <VineaButton
            data-testid="BtnCancel"
            onClick={onClose}
            color="secondary"
            variant="text"
            autoFocus
          >
            {t('Cancel')}
          </VineaButton>
          <VineaButton
            data-testid="BtnDelete"
            onClick={handleDiscardChanges}
            color="error"
            variant="text"
            autoFocus
          >
            {t('Discard Changes')}
          </VineaButton>
          <VineaButton
            data-testid="BtnDelete"
            onClick={onSave}
            color="success"
            variant="contained"
            autoFocus
          >
            {t('Save Changes')}
          </VineaButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// DiscardDialog.propTypes = {
//   open: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
//   handleSaveChanges: PropTypes.func.isRequired,
//   handleDiscardChanges: PropTypes.func.isRequired,
// };
