/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { StyledListItemText } from './SampleHeaderDetailsExt1';

export default function SampleHeaderDetailsExt2({
  actualStartDate,
  plannedStartDate,
  lastSampleDate,
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <List
      sx={{
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        paddingTop: 0,
        paddingBottom: 0,
        '& .MuiListItem-root': {
          alignItems: 'flex-start',
        },
      }}
      dense
    >
      <>
        <ListItem>
          <StyledListItemText id="total-area">
            <Typography variant="body2" data-testid="planned-date">{`${t(
              'Planned Start',
            )}:`}</Typography>
          </StyledListItemText>
          <StyledListItemText>
            <Typography variant="body1" data-testid="plannedstartdate">
              {plannedStartDate}
            </Typography>
          </StyledListItemText>
        </ListItem>
        <ListItem>
          <StyledListItemText id="planted-area">
            <Typography variant="body2" data-testid="actual-date">{`${t(
              'Actual Start',
            )}:`}</Typography>
          </StyledListItemText>
          <StyledListItemText>
            <Typography variant="body1" data-testid="actualstartdate">
              {actualStartDate}
            </Typography>
          </StyledListItemText>
        </ListItem>
        <ListItem>
          <StyledListItemText id="ethnicity">
            <Typography variant="body2">
              {`${t('Last Sample Completed')}:`}
            </Typography>
          </StyledListItemText>
          <StyledListItemText>
            <Typography variant="body1" data-testid="lastsampledate">
              {lastSampleDate}
            </Typography>
          </StyledListItemText>
        </ListItem>
      </>
    </List>
  );
}
