export const lgDataGridHeaderColumns = (
  RenderHeader,
  RenderCellTrellisStatus,
  RenderCellWithVintageStatus,
  RenderEditButton,
  handleOnChangeCommon,
  handleOnEditClick,
) => [
  {
    field: 'id',
    headerName: 'Select',
    // flex: 1,
    width: 115,
    hideable: false,
    renderCell: RenderEditButton,
    onEditClick: handleOnEditClick,
  },
  {
    field: 'vintageYear',
    headerName: 'Vintage Year',
    flex: 1,
    hideable: false,
    renderHeader: RenderHeader,
  },
  {
    field: 'vintageStatus',
    headerName: 'Status',
    flex: 0.75,
    hideable: false,
    onChange: handleOnChangeCommon,
    renderCell: RenderCellWithVintageStatus,
  },
  {
    field: 'trellisStatus',
    headerName: 'Trellis',
    flex: 1,
    hideable: false,
    onChange: handleOnChangeCommon,
    renderCell: RenderCellTrellisStatus,
  },
  {
    field: 'targetYield',
    headerName: 'Target Yield T/Ha',
    flex: 0.75,
    hideable: false,
    renderHeader: RenderHeader,
    type: 'number',
  },
  {
    field: 'actualYield',
    headerName: 'Actual Yield T/Ha',
    flex: 1,
    hideable: false,
    renderHeader: RenderHeader,
    type: 'number',
  },
  {
    field: 'harvestDate',
    headerName: 'Harvest Date',
    flex: 1,
    height: 50,
    hideable: false,
    renderHeader: RenderHeader,
  },
];
