import { filter, isNil, map, sortBy, uniqBy } from 'lodash';
import { useCallback } from 'react';
import { VineaHooks } from 'vineanova-redux-artifacts';
import { IdentityRoleTypes, IdentityTypeIds } from '../../constants';

export const useSupplyContractsHook = () => {
  const { data: growersAndWineCompanies } = VineaHooks.useFetchIdentitiesByRole(
    {
      queryParams: {
        RoleTypeIDs: `${IdentityRoleTypes.GROWER},${IdentityRoleTypes.WINE_COMPANY}`,
      },
    },
  );
  const { data: identities } = VineaHooks.useFetchIdentity({});

  const getSpecificIdentitiesByRole = useCallback(
    (roleType: number, identityTypes: any) => {
      const specificIdentities = map(
        filter(growersAndWineCompanies, g => {
          return (
            g.roleTypeID === roleType &&
            identityTypes.includes(g.identityTypeID)
          );
        }),
        g => {
          return { id: g.id, key: g.id, value: g.displayName };
        },
      );
      specificIdentities.unshift({ id: 0, key: 0, value: 'Select' });
      return specificIdentities;
    },
    [growersAndWineCompanies],
  );

  const purchasingParties = getSpecificIdentitiesByRole(
    IdentityRoleTypes.WINE_COMPANY,
    [
      IdentityTypeIds.ORGANISATION,
      IdentityTypeIds.SERVICE_PROVIDER,
      IdentityTypeIds.VINEYARD,
    ],
  );
  const supplyingParties = getSpecificIdentitiesByRole(
    IdentityRoleTypes.GROWER,
    [
      IdentityTypeIds.ORGANISATION,
      IdentityTypeIds.SERVICE_PROVIDER,
      IdentityTypeIds.VINEYARD,
    ],
  );

  const primaryContacts = sortBy(
    uniqBy(
      filter(
        map(identities, i => {
          if (
            isNil(i?.inactiveDate) &&
            i?.identityTypeID === IdentityTypeIds.PERSON
          ) {
            return {
              id: i.id,
              key: i.id,
              value: i.displayName,
            };
          }
        }),
        i => !isNil(i),
      ),
      'key',
    ),
    'value',
  );
  primaryContacts.unshift({ id: 0, key: 0, value: 'Select' });

  return {
    purchasingParties,
    supplyingParties,
    primaryContacts,
  };
};
