/* eslint-disable no-unused-vars  */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import { ErrorBoundary } from 'react-error-boundary';

import { FieldNameSelect } from '../../components/Select';
import { VineaButton } from '../../components/VineaButton';
import { DiscardDialog } from '../../components/Dialog';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

const StyledDrawerControlDiv = styled('div')(() => ({
  width: 350,
}));

export const StatusDrawer = ({
  open,
  onClose,
  handleOnStatus,
  statusId,
  statusOptions = [],
  handleOnSave,
  hasChanges,
}) => {
  const theme = useTheme();
  const [state, setState] = React.useState(open);
  const { t } = useTranslation();

  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(null);
  const [initialStatusId] = React.useState(statusId);

  React.useEffect(() => {
    setState(open);
  }, [open]);

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const handleOnClickAway = event => {
    // TODO: ClickAwayListener is broken in Mui currently
    // handleOnDiscard();
  };

  const handleOnDiscard = () => {
    if (statusId !== initialStatusId) {
      setDiscardDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    onClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  const list = () => (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleOnClickAway}>
      <StyledDrawerControlDiv>
        <Box
          flex={1}
          flexDirection="row"
          justifyContent="flex-end"
          display="flex"
        >
          <IconButton
            aria-label="toggle sidebar"
            onClick={handleOnDiscard}
            onMouseDown={() => {}}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <div
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Typography
            sx={{
              padding: theme.spacing(2),
            }}
            variant="subtitle2"
          >
            {t('Update Status for Selected Blocks')}
          </Typography>
          <Divider />

          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />
            <FormControl
              sx={{
                padding: theme.spacing(2),
                flex: 1,
                display: 'flex',
              }}
            >
              <FieldNameSelect
                data-testid="dropdown"
                name="Update Status"
                label={t('Status')}
                value={statusId}
                onChange={handleOnStatus}
                displayEmpty
                options={statusOptions}
                inputProps={{
                  id: 'status-id',
                }}
              />
            </FormControl>
            <StyledButtonControlDiv>
              <div>
                <VineaButton
                  color="success"
                  onClick={handleOnSave}
                  variant="contained"
                  minWidth={100}
                  disabled={statusId === 0}
                >
                  {t('Save')}
                </VineaButton>
              </div>
            </StyledButtonControlDiv>
          </ErrorBoundary>
        </div>
        <DiscardDialog
          open={discardDialogOpen}
          onClose={() => setDiscardDialogOpen(false)}
          handleSaveChanges={handleOnDiscardSaveChanges}
          handleDiscardChanges={handleOnDiscardConfirm}
        />
      </StyledDrawerControlDiv>
    </ClickAwayListener>
  );

  return (
    <Drawer anchor="right" open={state} onClose={toggleDrawer}>
      {list('right')}
    </Drawer>
  );
};

StatusDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleOnStatus: PropTypes.func.isRequired,
  statusId: PropTypes.oneOfType(PropTypes.string, PropTypes.number).isRequired,
  handleOnSave: PropTypes.func.isRequired,
  statusOptions: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  hasChanges: PropTypes.bool.isRequired,
};
