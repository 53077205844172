import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

const getAllPlansData = state => state.searchPlans;

export const getAllPlans = createSelector(
  [getAllPlansData],
  planSearchData => {
    const { data = [] } = planSearchData;
    return {
      ...planSearchData,
      data: isEmpty(data) ? [] : data,
    };
  },
);
