import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { VineaCommonGrid } from '../../components/Grid';
import { ManageJobActivityDrawer } from './ManageJobActivityDrawer';
import { commonGridHeaderNames } from '../../constants';
import { useCreateJobHook } from './hooks/useCreateJobActivityHook';

const CreateJobActivities = (identityID: any) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    columnHeaders,
    jobActivityRatesLoading,
    jobActivityRatesData,
    jobActivityDrawerOpen,
    isAddDrawer,
    jobActivityRateObject,
    activityOptions,
    validationErrors,
    rowSpecialAttributeOptions,
    handleEditJobActivityRate,
    handleAddJobActivityRate,
    handleOnCloseDrawer,
    handleOnSave,
    handleOnChange,
    handleOnChangeCheckbox,
    handleOnDelete,
  } = useCreateJobHook(identityID);

  return (
    <Paper
      elevation={0}
      data-testid="new-job-paper"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid spacing={2} data-testid="parent-grid-createjob">
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <VineaCommonGrid
            heading={t(commonGridHeaderNames.JOB_ACTIVITIES)}
            colHeaders={columnHeaders}
            buttonText={t('Add Activity')}
            isLoading={!!jobActivityRatesLoading}
            tableData={jobActivityRatesData}
            //@ts-ignore
            onRowClick={handleEditJobActivityRate}
            manage={handleAddJobActivityRate}
            hasActiveToggle={false}
          />
        </ErrorBoundary>
      </Grid>
      <ManageJobActivityDrawer
        isOpen={jobActivityDrawerOpen}
        isNew={isAddDrawer}
        handleOnClose={handleOnCloseDrawer}
        handleOnSave={handleOnSave}
        handleOnChange={handleOnChange}
        handleOnChangeCheckbox={handleOnChangeCheckbox}
        handleOnDelete={handleOnDelete}
        jobActivityData={jobActivityRateObject}
        identityActivityOptions={activityOptions}
        rowSpecialAttributeOptions={rowSpecialAttributeOptions}
        validationErrors={validationErrors}
      />
    </Paper>
  );
};

export default CreateJobActivities;
