import React, { useState } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import get from 'lodash/get';
import '@cyntler/react-doc-viewer/dist/index.css';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import useDebounce from '../../../hooks/useDebounce';

// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

// add type definition
export type DocumentViewerProps = {
  open: boolean;
  handleClose: any;
  documentId: number;
  isNewNote: boolean;
  documentObject: {
    isLoading: boolean;
    isLoaded: boolean;
    hasError: boolean;
    data: {
      documentID: number;
      documentTypeID: number;
      documentDate: string;
      sourceFile: string;
      documentSubject: string;
      documentStatusID: number;
      documentObject: string;
    };
  };
};

export const DocumentViewer = ({
  open,
  handleClose,
  documentObject,
  documentId,
  isNewNote,
}: DocumentViewerProps) => {
  const documentRef = React.useRef<any>(null);
  const [blobUrl, setBlobUrl] = useState<string>('');
  const [docs, setDocs] = useState<any>([]);

  const documentRawBlob = get(documentObject, 'data.documentObject', '');
  const documentName = get(documentObject, 'data.sourceFile', '');
  const isLoadedDocument = get(documentObject, 'isLoaded', false);
  const isLoadingDocument = get(documentObject, 'isLoading', false);
  const hasError = get(documentObject, 'hasError', false);

  const getMimeType = (fileName: string) => {
    const extension = fileName.split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'doc':
        return 'application/msword';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'ppt':
        return 'application/vnd.ms-powerpoint';
      case 'pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'txt':
        return 'text/plain';
      case 'csv':
        return 'text/csv';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      // Add more cases as needed
      default:
        return 'application/octet-stream';
    }
  };

  React.useEffect(() => {
    if (open && documentRawBlob && isLoadedDocument) {
      const mimeType = getMimeType(documentName);
      // Convert base64 string to Uint8Array if needed
      if (isNewNote) {
        setBlobUrl(documentRawBlob);
      } else {
        let byteArray;
        if (typeof documentRawBlob === 'string') {
          byteArray = Uint8Array.from(atob(documentRawBlob), c =>
            c.charCodeAt(0),
          );
        } else {
          byteArray = new Uint8Array(documentRawBlob);
        }

        const newBlob = new Blob([byteArray], { type: mimeType });
        const newBlobUrl = URL.createObjectURL(newBlob);
        setBlobUrl(newBlobUrl);
      }
    }
  }, [
    isNewNote,
    documentRawBlob,
    open,
    documentName,
    isLoadedDocument,
    documentId,
  ]);

  React.useEffect(() => {
    if (blobUrl) {
      setDocs([
        {
          // @ts-ignore
          uri: blobUrl,
          fileName: documentName,
        },
      ]);
    }
    // Cleanup function to revoke object URL when component unmounts
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [blobUrl, documentRawBlob, documentName]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl" // Set maxWidth to 'xl'
        fullWidth // Make the dialog full width
      >
        {/* <DialogTitle id="alert-dialog-title">Document</DialogTitle> */}
        {isLoadingDocument && (
          <DialogContent>
            <CircularProgress color="inherit" size={20} />
          </DialogContent>
        )}
        {!isLoadingDocument && !hasError && (
          <DialogContent>
            {docs.length > 0 && (
              <DocViewer
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                ref={documentRef}
              />
            )}
          </DialogContent>
        )}

        {hasError && (
          <DialogContent>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Document has error.
            </Typography>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
