import React from 'react';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import DeleteIcon from '@mui/icons-material/Delete';
import { VineaButton } from '../../components/VineaButton';
import FrappeGanttSample from './FrappeGanttSample';

import { Checkboxes } from './Checkboxes';
import { Radios } from './Radios';
import { TextFields } from './TextFields';
import { SelectFields } from './SelectFields';
import { Spinners } from './Spinners';

const StyledToolbar = styled(Toolbar)(() => ({
  root: { paddingRight: 24 },
}));

const StyledGridItem = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(12),
  marginLeft: theme.spacing(2),
}));

const StyledGridItems = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
}));

const StyledGridSpacer = styled(Grid)(({ theme }) => ({
  '& > button': {
    margin: theme.spacing(1),
  },
}));

export const FormSamples = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Grid
      container
      spacing={0}
      sx={{
        margin: 0,
        width: '99%',
      }}
    >
      <AppBar
        position="fixed"
        data-testid="appbar"
        sx={{
          backgroundColor: theme.palette.white.main,
        }}
      >
        <StyledToolbar disableGutters>
          <IconButton
            color="primary"
            aria-label="Open drawer"
            sx={{
              marginLeft: 12,
              marginRight: 18,
            }}
            data-testid="icon-button"
            onClick={() => navigate('/')}
            size="large"
          >
            <ion-icon name="logo-react" size="large" />
          </IconButton>
          <Typography variant="h5" noWrap data-testid="header-text">
            Vinea Nova
          </Typography>
        </StyledToolbar>
      </AppBar>
      <StyledGridItem item xs={12} sm={12}>
        <Typography component="p" variant="h5">
          Contained Buttons
        </Typography>
        <Grid container spacing={2}>
          <StyledGridSpacer item xs={12} sm={8}>
            <VineaButton variant="contained" color="primary">
              Primary
            </VineaButton>
            <VineaButton variant="contained" color="secondary">
              secondary
            </VineaButton>
            <VineaButton variant="contained" color="success">
              Success
            </VineaButton>
            <VineaButton variant="contained" color="error">
              Error
            </VineaButton>
            <VineaButton variant="contained" color="warning">
              warning
            </VineaButton>
            <VineaButton variant="contained" color="info">
              info
            </VineaButton>
          </StyledGridSpacer>

          <StyledGridSpacer item xs={12} sm={8}>
            <Typography component="p" variant="h5">
              Outlined Buttons
            </Typography>
            <VineaButton variant="outlined" color="primary">
              Primary
            </VineaButton>
            <VineaButton variant="outlined" color="secondary">
              secondary
            </VineaButton>
            <VineaButton variant="outlined" color="success">
              Success
            </VineaButton>
            <VineaButton variant="outlined" color="error">
              Error
            </VineaButton>
            <VineaButton variant="outlined" color="warning">
              warning
            </VineaButton>
            <VineaButton variant="outlined" color="info">
              info
            </VineaButton>
          </StyledGridSpacer>

          <StyledGridSpacer item xs={12} sm={8}>
            <Typography component="p" variant="h5">
              Text Buttons
            </Typography>
            <VineaButton variant="text" color="primary">
              Primary
            </VineaButton>
            <VineaButton variant="text" color="secondary">
              secondary
            </VineaButton>
            <VineaButton variant="text" color="success">
              Success
            </VineaButton>
            <VineaButton variant="text" color="error">
              Error
            </VineaButton>
            <VineaButton variant="text" color="warning">
              warning
            </VineaButton>
            <VineaButton variant="text" color="info">
              info
            </VineaButton>
          </StyledGridSpacer>
        </Grid>
      </StyledGridItem>
      <StyledGridItems item xs={12} sm={8}>
        <Typography component="p" variant="h5">
          Buttons Sizes
        </Typography>
        <Grid container spacing={2}>
          <StyledGridSpacer item xs={12} sm={8}>
            <VineaButton variant="contained" size="large">
              Large
            </VineaButton>
            <VineaButton variant="contained" size="medium">
              Medium
            </VineaButton>
            <VineaButton variant="contained" size="small">
              small
            </VineaButton>
          </StyledGridSpacer>
        </Grid>
      </StyledGridItems>

      <StyledGridItems item xs={12} sm={8}>
        <Typography component="p" variant="h5">
          Buttons Icons
        </Typography>
        <Grid container spacing={2}>
          <StyledGridSpacer item xs={12} sm={8}>
            <VineaButton
              variant="outlined"
              size="large"
              startIcon={<CloudUploadIcon />}
            >
              Large
            </VineaButton>
            <VineaButton
              variant="outlined"
              size="large"
              endIcon={<KeyboardVoiceIcon />}
            >
              Medium
            </VineaButton>
          </StyledGridSpacer>
        </Grid>
      </StyledGridItems>
      <StyledGridItems item xs={12} sm={8}>
        <Typography component="p" variant="h5">
          IconsButtons
        </Typography>
        <Grid container spacing={2}>
          <StyledGridSpacer item xs={12} sm={8}>
            <IconButton aria-label="delete" size="small" color="primary">
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <IconButton aria-label="delete" color="secondary" size="large">
              <DeleteIcon fontSize="small" />
            </IconButton>
            <IconButton aria-label="delete" size="large">
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="delete" size="large">
              <DeleteIcon fontSize="large" />
            </IconButton>
          </StyledGridSpacer>
        </Grid>
      </StyledGridItems>
      <StyledGridItems item xs={12} sm={8}>
        <Typography component="p" variant="h5">
          Full Width Buttons
        </Typography>
        <Grid container spacing={2}>
          <StyledGridSpacer item xs={12} sm={8}>
            <VineaButton variant="contained" size="large" fullWidth>
              Full Width
            </VineaButton>
          </StyledGridSpacer>
        </Grid>
      </StyledGridItems>
      <Checkboxes />
      <Radios />
      <TextFields />
      <SelectFields />
      <Spinners />
      <FrappeGanttSample />
    </Grid>
  );
};

FormSamples.propTypes = {
  // history: historyPropTypes.isRequired,
};

const mapStateToProps = state => ({
  authUser: state.authentication,
  language: state.language.selectedLanguage,
});
const mapDispatchToProps = dispatch => ({
  languageSelect: langValue =>
    dispatch({ type: 'LANGUAGE_CHANGE', payload: langValue }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FormSamples,
);
