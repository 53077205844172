/* eslint-disable react/no-children-prop */
import React, {
  useEffect,
  useReducer,
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback,
} from 'react';
import { get, isEmpty } from 'lodash';
import { format } from 'date-fns';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/lab/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { FieldLabelInput } from '../../components/TextField';
import { VineaAlert } from '../../components/VineaAlert';
import { VineaAutoComplete } from '../../components/ComboBox';
import {
  sagaActionTypes,
  vineaDetails,
  apiTypes,
  IdentityTypeIds,
  JobStatusIDs,
  dateFormat,
  commonGridHeaderNames,
  commonGridColumns,
  LicenseeTypeIDs,
  SiteTypeIDs,
} from '../../constants';
import { getUserPreferences, getLkpJobType } from '../../redux/selectors';
import {
  addGenericReducer,
  types,
  jobState,
} from '../VineyardIdentity/stateReducers';
import { JobIdentitySchema } from './validations';
import { syncValidator } from '../../utils/validator';
import { useJobHook } from './hooks/useJobsHook';
import { VineaCommonGrid } from '../../components/Grid';

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

interface PropsType {
  onSaveStepData: (data: SaveStepDataType, step: any) => void;
  step: string;
  stepData: any;
  calculateNameToCheck: any;
  matchingIdentities: [];
  setIsFormSaving: (x: any) => {};
}

interface SaveStepDataType {
  data: any;
  isSaving: boolean;
  isComplete: boolean;
  hasError: boolean;
}

const CreateJobIdentity = forwardRef((props: PropsType, ref: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [formdata, dispatch] = useReducer(addGenericReducer, jobState);
  const {
    onSaveStepData,
    setIsFormSaving,
    step,
    stepData,
    calculateNameToCheck,
    matchingIdentities,
  } = props;
  const [stepperDataSaved, setStepperDataSaved] = useState(false);
  const formWrite = useSelector((state: any) => state.formWrite);
  const [alertType, setAlertType] = useState(false);
  const [inSavingMode, setSaveMode] = useState(false);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = useState(false);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [isIdentityTrigger, setIdentityTrigger] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const today = format(new Date(), dateFormat);

  // use job hook for formdata
  const {
    formData: updatedFormData,
    quantityTypeOptions,
    jobInvoiceMethodOptions,
    workRecordLevelOptions,
    workRecordUnitTypeOptions,
    workRecordLevelDisabled,
    unitTypeDisabled,
    quantityTypeDisabled,
    jobSearchFilterData,
    licenseeTypeID,
    siteTypeOptions,
    jobTypeOptions,
    setSaveHasBeenClicked,
    setUpdatedFormData,
  } = useJobHook(formdata);

  const { basicSettings = {} } = useSelector(state =>
    getUserPreferences(state),
  );

  // Use business unit chosen in job search filter, if this is null use user's default business unit
  const { businessUnitID: userSettingsBusinessUnitID } = basicSettings;
  const defaultBusinessUnitID = get(
    jobSearchFilterData,
    'businessUnitID',
    userSettingsBusinessUnitID,
  );

  const hasSaved = useMemo(() => {
    if (
      (get(updatedFormData, 'identityTypeID', 0) === 0 || hasChanges) &&
      !stepperDataSaved
    )
      return false;
    else return true;
  }, [updatedFormData, stepperDataSaved]);

  useImperativeHandle(
    ref,
    () => ({
      saveForm() {
        //@ts-ignore
        const validationErrors =
          syncValidator(JobIdentitySchema)(updatedFormData);
        if (isEmpty(validationErrors)) {
          const {
            jobName,
            jobTypeID,
            jobDescription,
            invoiceMethodID,
            workRecordLevelID,
            quantityTypeID,
            workRecordUnitTypeID,
          } = updatedFormData;

          const data = {
            jobName,
            identityTypeID: IdentityTypeIds.JOB,
            jobTypeID,
            jobStatusID: JobStatusIDs.UNSCHEDULED,
            jobDescription,
            invoiceMethodID,
            workRecordLevelID,
            quantityTypeID,
            workRecordUnitTypeID,
            businessUnitID: defaultBusinessUnitID,
            createdDate: today,
            siteTypeID: get(
              updatedFormData,
              'siteTypeID',
              SiteTypeIDs.VINEYARD,
            ),
          };
          dispatchAPI({
            type: sagaActionTypes.FORM_SUBMIT,
            payload: {
              data,
              name: vineaDetails.job,
              methodType: apiTypes.POST,
            },
          });
          setSaveMode(true);
          setIsFormSaving(true);
          setActionTriggered(true);
          setIdentityTrigger(true);
        }
        // @ts-ignore
        dispatch({ type: types.ERROR, payload: validationErrors });
        setSaveHasBeenClicked(true);
      },
    }),
    [defaultBusinessUnitID, dispatchAPI, updatedFormData, today],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    // @ts-ignore
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
    // @ts-ignore
    setUpdatedFormData({ ...updatedFormData, [name]: value });
    setHasChanges(true);
  };

  const clearJobData = useCallback(() => {
    dispatchAPI(VineaNovaActions.api.v1.identityJob.get.cleardata());
    dispatchAPI(VineaNovaActions.api.v1.blocksForJob.get.cleardata());
  }, []);

  useEffect(() => {
    calculateNameToCheck(formdata);
  }, [formdata.jobName]);

  useEffect(() => {
    const { data: formWriteData, hasError, isLoading } = formWrite;
    if (inSavingMode && !isLoading) {
      if (!hasError) {
        setSaveMode(false);
        onSaveStepData(
          {
            data: formWriteData,
            isSaving: false,
            isComplete: true,
            hasError: false,
          },
          step,
        );
      } else {
        setSaveMode(false);
        onSaveStepData(
          {
            data: { identityId: null },
            isSaving: false,
            isComplete: false,
            hasError: true,
          },
          step,
        );
      }
    }
  }, [inSavingMode, formWrite, onSaveStepData, step]);

  useEffect(() => {
    const { hasError, data, isLoaded } = formWrite;
    if (isLoaded && actionTriggered) {
      if (!hasError && !isEmpty(data) && isIdentityTrigger) {
        setIdentityTrigger(false);
        setStepperDataSaved(true);
        setIsFormSaving(false);
        enqueueSnackbar(t('Success'), { variant: 'success' });
        setAlertType(true);
        clearJobData();
        const {
          data: { id, identityTypeID },
        } = formWrite;
        dispatchAPI({
          type: sagaActionTypes.FETCH_IDENTITIES,
          payload: {
            id,
            identityTypeId: identityTypeID,
          },
        });
      }
    }
  }, [
    formWrite,
    actionTriggered,
    isIdentityTrigger,
    enqueueSnackbar,
    t,
    dispatchAPI,
  ]);

  // Load api data initially
  useEffect(() => {
    // @ts-ignore
    dispatch({
      type: types.UPDATE,
      payload: {
        ...stepData,
        jobName: stepData.jobName,
        jobTypeID: stepData.jobTypeID,
        jobDescription: stepData.jobDescription,
        workRecordLevelID: stepData.workRecordLevelID,
        quantityTypeID: stepData.quantityTypeID,
        workRecordUnitTypeID: stepData.workRecordUnitTypeID,
        invoiceMethodID: stepData.invoiceMethodID,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      elevation={0}
      data-testid="new-job-paper"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid spacing={2} data-testid="parent-grid-createjob">
        <Grid item xs={12} data-testid="grid-vineaalert-createjob">
          <VineaAlert
            isOpen={!!false}
            onExit={() => setErrorInSubmit(false)}
            children={undefined}
            alertType="error"
            message=""
          />
        </Grid>
        <Grid item xs={12} md={12} lg={8} data-testid="grid-warning-createjob">
          {warningInSubmit && (
            <Collapse in={warningInSubmit}>
              <Alert
                variant="standard"
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setWarningInSubmit(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Warning: Choose at least one block to create a New Plan
              </Alert>
            </Collapse>
          )}

          <Stack direction="row" width="100%">
            <Stack
              direction="column"
              sx={{
                marginTop: theme.spacing(5),
              }}
            >
              <StyledBox>
                <FormControl
                  sx={{
                    minWidth: 250,
                  }}
                >
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="jobName"
                    name="jobName"
                    label={t('Job Name')}
                    size="small"
                    inlineLabel
                    value={updatedFormData.jobName}
                    onChange={handleChange}
                    error={formdata.errors.jobName}
                    disabled={hasSaved}
                    onBlur={undefined}
                    classes={undefined}
                    rows={undefined}
                    sx={undefined}
                  />
                </FormControl>
              </StyledBox>
              <StyledBox>
                <FormControl
                  sx={{
                    minWidth: 250,
                  }}
                >
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="jobDescription"
                    name="jobDescription"
                    label={t('Job Description')}
                    size="small"
                    inlineLabel
                    value={updatedFormData.jobDescription}
                    onChange={handleChange}
                    error={formdata.errors.jobDescription}
                    rows={8}
                    disabled={hasSaved}
                    multiline
                    onBlur={undefined}
                    classes={undefined}
                    sx={undefined}
                  />
                </FormControl>
              </StyledBox>
              <StyledBox>
                <FormControl
                  sx={{
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.invoiceMethodID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={updatedFormData.invoiceMethodID}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={jobInvoiceMethodOptions || []}
                    labelVariant="body1"
                    label={t('Invoice Method')}
                    name={t('Invoice Method')}
                    id="invoiceMethodID"
                    inputProps={{
                      name: 'invoiceMethodID',
                    }}
                    fullWidth={false}
                    disabled={hasSaved}
                  />
                  {formdata.errors.invoiceMethodID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.invoiceMethodID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
            </Stack>
            <Stack
              direction="column"
              sx={{
                marginTop: theme.spacing(5),
              }}
            >
              <StyledBox>
                <FormControl
                  sx={{
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.siteTypeID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={updatedFormData.siteTypeID}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={siteTypeOptions || []}
                    labelVariant="body1"
                    label={t('Site Type')}
                    name={t('Site Type')}
                    id="siteTypeID"
                    inputProps={{
                      name: 'siteTypeID',
                    }}
                    fullWidth={false}
                    disabled={
                      hasSaved ||
                      licenseeTypeID === LicenseeTypeIDs.WINE_GROWER ||
                      licenseeTypeID ===
                        LicenseeTypeIDs.CONTRACTOR_VINEYARDS_ONLY
                    }
                  />
                  {formdata.errors.siteTypeID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.siteTypeID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledBox>
                <FormControl
                  sx={{
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.jobTypeID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={updatedFormData.jobTypeID}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={jobTypeOptions || []}
                    labelVariant="body1"
                    label={t('Job Type')}
                    name={t('Job Type')}
                    id="jobTypeID"
                    inputProps={{
                      name: 'jobTypeID',
                    }}
                    fullWidth={false}
                    disabled={hasSaved}
                  />
                  {formdata.errors.jobTypeID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.jobTypeID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledBox>
                <FormControl
                  sx={{
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.workRecordLevelID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={updatedFormData.workRecordLevelID}
                    disabled={workRecordLevelDisabled || hasSaved}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={workRecordLevelOptions || []}
                    labelVariant="body1"
                    label={t('Work Record Level')}
                    name={t('Work Record Level')}
                    id="workRecordLevelID"
                    inputProps={{
                      name: 'workRecordLevelID',
                    }}
                    fullWidth={false}
                  />
                  {formdata.errors.workRecordLevelID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.workRecordLevelID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledBox>
                <FormControl
                  sx={{
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.quantityTypeID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={updatedFormData.quantityTypeID}
                    disabled={quantityTypeDisabled || hasSaved}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={quantityTypeOptions || []}
                    labelVariant="body1"
                    label={t('Quantity Type')}
                    name={t('Quantity Type')}
                    id="quantityTypeID"
                    inputProps={{
                      name: 'quantityTypeID',
                    }}
                    fullWidth={false}
                  />
                  {formdata.errors.quantityTypeID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.quantityTypeID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledBox>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.workRecordUnitTypeID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={updatedFormData.workRecordUnitTypeID}
                    disabled={unitTypeDisabled || hasSaved}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={workRecordUnitTypeOptions || []}
                    labelVariant="body1"
                    label={t('Unit Type')}
                    name={t('Unit Type')}
                    id="workRecordUnitTypeID"
                    inputProps={{
                      name: 'workRecordUnitTypeID',
                    }}
                    fullWidth={false}
                  />
                  {formdata.errors.workRecordUnitTypeID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.workRecordUnitTypeID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
            </Stack>
            <Box
              sx={{
                marginLeft: theme.spacing(4),
                marginBottom: theme.spacing(4),
              }}
            >
              <VineaCommonGrid
                heading={t(commonGridHeaderNames.SIMILAR_IDENTITIES)}
                colHeaders={commonGridColumns.SIMILAR_IDENTITIES}
                tableData={matchingIdentities}
                hasManageOption={false}
                hasActiveToggle={false}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
});

export default CreateJobIdentity;
