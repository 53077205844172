import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme, styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import Alert from '@mui/lab/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { VineaButton } from '../../../components/VineaButton';
import { VineaAlert } from '../../../components/VineaAlert';
import { FieldLabelInput } from '../../../components/TextField';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { SamplesBlockTransferList } from '../../../components/SamplingComponents';
import { useSamplesHook } from '../hooks/useSamplesHook';
import { Datepicker } from '../../../components/Datepicker';
import { viewDateFormat } from '../../../constants';

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const StyledBox1 = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 420,
  '& .MuiFormControl-root': {
    minWidth: 420,
  },
}));

const StyledBox2 = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  root: {
    marginTop: theme.spacing(1),
    minWidth: 250,
  },
}));

export const NewSampleSet = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    latestVintage,
    lkpGrowerRegion,
    lkpMeasureInstance,
    lkpMeasureGroup,
    handleOnChange,
    sampleSetData,
    sampleBlockVintages,
    blockVintagesLoading,
    blockVintagesGroups,
    handleOnSave,
    handleOnBlur,
    warningInSubmit,
    setWarningInSubmit,
  } = useSamplesHook();
  const formdata = { errors: {}, ...sampleSetData };
  const handleOnCancel = () => {
    navigate(-1);
  };

  console.log('new sampleheader', sampleBlockVintages);

  const setErrorInSubmit = () => {};
  return (
    <Paper
      elevation={0}
      data-testid="detail-newsampleset"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12} data-testid="vineaalert">
          <VineaAlert isOpen={!!false} onExit={() => setErrorInSubmit(false)} />
        </Grid>
        <Grid item xs={12} md={12} lg={8} data-testid="grid-top-wrapper">
          <Grid container spacing={2} data-testid="grid-field">
            <Grid item xs={12} data-testid="grid-inner-fields1">
              <StyledBox mt={1}>
                <Typography
                  variant="subtitle1"
                  noWrap
                  data-testid="breadcrumbtext"
                >
                  {`${t('Create new sample set for current vintage')} ${
                    latestVintage.value
                  }`}
                </Typography>
              </StyledBox>
              <Box px={2}>
                {warningInSubmit && (
                  <Collapse in={warningInSubmit}>
                    <Alert
                      variant="standard"
                      severity="warning"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setWarningInSubmit(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      Warning: Choose at least one block to create a Sample Set
                    </Alert>
                  </Collapse>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} data-testid="grid-inner-fields">
              <StyledBox mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.regionId}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.regionId}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    inlineLabel={false}
                    options={lkpGrowerRegion}
                    labelVariant="body1"
                    label={t('Region')}
                    name={t('Region')}
                    displayLabel
                    inputProps={{
                      name: 'regionId',
                    }}
                  />
                  {formdata.errors.regionId && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.regionId)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
            </Grid>
            <Grid item xs={12} sm={6} md={4} data-testid="grid-inner-fields">
              <StyledBox mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.measureGroupId}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.measureGroupId}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    inlineLabel={false}
                    options={lkpMeasureGroup}
                    labelVariant="body1"
                    name={t('Measure Group')}
                    label={t('Measure Group')}
                    displayLabel
                    inputProps={{
                      name: 'measureGroupId',
                    }}
                  />
                  {formdata.errors.measureGroupId && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.measureGroupId)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
            </Grid>
            <Grid item xs={12} sm={6} md={4} data-testid="grid-inner-fields">
              <StyledBox1 mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 420,
                  }}
                  error={!!formdata.errors.measureId}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.measureInstanceId}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    inlineLabel={false}
                    options={lkpMeasureInstance}
                    labelVariant="body1"
                    name={t('Measure Instance')}
                    label={t('Measure Instance')}
                    displayLabel
                    inputProps={{
                      name: 'measureId',
                    }}
                  />
                  {formdata.errors.measureId && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.measureId)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox1>
            </Grid>
            <Grid item xs={12} sm={6} md={4} data-testid="grid-inner-fields">
              <StyledBox2 mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.defaultSampleSize}
                  margin="none"
                >
                  <FieldLabelInput
                    autoComplete="off"
                    id="defaultSampleSize"
                    name="defaultSampleSize"
                    label={t('Recommended Sample Size')}
                    inlineLabel
                    minWidth="250px"
                    disabled={
                      formdata.defaultSampleSizeFromMeasureInstance === 1
                    }
                    value={formdata.defaultSampleSize}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                  />
                  {formdata.errors.defaultSampleSize && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.defaultSampleSize}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox2>
            </Grid>
            <Grid item xs={12} sm={6} md={4} data-testid="grid-inner-fields">
              <StyledBox mt={2}>
                <StyledFormControl
                  error={!!formdata.errors.plannedStartDate}
                  data-testid="datePlanted-formcontrol"
                >
                  <Datepicker
                    disablePast={false}
                    id="plannedStartDate"
                    name="plannedStartDate"
                    onChange={handleOnChange}
                    inputFormat={viewDateFormat}
                    label={t('Planned Start')}
                    value={formdata.plannedStartDate}
                    inputProps={{
                      'data-testid': 'plannedStartDate',
                      'data-name': 'plannedStartDate',
                    }}
                  />
                  {formdata.errors.plannedStartDate && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.plannedStartDate}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </StyledBox>
            </Grid>
            <Grid item xs={8}>
              <StyledBox2 mt={1}>
                <FieldLabelInput
                  id="sampleSetNote"
                  name="sampleSetNote"
                  label={t('Comments')}
                  inlineLabel
                  minWidth="250px"
                  value={formdata.sampleSetNote}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.sampleSetNote}
                  multiline
                  rowsMax={4}
                  rows={3}
                />
              </StyledBox2>
            </Grid>
            <Grid item xs={12}>
              <StyledBox mt={1}>
                <InputLabel
                  variant="body2"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: 500,
                  }}
                >
                  {t('Select vineyards/blocks for the sample set')}
                </InputLabel>
                <Box ml={-1} data-testid="simple-transfer-list">
                  <SamplesBlockTransferList
                    sampleBlockVintages={sortBy(sampleBlockVintages, [
                      'vineyardName',
                      'blockName',
                    ])}
                    isLoading={blockVintagesLoading}
                    groups={blockVintagesGroups}
                    onChange={handleOnChange}
                  />
                </Box>
              </StyledBox>
            </Grid>

            <Grid item xs={12}>
              <Box mx={2} mt={1}>
                <VineaButton
                  color="success"
                  onClick={handleOnSave}
                  variant="contained"
                  sx={{
                    marginRight: theme.spacing(1),
                    minWidth: 150,
                  }}
                  minWidth={100}
                  data-testid="btnsave"
                  // disabled={!!formWriteLoading}
                >
                  {t('Save')}
                </VineaButton>
                <VineaButton
                  variant="outlined"
                  color="secondary"
                  onClick={handleOnCancel}
                  data-testid="btncancel"
                  // disabled={!!formWriteLoading}
                >
                  {t('Cancel')}
                </VineaButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
