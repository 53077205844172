import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { GridWithFilter } from '../../../components/Grid';
import { sagaActionTypes as Types, reducers } from '../../../constants';
import { VineaButton } from '../../../components/VineaButton';
import { useVineyardBlockRowsHook } from '../Hooks/useVineyardBlockRowsHook';
import { VineaAlert } from '../../../components/VineaAlert';
import { DeleteDialog } from '../../../components/Dialog';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';
import { ManageBlockRowDrawer } from './ManageBlockRowDrawer';

export const ManageBlockRowDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { blockId } = useParams();
  const { state: { blockFullName } = {} } = location;
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    blockRowDetails,
    errorInSubmit,
    blockRowColumns,
    dataUpdated,
    isLoading,
    blockRowToEdit,
    editBlockRowDrawerOpen,
    lkpRowSpecialAttribute,
    validationErrors,
    blockRowHasChanges,
    siteTypeID,
    handleOnAddRow,
    handleOnSaveBlockRow,
    handleOnCloseBlockRowDrawer,
    handleEditJobBlockRow,
    handleChangeBlockRow,
    handleOnUpdateBlockRows,
    setErrorInSubmit,
    handleOnDeleteRow,
  } = useVineyardBlockRowsHook();

  const gridDataTable = {
    columns: blockRowColumns,
    rows: blockRowDetails,
  };

  const handleOnDeleteConfirm = () => {
    const filteredRows = blockRowDetails.filter(
      row => row.id !== blockRowToEdit.id,
    );
    handleOnUpdateBlockRows(filteredRows);
    handleOnDeleteRow();
    setDialogOpen(false);
  };

  const handleOnDeleteReject = () => {
    setDialogOpen(false);
  };

  const handleOnCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatchAPI({
      type: Types.FETCH_VINEYARD_BLOCK_ROWS,
      name: reducers.vineyardBlockRows,
      payload: {
        id: blockId,
        name: reducers.vineyardBlockRows,
      },
    });
  }, [blockId, dispatchAPI, dataUpdated]);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      data-testid="manage-vintage-box-details"
      height="100%"
      p={2}
    >
      <Box
        display="flex"
        flex={1 / 12}
        flexDirection="column"
        data-testid="header-info=griditem-01"
      >
        <Paper elevation={0} data-testid="detail-info">
          <Box
            display="flex"
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            p={2}
          >
            <Box justifyContent="flex-start">
              <Typography variant="h5">
                {`Rows for block  ${blockFullName}`}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <VineaButton
                variant="contained"
                color="success"
                onClick={handleOnAddRow}
                data-testid="btn-add"
                minWidth={65}
                sx={{
                  marginRight: 2,
                }}
              >
                {t('Add')}
              </VineaButton>
              <VineaButton
                onClick={handleOnCancel}
                variant="outlined"
                color="secondary"
                minWidth={100}
              >
                {t('Close')}
              </VineaButton>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box
        display="flex"
        flex={!!errorInSubmit ? 1 / 10 : 0}
        flexDirection="column"
        data-testid="vineaalert"
      >
        <VineaAlert
          isOpen={!!errorInSubmit}
          onExit={() => setErrorInSubmit(false)}
        >
          {Object.keys(errorInSubmit).map(key => {
            return <p>{`${key}: ${errorInSubmit[key]}`}</p>;
          })}
        </VineaAlert>
      </Box>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        data-testid="griditem-03"
      >
        <Paper
          elevation={0}
          data-testid="detail-info"
          sx={{
            height: '100%',
          }}
        >
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <GridWithFilter
              data={gridDataTable}
              rowHeight={40}
              autoPageSize
              rowsPerPageOptions={[50]}
              onRowClick={handleEditJobBlockRow}
              loading={isLoading}
              advancedSearch={false}
              experimentalFeatures={{ newEditingApi: true }}
            />
          </ErrorBoundary>
        </Paper>
      </Box>
      <DeleteDialog
        open={dialogOpen}
        dialogHeader="Delete a row"
        onClose={handleOnDeleteReject}
        dialogActionTrigger={handleOnDeleteConfirm}
      />
      <ManageBlockRowDrawer
        isOpen={editBlockRowDrawerOpen}
        handleOnClose={handleOnCloseBlockRowDrawer}
        handleOnSave={handleOnSaveBlockRow}
        handleOnDelete={() => setDialogOpen(true)}
        handleOnChange={handleChangeBlockRow}
        blockRowHasChanges={blockRowHasChanges}
        blockRowData={blockRowToEdit}
        validationErrors={validationErrors}
        lkpRowSpecialAttribute={lkpRowSpecialAttribute}
        siteTypeID={siteTypeID}
      />
    </Box>
  );
};
