import React from 'react';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material';

export default function PopperComponent({
  popperOpen,
  current,
  handleListKeyDown,
  options,
  handleClose,
  disableClickAway,
}) {
  const theme = useTheme();
  return (
    <Popper
      open={popperOpen}
      anchorEl={current}
      role={undefined}
      placement="right-start"
      transition
      disablePortal
      sx={{
        zIndex: 1101,
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener
              onClickAway={() => {
                if (!disableClickAway) {
                  handleClose();
                }
              }}
            >
              <MenuList
                autoFocusItem={popperOpen}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                {options.map(option => (
                  <MenuItem
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                    onClick={() => handleClose(option.route)}
                    key={option.value}
                    data-testid={option.value}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

PopperComponent.propTypes = {
  popperOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleListKeyDown: PropTypes.func.isRequired,
  current: PropTypes.element,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  disableClickAway: PropTypes.bool,
};

PopperComponent.defaultProps = {
  disableClickAway: false,
  current: null,
};
