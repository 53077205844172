import * as Yup from 'yup';
import { isValid } from 'date-fns';

export const BlockSchema = Yup.object().shape({
  blockRef: Yup.string().nullable().required('Required'),
  blockVintageStatusID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
  rowSpacing: Yup.number()
    .typeError('Must only be numbers or decimals')
    .nullable(),
  plantSpacing: Yup.number()
    .typeError('Must only be numbers or decimals')
    .nullable(),
  plantedArea: Yup.number()
    .typeError('Must only be numbers or decimals')
    .nullable(),
  plantedVines: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'Must be numbers'),
  activeFromDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
});

export const AdhocBlockSchema = Yup.object().shape({
  blockRef: Yup.string().nullable().required('Required'),
  blockVintageStatusID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
  activeFromDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
});
