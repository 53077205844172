import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import { isValid, format } from 'date-fns';
import { useTrackingHook } from '../Hooks/useTrackingHook';
import { dataGridVintageColumn } from '../gridColumns';
import { VintageMeasureDrawer } from '../Drawers';
import { dateFormat } from '../../../constants';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialog-paper': {
    maxWidth: '100%',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiBackdrop-root': {
    background: 'transparent',
  },
}));

const StyledDialogTitle = props => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const VintageMeasurePopup = props => {
  const { blockInfo, open, close, vineyardID } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const {
    cropBlockVintageEntityData,
    handleOnUpdateVintageData,
    vintageBlockSelected = {},
    searchFilterData,
    lkpMeasures,
    lkpMeasureGroup,
    lkpMeasureInstance,
    handleOnDeleteCropBlockVintage,
    isLoadingData,
    vintageNoteImage,
    setVintageNoteImage,
  } = useTrackingHook();

  const { vineyard: vineyardSelected, block: blockSelected } =
    vintageBlockSelected;

  const handleOnDrawerOpen = () => {
    close();
    setDrawerOpen(true);
    setVintageNoteImage(null);
  };

  const handleOnDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleOnSaveVintageMeasure = data => {
    handleOnUpdateVintageData(data);
    setDrawerOpen(false);
  };

  const handleOnDeleteRow = () => {
    handleOnDeleteCropBlockVintage(selectedRow);
    setDrawerOpen(false);
  };

  const handleOnImageUpload = data => setVintageNoteImage(data);

  const handleOnDeleteNoteImage = imageDeleteId => {
    setSelectedRow({
      ...selectedRow,
      noteImage: null,
      imageDeleteId,
      hasChanges: true,
    });
  };

  const handleOnRowClick = cellProps => {
    const { row } = cellProps;

    const measureDate = isValid(new Date(row.measureDate))
      ? format(new Date(row.measureDate), dateFormat)
      : row.measureDate;
    setSelectedRow({ ...row, isEdit: true, measureDate });
    handleOnDrawerOpen();
  };

  const handleOnAddRow = () => {
    const { vintageID } = searchFilterData;
    const blockMeasure = {
      blockVintageId: 0,
      isAdd: true,
      isEditable: true,
      isPrivate: false,
      isValuePair: false,
      measure: null,
      measureDate: format(new Date(), dateFormat),
      measureInstanceId: null,
      measureSort: null,
      measureSource: null,
      measureType: null,
      measureValue: null,
      measureValue1: 0,
      measureValue2: null,
      modifiedBy: null,
      note: '',
      noteImage: null,
      vineyardBlockId: blockInfo.id,
      vintageId: vintageID,
      vineyardID,
    };
    setSelectedRow(blockMeasure);
    handleOnDrawerOpen();
  };

  return (
    <div data-testid="maps-tracking">
      <Box>
        <StyledDialog open={open} data-testid="vintage-measures-popup">
          <StyledDialogTitle
            data-testid="vintage-popup-dialog-title"
            onClose={close}
          >
            {blockInfo.name} - Crop Measure Instances
          </StyledDialogTitle>
          <DialogContent>
            <Grid
              sx={{
                height: 400,
                width: 780,
                '& .MuiDataGrid-cell': {
                  '&:focus': {
                    outline: 'none !important',
                  },
                },
                '& .MuiDataGrid-columnHeader': {
                  '&:focus': {
                    outline: 'none !important',
                  },
                },
              }}
            >
              <DataGridPro
                rows={cropBlockVintageEntityData || []}
                columns={dataGridVintageColumn}
                pageSize={5}
                rowsPerPageOptions={[5]}
                rowHeight={30}
                onRowClick={handleOnRowClick}
                isLoading={isLoadingData}
                disableColumnMenu
                disableSelectionOnClick
                disableColumnSelector
              />
            </Grid>
          </DialogContent>
          <DialogActions sx={{ pr: '16px !important' }}>
            <Button variant="contained" onClick={handleOnAddRow}>
              Add Measure
            </Button>
          </DialogActions>
        </StyledDialog>
      </Box>
      <VintageMeasureDrawer
        open={drawerOpen}
        onClose={handleOnDrawerClose}
        data={selectedRow}
        handleOnSave={handleOnSaveVintageMeasure}
        vineyardSelected={vineyardSelected}
        blockSelected={blockSelected}
        lkpMeasures={lkpMeasures}
        lkpMeasureGroup={lkpMeasureGroup}
        lkpMeasureInstance={lkpMeasureInstance}
        onDelete={handleOnDeleteRow}
        handleOnImageUpload={handleOnImageUpload}
        editedNoteImage={vintageNoteImage}
        onDeleteNoteImage={handleOnDeleteNoteImage}
      />
    </div>
  );
};
