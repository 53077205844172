import { isEmpty, isNull } from 'lodash';
import { createSelector } from 'reselect';

const getAuthenticationUser = state => state.authentication.user;
const getAuthenticationError = state => state.authentication.error;
const getAuthenticationData = state => state.userAccess;

export const getPreferredLanguage = createSelector(
  [getAuthenticationUser],
  user => {
    if (!isEmpty(user)) {
      const { language } = user;
      return language;
    }
    return 'en';
  },
);

export const getUserDisplayName = createSelector(
  [getAuthenticationUser],
  user => {
    if (!isEmpty(user)) {
      const { displayName } = user;
      return displayName;
    }
    return '';
  },
);

export const getUserEmail = createSelector([getAuthenticationUser], user => {
  if (!isEmpty(user)) {
    const { email } = user;
    return email;
  }
  return '';
});

export const getOrganisationStatus = createSelector(
  [getAuthenticationData],
  authentication => {
    if (!isEmpty(authentication)) {
      const { data = {} } = authentication;
      const { isSaleComplete, isProvisioned, isLicenseActive, tenantID } = data;
      const isActive = isSaleComplete && isProvisioned && isLicenseActive;

      return { isActive, tenantID };
    }
  },
);

export const getOrgDefaultBusinessUnit = createSelector(
  [getAuthenticationData],
  authentication => {
    if (!isEmpty(authentication)) {
      const { data = {} } = authentication;
      const { defaultBusinessUnitID } = data;

      return defaultBusinessUnitID;
    }
  },
);

export const getAuthenticationResult = createSelector(
  [getAuthenticationData],
  authentication => {
    const { isLoading, isLoaded, data = {} } = authentication;

    if (isLoading) {
      return {
        isLoading,
        isLoaded,
        data,
        isUserAllowed: false,
      };
    }

    if (isEmpty(data)) {
      return {
        isLoading,
        isLoaded,
        data: null,
        isUserAllowed: false,
      };
    }

    const {
      isSaleComplete,
      isProvisioned,
      isLicenseActive,
      email,
      firstName,
      lastName,
      isUserActive,
      isVineaAdmin,
    } = data;

    const isUserAllowed =
      isSaleComplete &&
      isProvisioned &&
      isLicenseActive &&
      email != null &&
      firstName != null &&
      lastName != null &&
      isUserActive;

    return {
      isLoading,
      isLoaded,
      data,
      isUserAllowed,
      isVineaAdmin,
    };
  },
);

export const getUnauthorizedStatus = createSelector(
  [getAuthenticationResult],
  res => {
    const { isLoading, data } = res;
    if (isLoading) return false;
    if (!data) return false;
    const { isUserAllowed } = res;
    return isUserAllowed;
  },
);

export const getAuthenticationText = createSelector(
  [
    getAuthenticationData,
    getUserDisplayName,
    getAuthenticationError,
    getUserEmail,
  ],
  (details, displayName, authError, userEmail) => {
    if (authError && authError.message) {
      return {
        line1: authError.message,
        line2: '',
      };
    }

    if (!details) return null;
    const { data } = details;
    const {
      isProvisioned,
      isSaleComplete,
      email,
      isLicenseActive,
      organisation,
      adminName,
      adminEmail,
      isUserActive,
    } = data;

    if (isEmpty(data)) {
      return {
        line1: 'Unauthorised',
        line2: 'Click Sign Up to find out more about Vinea Nova!',
        line3: '',
      };
    }
    if (!isSaleComplete) {
      return {
        line1: `Hello ${organisation}`,
        line2:
          'Your request to access Vinea has been received. One of our team will be in touch soon to assist you in completing the sign up process, meanwhile if you have any questions please contact us at signup@myvinea.net.',
        line3: 'Have a great day!',
      };
    }
    if (!isProvisioned) {
      return {
        line1: `Hello ${organisation}`,
        line2:
          'We are still in the process of provisioning your Vinea database. One of our team will be in touch as soon as everything is ready for you. Meanwhile if you have any questions please contact us at help@myvinea.net.',
        line3: 'Have a great day!',
      };
    }
    if (!isLicenseActive) {
      return {
        line1: `Hello ${organisation}`,
        line2:
          'We are sorry that your access to Vinea has been interrupted. Please contact help@myvinea.net so that we can resolve this problem for you.',
        line3: 'Have a great day!',
      };
    }

    if (isNull(email) || !isUserActive) {
      return {
        line1: `Hello ${displayName}`,
        line2: `As this is the first time you have logged in to ${organisation}'s  Vinea instance, your administrator needs to authorise your account. Please contact your Vinea Nova administrator ${adminName} at ${adminEmail}.`,
        line3: 'Have a great day!',
      };
    }
    return {
      line1: 'Unauthorised',
      line2: 'Click Sign Up to find out more about Vinea Nova!',
      line3: '',
    };
  },
);
