import React from 'react';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
// loading component for suspense fallback
const Loader = () => (
  <Container
    sx={{
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <CircularProgress size="100px" color="success" />
  </Container>
);

export default Loader;
