/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { GreenSwitch } from '../Switch';
import { VineaTextField } from '.';

export const VineaTextFieldWithFooterSwitch = ({
  id,
  label,
  size,
  placeholder,
  variant,
  inlineLabel,
  multiline,
  rowsMax,
  rows,
  onSwitchChange,
  checked,
  disabled,
  inputProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      {inlineLabel && label && <InputLabel htmlFor={label}>{label}</InputLabel>}
      <VineaTextField
        id={id}
        label={inlineLabel ? '' : label}
        placeholder={placeholder}
        variant={variant}
        size={size}
        multiline={multiline}
        rowsMax={rowsMax}
        rows={rows}
        disabled={disabled}
        inputProps={inputProps}
        {...props}
      />
      {!disabled && (
        <FormControlLabel
          control={
            <GreenSwitch
              checked={checked}
              onChange={evt => {
                if (!inputProps.readOnly) onSwitchChange(evt);
              }}
              name="activeOnly"
            />
          }
          label={
            <Typography variant="caption">{t('Internal use only')}</Typography>
          }
        />
      )}
    </>
  );
};

VineaTextFieldWithFooterSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  inlineLabel: PropTypes.bool,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  onSwitchChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  inputProps: PropTypes.shape({
    readOnly: PropTypes.bool,
  }),
};

VineaTextFieldWithFooterSwitch.defaultProps = {
  size: 'small',
  variant: 'outlined',
  placeholder: '',
  inlineLabel: false,
  multiline: false,
  disabled: false,
  rowsMax: 0,
  rows: 1,
  label: '',
  checked: false,
  inputProps: {},
};
