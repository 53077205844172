/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const AuthContext = React.createContext([{}, () => {}]);

const AuthContextProvider = ({ children }) => {
  const [AuthContextData, setAuthContextData] = useState({});
  return (
    <AuthContext.Provider value={[AuthContextData, setAuthContextData]}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { AuthContext, AuthContextProvider };
