/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import { formatTestId } from '../../utils/dataTestId';

export function KPITile({ options, series = {}, title = '' }) {
  const theme = useTheme();
  const { kpis = [] } = series;

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.white.main,
        height: '100%',
      }}
      name={title}
      data-testid={formatTestId(`kpitile${title}box`)}
    >
      <Box
        sx={{ ...options.title.style }}
        data-testid={formatTestId(`kpitile${title}`)}
      >
        {title}
      </Box>
      <Grid
        container
        columns={1}
        direction="column"
        wrap="wrap"
        sx={{
          height: '100%',
        }}
        name={`${title}grid`}
        data-testid={formatTestId(`kpitilegrid${title}`)}
      >
        {kpis.map(({ value = '', description = '' }) => (
          <Grid
            key={value}
            item
            sx={{
              flexBasis: 'unset',
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
            }}
            name={`${title}griditem`}
            data-testid={formatTestId(`kpitilegriditem${title}`)}
          >
            <Box
              sx={{ ...options.value.style }}
              name={`${title}kpitilegriditemboxvalue`}
              data-testid={formatTestId(`kpitilegriditemboxvalue${title}`)}
            >
              {value}
            </Box>
            <Box
              sx={{ ...options.description.style }}
              name={`${title}kpitilegriditemdescvalue`}
              data-testid={formatTestId(`kpitilegriditemdescvalue${title}`)}
            >
              {description}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

KPITile.propTypes = {
  options: PropTypes.shape({
    title: PropTypes.shape({
      style: PropTypes.object, // a JSS object
    }),
    subtitle: PropTypes.shape({
      style: PropTypes.object, // a JSS object
    }),
    value: PropTypes.shape({
      style: PropTypes.object, // a JSS object
    }),
    description: PropTypes.shape({
      style: PropTypes.object, // a JSS object
    }),
  }).isRequired,
  series: PropTypes.shape({
    kpis: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }).isRequired,
  title: PropTypes.string.isRequired,
};
