import { RenderDataGridHeader } from '../../../components/Grid';

export const dataGridHeaderColumns = [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    hideable: false,
    type: 'string',
    // flex: 1,
    width: 75,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'sampleSet',
    headerName: 'Sample Set',
    flex: 1.5,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'sampleSetNote',
    headerName: 'Sample Set Note',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'region',
    headerName: 'Region',
    flex: 0.6,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'starting',
    headerName: 'Starting',
    flex: 0.6,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    flex: 0.8,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'samples',
    headerName: 'Samples',
    flex: 0.6,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'unassigned',
    headerName: 'Unassigned',
    flex: 0.68,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'active',
    headerName: 'Active',
    flex: 0.5,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'complete',
    headerName: 'Complete',
    flex: 0.6,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'closed',
    headerName: 'Closed',
    flex: 0.5,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
];

export const filterDataGridColumns = [
  {
    field: 'blockFullName',
    headerName: 'Block',
    flex: 0.7,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'plantedArea',
    headerName: 'Area',
    flex: 0.3,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'plantedVines',
    headerName: 'Vines',
    flex: 0.3,
    renderHeader: RenderDataGridHeader,
  },
];
