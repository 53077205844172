import { zipObject } from 'lodash';
import * as Yup from 'yup';

// We want to make sure all fields are required when generating a report
// We only know which fields exist at runtime, so we have to generate the Yup schema on the fly
export function generateReportRequestSchema(filterParameterNames) {
  return Yup.object().shape({
    outputFormat: Yup.string()
      .nullable()
      .notOneOf(['Select', 0, '0'], 'Required')
      .required('Required'),
    ...zipObject(
      filterParameterNames,
      filterParameterNames.map(() =>
        Yup.string().nullable().required('Required'),
      ),
    ),
  });
}
