/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';
import SampleHeaderDetailsExt1 from './SampleHeaderDetailsExt1';
import SampleHeaderDetailsExt2 from './SampleHeaderDetailsExt2';

const StyledRoot = styled('div')(() => ({
  display: 'flex',
}));

export const IdentitySamplingHeader = ({
  displayName,
  isLoading = false,
  sampleInfo = {},
}) => {
  const theme = useTheme();
  const {
    region,
    createdBy,
    sampleSet,
    actualStartDate,
    lastSampleDate,
    performance,
    plannedStartDate,
  } = sampleInfo;

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isLoading && (
            <Box width="100%" display="inline-flex" px={1}>
              <Skeleton variant="rectangular" width={210} height={100} />

              <Box width="100%" px={1}>
                <Skeleton variant="text" width="100%" height={20} />
                <Skeleton variant="text" width="100%" height={20} />
                <Skeleton variant="text" width="100%" height={20} />
                <Skeleton variant="text" width="100%" height={20} />
              </Box>
            </Box>
          )}
          {!isLoading && (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    padding: theme.spacing(1),
                    display: 'flex',
                    flexGrow: 1,
                  }}
                >
                  <Grid container spacing={2} data-testid="identity-details-1">
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        data-testid="identity-details-1"
                      >
                        <Grid item xs={6}>
                          <SampleHeaderDetailsExt1
                            displayName={displayName}
                            sampleSet={sampleSet}
                            region={region}
                            createdBy={createdBy}
                            performance={performance}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SampleHeaderDetailsExt2
                            displayName={displayName}
                            actualStartDate={actualStartDate}
                            lastSampleDate={lastSampleDate}
                            plannedStartDate={plannedStartDate}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </StyledRoot>
  );
};
