import { all, fork } from 'redux-saga/effects';
// eslint-disable
import { VineaNovaRootSagas } from 'vineanova-redux-artifacts';
import identityFeatures from './identityFields';
import loginUser from './loginUserAccessSaga';
import addNewIdentity from './AddNewIdentities';
import updateIdentities from './updateIdentities';
import refreshPageSagas from './refreshPageSaga';
import userPreferences from './userPreferencesSaga';
import fileDownloadRootSagas from './FileDownload.saga';
import fetchLookupSaga from './fetchLookupsSaga';
/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(loginUser),
    fork(identityFeatures),
    fork(addNewIdentity),
    fork(updateIdentities),
    fork(refreshPageSagas),
    fork(VineaNovaRootSagas),
    fork(userPreferences),
    fork(fileDownloadRootSagas),
    fork(fetchLookupSaga),
  ]);
}
