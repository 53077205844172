import {
  useEffect,
  useReducer,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { sortBy, isEmpty, map, filter, isNil, find, get } from 'lodash';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/lab/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import {
  VineaNovaSelectors,
  VineaNovaActions,
  VineaHooks,
} from 'vineanova-redux-artifacts';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { FieldLabelInput } from '../../components/TextField';
import { VineaAlert } from '../../components/VineaAlert';
import { VineaAutoComplete } from '../../components/ComboBox';
import { BlocksTransferList } from '../../components/SamplingComponents';
import { usePlansHook } from '../Plans/hooks/usePlansHook';
import { Datepicker } from '../../components/Datepicker';
import {
  commonGridColumns,
  commonGridHeaderNames,
  viewDateFormat,
} from '../../constants';
import { useDispatch } from 'react-redux';
import {
  sagaActionTypes,
  vineaDetails,
  apiTypes,
  IdentityTypeIds,
  IdentityRelationshipTypes,
} from '../../constants';
import {
  getSupplyContractType,
  getUserPreferences,
} from '../../redux/selectors';
import {
  addGenericReducer,
  types,
  supplyContractState,
} from '../VineyardIdentity/stateReducers';
import { SupplyContractSchema } from '../VineyardIdentity/validations';
import { formatDate } from '../../constants/formatter';
import { syncValidator } from '../../utils/validator';
import { useSupplyContractsHook } from './useSupplyContractsHook';
import { VineaCommonGrid } from '../../components/Grid';

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 350,
  '& .MuiFormControl-root': {
    minWidth: 350,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const CreateSupplyContract = forwardRef((props, ref) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [formdata, dispatch] = useReducer(
    addGenericReducer,
    supplyContractState,
  );
  const {
    onSaveStepData,
    step,
    stepData,
    calculateNameToCheck,
    matchingIdentities,
  } = props;

  const formWrite = useSelector(state => state.formWrite);
  const [alertType, setAlertType] = useState(false);
  const [inSavingMode, setSaveMode] = useState(false);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = useState(false);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [isIdentityTrigger, setIdentityTrigger] = useState(false);

  const [selectedBlocks, setSelectedBlocks] = useState([]);

  const { basicSettings = {} } = useSelector(state =>
    getUserPreferences(state),
  );
  const { businessUnitID: defaultBusinessUnitID } = basicSettings;
  const { blockVintagesLoading, blockVintagesVineyards } = usePlansHook();
  const { data: sampleBlockVintages } = VineaHooks.useFetchSampleBlockVintages({
    queryParams: {
      SampleSetID: 0,
      RegionID: 0,
      VintageID: 0,
    },
  });

  const [blockGroups, setBlockGroups] = useState([]);

  const lkpSupplyContractType = useSelector(state =>
    getSupplyContractType(state),
  );
  const { supplyingParties, purchasingParties, primaryContacts } =
    useSupplyContractsHook();

  // Related Vineyards to Grower
  const { data: relatedData } = useSelector(
    state => state.entities.identityRelationship,
  );
  const { isLoaded: relatedVineyardLoaded, isLoading: relatedVineyardLoading } =
    useSelector(VineaNovaSelectors.getIdentityRelationshipEntityMeta);

  const relatedVineyards = filter(relatedData, {
    relationshipTypeID: IdentityRelationshipTypes.VINEYARD_TO_PARENT_VINEYARD,
  }).map(vin => parseInt(vin.dependentIdentityID));

  useImperativeHandle(ref, () => ({
    saveForm() {
      const validationErrors = syncValidator(SupplyContractSchema)(formdata);
      if (!isEmpty(validationErrors)) {
        dispatch({ type: types.ERROR, payload: validationErrors });
      } else {
        const data = {
          contractName: formdata.contractName,
          IdentityTypeID: IdentityTypeIds.SUPPLY_CONTRACT,
          PurchasingPartyIdentityID: formdata.purchasingPartyIdentityID,
          SupplyingPartyIdentityID: formdata.supplyingPartyIdentityID,
          SupplyContractTypeID: formdata.supplyContractTypeID,
          PrimaryContactIdentityID: formdata.primaryContactIdentityID,
          BusinessUnitID: defaultBusinessUnitID,
          DateSigned: formdata.dateSigned,
          DateEnded: null,
          BlockVintageIDs: isEmpty(selectedBlocks)
            ? ''
            : selectedBlocks.join(','),
        };
        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.supplyContract,
            methodType: apiTypes.POST,
          },
        });
        setSaveMode(true);
        setActionTriggered(true);
        setIdentityTrigger(true);
      }
    },
  }));

  const handleOnAddBlocks = event => {
    const {
      target: { name, value },
    } = event;

    if (name === 'blockVintageIDs') {
      const validBlocks = filter(value, v => {
        const vineyardName = get(
          find(sampleBlockVintages, { blockVintageID: v }),
          'vineyardName',
        );
        if (blockGroups.includes(vineyardName)) return v;
      });

      setSelectedBlocks(validBlocks);
    }
  };

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  useEffect(() => {
    calculateNameToCheck(formdata);
  }, [formdata.contractName]);

  // Set contract name if no contract name is entered
  useEffect(() => {
    if (
      isNil(formdata.contractName) &&
      !isNil(formdata.purchasingPartyIdentityID) &&
      !isNil(formdata.supplyingPartyIdentityID) &&
      !isNil(formdata.supplyContractTypeID) &&
      !isNil(formdata.dateSigned)
    ) {
      const supplyContractAbbreviation = get(
        find(lkpSupplyContractType, { id: formdata.supplyContractTypeID }),
        'value',
      );

      const growerName = get(
        find(supplyingParties, { id: formdata.supplyingPartyIdentityID }),
        'value',
      );
      const purchaser = get(
        find(purchasingParties, { id: formdata.purchasingPartyIdentityID }),
        'value',
      );

      const defaultContractName = `${supplyContractAbbreviation} between ${growerName} and ${purchaser} commencing on ${formdata.dateSigned}`;

      dispatch({
        type: types.UPDATE,
        payload: { contractName: defaultContractName },
      });
    }
  }, [
    formdata.purchasingPartyIdentityID,
    formdata.supplyingPartyIdentityID,
    formdata.supplyContractTypeID,
    formdata.dateSigned,
  ]);

  useEffect(() => {
    const { data: formWriteData, hasError, isLoading } = formWrite;
    if (inSavingMode && !isLoading) {
      if (!hasError) {
        setSaveMode(false);
        onSaveStepData(
          { data: formWriteData, isSaving: false, isComplete: true },
          step,
        );
      } else {
        setSaveMode(false);
        onSaveStepData(
          {
            data: { identityId: null },
            isSaving: false,
            isComplete: false,
            hasError: true,
          },
          step,
        );
      }
    }
  }, [inSavingMode, formWrite, onSaveStepData, step]);

  useEffect(() => {
    const { hasError, data, isLoaded } = formWrite;
    if (isLoaded && actionTriggered) {
      if (!hasError && !isEmpty(data) && isIdentityTrigger) {
        setIdentityTrigger(false);
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        setAlertType(true);
        const {
          data: { id, identityTypeID },
        } = formWrite;
        dispatchAPI({
          type: sagaActionTypes.FETCH_IDENTITIES,
          payload: {
            id,
            identityTypeId: identityTypeID,
          },
        });
      }
    }
  }, [
    formWrite,
    actionTriggered,
    isIdentityTrigger,
    enqueueSnackbar,
    t,
    dispatchAPI,
  ]);

  // If user changes supplying party then update vineyards
  useEffect(() => {
    if (
      !blockVintagesLoading &&
      relatedVineyardLoaded &&
      !relatedVineyardLoading
    ) {
      const filteredBlockVintageVineyards = filter(blockVintagesVineyards, bv =>
        relatedVineyards.includes(bv.vineyardID),
      );
      const filteredBlockGroups = map(
        filteredBlockVintageVineyards,
        'vineyardName',
      );
      setBlockGroups(filteredBlockGroups);

      setSelectedBlocks([]);
    }
  }, [
    formdata.supplyingPartyIdentityID,
    blockVintagesLoading,
    relatedVineyardLoaded,
    relatedVineyardLoading,
  ]);

  useEffect(() => {
    // set relatedVineyards
    if (
      !isNil(formdata.supplyingPartyIdentityID) &&
      formdata.supplyingPartyIdentityID > 0
    ) {
      dispatchAPI(
        VineaNovaActions.api.v1.identityRelationship.get.request({
          queryParams: {
            IdentityID: formdata.supplyingPartyIdentityID,
          },
        }),
      );
    }
  }, [formdata.supplyingPartyIdentityID]);

  // Load api data initially
  useEffect(() => {
    dispatch({
      type: types.UPDATE,
      payload: {
        ...stepData,
        contractName: stepData.contractName,
        supplyingPartyIdentityID: stepData.supplyingPartyIdentityID,
        purchasingPartyIdentityID: stepData.purchasingPartyIdentityID,
        supplyContractTypeID: stepData.supplyContractTypeID,
        dateSigned: stepData.dateSigned,
        primaryContactIdentityID: stepData.primaryContactIdentityID,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      elevation={0}
      data-testid="new-plan"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      {' '}
      <Grid item xs={12} data-testid="vineaalert">
        <VineaAlert isOpen={!!false} onExit={() => setErrorInSubmit(false)} />
      </Grid>
      {warningInSubmit && (
        <Collapse in={warningInSubmit}>
          <Alert
            variant="standard"
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWarningInSubmit(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Warning: Choose at least one block to create a New Supply Contract
          </Alert>
        </Collapse>
      )}
      <Stack>
        <Grid item xs={12} md={12} lg={8} data-testid="grid-top-wrapper">
          <Stack direction="row" width="100%">
            <Box px={2}>
              <StyledBox mt={3}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                  }}
                  error={!!formdata.errors.purchasingPartyID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.purchasingPartyIdentityID}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={purchasingParties}
                    labelVariant="body1"
                    label={t('Purchasing Party')}
                    name={t('Purchasing Party')}
                    id="purchasingPartyIdentityID"
                    displayLabel
                    inputProps={{
                      name: 'purchasingPartyIdentityID',
                    }}
                  />
                  {formdata.errors.purchasingPartyIdentityID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.purchasingPartyIdentityID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledBox mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                  }}
                  error={!!formdata.errors.supplyingPartyIdentityID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.supplyingPartyIdentityID}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={supplyingParties}
                    labelVariant="body1"
                    label={t('Supplying Party')}
                    name={t('Supplying Party')}
                    id="supplyingPartyIdentityID"
                    displayLabel
                    inputProps={{
                      name: 'supplyingPartyIdentityID',
                    }}
                  />
                  {formdata.errors.supplyingPartyIdentityID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.supplyingPartyIdentityID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledBox mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                  }}
                  error={!!formdata.errors.supplyContractTypeID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.supplyContractTypeID || 0}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={lkpSupplyContractType}
                    labelVariant="body1"
                    label={t('Contract Type')}
                    name={t('Contract Type')}
                    id="supplyContractTypeID"
                    displayLabel
                    inputProps={{
                      name: 'supplyContractTypeID',
                    }}
                  />
                  {formdata.errors.supplyContractTypeID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.supplyContractTypeID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledBox mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                  }}
                  error={!!formdata.errors.dateSigned}
                  data-testid="dateSigned-formcontrol"
                >
                  <Datepicker
                    id="dateSigned"
                    label={t('Date Signed')}
                    placeholder="DD/MM/YYYY"
                    inputFormat={viewDateFormat}
                    formatDate={formatDate}
                    variant="outlined"
                    size="small"
                    name="dateSigned"
                    value={formdata.dateSigned}
                    onChange={handleChange}
                    inputProps={{
                      'data-testid': 'dateSigned',
                      'data-name': 'dateSigned',
                    }}
                  />
                  {formdata.errors.dateSigned && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.dateSigned}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledBox mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                  }}
                >
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="contractName"
                    name="contractName"
                    label={t('Contract Name')}
                    size="small"
                    inlineLabel
                    value={formdata.contractName}
                    onChange={handleChange}
                    error={formdata.errors.contractName}
                  />
                </FormControl>
              </StyledBox>
              <StyledBox mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                  }}
                  error={!!formdata.errors.primaryContactIdentityID}
                  data-testid="primaryContactIdentityID-formcontrol"
                >
                  <VineaAutoComplete
                    value={formdata.primaryContactIdentityID}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={primaryContacts}
                    labelVariant="body1"
                    label={t('Primary Contact')}
                    name={t('Primary Contact')}
                    id="primaryContactIdentityID"
                    displayLabel
                    inputProps={{
                      name: 'primaryContactIdentityID',
                    }}
                  />
                  {formdata.errors.primaryContactIdentityID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.primaryContactIdentityID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
            </Box>
            <Grid item xs={12}>
              <StyledBox mt={1}>
                <Box ml={-1} data-testid="simple-transfer-list">
                  <BlocksTransferList
                    sampleBlockVintages={sortBy(sampleBlockVintages, [
                      'vineyardName',
                      'blockName',
                    ])}
                    selectedBlocks={selectedBlocks}
                    isLoading={blockVintagesLoading || relatedVineyardLoading}
                    groups={blockGroups}
                    onChange={handleOnAddBlocks}
                  />
                </Box>
              </StyledBox>
            </Grid>
          </Stack>
        </Grid>
        <Box
          sx={{
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(2),
          }}
        >
          <VineaCommonGrid
            heading={t(commonGridHeaderNames.SIMILAR_IDENTITIES)}
            colHeaders={commonGridColumns.SIMILAR_IDENTITIES}
            tableData={matchingIdentities}
            hasManageOption={false}
            hasActiveToggle={false}
            tableHeight={200}
          />
        </Box>
      </Stack>
    </Paper>
  );
});

export default CreateSupplyContract;
