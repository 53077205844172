/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { format, isValid, parse, isBefore } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import shortId from 'shortid';
import { find, isNull } from 'lodash';
import sortBy from 'lodash/sortBy';
import {
  getLookupContactMethods,
  getAllIdentityContactMethods,
  getLookupTowns,
  getLookupCountry,
} from '../../../redux/selectors';

import {
  ContactSchema,
  ContactAddressSchema,
  EmailSchema,
  PhoneSchema,
  URLSchema,
} from '../validations';
import { syncValidator } from '../../../utils/validator';
import { validDateFormat } from '../../../utils/DateUtil';
import {
  reducers,
  sagaActionTypes,
  vineaDetails,
  actionTypes,
  IdentityTypeIds,
  apiTypes,
  similarContactTypeMapping,
  contactMethodTypes,
  dateFormat,
} from '../../../constants';
import logger from '../../../utils/winstonLogger';

const useIdContactsHook = (
  identityTypeId = IdentityTypeIds.VINEYARD,
  identityId = null,
) => {
  // state
  const [identityContacts, setIdentityContacts] = useState([]);
  // const [addressList, setAddressList] = useState([]);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [seqToSave, setSeqToSave] = useState(null);
  const [previous, setPrevious] = useState([]);
  const [formLoadingStatus, setFormLoadingStatus] = useState(false);
  const [alert, setAlert] = useState('');

  logger.debug('identityTypeId', identityTypeId);
  // dispatches;
  const dispatchAPI = useDispatch();
  const { id: pathParamIdentityId = identityId } = useParams();

  // selectors
  const lkpContactMethods = useSelector(state =>
    getLookupContactMethods(state),
  );

  const lkpCountryList = useSelector(state => getLookupCountry(state));
  const lkpTownList = useSelector(state => getLookupTowns(state));
  const { data: overViewData } = useSelector(
    state => state.identityOverviewDetails,
  );

  const {
    isLoading,
    hasError,
    isLoaded,
    data: contactMethodsData,
  } = useSelector(state => getAllIdentityContactMethods(state));

  const {
    isLoading: formLoading,
    hasError: formError,
    isLoaded: formLoaded,
    data: formWriteData,
  } = useSelector(state => state.formWrite);

  const constructContacts = data => {
    if (Array.isArray(data) && !isEmpty(data) && !isEmpty(lkpContactMethods)) {
      const newRowData = data.map(f => {
        const {
          contactMethodType: contactType = null,
          isEmail,
          isAddress,
          isPhone,
        } = lkpContactMethods.find(cmt => cmt.id === f.contactMethodTypeId);
        const isContactTypeOf = isEmail
          ? 'isEmail'
          : isPhone
          ? 'isPhone'
          : isAddress
          ? 'isAddress'
          : contactType.toLowerCase().includes('fax')
          ? 'isFax'
          : contactType.toLowerCase().includes('url')
          ? 'isURL'
          : null;

        return {
          id: f.id,
          identityID: f.identityID,
          contactMethodDetails: f.contactMethodDetails,
          contactMethodType: contactType,
          contactMethodTypeId: f.contactMethodTypeId || 0,
          isPrimaryContact: f.isPrimaryContact || false,
          effectiveFromDate: f.effectiveFromDate,
          effectiveToDate: f.effectiveToDate,
          isEditMode: false,
          methodType: f.methodType || apiTypes.POST,
          addressLine1: isAddress
            ? (f.contactMethodDetails ?? '').split(',')[0]
            : '',
          addressLine2: f.addressLine2 || '',
          suburb: f.suburb || '',
          postCode: f.postCode || '',
          townID: f.townID || 0,
          countryID: f.countryID || 0,
          similarContactMethodOptions: contactType
            ? getSimilarContactTypes(isContactTypeOf)
            : [],
          errors: {},
          addressTS: f.addressTS,
          ts: f.ts,
        };
      });
      logger.debug('newRowData');
      logger.debug(newRowData);
      return newRowData;
    }
    return [];
  };

  useEffect(() => {
    if (formLoading && actionTriggered) setFormLoadingStatus(true);
    if (
      !formLoading &&
      formLoaded &&
      !isEmpty(formWriteData) &&
      !formError &&
      formLoadingStatus
    ) {
      setFormLoadingStatus(false);
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
    }
  });

  // useEffects
  useEffect(() => {
    if (isLoading || hasError || !isLoaded) {
      setIdentityContacts([]);
    } else {
      const newRows = constructContacts(contactMethodsData);
      setIdentityContacts(newRows);
    }
  }, [contactMethodsData, isLoading, hasError, isLoaded, lkpContactMethods]);

  /** Mapping Similar Contact Method  Types */
  const getSimilarContactTypes = useCallback(
    contactTypeSelected => {
      if (isNull(contactTypeSelected)) return null;
      const mappedListed = [];
      if (contactTypeSelected.toLowerCase().includes('email')) {
        Array.prototype.push.apply(
          mappedListed,
          similarContactTypeMapping.email,
        );
      } else if (contactTypeSelected.toLowerCase().includes('phone')) {
        Array.prototype.push.apply(
          mappedListed,
          similarContactTypeMapping.phone,
        );
      } else if (contactTypeSelected.toLowerCase().includes('fax')) {
        Array.prototype.push.apply(mappedListed, similarContactTypeMapping.fax);
      } else if (contactTypeSelected.toLowerCase().includes('url')) {
        Array.prototype.push.apply(mappedListed, similarContactTypeMapping.url);
      } else if (contactTypeSelected.toLowerCase().includes('address')) {
        Array.prototype.push.apply(
          mappedListed,
          similarContactTypeMapping.address,
        );
      } else {
        Object.keys(contactMethodTypes).forEach(key => {
          const methodTypeValue = contactMethodTypes[key];
          mappedListed.push(methodTypeValue);
        });
      }
      if (mappedListed.length > 0) {
        // eslint-disable-next-line array-callback-return
        const getRelevantContactMethods = lkpContactMethods.filter(f =>
          mappedListed.includes(f.value),
        );
        return sortBy(getRelevantContactMethods, ['value']);
      }

      return sortBy(mappedListed, ['value']);
    },
    [lkpContactMethods],
  );

  const onAddNew = useCallback(() => {
    const newRows = identityContacts.concat([
      {
        id: shortId.generate(),
        identityID: null,
        contactMethodDetails: '',
        contactMethodType: '',
        contactMethodTypeId: 0,
        isPrimaryContact: false,
        effectiveFromDate: format(new Date(), dateFormat),
        effectiveToDate: null,
        isEditMode: true,
        methodType: apiTypes.POST,
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        postCode: '',
        townID: 0,
        countryID: 0,
        similarContactMethodOptions: getSimilarContactTypes(' '),
        errors: {},
        addressTS: null,
        ts: null,
      },
    ]);
    setIdentityContacts(newRows);
  }, [identityContacts, getSimilarContactTypes]);

  /** changes to the row data */
  const handleOnChangeRowData = useCallback(
    (e, rowdata) => {
      logger.debug('rowdata');
      const {
        target: { value, name, checked },
      } = e;

      const { id } = rowdata;
      const isFromDateModified = name === 'effectiveFromDate';

      const newRowsNameValue = identityContacts.map(row => {
        if (row.id === id) {
          const { warning, ...restCols } = row;
          return {
            ...restCols,
            effectiveToDate: isFromDateModified ? null : row.effectiveToDate,
            [name]:
              id === row.id && name !== 'isPrimaryContact' ? value : row[name],
          };
        }
        return row;
      });

      let newRows = newRowsNameValue.map(row => {
        if (id === row.id) {
          const { warning, errors, ...restCols } = row;

          const validationErrors = syncValidator(ContactSchema)(row);
          let newRowValue = {};

          if (!isEmpty(errors)) {
            newRowValue = {
              ...restCols,
              errors: validationErrors,
            };
          } else {
            newRowValue = {
              ...restCols,
              effectiveToDate: isFromDateModified ? null : row.effectiveToDate,
              errors,
            };
          }

          return newRowValue;
        }
        return row;
      });

      if (name === 'isPrimaryContact') {
        newRows = identityContacts.map(row => {
          return {
            ...row,
            [name]: id === row.id ? checked : row[name],
          };
        });
      }

      // duplicate row validation
      const currentRowData = newRows.find(f => f.id === id);
      const duplicateRows = newRows.filter(
        f =>
          f.contactMethodTypeId === currentRowData.contactMethodTypeId &&
          f.contactMethodDetails === currentRowData.contactMethodDetails &&
          f.id !== currentRowData.id &&
          ((f.effectiveToDate >= currentRowData.effectiveFromDate &&
            f.effectiveFromDate <= currentRowData.effectiveFromDate &&
            currentRowData.effectiveFromDate !== null &&
            f.effectiveToDate !== null &&
            f.effectiveFromDate !== '') ||
            (f.effectiveFromDate <= currentRowData.effectiveFromDate &&
              f.effectiveToDate === null &&
              currentRowData.effectiveFromDate !== null) ||
            (f.effectiveFromDate >= currentRowData.effectiveFromDate &&
              currentRowData.effectiveToDate === null &&
              currentRowData.effectiveFromDate !== null) ||
            (f.effectiveToDate >= currentRowData.effectiveToDate &&
              f.effectiveFromDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate !== null &&
              f.effectiveFromDate !== null &&
              f.effectiveToDate !== null) ||
            (f.effectiveFromDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate === null) ||
            (f.effectiveFromDate >= currentRowData.effectiveFromDate &&
              f.effectiveToDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate !== null &&
              currentRowData.effectiveFromDate !== null &&
              f.effectiveFromDate !== null &&
              f.effectiveToDate !== null)),
      );
      // update validation errors
      const hasDuplicateRowValue = duplicateRows.length > 0;
      if (hasDuplicateRowValue) {
        newRows = identityContacts.map(row => {
          if (id === row.id) {
            return {
              ...currentRowData,
              warning:
                'You cannot create a duplicate record. Please, update details or reuse the existing one.',
            };
          }
          return row;
        });
      }

      if (name === 'contactMethodTypeId') {
        newRows = newRows.map(row => {
          if (row.id === id) {
            const typeName = get(
              lkpContactMethods.find(f => f.id === row.contactMethodTypeId),
              'contactMethodType',
              null,
            );
            return { ...row, contactMethodType: typeName };
          }
          return row;
        });
      }

      if (name && ['effectiveFromDate', 'effectiveToDate'].includes(name)) {
        const currentRow = newRows.find(f => f.id === id);
        const { effectiveFromDate, effectiveToDate } = currentRow || {};
        const fromDate = isValid(validDateFormat(effectiveFromDate))
          ? validDateFormat(effectiveFromDate)
          : null;
        const toDate = isValid(validDateFormat(effectiveToDate))
          ? validDateFormat(effectiveToDate)
          : null;
        const isBeforeStartDate =
          fromDate && toDate && isBefore(toDate, fromDate);

        newRows = newRows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              errors: isBeforeStartDate ? { effectiveToDate: true } : {},
            };
          }
          return row;
        });
      }
      setIdentityContacts(newRows);
      setAlert('Update');
    },
    [identityContacts, lkpContactMethods],
  );

  /** update when address Type has modified */
  const handleOnChangeAddress = useCallback(
    (e, rowdata) => {
      const {
        target: { value, name },
      } = e;
      const { id } = rowdata;
      let newRows = identityContacts.map(row => {
        return {
          ...row,
          [name]: id === row.id ? value : row.value,
        };
      });

      newRows = newRows.map(row => {
        if (row.id === id) {
          const { errors } = row;

          const validationErrors = syncValidator(ContactAddressSchema)(row);
          let newRowValue = {};

          if (!isEmpty(errors)) {
            newRowValue = {
              ...row,
              errors: validationErrors,
            };
          } else {
            newRowValue = {
              ...row,
              errors,
            };
          }
          return newRowValue;
        }
        return row;
      });

      if (name === 'contactMethodTypeId') {
        newRows = newRows.map(row => {
          if (row.id === id) {
            const typeName = get(
              lkpContactMethods.find(f => f.id === row.contactMethodTypeId),
              'contactMethodType',
              null,
            );
            return {
              ...row,
              contactMethodType: typeName,
            };
          }
          return row;
        });
      }
      const currentRowData = newRows.find(f => f.id === id);
      const countryName = lkpCountryList.find(
        f => f.id === currentRowData.countryID,
      ).value;

      const townName = lkpTownList.find(
        f => f.id === currentRowData.townID,
      ).value;
      const contactMethodDetails = `${currentRowData.addressLine1}, ${currentRowData.addressLine2}, ${currentRowData.suburb}, ${townName} ${currentRowData.postCode}, ${countryName}`;
      const duplicateRows = newRows.filter(
        f =>
          f.contactMethodTypeId === currentRowData.contactMethodTypeId &&
          f.contactMethodDetails === contactMethodDetails &&
          f.id !== currentRowData.id &&
          ((f.effectiveToDate >= currentRowData.effectiveFromDate &&
            f.effectiveFromDate <= currentRowData.effectiveFromDate &&
            currentRowData.effectiveFromDate !== null &&
            f.effectiveToDate !== null &&
            f.effectiveFromDate !== '') ||
            (f.effectiveFromDate <= currentRowData.effectiveFromDate &&
              f.effectiveToDate === null &&
              currentRowData.effectiveFromDate !== null) ||
            (f.effectiveFromDate >= currentRowData.effectiveFromDate &&
              currentRowData.effectiveToDate === null &&
              currentRowData.effectiveFromDate !== null) ||
            (f.effectiveToDate >= currentRowData.effectiveToDate &&
              f.effectiveFromDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate !== null &&
              f.effectiveFromDate !== null &&
              f.effectiveToDate !== null) ||
            (f.effectiveFromDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate === null) ||
            (f.effectiveFromDate >= currentRowData.effectiveFromDate &&
              f.effectiveToDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate !== null &&
              currentRowData.effectiveFromDate !== null &&
              f.effectiveFromDate !== null &&
              f.effectiveToDate !== null)),
      );

      // update validation errors
      const hasDuplicateRowValue = duplicateRows.length > 0;
      if (hasDuplicateRowValue) {
        newRows = identityContacts.map(row => {
          if (id === row.id) {
            return {
              ...currentRowData,
              warning:
                'You cannot create a duplicate record. Please, update details or reuse the existing one.',
            };
          }
          return row;
        });
      } else {
        newRows = identityContacts.map(row => {
          if (id === row.id) {
            return {
              ...currentRowData,
              warning: null,
            };
          }
          return row;
        });
      }
      setIdentityContacts(newRows);
    },
    [identityContacts, lkpContactMethods, lkpCountryList, lkpTownList],
  );

  const onToggleEditMode = useCallback((id, rows) => {
    setPrevious(rows);
    setIdentityContacts(() => {
      return rows.map(row => {
        if (row.id === id) {
          return {
            ...row,
            isEditMode: !row.isEditMode,
            methodType: apiTypes.PUT,
          };
        }
        return row;
      });
    });
  }, []);

  const onToggleAddressEditMode = useCallback((data, rows) => {
    setPrevious(rows);
    logger.debug('AddressData');
    logger.debug(data);
    setIdentityContacts(() => {
      return rows.map(row => {
        if (row.id === data.id) {
          return {
            ...row,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            suburb: data.suburb,
            postCode: data.postCode,
            townID: data.townID,
            countryID: data.countryID,
            isEditMode: !row.isEditMode,
            methodType: apiTypes.PUT,
          };
        }
        return row;
      });
    });
  }, []);

  const handleRevert = useCallback(
    id => {
      let newRows;
      const currentRow = find(identityContacts, f => f.id === id);
      if (isNull(currentRow.ts)) {
        newRows = identityContacts.filter(f => f.id !== id);
      } else {
        newRows = identityContacts.map(row => {
          if (row.id === id) {
            const prevState = find(previous, f => f.id === id);
            return {
              ...row,
              contactMethodTypeId: prevState.contactMethodTypeId,
              contactMethodType: prevState.contactMethodType,
              isPrimaryContact: prevState.isPrimaryContact,
              contactMethodDetails: prevState.contactMethodDetails,
              effectiveFromDate: prevState.effectiveFromDate,
              effectiveToDate: prevState.effectiveToDate,
              warning: '',
            };
          }
          return row;
        });
      }
      setIdentityContacts(newRows);
      onToggleEditMode(id, newRows);
    },
    [identityContacts, onToggleEditMode, previous],
  );

  const handleOnDeleteRow = useCallback(
    id => {
      const selectedRecord = find(identityContacts, f => f.id === id);
      if (isEmpty(selectedRecord.ts) || isNull(selectedRecord.ts)) {
        const updatedRows = identityContacts.filter(f => f.id !== id);
        setIdentityContacts(updatedRows);
      } else {
        const data = {
          ID: id,
          ts: selectedRecord.ts,
        };
        dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.contactMethod,
            methodType: apiTypes.DELETE,
          },
        });
        setActionTriggered(true);
        setAlert('Delete');
      }
    },
    [dispatchAPI, identityContacts],
  );

  const handleOnSaveRow = id => {
    const dataToSave = identityContacts.find(f => f.id === id);
    let validationErrors = [];
    validationErrors = syncValidator(ContactSchema)(dataToSave);
    const contactType = get(
      lkpContactMethods.find(f => f.id === dataToSave.contactMethodTypeId),
      'contactMethodType',
      null,
    );

    const isAddressType = contactType && contactType.includes('Address');
    const isEmailType = contactType && contactType.includes('Email');
    const isPhoneType = contactType && contactType.includes('Phone');
    const isURLType = contactType && contactType.includes('URL');

    if (isAddressType) {
      const validationErrorsAddress =
        syncValidator(ContactAddressSchema)(dataToSave);
      validationErrors = validationErrorsAddress;
    }

    if (isEmailType) {
      const validationErrorsEmail = syncValidator(EmailSchema)(dataToSave);
      validationErrors = validationErrorsEmail;
    }
    if (isPhoneType) {
      const validationErrorsPhone = syncValidator(PhoneSchema)(dataToSave);
      validationErrors = validationErrorsPhone;
    }
    if (isURLType) {
      const validationErrorsURL = syncValidator(URLSchema)(dataToSave);
      validationErrors = validationErrorsURL;
    }

    // verify validations
    if (!isEmpty(dataToSave.errors)) {
      logger.debug('validations errors');
    } else if (!isEmpty(validationErrors)) {
      const newRows = identityContacts.map(row => {
        if (row.id === id) {
          return { ...row, errors: validationErrors };
        }
        return row;
      });
      setIdentityContacts(newRows);
    } else {
      const countryName = lkpCountryList.find(
        f => f.id === dataToSave.countryID,
      ).value;

      const townName = lkpTownList.find(f => f.id === dataToSave.townID).value;
      const contactMethodDetails = `${dataToSave.addressLine1}, ${dataToSave.addressLine2}, ${dataToSave.suburb}, ${townName}  ${dataToSave.postCode}, ${countryName} `;
      const data = {
        ID: dataToSave.methodType === apiTypes.POST ? null : id,
        identityID: pathParamIdentityId, // TODO: remove later
        ContactMethodDetails: dataToSave.contactMethodDetails,
        ContactMethodTypeID: dataToSave.contactMethodTypeId,
        IsPrimaryContact: dataToSave.isPrimaryContact || false,
        effectiveFromDate:
          dataToSave.effectiveFromDate || format(new Date(), dateFormat),
        effectiveToDate: dataToSave.effectiveToDate,
        ts: dataToSave.methodType === apiTypes.POST ? null : dataToSave.ts,
      };

      const contactAddressData = {
        ID: dataToSave.methodType === apiTypes.POST ? null : id,
        identityID: pathParamIdentityId || overViewData.id, // TODO: remove later
        ContactMethodTypeID: dataToSave.contactMethodTypeId,
        ContactMethodDetails: contactMethodDetails,
        addressLine1: dataToSave.addressLine1,
        addressLine2: dataToSave.addressLine2 || '',
        suburb: dataToSave.suburb || '',
        postCode: dataToSave.postCode || '',
        townID: dataToSave.townID || null,
        countryID: dataToSave.countryID || 0,
        IsPrimaryContact: dataToSave.isPrimaryContact || false,
        effectiveFromDate:
          dataToSave.effectiveFromDate || format(new Date(), dateFormat),
        effectiveToDate: dataToSave.effectiveToDate,
        ts: dataToSave.methodType === apiTypes.POST ? null : dataToSave.ts,
      };

      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data: isAddressType ? contactAddressData : data,
          name: isAddressType
            ? vineaDetails.addressContact
            : vineaDetails.contactMethod,
          methodType: dataToSave.methodType,
        },
      });
      setSeqToSave(id);
      setActionTriggered(true);
      if (dataToSave.methodType === 'PUT') {
        setAlert('Update');
      } else {
        setAlert('Created');
      }
    }
  };

  return {
    identityId: pathParamIdentityId,
    isLoadingIdContacts: isLoading,
    hasErrorIdContacts: hasError,
    setSeqToSave,
    setActionTriggered,
    actionTriggered,
    seqToSave,
    identityContacts,
    setIdentityContacts,
    onAddNew,
    handleRevert,
    handleOnChangeRowData,
    onToggleEditMode,
    handleOnDeleteRow,
    lkpContactMethods,
    onToggleAddressEditMode,
    handleOnSaveRow,
    handleOnChangeAddress,
    lkpCountryList,
    lkpTownList,
    alert,
  };
};

export { useIdContactsHook };
