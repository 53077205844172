import React from 'react';
import Box from '@mui/material/Box';
import { VintageStatusDropdown } from './VintageStatusDropdown';

export const RenderCellWithVintageStatus = params => {
  const {
    row: { id, isEditMode, blockVintageStatusID, vintageStatus } = {},
    colDef: { onChange } = {},
  } = params || {};
  return (
    <Box
      display="flex"
      flex="1"
      data-testid="gridvintagestatus"
      sx={{
        width: '100%',
        '& .MuiFormControl-root': {
          width: '100%',
        },
        '& .MuiInputBase-root': {
          width: '100%',
        },
      }}
    >
      {!!isEditMode && (
        <VintageStatusDropdown
          value={blockVintageStatusID}
          onChange={onChange}
          name="blockVintageStatusID"
          id="blockVintageStatusID"
          rowId={id}
        />
      )}

      {!isEditMode && <div>{vintageStatus}</div>}
    </Box>
  );
};
