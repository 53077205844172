/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { AzureMapsContext } from 'react-azure-maps';
import Stack from '@mui/material/Stack';
import { layer, source } from 'azure-maps-control';
import MapComponent from './MapComponent';
import { reducers, actionTypes } from '../../constants';
import { VintageMeasurePopup } from '../../containers/Tracking/Popups';
import { useTrackingHook } from '../../containers/Tracking/Hooks/useTrackingHook';
import { isNil } from 'lodash';

const dataSourceRef = new source.DataSource();
const layerRef = new layer.PolygonLayer(dataSourceRef, null, {
  fillColor: 'green',
  fillOpacity: 0.5,
});

const selecteddataSourceRef = new source.DataSource();
const selectedLayer = new layer.LineLayer(selecteddataSourceRef, null, {
  strokeColor: '#00ffff',
  strokeWidth: 2,
});
const selectedSymbolLayer = new layer.SymbolLayer(selecteddataSourceRef, null, {
  iconOptions: {
    // Hide the default image.
    image: 'none',
  },
  textOptions: {
    // Reference the label property of the polygon as the text field.
    color: '#ffffff',
    size: 10,
    textField: ['get', 'labelName'],
  },
});

const vineyarddataSourceRef = new source.DataSource();
const vineyardLayer = new layer.LineLayer(vineyarddataSourceRef, null, {
  strokeColor: 'white',
  strokeWidth: 2,
});

const MapController = props => {
  const { allGeoJson, seletedGeoJson, vineyardID, vineyardGeometry } = props;

  const dispatchAPI = useDispatch();
  const { mapRef, isMapReady } = useContext(AzureMapsContext);
  const [open, setOpen] = useState(false);
  const [blockInfo, setBlockInfo] = useState({});
  const { searchFilterData, handleOnFetchVintageData } = useTrackingHook();

  const onBlockRowClick = ({ BlockId }) => {
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.trackingSearchFilter,
      payload: { ...searchFilterData, vintageID: 10, vineyardBlockId: BlockId },
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const mapBlockClickFunc = e => {
    const { properties } = e.shapes[0];
    if (properties.id) {
      onBlockRowClick(properties.id);
      setBlockInfo({ id: properties.id, name: properties.labelName });
      if (e.shapes && e.shapes.length > 0) {
        handleOnFetchVintageData(properties.id);
        handleClickOpen();
      }
    }
  };

  useEffect(() => {
    if (isMapReady && mapRef) {
      mapRef.sources.add(dataSourceRef);
      mapRef.layers.add(layerRef);

      mapRef.sources.add(selecteddataSourceRef);
      mapRef.layers.add(selectedLayer);
      mapRef.layers.add(selectedSymbolLayer);

      mapRef.sources.add(vineyarddataSourceRef);
      mapRef.layers.add(vineyardLayer);

      mapRef.setStyle({ style: 'satellite' });

      mapRef.events.add('click', mapBlockClickFunc);

      // Controls for the map
      mapRef.events.add('ready', function mapControlsFunction() {
        mapRef.controls.add(
          [
            new atlas.control.ZoomControl(),
            new atlas.control.CompassControl(),
            new atlas.control.PitchControl(),
          ],
          {
            position: 'top-right',
          },
        );
      });
      if (!isNil(vineyardGeometry))
        vineyarddataSourceRef.setShapes(vineyardGeometry);
      mapRef.setCamera({
        bounds: atlas.data.BoundingBox.fromData(vineyardGeometry),
        padding: 100,
      });
      dataSourceRef.setShapes(allGeoJson);
    }
    return () => {
      dataSourceRef.clear();
      selecteddataSourceRef.clear();
    };
  }, [isMapReady]);

  useEffect(() => {
    if (!isEmpty(seletedGeoJson) && isMapReady) {
      selecteddataSourceRef.setShapes(seletedGeoJson);
    }
  }, [seletedGeoJson, isMapReady]);

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <MapComponent />
      <VintageMeasurePopup
        blockInfo={blockInfo}
        open={open}
        close={handleClose}
        vineyardID={vineyardID}
      />
    </Stack>
  );
};

export default MapController;
