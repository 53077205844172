import { useSelector } from 'react-redux';
import { VineaHooks } from 'vineanova-redux-artifacts';
import get from 'lodash/get';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getUserPreferences } from '../redux/selectors';
import { some } from 'lodash';

const useUserResponsiveLayout = () => {
  const theme = useTheme();
  const { securityGroup = {} } = useSelector(state =>
    getUserPreferences(state),
  );
  const {
    licensedUserIdentityID: userIdentityID,
    email: userEmail,
    organisation: userOrganisation,
    firstName,
    lastName,
  } = useSelector(state => state.userAccess.data);

  const userFullName = `${firstName} ${lastName}`;

  const { data: rolesData } = VineaHooks.useFetchIdentityRole({
    queryParams: {
      IdentityID: userIdentityID,
    },
  });

  const isSupervisor = some(rolesData, {
    roleType: 'Supervisor',
    effectiveToDate: null,
  });
  const isBelowLaptop = useMediaQuery(theme.breakpoints.down('lg'));
  const isTabletUser = get(securityGroup, 'isTabletUser', false);
  const isAdministrator = get(securityGroup, 'isAdministrator', true);
  const isAllowedOnTablet = isBelowLaptop && isTabletUser;

  return {
    isBelowLaptop,
    isTabletUser,
    isAllowedOnTablet,
    isAdministrator,
    isSupervisor,
    userIdentityID,
    userEmail,
    userOrganisation,
    userFullName,
  };
};

export default useUserResponsiveLayout;
