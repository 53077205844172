import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { VineaButton } from '../../../components/VineaButton';
import {
  IdentityTypeIds,
  LOCAL_ARC_GIS_TOKEN,
  LOCAL_ARC_GIS_TOKEN_CREATED,
} from '../../../constants';
import { getAllVineyards } from '../../../redux/selectors';
import { isNil } from 'lodash';

export const SyncMaps = ({ pathParamId }) => {
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = React.useState(false);
  const [geometryHasSynced, setGeometryHasSynced] = React.useState(false);
  const [selectedVineyardID, setSelectedVineyardID] = useState(0);
  const allVineyards = useSelector(state => getAllVineyards(state));
  const dataConnectorDetails = useSelector(
    state => state.identityOverviewDetails,
  );

  const {
    isLoading: isSyncLoading,
    isLoaded: isSyncLoaded,
    hasError: syncHasError,
    data: syncData,
  } = useSelector(VineaNovaSelectors.getSyncVineyardGeometryEntity);

  const handleOnSync = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.syncVineyardGeometry.get.request({
        queryParams: {
          VineyardID: selectedVineyardID,
          ApiURL: dataConnectorDetails?.data?.apI_URL,
          Token: localStorage.getItem(LOCAL_ARC_GIS_TOKEN),
        },
      }),
    );
    setGeometryHasSynced(true);
  };

  const handleOnChange = evt => {
    const evtValue = evt.target.value;
    setSelectedVineyardID(evtValue);
  };

  // Check if the difference is less than 2hrs, as access token only is valid for 2hrs
  const checkSync = () => {
    const currentTimestamp = new Date().getTime();
    const timeDifference = Math.abs(
      currentTimestamp -
        parseInt(localStorage.getItem(LOCAL_ARC_GIS_TOKEN_CREATED)),
    );
    const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
    return timeDifference < twoHoursInMilliseconds;
  };

  // Get vineyards for vineyard dropdown on initial load
  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.searchIdentity.post.request({
        postBody: {
          IdentityTypeID: IdentityTypeIds.VINEYARD,
          FindNonActive: true,
        },
      }),
    );
  }, [dispatchAPI, pathParamId]);

  // Show snackbar once sync has finished
  useEffect(() => {
    if (
      isSyncLoaded &&
      !isSyncLoading &&
      !isNil(syncData) &&
      !(syncData === '') &&
      geometryHasSynced
    ) {
      if (!syncHasError) {
        setAlertMessage(syncData);
        setShowAlert(true);
        enqueueSnackbar('Success!', { variant: 'success' });
      } else enqueueSnackbar(t('Error'), { variant: 'Error' });
    }
  }, [isSyncLoaded, isSyncLoading, syncHasError]);

  return (
    <Paper elevation={0}>
      {showAlert && (
        <Box px={2} pt={2}>
          <Alert
            variant="outlined"
            severity="info"
            action={
              <>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setShowAlert(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </>
            }
          >
            {alertMessage}
          </Alert>
        </Box>
      )}
      <Box display="flex" flex={1} flexDirection="row" margin={1} p={2}>
        <Box
          mt={1}
          data-testid="vineyard"
          sx={{
            minWidth: 250,
            '& .MuiFormControl-root': {
              minWidth: 250,
            },
          }}
        >
          <FormControl
            sx={{
              width: 250,
            }}
            margin="none"
          >
            <VineaAutoComplete
              value={selectedVineyardID}
              onChange={handleOnChange}
              inlineLabel
              options={allVineyards}
              labelVariant="body1"
              label={t('Vineyard')}
              name={t('Vineyard')}
              displayLabel
              inputProps={{
                name: 'vineyardId',
              }}
            />
          </FormControl>
        </Box>
        <Box display="inline-flex" margin={2}>
          <VineaButton
            disabled={!(selectedVineyardID > 0) || !checkSync()}
            variant="contained"
            color="success"
            sx={{ marginRight: 1 }}
            onClick={handleOnSync}
          >
            {t('Sync')}
          </VineaButton>
        </Box>
      </Box>
    </Paper>
  );
};

SyncMaps.propTypes = {
  pathParamId: PropTypes.number.isRequired,
};
