/* eslint-disable no-undef */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';

import { isValid, format, isAfter } from 'date-fns';
import { viewDateFormat } from '../../constants';

const EMPTY_ARRAY = [];
const trackingSearchFilters = state => state.trackingSearchFilter;
// const getId = (_, id) => id;

const getCropBlockVintageById = (state, id) =>
  get(state.entities, `cropBlockVintage.entities.byId.${id}`, []);

export const selectVineyardBlockEntitiesById = createSelector(
  [
    VineaNovaSelectors.getIdentityVineyardBlocksEntityMeta,
    VineaNovaSelectors.getIdentityVineyardBlocksEntityData,
    (_, id) => id,
  ],
  (metadata, data, givenId) => {
    console.log(
      '*** >> executing selector: selectVineyardBlockEntitiesById *******>>>',
    );
    const { isLoading } = metadata;
    if (isLoading) return EMPTY_ARRAY;
    if (isEmpty(data)) return EMPTY_ARRAY;
    if (!Array.isArray(data) || isNull(data)) return EMPTY_ARRAY;
    const result = data.filter(f => f.vineyardID === givenId);
    return result;
  },
);

export const selectCropBlockVintageEntitiesById = createSelector(
  [
    VineaNovaSelectors.getCropBlockVintageEntityMeta,
    getCropBlockVintageById,
    VineaNovaSelectors.getlookupMeasureInstanceEntityData,
  ],
  (metadata, listEntityData, measureInstanceEntityData) => {
    const { isLoading } = metadata;
    const data = listEntityData;

    if (isLoading) {
      return EMPTY_ARRAY;
    }

    if (!Array.isArray(data) || isNull(data)) return EMPTY_ARRAY;
    if (isEmpty(data)) return EMPTY_ARRAY;

    const measureDate = isValid(data.measureDate)
      ? format(parse(d.measureDate, viewDateFormat, new Date()), viewDateFormat)
      : '';

    let isValuePair = false;
    if (!isEmpty(measureInstanceEntityData)) {
      isValuePair = get(
        measureInstanceEntityData.find(mi => mi.id === data.measureInstanceId),
        'isValuePair',
        false,
      );
    }

    return {
      ...data,
      measureDate,
      isValuePair,
    };
  },
);

export const getCropBlockVintageEntityData = createSelector(
  [
    VineaNovaSelectors.getCropBlockVintageEntityMeta,
    VineaNovaSelectors.getCropBlockVintageEntityData,
    VineaNovaSelectors.getlookupMeasureInstanceEntityData,
  ],
  (metadata, listEntityData, measureInstanceEntityData) => {
    const { isLoading } = metadata;
    const data = listEntityData;

    if (isLoading) {
      return EMPTY_ARRAY;
    }

    if (!Array.isArray(data) || isNull(data)) return EMPTY_ARRAY;
    if (isEmpty(data)) return EMPTY_ARRAY;

    const entityData = data.map(d => {
      const fixDate = new Date(d.measureDate);
      const measureDate =
        isValid(fixDate) && isAfter(new Date(fixDate), new Date('1980-01-01'))
          ? d.measureDate
          : '';

      let isValuePair = false;
      if (!isEmpty(measureInstanceEntityData)) {
        isValuePair = get(
          measureInstanceEntityData.find(mi => mi.id === d.measureInstanceId),
          'isValuePair',
          false,
        );
      }

      return {
        ...d,
        measureDate,
        isValuePair,
      };
    });
    return entityData;
  },
);

export const getCropBlockPhenologyEntityData = createSelector(
  [
    VineaNovaSelectors.getCropBlockPhenologyEntityMeta,
    VineaNovaSelectors.getCropBlockPhenologyEntityData,
  ],
  (metadata, listEntityData) => {
    const { isLoading } = metadata;
    const data = listEntityData;

    if (isLoading) {
      return EMPTY_ARRAY;
    }

    if (!Array.isArray(data) || isNull(data)) return EMPTY_ARRAY;
    if (isEmpty(data)) return EMPTY_ARRAY;
    const entityData = data.map(d => {
      const fixDate = new Date(d.phenologyMeasureDate);
      const measureDate =
        isValid(fixDate) && isAfter(new Date(fixDate), new Date('1980-01-01'))
          ? d.phenologyMeasureDate
          : '';

      return {
        ...d,
        measureDate,
        value: `${d.phenologyMeasureValue1} / ${d.phenologyMeasureValue2} / ${d.phenologyMeasureValue3}`,
      };
    });
    return entityData;
  },
);

export const getSelectedVintageBlockValue = createSelector(
  [
    trackingSearchFilters,
    VineaNovaSelectors.getSearchTrackingBlockVintagesEntityData,
  ],
  (trackingSearchEntities, iVineyardBlocksData) => {
    const { data: filterData = {} } = trackingSearchEntities;
    const { vineyardBlockId = 0 } = filterData;

    const block = iVineyardBlocksData.find(
      b => b.vineyardBlockID === vineyardBlockId,
    );

    const vineyardName = block?.vineyardName || '';
    const blockName = block?.blockName || '';

    return {
      vineyard: vineyardName,
      block: blockName,
    };
  },
);
