const initialState = {
  isLoading: false,
  hasError: false,
  isLoaded: false,
  error: null,
  data: {},
};

const createNamedReducer = (reducerName = '') =>
  function counter(state = initialState, action) {
    const { name, payload } = action;
    if (name !== reducerName) return state;
    switch (action.type) {
      case `FETCHING`:
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
          hasError: false,
          error: null,
        };
      case `SUCCESS`:
        return {
          ...state,
          isLoading: false,
          isLoaded: true,
          data: payload,
        };
      case `ERROR`:
        return {
          ...state,
          isLoading: false,
          isLoaded: true,
          hasError: true,
          error: payload,
        };
      case 'CLEAR_DATA':
        return {
          ...state,
          isLoaded: false,
          hasError: false,
          data: {},
        };
      case 'UPDATE_DATA':
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  };
export default createNamedReducer;
