import logger from '../utils/winstonLogger';
import { reducers, vineaDetails, apiTypes } from '../constants';
import serviceConfig from './class/serviceConfig';
import { getIsActive } from '../constants/formatter';

const generateFetchAPIUrl = (payload, reducerName) => {
  const {
    id = null,
    name = reducerName,
    identityTypeId = null,
    regionID = null,
    vintageID = null,
  } = payload;

  switch (name) {
    case reducers.repository:
      return `/Identity`;
    case reducers.details:
      return `/IdentityDetails?IdentityID=${id}`;
    case reducers.references:
      return `/IdentityReference?IdentityID=${id}&IdentityTypeID=${identityTypeId}`;
    case reducers.relationshipType:
      return `/IdentityRelationshipType?IdentityTypeID=${identityTypeId}`;
    case reducers.relationship:
      return `/IdentityRelationship?IdentityID=${id}`;
    case reducers.classifications:
      return `/IdentityClassification?IdentityID=${id}`;
    case reducers.contactMethods:
      return `/IdentityContactMethod?IdentityID=${id}`;
    case reducers.roles:
      return `/IdentityRole?IdentityID=${id}`;
    case reducers.person:
      return `/IdentityPerson?IdentityID=${id}`;
    case reducers.organisation:
      return `/IdentityOrganisation?IdentityID=${id}`;
    case reducers.vineyard:
      return `/IdentityVineyard?IdentityID=${id}`;
    case reducers.vineyardBlocks:
      return `/IdentityVineyardBlocks?IdentityID=${id}`;
    case reducers.blockVintages:
      return `/BlockVintage?VineyardBlockID=${id}`;
    case reducers.vineyardBlockRows:
      return `/IdentityVineyardBlockRows?VineyardBlockID=${id}`;
    case reducers.identityNotes:
      return `/IdentityNotes?IdentityID=${id}`;
    case reducers.sampleHeaders:
      return `/SampleHeaders?SampleSetID=${id}`;
    case reducers.sampleBlockVintages:
      return `/SampleBlockVintages?SampleSetID=${id}&RegionID=${regionID}&VintageID=${vintageID}`;
    case reducers.serviceProvider:
      return `/IdentityServiceProvider?IdentityID=${id}`;
    case reducers.dataConnection:
      return `/IdentityDataConnector?IdentityID=${id}`;
    case reducers.consumable:
      return `/IdentityConsumable?IdentityID=${id}`;
    case reducers.accommodation:
      return `/IdentityAccommodation?IdentityID=${id}`;
    case reducers.activity:
      return `/IdentityActivity?IdentityID=${id}`;
    case reducers.plan:
      return `/Plans?PlanID=${id}`;
    case reducers.supplyContract:
      return `/IdentitySupplyContract?IdentityID=${id}`;
    case reducers.job:
      return `/IdentityJob?IdentityID=${id}`;
    case reducers.vehicle:
      return `/IdentityVehicle?IdentityID=${id}`;
    default:
      return '';
  }
};

const generateAddOrUpdateAPIUrl = name => {
  switch (name) {
    case vineaDetails.identity:
      return `/IdentityOrganisation`;
    case vineaDetails.reference:
      return `/IdentityReference`;
    case vineaDetails.classification:
      return `/IdentityClassification`;
    case vineaDetails.relationship:
      return `/IdentityRelationship`;
    case vineaDetails.contactMethod:
      return `/ContactMethod`;
    case vineaDetails.person:
      return `/IdentityPerson`;
    case vineaDetails.addressContact:
      return `/IdentityContactMethodAddress`;
    case vineaDetails.vineyard:
      return `/IdentityVineyard`;
    case vineaDetails.serviceProvider:
      return `/IdentityServiceProvider`;
    case vineaDetails.job:
      return `/IdentityJob`;
    case vineaDetails.blocks:
      return `/IdentityVineyardBlock`;
    case vineaDetails.blockVintage:
      return `/BlockVintage`;
    case vineaDetails.blockRows:
      return `/IdentityVineyardBlockRow`;
    case vineaDetails.note:
      return `/IdentityNote`;
    case vineaDetails.samplesSet:
      return `/SampleSet`;
    case vineaDetails.postSampleHeaderBlocks:
      return `/SampleHeader`;
    case vineaDetails.dataConnection:
      return `/IdentityDataConnector`;
    case vineaDetails.consumable:
      return `/IdentityConsumable`;
    case vineaDetails.accommodation:
      return `/IdentityAccommodation`;
    case vineaDetails.user:
      return `/user`;
    case vineaDetails.activity:
      return `/IdentityActivity`;
    case vineaDetails.plans:
      return `/Plans`;
    case vineaDetails.supplyContract:
      return `/IdentitySupplyContract`;
    case vineaDetails.job:
      return `/IdentityJob`;
    case vineaDetails.vehicle:
      return `/IdentityVehicle`;
    default:
      return '';
  }
};

const generateUpdateAPIUrl = name => {
  switch (name) {
    case vineaDetails.classification:
      return `/IdentityClassification?code=wGF5LnDkPMDgqavpGR/SSAHDKKTrIXRn5NvmSmhWcDJzfL7rcUxocg==`;
    case vineaDetails.reference:
      return `/IdentityReference?code=tVPQNqL9HuQDb0xgRJ1UbIdGJsZKCmGRzUAxbvHTEvYs0wBWv4ee8w==`;
    case vineaDetails.person:
      return `/IdentityPerson?code=Ebh40jsI3tQxXpQ0lp/RfT97YY8AfCrozFzZhDWYGc1kNuobkV/sZg==`;
    case vineaDetails.blocks:
      return `/IdentityVineyardBlock`;
    case vineaDetails.postSampleHeaderBlocks:
      return `/SamplesHeader`;
    case vineaDetails.job:
      return `/IdentityJob`;
    default:
      return '';
  }
};

export const commonFetchIdentity = async (payload, reducerName) => {
  const apiUrl = generateFetchAPIUrl(payload, reducerName);
  try {
    const response = await serviceConfig.request(
      {
        url: apiUrl,
        method: 'GET',
        body: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
      'others',
    );
    return mapResultsToActive(response);
  } catch (err) {
    logger.error(err);
    throw err;
  }
};

export const searchUsers = async (payload = {}) => {
  try {
    const { searchName, searchEmail, searchIsActive, searchIsAdmin } = payload;

    const response = await serviceConfig.request(
      {
        url: `/searchuser`,
        method: 'POST',
        data: JSON.stringify({
          searchName,
          searchEmail,
          searchIsActive,
          searchIsAdmin,
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
      'login',
    );
    return response;
  } catch (err) {
    logger.error(err);
    throw err;
  }
};

export const searchPlans = async (payload = {}) => {
  try {
    const {
      planName,
      planDescription,
      planTypeID,
      businessUnitID,
      vintageID,
      activeOnly,
    } = payload;

    const response = await serviceConfig.request({
      url: `/SearchPlans`,
      method: 'POST',
      data: JSON.stringify({
        planName,
        planDescription,
        planTypeID,
        businessUnitID,
        planVintageID: vintageID,
        activeOnly,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response;
  } catch (err) {
    logger.error(err);
    throw err;
  }
};

export const searchSupplyContracts = async (payload = {}) => {
  try {
    const {
      contractName,
      supplyingPartyIdentityID,
      purchasingPartyIdentityID,
      supplyContractTypeID,
    } = payload;

    const response = await serviceConfig.request({
      url: `/SearchSupplyContracts`,
      method: 'POST',
      data: JSON.stringify({
        contractName,
        supplyingPartyIdentityID,
        purchasingPartyIdentityID,
        supplyContractTypeID,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response;
  } catch (err) {
    logger.error(err);
    throw err;
  }
};

export const searchIdentity = async (payload = {}) => {
  try {
    const {
      IdentityTypeID,
      ReferenceTypeID,
      RefValue,
      ClassificationTypeID,
      Name,
      FindNonActive,
      RoleTypeID,
      BusinessUnitId,
    } = payload;
    const response = await serviceConfig.request({
      url: `/SearchIdentity`,
      method: 'POST',
      data: JSON.stringify({
        // dummy json body
        IdentityTypeID,
        RoleTypeID,
        Name,
        ClassificationTypeID,
        ReferenceTypeID,
        RefValue,
        FindNonActive,
        BusinessUnitId,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response;
  } catch (err) {
    logger.error(err);
    throw err;
  }
};

/**
 * Function to search list of all samples in vinea
 * Triggered by /sampling/samples route
 * @param {samples} payload
 * @returns
 */
export const searchSamples = async (payload = {}) => {
  try {
    const { vintageID, regionID, measureID, createdBy } = payload;
    const response = await serviceConfig.request({
      url: `/SampleSets`,
      method: 'POST',
      data: JSON.stringify({
        // dummy json body
        vintageID,
        regionID,
        measureID,
        createdBy,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response;
  } catch (err) {
    logger.error(err);
    throw err;
  }
};

export const commonAddIdentity = async payload => {
  try {
    const { data, name, methodType } = payload;
    const apiUrl = generateAddOrUpdateAPIUrl(name);
    if (name === vineaDetails.user) {
      const response = await serviceConfig.request(
        {
          url: apiUrl,
          method: methodType,
          data: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
        'login',
      );
      return response;
    }
    const response = await serviceConfig.request({
      url: apiUrl,
      method: methodType,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response;
  } catch (err) {
    logger.error(err);
    throw err;
  }
};

export const commonUpdateIdentity = async payload => {
  try {
    const { data, name } = payload;
    const apiUrl = generateUpdateAPIUrl(name);
    const response = await serviceConfig.request({
      url: apiUrl,
      method: 'PUT',
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response;
  } catch (err) {
    logger.error(err);
    throw err;
  }
};

export const fetchIdentityTypeService = async (payload, reducerName) => {
  try {
    const kName = 'IDENTITY_RELATIONSHIP_TYPE';
    const { identityTypeId = null } = payload;
    let response = null;
    if (kName === reducerName) {
      response = await serviceConfig.request(
        {
          url: '/IdentityRelationshipType',
          method: 'GET',
          body: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          params: {
            IdentityTypeID: identityTypeId,
          },
        },
        'others',
      );
    } else {
      const apiUrl = generateFetchAPIUrl(payload, reducerName);
      response = await serviceConfig.request(
        {
          url: apiUrl,
          method: 'GET',
          body: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
        'others',
      );
    }
    return mapResultsToActive(response);
  } catch (err) {
    logger.error(err);
    throw err;
  }
};

const mapResultsToActive = data => {
  if (!data) return data;
  if (!Array.isArray(data)) return data;
  if (data.length < 1) return data;

  const formattedData = data.map(f => {
    const isActive = getIsActive(f.effectiveFromDate, f.effectiveToDate);

    return {
      ...f,
      isActive,
      methodType: apiTypes.PUT,
    };
  });
  return formattedData;
};

export const getAddressDetails = async payload => {
  try {
    const { id } = payload;
    const apiUrl = `/IdentityContactMethodAddress?ID=${id}`;
    const response = await serviceConfig.request({
      url: apiUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response;
  } catch (err) {
    logger.error(err);
    throw err;
  }
};

export const createDocument = async payload => {
  try {
    const { file, params } = payload;

    const queryParams = new URLSearchParams(params).toString();
    const apiUrl = `/LinkedDocument?${queryParams}`;

    // eslint-disable-next-line no-undef
    const formUploadData = new FormData();
    formUploadData.append('document', file);

    const response = await serviceConfig.request({
      url: apiUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formUploadData,
    });
    return response;
  } catch (err) {
    logger.error(err);
    throw err;
  }
};
