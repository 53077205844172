import React from 'react';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

export const useQuickSearchIdentityHoook = () => {
  const dispatchAPI = useDispatch();
  const userSettings = useSelector(
    state => state.userAccess.data?.userSettings,
  );

  const businessUnitId = get(
    userSettings,
    'basicSettings.businessUnitID',
    null,
  );

  /// use selector get user history
  const quickSearchData = useSelector(
    VineaNovaSelectors.getQuickSearchEntityData,
  );

  const fetchQuickSearchIdentity = React.useCallback(
    searchText => {
      dispatchAPI(
        VineaNovaActions.api.v1.quickSearch.post.request({
          postBody: {
            Name: searchText,
            BusinessUnitID: businessUnitId,
            FindNonActive: false,
          },
        }),
      );
    },
    [dispatchAPI, businessUnitId],
  );

  return {
    quickSearchData,
    fetchQuickSearchIdentity,
  };
};
