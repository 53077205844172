import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Box, useTheme } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PropTypes from 'prop-types';

import { formatAsViewDateFormat } from '../../../constants/formatter';
import { RenderDataGridHeader } from '../../../components/Grid';

const NameWithNewChip = ({ name, isNew }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <Box pr={2}>{name}</Box>
      {isNew && (
        <Chip
          sx={{
            '.MuiChip-label': {
              color: theme.palette.black,
            },
            fontSize: '12px',
            '& .MuiChip-icon': {
              color: theme.palette.info.main,
            },
          }}
          icon={<FiberManualRecordIcon />}
          label={t('New')}
          variant="outlined"
          size="small"
        />
      )}
    </Box>
  );
};

NameWithNewChip.propTypes = {
  name: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
};

export const sitesGridColumn = () => [
  {
    field: 'id',
    headerName: 'Site ID',
    width: 75,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'friendlySiteName',
    headerName: 'Name',
    flex: 1,
    hideable: false,
    resizable: false,
    renderHeader: RenderDataGridHeader,
    renderCell: params => {
      const {
        value: name,
        row: { isNew },
      } = params;
      return <NameWithNewChip name={name} isNew={isNew} />;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'location',
    headerName: 'Location',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'vineyard',
    headerName: 'Primary Vineyard',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    flex: 1,
    renderHeader: RenderDataGridHeader,
    valueFormatter: param => formatAsViewDateFormat(param.value),
  },
  {
    field: 'lastUpdatedDate',
    headerName: 'Last Update',
    flex: 1,
    renderHeader: RenderDataGridHeader,
    valueFormatter: param => formatAsViewDateFormat(param.value),
  },
];

export const sensorsGridColumn = () => [
  {
    field: 'id',
    headerName: 'Sensor ID',
    width: 75,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'friendlySensorName',
    headerName: 'Name',
    flex: 1,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    renderCell: params => {
      const {
        value: name,
        row: { sensorStatusID },
      } = params;
      return <NameWithNewChip name={name} isNew={sensorStatusID === 3} />;
    },
  },
  {
    field: 'siteName',
    headerName: 'Site',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'sensorStatus',
    headerName: 'Status',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'sensorType',
    headerName: 'Type',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'sensorMeasureUnit',
    headerName: 'Units',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    flex: 1,
    renderHeader: RenderDataGridHeader,
    valueFormatter: param => formatAsViewDateFormat(param.value),
  },
  {
    field: 'lastUpdatedDate',
    headerName: 'Last Update',
    flex: 1,
    renderHeader: RenderDataGridHeader,
    valueFormatter: param => formatAsViewDateFormat(param.value),
  },
  {
    field: 'blocks',
    headerName: 'Blocks',
    flex: 2,
    renderHeader: RenderDataGridHeader,
  },
];
