import React from 'react';
import { format } from 'date-fns';
// @ts-ignore
import shortid from 'shortid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box, Grid, Paper } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { VineaButton } from '../../../components/VineaButton';
import { GridWithFilter, GridNoRowMessage } from '../../../components/Grid';
import { dateFormat, actionTypes, reducers } from '../../../constants';
import { syncValidator } from '../../../utils/validator';
import { AttachedDocumentSchema } from '../validation';
import { dataGridAttachedDocumentsColumn } from '../../Tracking/gridColumns';
import { DocumentViewer } from './DocumentViewer';
import { DocumentsDrawer } from './DocumentsDrawer';
import { useDocumentsHook } from './useDocumentsHook';

export type DocumentDataType = {
  id: number | string;
  date: string | null;
  subject: string | null;
  statusId: number;
  status: string | null;
  filename: string | null;
  typeId: number | null;
  type: string | null;
  loadedDate: string | null;
  loadedByUser: string | null;
  file: File | null;
  base64: string | null;
  errors: any;
};

type DocumentsGridProps = {
  isNewNote: boolean;
};

const newDocumentData: DocumentDataType = {
  id: 0,
  date: format(new Date(), dateFormat),
  subject: null,
  statusId: 1,
  status: null,
  filename: null,
  loadedDate: null,
  loadedByUser: null,
  typeId: null,
  type: null,
  file: null,
  base64: null,
  errors: {},
};

export function DocumentsGrid({ isNewNote }: DocumentsGridProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<DocumentDataType>({
    ...newDocumentData,
  });
  const [isDocViewerOpen, setIsDocViewerOpen] = React.useState(false);
  const [currentDocument, setCurrentDocument] = React.useState<any>(null);
  const [hasSelectedDocument, setHasSelectedDocument] = React.useState(false);
  const [hasBase64, setHasBase64] = React.useState<any | null>(null);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const { data: { documentsToSave = [] } = {} } =
    // @ts-ignore
    useSelector(state => state.newNoteDocuments) || {};

  // // if the note doesn't exist yet, store the rows to be saved when it is created
  // const [rowsToSave, setRowsToSave] = React.useState<DocumentDataType[]>([]);

  const {
    handleOnGetAllDocuments,
    handleOnGetDocument,
    handleOnUpdateDocument,
    handleOnUploadDocument,
    allDocumentsData,
    allDocumentsIsLoading,
    handleOnDownloadDocument,
    documentStatusOptions,
    lkpDocumentType,
    lkpDocumentStatus,
    acceptedFileExtensions,
    linkedDocumentObject,
  } = useDocumentsHook();

  const rows = isNewNote ? documentsToSave : allDocumentsData;

  const handleOnCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const NoRowsOverlayMessage = () => (
    <Box mt={10} data-testid="blockgrid-norowmessage">
      <GridNoRowMessage message="No Document Found." />
    </Box>
  );

  const handleOnPressDownload = (row: any = {}) => {
    if ((!isEdit || isNewNote) && selectedRow.base64 && selectedRow.filename) {
      // if DOWNLOAD pressed before document was uploaded, download from file in memory

      const { base64, filename } = row;

      // eslint-disable-next-line no-undef
      const downloadLink = document.createElement('a');
      downloadLink.href = base64 || selectedRow.base64;
      downloadLink.download = filename || selectedRow.filename;
      downloadLink.click();
    } else if ((hasSelectedDocument && selectedRow.id) || Number(row.id)) {
      // if document already uploaded, download via API
      handleOnDownloadDocument(row.id || selectedRow.id);
    }
  };

  const handleOnSaveDocument = () => {
    const errors = syncValidator(AttachedDocumentSchema)(selectedRow);
    setSelectedRow({ ...selectedRow, errors });

    if (isEmpty(errors)) {
      if (isEdit) {
        if (isNewNote) {
          const index = documentsToSave.findIndex(
            (r: any) => r.id === selectedRow.id,
          );
          if (index === -1) return;

          const newRows = [...documentsToSave];
          newRows[index] = selectedRow;

          dispatchAPI({
            type: actionTypes.updateData,
            name: reducers.newNoteDocuments,
            payload: { documentsToSave: newRows },
          });
        } else {
          handleOnUpdateDocument(selectedRow);
        }
      } else if (selectedRow.file) {
        if (isNewNote) {
          // @ts-ignore
          const status = lkpDocumentStatus.find(
            (e: any) => e.id === selectedRow.statusId,
          )?.documentStatus;
          const newRow = { ...selectedRow, id: shortid.generate(), status };

          dispatchAPI({
            type: actionTypes.updateData,
            name: reducers.newNoteDocuments,
            payload: { documentsToSave: [...documentsToSave, newRow] },
          });
        } else {
          handleOnUploadDocument(selectedRow);
        }
      }
      setDrawerOpen(false);
    }
  };

  const handleOnDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleOnRowClick = (row: any) => {
    setSelectedRow({ ...newDocumentData, ...row });

    setIsEdit(true);
    setHasSelectedDocument(true);
    setHasChanges(false);

    handleOnDrawerOpen();
  };

  const handleOnCellClick = (cellProps: any) => {
    const { row } = cellProps;

    if (cellProps.field === 'downloadButton') {
      handleOnPressDownload(row);
    } else if (cellProps.field === 'viewDocButton') {
      setIsDocViewerOpen(true);
      setCurrentDocument(row.id);
      if (row.base64) {
        setHasBase64({
          data: {
            documentObject: row.base64,
            sourceFile: row.filename,
          },
          isLoaded: true,
          isLoading: false,
          hasError: false,
        });
      } else {
        handleOnGetDocument(row.id);
      }
    } else {
      handleOnRowClick(row);
    }
  };

  const handleOnAddRow = () => {
    setIsEdit(false);
    setHasSelectedDocument(false);
    setHasChanges(false);

    const document = { ...newDocumentData };
    setSelectedRow(document);
    handleOnDrawerOpen();
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    setHasChanges(true);
    setSelectedRow({ ...selectedRow, [name]: value });
  };

  const handleOnSelectDocument = (file: File, base64: string) => {
    setHasChanges(true);
    setHasSelectedDocument(true);

    // @ts-ignore
    const documentType: any = lkpDocumentType.find((e: any) =>
      file.name.endsWith(`.${e.documentType}`),
    );

    const typeId = documentType?.id ?? null;
    const type = documentType?.documentTypeDescription ?? 'Unknown';

    const subject =
      selectedRow.subject && selectedRow.subject !== selectedRow.filename
        ? selectedRow.subject
        : file.name;

    const newRow = {
      ...selectedRow,
      base64,
      filename: file.name,
      subject,
      file,
      typeId,
      type,
    };

    const errors = syncValidator(AttachedDocumentSchema)(newRow);
    setSelectedRow({ ...newRow, errors });
  };

  React.useEffect(() => {
    if (!isNewNote) {
      handleOnGetAllDocuments();
    }

    return () => {
      dispatchAPI(VineaNovaActions.api.v1.linkedDocuments.get.cleardata());
      dispatchAPI({
        type: actionTypes.updateData,
        name: reducers.newNoteDocuments,
        payload: { documentsToSave: [] },
      });
    };
  }, []);

  console.log('document grid rows', rows);
  console.log('raw base64', hasBase64);

  return (
    <Paper
      elevation={3}
      sx={{
        marginBottom: theme.spacing(1),
      }}
    >
      <Grid container xs={12} direction="column">
        <Grid item>
          <Box
            sx={{
              paddingRight: theme.spacing(2),
              paddingLeft: theme.spacing(2),
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2">{t('Linked Documents')}</Typography>
            <VineaButton
              color="secondary"
              variant="text"
              onClick={handleOnAddRow}
            >
              {t('Upload Document')}
            </VineaButton>
          </Box>
        </Grid>
        <Grid item style={{ width: '850px', height: '350px' }}>
          <GridWithFilter
            data={{ columns: dataGridAttachedDocumentsColumn, rows }}
            onRowClick={() => {}}
            actionType=""
            gridColumnState={{}}
            noRowsOverlay={NoRowsOverlayMessage}
            onCellClick={handleOnCellClick}
            rowHeight={30}
            hideFooterSelectedRowCount
            autoPageSize={false}
            loading={allDocumentsIsLoading}
            className=""
            getRowClassName={(params: any) => {
              return `vinea-xgrid--${params.getValue(params.id, 'isEditable')}`;
            }}
            handleOnAdvancedSearch={{}}
            advancedSearch={false}
            selectedFilterValuesText={[]}
            hasButton={undefined}
            onButtonClick={undefined}
            buttonText={undefined}
            initialState={undefined}
          />
        </Grid>
      </Grid>
      <DocumentsDrawer
        isOpen={drawerOpen}
        data={selectedRow}
        handleOnSave={handleOnSaveDocument}
        handleOnClose={handleOnCloseDrawer}
        handleOnChange={handleOnChange}
        handleOnSelectDocument={handleOnSelectDocument}
        handleOnPressDownload={handleOnPressDownload}
        hasChanges={hasChanges}
        hasSelectedDocument={hasSelectedDocument}
        isEdit={isEdit}
        documentStatusOptions={documentStatusOptions}
        acceptedFileExtensions={acceptedFileExtensions}
      />
      <DocumentViewer
        open={isDocViewerOpen}
        handleClose={() => setIsDocViewerOpen(false)}
        documentObject={isNewNote ? hasBase64 : linkedDocumentObject}
        documentId={currentDocument}
        isNewNote={isNewNote}
      />
    </Paper>
  );
}
