import React, {
  useEffect,
  useState,
  useReducer,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, isEmpty } from 'lodash';
import Stepper from '@mui/material/Stepper';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useLocation } from 'react-router-dom';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import CreateContactDetails from '../Create/CreateContactDetails';
import CreateNewClassification from '../Create/CreateNewClassification';
import CreateNewReference from '../Create/CreateNewReference';
import CreateNewPerson from '../../PersonIdentity/Create/CreateNewPerson';
import CreateSupplyContract from '../../SupplyContracts/CreateSupplyContract';
import { stepsReducer, initialState, stepTypes } from './stepsReducer';
import { VineaButton } from '../../../components/VineaButton';
import {
  sagaActionTypes,
  actionTypes,
  reducers,
  IdentityTypeIds,
} from '../../../constants';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { CreateVineyard } from '../Create';
import CreateServiceProvider from '../../ServieProviderIdentity/Create/CreateServiceProvider';
import { ManageRelationships } from '../Manage';
import CreateNewConnection from '../../DataConnectionIdentity/Create/CreateConnections';
import CreateOrganisation from '../../Organisation/Create/CreateNewOrganisation';
import CreateNewRoles from '../Create/CreateNewRoles';
import CreateNewConsumable from '../Create/CreateNewConsumable';
import CreateNewAccommodation from '../Create/CreateNewAccommodation';
import CreateNewActivity from '../Create/CreateNewActivity';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';
import CreateJobIdentity from '../../Jobs/CreateJobIdentity';
import CreateJobVineyard from '../../Jobs/CreateJobVineyard';
import CreateJobActivities from '../../Jobs/CreateJobActivities';
import CreateJobSupervisors from '../../Jobs/CreateJobSupervisors';
import CreateNewVehicle from '../Create/CreateNewVehicle';
import useFindDuplicateIdentitiesHook from '../../../hooks/useFindDuplicateIdentitiesHook';
import { ConfirmSaveDialog } from '../../../components/Dialog/ConfirmSaveDialog';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

const StepperCreateIdentity = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isWorker = pathname.includes('worker');
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const [identityId, setIdentityId] = useState(0);
  const [stepsData, dispatch] = useReducer(stepsReducer, initialState);
  const [activeStep, setActiveStep] = useState(0);
  const [isFormSaving, setIsFormSaving] = useState(false);
  const [canProceedNext, setCanProceedNext] = useState(true);
  const [identityNameToCheck, setIdentityNameToCheck] = useState('');
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();

  const [confirmCreateIdentityDialogOpen, setConfirmCreateIdentityDialogOpen] =
    useState(false);
  const identityType = useIdentityTypeId(vineyardTypeScreenName);
  const wizardRef = useRef();
  const identitySteps = {
    1: isWorker
      ? [
          'Identity',
          'Contact Details',
          'References',
          'Classifications',
          'Relationships',
        ]
      : [
          'Identity',
          'Contact Details',
          'References',
          'Classifications',
          'Relationships',
          'Roles',
        ],
    2: [
      'Identity',
      'Contact Details',
      'References',
      'Classifications',
      'Relationships',
      'Roles',
    ],
    3: [
      'Identity',
      'Contact Details',
      'References',
      'Classifications',
      'Relationships',
    ],
    4: [
      'Identity',
      'Contact Details',
      'References',
      'Classifications',
      'Relationships',
    ],
    5: [
      'Identity',
      `${capitalize(vineyardTypeScreenName)}`,
      'Activities',
      'Supervisor',
    ],
    8: ['Identity', 'Contact Details', 'Relationships'],
    20: [
      'Identity',
      'Contact Details',
      'References',
      'Classifications',
      'Relationships',
    ],
    21: [
      'Identity',
      'Contact Details',
      'References',
      'Classifications',
      'Relationships',
    ],
    23: ['Identity', 'References', 'Classifications', 'Relationships'],
    25: ['Identity', 'References'],
    26: [
      'Identity',
      'Contact Details',
      'References',
      'Classifications',
      'Relationships',
    ],
  };

  const steps = identitySteps[identityType];
  const identitytDetailsData = useSelector(state => state.identityDetails).data;

  const { matchingIdentities } = useFindDuplicateIdentitiesHook(
    identityType,
    identityNameToCheck,
    5,
  );

  /** Callback Handles */

  const calculateNameToCheck = useCallback(formdata => {
    if (identityType === IdentityTypeIds.PERSON) {
      setIdentityNameToCheck(
        `${get(formdata, 'firstName', '')} ${get(formdata, 'lastName', '')}`,
      );
    } else if (identityType === IdentityTypeIds.VINEYARD) {
      setIdentityNameToCheck(get(formdata, 'vineyardName', ''));
    } else if (identityType === IdentityTypeIds.SERVICE_PROVIDER) {
      setIdentityNameToCheck(get(formdata, 'serviceProviderName', ''));
    } else if (identityType === IdentityTypeIds.ORGANISATION) {
      setIdentityNameToCheck(get(formdata, 'organisationName', ''));
    } else if (identityType === IdentityTypeIds.ACTIVITY) {
      setIdentityNameToCheck(get(formdata, 'activityCode', ''));
    } else if (identityType === IdentityTypeIds.DATA_CONNECTION) {
      setIdentityNameToCheck(get(formdata, 'connectionDescription', ''));
    } else if (identityType === IdentityTypeIds.ACCOMMODATION) {
      setIdentityNameToCheck(get(formdata, 'accommodationName', ''));
    } else if (identityType === IdentityTypeIds.CONSUMABLE) {
      setIdentityNameToCheck(get(formdata, 'consumableName', ''));
    } else if (identityType === IdentityTypeIds.VEHICLE) {
      setIdentityNameToCheck(get(formdata, 'vehicleName', ''));
    } else if (identityType === IdentityTypeIds.SUPPLY_CONTRACT) {
      setIdentityNameToCheck(get(formdata, 'contractName', ''));
    } else if (identityType === IdentityTypeIds.JOB) {
      setIdentityNameToCheck(get(formdata, 'jobName', ''));
    }
  }, []);

  const onCancel = () => {
    if (isEmpty(identitytDetailsData)) {
      if (identityType === IdentityTypeIds.PERSON && !isWorker)
        navigate('/person');
      else if (identityType === IdentityTypeIds.PERSON && isWorker)
        navigate('/worker');
      else if (identityType === IdentityTypeIds.VINEYARD)
        navigate(`/organisation/${vineyardTypeScreenName}s`);
      else if (identityType === IdentityTypeIds.SERVICE_PROVIDER)
        navigate('/organisation/serviceproviders');
      else if (identityType === IdentityTypeIds.JOB) navigate('/job');
      else if (identityType === IdentityTypeIds.DATA_CONNECTION)
        navigate('/connections');
      else if (identityType === IdentityTypeIds.CONSUMABLE)
        navigate('/consumable');
      else if (identityType === IdentityTypeIds.ACCOMMODATION)
        navigate('/accommodation');
      else if (identityType === IdentityTypeIds.ORGANISATION)
        navigate('/organisation/other-organisations');
      else if (identityType === IdentityTypeIds.ACTIVITY) navigate('/activity');
      else if (identityType === IdentityTypeIds.SUPPLY_CONTRACT)
        navigate('/supplycontract');
      else if (identityType === IdentityTypeIds.VEHICLE) navigate('/vehicle');
    } else {
      const { id = null } = identitytDetailsData;
      if (identityType === IdentityTypeIds.PERSON && !isWorker)
        navigate(`/person/${id}`);
      else if (identityType === IdentityTypeIds.PERSON && isWorker)
        navigate(`/worker/${id}`);
      else if (identityType === IdentityTypeIds.VINEYARD)
        navigate(`/organisation/${vineyardTypeScreenName}s/${id}`);
      else if (identityType === IdentityTypeIds.SERVICE_PROVIDER)
        navigate(`/organisation/serviceproviders/${id}`);
      else if (identityType === IdentityTypeIds.JOB) navigate(`/job/${id}`);
      else if (identityType === IdentityTypeIds.DATA_CONNECTION)
        navigate(`/connections/${id}`);
      else if (identityType === IdentityTypeIds.CONSUMABLE)
        navigate(`/consumables/${id}`);
      else if (identityType === IdentityTypeIds.ACCOMMODATION)
        navigate(`/accommodation/${id}`);
      else if (identityType === IdentityTypeIds.ORGANISATION)
        navigate(`/organisation/other-organisations/${id}`);
      else if (identityType === IdentityTypeIds.ACTIVITY)
        navigate(`/activity/${id}`);
      else if (identityType === IdentityTypeIds.SUPPLY_CONTRACT)
        navigate(`/supplycontract/${id}`);
      else if (identityType === IdentityTypeIds.VEHICLE)
        navigate(`/vehicle/${id}`);
    }
  };

  const handleNext = () => {
    dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
    if (activeStep !== steps.length - 1) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    if (activeStep === steps.length - 1) {
      handleOnDone();
    }
  };

  const handleSave = () => {
    if (isEmpty(matchingIdentities) || confirmCreateIdentityDialogOpen) {
      setConfirmCreateIdentityDialogOpen(false);
      wizardRef.current.saveForm();
    } else if (!isFormSaving) {
      setConfirmCreateIdentityDialogOpen(true);
    }
  };

  const handleOnSaveData = (newData, step) => {
    if (newData.isComplete) {
      dispatch({
        type: stepTypes.UPDATE,
        payload: newData,
        step,
      });
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleOnDone = () => {
    if (pathname.includes(vineyardTypeScreenName) && identityId > 0)
      navigate(`/organisation/${vineyardTypeScreenName}s/${identityId}`);
    if (pathname.includes('person') && identityId > 0)
      navigate(`/person/${identityId}`);
    if (pathname.includes('worker') && identityId > 0)
      navigate(`/worker/${identityId}`);
    if (pathname.includes('serviceproviders') && identityId > 0)
      navigate(`/organisation/serviceproviders/${identityId}`);
    if (pathname.includes('job') && identityId > 0)
      navigate(`/job/${identityId}`);
    if (pathname.includes('connections') && identityId > 0)
      navigate(`/connections/${identityId}`);
    if (pathname.includes('other-organisations') && identityId > 0)
      navigate(`/organisation/other-organisations/${identityId}`);
    if (pathname.includes('consumable') && identityId > 0)
      navigate(`/consumable/${identityId}`);
    if (pathname.includes('accommodation') && identityId > 0)
      navigate(`/accommodation/${identityId}`);
    if (pathname.includes('activity') && identityId > 0)
      navigate(`/activity/${identityId}`);
    if (pathname.includes('supplycontract') && identityId > 0)
      navigate(`/supplycontract/${identityId}`);
    if (pathname.includes('vehicle') && identityId > 0)
      navigate(`/vehicle/${identityId}`);
  };

  /** Side Effects */
  useEffect(() => {
    // const { isSaving: isFormSavingMode } = stepsData;
    // setIsFormSaving(isFormSavingMode);
    if (activeStep === 0) {
      const idValue = get(stepsData.data, 'id', 0);
      setIdentityId(idValue);
    }
  }, [stepsData, activeStep]);

  useEffect(() => {
    dispatchAPI({ type: sagaActionTypes.CLEAR_IDENTITIES });
    dispatchAPI(VineaNovaActions.api.v1.identityRole.get.cleardata({}));
  }, [dispatchAPI]);

  const FallbackComponent = ({ resetErrorBoundary, ...restProps }) => {
    const originalStep = React.useRef(activeStep);

    React.useEffect(() => {
      if (activeStep !== originalStep.current) {
        resetErrorBoundary();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep]);

    return (
      <Box sx={{ height: '400px' }}>
        <ErrorBoundaryFallback
          resetErrorBoundary={resetErrorBoundary}
          {...restProps}
        />
      </Box>
    );
  };

  return (
    <div data-testid="div-createidentitystepper" style={{ margin: '12px' }}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: 'none',
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          px={2}
        >
          <IconButton
            aria-label="toggle password visibility"
            sx={{
              padding: 0,
            }}
            onClick={onCancel}
            color="primary"
          >
            <HighlightOffOutlinedIcon fontSize="large" />
          </IconButton>
        </Box>
      </Paper>
      <Stepper
        activeStep={activeStep}
        sx={{ backgroundColor: '#FFFFFF' }}
        alternativeLabel
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>
        <div>
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            {identityType === IdentityTypeIds.VINEYARD && (
              <div>
                {activeStep === 0 && (
                  <CreateVineyard
                    ref={wizardRef}
                    onSaveStepData={handleOnSaveData}
                    stepData={stepsData.data}
                    step={activeStep}
                    calculateNameToCheck={calculateNameToCheck}
                    matchingIdentities={matchingIdentities}
                    vineyardTypeScreenName={vineyardTypeScreenName}
                  />
                )}
              </div>
            )}
            {identityType === IdentityTypeIds.PERSON && (
              <div>
                {activeStep === 0 && (
                  <CreateNewPerson
                    ref={wizardRef}
                    onSaveStepData={handleOnSaveData}
                    stepData={stepsData.data}
                    step={activeStep}
                    isWorker={isWorker}
                    calculateNameToCheck={calculateNameToCheck}
                    matchingIdentities={matchingIdentities}
                  />
                )}
                {activeStep === 5 && !isWorker && (
                  <CreateNewRoles identityId={identityId} />
                )}
              </div>
            )}
            {identityType === IdentityTypeIds.ORGANISATION && (
              <div>
                {activeStep === 0 && (
                  <CreateOrganisation
                    ref={wizardRef}
                    onSaveStepData={handleOnSaveData}
                    stepData={stepsData.data}
                    step={activeStep}
                    calculateNameToCheck={calculateNameToCheck}
                    matchingIdentities={matchingIdentities}
                  />
                )}
                {activeStep === 5 && <CreateNewRoles identityId={identityId} />}
              </div>
            )}
            {identityType === IdentityTypeIds.SERVICE_PROVIDER && (
              <div>
                {activeStep === 0 && (
                  <CreateServiceProvider
                    ref={wizardRef}
                    onSaveStepData={handleOnSaveData}
                    stepData={stepsData.data}
                    step={activeStep}
                    calculateNameToCheck={calculateNameToCheck}
                    matchingIdentities={matchingIdentities}
                  />
                )}
              </div>
            )}
            {identityType === IdentityTypeIds.JOB && (
              <div>
                {activeStep === 0 && (
                  <CreateJobIdentity
                    ref={wizardRef}
                    onSaveStepData={handleOnSaveData}
                    stepData={stepsData.data}
                    step={activeStep}
                    calculateNameToCheck={calculateNameToCheck}
                    matchingIdentities={matchingIdentities}
                    setIsFormSaving={setIsFormSaving}
                  />
                )}
                {activeStep === 1 && (
                  <CreateJobVineyard
                    identityID={identityId}
                    setCanProceedNext={setCanProceedNext}
                  />
                )}
                {activeStep === 2 && (
                  <CreateJobActivities identityId={identityId} />
                )}
                {activeStep === 3 && (
                  <CreateJobSupervisors identityId={identityId} />
                )}
              </div>
            )}
            {identityType === IdentityTypeIds.DATA_CONNECTION && (
              <div>
                {activeStep === 0 && (
                  <CreateNewConnection
                    ref={wizardRef}
                    onSaveStepData={handleOnSaveData}
                    stepData={stepsData.data}
                    step={activeStep}
                    calculateNameToCheck={calculateNameToCheck}
                    matchingIdentities={matchingIdentities}
                  />
                )}
                {activeStep === 1 && (
                  <CreateContactDetails identityId={identityId} />
                )}
                {activeStep === 2 && (
                  <ManageRelationships identityId={identityId} />
                )}
              </div>
            )}
            {identityType === IdentityTypeIds.SUPPLY_CONTRACT && (
              <div>
                {activeStep === 0 && (
                  <CreateSupplyContract
                    ref={wizardRef}
                    onSaveStepData={handleOnSaveData}
                    stepData={stepsData.data}
                    step={activeStep}
                    calculateNameToCheck={calculateNameToCheck}
                    matchingIdentities={matchingIdentities}
                  />
                )}
              </div>
            )}
            {identityType === IdentityTypeIds.CONSUMABLE && (
              <div>
                {activeStep === 0 && (
                  <CreateNewConsumable
                    ref={wizardRef}
                    onSaveStepData={handleOnSaveData}
                    stepData={stepsData.data}
                    step={activeStep}
                    calculateNameToCheck={calculateNameToCheck}
                    matchingIdentities={matchingIdentities}
                  />
                )}
              </div>
            )}
            {identityType === IdentityTypeIds.CONSUMABLE && (
              <>
                {activeStep === 1 && (
                  <CreateNewReference identityId={identityId} />
                )}
                {activeStep === 2 && (
                  <CreateNewClassification identityId={identityId} />
                )}
                {activeStep === 3 && (
                  <ManageRelationships identityId={identityId} />
                )}
              </>
            )}
            {identityType === IdentityTypeIds.ACCOMMODATION && (
              <div>
                {activeStep === 0 && (
                  <CreateNewAccommodation
                    ref={wizardRef}
                    onSaveStepData={handleOnSaveData}
                    stepData={stepsData.data}
                    step={activeStep}
                    calculateNameToCheck={calculateNameToCheck}
                    matchingIdentities={matchingIdentities}
                  />
                )}
              </div>
            )}
            {identityType === IdentityTypeIds.ACTIVITY && (
              <div>
                {activeStep === 0 && (
                  <CreateNewActivity
                    ref={wizardRef}
                    onSaveStepData={handleOnSaveData}
                    stepData={stepsData.data}
                    step={activeStep}
                    calculateNameToCheck={calculateNameToCheck}
                    matchingIdentities={matchingIdentities}
                  />
                )}
              </div>
            )}
            {identityType === IdentityTypeIds.ACTIVITY && (
              <div>
                {activeStep === 1 && (
                  <CreateNewReference identityId={identityId} />
                )}
              </div>
            )}
            {identityType === IdentityTypeIds.VEHICLE && (
              <div>
                {activeStep === 0 && (
                  <CreateNewVehicle
                    ref={wizardRef}
                    onSaveStepData={handleOnSaveData}
                    stepData={stepsData.data}
                    step={activeStep}
                    calculateNameToCheck={calculateNameToCheck}
                    matchingIdentities={matchingIdentities}
                  />
                )}
              </div>
            )}
            {![
              IdentityTypeIds.DATA_CONNECTION,
              IdentityTypeIds.CONSUMABLE,
              IdentityTypeIds.ACTIVITY,
              IdentityTypeIds.JOB,
              IdentityTypeIds.VEHICLE,
            ].includes(identityType) && (
              <>
                {activeStep === 1 && (
                  <CreateContactDetails identityId={identityId} />
                )}
                {activeStep === 2 && (
                  <CreateNewReference identityId={identityId} />
                )}
                {activeStep === 3 && (
                  <CreateNewClassification identityId={identityId} />
                )}
                {activeStep === 4 && (
                  <ManageRelationships identityId={identityId} />
                )}
              </>
            )}
          </ErrorBoundary>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mt={2}
          >
            {activeStep === 0 ? (
              <div>
                <VineaButton
                  color="success"
                  onClick={handleSave}
                  variant="contained"
                  sx={{
                    marginRight: theme.spacing(1),
                  }}
                  minWidth={100}
                  disabled={identityId > 0}
                >
                  {!isFormSaving && <>{t('Save')}</>}
                  {isFormSaving && (
                    <>
                      <CircularProgress color="secondary" size={20} />
                      <span>{t('saving')}</span>
                    </>
                  )}
                </VineaButton>
              </div>
            ) : (
              <div></div>
            )}
            <div>
              <VineaButton
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{
                  marginRight: theme.spacing(1),
                }}
                color="secondary"
                variant="text"
              >
                {t('Back')}
              </VineaButton>
              {!(
                activeStep === steps.length - 1 && pathname.includes('job')
              ) && (
                <VineaButton
                  variant={
                    activeStep === steps.length - 1 ? 'contained' : 'outlined'
                  }
                  color="secondary"
                  onClick={handleNext}
                  disabled={identityId === 0 || !canProceedNext}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </VineaButton>
              )}
            </div>
          </Box>
        </div>
        <ConfirmSaveDialog
          open={confirmCreateIdentityDialogOpen}
          onClose={() => setConfirmCreateIdentityDialogOpen(false)}
          handleSaveChanges={handleSave}
          handleDiscardChanges={() => setConfirmCreateIdentityDialogOpen(false)}
        />
      </div>
    </div>
  );
};

export default StepperCreateIdentity;
