import React from 'react';
import { useFeatureFlag } from './useFeatureConfiguration';
import { FeatureInDevelopment } from '../components/FeatureInDevelopment';
import logger from '../utils/winstonLogger';

function withFeatureFlagHOC(featureName) {
  return Component => props => {
    const hasFeature = useFeatureFlag(featureName);
    logger.debug('HoCWithFeature', hasFeature);

    if (hasFeature === undefined) return null;
    if (!hasFeature) return <FeatureInDevelopment />;
    return <Component {...props} />;
  };
}
export default withFeatureFlagHOC;
