import dot from 'dot-object';

const validator = schema => async formValues => {
  try {
    await schema.validate(formValues, { abortEarly: false });
    return {};
  } catch (errors) {
    return errors.inner.reduce(
      (error, err) => ({
        ...error,
        [err.path]: err.message,
      }),
      {},
    );
  }
};

const syncValidator = schema => formValues => {
  try {
    schema.validateSync(formValues, { abortEarly: false });
    return {};
  } catch (errors) {
    const allErrors = errors.inner.reduce(
      (error, err) => ({
        ...error,
        [err.path]: err.message,
      }),
      {},
    );
    dot.object(allErrors);
    return allErrors;
  }
};

export { validator, syncValidator };
