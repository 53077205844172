/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled as muiStyled } from '@mui/material/styles';
import { filter } from 'lodash';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { CollapseHorizontal } from '../CollapseHorizontal';
import { commonLookupPropTypes } from '../../utils/commonPropTypes';
import {
  commonFieldSelectOption,
  IdentityTypeIds,
  FilterTypes,
  LicenseeTypeIDs,
} from '../../constants';
// eslint-disable-next-line import/no-unresolved
import { GreenSwitch } from '../Switch';
import { VineaAutoComplete } from '../ComboBox';
import { VineaButton } from '../VineaButton';
import { VineaTextField } from '../TextField';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { Filter } from '@mui/icons-material';

// import logger from '../../utils/winstonLogger';
interface LookupPropType {
  key: any;
  value: any;
  id: number;
}

type FilterScreenProps = {
  onFilterToggle: any;
  isFilterOpen: boolean;
  search: any;
  lkpReferences?: LookupPropType[];
  lkpClassificationsGroup?: LookupPropType[];
  lkpclassifications: LookupPropType[];
  lkpBusinessUnit: LookupPropType[];
  lkpRoleType: LookupPropType[];
  lkpJobStatus: LookupPropType[];
  lkpSiteType: LookupPropType[];
  identityTypeId: number;
  filterData: any;
  onUpdateFilterData: any;
  firstFilterInputRef: React.RefObject<HTMLInputElement>;
  nameMap: any;
  licenseeTypeID: number;
  defaultFilterValues: any;
};

const FlexBox = muiStyled(Box)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
}));

const FlexItemBox = muiStyled(Box)(() => ({
  flex: 1,
  '& > *': {
    flex: 1,
    display: 'flex',
  },
  '& > .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const FilterScreen = ({
  onFilterToggle,
  isFilterOpen,
  search,
  lkpReferences,
  lkpClassificationsGroup,
  identityTypeId,
  lkpclassifications,
  lkpBusinessUnit,
  lkpJobStatus,
  lkpSiteType,
  filterData,
  onUpdateFilterData,
  lkpRoleType,
  firstFilterInputRef,
  nameMap,
  licenseeTypeID,
  defaultFilterValues,
}: FilterScreenProps) => {
  const { t } = useTranslation();
  const [classificationsNames, setClassificationsNames] = React.useState(
    commonFieldSelectOption,
  );

  const {
    referenceTypeID = 0,
    classificationGroupID = 0,
    classificationTypeID = 0,
    businessUnitID = 0,
    name = '',
    refValue = '',
    findNonActive = false,
    roleTypeID = 0,
    activityCode = '',
    jobName = '',
    jobID = 0,
    statusID = 0,
    siteTypeID = 0,
  } = filterData || {};

  useEffect(() => {
    const classificationTypeOptions = commonFieldSelectOption.concat(
      filter(
        lkpclassifications,
        // @ts-ignore
        cls => cls.classificationGroupID === classificationGroupID,
      ).map(lkpCls => {
        return {
          id: lkpCls.id,
          key: lkpCls.id,
          // @ts-ignore
          value: lkpCls.classification,
        };
      }),
    );
    setClassificationsNames(classificationTypeOptions);
  }, [classificationGroupID, lkpClassificationsGroup, lkpclassifications]);

  const handleOnRefValue = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const evtValue = evt.target.value;
    onUpdateFilterData({ ...filterData, refValue: evtValue });
  };

  const handleChangeInActive = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const evtValue = evt.target.checked;
    onUpdateFilterData({ ...filterData, findNonActive: !evtValue });
  };

  const handleChangeValue = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = evt;

    onUpdateFilterData({ ...filterData, [name]: value });
  };

  const identityHasFilter = (idenityTypeID: number, filterType: string) => {
    if (
      [
        IdentityTypeIds.PERSON,
        IdentityTypeIds.ORGANISATION,
        IdentityTypeIds.VINEYARD,
        IdentityTypeIds.SERVICE_PROVIDER,
        IdentityTypeIds.ACCOMMODATION,
        IdentityTypeIds.CONSUMABLE,
        IdentityTypeIds.VEHICLE,
      ].includes(idenityTypeID)
    ) {
      if (
        filterType === FilterTypes.BUSINESS_UNIT ||
        filterType === FilterTypes.NAME ||
        filterType === FilterTypes.REFERENCE_TYPE ||
        filterType === FilterTypes.REFERENCE_VALUE ||
        filterType === FilterTypes.CLASSIFICATION_GROUP ||
        filterType === FilterTypes.CLASSIFICATION_NAME ||
        (filterType === FilterTypes.SITE_TYPE &&
          idenityTypeID === IdentityTypeIds.VINEYARD &&
          licenseeTypeID === LicenseeTypeIDs.CONTRACTOR_VINEYARDS_AND_ORCHARDS)
      )
        return true;
    } else if (idenityTypeID === IdentityTypeIds.DATA_CONNECTION) {
      if (
        filterType === FilterTypes.BUSINESS_UNIT ||
        filterType === FilterTypes.NAME
      )
        return true;
    } else if (idenityTypeID === IdentityTypeIds.ACTIVITY) {
      if (
        filterType === FilterTypes.NAME ||
        filterType === FilterTypes.REFERENCE_TYPE ||
        filterType === FilterTypes.REFERENCE_VALUE
      )
        return true;
    } else if (idenityTypeID === IdentityTypeIds.JOB) {
      if (
        filterType === FilterTypes.BUSINESS_UNIT ||
        (filterType === FilterTypes.SITE_TYPE &&
          licenseeTypeID ===
            LicenseeTypeIDs.CONTRACTOR_VINEYARDS_AND_ORCHARDS) ||
        filterType === FilterTypes.JOB_NAME ||
        filterType === FilterTypes.JOB_ID ||
        filterType === FilterTypes.STATUS
      )
        return true;
    }
    return false;
  };

  const handleOnSearch = () => {
    const data = {
      name,
      referenceTypeID,
      refValue,
      classificationTypeID,
      businessUnitID,
      findNonActive,
      roleTypeID,
      activityCode,
      jobName,
      jobID,
      statusID,
      siteTypeID,
    };

    search(data);
  };

  const handleOnClearSearch = () => {
    onUpdateFilterData(defaultFilterValues);
    search(defaultFilterValues);
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{
        paddingBottom: 3,
        minHeight: '100%',
        '& .MuiCollapse-horizontal': {
          height: '100%',
        },
        '& .MuiCollapse-wrapper': {
          flex: 1,
        },
        '& .MuiInputLabel-outlined': {
          zIndex: 0,
        },
        '& .MuiCollapse-wrapperInner': {
          width: '100%',
        },
      }}
      data-testid="filter-screen"
    >
      <Grid item xs={12} style={{ height: '100%' }}>
        <Paper
          elevation={2}
          data-testid="filter-collapse-paper"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            '& .MuiCollapse-horizontal': {
              height: '100%',
              width: '100px',
            },
            '& .MuiCollapse-wrapper': {
              flex: 1,
            },
            '& .MuiInputLabel-outlined': {
              zIndex: 0,
            },
            '& .MuiCollapse-wrapperInner': {
              width: '100%',
            },
          }}
        >
          {!isFilterOpen && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                flex: 1,
                '&  .MuiIconButton-root': {
                  flex: 1,
                },
              }}
            >
              <Box
                flex={1}
                flexDirection="row"
                justifyContent="flex-start"
                display="flex"
              >
                <div />
                <div>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      onFilterToggle();
                    }}
                    onMouseDown={() => {}}
                    size="large"
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </div>
              </Box>
            </Box>
          )}
          <>
            <CollapseHorizontal
              orientation="horizontal"
              collapsedSize={48}
              in={isFilterOpen}
              data-testid="collapse-horizontal"
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
              className={''}
              timeout={0}
              onEnter={undefined}
              onEntering={undefined}
              onEntered={undefined}
              onExit={undefined}
              onExiting={undefined}
              onExited={undefined}
              component={undefined}
              TransitionComponent={undefined}
            >
              <Box
                display="flex"
                data-testid="collapse-box"
                flex={1}
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
                sx={{ height: '100%' }}
              >
                {!!isFilterOpen && (
                  <Box
                    display="flex"
                    data-testid="collapse-show-filter-box"
                    flex={1}
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{ height: '100%' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      <Box
                        pl={2}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          noWrap
                          data-testid="header-text"
                        >
                          {t('Filters')}
                        </Typography>

                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            onFilterToggle();
                          }}
                          onMouseDown={() => {}}
                          size="large"
                        >
                          <ChevronLeftIcon />
                        </IconButton>
                      </Box>
                      {/* @ts-ignore */}
                      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                        <Box sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
                          {identityHasFilter(
                            identityTypeId,
                            FilterTypes.BUSINESS_UNIT,
                          ) && (
                            <FlexItemBox
                              py={1}
                              px={2}
                              data-testid="businessunit"
                            >
                              <VineaAutoComplete
                                value={businessUnitID}
                                onChange={handleChangeValue}
                                inlineLabel
                                options={lkpBusinessUnit}
                                labelVariant="body1"
                                label={t(nameMap.businessUnitID.name)}
                                name={t(nameMap.businessUnitID.name)}
                                inputProps={{
                                  name: 'businessUnitID',
                                }}
                                fullWidth={false}
                              />
                            </FlexItemBox>
                          )}{' '}
                          {identityHasFilter(
                            identityTypeId,
                            FilterTypes.SITE_TYPE,
                          ) && (
                            <FlexItemBox py={1} px={2} data-testid="siteType">
                              <VineaAutoComplete
                                value={siteTypeID}
                                onChange={handleChangeValue}
                                inlineLabel
                                options={lkpSiteType}
                                labelVariant="body1"
                                label={t(nameMap.siteTypeID.name)}
                                name={'siteTypeID'}
                                inputProps={{
                                  name: 'siteTypeID',
                                }}
                                fullWidth={false}
                              />
                            </FlexItemBox>
                          )}
                          {identityHasFilter(
                            identityTypeId,
                            FilterTypes.NAME,
                          ) && (
                            <FlexItemBox
                              py={2}
                              px={2}
                              data-testid="searchvineyards"
                            >
                              <FormControl variant="outlined" margin="dense">
                                <VineaTextField
                                  id="outlined-search-id-name"
                                  name={'name'}
                                  label={
                                    identityTypeId === IdentityTypeIds.ACTIVITY
                                      ? t(nameMap.activityCode.name)
                                      : t(nameMap.name.name)
                                  }
                                  type="search"
                                  variant="outlined"
                                  size="small"
                                  value={
                                    identityTypeId === IdentityTypeIds.ACTIVITY
                                      ? activityCode
                                      : name
                                  }
                                  onChange={handleChangeValue}
                                  inputRef={firstFilterInputRef}
                                />
                              </FormControl>
                            </FlexItemBox>
                          )}
                          {identityHasFilter(
                            identityTypeId,
                            FilterTypes.ROLE,
                          ) && (
                            <FlexItemBox px={2} data-testid="searchvineyards">
                              <FormControl margin="dense">
                                <VineaAutoComplete
                                  value={roleTypeID}
                                  onChange={handleChangeValue}
                                  inlineLabel
                                  options={lkpRoleType}
                                  labelVariant="body1"
                                  label={t(nameMap.roleTypeID.name)}
                                  name={t(nameMap.roleTypeID.name)}
                                  inputProps={{
                                    name: 'roleTypeID',
                                  }}
                                  fullWidth={false}
                                />
                              </FormControl>
                            </FlexItemBox>
                          )}
                          {identityHasFilter(
                            identityTypeId,
                            FilterTypes.REFERENCE_TYPE,
                          ) && (
                            <FlexItemBox py={2} px={2} data-testid="references">
                              <VineaAutoComplete
                                value={referenceTypeID}
                                onChange={handleChangeValue}
                                inlineLabel
                                options={lkpReferences ?? []}
                                labelVariant="body1"
                                label={t(nameMap.referenceTypeID.name)}
                                name={t(nameMap.referenceTypeID.name)}
                                inputProps={{
                                  name: 'referenceTypeID',
                                }}
                                fullWidth={false}
                              />
                            </FlexItemBox>
                          )}
                          {identityHasFilter(
                            identityTypeId,
                            FilterTypes.REFERENCE_VALUE,
                          ) && (
                            <FlexItemBox
                              py={2}
                              px={2}
                              data-testid="references-value"
                            >
                              <FormControl variant="outlined" margin="dense">
                                <VineaTextField
                                  id="outlined-search"
                                  label={t(nameMap.refValue.name)}
                                  type="search"
                                  variant="outlined"
                                  size="small"
                                  value={refValue}
                                  onChange={handleOnRefValue}
                                />
                              </FormControl>
                            </FlexItemBox>
                          )}
                          {identityHasFilter(
                            identityTypeId,
                            FilterTypes.CLASSIFICATION_GROUP,
                          ) && (
                            <FlexItemBox
                              py={2}
                              px={2}
                              data-testid="classificationgroups"
                            >
                              <VineaAutoComplete
                                value={classificationGroupID}
                                onChange={handleChangeValue}
                                inlineLabel
                                options={lkpClassificationsGroup ?? []}
                                labelVariant="body1"
                                label={t('Classifications Group')}
                                name={t('Classifications Group')}
                                // displayLabel
                                inputProps={{
                                  name: 'classificationGroupID',
                                }}
                                fullWidth={false}
                              />
                            </FlexItemBox>
                          )}
                          {identityHasFilter(
                            identityTypeId,
                            FilterTypes.JOB_NAME,
                          ) && (
                            <FlexItemBox
                              py={1}
                              px={2}
                              data-testid="jobnamefilter-flexitembox"
                            >
                              <FormControl variant="outlined" margin="dense">
                                <VineaTextField
                                  id="outlined-search-id-jobname"
                                  label={t(nameMap.jobName.name)}
                                  type="search"
                                  variant="outlined"
                                  size="small"
                                  value={jobName}
                                  name={'jobName'}
                                  onChange={handleChangeValue}
                                  inputRef={firstFilterInputRef}
                                  fullWidth={false}
                                />
                              </FormControl>
                            </FlexItemBox>
                          )}
                          {identityHasFilter(
                            identityTypeId,
                            FilterTypes.JOB_ID,
                          ) && (
                            <FlexItemBox
                              py={1}
                              px={2}
                              data-testid="jobidfilter-flexitembox"
                            >
                              <FormControl variant="outlined" margin="dense">
                                <VineaTextField
                                  id="outlined-search-id-jobid"
                                  label={t(nameMap.jobID.name)}
                                  type="search"
                                  variant="outlined"
                                  size="small"
                                  value={jobID}
                                  name={'jobID'}
                                  onChange={handleChangeValue}
                                  inputRef={firstFilterInputRef}
                                />
                              </FormControl>
                            </FlexItemBox>
                          )}
                          {identityHasFilter(
                            identityTypeId,
                            FilterTypes.STATUS,
                          ) && (
                            <FlexItemBox
                              py={1}
                              px={2}
                              data-testid="statusfilter-flexitembox"
                            >
                              <FormControl margin="dense">
                                <VineaAutoComplete
                                  value={statusID}
                                  onChange={handleChangeValue}
                                  inlineLabel
                                  options={lkpJobStatus}
                                  labelVariant="body1"
                                  label={t(nameMap.statusID.name)}
                                  name={t(nameMap.statusID.name)}
                                  inputProps={{
                                    name: 'statusID',
                                  }}
                                  fullWidth={false}
                                />
                              </FormControl>
                            </FlexItemBox>
                          )}
                          <>
                            <FlexItemBox
                              py={1}
                              px={2}
                              data-testid="active-toggle-flexItemBox"
                            >
                              <FormControlLabel
                                control={
                                  <GreenSwitch
                                    checked={!findNonActive}
                                    onChange={handleChangeInActive}
                                    name="activeOnly"
                                  />
                                }
                                label={
                                  <Typography variant="caption">
                                    {t(nameMap.findNonActive.name)}
                                  </Typography>
                                }
                              />
                            </FlexItemBox>
                          </>
                        </Box>
                        <FlexBox
                          py={2}
                          px={2}
                          sx={{ height: '100%', alignItems: 'flex-start' }}
                          data-testid="control-buttons"
                        >
                          <VineaButton
                            aria-label="clear"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            data-testid="filter-clear"
                            color="secondary"
                            variant="text"
                            onClick={handleOnClearSearch}
                          >
                            {t('Clear')}
                          </VineaButton>
                          <Box px={1} py={1} />
                          <VineaButton
                            aria-label="Search"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            data-testid="filter-search"
                            color="secondary"
                            variant="outlined"
                            onClick={handleOnSearch}
                          >
                            {t('Search')}
                          </VineaButton>
                        </FlexBox>
                      </ErrorBoundary>
                    </Box>
                  </Box>
                )}
              </Box>
            </CollapseHorizontal>
          </>
        </Paper>
      </Grid>
    </Grid>
  );
};

FilterScreen.propTypes = {
  onFilterToggle: PropTypes.func.isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
  lkpReferences: commonLookupPropTypes,
  lkpClassificationsGroup: commonLookupPropTypes,
  lkpclassifications: commonLookupPropTypes,
  lkpBusinessUnit: commonLookupPropTypes,
  lkpJobStatus: commonLookupPropTypes,
  identityTypeId: PropTypes.number,
  filterData: PropTypes.any,
  onUpdateFilterData: PropTypes.func.isRequired,
};

FilterScreen.defaultProps = {
  lkpReferences: [],
  lkpClassificationsGroup: [],
  lkpclassifications: [],
  identityTypeId: 0,
  lkpBusinessUnit: [],
  lkpJobStatus: [],
  filterData: null,
};

export default FilterScreen;
