/* eslint-disable prettier/prettier */
// @ts-nocheck
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { get, startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { customBreadcrumbs, IdentityTypeIds } from '../../constants';
import logger from '../../utils/winstonLogger';
import useCustomBreadCrumbsHook from '../../hooks/useCustomBreadCrumbsHook';

export const BreadCrumb = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname } = useLocation();

  const { customBCs } = useCustomBreadCrumbsHook();
  const breadcrumbs = useBreadcrumbs(customBCs);
  const breadcrumbsValue = breadcrumbs.slice(1);

  return (
    <Box
      flexGrow={1}
      sx={{
        '& .MuiBreadcrumbs-separator': {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          color: `${theme.palette.primary.contrastText}AA`,
        },
      }}
      data-testid="breadcrumbslist"
    >
      <>
        <Breadcrumbs>
          {breadcrumbsValue.map(({ breadcrumb, match }, i) => {
            const isLastElement = i === breadcrumbsValue.length - 1;
            return (
              <Link
                key={match.pathname}
                component={RouterLink}
                underline="hover"
                to={match.pathname}
              >
                <Typography
                  sx={{
                    color: isLastElement
                      ? theme.palette.primary.contrastText
                      : `${theme.palette.primary.contrastText}AA`,
                  }}
                  variant="body1"
                  noWrap
                  data-testid="breadcrumbtext"
                >
                  {t(startCase(breadcrumb.props.children))}
                </Typography>
              </Link>
            );
          })}
        </Breadcrumbs>
      </>
    </Box>
  );
};
