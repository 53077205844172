import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, filter, isNil, get, isEmpty, has } from 'lodash';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import {
  VineaNovaSelectors,
  VineaHooks,
  VineaNovaActions,
} from 'vineanova-redux-artifacts';

import { isBefore, parseISO } from 'date-fns';

import { VineaButton } from '../../components/VineaButton';
import { DiscardDialog } from '../../components/Dialog';
import { Datepicker } from '../../components/Datepicker';
import { VineaAutoComplete } from '../../components/ComboBox';
import { formatDate } from '../../constants/formatter';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import {
  IdentityDrawerTypes,
  IdentityRoleTypes,
  IdentityTypeIds,
  viewDateFormat,
} from '../../constants';
//@ts-ignore
import { syncValidator } from '../../utils/validator';
import { JobSupervisorSchema } from './validations';
import { getBusinessUnitDashboardEnabled } from '../../redux/selectors';
import { MetaDataType } from './interfaces/jobInterfaces';

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

const StyledCloseDiv = styled('div')(() => ({
  paddingLeft: 10,
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
  display: 'flex',
}));

interface DropdownType {
  id: number;
  key: number;
  value: string;
}

interface JobSupervisorType {
  supervisorName?: string;
  relationshipID?: number;
  supervisorID?: number;
  jobID: number;
  effectiveFromDate?: string;
  effectiveToDate?: string;
}

export function ManageJobSupervisorsDrawer({
  isOpen,
  isAdd,
  handleOnClose,
  handleOnChange,
  editJobSupervisorData = {},
  jobSupervisorsData = [],
  businessUnitID,
}: {
  isOpen: boolean;
  isAdd: boolean;
  handleOnClose: (drawerType: number) => void;
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  editJobSupervisorData: any;
  jobSupervisorsData: Array<JobSupervisorType>;
  businessUnitID: number;
}) {
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const drawerHeading = isAdd ? 'Add Supervisor' : 'Edit Supervisor';
  const noErrors = {
    jobID: false,
    supervisorID: false,
    effectiveFromDate: false,
    effectiveToDate: false,
  };
  const { businessUnitID: supervisorBusinessUnitID } = editJobSupervisorData;
  const { isLoading: roleSupervisorsLoading, isLoaded: roleSupervisorsLoaded } =
    useSelector(
      VineaNovaSelectors.getIdentitiesByRoleEntityMeta,
    ) as MetaDataType;
  const { data: roleSupervisors } = VineaHooks.useFetchIdentitiesByRole({
    queryParams: {
      RoleTypeIDs: IdentityRoleTypes.SUPERVISOR,
    },
  });
  const lkpBusinessUnit = useSelector(state =>
    getBusinessUnitDashboardEnabled(state),
  );
  const { isLoading: jobSupervisorLoading, isLoaded: jobSupervisorLoaded } =
    useSelector((state: any) => state.entities.jobSupervisor);
  const [supervisorOptions, setSupervisorOptions] = useState<DropdownType[]>(
    [],
  );
  const [validationErrors, setValidationErrors] = useState({
    ...noErrors,
  });
  const [businessUnit, setBusinessUnit] = useState(businessUnitID);

  const handleOnChangeBusinessUnit = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setBusinessUnit(Number(event.target.value));
  };

  // Save supervisor to job
  const handleOnSave = () => {
    let validationErrors = syncValidator(JobSupervisorSchema)(
      editJobSupervisorData,
    );

    if (
      isEmpty(validationErrors) &&
      !isNil(editJobSupervisorData?.effectiveToDate)
    ) {
      const isBeforeFromDate =
        has(editJobSupervisorData, 'effectiveToDate') &&
        has(editJobSupervisorData, 'effectiveFromDate') &&
        isBefore(
          parseISO(get(editJobSupervisorData, 'effectiveToDate')),
          parseISO(get(editJobSupervisorData, 'effectiveFromDate')),
        );
      if (isBeforeFromDate) {
        validationErrors = {
          effectiveToDate: 'Active To date is before Active From date',
        };
      }
    }

    if (isEmpty(validationErrors)) {
      if (!isNil(editJobSupervisorData?.supervisorID)) {
        if (isAdd) {
          dispatchAPI(
            VineaNovaActions.api.v1.jobSupervisor.post.request({
              postBody: {
                jobID: editJobSupervisorData?.jobID,
                supervisorID: editJobSupervisorData?.supervisorID,
                effectiveFromDate: editJobSupervisorData?.effectiveFromDate,
                effectiveToDate: editJobSupervisorData?.effectiveToDate,
              },
            }),
          );
        } else {
          dispatchAPI(
            VineaNovaActions.api.v1.jobSupervisor.put.request({
              postBody: {
                relationshipID: editJobSupervisorData?.relationshipID,
                jobID: editJobSupervisorData?.jobID,
                supervisorID: editJobSupervisorData?.supervisorID,
                effectiveFromDate: editJobSupervisorData?.effectiveFromDate,
                effectiveToDate: editJobSupervisorData?.effectiveToDate,
              },
            }),
          );
        }
      }
      handleOnClose(IdentityDrawerTypes.JOB_SUPERVISOR);
    } else {
      setValidationErrors(validationErrors);
    }
  };

  const toggleDrawer = (event: React.KeyboardEvent) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const handleDelete = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.jobSupervisor.delete.request({
        queryParams: {
          JobID: editJobSupervisorData?.jobID,
          SupervisorID: editJobSupervisorData?.supervisorID,
        },
      }),
    );
    handleOnClose(IdentityDrawerTypes.JOB_SUPERVISOR);
  };
  // don't show any existing supervisors, except the selected supervisor
  const handleSetSupervisorOptions = () => {
    const existingSupervisors = map(
      filter(
        jobSupervisorsData as JobSupervisorType[],
        (s: JobSupervisorType) => {
          return (
            s.supervisorID !== get(editJobSupervisorData, 'supervisorID', 0)
          );
        },
      ),
      'supervisorID',
    );
    const supervisors = map(
      filter(roleSupervisors as object[], (s: any) => {
        return (
          (businessUnit === 0 || s.businessUnitID === businessUnit) &&
          s.identityTypeID === IdentityTypeIds.PERSON &&
          !existingSupervisors.includes(s.id)
        );
      }),
      (v: any) => {
        return {
          id: v.id,
          key: v.id,
          value: v.displayName,
        };
      },
    );

    setSupervisorOptions(supervisors);
  };
  const handleOnDiscard = () => {
    handleOnClose(IdentityDrawerTypes.JOB_SUPERVISOR);
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose(IdentityDrawerTypes.JOB_SUPERVISOR);
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  // Set supervisor options once api loads
  useEffect(() => {
    if (
      roleSupervisorsLoaded &&
      !roleSupervisorsLoading &&
      jobSupervisorLoaded &&
      !jobSupervisorLoading &&
      isOpen
    ) {
      handleSetSupervisorOptions();
    }
  }, [
    roleSupervisorsLoaded,
    roleSupervisorsLoading,
    jobSupervisorLoaded,
    jobSupervisorLoading,
    isOpen,
  ]);

  useEffect(() => {
    if (!isNil(supervisorBusinessUnitID)) {
      setBusinessUnit(supervisorBusinessUnitID);
    } else {
      setBusinessUnit(businessUnitID);
    }
  }, [businessUnitID, supervisorBusinessUnitID]);

  useEffect(() => {
    handleSetSupervisorOptions();
  }, [businessUnit]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={handleOnClickAway}
      >
        <Box sx={{ width: 380 }} role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography sx={{ padding: 2 }} variant="body1">
                {t(drawerHeading)}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOnDiscard}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />
            <Box my={2}>
              <StyledFormControl data-testid="managejobsupervisor-businessUnitID">
                <VineaAutoComplete
                  value={businessUnit || 0}
                  onChange={handleOnChangeBusinessUnit}
                  options={lkpBusinessUnit || []}
                  labelVariant="body1"
                  inlineLabel={false}
                  id="businessUnitID"
                  name={t('businessUnitID')}
                  label={t('Business Unit')}
                  inputProps={{
                    name: 'businessUnitID',
                  }}
                  fullWidth={false}
                  disabled={!isAdd}
                />
              </StyledFormControl>
              <StyledFormControl data-testid="managejobsupervisor-supevisorform">
                <VineaAutoComplete
                  value={editJobSupervisorData?.supervisorID}
                  onChange={handleOnChange}
                  options={supervisorOptions}
                  labelVariant="body1"
                  inlineLabel={false}
                  id="supervisorID"
                  name={t('supervisorID')}
                  label={t('Supervisor')}
                  inputProps={{
                    name: 'supervisorID',
                  }}
                  fullWidth={false}
                  disabled={!isAdd}
                />
              </StyledFormControl>
              <StyledFormControl data-testid="managejobsupervisoractivefrom">
                <Datepicker
                  id="effectiveFromDate"
                  label={t('Active From')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  name="effectiveFromDate"
                  value={editJobSupervisorData?.effectiveFromDate || null}
                  onChange={handleOnChange}
                  inputProps={{
                    'data-id': 'effectiveFromDate',
                    'data-name': 'effectiveFromDate',
                  }}
                  fullWidth
                />
              </StyledFormControl>
              <StyledFormControl data-testid="managejobsupervisoractiveto">
                <Datepicker
                  id="effectiveToDate"
                  label={t('Active To')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  name="effectiveToDate"
                  value={editJobSupervisorData?.effectiveToDate || null}
                  onChange={handleOnChange}
                  inputProps={{
                    'data-id': 'effectiveToDate',
                    'data-name': 'effectiveToDate',
                  }}
                  fullWidth
                />
                {validationErrors.effectiveToDate && (
                  <FormHelperText id="component-error-text">
                    {validationErrors.effectiveToDate}
                  </FormHelperText>
                )}
              </StyledFormControl>
            </Box>
            <StyledButtonControlDiv>
              <div>
                <VineaButton
                  color="success"
                  onClick={handleOnSave}
                  variant="contained"
                  minWidth={150}
                >
                  {t('Save')}
                </VineaButton>
              </div>
              <StyledCloseDiv>
                <VineaButton
                  color="secondary"
                  onClick={handleOnDiscard}
                  variant="outlined"
                  minWidth={150}
                >
                  {t('Close')}
                </VineaButton>
              </StyledCloseDiv>
              {!isAdd && (
                <StyledCloseDiv>
                  <VineaButton
                    color="error"
                    onClick={handleDelete}
                    variant="text"
                    minWidth={150}
                  >
                    {t('Delete')}
                  </VineaButton>
                </StyledCloseDiv>
              )}
            </StyledButtonControlDiv>
          </ErrorBoundary>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={() => setDiscardDialogOpen(false)}
            handleSaveChanges={handleOnDiscardSaveChanges}
            handleDiscardChanges={handleOnDiscardConfirm}
          />
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
}
