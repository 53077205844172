import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './TelemetryService';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    const { instrumentationKey, after } = this.props;
    const { initialized } = this.state;
    const AppInsightsInstrumentationKey = instrumentationKey; // PUT YOUR KEY HERE
    if (!initialized && Boolean(AppInsightsInstrumentationKey)) {
      ai.initialize(AppInsightsInstrumentationKey);
      this.setState({ initialized: true });
    }
    after();
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

TelemetryProvider.propTypes = {
  instrumentationKey: PropTypes.string.isRequired,
  after: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
export default withAITracking(ai.reactPlugin, TelemetryProvider);
