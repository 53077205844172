/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import {
  sortBy,
  isEmpty,
  filter,
  map,
  get,
  unionBy,
  differenceBy,
} from 'lodash';
import { PlanBlocksTreeViewComponent } from '../Treeview';

export const PlanBlocksTransfer = ({
  availableBlocks = [],
  currentBlocks = [],
  isLoading = false,
  groups = [],
  onChange,
  width = 200,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [leftSelected, setLeftSelected] = React.useState([]);
  const [rightSelected, setRightSelected] = React.useState([]);
  const [leftBlocks, setLeftBlocks] = React.useState([]);
  const [rightBlocks, setRightBlocks] = React.useState([]);
  const planActivityBlocks = useMemo(() => {
    let blocks = filter(
      rightBlocks,
      block => get(block, 'planActivityBlockID', 0) > 0,
    );
    return blocks;
  }, [rightBlocks]);

  const rightHeader = t('Blocks In This Plan');
  const leftHeader = t('Available Blocks');

  React.useEffect(() => {
    setLeftBlocks(availableBlocks);
  }, [availableBlocks]);

  React.useEffect(() => {
    setRightBlocks(currentBlocks);
  }, [currentBlocks]);

  const handleOnChange = React.useCallback(
    selectedValues => {
      const getIds = selectedValues.reduce((acc, val) => {
        const { vineyardBlockID } = val;
        acc.push(vineyardBlockID);
        return acc;
      }, []);
      const target = {
        value: getIds,
        name: 'vineyardBlockIDs',
      };
      onChange({ target });
    },
    [onChange],
  );

  const handleAllRight = () => {
    const newValues = rightBlocks.concat(leftBlocks);
    setRightBlocks(newValues);
    setLeftBlocks([]);
    setLeftSelected([]);
    handleOnChange(newValues);
  };

  const handleMoveToRight = () => {
    let copyToRight = leftBlocks.filter(f =>
      leftSelected.includes(f.vineyardBlockID),
    );

    if (isEmpty(copyToRight)) {
      copyToRight = leftBlocks.filter(f =>
        leftSelected.includes(f.vineyardName),
      );
    }

    let leftRemoved = leftBlocks.filter(
      f => !leftSelected.includes(f.vineyardBlockID),
    );
    const selectedIds = copyToRight.map(f => f.vineyardBlockID);

    if (!isEmpty(leftRemoved)) {
      leftRemoved = leftBlocks.filter(
        f => !selectedIds.includes(f.vineyardBlockID),
      );
    }

    const newValues = rightBlocks.concat(copyToRight);
    setRightBlocks(newValues);
    setLeftBlocks(leftRemoved);
    setLeftSelected([]);
    handleOnChange(newValues);
  };
  const sortGroups = sortBy(groups);

  const handleMoveToLeft = () => {
    let copyToLeft = rightBlocks.filter(f =>
      rightSelected.includes(f.vineyardBlockID),
    );
    if (isEmpty(copyToLeft)) {
      copyToLeft = rightBlocks.filter(f =>
        rightSelected.includes(f.vineyardName),
      );
    }

    let newRight = rightBlocks.filter(
      f => !rightSelected.includes(f.vineyardBlockID),
    );
    const selectedIds = copyToLeft.map(f => f.vineyardBlockID);

    if (!isEmpty(newRight)) {
      newRight = rightBlocks.filter(
        f => !selectedIds.includes(f.vineyardBlockID),
      );
    }

    newRight = unionBy(newRight, planActivityBlocks, 'vineyardBlockID');
    let newLeft = differenceBy(
      leftBlocks.concat(copyToLeft),
      planActivityBlocks,
      'vineyardBlockID',
    );
    setLeftBlocks(newLeft);
    setRightBlocks(newRight);
    setRightSelected([]);
    handleOnChange(newRight);
  };

  const handleAllLeft = () => {
    let newLeft = differenceBy(
      leftBlocks.concat(rightBlocks),
      planActivityBlocks,
      'vineyardBlockID',
    );
    setLeftBlocks(newLeft);
    setRightBlocks(planActivityBlocks);
    setRightSelected([]);
    handleOnChange([]);
  };

  const handleLeftNodeSelect = nodeIds => {
    setLeftSelected(nodeIds);
  };

  const handleRightNodeSelect = nodeIds => {
    setRightSelected(nodeIds);
  };

  const customList = side => (
    <Box>
      {side === 'left' && (
        <Typography variant="body1">{t(`${leftHeader}`)}</Typography>
      )}
      {side === 'right' && (
        <Typography variant="body1">{t(`${rightHeader}`)}</Typography>
      )}
      <Paper
        sx={{
          width: width,
          height: 330,
          overflow: 'auto',
          border: '1px solid rgba(0,0,0,0.23)',
        }}
        data-testid={`plan-block-transfer-list-${side}`}
      >
        <Box
          sx={{
            padding: theme.spacing(1, 2),
          }}
        >
          <PlanBlocksTreeViewComponent
            groups={sortGroups}
            blocks={side === 'left' ? leftBlocks : rightBlocks}
            onNodeSelect={
              side === 'left' ? handleLeftNodeSelect : handleRightNodeSelect
            }
            selected={side === 'left' ? leftSelected : rightSelected}
            isLoading={isLoading}
            side={side}
          />
        </Box>
      </Paper>
    </Box>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        margin: 'auto',
      }}
    >
      <Grid item>{customList('left')}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            sx={{
              margin: theme.spacing(0.5, 0),
            }}
            onClick={handleAllRight}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              margin: theme.spacing(0.5, 0),
            }}
            onClick={handleMoveToRight}
            disabled={leftSelected.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              margin: theme.spacing(0.5, 0),
            }}
            onClick={handleMoveToLeft}
            disabled={rightSelected.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              margin: theme.spacing(0.5, 0),
            }}
            onClick={handleAllLeft}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('right')}</Grid>
    </Grid>
  );
};
