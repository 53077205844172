/**
 * @module Sagas/download excel file
 * @desc create identity, classification and references from organiztions/vineyards/name/create new
 */

import { takeLatest, all, call } from 'redux-saga/effects';
import { sagaActionTypes } from '../../constants';
import logger from '../../utils/winstonLogger';
import { reportFileDownloadService } from '../../services/exportFile.service';

/**
 * Fetch All Lookup tables
 */
export function* fnDownloadExcelFile(action) {
  try {
    const { payload } = action;
    logger.debug(payload);
    yield call(reportFileDownloadService, payload.postBody, payload.filename);
  } catch (err) {
    /* istanbul ignore next */
    logger.error(err);
    // console.error(err);
  }
}

export default function* root() {
  yield all([
    takeLatest(sagaActionTypes.EXPORT_EXCEL_REPORT, fnDownloadExcelFile),
  ]);
}
