import React from 'react';
import { FormHelperText } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const defaultProps = {
  id: 'textfield',
  size: 'small',
  error: null,
  value: '',
};

const VineaTextField = ({
  id,
  size,
  error,
  value,
  ...props
}: TextFieldProps) => {
  return (
    <>
      <TextField
        {...props}
        variant="outlined"
        color="secondary"
        size={size}
        value={value || ''}
        fullWidth
      />
      {error && (
        <FormHelperText color="error" error id={`${id}-error-text`}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

VineaTextField.defaultProps = defaultProps;
export default VineaTextField;
