import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';

import { dateFormat } from '../../../constants';
import { getDocumentsGridSelector } from '../../../redux/selectors/documentsSelector';

export function useDocumentsHook() {
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [triggerDownloadDocument, setTriggerDownloadDocument] =
    React.useState(false);
  const [triggerGetAllDocuments, setTriggerGetAllDocuments] =
    React.useState(false);

  const { noteId } = useParams();

  const { documentObject, sourceFile } = useSelector(
    state => state.entities.linkedDocument.data,
  );

  const linkedDocumentObject = useSelector(
    state => state.entities.linkedDocument,
  );

  const { isLoading: allDocumentsIsLoading } = useSelector(
    VineaNovaSelectors.getLinkedDocumentsEntityMeta,
  );

  const {
    isLoading: documentIsLoading,
    isLoaded: documentIsLoaded,
    hasError: documentHasError,
  } = useSelector(VineaNovaSelectors.getLinkedDocumentEntityMeta);

  const lkpDocumentType = useSelector(
    VineaNovaSelectors.getlookupDocumentTypeEntityData,
  );

  const lkpDocumentStatus = useSelector(
    VineaNovaSelectors.getlookupDocumentStatusEntityData,
  );

  // string of comma-separated file extensions for file input
  const acceptedFileExtensions = lkpDocumentType
    .map(e => `.${e.documentType}`)
    .join(',');

  const documentStatusOptions = useSelector(
    VineaNovaSelectors.getlookupDocumentStatusEntityData,
  ).map(e => ({ id: e.id, key: e.id, value: e.documentStatus }));

  const allDocumentsData = useSelector(getDocumentsGridSelector);

  const handleOnUploadDocument = row => {
    // eslint-disable-next-line no-undef
    const formUploadData = new FormData();
    formUploadData.append('document', row.file);
    dispatchAPI(
      VineaNovaActions.api.v1.linkedDocument.post.request({
        queryParams: {
          NoteId: noteId,
          DocumentTypeId: row.typeId || 1,
          DocumentDate: format(new Date(row.date), dateFormat),
          DocumentSubject: row.subject,
          SourceFile: row.filename,
          DocumentStatusId: row.statusId,
        },
        postBody: formUploadData,
        options: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formUploadData,
        },
      }),
    );

    setTriggerGetAllDocuments(true);
  };

  const handleOnUpdateDocument = row => {
    dispatchAPI(
      VineaNovaActions.api.v1.linkedDocument.put.request({
        postBody: {
          DocumentId: row.id,
          DocumentDate: row.date,
          DocumentStatusID: row.statusId,
          DocumentSubject: row.subject,
        },
      }),
    );

    setTriggerGetAllDocuments(true);
  };

  const handleOnGetAllDocuments = () => {
    if (!Number(noteId)) return;

    dispatchAPI(
      VineaNovaActions.api.v1.linkedDocuments.get.request({
        queryParams: {
          NoteId: noteId,
        },
      }),
    );
  };

  const handleOnGetDocument = documentId => {
    dispatchAPI(
      VineaNovaActions.api.v1.linkedDocument.get.request({
        queryParams: {
          DocumentId: documentId,
        },
      }),
    );
  };

  const handleOnDownloadDocument = documentId => {
    handleOnGetDocument(documentId);
    setTriggerDownloadDocument(true);
    enqueueSnackbar(t('Downloading...'), { variant: 'Info' });
  };

  React.useEffect(() => {
    if (triggerDownloadDocument && !documentIsLoading && documentIsLoaded) {
      if (documentHasError || !documentObject || !sourceFile) {
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      } else {
        // eslint-disable-next-line no-undef
        const downloadLink = document.createElement('a');

        downloadLink.href = `data:;base64,${documentObject}`;
        downloadLink.download = sourceFile;

        downloadLink.click();
      }

      setTriggerDownloadDocument(false);
    }
  }, [
    triggerDownloadDocument,
    documentIsLoading,
    documentIsLoaded,
    documentHasError,
    setTriggerDownloadDocument,
  ]);

  React.useEffect(() => {
    if (triggerGetAllDocuments && !documentIsLoading && documentIsLoaded) {
      if (documentHasError) {
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      } else {
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        handleOnGetAllDocuments();
      }

      setTriggerGetAllDocuments(false);
    }
  }, [
    triggerGetAllDocuments,
    documentIsLoading,
    documentIsLoaded,
    documentHasError,
    setTriggerGetAllDocuments,
  ]);

  return {
    handleOnUploadDocument,
    handleOnUpdateDocument,
    handleOnGetAllDocuments,
    handleOnGetDocument,
    handleOnDownloadDocument,
    allDocumentsData,
    allDocumentsIsLoading,
    lkpDocumentType,
    lkpDocumentStatus,
    documentStatusOptions,
    acceptedFileExtensions,
    linkedDocumentObject,
  };
}
