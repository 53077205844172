import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { ErrorBoundary } from 'react-error-boundary';

// import { FieldNameSelect } from '../../../components/Select';
import { VineaButton } from '../../../components/VineaButton';
import { Datepicker } from '../../../components/Datepicker';
import { VineaTextField } from '../../../components/TextField';
import { VineaTextFieldWithFooterSwitch } from '../../../components/TextField/VineaTextFieldWithFooterSwitch';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { ImageUpload } from '../../../components/ImageUpload';
import { PhenologyBlockVintageSchema } from './validations';
import { syncValidator } from '../../../utils/validator';
import useImageUtils from '../../../hooks/ImageUtils';
import { DiscardDialog, DeleteDialog } from '../../../components/Dialog';
import { viewDateFormat } from '../../../constants';
import { formatDate } from '../../../constants/formatter';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';

const StyledDiv = styled('div')(() => ({
  marginTop: 1,
}));

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

const StyledDrawerControl = styled('div')(() => ({
  width: 350,
}));

const StyledCloseDiv = styled('div')(() => ({
  paddingLeft: 10,
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
  display: 'flex',
}));

export const PhenologyMeasureDrawer = ({
  open,
  onClose,
  data,
  handleOnSave,
  blockSelected,
  vineyardSelected,
  lkpPhenologyMeasure = [],
  lkpPhenologyMeasureOptions = [],
  lkpPhenologyMeasureSet = [],
  onDelete,
  handleOnImageUpload,
  editedNoteImage,
  onDeleteNoteImage,
}) => {
  const theme = useTheme();
  const [state, setState] = React.useState(open);
  const { t } = useTranslation();
  const { isEdit = false, measure, isAdd = false } = data;
  // const [measureInstanceByGroup, setMeasureInstanceByGroup] =
  //   React.useState(lkpMeasureInstance);
  const [selectedRow, setSelectedRow] = React.useState(data);
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(null);
  const [deletePhenologyDialog, setPhenologyDeleteDialog] =
    React.useState(null);

  const [filteredMeasureOptions, setFilteredMeasureOptions] = React.useState(
    [],
  );
  const [validationErrors, setValidationErrors] = React.useState({});

  const {
    phenologyMeasureDate,
    phenologyComment,
    phenologyMeasureID,
    phenologyMeasureSetID,
    isPrivate,
    noteImage,
    cropNoteID,
  } = selectedRow || {};

  const { imageDataUrl } = useImageUtils(editedNoteImage?.base64 || noteImage);

  React.useEffect(() => {
    const { phenologyMeasureID: xphenologyMeasureID } = data;
    let phenologyMeasureTypeID = 0;
    if (!isEmpty(lkpPhenologyMeasure)) {
      phenologyMeasureTypeID = get(
        lkpPhenologyMeasure.find(mi => mi.id === xphenologyMeasureID),
        'phenologyMeasureTypeID',
        0,
      );
    }
    setSelectedRow({ ...data, phenologyMeasureTypeID });
  }, [data, lkpPhenologyMeasure, lkpPhenologyMeasureOptions]);

  React.useEffect(() => {
    const updatedPhenologyMeasureOptions = lkpPhenologyMeasureOptions.filter(
      f => f.phenologyMeasureID === phenologyMeasureID,
    );
    setFilteredMeasureOptions(updatedPhenologyMeasureOptions);
  }, [phenologyMeasureID, lkpPhenologyMeasureOptions]);

  React.useEffect(() => {
    setState(open);
  }, [open]);

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const onSave = () => {
    const currentErrors = syncValidator(PhenologyBlockVintageSchema)(
      selectedRow,
    );
    console.log('currentErrors', currentErrors);
    setValidationErrors(currentErrors);
    if (isEmpty(currentErrors)) {
      handleOnSave(selectedRow);
    }
  };

  const handleOnChange = event => {
    const {
      target: { value, name },
    } = event;
    let { phenologyMeasureTypeID } = selectedRow;
    if (name === 'phenologyMeasureID') {
      phenologyMeasureTypeID = get(
        lkpPhenologyMeasure.find(mi => mi.id === value),
        'phenologyMeasureTypeID',
        phenologyMeasureTypeID,
      );
    }

    const {
      phenologyMeasureValue1,
      phenologyMeasureValue2,
      phenologyMeasureValue3,
      phenologyMeasureOptionID,
    } = selectedRow;

    const resetMeasureId = name === 'phenologyMeasureSetID';
    const resetMeasureValue = name === 'phenologyMeasureID';

    setSelectedRow({
      ...selectedRow,
      phenologyMeasureTypeID,
      phenologyMeasureID: resetMeasureId ? 0 : phenologyMeasureID,
      phenologyMeasureValue1: resetMeasureValue ? '' : phenologyMeasureValue1,
      phenologyMeasureValue2: resetMeasureValue ? '' : phenologyMeasureValue2,
      phenologyMeasureValue3: resetMeasureValue ? '' : phenologyMeasureValue3,
      phenologyMeasureOptionID: resetMeasureValue
        ? 0
        : phenologyMeasureOptionID,
      [name]: value,
      hasChanges: true,
    });
  };

  const handleOnImageUploadData = imageSet => {
    if (imageSet && imageSet.base64) {
      handleOnImageUpload(imageSet);
      setSelectedRow({
        ...selectedRow,
        hasChanges: true,
      });
    }
  };

  const handleOnDeleteImage = () => {
    if (selectedRow.isEditable) {
      onDeleteNoteImage(cropNoteID);
    }
  };

  const handleOnClosePhenologyDeleteDialog = () => {
    setPhenologyDeleteDialog(false);
  };

  const handleOnPhenologyDeleteRequest = () => {
    setPhenologyDeleteDialog(true);
  };

  const handleOnPhenologyDeleteConfirm = () => {
    onDelete();
  };

  const handleOnSwitchChange = evt => {
    const {
      target: { checked },
    } = evt;
    setSelectedRow({ ...selectedRow, isPrivate: checked, hasChanges: true });
  };

  const handleOnDiscard = () => {
    const { hasChanges } = selectedRow;
    if (hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    onClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    onSave();
  };

  const vineyardBlockSelected = `${vineyardSelected || ''} ${
    blockSelected ? '/' : ''
  } ${blockSelected || ''}`;

  const drawerSubtitle = `${
    selectedRow.isEditable ? 'Edit Phenology Measure' : 'View Phenology Measure'
  }`;

  const renderValueFields = rowValue => {
    const {
      phenologyMeasureValue1,
      phenologyMeasureValue2,
      phenologyMeasureValue3,
      phenologyMeasureTypeID,
      phenologyMeasureOptionID,
    } = rowValue;

    return (
      <>
        {phenologyMeasureTypeID === 1 && (
          <StyledFormControl>
            <VineaAutoComplete
              value={phenologyMeasureOptionID}
              onChange={handleOnChange}
              inlineLabel
              options={filteredMeasureOptions || []}
              labelVariant="body1"
              label={t('Measure Option')}
              name={t('Measure Option')}
              displayLabel
              inputProps={{
                name: 'phenologyMeasureOptionID',
                readOnly: !selectedRow.isEditable,
              }}
              data-testid="phenologyMeasureOptionID-picker"
              fullWidth
              error={validationErrors.phenologyMeasureOptionID}
            />
          </StyledFormControl>
        )}
        {phenologyMeasureTypeID > 1 && (
          <StyledFormControl error={validationErrors.phenologyMeasureValue1}>
            <VineaTextField
              fullWidth
              autoComplete="off"
              id="phenologyMeasureValue1"
              size="small"
              label={t('Value 1')}
              name="phenologyMeasureValue1"
              value={phenologyMeasureValue1 || ''}
              onChange={handleOnChange}
              inputProps={{
                readOnly: !selectedRow.isEditable,
              }}
            />
            {validationErrors.phenologyMeasureValue1 && (
              <FormHelperText id="component-error-text">
                {validationErrors.phenologyMeasureValue1}
              </FormHelperText>
            )}
          </StyledFormControl>
        )}
        {phenologyMeasureTypeID > 2 && (
          <StyledFormControl error={validationErrors.phenologyMeasureValue2}>
            <VineaTextField
              fullWidth
              autoComplete="off"
              id="phenologyMeasureValue2"
              size="small"
              label={t('Value 2')}
              name="phenologyMeasureValue2"
              value={phenologyMeasureValue2 || ''}
              onChange={handleOnChange}
              inputProps={{
                readOnly: !selectedRow.isEditable,
              }}
            />
            {validationErrors.phenologyMeasureValue2 && (
              <FormHelperText id="component-error-text">
                {validationErrors.phenologyMeasureValue2}
              </FormHelperText>
            )}
          </StyledFormControl>
        )}
        {phenologyMeasureTypeID > 3 && (
          <StyledFormControl error={validationErrors.phenologyMeasureValue3}>
            <VineaTextField
              fullWidth
              autoComplete="off"
              id="phenologyMeasureValue3"
              size="small"
              label={t('Value 3')}
              name="phenologyMeasureValue3"
              value={phenologyMeasureValue3 || ''}
              onChange={handleOnChange}
              inputProps={{
                readOnly: !selectedRow.isEditable,
              }}
            />
            {validationErrors.phenologyMeasureValue3 && (
              <FormHelperText id="component-error-text">
                {validationErrors.phenologyMeasureValue3}
              </FormHelperText>
            )}
          </StyledFormControl>
        )}
      </>
    );
  };

  const filteredPhenologyMeasure = lkpPhenologyMeasure.filter(
    f => f.phenologyMeasureSetID === phenologyMeasureSetID,
  );

  const list = () => (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleOnClickAway}>
      <StyledDrawerControl>
        <StyledDiv
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography
                sx={{
                  padding: theme.spacing(2),
                }}
                variant="body1"
              >
                {vineyardBlockSelected}
              </Typography>
            </Box>
            <Box>
              <IconButton
                aria-label="toggle sidebar"
                onClick={handleOnDiscard}
                onMouseDown={() => {}}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {isEdit && (
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  paddingLeft: theme.spacing(2),
                }}
              >
                {t(`${drawerSubtitle}`)}
              </Typography>
              <Typography
                sx={{
                  paddingLeft: theme.spacing(2),
                  paddingBottom: theme.spacing(2),
                }}
                component="span"
              >
                {measure}
              </Typography>
            </Box>
          )}

          {isAdd && (
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  paddingLeft: theme.spacing(2),
                }}
              >
                {t('Add Phenology Measure')}
              </Typography>
              <Typography
                sx={{
                  paddingLeft: theme.spacing(2),
                  paddingBottom: theme.spacing(2),
                }}
                component="span"
              >
                {measure}
              </Typography>
            </Box>
          )}

          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />

            {!selectedRow.isEditable && (
              <Box>
                <Typography
                  sx={{
                    padding: theme.spacing(2),
                    color: 'red',
                  }}
                  variant="subtitle2"
                >
                  {t('This measure is read-only')}
                </Typography>
              </Box>
            )}

            <StyledFormControl
              data-testid="datepicker-formcontrol"
              error={validationErrors.phenologyMeasureDate}
            >
              <Datepicker
                disablePast
                id="phenologyMeasureDate"
                label={t('Measure Date')}
                placeholder="DD/MM/YYYY"
                inputFormat={viewDateFormat}
                formatDate={formatDate}
                variant="outlined"
                size="small"
                name="phenologyMeasureDate"
                value={phenologyMeasureDate}
                onChange={handleOnChange}
                inputProps={{
                  'data-id': 'phenologyMeasureDate',
                  'data-name': 'phenologyMeasureDate',
                  placeholder: 'DD/MM/YYYY',
                  readOnly: !selectedRow.isEditable,
                }}
                fullWidth
              />
              {validationErrors.phenologyMeasureDate && (
                <FormHelperText id="component-error-text">
                  {validationErrors.phenologyMeasureDate}
                </FormHelperText>
              )}
            </StyledFormControl>

            {isAdd && (
              <>
                <Box px={2} data-testid="pickerPhenologyMeasureSet">
                  <VineaAutoComplete
                    value={phenologyMeasureSetID}
                    onChange={handleOnChange}
                    inlineLabel
                    options={lkpPhenologyMeasureSet}
                    labelVariant="body1"
                    label={t('Measure Set')}
                    name={t('Measure Set')}
                    displayLabel
                    inputProps={{
                      name: 'phenologyMeasureSetID',
                    }}
                    data-testid="phenologyMeasureSetID-picker"
                    fullWidth
                    error={validationErrors.phenologyMeasureSetID}
                  />
                </Box>

                <Box px={2} data-testid="pickerPhenologyMeasure">
                  <VineaAutoComplete
                    value={phenologyMeasureID}
                    onChange={handleOnChange}
                    inlineLabel
                    options={filteredPhenologyMeasure}
                    labelVariant="body1"
                    label={t('Measure')}
                    name={t('Measure')}
                    displayLabel
                    inputProps={{
                      name: 'phenologyMeasureID',
                    }}
                    data-testid="phenologyMeasure-picker"
                    fullWidth
                    error={validationErrors.phenologyMeasureID}
                  />
                </Box>
              </>
            )}

            {/** render value1 and value2 fields */}
            {renderValueFields(selectedRow)}

            <StyledFormControl>
              <VineaTextFieldWithFooterSwitch
                fullWidth
                autoComplete="off"
                id="phenologyComment"
                size="small"
                label={t('Comment')}
                multiline
                rowsMax={4}
                rows={3}
                value={phenologyComment || ''}
                name="phenologyComment"
                onChange={handleOnChange}
                inputProps={{
                  readOnly: !selectedRow.isEditable,
                }}
                onSwitchChange={handleOnSwitchChange}
                checked={!!isPrivate}
              />
            </StyledFormControl>

            {selectedRow.isEditable && (
              <>
                <StyledFormControl>
                  <ImageUpload handleOnChange={handleOnImageUploadData} />
                </StyledFormControl>
              </>
            )}
            <Box>
              {imageDataUrl && (
                <StyledFormControl>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      boxShadow: 'none',
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        sx={{
                          height: '300px',
                          width: '300px',
                          flex: 1,
                          flexDirection: 'column',
                        }}
                        image={imageDataUrl}
                        title="phenologyimage"
                      />
                    </CardActionArea>
                    <CardActions>
                      {selectedRow.isEditable && (
                        <IconButton
                          aria-label="delete"
                          onClick={handleOnDeleteImage}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </CardActions>
                  </Card>
                </StyledFormControl>
              )}
            </Box>
            {selectedRow.isEditable && (
              <StyledButtonControlDiv>
                <div>
                  <VineaButton
                    color="success"
                    onClick={onSave}
                    variant="contained"
                    minWidth={100}
                  >
                    {t('Save')}
                  </VineaButton>
                </div>

                {isEdit && (
                  <StyledCloseDiv>
                    <VineaButton
                      variant="contained"
                      onClick={handleOnPhenologyDeleteRequest}
                      color="error"
                    >
                      {t('Delete')}
                    </VineaButton>
                  </StyledCloseDiv>
                )}
              </StyledButtonControlDiv>
            )}
          </ErrorBoundary>
        </StyledDiv>

        <DeleteDialog
          onClose={handleOnClosePhenologyDeleteDialog}
          dialogHeader={`Delete measure for ${selectedRow.measure}?`}
          dialogContent="This action is irreversible"
          open={deletePhenologyDialog}
          rowId={0}
          dialogActionTrigger={handleOnPhenologyDeleteConfirm}
        />
        <DiscardDialog
          open={discardDialogOpen}
          onClose={() => setDiscardDialogOpen(false)}
          handleSaveChanges={handleOnDiscardSaveChanges}
          handleDiscardChanges={handleOnDiscardConfirm}
        />
      </StyledDrawerControl>
    </ClickAwayListener>
  );

  return (
    <Drawer anchor="right" open={state} onClose={toggleDrawer}>
      {list('right')}
    </Drawer>
  );
};

PhenologyMeasureDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    isEdit: PropTypes.bool,
    isAdd: PropTypes.bool,
    measure: PropTypes.string,
    phenologyMeasureID: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  blockSelected: PropTypes.string,
  vineyardSelected: PropTypes.string,
  lkpPhenologyMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  lkpPhenologyMeasureOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  lkpPhenologyMeasureSet: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  handleOnImageUpload: PropTypes.func.isRequired,
  editedNoteImage: PropTypes.shape({
    base64: PropTypes.string,
  }),
  onDeleteNoteImage: PropTypes.func.isRequired,
};

PhenologyMeasureDrawer.defaultProps = {
  vineyardSelected: '',
  blockSelected: '',
  lkpPhenologyMeasure: [],
  lkpPhenologyMeasureOptions: [],
  lkpPhenologyMeasureSet: [],
  editedNoteImage: {},
};
