import { RenderDataGridHeader } from '../Grid';

export const filterDataGridColumns = [
  {
    field: 'blockName',
    headerName: 'Block',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'plantedArea',
    headerName: 'Area (ha)',
    flex: 0.8,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
];
