import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

const getIdentitySearchData = state => state.searchUsers;

export const getAllUsers = createSelector(
  [getIdentitySearchData],
  identitySearchData => {
    const { data = [] } = identitySearchData;
    return {
      ...identitySearchData,
      data: isEmpty(data) ? [] : data,
    };
  },
);
