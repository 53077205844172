/* eslint-disable react/prop-types */
import React from 'react';
import Switch from '@mui/material/Switch';
import { FormControlLabel, FormGroup } from '@mui/material';
import { useDispatch } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { VineaButton } from '../VineaButton';

export default function PreferancePanel(props) {
  const dispatchAPI = useDispatch();
  const { t } = useTranslation();
  const {
    handleOnClose,
    columnsData,
    gridColumnState,
    dialogOpen,
    actionType,
  } = props;
  const [columnsState, setColumnsState] = React.useState(gridColumnState);
  const columns = columnsData.lookup;
  const columnsValue = Object.values(columns);

  const handleChange = event => {
    setColumnsState({
      ...columnsState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClose = () => {
    handleOnClose(columnsState);
    dispatchAPI({
      type: 'SETTINGS_UPDATE',
    });
  };

  const handleOnHideAll = () => {
    if (actionType === 'TRACKING_GRID_COLUMN_UPDATE') {
      handleClose();
    } else {
      const newState = Object.fromEntries(
        Object.entries(columnsState).map(([key]) => [key, false]),
      );
      if (actionType === 'BLOCKS_GRID_COLUMN_UPDATE') {
        setColumnsState({ ...newState, blockFullName: true, id: false });
      } else {
        setColumnsState({ ...newState, name: true, id: false });
      }
    }
  };

  const handleOnShowAll = () => {
    if (actionType === 'TRACKING_GRID_COLUMN_UPDATE') {
      handleClose();
    } else {
      const newState = Object.fromEntries(
        Object.entries(columnsState).map(([key]) => [key, true]),
      );
      setColumnsState({ ...newState, id: false });
    }
  };

  return (
    <>
      <Dialog
        onClose={handleOnClose}
        open={dialogOpen}
        sx={{
          '& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthLg.MuiPaper-elevation24.MuiPaper-rounded':
            {
              width: '300px',
            },
        }}
        maxWidth="lg"
      >
        <Toolbar
          data-testid="preference-popover-toolbar"
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <VineaButton
            autoFocus
            color="primary"
            data-testid="hide-all"
            onClick={handleOnHideAll}
          >
            {t('HIDE ALL')}
          </VineaButton>
          <VineaButton
            autoFocus
            color="primary"
            data-testid="show-all"
            onClick={handleOnShowAll}
          >
            {t('SHOW ALL')}
          </VineaButton>
        </Toolbar>
        <FormGroup
          row={false}
          sx={{
            padding: '15px',
          }}
        >
          {columnsValue.map(column => (
            <FormControlLabel
              key={column.field}
              control={
                <Switch
                  key={column.field}
                  checked={!!columnsState[column.field]}
                  onChange={handleChange}
                  name={column.field}
                  disabled={
                    actionType === 'TRACKING_GRID_COLUMN_UPDATE'
                      ? true
                      : column.field === 'name' ||
                        column.field === 'id' ||
                        column.field === 'blockFullName'
                  }
                />
              }
              label={column.headerName}
            />
          ))}
          <VineaButton
            variant="contained"
            color="secondary"
            data-testid="set-column-preference"
            onClick={handleClose}
            sx={{
              marginTop: '10px',
            }}
          >
            {t('SET AS COLUMN PREFERANCE')}
          </VineaButton>
        </FormGroup>
      </Dialog>
    </>
  );
}
