import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

export const StyledTableCell = styled(TableCell)(
  ({ theme, manageStyle = false, iconStyle = false }) => ({
    ...(manageStyle && {
      verticalAlign: 'center',
      '& .MuiFormControl-root': {
        minWidth: 100,
        marginTop: 0,
      },
      '& .MuiFormHelperText-root': {
        color: theme.palette.error.main,
      },
    }),
    ...(iconStyle && {}),
    maxWidth: '130px',
    height: '10px',
    minHeight: '10px',
    padding: 0,
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.action.hover,
    },
    '& .MuiTableCell-body': {
      fontSize: 12,
    },
  }),
);
