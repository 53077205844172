import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { VineaButton } from '../../../components/VineaButton';
import GridWithFilter from '../../../components/Grid/GridWithFilter';
import { GridNoRowMessage } from '../../../components/Grid/GridNoRowMessage';
import { sitesGridColumn } from './sensorGridColumn';
import {
  getSiteGridSelector,
  getUserPreferences,
} from '../../../redux/selectors';
import { SitesDrawer } from './SitesDrawer';
import { GreenSwitch } from '../../../components/Switch';
import { IdentityTypeIds, commonFieldSelectOption } from '../../../constants';

const StyledRoot = styled('div')(({ noRows }) => {
  // eslint-disable-next-line no-unused-expressions
  noRows ? { flexGrow: 1, height: 450 } : { flexGrow: 1 };
});

export const SitesGrid = ({
  pathParamId,
  hasSites,
  tabIsLoading,
  dataConnectionName,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [activeOnly, setActiveOnly] = React.useState(false);
  const [showNewSitesAlert, setShowNewSitesAlert] = React.useState(false);

  const [triggerGetSites, setTriggerGetSites] = React.useState(false);

  // get selector values
  const sitesData = useSelector(getSiteGridSelector);
  const dataGridColumnsPreferences = useSelector(state =>
    getUserPreferences(state),
  );
  const lgColumnsTable = sitesGridColumn();
  const noRows = sitesData.length === 0;

  const searchData =
    useSelector(VineaNovaSelectors.getSearchIdentityEntityData) || [];
  const lkpVineyardNames = commonFieldSelectOption.concat(
    searchData.map(e => ({
      id: e.id,
      key: e.id,
      value: e.name,
    })),
  );

  const { isLoading: sitesLoading, isLoaded: sitesLoaded } = useSelector(
    VineaNovaSelectors.getSitesEntityMeta,
  );

  const gridXData = {
    columns: lgColumnsTable,
    rows: activeOnly ? sitesData.filter(s => s.isActive) : sitesData,
  };

  const handleOnRowClick = event => {
    const { row } = event;
    setSelectedRow(row);
    setHasChanges(false);
    setDrawerOpen(true);
  };

  const handleOnSave = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.sites.put.request({
        postBody: {
          SiteID: selectedRow.id,
          Location: selectedRow.location,
          VineyardID: selectedRow.vineyardID,
          Active: selectedRow.isActive,
          SiteName: selectedRow.siteName,
          FriendlySiteName: selectedRow.friendlySiteName,
          ts: selectedRow.ts,
        },
      }),
    );
    setTriggerGetSites(true);
  };

  const handleOnCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleOnChange = event => {
    const {
      target: { name, value, checked = false },
    } = event;

    if (name === 'isActive') {
      setSelectedRow({ ...selectedRow, [name]: checked });
    } else {
      setSelectedRow({ ...selectedRow, [name]: value });
    }

    setHasChanges(true);
  };

  const NoRowsOverlayMessage = () => (
    <Box mt={10}>
      <GridNoRowMessage message="No sites found. Press Refresh to get the latest data." />
    </Box>
  );

  const getNumNewSites = React.useCallback(
    () => sitesData.filter(s => s.isNew).length,
    [sitesData],
  );

  const getAlertMessage = () => {
    const numSites = getNumNewSites();
    const msg1 =
      numSites > 1
        ? t('new sites have been found')
        : t('new site has been found');
    const msg2 = t(
      'Sites must be enabled in order for their sensors to be available',
    );

    return `${numSites} ${msg1}. ${msg2}`;
  };

  const dismissAllNewSites = () => {
    sitesData
      .filter(site => site.isNew)
      .forEach(site => {
        dispatchAPI(
          VineaNovaActions.api.v1.sites.put.request({
            postBody: {
              SiteID: site.id,
              Location: site.location,
              VineyardID: site.vineyardID,
              Active: site.isActive,
              SiteName: site.siteName,
              FriendlySiteName: site.FriendlySiteName,
              ts: site.ts,
            },
          }),
        );
      });

    setTriggerGetSites(true);
  };

  const handleRefresh = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.sites.get.request({
        queryParams: {
          DataConnectorID: pathParamId,
          TriggerSync: true,
        },
      }),
    );
  };

  React.useEffect(() => {
    // if we got to this component somehow but it does not support sites,
    // navigate back to the overview tab
    if (!tabIsLoading && !hasSites) {
      navigate(`/connections/${pathParamId}/more`);
    }
  }, [tabIsLoading, hasSites, navigate, pathParamId]);

  React.useEffect(() => {
    setShowNewSitesAlert(getNumNewSites() > 0);
  }, [getNumNewSites]);

  React.useEffect(() => {
    // temporary until we have better identity search functionality
    dispatchAPI(
      VineaNovaActions.api.v1.searchIdentity.post.request({
        postBody: {
          IdentityTypeID: IdentityTypeIds.VINEYARD,
          FindNonActive: true,
        },
      }),
    );

    dispatchAPI(
      VineaNovaActions.api.v1.sites.get.request({
        queryParams: {
          DataConnectorID: pathParamId,
        },
      }),
    );
  }, [dispatchAPI, pathParamId]);

  React.useEffect(() => {
    if (triggerGetSites && !sitesLoading && sitesLoaded) {
      dispatchAPI(
        VineaNovaActions.api.v1.sites.get.request({
          queryParams: {
            DataConnectorID: pathParamId,
          },
        }),
      );
      setTriggerGetSites(false);
      setDrawerOpen(false);
    }
  }, [triggerGetSites, sitesLoading, sitesLoaded, dispatchAPI, pathParamId]);

  return (
    <StyledRoot noRows={noRows}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper elevation={0}>
            {showNewSitesAlert && (
              <Box px={2} pt={2}>
                <Alert
                  variant="outlined"
                  severity="info"
                  action={
                    <>
                      <VineaButton
                        variant="text"
                        color="secondary"
                        onClick={dismissAllNewSites}
                      >
                        {t('Dismiss All')}
                      </VineaButton>
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setShowNewSitesAlert(false)}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    </>
                  }
                >
                  {getAlertMessage()}
                </Alert>
              </Box>
            )}

            <Box
              display="flex"
              flex={1}
              flexDirection="row"
              justifyContent="space-between"
              p={2}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6" color="textPrimary">
                  {t('Sites')}
                </Typography>
              </Box>

              <Box display="inline-flex">
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={activeOnly}
                      onChange={() => setActiveOnly(!activeOnly)}
                    />
                  }
                  label={
                    <Typography variant="caption">
                      {t('Active only')}
                    </Typography>
                  }
                />
                <VineaButton
                  variant="text"
                  color="secondary"
                  sx={{ marginRight: 1 }}
                  onClick={handleRefresh}
                >
                  {t('Refresh')}
                </VineaButton>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ height: 450 }}>
            <GridWithFilter
              data={gridXData}
              onRowClick={handleOnRowClick}
              rowHeight={30}
              autoPageSize
              hideFooterSelectedRowCount
              components={{
                NoRowsOverlay: NoRowsOverlayMessage,
              }}
              actionType="SITES_GRID_COLUMN_UPDATE"
              gridColumnState={dataGridColumnsPreferences.sitesDataGrid}
              advancedSearch={false}
            />
          </Paper>
        </Grid>
      </Grid>
      <SitesDrawer
        isOpen={drawerOpen}
        data={selectedRow}
        handleOnSave={handleOnSave}
        handleOnClose={handleOnCloseDrawer}
        handleOnChange={handleOnChange}
        hasChanges={hasChanges}
        dataConnectionName={dataConnectionName}
        lkpVineyardNames={lkpVineyardNames}
      />
    </StyledRoot>
  );
};

SitesGrid.propTypes = {
  pathParamId: PropTypes.number.isRequired,
  hasSites: PropTypes.bool.isRequired,
  tabIsLoading: PropTypes.bool.isRequired,
  dataConnectionName: PropTypes.string.isRequired,
};
