/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { FieldNameSelect } from '../../../components/Select';
import { getLookupBlockVintageStatus } from '../../../redux/selectors';

export const VintageStatusDropdown = ({ id, name, value, onChange, rowId }) => {
  const blockVintageStatusOptions = useSelector(getLookupBlockVintageStatus);

  const handleOnValueChange = evt => {
    if (onChange) {
      onChange(evt, rowId);
    }
  };

  return (
    <FieldNameSelect
      sx={{
        width: '90px',
      }}
      id={id}
      value={value}
      onChange={handleOnValueChange}
      displayLabel={false}
      options={blockVintageStatusOptions}
      data-testid="selectwrapper"
      inputProps={{
        name,
        'data-testid': 'vintagedropdown',
      }}
    />
  );
};
