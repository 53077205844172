import React, { useMemo } from 'react';
import { VineaNovaSelectors, VineaHooks } from 'vineanova-redux-artifacts';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Paper, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { VineaButton } from '../../../components/VineaButton';
import {
  areLookupsLoading,
  getUserPreferences,
} from '../../../redux/selectors';
import { NotesGridView } from './NotesGridView';
import { useTrackingHook } from '../Hooks/useTrackingHook';
import { NoteDrawer } from '../Drawers/NoteDrawer';
import { dateFormat } from '../../../constants';

export const NotesListContainer = ({ isVineyardBlockScreen = false }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [isNewNote, setIsNewNote] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const {
    vintageBlockSelected,
    isLoadingData,
    handleOnUpdateNotesData,
    handleOnDeleteCropBlockNote,
    searchFilterData,
  } = useTrackingHook();

  const { vineyardBlockId, vintageID } = searchFilterData;

  const buttonDisabled = useMemo(() => {
    if (isVineyardBlockScreen) return false;
    else return !isEmpty(selectionModel) || isEmpty(vintageBlockSelected.block);
  }, [isVineyardBlockScreen, selectionModel, blockSelected]);

  const cropBlockNotesData = useSelector(
    VineaNovaSelectors.getCropBlockNotesEntityData,
  );

  const isLoadingLookups = useSelector(areLookupsLoading);
  const { trackingDataGrid, basicSettings } = useSelector(state =>
    getUserPreferences(state),
  );

  const { vineyard: vineyardSelected, block: blockSelected } =
    vintageBlockSelected;

  const subTitle1 = `${t('Notes for all measure instances for')} ${
    vineyardSelected || ''
  } ${blockSelected ? '/' : ''} ${blockSelected || ''}`;

  const handleOnRowClick = cellProps => {
    const { row } = cellProps;

    setSelectedRow({ ...row });
    setIsDrawerOpen(true);
  };

  const handleOnChangeNote = event => {
    const {
      target: { name, value },
    } = event;
    if (name === 'date') {
      setSelectedRow({ ...selectedRow, noteDate: value, hasChanges: true });
    } else {
      setSelectedRow({ ...selectedRow, [name]: value, hasChanges: true });
    }
  };

  const handleOnAddNote = () => {
    setIsNewNote(true);
    const newRow = {
      vineyardBlockId,
      vintageId: vintageID,
      blockVintageId: 0,
      measure: null,
      isPrivate: false,
      note: null,
      noteDate: format(new Date(), dateFormat),
      noteImage: null,
      isEditable: true,
    };

    setSelectedRow(newRow);

    setIsDrawerOpen(true);
  };

  const getNotesData = () => {
    return cropBlockNotesData.filter(d => d.noteImage || d.note !== '');
  };

  const handleOnCloseDrawer = () => {
    setIsNewNote(false);
    setIsDrawerOpen(false);
    setSelectedRow({});
  };

  const handleOnCancelViewNote = () => {
    handleOnCloseDrawer();
  };

  const handleOnDeleteNote = () => {
    handleOnDeleteCropBlockNote(selectedRow);
    handleOnCloseDrawer();
  };

  const handleOnSaveNote = () => {
    handleOnUpdateNotesData(selectedRow, isNewNote);
    handleOnCloseDrawer();
  };

  const handleOnNoteImageUpload = imageSet => {
    if (imageSet && imageSet.base64) {
      setSelectedRow({
        ...selectedRow,
        noteImage: imageSet.base64,
        hasChanges: true,
      });
    }
  };

  const handleOnDeleteNoteImage = () => {
    setSelectedRow({
      ...selectedRow,
      noteImage: null,
      hasChanges: true,
    });
  };

  const handleOnTogglePrivate = () => {
    setSelectedRow({
      ...selectedRow,
      isPrivate: !selectedRow.isPrivate,
      hasChanges: true,
    });
  };

  const handleOnAdvancedSearch = () => {
    dispatchAPI({
      type: 'BASIC_SETTINGS_UPDATE',
      payload: {
        ...basicSettings,
        filterPanelCollapsed: !basicSettings.filterPanelCollapsed,
      },
    });
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <NoteDrawer
        isOpen={isDrawerOpen}
        handleOnClose={handleOnCancelViewNote}
        handleOnDelete={handleOnDeleteNote}
        handleOnSave={handleOnSaveNote}
        handleOnChange={handleOnChangeNote}
        handleOnImageUpload={handleOnNoteImageUpload}
        handleOnDeleteImage={handleOnDeleteNoteImage}
        handleOnTogglePrivate={handleOnTogglePrivate}
        data={selectedRow}
        isEdit={!isNewNote}
        vineyardSelected={vineyardSelected}
        blockSelected={blockSelected}
      />

      <Box
        mx={1}
        my={2}
        display="flex"
        justifyContent="space-between"
        data-testid="action-buttons"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          {(vineyardSelected || blockSelected) && (
            <>
              <InfoIcon />
              <Typography
                variant="body2"
                sx={{
                  padding: 2,
                }}
              >
                {subTitle1}
              </Typography>
            </>
          )}
        </Box>
        <Box>
          <VineaButton
            color="secondary"
            variant="contained"
            sx={{
              marginRight: theme.spacing(1),
            }}
            minWidth={100}
            onClick={handleOnAddNote}
            disabled={buttonDisabled}
          >
            {t('Add Note')}
          </VineaButton>
        </Box>
      </Box>
      <Box display="flex" flex={1} mx={1} sx={{ height: '100%' }}>
        <Grid
          container
          spacing={0}
          data-testid="organisation-results"
          direction="column"
        >
          <Grid
            item
            xs={12}
            sx={{
              flex: 1,
            }}
          >
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                height: 500,
              }}
            >
              <Grid container xs={12} direction="column">
                <NotesGridView
                  data={getNotesData()}
                  isLoading={isLoadingLookups || isLoadingData}
                  handleOnRowClick={handleOnRowClick}
                  handleOnCellClick={f => f}
                  onSelectionModelChange={newSelection => {
                    setSelectionModel(newSelection.selectionModel);
                  }}
                  selectionModel={selectionModel}
                  actionType="TRACKING_GRID_COLUMN_UPDATE"
                  gridColumnState={trackingDataGrid.notes}
                  handleOnAdvancedSearch={handleOnAdvancedSearch}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
