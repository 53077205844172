import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { ThreeCogs } from '../../components/ThreeCogs';

export const Spinners = () => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      sm={8}
      sx={{
        flexGrow: 1,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
      }}
    >
      <Typography component="p" variant="h3">
        Cog Spinners
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            '& > button': {
              margin: theme.spacing(1),
            },
          }}
        >
          <FormControl
            sx={{
              margin: theme.spacing(1),
              minWidth: 300,
            }}
          >
            <ThreeCogs />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
