/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty, filter } from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';

import { VineaButton } from '../../../components/VineaButton';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { CropSettingsSchema } from './validations';
import { syncValidator } from '../../../utils/validator';
import { DiscardDialog } from '../../../components/Dialog';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';

const StyledDiv = styled('div')(() => ({
  marginTop: 1,
}));

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

const StyledDrawerControl = styled('div')(() => ({
  width: 350,
}));

export const OtherAttributeDrawer = ({
  open,
  onClose,
  data,
  handleOnSave,
  blockSelected,
  vineyardSelected,
  usedAttributeOptions,
  usedAttributeInstance,
  lkpCropSettingOption = [],
  lkpCropSettingInstance = [],
}) => {
  const theme = useTheme();
  const [state, setState] = React.useState(open);
  const { t } = useTranslation();
  const { isEdit = false, isAdd = false } = data;
  const [selectedRow, setSelectedRow] = React.useState(data);
  const [validationErrors, setValidationErrors] = React.useState({});
  const [cropSettingOptionsByInstance, setCropSettingOptionsByInstance] =
    React.useState(null);
  const [cropSettingInstanceOptions, setCropSettingInstanceOptions] =
    React.useState([]);

  const { cropSettingInstance, cropSettingOptionID, cropSettingInstanceID } =
    selectedRow || {};

  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(null);

  React.useEffect(() => {
    setSelectedRow(data);
    const { cropSettingInstanceID: instanceId, isAdd: Add } = data;
    if (Add) {
      const attributeOptions = filter(lkpCropSettingInstance, x => {
        return !usedAttributeInstance.includes(x.cropSettingInstance);
      });
      setCropSettingInstanceOptions(attributeOptions);
    }
    const options = lkpCropSettingOption.filter(
      f => f.cropSettingInstanceID === instanceId,
    );

    setCropSettingOptionsByInstance(options);
  }, [
    data,
    lkpCropSettingOption,
    usedAttributeOptions,
    lkpCropSettingInstance,
    usedAttributeInstance,
  ]);

  React.useEffect(() => {
    setState(open);
  }, [open]);

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const onSave = () => {
    const currentErrors = syncValidator(CropSettingsSchema)(selectedRow);
    console.log('currentErrors', currentErrors);
    setValidationErrors(currentErrors);
    if (isEmpty(currentErrors)) {
      handleOnSave(selectedRow);
    }
  };

  const handleOnChange = event => {
    const {
      target: { value, name },
    } = event;

    setSelectedRow({
      ...selectedRow,
      [name]: value,
      cropSettingOptionID: name === 'cropSettingInstanceID' ? 0 : value,
      hasChanges: true,
    });

    if (name === 'cropSettingInstanceID') {
      const options = lkpCropSettingOption.filter(
        f => f.cropSettingInstanceID === value,
      );
      if (isAdd) {
        const availableAttributeOptions = filter(options, x => {
          return !usedAttributeOptions.includes(x.cropSettingOption);
        });
        setCropSettingOptionsByInstance(availableAttributeOptions);
      } else {
        setCropSettingOptionsByInstance(options);
      }
    }
  };

  const handleOnDiscard = () => {
    const { hasChanges } = selectedRow;
    if (hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    onClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    onSave();
  };

  const vineyardBlockSelected = `${vineyardSelected || ''} ${
    blockSelected ? '/' : ''
  } ${blockSelected || ''}`;

  const list = () => (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleOnClickAway}>
      <StyledDrawerControl>
        <StyledDiv
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography
                sx={{
                  padding: theme.spacing(2),
                }}
                variant="body1"
              >
                {vineyardBlockSelected}
              </Typography>
            </Box>
            <Box>
              <IconButton
                aria-label="toggle sidebar"
                onClick={handleOnDiscard}
                onMouseDown={() => {}}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {isEdit && (
            <Box mb={1}>
              <Typography
                variant="subtitle2"
                sx={{
                  paddingLeft: theme.spacing(2),
                }}
              >
                {t('Edit Attribute')}
              </Typography>
            </Box>
          )}

          {isAdd && (
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  paddingLeft: theme.spacing(2),
                }}
              >
                {t('Add New Attribute')}
              </Typography>
              {/* <Typography className={classes.measureValue} component="span">
                {measure}
              </Typography> */}
            </Box>
          )}

          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />
            {isEdit && (
              <Box my={2}>
                <Typography
                  sx={{
                    paddingLeft: theme.spacing(2),
                    paddingBottom: theme.spacing(2),
                  }}
                  component="h5"
                >
                  {cropSettingInstance}
                </Typography>
              </Box>
            )}
            {isAdd && (
              <>
                <Box px={2} data-testid="pickercropSettingInstanceID">
                  <VineaAutoComplete
                    value={cropSettingInstanceID}
                    onChange={handleOnChange}
                    inlineLabel
                    options={cropSettingInstanceOptions}
                    labelVariant="body1"
                    label={t('Attribute')}
                    name={t('Attribute')}
                    displayLabel
                    inputProps={{
                      name: 'cropSettingInstanceID',
                    }}
                    data-testid="cropSettingInstanceID-picker"
                    fullWidth
                    error={validationErrors.cropSettingInstanceID}
                  />
                </Box>
              </>
            )}
            <Box px={2} data-testid="pickercropSettingOptionID">
              <VineaAutoComplete
                value={cropSettingOptionID}
                onChange={handleOnChange}
                inlineLabel
                options={cropSettingOptionsByInstance || []}
                labelVariant="body1"
                label={t('Value')}
                name={t('Value')}
                displayLabel
                inputProps={{
                  name: 'cropSettingOptionID',
                }}
                data-testid="cropSettingOptionID-picker"
                fullWidth
                error={validationErrors.cropSettingOptionID}
              />
            </Box>
            <StyledButtonControlDiv>
              <div>
                <VineaButton
                  color="success"
                  onClick={onSave}
                  variant="contained"
                  minWidth={100}
                  disabled={1 === 0}
                >
                  {t('Save')}
                </VineaButton>
              </div>
            </StyledButtonControlDiv>
          </ErrorBoundary>
        </StyledDiv>
        <DiscardDialog
          open={discardDialogOpen}
          onClose={() => setDiscardDialogOpen(false)}
          handleSaveChanges={handleOnDiscardSaveChanges}
          handleDiscardChanges={handleOnDiscardConfirm}
        />
      </StyledDrawerControl>
    </ClickAwayListener>
  );

  return (
    <Drawer anchor="right" open={state} onClose={toggleDrawer}>
      {list('right')}
    </Drawer>
  );
};

OtherAttributeDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    isEdit: PropTypes.bool,
    isAdd: PropTypes.bool,
    measure: PropTypes.string,
    cropSettingOptionID: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    cropSettingInstanceID: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    phenologyMeasureID: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  blockSelected: PropTypes.string,
  vineyardSelected: PropTypes.string,
  lkpCropSettingOption: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  lkpCropSettingInstance: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
};

OtherAttributeDrawer.defaultProps = {
  vineyardSelected: '',
  blockSelected: '',
  lkpCropSettingOption: [],
  lkpCropSettingInstance: [],
};
