/**
 * @module Sagas/updateIdentities
 * @desc create identity, classification and references from organiztions/vineyards/name/create new
 */

import { put, call, takeLatest, all } from 'redux-saga/effects';
import { reducers, sagaActionTypes } from '../../constants';
import { commonActionService } from './commonActionService';
import logger from '../../utils/winstonLogger';

export function* commonUpdateActionCreator(action) {
  const { payload = {}, type } = action;
  try {
    yield put({
      type: 'FETCHING',
      name: reducers.formWrite,
    });
    const response = yield call(commonActionService(type), payload);
    yield put({
      type: 'SUCCESS',
      payload: response,
      name: reducers.formWrite,
    });
  } catch (err) {
    logger.error(err);
    yield put({
      type: 'ERROR',
      payload: err,
      name: reducers.formWrite,
    });
  }
}

export default function* root() {
  logger.debug('Inside Saga Root');
  yield all([
    takeLatest(sagaActionTypes.UPDATE_IDENTITIES, commonUpdateActionCreator),
  ]);
}
