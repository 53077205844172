import * as Yup from 'yup';

export const BlockRowSchema = Yup.object().shape({
  plantedUnits: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'Planted Units must be numbers'),
  actualUnits: Yup.string()
    .typeError('Actual units must be numbers')
    .nullable()
    .matches(/^[0-9]+$/, 'Actual units must be numbers'),
  postCount: Yup.string()
    .typeError('Post count must be numbers')
    .nullable()
    .matches(/^[0-9]+$/, 'Post count must be numbers'),
  rowLength: Yup.number()
    .typeError('Row length must be numbers or decimals')
    .nullable(),
  rowNumber: Yup.string().nullable().required('Row number is required'),
});

export const BlockRowAddSchema = Yup.object().shape({
  inRange: Yup.boolean().required('Required'),
  plantedUnits: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'Must be numbers'),
  actualUnits: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'Must be numbers'),
  postCount: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'Must be numbers'),
  rowLength: Yup.number()
    .typeError('Must only be numbers or decimals')
    .nullable(),
  startRow: Yup.string()
    .when('inRange', {
      is: val => {
        return val === true;
      },
      then: Yup.string().matches(
        /^[a]+$/,
        'Row number is aleady created in this range',
      ),
      otherwise: Yup.string().notRequired(),
    })
    .nullable()
    .required('Required'),
  endRow: Yup.number().typeError('Must only be numbers or decimals').nullable(),
});

export const BlockRowUpdateSchema = Yup.object().shape({
  rowNumber: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  plantedUnits: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'Must be a number'),
  actualUnits: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'Must be a number'),
  postCount: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'Must be number'),
  rowLength: Yup.number()
    .typeError('Must only be numbers or decimals')
    .nullable(),
});
