/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
// @ts-nocheck
import React, { useMemo } from 'react';
// @ts-ignore
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { styled } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import isEqual from 'lodash/isEqual';
import { debounce, isNil, sortBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { FilterScreen } from '../../components/TrackingComponents';
import {
  reducers,
  actionTypes,
  featureFlags,
  commonFieldSelectOption,
} from '../../constants';
import logger from '../../utils/winstonLogger';
import { useSamplesHook } from '../Samples/hooks/useSamplesHook';
import { useTrackingHook } from './Hooks/useTrackingHook';
import { TrackingDetails } from './TrackingDetails';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import withFeatureFlagHOC from '../../hooks/withFeatureFlagHOC';
import { getUserPreferences } from '../../redux/selectors';

const StyledRoot = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  '& #tabpanelcontentwrapper': {
    padding: 0,
    height: '100%',
  },
  '& .react-swipeable-view-container': { height: '100%' },
  '& .tabpanel': { height: '100%' },
}));

const TrackingSearchResult = () => {
  const dispatchAPI = useDispatch();
  // @ts-ignore
  const { name: pathParam } = useParams();
  const { basicSettings } = useSelector(state => getUserPreferences(state));

  logger.debug('TrackingSearchContainer.jsx', { pathParam });

  /** Selectors */
  const { lkpGrowerSubRegion, allLookupVintages, latestVintageIdValue } =
    useSamplesHook();

  const {
    searchFilterData,
    handleOnFetchPhenologyData,
    handleOnFetchVintageData,
    handleOnFetchNotesData,
    handleOnFetchOtherDetailsData,
  } = useTrackingHook();

  const { subRegionID, vintageID, vineyardID, vineyardBlockId } =
    searchFilterData;

  const vineyardOptions = commonFieldSelectOption.concat(
    sortBy(
      useSelector(VineaNovaSelectors.getSearchTrackingVineyardsEntityData).map(
        e => ({
          id: e.vineyardID,
          key: e.vineyardID,
          value: e.vineyardName,
        }),
      ),
      'value',
    ),
  );

  const subRegionOptions = useMemo(() => {
    if (
      !isNil(basicSettings.defaultRegionID) &&
      basicSettings.defaultRegionID > 0
    ) {
      return commonFieldSelectOption.concat(
        lkpGrowerSubRegion.filter(
          f => f.growingRegionID === basicSettings.defaultRegionID,
        ),
      );
    } else return lkpGrowerSubRegion;
  }, [basicSettings.defaultRegionID]);

  const blocks = useSelector(
    VineaNovaSelectors.getSearchTrackingBlockVintagesEntityData,
  ).map(e => ({ ...e, id: e.vineyardBlockID }));

  const { isLoading: blocksIsLoading } = useSelector(
    VineaNovaSelectors.getSearchTrackingBlockVintagesEntityMeta,
  );

  const { setIsLocked, isLocked } = useTabChangeLock();

  /** Dispatches */
  const onSearchTrackings = debounce(() => {
    if (vineyardBlockId) {
      handleOnFetchVintageData(vineyardBlockId);
      handleOnFetchPhenologyData(vineyardBlockId);
      handleOnFetchNotesData(vineyardBlockId);
      handleOnFetchOtherDetailsData(vineyardBlockId);
    }
  }, 500);

  const onSearchVineyards = debounce(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.searchTrackingVineyards.post.request({
        postBody: {
          SubRegionID: subRegionID,
          VintageID: vintageID,
        },
      }),
    );
  }, 100);

  const onSearchBlockVintages = debounce(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.searchTrackingBlockVintages.post.request({
        postBody: {
          VintageID: vintageID,
          SubRegionID: subRegionID,
          VineyardID: vineyardID,
        },
      }),
    );
  }, 100);

  const onUpdateFilterData = (data: any) => {
    if (isLocked) return;

    const { clearRows, ...payload } = data;
    if (!isEqual(payload, searchFilterData)) {
      dispatchAPI({
        type: actionTypes.updateData,
        name: reducers.trackingSearchFilter,
        payload,
      });
      dispatchAPI(VineaNovaActions.api.v1.cropBlockVintage.get.cleardata());
      dispatchAPI(VineaNovaActions.api.v1.cropBlockPhenology.get.cleardata());
      dispatchAPI(VineaNovaActions.api.v1.cropBlockNotes.get.cleardata());
      dispatchAPI(VineaNovaActions.api.v1.cropBlockSetting.get.cleardata());
    }
  };

  const onBlockRowClick = (evt: any) => {
    const { id = null } = evt;
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.trackingSearchFilter,
      payload: { ...searchFilterData, vineyardBlockId: id },
    });
  };

  /** useEffects */

  // if the currently selected vineyard is no longer returned by the search
  // (e.g. it does not exist in the newly selected subregion), set it to Select
  React.useEffect(() => {
    const selectedVineyardID =
      basicSettings.defaultVineyardID === 0
        ? null
        : basicSettings.defaultVineyardID;
    if (vintageID && subRegionID)
      onUpdateFilterData({
        ...searchFilterData,
        vineyardID: selectedVineyardID,
      });
  }, [vintageID, subRegionID]);

  React.useEffect(() => {
    if (isLocked) {
      setIsLocked(false);
    }
    if (vineyardID === null) return;

    if (!vineyardOptions.some(e => e.id === vineyardID)) {
      dispatchAPI({
        type: actionTypes.updateData,
        name: reducers.trackingSearchFilter,
        payload: { ...searchFilterData, vineyardID: 0 },
      });
    }
  }, [vineyardOptions, isLocked, setIsLocked]);

  React.useEffect(() => {
    if (isLocked) return;

    // if the subregion or vintage change, refresh list of vineyards
    if (subRegionID && vintageID) {
      onSearchVineyards();
    } else {
      if (vineyardID) {
        dispatchAPI({
          type: actionTypes.updateData,
          name: reducers.trackingSearchFilter,
          payload: { ...searchFilterData, vineyardID: 0 },
        });
      }
      dispatchAPI(
        VineaNovaActions.api.v1.searchTrackingVineyards.post.cleardata(),
      );
    }
  }, [vintageID, subRegionID]);

  React.useEffect(() => {
    if (isLocked) return;

    // if any fields change, refresh blocks grid
    if (vintageID && subRegionID && vineyardID) {
      onSearchBlockVintages();
    } else {
      if (vineyardBlockId) {
        dispatchAPI({
          type: actionTypes.updateData,
          name: reducers.trackingSearchFilter,
          payload: { ...searchFilterData, vineyardBlockId: null },
        });
      }
      dispatchAPI(
        VineaNovaActions.api.v1.searchTrackingBlockVintages.post.cleardata(),
      );
    }
  }, [vintageID, subRegionID, vineyardID]);

  React.useEffect(() => {
    if (isLocked) return;

    onSearchTrackings();
  }, [vineyardBlockId]);

  return (
    <>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        data-testid="trackingsearch-results"
        sx={{ height: '100%' }}
      >
        <StyledRoot data-testid="samplingcontainer">
          <Box sx={{ height: '100%' }}>
            <FilterScreen
              // className={classes.dataGridControl}
              // url={url}
              sampleData={blocks}
              filterType="Tracking"
              search={onSearchTrackings}
              lookupVintages={allLookupVintages}
              lookupRegions={subRegionOptions}
              lookupVintageLatestId={latestVintageIdValue}
              selectedSubRegionIdValue={basicSettings.defaultSubRegionID}
              selectedVineyardIdValue={basicSettings.defaultVineyardID}
              onUpdateFilterData={onUpdateFilterData}
              searchFilterData={searchFilterData}
              vineyardList={vineyardOptions}
              handleOnBlockRowClick={onBlockRowClick}
              isLoading={blocksIsLoading}
            />
          </Box>
          <Box display="flex" flex={1} ml={2} pb={3}>
            <TrackingDetails />
          </Box>
        </StyledRoot>
      </Box>
    </>
  );
};

export default withFeatureFlagHOC(featureFlags.TRACKING)(TrackingSearchResult);
