import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { IdentityTypeIds } from '../constants';
import { useSelector } from 'react-redux';
import { MetaDataType } from '../containers/Jobs/interfaces/jobInterfaces';

export interface FetchSearchIdentityProps {
  identityTypeID?: number;
  findNonActive?: boolean;
}

export const useFetchSearchIdentity = ({
  identityTypeID,
  findNonActive,
}: FetchSearchIdentityProps) => {
  const dispatchAPI = useDispatch();
  const searchIdentity: any[] = useSelector(
    VineaNovaSelectors.getSearchIdentityEntityData,
  );
  const {
    isLoading: searchIdentityLoading,
    isLoaded: searchIdentityLoaded,
    hasError: searchIdentityHasError,
    error: searchIdentityError,
  } = useSelector(
    VineaNovaSelectors.getSearchIdentityEntityMeta,
  ) as MetaDataType;

  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.searchIdentity.post.request({
        postBody: {
          IdentityTypeID: IdentityTypeIds.VINEYARD,
          FindNonActive: true,
        },
      }),
    );
  }, [identityTypeID, findNonActive]);

  return {
    searchIdentity,
    searchIdentityLoading,
    searchIdentityLoaded,
    searchIdentityHasError,
    searchIdentityError,
  };
};
