/* eslint-disable no-undef */
/* eslint-disable import/extensions */
import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import {
  useTheme,
  useMediaQuery,
  Popover,
  Avatar,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BreadCrumb } from '../BreadCrumb';
import { IdentityTypeIds, languageList } from '../../constants';
// eslint-disable-next-line import/no-unresolved
import { ILanguageList, IMenuList } from '../../constants/datatypes';
import logo from '../../assets/vinealogo.png';
import { HistoryDrawer } from '../HistoryComponent';
import { QuickSearchIdentityDrawer } from '../QuickSearchIdentity';
import useUserResponsiveLayout from '../../hooks/useUserResponsiveLayout';
import { useProfileImageUpload } from '../../containers/VineyardIdentity/hooks/useProfileImageUpload';

type HeaderProps = {
  handleToggleDrawer: any;
  isAuthenticated: boolean;
  login: any;
  logout: any;
  handleLanguageChange: any;
  selectedLanguageValue: string;
  handleOnSettingsClick: any;
};

const StyledLogo = styled('img')(() => ({
  width: '30px',
  height: '30px',
  marginLeft: '12px',
  marginRight: '24px',
  marginTop: '4px',
}));

const Header = (props: HeaderProps) => {
  const { t } = useTranslation();
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
  const theme = useTheme();
  const {
    handleToggleDrawer,
    isAuthenticated,
    login,
    logout: signOut,
    handleOnSettingsClick,
  } = props;

  const [profileImageData, setProfileImageData] = React.useState(null);
  const [historyDrawerOpen, setHistoryDrawerOpen] = React.useState(false);
  const [quickSearchDrawerOpen, setQuickSearchDrawerOpen] =
    React.useState(false);

  const { handleLanguageChange, selectedLanguageValue } = props;
  const [openProfile, setOpenProfile] = React.useState<
    HTMLElement | null | undefined
  >(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<
    HTMLElement | null | undefined
  >(null);

  // Display user details popover on hover profile icon
  const [popoverAnchorE1, setPopoverAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const handlePopoverOpen = (event: any) => {
    setPopoverAnchorEl(event.currentTarget);
    setTimeout(() => {
      setPopoverOpen(false);
    }, 1000);
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    setPopoverOpen(false);
  };
  const {
    isBelowLaptop: isTabletDevice,
    userIdentityID,
    userEmail,
    userOrganisation,
  } = useUserResponsiveLayout();

  const {
    imageDataUrl: profileImageDataUrl,
    imageIdentityID: profileImageIdentityID,
  } = useProfileImageUpload(IdentityTypeIds.PERSON, userIdentityID);

  React.useEffect(() => {
    if (profileImageIdentityID === userIdentityID)
      setProfileImageData(profileImageDataUrl);
  }, [userIdentityID, profileImageDataUrl, profileImageIdentityID]);

  const handleClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    // @ts-ignore
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleOnLogout = () => {
    setOpenProfile(null);
    signOut();
  };

  const handleSettingsClick = () => {
    handleOnSettingsClick();
    setOpenProfile(null);
  };

  const handleOnLanguageClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (key: any) => {
    handleLanguageChange(key);
    setMenuAnchorEl(null);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const languageListTyped: ILanguageList[] = languageList;
  const menuList: IMenuList[] = [
    { title: 'Profile', onClick: handleCloseProfile, test: 'profile' },
    { title: 'Settings', onClick: handleSettingsClick, test: 'settings' },
    { title: 'Logout', onClick: handleOnLogout, test: 'logout' },
  ];

  let defaultLanguage: string = 'English';
  let languageValue: string = 'English';

  languageValue =
    languageListTyped?.find(
      (f: ILanguageList) => f.key === selectedLanguageValue,
    )?.value || defaultLanguage;

  defaultLanguage =
    languageListTyped?.find((f: ILanguageList) => f.key === 'en')?.value ||
    defaultLanguage;

  return (
    <AppBar
      position="fixed"
      data-testid="appbar"
      sx={{
        // @ts-ignore
        backgroundColor: theme.palette.primary.main,
        '& button': {
          color: theme.palette.primary.contrastText,
        },
        '& .MuiAppBar-positionFixed': {
          padding: 0,
        },
        maxHeight: '50px',
      }}
    >
      <Toolbar disableGutters sx={{ '& .MuiToolbar-gutters	': { padding: 0 } }}>
        <Link to="/">
          <StyledLogo src={logo} alt="logo" />
        </Link>
        {false && (
          <IconButton
            aria-label="Open drawer"
            onClick={handleToggleDrawer}
            sx={{
              marginLeft: theme.spacing(2),
              marginRight: theme.spacing(2),
            }}
            data-testid="opendrawericon"
            size="large"
          >
            <MenuIcon />
          </IconButton>
        )}
        <BreadCrumb />
        {!isTabletDevice && (
          <IconButton
            aria-label="quick search"
            aria-controls="quick-search-menu"
            aria-haspopup="true"
            onClick={() => {
              setQuickSearchDrawerOpen(true);
              setHistoryDrawerOpen(false);
            }}
            data-testid="quick-search-icon"
            size="large"
            title="Quick Search"
          >
            <SearchIcon />
          </IconButton>
        )}
        {!isTabletDevice && (
          <IconButton
            aria-label="history"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() => {
              setHistoryDrawerOpen(true);
              setQuickSearchDrawerOpen(false);
            }}
            data-testid="historyicon"
            size="large"
            title="History"
          >
            <HistoryIcon />
          </IconButton>
        )}
        {lgUp ? (
          <Button
            aria-label="language"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOnLanguageClick}
            startIcon={<LanguageIcon />}
            data-testid="languageicon"
          >
            {languageValue || defaultLanguage}
          </Button>
        ) : (
          <IconButton
            aria-label="language"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOnLanguageClick}
            data-testid="languageicon"
            size="large"
          >
            <LanguageIcon />
          </IconButton>
        )}

        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleClose}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {languageList.map(data => (
            <MenuItem
              value={data.key}
              key={data.key}
              onClick={() => handleMenuItemClick(data.key)}
              data-testid={data.key}
            >
              {data.value}
            </MenuItem>
          ))}
        </Menu>
        <IconButton size="large">
          <Badge badgeContent={4} color="secondary">
            <NotificationsIcon data-testid="notificationicon" />
          </Badge>
        </IconButton>
        {!isAuthenticated && (
          <Button
            color="primary"
            variant="contained"
            sx={{
              color: 'white',
              marginRight: '6px',
              marginLeft: '6px',
              boxShadow: 'none',
            }}
            onClick={login}
            data-testid="signin"
          >
            {t('Sign in')}
          </Button>
        )}
        {!!isAuthenticated && (
          <>
            <IconButton
              color="primary"
              aria-label="account"
              onClick={handleClickProfile}
              data-testid="icon-button"
              size="large"
              onMouseEnter={handlePopoverOpen}
            >
              {profileImageData ? (
                <Avatar
                  alt={'User Profile Image'}
                  src={profileImageData}
                  sx={{ width: 30, height: 30 }}
                />
              ) : (
                <PersonIcon data-testid="personicon" />
              )}
            </IconButton>
            <Popover
              id={'simple-popover'}
              anchorEl={popoverAnchorE1}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{ marginTop: '24px' }}
              open={popoverOpen}
            >
              <Typography sx={{ p: 2 }}>
                {`${userEmail} - Person #${userIdentityID} - ${userOrganisation}`}
              </Typography>
            </Popover>

            <Menu
              id="simple-menu"
              anchorEl={openProfile}
              keepMounted
              open={Boolean(openProfile)}
              onClose={handleCloseProfile}
              elevation={0}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {menuList.map(menu => (
                <MenuItem
                  key={menu.title}
                  onClick={menu.onClick}
                  data-testid={menu.test}
                >
                  {t(menu.title)}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Toolbar>
      <>
        <HistoryDrawer
          open={historyDrawerOpen}
          setOpen={setHistoryDrawerOpen}
        />
      </>
      <>
        <QuickSearchIdentityDrawer
          open={quickSearchDrawerOpen}
          setOpen={setQuickSearchDrawerOpen}
        />
      </>
    </AppBar>
  );
};

Header.propTypes = {
  handleToggleDrawer: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
  selectedLanguageValue: PropTypes.string.isRequired,
};

export default Header;
