import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Alert } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { VineaAlert } from '../../../components/VineaAlert';
import { FieldLabelInput, VineaTextField } from '../../../components/TextField';
import ColorPickerComponent from '../../../components/ColorPickerComponent/ColorPickerComponent';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { addGenericReducer, types, activityState } from '../stateReducers';
import {
  getActivityGroup,
  getResourceTypeUnit,
  getCostingUnit,
  getWorkUnit,
  getDuration,
  getOrgDefaultBusinessUnit,
} from '../../../redux/selectors';
import { syncValidator } from '../../../utils/validator';
import { ActivitySchema } from '../validations';
import {
  apiTypes,
  commonGridColumns,
  commonGridHeaderNames,
  IdentityTypeIds,
  sagaActionTypes,
  vineaDetails,
} from '../../../constants';
import { VineaCommonGrid } from '../../../components/Grid';

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 300,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 300,
  '& .MuiFormControl-root': {
    minWidth: 300,
  },
  '& .MuiInputBase-input': {
    minWidth: 250,
  },
}));

const CreateNewActivity = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    onSaveStepData,
    step,
    stepData,
    calculateNameToCheck,
    matchingIdentities,
  } = props;
  const [formdata, dispatch] = useReducer(addGenericReducer, activityState);
  const activityGroupList = useSelector(state => getActivityGroup(state));
  const resourceTypeUnitList = useSelector(state => getResourceTypeUnit(state));
  const costingUnitList = useSelector(state => getCostingUnit(state));
  const workUnitList = useSelector(state => getWorkUnit(state));
  const durationList = useSelector(state => getDuration(state));
  const defaultBusinessUnitID = useSelector(state =>
    getOrgDefaultBusinessUnit(state),
  );
  const formWrite = useSelector(state => state.formWrite);
  const [alertType, setAlertType] = useState(false);
  const [inSavingMode, setSaveMode] = useState(false);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = useState(false);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [isIdentityTrigger, setIdentityTrigger] = useState(false);
  const defaultColour = '#C2C2C2';

  useImperativeHandle(ref, () => ({
    saveForm() {
      const validationErrors = syncValidator(ActivitySchema)(formdata);
      if (!isEmpty(validationErrors)) {
        dispatch({ type: types.ERROR, payload: validationErrors });
      } else {
        const data = {
          identityTypeID: IdentityTypeIds.ACTIVITY,
          activityCode: formdata.activityCode,
          activityDescription: formdata.activityDescription || '',
          isPlannable: formdata.activityIsPlannable || false,
          resourceUnitID: formdata.resourceUnitID,
          workUnitID: formdata.workUnitID,
          costingUnitID: formdata.costingUnitID,
          velocityDurationID: formdata.velocityDurationID,
          activityGroupID: formdata.activityGroupID,
          businessUnitID: defaultBusinessUnitID,
          colourHexCode: formdata.activityColor,
        };
        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.activity,
            methodType: apiTypes.POST,
          },
        });
        setSaveMode(true);
        setActionTriggered(true);
        setIdentityTrigger(true);
      }
    },
  }));

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleCheckboxChange = event => {
    const {
      target: { checked },
    } = event;
    dispatch({ type: types.UPDATE, payload: { activityIsPlannable: checked } });
  };

  const handleColorChange = color => {
    dispatch({ type: types.UPDATE, payload: { activityColor: color } });
  };

  const handleOnBlurOrgName = () => {
    const validationErrors = syncValidator(ActivitySchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  useEffect(() => {
    calculateNameToCheck(formdata);
  }, [formdata.activityCode]);

  useEffect(() => {
    const { data: formWriteData, hasError, isLoading } = formWrite;
    if (inSavingMode && !isLoading) {
      if (!hasError) {
        setSaveMode(false);
        onSaveStepData(
          { data: formWriteData, isSaving: false, isComplete: true },
          step,
        );
      } else {
        setSaveMode(false);
        onSaveStepData(
          {
            data: { identityId: null },
            isSaving: false,
            isComplete: false,
            hasError: true,
          },
          step,
        );
      }
    }
  }, [inSavingMode, formWrite, onSaveStepData, step]);

  useEffect(() => {
    dispatch({ type: types.UPDATE, payload: { activityColor: defaultColour } });
    dispatch({
      type: types.UPDATE,
      payload: {
        ...stepData,
        activityCode: stepData.activityCode,
        activityDescription: stepData.activityDescription,
        isPlannable: stepData.activityIsPlannable,
        resoruceUnitID: stepData.resourceUnitID,
        workUnitID: stepData.workUnitID,
        costingUnitID: stepData.costingUnitID,
        velocityDurationID: stepData.velocityDurationID,
        activityGroupID: stepData.activityGroupID,
        colourHexCode: formdata.activityColor,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { hasError, data, isLoaded } = formWrite;
    if (isLoaded && actionTriggered) {
      if (!hasError && !isEmpty(data) && isIdentityTrigger) {
        setIdentityTrigger(false);
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        setAlertType(true);
        const {
          data: { id, identityTypeID },
        } = formWrite;
        dispatchAPI({
          type: sagaActionTypes.FETCH_IDENTITIES,
          payload: {
            id,
            identityTypeId: identityTypeID,
          },
        });
      }
    }
  }, [
    formWrite,
    actionTriggered,
    isIdentityTrigger,
    enqueueSnackbar,
    t,
    dispatchAPI,
  ]);

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12}>
          <VineaAlert
            isOpen={!!errorInSubmit}
            onExit={() => setErrorInSubmit(false)}
          />
          <VineaAlert
            isOpen={alertType}
            onExit={() => setAlertType(false)}
            alertType="success"
            message="Activity Created Successfully"
          />
          <Box px={2}>
            {warningInSubmit && (
              <Collapse in={warningInSubmit}>
                <Alert
                  variant="standard"
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarningInSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Warning: Nothing to Update
                </Alert>
              </Collapse>
            )}
          </Box>
          <Stack
            direction="row"
            justifyContent="space-around"
            sx={{
              marginTop: theme.spacing(4),
            }}
          >
            <Box
              sx={{
                marginTop: theme.spacing(4),
              }}
            >
              <StyledFormBoxControl mt={1}>
                <FormControl>
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="name"
                    name="activityCode"
                    label={t('Activity Code')}
                    size="small"
                    inlineLabel
                    value={formdata.activityCode}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    error={formdata.errors.activityCode}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl sx={{ height: '100px' }}>
                  <VineaTextField
                    autoComplete="off"
                    id="description"
                    name="activityDescription"
                    label={t('Description')}
                    size="large"
                    inlineLabel
                    value={formdata.activityDescription}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    error={formdata.errors.activityDescription}
                    multiline
                    maxRows={6}
                    minRows={4}
                  />
                </FormControl>
              </StyledFormBoxControl>
            </Box>
            <Box
              sx={{
                marginTop: theme.spacing(4),
              }}
            >
              <StyledFormBoxControl mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                  error={!!formdata.errors.activityGroupID}
                >
                  <VineaAutoComplete
                    value={formdata.activityGroupID}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    options={activityGroupList}
                    labelVariant="body1"
                    label={t('Activity Group')}
                    name={t('Activity Group')}
                    inlineLabel
                    inputProps={{
                      name: 'activityGroupID',
                    }}
                  />
                  {formdata.errors.activityGroupID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.activityGroupID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                  error={!!formdata.errors.resourceUnitID}
                >
                  <VineaAutoComplete
                    value={formdata.resourceUnitID}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    options={resourceTypeUnitList}
                    labelVariant="body1"
                    label={t('Resource Unit Type')}
                    name={t('Unit Type')}
                    inlineLabel
                    inputProps={{
                      name: 'resourceUnitID',
                    }}
                  />
                  {formdata.errors.resourceUnitID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.resourceUnitID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                  error={!!formdata.errors.costingUnitID}
                >
                  <VineaAutoComplete
                    value={formdata.costingUnitID}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    options={costingUnitList}
                    labelVariant="body1"
                    label={t('Activity Cost Unit')}
                    name={t('Activity Cost Unit')}
                    inlineLabel
                    inputProps={{
                      name: 'costingUnitID',
                    }}
                  />
                  {formdata.errors.costingUnitID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.costingUnitID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledFormBoxControl>
            </Box>
            <Box
              sx={{
                marginTop: theme.spacing(4),
              }}
            >
              <StyledFormBoxControl mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                  error={!!formdata.errors.workUnitID}
                >
                  <VineaAutoComplete
                    value={formdata.workUnitID}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    options={workUnitList}
                    labelVariant="body1"
                    label={t('Activity Work Unit')}
                    name={t('Activity Work Unit')}
                    inlineLabel
                    inputProps={{
                      name: 'workUnitID',
                    }}
                  />
                  {formdata.errors.workUnitID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.workUnitID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                  error={!!formdata.errors.velocityDurationID}
                >
                  <VineaAutoComplete
                    value={formdata.velocityDurationID}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    options={durationList}
                    labelVariant="body1"
                    label={t('Activity Duration')}
                    name={t('Activity Duration')}
                    inlineLabel
                    inputProps={{
                      name: 'velocityDurationID',
                    }}
                  />
                  {formdata.errors.velocityDurationID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.velocityDurationID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledFormBoxControl>
              <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    marginTop: theme.spacing(1),
                    marginLeft: theme.spacing(2),
                    marginBottom: theme.spacing(1),
                  }}
                  mt={1}
                >
                  <FormControl
                    sx={{
                      marginTop: theme.spacing(1),
                    }}
                    margin="none"
                    error={!!formdata.errors.activityResourceTypeID}
                  >
                    <ColorPickerComponent
                      color={formdata.activityColor}
                      onChange={handleColorChange}
                    />
                    {formdata.errors.activityColor && (
                      <FormHelperText id="component-error-text">
                        {formdata.errors.activityColor}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <FormGroup sx={{ margin: '10px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formdata.activityIsPlannable}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Plannable"
                  />
                </FormGroup>
              </Stack>
            </Box>
            <Box
              sx={{
                marginBottom: theme.spacing(2),
                marginLeft: theme.spacing(2),
              }}
            >
              <VineaCommonGrid
                heading={t(commonGridHeaderNames.SIMILAR_IDENTITIES)}
                colHeaders={commonGridColumns.SIMILAR_IDENTITIES}
                tableData={matchingIdentities}
                hasManageOption={false}
                hasActiveToggle={false}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
});

CreateNewActivity.propTypes = {
  stepData: PropTypes.shape({
    activityCode: PropTypes.string,
    activityDescription: PropTypes.string,
    isPlannable: PropTypes.bool,
    resoruceUnitID: PropTypes.number,
    workUnitID: PropTypes.number,
    costingUnitID: PropTypes.number,
    velocityDurationID: PropTypes.number,
    activityGroupID: PropTypes.number,
    ts: PropTypes.string,
  }),
  onSaveStepData: PropTypes.func,
  step: PropTypes.number,
  calculateNameToCheck: PropTypes.func,
  matchingIdentities: PropTypes.array,
};

CreateNewActivity.defaultProps = {
  stepData: {},
  onSaveStepData: () => {},
  step: 0,
  calculateNameToCheck: () => {},
  matchingIdentities: [],
};

export default CreateNewActivity;
