import * as Yup from 'yup';
import { isValid } from 'date-fns';

export const NoteSchema = Yup.object().shape({
  noteSubject: Yup.string().nullable().required('Required'),
  subjectDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
  noteStatusID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select Note Status')
    .nullable()
    .required('Required'),
});

export const AttachedDocumentSchema = Yup.object().shape({
  date: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
  subject: Yup.string().nullable().required('Required'),
  statusId: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
});
