import React, { useReducer, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import { format } from 'date-fns';
import { Moment } from 'moment';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { filter, find, get, has, isEmpty, isNil, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';

import { VineaAlert } from '../../../components/VineaAlert';
import { FieldLabelInput, VineaTextField } from '../../../components/TextField';
import {
  getBusinessUnit,
  getLkpDataProvider,
  getLkpDataProviderType,
  getFormWriteData,
} from '../../../redux/selectors';
import {
  addGenericReducer,
  dataConnectionState,
  types,
} from '../../VineyardIdentity/stateReducers';
import { syncValidator } from '../../../utils/validator';
import { ConnectionSchema } from '../../VineyardIdentity/validations';
import {
  apiTypes,
  commonFieldSelectOption,
  DataProviderTypeIDs,
  reducers,
  sagaActionTypes,
  vineaDetails,
  dateFormat,
  viewDateFormat,
  ARC_GIS_CLIENT_ID,
  LOCAL_ARC_GIS_TOKEN,
  LOCAL_ARC_GIS_TOKEN_CREATED,
} from '../../../constants';
import { formatDate } from '../../../constants/formatter';
import logger from '../../../utils/winstonLogger';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { SplitButton } from '../../../components/SplitButton';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { VineaButton } from '../../../components/VineaButton';
import { DiscardDialog } from '../../../components/Dialog';
import { statechecker } from '../../../utils/statecomparator';
import {
  IdentityActivationSwitch,
  DeleteIdentityAlert,
  DeleteIdentityButton,
} from '../../../components/IdentityComponents';
import { useIdentityStatus } from '../../../hooks/useIdentityStatus';
import { providerFieldMap } from '../providerFieldMap';
import { Datepicker } from '../../../components/Datepicker';
import { GreenSwitch } from '../../../components/Switch';

export const ManageConnectionDetails = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [formdata, dispatch] = useReducer(
    addGenericReducer,
    dataConnectionState,
  );
  const formWriteData = useSelector(state => state.formWrite).data;

  const {
    hasError: hasErrorFormSubmit,
    isLoaded,
    isLoading,
  } = useSelector(getFormWriteData);
  // const formWrite = useSelector(state => state.formWrite);
  const dataProviderList = useSelector(state => getLkpDataProvider(state));
  const dataProviderTypeList = useSelector(state =>
    getLkpDataProviderType(state),
  );
  const businessUnitList = useSelector(state => getBusinessUnit(state));

  const identityTypeID = useIdentityTypeId();
  const [apiTriggerStatus, setTriggerStatus] = useState(true);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = useState(false);
  const [updateApiStatus, setUpdateApiStatus] = useState(false);
  const [formHasChanges, setFormHasChanges] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [formWriteReloadState, setFormWriteReloadState] = useState(false);
  const [providerList, setProviderList] = useState(commonFieldSelectOption);
  const [saveIndex, setSaveIndex] = useState(0);
  const { id: pathParam } = useParams();
  const [deleteHasFailed, setDeleteHasFailed] = useState(false);
  const [urlLabel, setUrlLabel] = useState(t('API Key'));
  const [isDeleting, setIsDeleting] = useState(false);
  const [arcGISToken, setArcGISToken] = useLocalStorage(
    LOCAL_ARC_GIS_TOKEN,
    null,
  );
  const [arcGISTokenCreated, setArcGISTokenCreated] = useLocalStorage(
    LOCAL_ARC_GIS_TOKEN_CREATED,
    null,
  );
  const [connectionButtonText, setConnectionButtonText] =
    React.useState('Test Connection');

  let popupLocation = useLocation();

  const {
    isLoading: connectionIsLoading,
    isLoaded: connectionIsLoaded,
    hasError: connectionHasError,
  } = useSelector(VineaNovaSelectors.getIdentityDataConnectorEntityMeta);

  const {
    isLoading: syncLoading,
    isLoaded: syncLoaded,
    hasError: syncHasError,
  } = useSelector(
    VineaNovaSelectors.getTriggerVintraceMetricDataSyncEntityMeta,
  );
  const [syncAPITrigger, setSyncAPITrigger] = useState(false);

  const isActive = useIdentityStatus();
  const { ARC_GIS, WINERY } = DataProviderTypeIDs;

  const handleComponentChange = event => {
    const {
      target: { value, name },
    } = event;

    if (name === 'isDataIngestionEnabled') {
      dispatch({
        type: types.UPDATE,
        payload: { [name]: !formdata.isDataIngestionEnabled },
      });
    } else {
      dispatch({
        type: types.UPDATE,
        payload: { [name]: value === 0 ? null : value },
      });
    }

    if (name === 'providerTypeID') {
      const providerOptions = filter(
        dataProviderList,
        f => f.dataProviderTypeID === value,
      );
      if (!isEmpty(providerOptions)) setProviderList(providerOptions);
      else setProviderList(commonFieldSelectOption);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleOnBlur = () => {
    const validationErrors = syncValidator(ConnectionSchema)(formdata);

    dispatch({
      type: types.ERROR,
      payload:
        !isEmpty(formdata.errors) && !isEmpty(validationErrors)
          ? validationErrors
          : {},
    });
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  const handleClose = () => {
    if (formHasChanges) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleSave = (event, index) => {
    setSaveIndex(index);
    const validationErrors = [];
    const instanceData = formWriteData;
    if (!isEmpty(validationErrors)) {
      dispatch({ type: types.ERROR, payload: validationErrors });
    } else if (
      instanceData.connectionDescription === formdata.connectionDescription &&
      instanceData.businessUnitID === formdata.businessUnitID &&
      instanceData.dataProviderID === formdata.dataProviderID &&
      instanceData.providerTypeID === formdata.providerTypeID
    ) {
      setWarningInSubmit(true);
    } else {
      const data = {
        id: pathParam,
        identityTypeID,
        businessUnitID: formdata.businessUnitID,
        connectionDescription: formdata.connectionDescription,
        dataProviderID: formdata.dataProviderID,
        isConnectionValid: true,
        lastUpdatedDate: format(new Date(), dateFormat),
        ts: formdata.ts,
        Username: formdata.userName || null,
        Password: formdata.password || null,
        APIKey: formdata.apiKey || null,
        API_URL: formdata.apiURL || null,
        DataIngestionStartDate: formdata.dataIngestionStartDate || null,
        IsDataIngestionEnabled: formdata.isDataIngestionEnabled || null,
      };
      setWarningInSubmit(false);
      setErrorInSubmit(false);
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.dataConnection,
          methodType: apiTypes.PUT,
        },
      });
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
      setUpdateApiStatus(true);
    }
  };

  const handleOnToggleActive = () => {
    navigate(`/connections/${pathParam}/managestatus`);
  };

  const handleOnConfirmDelete = () => {
    if (pathParam) {
      setIsDeleting(true);
      dispatchAPI(
        VineaNovaActions.api.v1.identityDataConnector.delete.request({
          queryParams: {
            IdentityID: pathParam,
          },
        }),
      );
    }
  };

  const handleTestConnection = async () => {
    if (formdata.providerTypeID === ARC_GIS) {
      const redirectUri = window.location.href;
      // open window and get token
      window.open(
        'https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=' +
          ARC_GIS_CLIENT_ID +
          '&response_type=token&expiration=20160&redirect_uri=' +
          window.encodeURIComponent(redirectUri),
        'oauth-window',
        'height=400,width=600,menubar=no,location=yes,resizable=yes,scrollbars=yes,status=yes',
      );
    } else if (formdata.providerTypeID === WINERY) {
      dispatchAPI(
        VineaNovaActions.api.v1.triggerVintraceMetricDataSync.get.request(),
      );
      setSyncAPITrigger(true);
    }
  };

  // Get token value from hash string
  const extractAccessToken = hash => {
    let extract = hash.split('#access_token=')[1];
    const indexOfAnd = extract.indexOf('&');
    if (indexOfAnd !== -1) return extract.substring(0, indexOfAnd);
    else return extract;
  };

  // Want to get the access token from the hash of the location of the popup window
  useEffect(() => {
    if (
      has(popupLocation, 'hash') &&
      !isNil(popupLocation.hash) &&
      popupLocation.hash.match(/#access_token=([^&]+)/)
    ) {
      setArcGISToken(popupLocation.hash);
    }
  }, [popupLocation]);

  // Close window once access token is obtained
  useEffect(() => {
    if (
      !isNil(localStorage.getItem(LOCAL_ARC_GIS_TOKEN)) &&
      localStorage.getItem(LOCAL_ARC_GIS_TOKEN).match(/#access_token=([^&]+)/)
    ) {
      setArcGISToken(
        extractAccessToken(localStorage.getItem(LOCAL_ARC_GIS_TOKEN)),
      );
      setArcGISTokenCreated(Math.floor(new Date().getTime()));
      window.close();
    }
  }, [arcGISToken]);

  useEffect(() => {
    if (!hasErrorFormSubmit && isLoaded && updateApiStatus) {
      enqueueSnackbar('Success!', { variant: 'success' });
      if (saveIndex === 0)
        navigate(`/connections/${pathParam}`);
      else if (formWriteReloadState && saveIndex === 1) {
        setFormWriteReloadState(false);
        dispatchAPI({
          type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
          payload: { id: pathParam, name: reducers.dataConnection },
        });
      }
    } else if (hasErrorFormSubmit && isLoaded && updateApiStatus) {
      setErrorInSubmit(true);
      enqueueSnackbar(t('Error'), { variant: 'Error' });
    }
  }, [
    hasErrorFormSubmit,
    isLoaded,
    updateApiStatus,
    enqueueSnackbar,
    t,
    navigate,
    saveIndex,
    pathParam,
    dispatchAPI,
    formWriteReloadState,
  ]);

  useEffect(() => {
    if (
      !isLoading &&
      isLoaded &&
      !isEmpty(formWriteData) &&
      !hasErrorFormSubmit
    ) {
      const stubFormData = omit(formdata, ['errors', 'status']);
      if (statechecker(stubFormData, formWriteData)) {
        setFormHasChanges(false);
      } else {
        setFormHasChanges(true);
      }
    }

    if (formdata.providerTypeID !== ARC_GIS) {
      //Gis Services
      setUrlLabel(t('API URL'));
    } else {
      setUrlLabel(t('Connection URL'));
    }

    if (formdata.providerTypeID === WINERY) {
      setConnectionButtonText('Sync Connection');
    }
  }, [formdata]);

  useEffect(() => {
    if (apiTriggerStatus && !isEmpty(formWriteData)) {
      const instanceData = formWriteData;
      const currentRecord = find(
        dataProviderList,
        f => f.id === instanceData.dataProviderID,
      );
      const { dataProviderTypeID = '' } = currentRecord;

      setProviderList(
        filter(
          dataProviderList,
          f => f.dataProviderTypeID === dataProviderTypeID,
        ),
      );
      dispatch({
        type: types.UPDATE,
        payload: {
          connectionDescription: instanceData.connectionDescription,
          dataProviderID: instanceData.dataProviderID,
          providerTypeID: dataProviderTypeID,
          isConnectionValid: instanceData.isConnectionValid,
          businessUnitID: instanceData.businessUnitID,
          ts: instanceData.ts,
          userName: instanceData.username || null,
          password: instanceData.password || null,
          apiKey: instanceData.apiKey || null,
          apiURL: instanceData.apI_URL || null,
          dataIngestionStartDate: instanceData.dataIngestionStartDate || null,
          isDataIngestionEnabled: instanceData.isDataIngestionEnabled || null,
        },
      });

      setTriggerStatus(false);
      logger.debug('After Update');
    }
  }, [apiTriggerStatus, formWriteData, dataProviderList]);

  useEffect(() => {
    if (isDeleting && !connectionIsLoading && connectionIsLoaded) {
      if (connectionHasError) {
        setDeleteHasFailed(true);
      } else {
        navigate('/connections');
      }

      setIsDeleting(false);
    }
  }, [
    connectionIsLoaded,
    connectionIsLoading,
    connectionHasError,
    isDeleting,
    setIsDeleting,
    navigate,
  ]);

  // Display snack bar if sync is clicked
  useEffect(() => {
    if (syncLoaded && !syncLoading && syncAPITrigger) {
      if (!syncHasError)
        enqueueSnackbar('Sync Successful!', { variant: 'success' });
      else enqueueSnackbar(t('Sync Unsuccessful'), { variant: 'Error' });

      setSyncAPITrigger(false);
    }
  }, [syncLoaded, syncLoading, syncHasError]);

  return (
    <Paper
      elevation={0}
      data-testid="manageperson-detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Stack spacing={2} data-testid="grid-details-manageperson">
        <Box>
          <VineaAlert
            isOpen={!!errorInSubmit}
            onExit={() => setErrorInSubmit(false)}
          />
          <DeleteIdentityAlert
            isOpen={deleteHasFailed}
            onExit={() => setDeleteHasFailed(false)}
            identityName="connection"
          />
        </Box>

        <Box px={2}>
          {warningInSubmit && (
            <Collapse in={warningInSubmit}>
              <Alert
                variant="standard"
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setWarningInSubmit(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {t('Warning: Nothing to Update')}
              </Alert>
            </Collapse>
          )}
        </Box>

        <Grid container direction="row" spacing={4}>
          <Grid item>
            <FieldLabelInput
              id="connectionDescription"
              sx={{ width: '350px' }}
              label={t('Name')}
              name="connectionDescription"
              size="small"
              inlineLabel
              value={formdata.connectionDescription}
              onChange={handleComponentChange}
              onBlur={handleOnBlur}
              error={formdata.errors.connectionDescription}
            />
          </Grid>
          <Grid item>
            <FormControl
              sx={{ minWidth: '350px' }}
              error={!!formdata.errors.businessUnitID}
              margin="none"
            >
              <VineaAutoComplete
                value={formdata.businessUnitID || 0}
                onChange={handleComponentChange}
                onBlur={handleOnBlur}
                options={businessUnitList}
                labelVariant="body1"
                id="businessunit"
                name={t('Business Unit')}
                label={t('Business Unit')}
                inlineLabel={false}
                inputProps={{
                  name: 'businessUnitID',
                }}
              />
              {formdata.errors.businessUnitID && (
                <FormHelperText id="component-error-text">
                  {t(formdata.errors.businessUnitID)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={4}>
          <Grid item>
            <FormControl
              sx={{ width: '350px' }}
              error={!!formdata.errors.providerTypeID}
              margin="none"
            >
              <VineaAutoComplete
                value={formdata.providerTypeID || 0}
                onChange={handleComponentChange}
                onBlur={handleOnBlur}
                inlineLabel={false}
                options={dataProviderTypeList}
                labelVariant="body1"
                id="salutation"
                name={t('Data Provider Type')}
                label={t('Data Provider Type')}
                inputProps={{
                  name: 'providerTypeID',
                }}
              />
              {formdata.errors.providerTypeID && (
                <FormHelperText id="component-error-text">
                  {t(formdata.errors.providerTypeID)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              sx={{ width: '350px' }}
              error={!!formdata.errors.dataProviderID}
              margin="none"
            >
              <VineaAutoComplete
                value={formdata.dataProviderID || 0}
                onChange={handleComponentChange}
                onBlur={handleOnBlur}
                inlineLabel={false}
                options={providerList}
                labelVariant="body1"
                id="salutation"
                name={t('Provider')}
                label={t('Provider')}
                inputProps={{
                  name: 'dataProviderID',
                }}
              />
              {formdata.errors.dataProviderID && (
                <FormHelperText id="component-error-text">
                  {t(formdata.errors.dataProviderID)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={4}>
          {providerFieldMap[formdata?.dataProviderID]?.userName && (
            <Grid item>
              <FieldLabelInput
                id="username"
                label={t('Username')}
                sx={{ width: '350px' }}
                name="userName"
                size="small"
                inlineLabel
                value={formdata.userName}
                onChange={handleComponentChange}
                onBlur={handleOnBlur}
                error={formdata.errors.userName}
                helperText={formdata.errors.userName}
              />
            </Grid>
          )}

          {providerFieldMap[formdata?.dataProviderID]?.password && (
            <Grid item>
              <VineaTextField
                sx={{ width: '350px', marginTop: 1 }}
                id="password"
                type="password"
                label={t('Password')}
                name="password"
                size="small"
                inlineLabel
                value={formdata.password}
                onChange={handleComponentChange}
                onBlur={handleOnBlur}
                error={formdata.errors.password}
                helperText={formdata.errors.password}
              />
            </Grid>
          )}

          {providerFieldMap[formdata?.dataProviderID]?.apiURL && (
            <Grid item>
              <FieldLabelInput
                id="apiURL"
                label={urlLabel}
                sx={{ width: '350px' }}
                name="apiURL"
                size="small"
                inlineLabel
                value={formdata.apiURL}
                onChange={handleComponentChange}
                onBlur={handleOnBlur}
                error={formdata.errors.apiURL}
                helperText={formdata.errors.apiURL}
                placeholder="e.g. https://nz2.vintrace.net/abc/api/v6"
              />
            </Grid>
          )}

          {providerFieldMap[formdata?.dataProviderID]?.apiKey && (
            <Grid item>
              <FieldLabelInput
                id="apikey"
                label={t('API Key')}
                sx={{ width: '350px' }}
                name="apiKey"
                size="small"
                inlineLabel
                value={formdata.apiKey}
                onChange={handleComponentChange}
                onBlur={handleOnBlur}
                error={formdata.errors.apiKey}
                helperText={formdata.errors.apiKey}
              />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={4}>
          {providerFieldMap[formdata?.dataProviderID]
            ?.dataIngestionStartDate && (
            <Grid item>
              <FormControl sx={{ width: '350px' }}>
                <Datepicker
                  label={t('Start Date')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  name="dataIngestionStartDate"
                  disablePast={false}
                  value={formdata?.dataIngestionStartDate}
                  error={formdata?.errors?.startDate}
                  onChange={handleComponentChange}
                  fullWidth
                />
              </FormControl>
            </Grid>
          )}

          {providerFieldMap[formdata?.dataProviderID]
            ?.isDataIngestionEnabled && (
            <Grid item>
              <Stack
                direction="row"
                sx={{
                  width: '350px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography variant="body2">
                    {t('Data Ingestion Status')}
                  </Typography>
                </Box>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={!!formdata?.isDataIngestionEnabled}
                      onChange={handleComponentChange}
                      name="isDataIngestionEnabled"
                    />
                  }
                  labelPlacement="bottom"
                  label={
                    <Typography variant="caption">
                      {t(
                        formdata?.isDataIngestionEnabled
                          ? 'Enabled'
                          : 'Disabled',
                      )}
                    </Typography>
                  }
                />
              </Stack>
            </Grid>
          )}
        </Grid>
        <Box padding={3}>
          <Box mt={2}>
            <VineaButton
              data-testid="BtnCancel"
              onClick={handleTestConnection}
              color="secondary"
              variant="text"
            >
              {t(connectionButtonText)}
            </VineaButton>
          </Box>

          <Box mb={2}>
            <Box my={2}>
              <IdentityActivationSwitch
                handleOnToggleActive={handleOnToggleActive}
                isActive={isActive}
                identityName="connection"
              />
            </Box>
            <Box my={2}>
              <DeleteIdentityButton
                handleOnDelete={handleOnConfirmDelete}
                identityName="connection"
                isDisabled={deleteHasFailed}
              />
            </Box>
          </Box>

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
            }}
          >
            <SplitButton
              color="success"
              onClick={handleSave}
              variant="contained"
              minWidth={130}
              disabled={!formHasChanges}
            />
            <Box mx={2}>
              <VineaButton variant="outlined" onClick={handleClose}>
                {t('Close')}
              </VineaButton>
            </Box>
          </Grid>
        </Box>
        <DiscardDialog
          open={discardDialogOpen}
          onClose={handleOnDialogClose}
          handleSaveChanges={handleSave}
          handleDiscardChanges={handleCancel}
        />
      </Stack>
    </Paper>
  );
};
