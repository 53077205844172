import { createSelector } from 'reselect';
import { filter, find, isNull } from 'lodash';
// import logger from '../../utils/winstonLogger';
import { IdentityTypeIds } from '../../constants';

const classificationData = state => state.identityClassifications.data;
const classificationGroupData = state =>
  state.entities.lookupClassificationGroup.data;
const classificationGroupIdentityTypeData = state =>
  state.entities.lookupClassificationGroupIdentityType.data;

export const getClassificationTimeStamp = id => {
  if (!isNull(classificationData)) {
    const selectedField = find(classificationData, f => f.id === id);
    return selectedField;
  }
  return null;
};

export const getClassificationOptions = createSelector(
  [classificationGroupData, classificationGroupIdentityTypeData],
  (type, idType) => {
    const options = [
      {
        key: '0',
        id: '0',
        value: 'Select',
      },
    ];
    const personTypeValues = filter(idType, record => {
      return record.identityTypeID === IdentityTypeIds.PERSON;
    });
    const classificationOptions = options.concat(
      filter(type, record => {
        return find(
          personTypeValues,
          param => param.classificationGroupID === record.id,
        );
      }).map(option => {
        return {
          key: option.id,
          id: option.id,
          value: option.classificationGroup,
        };
      }),
    );
    return classificationOptions;
  },
);
