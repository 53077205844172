export const contactMethodTypes = {
  email: 'Personal Email',
  workEmail: 'Work Email',
  mobile: 'Mobile',
  landLine: 'Land Line',
  faxPhone: 'Fax Phone',
  reception: 'Reception Phone',
  ddiPhone: 'DDI Phone',
  homePhone: 'Home Phone',
  homeFax: 'Home Fax',
  webUrl: 'Web URL',
  streetAddress: 'Street Address',
  postalAddress: 'Postal Address',
  invoiceAddress: 'Invoice Address',
  otherAddress: 'Other Address',
  companyEmail: 'Company Email',
  freePhone: 'Free Phone',
};

export const contactMethodReducerMapping = {
  email: 'email',
  phoneNo: 'mobile',
  url: 'webUrl',
  address1: 'streetAddress',
  address2: 'streetAddress',
  suburb: 'streetAddress',
  postcode: 'streetAddress',
  country: 'streetAddress',
  hasDifferentAddress: null,
  mailaddress1: 'postalAddress',
  mailaddress2: 'postalAddress',
  mailsuburb: 'postalAddress',
  mailpostCode: 'postalAddress',
  mailcountry: 'postalAddress',
  mailemail: 'workEmail',
  mailphoneNo: 'freePhone',
  mailurl: 'webUrl',
  errors: null,
  mailErrors: null,
};

export const similarContactTypeMapping = {
  email: ['Personal Email', 'Work Email', 'Company Email'],
  phone: [
    'Mobile',
    'Land Line',
    'DDI Phone',
    'Home Phone',
    'Free Phone',
    'Reception Phone',
  ],
  fax: ['Fax Phone', 'Home Fax'],
  url: ['Web URL'],
  address: [
    'Street Address',
    'Postal Address',
    'Other Address',
    'Invoice Address',
  ],
};
