export default {
  fontFamily: 'Poppins',
  color: 'rgba(0, 0, 0, 0.87)',
  h1: {
    fontWeight: 300,
    fontSize: '96px',
    letterSpacing: '-1.5px',
    lineHeight: '112px',
  },
  h2: {
    fontWeight: 300,
    fontSize: '60px',
    letterSpacing: '-0.5px',
    lineHeight: '72px',
  },
  h3: {
    fontWeight: 400,
    fontSize: '48px',
    letterSpacing: '0px',
    lineHeight: '56px',
  },
  h4: {
    fontWeight: 400,
    fontSize: '34px',
    letterSpacing: '0.25px',
    lineHeight: '42px',
  },
  h5: {
    fontWeight: 400,
    fontSize: '24px',
    letterSpacing: '0px',
    lineHeight: '32px',
  },
  h6: {
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '32px',
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '28px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '22px',
  },
  subtitle3: {
    fontWeight: 600,
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '22px',
  },
  body1: {
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
  },
  body2: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
  },
  button: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    lineHeight: '26px',
  },
  caption: {
    fontWeight: 400,
    fontSize: '11px',
    letterSpacing: '0.4px',
    lineHeight: '15px',
  },
  caption2: {
    fontWeight: 500,
    fontSize: '11px',
    letterSpacing: '0.4px',
    lineHeight: '15px',
  },
  overline: {
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '1px',
    lineHeight: '32px',
    textTransform: 'uppercase',
  },
  avatarLetter: {
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '0.14px',
    lineHeight: '20px',
  },
  inputLabel: {
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.15px',
    lineHeight: '20px',
  },
  helperText: {
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.4px',
    lineHeight: '20px',
  },
  inputText: {
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.15px',
    lineHeight: '21px',
  },
  tooltip: {
    fontSize: '10px',
    fontWeight: 500,
    letterSpacing: '0px',
    lineHeight: '14px',
  },
};
