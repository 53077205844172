import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { FieldNameSelect } from '../../components/Select';

export const SelectFields = () => {
  const theme = useTheme();
  const [age, setAge] = React.useState('');

  const handleChange = event => {
    setAge(event.target.value);
  };
  return (
    <Grid
      item
      xs={12}
      sm={8}
      sx={{
        flexGrow: 1,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
      }}
    >
      <Typography component="p" variant="h3">
        Radios
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            '& > button': {
              margin: theme.spacing(1),
            },
          }}
        >
          <FormControl
            sx={{
              margin: theme.spacing(1),
              minWidth: 300,
            }}
          >
            <FieldNameSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </FieldNameSelect>
            <FormHelperText>Required</FormHelperText>
          </FormControl>
          <FormControl
            variant="outlined"
            sx={{
              margin: theme.spacing(1),
              minWidth: 300,
            }}
          >
            <FieldNameSelect
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={age}
              onChange={handleChange}
              label="Age"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </FieldNameSelect>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
