import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import FilterScreen from './FilterScreen.tsx';
import { SupplyContractsSearchResults } from './SupplyContractsSearchResults.jsx';
import { IdentitySubMenu } from '../../components/IdentitySubMenu';
import {
  areLookupsLoaded,
  getUserPreferences,
  getBusinessUnit,
  getSupplyContractType,
} from '../../redux/selectors';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import useLocationListener from '../../hooks/useLocationListener';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { useSupplyContractsSearchHook } from './useSupplyContractsSearchHook.tsx';
import { useSupplyContractsHook } from './useSupplyContractsHook.tsx';

const RootDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: '100%',
}));

const StyledBox = styled(Box)(({ theme, isFilterOpen }) => ({
  ...(isFilterOpen && {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flex: '0.4',
    },
    [theme.breakpoints.up('md')]: {
      flex: '0.3',
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0.18',
    },
  }),
  ...(!isFilterOpen && { display: 'flex', flex: 0 }),
}));

const SupplyContracts = () => {
  const dispatchAPI = useDispatch();
  const newLocationKeys = useLocationListener();
  const { t } = useTranslation();
  const [locationKeys, setLocationKeys] = useState([]);
  const { isLocked, setIsLocked } = useTabChangeLock();
  const firstFilterInputRef = useRef();
  const { basicSettings } = useSelector(state => getUserPreferences(state));
  const lkpBusinessUnit = useSelector(state => getBusinessUnit(state));
  const { filterPanelCollapsed } = basicSettings;

  const {
    allSupplyContracts,
    defaultFilterValues,
    supplyContractSearchFilter,
    filterData,
    filterText,
    setFilterData,
    onUpdateFilterData,
    handleOnNewSupplyContract,
    dispatchSearchSupplyContract,
    onSearchIdentities,
  } = useSupplyContractsSearchHook();

  const { supplyingParties, purchasingParties } = useSupplyContractsHook();

  const lkpSupplyContractType = useSelector(state =>
    getSupplyContractType(state),
  );

  const isLookupsLoaded = useSelector(areLookupsLoaded);

  const handleOnFilterToggle = () => {
    dispatchAPI({
      type: 'BASIC_SETTINGS_UPDATE',
      payload: {
        ...basicSettings,
        filterPanelCollapsed: !filterPanelCollapsed,
      },
    });
  };

  const handleOnFilterOpen = () => {
    if (filterPanelCollapsed) {
      dispatchAPI({
        type: 'BASIC_SETTINGS_UPDATE',
        payload: {
          ...basicSettings,
          filterPanelCollapsed: false,
        },
      });
    } else if (firstFilterInputRef.current) {
      firstFilterInputRef.current?.focus();
    }
  };

  useEffect(() => {
    if (isLocked) {
      setIsLocked(false);
    }
    if (!isEqual(locationKeys, newLocationKeys) && isLookupsLoaded) {
      setLocationKeys(newLocationKeys);
      setFilterData({
        ...defaultFilterValues,
        ...supplyContractSearchFilter,
      });
      dispatchSearchSupplyContract();
    }
  }, [
    newLocationKeys,
    locationKeys,
    dispatchAPI,
    isLookupsLoaded,
    supplyContractSearchFilter,
    setIsLocked,
    isLocked,
    defaultFilterValues,
  ]);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      data-testid="supplycontract-search-screen"
      height="100%"
    >
      <Paper
        elevation={0}
        data-testid="submenu-supplycontract-search"
        sx={{
          padding: 1,
          marginBottom: 3,
        }}
      >
        <IdentitySubMenu
          onNewIdentityClick={handleOnNewSupplyContract}
          newButtonText={t('Add Supply Contract')}
          newButtonDisabled={false}
          //   onExportClick={handleOnExport}
        />
      </Paper>
      <RootDiv data-testid="supplycontract-search-table">
        <StyledBox isFilterOpen={!filterPanelCollapsed}>
          <FilterScreen
            isFilterOpen={!filterPanelCollapsed}
            onFilterToggle={handleOnFilterToggle}
            search={onSearchIdentities}
            filterData={filterData}
            onUpdateFilterData={onUpdateFilterData}
            defaultFilterValues={defaultFilterValues}
            lkpBusinessUnit={lkpBusinessUnit}
            supplyingPartyOptions={supplyingParties}
            purchasingPartyOptions={purchasingParties}
            lkpSupplyContractType={lkpSupplyContractType}
          />
        </StyledBox>
        <Box display="flex" flex={1} ml={2} pb={3} mr={2}>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <SupplyContractsSearchResults
              identities={allSupplyContracts}
              filterText={filterText}
              onFilterOpen={handleOnFilterOpen}
            />
          </ErrorBoundary>
        </Box>
      </RootDiv>
    </Box>
  );
};

export default SupplyContracts;
