/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { RenderDataGridHeader } from '../../../components/Grid';
import { formatDate } from '../../../constants/formatter';

function result(params) {
  let value = '';
  if (params.row.measure !== 'Pest and Disease Assessment') {
    value = params.row.result;
  }
  return value;
}

export const sampleHeaderGridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'string',
    // flex: 1,
    width: 75,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'vineyardName',
    headerName: 'Vineyard',
    flex: 1.5,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'blockFullName',
    headerName: 'Block Section',
    flex: 0.8,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedTo',
    headerName: 'Assigned To',
    flex: 0.8,
    hideable: false,
    headerAlign: 'left',
    type: 'number',
    renderCell: params => (
      <Tooltip title={params.row.assignedTo}>
        <span className="table-cell-trucate">{params.row.assignedTo}</span>
      </Tooltip>
    ),
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'recommendedSampleSize',
    headerName: 'Recommended Sample Size',
    flex: 0.8,
    hideable: false,
    headerAlign: 'left',
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'actualSampleSize',
    headerName: 'Actual Sample Size',
    flex: 0.8,
    hideable: false,
    headerAlign: 'left',
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'requestSentDate',
    headerName: 'Send Date',
    flex: 0.8,
    hideable: false,
    valueFormatter: param => formatDate(param.value),
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'requestDueDate',
    headerName: 'Due Date',
    flex: 0.8,
    hideable: false,
    valueFormatter: param => formatDate(param.value),
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'requestCompletionDate',
    headerName: 'Completed Date',
    flex: 1,
    hideable: false,
    valueFormatter: param => formatDate(param.value),
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'result',
    headerName: 'Result',
    flex: 1,
    hideable: false,
    headerAlign: 'left',
    type: 'number',
    renderHeader: RenderDataGridHeader,
    valueGetter: result,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
];
