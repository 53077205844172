/**
 * @module Sagas/updateIdentities
 * @desc create identity, classification and references from organiztions/vineyards/name/create new
 */

import { put, takeLatest, all } from 'redux-saga/effects';
import { reducers, sagaActionTypes } from '../../constants';
import logger from '../../utils/winstonLogger';

/**
 * Fetch All Lookup tables
 */
export function* refreshPageData(action) {
  try {
    const {
      payload: { refreshPage },
    } = action;
    yield put({
      type: 'SUCCESS',
      name: reducers.refreshPages,
      payload: {
        refreshPage,
      },
    });
  } catch (err) {
    /* istanbul ignore next */
    logger.error(err);
    // console.error(err);
    yield put({
      type: 'ERROR',
      payload: err,
      name: reducers.refreshPages,
    });
  }
}

export default function* root() {
  yield all([takeLatest(sagaActionTypes.REFRESH_PAGE_DATA, refreshPageData)]);
}
