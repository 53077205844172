import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { ErrorBoundary } from 'react-error-boundary';
import useRefDimensions from '../../hooks/useRefDimensions';
import PlanBlocks from './PlanBlocks';
import { IdentityDetails } from '../../components/IdentityDetails';
import { TabPanel, TabView } from '../../components/TabView';
import {
  commonClassificationPropTypes,
  commonReducerPropTypes,
  commonContactMethodPropTypes,
  commonRelatonshipPropTypes,
} from '../../utils/commonPropTypes';
import { reducers, sagaActionTypes } from '../../constants';
import {
  getIdentitySubDetailsIsLoading,
  getClassificationsById,
  getIdentityReferences,
  getAllIdentityRoles,
  getAllIdentityContactMethods,
  getIdentityOverviewDetailsFromContact,
  getIsIdentityOverviewLoading,
  getIdentityRelationshipData,
} from '../../redux/selectors';
import { getLicenseActiveStatus } from '../../redux/selectors/userAccessSelector';
import { useIdentityTypeId } from '../../hooks/useIdentityTypeId';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import PlanActivites from './PlanActivities';

const PlanDetails = ({
  isSubDetailsLoading,
  fetchIdentityRoot,
  classifications,
  contactDetails,
  identityRefData,
  identityRoles,
  licenseStatus,
  identityRelationshipData,
  ...restPropsVD
}) => {
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname } = useLocation();
  const identityTypeId = useIdentityTypeId();
  const [value, setValue] = React.useState(0);
  const [initialApiTriggered, setInitialTrigger] = React.useState(true);
  const { id: pathParam } = useParams();
  const acitivityRef = React.useRef(null);
  const dimensions = useRefDimensions(acitivityRef);

  const { data: { refreshPage } = {} } = useSelector(
    state => state.refreshPages,
  );

  const identityOverviewDetails = useSelector(state =>
    getIdentityOverviewDetailsFromContact(state),
  );

  const isIdentityOverviewDetaisLoading = useSelector(
    getIsIdentityOverviewLoading,
  );

  const { planActiveStatus } = identityOverviewDetails;

  const { setIsLocked, isLocked } = useTabChangeLock();

  const handleonTabChange = newValue => {
    setValue(newValue);
    setIsLocked(true);
    handleURLChange(newValue);
  };

  const stateMap = ['/overview', '/blocks', '/history', '/jobs'];

  const handleURLChange = tabIndex => {
    navigate(`/plans/${pathParam}${stateMap[tabIndex]}`);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  useEffect(() => {
    if (isLocked) return;

    if (initialApiTriggered || refreshPage) {
      setInitialTrigger(false);
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: { refreshPage: false },
      });
      dispatchAPI({
        type: sagaActionTypes.FETCH_IDENTITY_VALUES,
        payload: {
          id: pathParam,
          name: reducers.plan,
        },
      });
    }
  }, [
    pathParam,
    initialApiTriggered,
    dispatchAPI,
    identityTypeId,
    pathname,
    refreshPage,
    isLocked,
  ]);

  useEffect(() => {
    // navigate to tab based on url params
    if (pathname === `/plans/${pathParam}`) {
      navigate(`/plans/${pathParam}/overview`, {
        replace: true,
      });
      setValue(0);
    } else if (pathname === `/plans/${pathParam}/blocks`) {
      setValue(1);
    } else if (pathname === `/plans/${pathParam}/edithistory`) {
      setValue(2);
    } else if (pathname === `/plans/${pathParam}/jobs`) {
      setValue(3);
    }
  }, [pathname, pathParam, navigate]);

  const handleEdit = () => {
    dispatchAPI({
      type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
      payload: { id: pathParam, name: reducers.plan },
    });
    dispatchAPI(
      VineaNovaActions.api.v1.identityOrganisation.get.request({
        queryParams: {
          IdentityID: pathParam,
        },
      }),
    );
    navigate(`/plans/${pathParam}/manageplan`);
  };

  if (pathParam === `test`) return null;

  const isOneOfMatch = [
    `/connections/${pathParam}`,
    `/organisation/other-organisations/${pathParam}`,
  ].includes(pathname);

  // render no screen for above links
  if (isOneOfMatch) return null;

  return (
    <Box
      data-testid="plan-details"
      ref={acitivityRef}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box
        data-testid="plan-details"
        display="flex"
        flexDirection="column"
        flex="1"
      >
        <ErrorBoundary
          FallbackComponent={({ ...props }) => (
            <Box sx={{ height: '160px' }}>
              <ErrorBoundaryFallback {...props} />
            </Box>
          )}
        >
          <IdentityDetails
            identityTypeId={identityTypeId}
            licenseStatus={licenseStatus}
            identityStatus={planActiveStatus}
            handleEdit={handleEdit}
            identityOverviewDetails={identityOverviewDetails}
            isLoading={isIdentityOverviewDetaisLoading}
          />
        </ErrorBoundary>
        <Box data-testid="plans" display="flex" flex={1}>
          <TabView
            tabHeaders={[
              t('Overview'),
              t('Blocks'),
              t('Edit History'),
              t('Assign Jobs'),
            ]}
            onTabChange={handleonTabChange}
            onSwipe={handleChangeIndex}
            value={value}
            swipeableStyles={{ display: 'flex' }}
          >
            <TabPanel
              value={value}
              index={0}
              dir={theme.direction}
              data-testid="tabpanel-overview"
              dataTestId="tabpanel-overview"
              sx={{ height: '100%' }}
            >
              <PlanActivites dimensions={dimensions} />
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
              dir={theme.direction}
              data-testid="tabpanel-blocks"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <PlanBlocks pathParamId={pathParam} />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={value}
              index={2}
              dir={theme.direction}
              data-testid="tabpanel-edit-history"
            />
            <TabPanel
              value={value}
              index={3}
              dir={theme.direction}
              data-testid="tabpanel-assign-jobs"
            />
          </TabView>
        </Box>
      </Box>
    </Box>
  );
};

PlanDetails.propTypes = {
  classifications: commonClassificationPropTypes.isRequired,
  isSubDetailsLoading: PropTypes.bool,
  identityRefData: commonReducerPropTypes,
  identityRoles: commonReducerPropTypes,
  contactDetails: commonContactMethodPropTypes.isRequired,
  licenseStatus: PropTypes.bool.isRequired,
  identityRelationshipData: commonRelatonshipPropTypes,
};

PlanDetails.defaultProps = {
  isSubDetailsLoading: false,
  identityRefData: {},
  identityRoles: {},
  identityRelationshipData: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    licenseStatus: getLicenseActiveStatus(state),
    isSubDetailsLoading: getIdentitySubDetailsIsLoading(state),
    identityRefData: getIdentityReferences(state),
    identityRoles: getAllIdentityRoles(state),
    identityRelationshipData: getIdentityRelationshipData(state),
    classifications: getClassificationsById(state, {
      id: ownProps?.match?.params?.id,
    }),
    contactDetails: getAllIdentityContactMethods(state),
  };
};

const mapDispatchToProps = dispatch => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PlanDetails,
);
