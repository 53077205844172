import React, { useReducer, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { isEmpty, omit, isArray, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/lab/Alert';
import { useSnackbar } from 'notistack';
import {
  FormHelperText,
  useTheme,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import ColorPickerComponent from '../../../components/ColorPickerComponent/ColorPickerComponent';

import { FieldLabelInput, VineaTextField } from '../../../components/TextField';
import {
  addGenericReducer,
  types,
  manageActivityState,
} from '../stateReducers';
import { syncValidator } from '../../../utils/validator';
import { ActivitySchema } from '../validations';
import { VineaButton } from '../../../components/VineaButton';
import { VineaAlert } from '../../../components/VineaAlert';
import {
  DeleteIdentityAlert,
  IdentityActivationSwitch,
  DeleteIdentityButton,
} from '../../../components/IdentityComponents';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import {
  apiTypes,
  IdentityTypeIds,
  reducers,
  sagaActionTypes,
  vineaDetails,
} from '../../../constants';
import { SplitButton } from '../../../components/SplitButton';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { DiscardDialog } from '../../../components/Dialog';
import { statechecker } from '../../../utils/statecomparator';
import { useIdentityStatus } from '../../../hooks/useIdentityStatus';
import {
  getActivityGroup,
  getResourceTypeUnit,
  getCostingUnit,
  getWorkUnit,
  getDuration,
} from '../../../redux/selectors';

// import logger from '../../../utils/winstonLogger';

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 300,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 350,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

export const ManageActivity = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = React.useState(false);
  const [initialAPITrigger, setInitialAPITrigger] = useState(true);
  const [formLoadingState, setFormLoadingState] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [saveIndex, setSaveIndex] = useState(0);
  const identityTypeIdVal = useIdentityTypeId();
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [formWriteReloadState, setFormWriteReloadState] = useState(false);

  const activityGroupList = useSelector(state => getActivityGroup(state));
  const resourceTypeUnitList = useSelector(state => getResourceTypeUnit(state));
  const costingUnitList = useSelector(state => getCostingUnit(state));
  const workUnitList = useSelector(state => getWorkUnit(state));
  const durationList = useSelector(state => getDuration(state));
  const [deleteHasFailed, setDeleteHasFailed] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const {
    isLoading: activityIsLoading,
    isLoaded: activityIsLoaded,
    hasError: activityHasError,
  } = useSelector(VineaNovaSelectors.getIdentityActivityEntityMeta);

  const isActive = useIdentityStatus();
  const { id: pathParam } = useParams();

  const [formdata, dispatch] = useReducer(
    addGenericReducer,
    manageActivityState,
  );
  const {
    isLoading,
    isLoaded,
    hasError,
    data: activityData,
    error,
  } = useSelector(state => state.formWrite);
  let errorFromAPI = '';

  if (error) {
    errorFromAPI = error.data;
  }
  const [updateApiStatus, setUpdateApiStatus] = React.useState(false);
  const defaultColour = '#C2C2C2';

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleOnBlurOrgName = () => {
    const validationErrors = syncValidator(ActivitySchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  const handleSave = (event, index) => {
    setSaveIndex(index);
    const validationErrors = syncValidator(ActivitySchema)(formdata);
    if (!isEmpty(validationErrors)) {
      dispatch({ type: types.ERROR, payload: validationErrors });
    } else if (
      activityData.activityCode === formdata.activityCode &&
      activityData.activityDescription === formdata.activityDescription &&
      activityData.activityGroupID === formdata.activityGroupID &&
      activityData.colourHexCode === formdata.colourHexCode &&
      activityData.costingUnitID === formdata.costingUnitID &&
      activityData.isPlannable === formdata.isPlannable &&
      activityData.resourceUnitID === formdata.resourceUnitID &&
      activityData.velocityDurationID === formdata.velocityDurationID &&
      activityData.workUnitID === formdata.workUnitID
    ) {
      setWarningInSubmit(true);
    } else {
      setWarningInSubmit(false);
      setErrorInSubmit(false);
      const data = {
        id: activityData.id,
        identityTypeID: IdentityTypeIds.ACTIVITY,
        activityCode: formdata.activityCode,
        activityDescription: formdata.activityDescription,
        isPlannable: formdata.isPlannable,
        resourceUnitID: formdata.resourceUnitID,
        workUnitID: formdata.workUnitID,
        costingUnitID: formdata.costingUnitID,
        velocityDurationID: formdata.velocityDurationID,
        activityGroupID: formdata.activityGroupID,
        ts: activityData.ts,
        colourHexCode: formdata.colourHexCode,
      };
      dispatchAPI({
        // need to change payload
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.activity,
          methodType: apiTypes.PUT,
        },
      });
      setUpdateApiStatus(true);
      setFormWriteReloadState(true);
    }
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleClose = () => {
    if (!saveButtonStatus) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleOnConfirmDelete = () => {
    if (pathParam) {
      setIsDeleting(true);
      dispatchAPI(
        VineaNovaActions.api.v1.identityActivity.delete.request({
          queryParams: {
            IdentityID: pathParam,
          },
        }),
      );
    }
  };

  const handleOnToggleActive = () => {
    navigate(`/activity/${pathParam}/managestatus`);
  };

  const handleColorChange = color => {
    dispatch({ type: types.UPDATE, payload: { colourHexCode: color } });
  };

  const handleCheckboxChange = event => {
    const {
      target: { checked },
    } = event;
    dispatch({ type: types.UPDATE, payload: { isPlannable: checked } });
  };

  useEffect(() => {
    if (!isLoading && isLoaded && !isEmpty(activityData) && !hasError) {
      const stubActivityData = {
        ...activityData,
      };
      const stubFormData = omit(formdata, ['errors', 'activityResourceTypeID']);
      if (statechecker(stubFormData, stubActivityData)) {
        setSaveButtonStatus(true);
      } else setSaveButtonStatus(false);
    }
  }, [isLoading, isLoaded, activityData, hasError, formdata]);

  useEffect(() => {
    if (isLoaded && !hasError && initialAPITrigger) {
      setInitialAPITrigger(false);
      setFormLoadingState(false);
      dispatch({
        type: types.UPDATE,
        payload: {
          activityCode: activityData.activityCode,
          activityDescription: activityData.activityDescription,
          isPlannable: activityData.isPlannable,
          resourceUnitID: activityData.resourceUnitID,
          workUnitID: activityData.workUnitID,
          costingUnitID: activityData.costingUnitID,
          velocityDurationID: activityData.velocityDurationID,
          activityGroupID: activityData.activityGroupID,
          createdDate: activityData.createdDate,
          id: activityData.id,
          identityTypeID: activityData.identityTypeID,
          colourHexCode: activityData.colourHexCode,
          ts: activityData.ts,
        },
      });
    }
  }, [
    isLoaded,
    hasError,
    initialAPITrigger,
    isLoading,
    formLoadingState,
    activityData.activityCode,
    activityData.activityDescription,
    activityData.activityGroupID,
    activityData.isPlannable,
    activityData.resourceUnitID,
    activityData.workUnitID,
    activityData.costingUnitID,
    activityData.velocityDurationID,
    activityData.activityWorkUnitID,
    resourceTypeUnitList,
    activityData.createdDate,
    activityData.id,
    activityData.identityTypeID,
    activityData.ts,
    activityData.colourHexCode,
  ]);

  useEffect(() => {
    if (isLoading) {
      setFormLoadingState(true);
    }
    if (!hasError && isLoaded && updateApiStatus && formLoadingState) {
      enqueueSnackbar('Success!', { variant: 'success' });
      setAlertType(true);
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
      if (saveIndex === 0) navigate(`/activity/${pathParam}/defaultrates`);
      else if (formWriteReloadState && saveIndex === 1) {
        setFormWriteReloadState(false);
        dispatchAPI({
          type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
          payload: { id: activityData.id, name: reducers.activity },
        });
      }
      setFormLoadingState(false);
    } else if (hasError && isLoaded && updateApiStatus) {
      setErrorInSubmit(true);
      enqueueSnackbar(t('Error'), { variant: 'Error' });
    }
  }, [
    isLoaded,
    updateApiStatus,
    enqueueSnackbar,
    t,
    navigate,
    hasError,
    activityData.id,
    saveIndex,
    warningInSubmit,
    isLoading,
    formLoadingState,
    dispatchAPI,
    identityTypeIdVal,
    formWriteReloadState,
    pathParam,
  ]);

  useEffect(() => {
    if (isDeleting && !activityIsLoading && activityIsLoaded) {
      if (activityHasError) {
        setDeleteHasFailed(true);
      } else {
        navigate('/activity');
      }

      setIsDeleting(false);
    }
  }, [
    activityIsLoaded,
    activityIsLoading,
    activityHasError,
    isDeleting,
    setIsDeleting,
    navigate,
  ]);

  return (
    <Paper
      elevation={0}
      data-testid="manageactivity-detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12}>
          <Box mb={4}>
            <VineaAlert
              isOpen={!!errorInSubmit}
              onExit={() => setErrorInSubmit(false)}
            >
              <p>{errorFromAPI}</p>
            </VineaAlert>
            <VineaAlert
              isOpen={alertType}
              onExit={() => setAlertType(false)}
              alertType="success"
              message="Activity Updated"
            />
            <DeleteIdentityAlert
              isOpen={deleteHasFailed}
              onExit={() => setDeleteHasFailed(false)}
              identityName="activity"
            />
          </Box>
          <Box px={2}>
            {warningInSubmit && (
              <Collapse in={warningInSubmit}>
                <Alert
                  variant="standard"
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarningInSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Warning: Nothing to Update
                </Alert>
              </Collapse>
            )}
          </Box>
          <Stack direction="row" justifyContent="space-around">
            <Box>
              <StyledFormBoxControl mt={1}>
                <FormControl>
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="name"
                    name="activityCode"
                    label={t('Activity Code')}
                    size="small"
                    inlineLabel
                    value={formdata.activityCode}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    error={formdata.errors.activityCode}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl sx={{ height: '100px' }}>
                  <VineaTextField
                    autoComplete="off"
                    id="description"
                    name="activityDescription"
                    label={t('Description')}
                    size="large"
                    inlineLabel
                    value={formdata.activityDescription}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    error={formdata.errors.activityDescription}
                    multiline
                    maxRows={6}
                    minRows={4}
                  />
                </FormControl>
              </StyledFormBoxControl>
            </Box>
            <Box>
              <StyledFormBoxControl mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                  error={!!formdata.errors.activityGroupID}
                >
                  <VineaAutoComplete
                    value={formdata.activityGroupID}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    options={activityGroupList}
                    labelVariant="body1"
                    label={t('Activity Group')}
                    name={t('Activity Group')}
                    inlineLabel
                    inputProps={{
                      name: 'activityGroupID',
                    }}
                  />
                  {formdata.errors.activityGroupID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.activityGroupID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                  error={!!formdata.errors.resourceUnitID}
                >
                  <VineaAutoComplete
                    value={formdata.resourceUnitID}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    options={resourceTypeUnitList}
                    labelVariant="body1"
                    label={t('Resource Unit Type')}
                    name={t('Unit Type')}
                    inlineLabel
                    inputProps={{
                      name: 'resourceUnitID',
                    }}
                  />
                  {formdata.errors.resourceUnitID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.resourceUnitID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                  error={!!formdata.errors.costingUnitID}
                >
                  <VineaAutoComplete
                    value={formdata.costingUnitID}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    options={costingUnitList}
                    labelVariant="body1"
                    label={t('Activity Cost Unit')}
                    name={t('Activity Cost Unit')}
                    inlineLabel
                    inputProps={{
                      name: 'costingUnitID',
                    }}
                  />
                  {formdata.errors.costingUnitID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.costingUnitID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledFormBoxControl>
            </Box>
            <Box>
              <StyledFormBoxControl mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                  error={!!formdata.errors.workUnitID}
                >
                  <VineaAutoComplete
                    value={formdata.workUnitID}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    options={workUnitList}
                    labelVariant="body1"
                    label={t('Activity Work Unit')}
                    name={t('Activity Work Unit')}
                    inlineLabel
                    inputProps={{
                      name: 'workUnitID',
                    }}
                  />
                  {formdata.errors.workUnitID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.workUnitID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                  error={!!formdata.errors.velocityDurationID}
                >
                  <VineaAutoComplete
                    value={formdata.velocityDurationID}
                    onChange={handleChange}
                    onBlur={handleOnBlurOrgName}
                    options={durationList}
                    labelVariant="body1"
                    label={t('Activity Duration')}
                    name={t('Activity Duration')}
                    inlineLabel
                    inputProps={{
                      name: 'velocityDurationID',
                    }}
                  />
                  {formdata.errors.velocityDurationID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.velocityDurationID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledFormBoxControl>
              <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    marginTop: theme.spacing(1),
                    marginLeft: theme.spacing(2),
                    marginBottom: theme.spacing(1),
                  }}
                  mt={1}
                >
                  <FormControl
                    sx={{
                      marginTop: theme.spacing(1),
                    }}
                    margin="none"
                    error={!!formdata.errors.colourHexCode}
                  >
                    <ColorPickerComponent
                      color={
                        formdata.colourHexCode
                          ? formdata.colourHexCode
                          : defaultColour
                      }
                      onChange={handleColorChange}
                    />
                    {formdata.errors.colourHexCode && (
                      <FormHelperText id="component-error-text">
                        {formdata.errors.colourHexCode}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <FormGroup sx={{ margin: '10px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formdata.isPlannable}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Plannable"
                  />
                </FormGroup>
              </Stack>
            </Box>
          </Stack>
          <IdentityActivationSwitch
            handleOnToggleActive={handleOnToggleActive}
            isActive={isActive}
            identityName="activity"
          />
          <Box my={2}>
            <DeleteIdentityButton
              handleOnDelete={handleOnConfirmDelete}
              identityName="activity"
              isDisabled={deleteHasFailed}
            />
          </Box>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
            }}
          >
            <Box mx={2} mt={1}>
              <SplitButton
                color="success"
                onClick={handleSave}
                variant="contained"
                minWidth={130}
                disabled={saveButtonStatus}
                data-testid="btnsuccess"
              />
            </Box>
            <Box mx={2} mt={1}>
              <VineaButton
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                {t('Close')}
              </VineaButton>
            </Box>
          </Grid>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={handleOnDialogClose}
            handleSaveChanges={handleSave}
            handleDiscardChanges={handleCancel}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
