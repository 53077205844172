/* eslint-disable react/prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import {
  DataGridPro,
  useGridApiContext,
  useGridRootProps,
} from '@mui/x-data-grid-pro';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import isEqual from 'lodash/isEqual';

// import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { CustomGridLoadingOverlay } from '../Grid/CustomGridLoadingOverlay';
import PreferancePanel from '../PreferancePopover/PreferancePopover';

export const PreferencePanelMenu = React.forwardRef((props, ref) => {
  // const [open, setOpen] = React.useState(null);

  console.log('propscol', props);
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  console.log('gridSlotCompPropsAPi', ref, apiRef, rootProps);
  const { state } = apiRef.current;
  const { preferencePanel, columns } = state;
  const { hidePreferences, updateColumns } = apiRef.current;
  // eslint-disable-next-line react/prop-types

  React.useState(() => {}, [preferencePanel]);

  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    hidePreferences();
  };

  const handleUpdate = () => {
    updateColumns();
  };
  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  const handleOnDispatchActionType = rootProps.componentsProps.actionType;

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={!!preferencePanel.open}
        sx={{
          '& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthLg.MuiPaper-elevation24.MuiPaper-rounded':
            {
              width: '300px',
            },
          '& .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters': {
            justifyContent: 'space-between',
          },
        }}
        maxWidth="lg"
      >
        <Toolbar>
          <Button autoFocus color="primary">
            HIDE ALL
          </Button>
          <Button autoFocus color="primary">
            SHOW ALL
          </Button>
        </Toolbar>
        <PreferancePanel
          handleOnClose={handleClose}
          handleOnUpdate={handleUpdate}
          columnsData={columns}
          actionType={handleOnDispatchActionType}
        />
      </Dialog>
    </>
  );
});

function GridWithFilter({
  data,
  onRowClick,
  className,
  updateDataGridColumns,
  actionType,
  ...restProps
}) {
  const theme = useTheme();
  const handleOnRowClick = evt => {
    onRowClick(evt);
  };
  const handleOnColumnsChange = params => {
    const { field, isVisible } = params;
    updateDataGridColumns({ [field]: isVisible });
  };

  // const handleOnPreferancePanelChange = () => {
  //   console.log('Preferance Panel Change');
  // };

  return (
    <DataGridPro
      data-testid="data-grid-root"
      sx={{
        '& .MuiDataGrid-main .rendering-zone>div:nth-of-type(even)': {
          backgroundColor: theme.palette.action.hover,
        },
        '& .vinea-xgrid--false': {
          backgroundColor: theme.palette.action.hover,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        '& .MuiDataGrid-row.Mui-selected': {
          backgroundColor: 'rgba(25, 118, 210, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(25, 118, 210, 0.12)',
          },
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
          color: theme.palette.secondary.main,
        },
      }}
      components={{
        LoadingOverlay: CustomGridLoadingOverlay,
        PreferencesPanel: PreferencePanelMenu,
      }}
      componentsProps={{ actionType }}
      onRowClick={handleOnRowClick}
      {...data}
      {...restProps}
      filterModel={{
        items: [],
      }}
      pinnedColumns={{ left: ['name'] }}
      onColumnVisibilityChange={handleOnColumnsChange}
    />
  );
}

GridWithFilter.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string]),
    }),
  ).isRequired,
  onRowClick: PropTypes.func,
  updateDataGridColumns: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

GridWithFilter.defaultProps = {
  onRowClick: f => f,
  updateDataGridColumns: f => f,
  className: '',
};
// {
//   columnField: 'id',
//   operatorValue: 'contains',
//   value: '0',
// },
function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.data, nextProps.data);
}

export default React.memo(GridWithFilter, areEqual);
