import React from 'react';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { VineaButton } from '../../components/VineaButton';

const StyledToolbar = styled(Toolbar)(() => ({
  root: {
    paddingRight: 24,
  },
}));

const Logout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={0}
      sx={{
        margin: 0,
        width: '99%',
      }}
    >
      <AppBar
        position="fixed"
        data-testid="appbar"
        sx={{
          backgroundColor: theme.palette.white.main,
        }}
      >
        <StyledToolbar disableGutters>
          <IconButton
            color="primary"
            aria-label="Open drawer"
            sx={{
              marginLeft: 12,
              marginRight: 18,
            }}
            data-testid="icon-button"
            onClick={() => navigate('/')}
            size="large"
          >
            <ion-icon name="logo-react" size="large" />
          </IconButton>
          <Typography variant="h6" noWrap data-testid="header-text">
            Vinea Nova
          </Typography>
        </StyledToolbar>
      </AppBar>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flex: 1,
          height: '80vh',
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Box
            mt={3}
            alignItems="center"
            justifyContent="center"
            display="flex"
            flexDirection="column"
          >
            <Typography component="p" variant="h1">
              {t('You are now logged out!')}
            </Typography>
          </Box>
          <Box
            mt={1}
            alignItems="center"
            justifyContent="center"
            display="flex"
            flexDirection="column"
          >
            <Typography component="p" variant="body1">
              {t('Thank you and have a great day.')}
            </Typography>
          </Box>
          <Box mt={3}>
            <VineaButton
              variant="contained"
              size="large"
              color="success"
              data-testid="btn-learnmore"
              onClick={() => navigate('/')}
            >
              {t('Go Home')}
            </VineaButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

Logout.propTypes = {
  //   history: historyPropTypes.isRequired,
};

const mapStateToProps = state => ({
  authUser: state.authentication,
  language: state.language.selectedLanguage,
});
const mapDispatchToProps = dispatch => ({
  languageSelect: langValue =>
    dispatch({ type: 'LANGUAGE_CHANGE', payload: langValue }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Logout);
