import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import { useTheme, styled } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import get from 'lodash/get';
import DeleteIcon from '@mui/icons-material/Delete';
import { ErrorBoundary } from 'react-error-boundary';

import { VineaButton } from '../../../components/VineaButton';
import { Datepicker } from '../../../components/Datepicker';
import { VineaTextField } from '../../../components/TextField';
import { VineaTextFieldWithFooterSwitch } from '../../../components/TextField/VineaTextFieldWithFooterSwitch';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { ImageUpload } from '../../../components/ImageUpload';
import useImageUtils from '../../../hooks/ImageUtils';
import { CropBlockVintageSchema } from './validations';
import { syncValidator } from '../../../utils/validator';
import { DiscardDialog, DeleteDialog } from '../../../components/Dialog';
import { viewDateFormat } from '../../../constants';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';

const StyledDiv = styled('div')(() => ({
  marginTop: 1,
}));

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

const StyledDrawerControl = styled('div')(() => ({
  width: 350,
}));

const StyledCloseDiv = styled('div')(() => ({
  paddingLeft: 10,
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
  display: 'flex',
}));

export const VintageMeasureDrawer = ({
  open,
  onClose,
  data,
  handleOnSave,
  blockSelected,
  vineyardSelected,
  lkpMeasureInstance = [],
  onDelete,
  handleOnImageUpload,
  editedNoteImage,
  onDeleteNoteImage,
}) => {
  const theme = useTheme();
  const [state, setState] = React.useState(open);
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(null);
  const [deleteVintageDialog, setVintageDeleteDialog] = React.useState(null);

  const imageRef = React.useRef(null);
  const { t } = useTranslation();
  const { isEdit = false, measure, isAdd = false, cropNoteID } = data;
  const [selectedRow, setSelectedRow] = React.useState(data);
  const [validationErrors, setValidationErrors] = React.useState({});
  const {
    measureDate,
    cropMeasurementComment,
    measureInstanceId,
    isPrivate,
    noteImage,
  } = selectedRow || {};

  const { imageDataUrl } = useImageUtils(editedNoteImage?.base64 || noteImage);

  const measureInstanceOptions = lkpMeasureInstance
    .filter(
      mi =>
        mi.id === 0 ||
        (mi.isEditable && mi.isActive && mi.measureGroupID === 1),
    )
    .map(mi => ({ ...mi, value: mi.measureInstanceReportName }));

  React.useEffect(() => {
    setSelectedRow(data);
  }, [data]);

  React.useEffect(() => {
    setState(open);
  }, [open]);

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const onSave = () => {
    const currentErrors = syncValidator(CropBlockVintageSchema)(selectedRow);
    setValidationErrors(currentErrors);
    if (isEmpty(currentErrors)) {
      handleOnSave(selectedRow);
    }
  };

  const handleOnChange = event => {
    const {
      target: { name, value },
    } = event;
    let { isValuePair: currentValuePair } = selectedRow;
    if (name === 'measureInstanceId') {
      currentValuePair = get(
        measureInstanceOptions.find(mi => mi.id === value),
        'isValuePair',
        false,
      );
    }

    setSelectedRow({
      ...selectedRow,
      [name]: value,
      isValuePair: currentValuePair,
      hasChanges: true,
    });
  };

  const handleOnImageUploadData = imageSet => {
    if (imageSet && imageSet.base64) {
      handleOnImageUpload(imageSet);
      setSelectedRow({
        ...selectedRow,
        hasChanges: true,
      });
    }
  };

  const handleOnSwitchChange = evt => {
    const {
      target: { checked },
    } = evt;
    setSelectedRow({ ...selectedRow, isPrivate: checked, hasChanges: true });
  };

  const handleOnCloseVintageDeleteDialog = () => {
    setVintageDeleteDialog(false);
  };

  const handleOnDeleteVintageRequest = () => {
    setVintageDeleteDialog(true);
  };

  const handleOnDeleteVintageRequestConfirm = () => {
    onDelete();
  };

  const handleOnDeleteImage = () => {
    onDeleteNoteImage(cropNoteID);
  };

  const handleOnDiscard = () => {
    const { hasChanges } = selectedRow;
    if (hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    onClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    onSave();
  };

  const vineyardBlockSelected = `${vineyardSelected || ''} ${
    blockSelected ? '/' : ''
  } ${blockSelected || ''}`;

  const drawerSubtitle = `${
    selectedRow.isEditable ? 'Edit Crop Measure' : 'View Crop Measure'
  }`;

  const renderValueFields = rowValue => {
    const { isValuePair, measureValue1, measureValue2 } = rowValue;
    return (
      <>
        <StyledFormControl error={validationErrors.measureValue1}>
          <VineaTextField
            fullWidth
            autoComplete="off"
            id="measureValue1"
            size="small"
            label={t('Value 1')}
            name="measureValue1"
            value={measureValue1}
            onChange={handleOnChange}
            inputProps={{
              readOnly: !selectedRow.isEditable,
            }}
          />
          {validationErrors.measureValue1 && (
            <FormHelperText id="component-error-text">
              {validationErrors.measureValue1}
            </FormHelperText>
          )}
        </StyledFormControl>
        {isValuePair && (
          <StyledFormControl error={validationErrors.measureValue2}>
            <VineaTextField
              fullWidth
              autoComplete="off"
              id="measureValue2"
              size="small"
              label="Value 2"
              name="measureValue2"
              value={measureValue2}
              onChange={handleOnChange}
              inputProps={{
                readOnly: !selectedRow.isEditable,
              }}
            />
            {validationErrors.measureValue2 && (
              <FormHelperText id="component-error-text">
                {validationErrors.measureValue2}
              </FormHelperText>
            )}
          </StyledFormControl>
        )}
      </>
    );
  };

  const list = () => (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleOnClickAway}>
      <StyledDrawerControl>
        <StyledDiv
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography
                sx={{
                  padding: theme.spacing(2),
                }}
                variant="body1"
              >
                {vineyardBlockSelected}
              </Typography>
            </Box>
            <Box>
              <IconButton
                aria-label="toggle sidebar"
                onClick={handleOnDiscard}
                onMouseDown={() => {}}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {isEdit && (
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  paddingLeft: theme.spacing(2),
                }}
              >
                {t(`${drawerSubtitle}`)}
              </Typography>
              <Typography
                sx={{
                  paddingLeft: theme.spacing(2),
                  paddingBottom: theme.spacing(2),
                }}
                component="span"
              >
                {measure}
              </Typography>
            </Box>
          )}
          {isAdd && (
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  paddingLeft: theme.spacing(2),
                }}
              >
                {t('Add Crop Measure')}
              </Typography>
              <Typography
                sx={{
                  paddingLeft: theme.spacing(2),
                  paddingBottom: theme.spacing(2),
                }}
                component="span"
              >
                {measure}
              </Typography>
            </Box>
          )}

          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />
            {!selectedRow.isEditable && (
              <Box>
                <Typography
                  sx={{
                    padding: theme.spacing(2),
                    color: 'red',
                  }}
                  variant="subtitle2"
                >
                  {t('This measure is read-only')}
                </Typography>
              </Box>
            )}

            <FormControl
              sx={{
                padding: theme.spacing(2),
                flex: 1,
                display: 'flex',
                marginTop: 6,
              }}
              data-testid="datepicker-formcontrol"
              error={validationErrors.measureDate}
            >
              <Datepicker
                disablePast={false}
                id="measureDate"
                label={t('Measure Date')}
                placeholder="DD/MM/YYYY"
                inputFormat={viewDateFormat}
                variant="outlined"
                size="small"
                name="measureDate"
                value={measureDate}
                onChange={handleOnChange}
                inputProps={{
                  'data-id': 'measureDate',
                  'data-name': 'measureDate',
                  placeholder: 'DD/MM/YYYY',
                  readOnly: !selectedRow.isEditable,
                }}
                fullWidth
              />
              {validationErrors.measureDate && (
                <FormHelperText id="component-error-text">
                  {validationErrors.measureDate}
                </FormHelperText>
              )}
            </FormControl>
            {isAdd && (
              <>
                <Box px={2} data-testid="addMeasureInstance">
                  <VineaAutoComplete
                    value={measureInstanceId}
                    onChange={handleOnChange}
                    inlineLabel
                    options={measureInstanceOptions}
                    labelVariant="body1"
                    label={t('Measure Instance')}
                    name={t('Measure')}
                    displayLabel
                    inputProps={{
                      name: 'measureInstanceId',
                    }}
                    data-testid="measureinstance-picker"
                    fullWidth
                    error={validationErrors.measureInstanceId}
                  />
                </Box>
              </>
            )}

            {/** render value1 and value2 fields */}
            {renderValueFields(selectedRow)}

            <StyledFormControl>
              <VineaTextFieldWithFooterSwitch
                fullWidth
                autoComplete="off"
                id="note"
                size="small"
                label={t('Comment')}
                multiline
                rowsMax={4}
                rows={3}
                value={cropMeasurementComment}
                name="cropMeasurementComment"
                onChange={handleOnChange}
                inputProps={{
                  readOnly: !selectedRow.isEditable,
                }}
                onSwitchChange={handleOnSwitchChange}
                checked={!!isPrivate}
              />
            </StyledFormControl>

            {selectedRow.isEditable && (
              <>
                <StyledFormControl>
                  <ImageUpload
                    handleOnChange={handleOnImageUploadData}
                    ref={imageRef}
                  />
                </StyledFormControl>
              </>
            )}

            <Box>
              {imageDataUrl && (
                <StyledFormControl>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      boxShadow: 'none',
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        sx={{
                          height: '300px',
                          width: '300px',
                          flex: 1,
                          flexDirection: 'column',
                        }}
                        image={imageDataUrl}
                        title="vintageimage"
                      />
                    </CardActionArea>
                    <CardActions>
                      {selectedRow.isEditable && (
                        <IconButton
                          aria-label="delete"
                          onClick={handleOnDeleteImage}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </CardActions>
                  </Card>
                </StyledFormControl>
              )}
            </Box>
            {selectedRow.isEditable && (
              <StyledButtonControlDiv>
                <div>
                  <VineaButton
                    color="success"
                    onClick={onSave}
                    variant="contained"
                    minWidth={100}
                  >
                    {t('Save')}
                  </VineaButton>
                </div>
                {isEdit && (
                  <StyledCloseDiv>
                    <VineaButton
                      variant="contained"
                      onClick={handleOnDeleteVintageRequest}
                      color="error"
                    >
                      {t('Delete')}
                    </VineaButton>
                  </StyledCloseDiv>
                )}
              </StyledButtonControlDiv>
            )}
          </ErrorBoundary>
        </StyledDiv>

        <DeleteDialog
          onClose={handleOnCloseVintageDeleteDialog}
          dialogHeader={`Delete measure for ${selectedRow.measure}?`}
          dialogContent="This action is irreversible"
          open={deleteVintageDialog}
          rowId={0}
          dialogActionTrigger={handleOnDeleteVintageRequestConfirm}
        />
        <DiscardDialog
          open={discardDialogOpen}
          onClose={() => setDiscardDialogOpen(false)}
          handleSaveChanges={handleOnDiscardSaveChanges}
          handleDiscardChanges={handleOnDiscardConfirm}
        />
      </StyledDrawerControl>
    </ClickAwayListener>
  );

  return (
    <Drawer anchor="right" open={state} onClose={toggleDrawer}>
      {list('right')}
    </Drawer>
  );
};

VintageMeasureDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isEdit: PropTypes.bool,
    isAdd: PropTypes.bool,
    measure: PropTypes.string,
    cropNoteID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  blockSelected: PropTypes.string,
  vineyardSelected: PropTypes.string,
  lkpMeasureInstance: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  handleOnImageUpload: PropTypes.func.isRequired,
  editedNoteImage: PropTypes.shape({
    base64: PropTypes.string,
  }),
  onDeleteNoteImage: PropTypes.func.isRequired,
};

VintageMeasureDrawer.defaultProps = {
  vineyardSelected: '',
  blockSelected: '',
  editedNoteImage: {},
  lkpMeasureInstance: [],
};
