/* eslint-disable react/prop-types */
import React from 'react';
import { isEmpty, sortBy } from 'lodash';
import GridWithFilter from './GridWithFilter';
import { filterDataGridColumns } from './FilterGridColumns';

export const FilterGridBlockVintages = ({
  handleOnRowClick,
  handleOnCellClick,
  isLoading = false,
  samplesData = [],
  className = '',
  selectionModel = [],
}) => {
  const gridXData = {
    columns: filterDataGridColumns,
    rows: isEmpty(samplesData) ? [] : sortBy(samplesData, 'blockName'),
  };

  return (
    <GridWithFilter
      className={className}
      data={gridXData}
      onRowClick={handleOnRowClick}
      onCellClick={handleOnCellClick}
      rowHeight={30}
      autoPageSize
      loading={isLoading}
      hideFooter={isEmpty(gridXData.rows)}
      hideFooterSelectedRowCount
      selectionModel={selectionModel}
      advancedSearch={false}
    />
  );
};
