/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { VineaAutoComplete } from '../ComboBox';
import { FilterGridBlockVintages } from './FilterGridBlockVintages';
import { VineaButton } from '../VineaButton';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';

export function FilterScreen({
  filterType,
  search,
  lookupVintages = [],
  lookupRegions = [],
  lookupVintageLatestId,
  selectedSubRegionIdValue,
  selectedVineyardIdValue,
  onUpdateFilterData,
  searchFilterData,
  vineyardList = [],
  sampleData = [],
  className,
  handleOnBlockRowClick,
  // url = '',
  isLoading = false,
}) {
  const { t } = useTranslation();
  const [vintageIDError, setVintageIDError] = useState('');
  const [vineyardIDError, setVineyardIDError] = useState('');
  const [subRegionIDError, setSubRegionIDError] = useState('');

  const {
    vintageID = 0,
    subRegionID = 0,
    vineyardID = 0,
    vineyardBlockId = 0,
  } = searchFilterData || {};

  const selectionModel = vineyardBlockId ? [vineyardBlockId] : [];

  useEffect(() => {
    if (!vintageID && lookupVintageLatestId) {
      onUpdateFilterData({
        ...searchFilterData,
        vintageID: lookupVintageLatestId,
      });
    }
  }, [onUpdateFilterData, searchFilterData, lookupVintageLatestId, vintageID]);

  useEffect(() => {
    if (!subRegionID && selectedSubRegionIdValue) {
      onUpdateFilterData({
        ...searchFilterData,
        subRegionID: selectedSubRegionIdValue,
      });
    }
  }, [
    onUpdateFilterData,
    searchFilterData,
    subRegionID,
    selectedSubRegionIdValue,
  ]);

  const handleOnSelectVintages = evt => {
    const evtValue = evt.target.value;
    setVintageIDError(false);
    onUpdateFilterData({
      ...searchFilterData,
      vintageID: evtValue,
    });
  };

  const handleOnSelectRegion = evt => {
    const evtValue = evt.target.value;
    setSubRegionIDError(false);
    onUpdateFilterData({
      ...searchFilterData,
      subRegionID: evtValue,
    });
  };

  const handleOnSelectVineyard = evt => {
    const evtValue = evt.target.value;
    setVineyardIDError(false);
    onUpdateFilterData({
      ...searchFilterData,
      vineyardID: evtValue || selectedVineyardIdValue,
    });
  };

  const handleOnSearch = () => {
    // null is required to fetch all records
    const data = {
      vintageID: vintageID || lookupVintageLatestId,
      subRegionID: subRegionID || selectedSubRegionIdValue,
      vineyardID: vineyardID || selectedVineyardIdValue,
    };

    if (!data.vintageID) {
      setVintageIDError('Required');
    } else if (!data.subRegionID) {
      setSubRegionIDError('Required');
    } else if (!vineyardID || !data.vineyardID) {
      setVineyardIDError('Required');
    } else {
      setVintageIDError('');
      setSubRegionIDError('');
      setVineyardIDError('');
      search(data);
    }
  };

  const handleOnClearSearch = () => {
    setSubRegionIDError(false);
    setVineyardIDError(false);
    setVintageIDError(false);
    onUpdateFilterData({
      vintageID: 0,
      subRegionID: 0,
      measureID: 0,
      vineyardBlockId: 0,
      clearRows: true,
    });
  };

  return (
    <Stack
      container
      spacing={0}
      sx={{
        paddingBottom: 3,
        height: '100%',
      }}
      data-testid="filter-screen"
    >
      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <ErrorBoundary
              FallbackComponent={({ ...props }) => (
                <Box sx={{ width: '400px', height: '100%' }}>
                  <ErrorBoundaryFallback {...props} />
                </Box>
              )}
            >
              <Box
                pl={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant="subtitle2"
                  noWrap
                  data-testid="header-text"
                >
                  {t('Filters')}
                </Typography>
              </Box>
              <Box
                py={2}
                px={2}
                sx={{
                  '& > *': {
                    display: 'flex',
                  },
                  '& > .MuiFormControl-root': {
                    minWidth: 350,
                  },
                }}
                data-testid="vintages"
              >
                <FormControl error={!!vintageIDError} margin="none">
                  <VineaAutoComplete
                    value={vintageID || lookupVintageLatestId}
                    onChange={handleOnSelectVintages}
                    inlineLabel
                    options={lookupVintages}
                    labelVariant="body1"
                    label={t('Vintage')}
                    name={t('Vintages')}
                    displayLabel
                    inputProps={{
                      name: 'vintageID',
                    }}
                  />
                  {vintageIDError && (
                    <FormHelperText id="component-error-text">
                      {vintageIDError}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box
                py={2}
                px={2}
                sx={{
                  flex: 1,
                  '& > *': {
                    flex: 1,
                    display: 'flex',
                  },
                  '& > .MuiFormControl-root': {
                    minWidth: 350,
                  },
                }}
                data-testid="regions"
              >
                <FormControl error={!!subRegionIDError} margin="none">
                  <VineaAutoComplete
                    value={subRegionID || selectedSubRegionIdValue}
                    onChange={handleOnSelectRegion}
                    inlineLabel
                    options={lookupRegions}
                    labelVariant="body1"
                    label={t('Sub Region')}
                    name={t('Sub Regions')}
                    displayLabel
                    inputProps={{
                      name: 'subRegionID',
                    }}
                  />
                  {subRegionIDError && (
                    <FormHelperText id="component-error-text">
                      {subRegionIDError}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box
                py={2}
                px={2}
                sx={{
                  flex: 1,
                  '& > *': {
                    flex: 1,
                    display: 'flex',
                  },
                  '& > .MuiFormControl-root': {
                    minWidth: 350,
                  },
                }}
                data-testid="vineyardID"
              >
                <FormControl error={!!vineyardIDError} margin="none">
                  <VineaAutoComplete
                    value={vineyardID || selectedVineyardIdValue}
                    onChange={handleOnSelectVineyard}
                    inlineLabel
                    options={vineyardList}
                    labelVariant="body1"
                    label={t('Vineyard')}
                    name={t('Vineyard')}
                    displayLabel
                    inputProps={{
                      name: 'vineyardID',
                    }}
                  />
                  {vineyardIDError && (
                    <FormHelperText id="component-error-text">
                      {vineyardIDError}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box display="flex" mx={2} my={1} style={{ height: '100%' }}>
                <FilterGridBlockVintages
                  filterType={filterType}
                  className={className}
                  handleOnRowClick={handleOnBlockRowClick}
                  isLoading={isLoading}
                  samplesData={sampleData}
                  selectionModel={selectionModel}
                />
              </Box>
              {filterType === 'Tracking' && (
                <Box
                  py={2}
                  px={1}
                  mx={1}
                  sx={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                  data-testid="control-buttons"
                >
                  <VineaButton
                    aria-label="clear"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    data-testid="filter-clear"
                    color="secondary"
                    variant="text"
                    onClick={handleOnClearSearch}
                  >
                    {t('Clear')}
                  </VineaButton>
                  <Box px={1} py={1} />
                  <VineaButton
                    aria-label="Search"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    data-testid="filter-search"
                    color="secondary"
                    variant="outlined"
                    onClick={handleOnSearch}
                  >
                    {t('Search')}
                  </VineaButton>
                </Box>
              )}
            </ErrorBoundary>
          </Box>
        </Box>
      </Paper>
    </Stack>
  );
}

FilterScreen.propTypes = {
  search: PropTypes.func.isRequired,
  lookupVintages: PropTypes.any,
  lookupRegions: PropTypes.any,
  lookupVintageLatestId: PropTypes.number,
  selectedSubRegionIdValue: PropTypes.number,
  selectedVineyardIdValue: PropTypes.number,
  onUpdateFilterData: PropTypes.func.isRequired,
  searchFilterData: PropTypes.any,
  filterType: PropTypes.string,
  vineyardList: PropTypes.any,
  sampleData: PropTypes.any,
  className: PropTypes.any,
  handleOnBlockRowClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

FilterScreen.defaultProps = {
  lookupVintages: [],
  lookupRegions: [],
  lookupVintageLatestId: 0,
  selectedSubRegionIdValue: 0,
  selectedVineyardIdValue: 0,
  searchFilterData: null,
  filterType: 'Sampling',
  vineyardList: [],
  sampleData: [],
  className: '',
  handleOnBlockRowClick: f => f,
  isLoading: false,
};
