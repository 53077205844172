import typography from './typography';

const black = '#000000';

export default {
  mode: 'dark',
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    black,
    white: {
      main: '#FFFFFF',
      dark: '#F5F5F5',
      contrastText: '#2D6D1B',
    },
    primary: {
      main: '#2D6D1B',
      light: '#5D9C47',
      dark: '#002B00',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#1B2D6D',
      light: '#4E569C',
      dark: '#000441',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#4CAF50',
      dark: '#3B873E',
      light: '#7BC67E',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#2196F3',
      dark: '#0B79D0',
      light: '#64B6F7',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#ED6C02',
      dark: '#C77700',
      light: '#FFB547',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#C55245',
      dark: '#E31B0C',
      light: '#F88078',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    background: {
      default: '#F1F4EF',
      paper: 'white',
      snow: '#edf2f6',
    },
    icon: '#7cc0b4',
    divider: 'rgba(0, 0, 0, 0.12)',
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
    },
  },
  spacing: 6,
  typography,
};
