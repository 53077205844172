import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { VineaButton } from '../../../components/VineaButton';
import { VineaAlert } from '../../../components/VineaAlert';
import { FieldLabelInput } from '../../../components/TextField';
import { Datepicker } from '../../../components/Datepicker';
import logger from '../../../utils/winstonLogger';
import { useVineyardBlocksHook } from '../Hooks/useVineyardBlocksHook';
import {
  getLookupVariety,
  getLookupBlockVintageStatus,
  getLookupVarietyCloneByID,
  getLookupRootStock,
} from '../../../redux/selectors';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { SplitButton } from '../../../components/SplitButton';
import { DiscardDialog } from '../../../components/Dialog';
import { viewDateFormat } from '../../../constants';

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  width: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 250,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 250,
}));

const StyledFormControl2 = styled(FormControl)(({ theme }) => ({
  root: { marginTop: theme.spacing(1), minWidth: 250 },
}));

export const ManageBlocksDetails = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname = '' } = location;
  const isNewBlock = pathname.includes('blocks/create');

  // const dispatchAPI = useDispatch();
  const navigate = useNavigate();
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);
  // const blocksData = useSelector(getVineyardBlocks);
  const {
    vineyardBlockSelected,
    blockIsAdHoc,
    errorFromAPI,
    saveButtonStatus,
    formWriteLoading,
    errorInSubmit,
    handleOnChange,
    onUpdate,
    onSaveNew,
    setErrorInSubmit,
    handleOnBlur,
    onDelete,
  } = useVineyardBlocksHook();

  const formdata = { errors: {}, ...vineyardBlockSelected };

  // for populating dropddowns
  // get selector values
  const varietySelector = useSelector(getLookupVariety);
  const blockStatusSelector = useSelector(getLookupBlockVintageStatus);
  const variteyCloneSelector = useSelector(state =>
    getLookupVarietyCloneByID(state, formdata.varietyID),
  );
  const rootStockSelector = useSelector(getLookupRootStock);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleClose = () => {
    if (!saveButtonStatus) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12} data-testid="vineaalert">
          <VineaAlert
            isOpen={!!errorInSubmit}
            onExit={() => setErrorInSubmit(false)}
          >
            <p>{errorFromAPI}</p>
          </VineaAlert>
        </Grid>
        <Grid item xs={12} md={12} lg={8} data-id="grid-top-wrapper">
          <Grid container spacing={2} data-testid="grid-field">
            <Grid item xs={12} sm={6} md={4} data-testid="grid-inner-fields">
              <StyledFormBoxControl mt={1}>
                <FieldLabelInput
                  autoComplete="off"
                  id="blockRef"
                  name="blockRef"
                  autoFocus
                  label={t('Block Reference')}
                  size="small"
                  inlineLabel
                  value={formdata.blockRef}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.blockRef}
                />
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <FieldLabelInput
                  id="sectionReference"
                  name="sectionRef"
                  label={t('Section Reference')}
                  size="small"
                  inlineLabel
                  value={formdata.sectionRef}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.sectionRef}
                />
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <FieldLabelInput
                  id="subSectionRef"
                  name="subSectionRef"
                  label={t('Sub Section Reference')}
                  size="small"
                  inlineLabel
                  value={formdata.subSectionRef}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.subSectionRef}
                />
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl>
                <StyledFormControl
                  error={!!formdata.errors.blockVintageStatusID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.blockVintageStatusID || 0}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    options={blockStatusSelector}
                    labelVariant="body1"
                    label={t('Block Vintage Status')}
                    inlineLabel={false}
                    name={t('Block Vintage Status')}
                    inputProps={{
                      name: 'blockVintageStatusID',
                    }}
                  />
                  {formdata.errors.blockVintageStatusID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.blockVintageStatusID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={2}>
                <StyledFormControl2
                  error={!!formdata.errors.activeFromDate}
                  data-testid="datefrom-formcontrol"
                >
                  <Datepicker
                    maxDate={formdata.activeToDate}
                    id="activeFromDate"
                    inputFormat={viewDateFormat}
                    placeholder="DD/MM/YYYY"
                    variant="outlined"
                    size="small"
                    value={formdata.activeFromDate}
                    onChange={handleOnChange}
                    name="activeFromDate"
                    label={t('Date From')}
                  />
                  {formdata.errors.activeFromDate && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.activeFromDate}
                    </FormHelperText>
                  )}
                </StyledFormControl2>
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={2}>
                <StyledFormControl2
                  error={!!formdata.errors.activeToDate}
                  data-testid="dateto-formcontrol"
                >
                  <Datepicker
                    disablePast={false}
                    id="activeToDate"
                    inputFormat={viewDateFormat}
                    minDate={formdata.activeFromDate}
                    placeholder="DD/MM/YYYY"
                    variant="outlined"
                    size="small"
                    value={formdata.activeToDate}
                    onChange={handleOnChange}
                    name="activeToDate"
                    label={t('Date To')}
                  />
                  {formdata.errors.activeToDate && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.activeToDate}
                    </FormHelperText>
                  )}
                </StyledFormControl2>
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={2}>
                <StyledFormControl2
                  error={!!formdata.errors.datePlanted}
                  data-testid="datePlanted-formcontrol"
                >
                  <Datepicker
                    id="datePlanted"
                    inputFormat={viewDateFormat}
                    placeholder="DD/MM/YYYY"
                    variant="outlined"
                    size="small"
                    value={formdata.datePlanted}
                    onChange={handleOnChange}
                    name="datePlanted"
                    label={t('Date Planted')}
                    disabled={blockIsAdHoc}
                  />
                  {formdata.errors.datePlanted && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.datePlanted}
                    </FormHelperText>
                  )}
                </StyledFormControl2>
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFormControl2 error={!!formdata.errors.varietyID}>
                  <VineaAutoComplete
                    value={formdata.varietyID || 0}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    options={varietySelector}
                    labelVariant="body1"
                    inlineLabel={false}
                    label={t('Variety')}
                    name={t('Variety')}
                    inputProps={{
                      name: 'varietyID',
                    }}
                    disabled={blockIsAdHoc}
                  />
                  {formdata.errors.varietyID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.varietyID}
                    </FormHelperText>
                  )}
                </StyledFormControl2>
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFormControl2
                  error={!!formdata.errors.cloneID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.cloneID || 0}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    options={variteyCloneSelector}
                    labelVariant="body1"
                    label={t('Clone')}
                    name={t('Clone')}
                    inlineLabel={false}
                    inputProps={{
                      name: 'cloneID',
                    }}
                    disabled={blockIsAdHoc}
                  />
                  {formdata.errors.cloneID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.cloneID}
                    </FormHelperText>
                  )}
                </StyledFormControl2>
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFormControl
                  error={!!formdata.errors.rootStockID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.rootStockID || 0}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    options={rootStockSelector}
                    labelVariant="body1"
                    label={t('Root Stock')}
                    name={t('Root Stock')}
                    inlineLabel={false}
                    inputProps={{
                      name: 'rootStockID',
                    }}
                    disabled={blockIsAdHoc}
                  />
                  {formdata.errors.rootStockID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.rootStockID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFieldLabelInput
                  id="rowSpacing"
                  name="rowSpacing"
                  label={t('Row Spacing (m)')}
                  size="small"
                  inlineLabel
                  value={formdata.rowSpacing}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.rowSpacing}
                  disabled={blockIsAdHoc}
                />
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFieldLabelInput
                  id="plantSpacing"
                  name="plantSpacing"
                  label={t('Plant Spacing (m)')}
                  size="small"
                  inlineLabel
                  value={formdata.plantSpacing}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.plantSpacing}
                  disabled={blockIsAdHoc}
                />
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="plantedArea"
                  name="plantedArea"
                  label={t('Planted area (ha)')}
                  size="small"
                  inlineLabel
                  value={formdata.plantedArea}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.plantedArea}
                  disabled={blockIsAdHoc}
                />
              </StyledFormBoxControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="totalPlantedArea"
                  name="plantedVines"
                  label={t('Total Planted (units)')}
                  size="small"
                  inlineLabel
                  value={formdata.plantedVines}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.plantedVines}
                  disabled={blockIsAdHoc}
                />
              </StyledFormBoxControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFieldLabelInput
                  id="rowRange"
                  name="rowRange"
                  label={t('Row Range')}
                  size="small"
                  inlineLabel
                  value={formdata.rowRange}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.rowRange}
                  disabled={blockIsAdHoc}
                />
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFieldLabelInput
                  id="row_Count"
                  name="row_Count"
                  label={t('Row Count')}
                  size="small"
                  inlineLabel
                  value={formdata.row_Count}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.row_Count}
                  disabled={blockIsAdHoc}
                />
              </StyledFormBoxControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFieldLabelInput
                  id="primarylinkcode"
                  name="linkCode01"
                  label={t('Primary Link Code')}
                  size="small"
                  inlineLabel
                  value={formdata.linkCode01}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.primarylinkcode}
                  disabled={blockIsAdHoc}
                />
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFieldLabelInput
                  id="secondarylinkcode"
                  name="linkCode02"
                  label={t('Secondary Link Code')}
                  size="small"
                  inlineLabel
                  value={formdata.linkCode02}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.secondarylinkcode}
                  disabled={blockIsAdHoc}
                />
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <StyledFormBoxControl mt={1}>
                <StyledFieldLabelInput
                  id="comments"
                  name="blockNotes"
                  label={t('Comments')}
                  size="small"
                  inlineLabel
                  value={formdata.blockNotes}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.blockNotes}
                  multiline
                  rowsMax={4}
                  rows={3}
                />
              </StyledFormBoxControl>
            </Grid>
            <Grid item xs={6}>
              <StyledFormBoxControl mt={1}>
                <StyledFieldLabelInput
                  id="version"
                  name="version"
                  label={t('Version')}
                  size="small"
                  inlineLabel
                  value={formdata.version}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.version}
                />
              </StyledFormBoxControl>
            </Grid>

            <Grid item xs={12}>
              {' '}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
              }}
            >
              <Box mx={2} mt={1}>
                {isNewBlock && (
                  <VineaButton
                    color="success"
                    onClick={onSaveNew}
                    variant="contained"
                    sx={{
                      marginRight: theme.spacing(1),
                      minWidth: 150,
                    }}
                    minWidth={100}
                    disabled={!!formWriteLoading}
                  >
                    Save
                  </VineaButton>
                )}
                {!isNewBlock && (
                  <SplitButton
                    color="success"
                    onClick={onUpdate}
                    variant="contained"
                    minWidth={130}
                    disabled={saveButtonStatus}
                  />
                )}
              </Box>
              <Box mx={2} mt={1}>
                {!isNewBlock && (
                  <VineaButton
                    data-testid="DeleteID"
                    variant="outlined"
                    color="error"
                    onClick={onDelete}
                  >
                    Delete
                  </VineaButton>
                )}
              </Box>
              <Box mx={2} mt={1}>
                <VineaButton
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Close
                </VineaButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} />
        <DiscardDialog
          open={discardDialogOpen}
          onClose={handleOnDialogClose}
          handleSaveChanges={onUpdate}
          handleDiscardChanges={handleCancel}
        />
      </Grid>
    </Paper>
  );
};
