import React from 'react';
import { Box, Paper } from '@mui/material';
import { VineaButton } from '../../components/VineaButton';
import { GridWithFilter } from '../../components/Grid';
export const BlockVintageDetailsContainer = ({
  handleOnCancel,
  handleOnCellClick,
  handleOnSaveRow,
  data,
}) => {
  const handleCancle = () => {
    handleOnCancel();
  };

  const handleSaveRow = () => {
    handleOnSaveRow();
  };

  const handleCellClick = () => {
    handleOnCellClick();
  };

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: 500,
      }}
    >
      <GridWithFilter
        data={data}
        rowHeight={30}
        autoPageSize
        rowsPerPageOptions={[25]}
        onRowClick={handleCellClick}
        advancedSearch={false}
      />
      <Box
        display="flex"
        flex={1 / 12}
        flexDirection="column"
        data-testid="griditem-02"
      >
        <Paper elevation={0} data-testid="detail-info">
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="space-between"
            p={2}
          >
            <Box display="flex" justifyContent="flex-start">
              <VineaButton
                color="success"
                onClick={handleSaveRow}
                variant="contained"
                minWidth={65}
                sx={{
                  marginRight: 1,
                }}
              >
                {'Save'}
              </VineaButton>
              <VineaButton
                onClick={handleCancle}
                variant="outlined"
                color="secondary"
                minWidth={100}
              >
                {'Cancel'}
              </VineaButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
};
