/* eslint-disable react/prop-types */
import React from 'react';
import { isEmpty } from 'lodash';
import GridWithFilter from '../TrackingComponents/GridWithFilter';
import { filterDataGridColumns } from './FilterGridColumns';

export const FilterGridBlockActivities = ({
  handleOnRowClick,
  isLoading = false,
  activityData = [],
  className = '',
  selectionModel = [],
}) => {
  const gridXData = {
    columns: filterDataGridColumns,
    rows: isEmpty(activityData) ? [] : activityData,
  };

  return (
    <GridWithFilter
      className={className}
      data={gridXData}
      onRowClick={handleOnRowClick}
      rowHeight={30}
      autoPageSize
      loading={isLoading}
      hideFooter
      hideFooterSelectedRowCount
      selectionModel={selectionModel}
      advancedSearch={false}
    />
  );
};
