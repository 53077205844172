/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, first, get, isNull } from 'lodash';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { CollapseHorizontal } from '../CollapseHorizontal';
import { VineaAutoComplete } from '../ComboBox';
import { VineaButton } from '../VineaButton';
import { MultiSelectCheckbox } from '../MultiSelectCheckbox/MultiSelectCheckbox';
import { actionTypes, reducers } from '../../constants';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';

export function FilterScreen({
  onFilterToggle,
  isFilterOpen,
  pageData,
  handleApplyDashboardPageFilter,
  handleDashboardPageFilterChange,
  triggerFocusFirstInput,
  basicSettings,
}) {
  const { filters, dashboardPageID } = pageData;
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const isFirstRender = React.useRef(true);

  const firstInputRef = React.useRef();

  const [filterValues, setFilterValues] = React.useState({});

  const { data: allPageFilters = {} } = useSelector(
    state => state.dashboardPageFilter,
  );
  const { [dashboardPageID]: pageFilters = {}, defaults = {} } = allPageFilters;

  const handleChangeSingleSelect = e => {
    const {
      target: { value, name },
    } = e;

    setFilterValues({
      ...filterValues,
      [name]: { ...filterValues[name], value: [value] },
    });

    if (name === 'BusinessUnitID') {
      dispatchAPI({
        type: 'BASIC_SETTINGS_UPDATE',
        payload: {
          ...basicSettings,
          searchFilterBusinessUnitID: value,
        },
      });
    }

    // here need to call POST DashboardPageFilters passing similar request body as
    // existing calls to POST ChartsByPageFilters
    //handleDashboardPageFilterChange(dashboardPageID);
  };

  const handleChangeMultiSelect = (value, name) => {
    const newValueHasAll = value.includes(-1);
    const currentValueHasAll = filterValues[name].value.includes(-1);

    const updatedValue =
      newValueHasAll && !currentValueHasAll
        ? [-1] // deselect everything that isn't <All>
        : newValueHasAll && value.length > 1
        ? value.filter(i => i !== -1) // deselect <All>
        : value;

    setFilterValues({
      ...filterValues,
      [name]: { ...filterValues[name], value: updatedValue },
    });
  };

  const handleApplyFilters = () => {
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.dashboardPageFilter,
      payload: {
        ...allPageFilters,
        [dashboardPageID]: {
          ...filterValues,
        },
      },
    });
  };

  const handleClearFilters = () => {
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.dashboardPageFilter,
      payload: {
        ...allPageFilters,
        [dashboardPageID]: {
          ...defaults[dashboardPageID],
        },
      },
    });
  };

  const handleFilterToggle = () => {
    onFilterToggle();
  };

  React.useEffect(() => {
    setFilterValues(pageFilters);
  }, [pageFilters]);

  React.useEffect(() => {
    if (!isFirstRender.current) {
      handleApplyDashboardPageFilter(dashboardPageID);
    }
    isFirstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFilters]);

  React.useEffect(() => {
    // null check to prevent this triggering on first render
    if (!isNull(triggerFocusFirstInput) && firstInputRef.current) {
      firstInputRef.current?.focus();
    }
  }, [triggerFocusFirstInput]);

  const FallbackComponent = ({ resetErrorBoundary, ...restProps }) => {
    const originalPage = React.useRef(dashboardPageID);

    React.useEffect(() => {
      if (dashboardPageID !== originalPage.current) {
        resetErrorBoundary();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardPageID]);

    return (
      <Box sx={{ width: '280px', height: '100%' }}>
        <ErrorBoundaryFallback
          resetErrorBoundary={resetErrorBoundary}
          {...restProps}
        />
      </Box>
    );
  };

  return !isFilterOpen ? null : (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <CollapseHorizontal
        orientation="horizontal"
        collapsedSize={48}
        in={isFilterOpen}
      >
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box>
            <Box
              pl={2}
              sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <FilterAltIcon color="primary" style={{ marginRight: 5 }} />
                <Typography variant="body2">{t('Page Filters')}</Typography>
              </Box>

              <Tooltip title={t('Minimise')}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    handleFilterToggle();
                  }}
                  onMouseDown={() => {}}
                  size="large"
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              pl={2}
              sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Typography variant="body1">
                {pageData.pageName.split(' ').map(capitalize).join(' ')}
              </Typography>
            </Box>
            <ErrorBoundary FallbackComponent={FallbackComponent}>
              <Box
                py={2}
                px={2}
                sx={{
                  flex: 1,
                  '& > *': {
                    flex: 1,
                    display: 'flex',
                  },
                }}
                data-testid="dashboardfilter"
              >
                {filters.map(
                  (
                    {
                      filterOptions,
                      filterName,
                      filterParameter,
                      isMultiSelect,
                      filterID,
                    },
                    i,
                  ) => (
                    <Box
                      key={filterID}
                      mt={1}
                      sx={{
                        marginBottom: theme.spacing(1),
                        marginTop: theme.spacing(2),
                        minWidth: 250,
                        '& .MuiFormControl-root': {
                          minWidth: 250,
                        },
                      }}
                    >
                      {isMultiSelect ? (
                        <FormControl
                          sx={{
                            width: 250,
                          }}
                          margin="none"
                        >
                          <MultiSelectCheckbox
                            onChange={val => {
                              return handleChangeMultiSelect(
                                val,
                                filterParameter,
                              );
                            }}
                            options={filterOptions}
                            name={filterParameter}
                            label={t(filterName)}
                            value={get(
                              filterValues,
                              [filterParameter, 'value'],
                              [],
                            )}
                            inputProps={{
                              name: filterParameter,
                            }}
                            inputRef={i === 0 && firstInputRef}
                          />
                        </FormControl>
                      ) : (
                        <FormControl
                          sx={{
                            width: 250,
                          }}
                          margin="none"
                        >
                          <VineaAutoComplete
                            onChange={handleChangeSingleSelect}
                            options={filterOptions}
                            name={filterParameter}
                            label={t(filterName)}
                            value={first(
                              get(filterValues, [filterParameter, 'value'], []),
                            )}
                            inputProps={{
                              name: filterParameter,
                            }}
                            inputRef={i === 0 && firstInputRef}
                          />
                        </FormControl>
                      )}
                    </Box>
                  ),
                )}
              </Box>
              <Box
                py={2}
                px={1}
                mx={1}
                sx={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'space-between', // justifyContent: 'flex-end'
                  flexDirection: 'row',
                }}
                data-testid="control-buttons"
              >
                <VineaButton
                  aria-label="clear"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  data-testid="filter-clear"
                  color="secondary"
                  variant="text"
                  onClick={handleClearFilters}
                >
                  {t('Clear')}
                </VineaButton>
                <Box px={1} py={1} />
                <VineaButton
                  aria-label="Search"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  data-testid="filter-search"
                  color="secondary"
                  variant="outlined"
                  onClick={handleApplyFilters}
                >
                  {t('Apply')}
                </VineaButton>
              </Box>
            </ErrorBoundary>
          </Box>
        </Box>
      </CollapseHorizontal>
    </Paper>
  );
}

FilterScreen.propTypes = {
  onFilterToggle: PropTypes.func.isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  pageData: PropTypes.shape({
    dashboardPageID: PropTypes.number,
    isContentLocked: PropTypes.bool,
    pageDescription: PropTypes.string,
    pageName: PropTypes.string,
    pageSequence: PropTypes.number,
    hasFilters: PropTypes.bool,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        chartID: PropTypes.number,
        filterID: PropTypes.number,
        filterName: PropTypes.string,
        filterParameter: PropTypes.string,
        isMultiSelect: PropTypes.bool,
        filterOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            key: PropTypes.number,
            value: PropTypes.string,
          }),
        ),
      }),
    ).isRequired,
  }).isRequired,
  handleApplyDashboardPageFilter: PropTypes.func.isRequired,
};
