import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import { increment, decrement, getCounter } from './counterReducer';

const StyledMarginDiv = styled('div')(() => ({
  margin: '10px 10px',
}));

export default function Home() {
  const counter = useSelector(getCounter);
  const theme = useTheme();
  const { t, i18n } = useTranslation('translation');

  const dispatch = useDispatch();
  const throwError = () => {
    const foo = {
      field: { bar: 'value' },
    };

    // This will crash the app; the error will show up in the Azure Portal
    return foo.fielld.bar;
  };

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div>
        <Card
          sx={{
            backgroundColor: theme.palette.secondary.main,
            display: 'flex',
          }}
        >
          <CardContent>
            <Typography variant="h5">Redux Example</Typography>
            <Typography align="center" variant="subtitle1">
              {`Counter: ${counter}`}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatch(increment())}
            >
              Increment
            </Button>
            <Button variant="contained" onClick={() => dispatch(decrement())}>
              Decrement
            </Button>
          </CardActions>
        </Card>
      </div>
      <StyledMarginDiv>
        <Card
          sx={{
            backgroundColor: theme.palette.secondary.main,
            display: 'flex',
          }}
        >
          <CardContent>
            <Typography variant="h5">Exceptions Errors</Typography>
          </CardContent>
          <CardActions>
            <Button color="primary" variant="contained" onClick={throwError}>
              Throw Error
            </Button>
          </CardActions>
        </Card>
      </StyledMarginDiv>

      <div>
        <Card sx={{ display: 'flex', margin: '10px 10px' }}>
          <CardContent>
            <Typography variant="h5">Localization Example</Typography>
            <Typography align="center" variant="subtitle1">
              {t('welcome.title', { framework: 'React' })}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => changeLanguage('en')}
            >
              English
            </Button>
            <Button variant="contained" onClick={() => changeLanguage('de')}>
              Spanish
            </Button>
          </CardActions>
        </Card>
      </div>
    </div>
  );
}
