import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { isValid, isAfter, format } from 'date-fns';
import {
  getLookupBlockVintageStatus,
  getLookupRootStock,
  getLookupRowSpecialAttribute,
  getLookupVariety,
  getLookupVarietyClone,
  getLookupVintage,
} from './lookupSelectors';
import { viewDateFormat } from '../../constants';
import {
  formatAsDateFormat,
  formatAsViewDateFormat,
  getIsActive,
} from '../../constants/formatter';

const getVineyardBlocksReducer = state => state.vineyardBlocks;
const getBlockVintages = state => state.blockVintages;
const getVineyardBlockRowsReducer = state => state.vineyardBlockRows;

export const getVineyardBlocks = createSelector(
  [
    getVineyardBlocksReducer,
    getLookupBlockVintageStatus,
    getLookupRootStock,
    getLookupVariety,
    getLookupVarietyClone,
    getLookupVintage,
  ],
  (
    blocks,
    lkpBlockStats,
    lkpRootStock,
    lkpVariety,
    lkpVarietyClone,
    lkpVintages,
  ) => {
    const { data } = blocks;
    if (isEmpty(blocks)) return [];
    if (!Array.isArray(data) || isNull(data)) return [];
    // massaging the data for showing it on the grid
    let gridFormattedTableData = [];
    if (data.length > 0) {
      gridFormattedTableData = data.map(f => {
        const fromDate = formatAsViewDateFormat(f.activeFromDate);
        const toDate = formatAsViewDateFormat(f.activeToDate);

        const activeFromDate = formatAsDateFormat(f.activeFromDate);
        const activeToDate = formatAsDateFormat(f.activeToDate);

        const datePlanted = formatAsViewDateFormat(f.datePlanted);
        const datePlantedActual = formatAsDateFormat(f.datePlanted);

        const varietyName =
          !isEmpty(lkpVariety) &&
          get(
            lkpVariety.find(vn => vn.id === f.varietyID),
            'value',
            ' ',
          );

        const rootStock =
          !isEmpty(lkpRootStock) &&
          get(
            lkpRootStock.find(vn => vn.id === f.rootStockID),
            'value',
            ' ',
          );

        const varietyClone =
          !isEmpty(lkpVarietyClone) &&
          get(
            lkpVarietyClone.find(vn => vn.id === f.cloneID),
            'value',
            ' ',
          );

        const blockStatus =
          !isEmpty(lkpBlockStats) &&
          get(
            lkpBlockStats.find(vn => vn.id === f.blockVintageStatusID),
            'value',
            ' ',
          );

        const latestVintageYear =
          !isEmpty(lkpVintages) &&
          get(
            lkpVintages.find(vn => vn.id === f.latestVintageID),
            'value',
            'not available',
          );

        // Preparing Row plannt spacing column
        const rowSpacing = f.rowSpacing ? f.rowSpacing : ' ';
        const plantSpacing = f.plantSpacing ? f.plantSpacing : ' ';

        let startEndRow = `${f.row_Count}` || ' ';
        const rowRangeStr = f.rowRange || ' ';

        if (rowRangeStr !== ' ') {
          startEndRow = `${startEndRow} (${rowRangeStr})`;
        }

        // Preparing is Active Flag for blocks
        const isActive = getIsActive(f.activeFromDate, f.activeToDate);

        const formatNumber = n => (n || n === 0 ? n.toString() : null);

        return {
          ...f,
          linkCode01: f.linkCode01 || '',
          linkCode02: f.linkCode02 || '',
          rowSpacing,
          plantSpacing,
          plantedArea: formatNumber(f.plantedArea),
          row_Count: formatNumber(f.row_Count),
          plantedVines: formatNumber(f.plantedVines),
          fromTo: `${fromDate}${toDate ? ' - ' : ''}${toDate}`,
          startEndRow,
          datePlantedFormat: datePlanted,
          plantedAreaHa: f.plantedArea ? `${f.plantedArea} ha` : null,
          version: f.version || ' ',
          varietyName,
          varietyClone,
          rootStock,
          blockStatus,
          activeToDate,
          datePlanted: datePlantedActual,
          latestVintageYear,
          isActive: !f.activeToDate || isActive,
          rowRange: f.rowRange || ' ',
          activeFromDate,
        };
      });
    }

    return gridFormattedTableData;
  },
);

export const getVineyardBlocksInfo = createSelector(
  [getVineyardBlocksReducer],
  blocks => {
    const { isLoading, isLoaded, hasError, error } = blocks;
    return {
      isLoading,
      isLoaded,
      hasError,
      error,
    };
  },
);

export const getVineyardBlockRows = createSelector(
  [getVineyardBlockRowsReducer, getLookupRowSpecialAttribute],
  (blockRows, lkpRowSpecialAttribute) => {
    const { data = [] } = blockRows;
    const refData = isEmpty(data) ? [] : data;
    if (!Array.isArray(refData)) return [];
    // massaging the data for showing it on the grid
    let gridFormattedTableData = [];
    if (data.length > 0) {
      gridFormattedTableData = data.map(f => {
        const rowSpecialAttribute =
          !isEmpty(lkpRowSpecialAttribute) &&
          get(
            lkpRowSpecialAttribute.find(vn => vn.id === f.specialAttributeID),
            'value',
            '',
          );
        return {
          ...f,
          rowSpecialAttribute,
        };
      });
    }
    return gridFormattedTableData;
  },
);

export const getVineyardBlockRowsInfo = createSelector(
  [getVineyardBlocksReducer],
  blocks => {
    const { isLoading, isLoaded, hasError, error } = blocks;
    return {
      isLoading,
      isLoaded,
      hasError,
      error,
    };
  },
);

export const blockVintages = createSelector(
  [getBlockVintages, getLookupVintage],
  (blockVintageRecords, lkpVintages) => {
    const { data = [] } = blockVintageRecords;
    const refData = isEmpty(data) ? [] : data;
    if (!Array.isArray(refData)) return [];
    let blockVintageList = [];
    if (data.length > 0) {
      blockVintageList = data.map(f => {
        const harvestDate =
          isValid(f.harvestDate) &&
          isAfter(new Date(f.harvestDate), new Date('1980-01-01'))
            ? format(f.harvestDate, viewDateFormat)
            : '';

        const vintageYear =
          !isEmpty(lkpVintages) &&
          get(
            lkpVintages.find(vn => vn.id === f.vintageID),
            'value',
            '',
          );

        const isActiveVintageYear =
          !isEmpty(lkpVintages) &&
          get(
            lkpVintages.find(vn => vn.id === f.vintageID),
            'isActive',
            '',
          );

        return {
          ...f,
          harvestDate,
          vintageYear,
          isActiveVintageYear,
        };
      });
      blockVintageList = orderBy(blockVintageList, ['id'], ['desc']);
    }
    return blockVintageList;
  },
);

export const blockVintagesInfo = createSelector(
  [getBlockVintages],
  blockVintageRecords => {
    const { isLoading, isLoaded, hasError, error } = blockVintageRecords;
    return {
      isLoading,
      isLoaded,
      hasError,
      error,
    };
  },
);
