/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled as muiStyled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';

import { CollapseHorizontal } from '../../components/CollapseHorizontal';
// eslint-disable-next-line import/no-unresolved
import { GreenSwitch } from '../../components/Switch';
import { VineaButton } from '../../components/VineaButton';
import { VineaTextField } from '../../components/TextField';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';

// import logger from '../../utils/winstonLogger';

type FilterScreenProps = {
  onFilterToggle: any;
  isFilterOpen: boolean;
  search: any;
  filterData: any;
  onUpdateFilterData: any;
  firstFilterInputRef: React.RefObject<HTMLInputElement>;
};

const FlexBox = muiStyled(Box)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
}));

const FlexItemBox = muiStyled(Box)(() => ({
  flex: 1,
  '& > *': {
    flex: 1,
    display: 'flex',
  },
  '& > .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const FilterScreen = ({
  onFilterToggle,
  isFilterOpen,
  search,
  filterData,
  onUpdateFilterData,
  firstFilterInputRef,
}: FilterScreenProps) => {
  const { t } = useTranslation();

  const location = useLocation();

  const {
    searchName = '',
    searchEmail = '',
    searchIsActive = false,
    searchIsAdmin = true,
  } = filterData || {};

  // map from each filter name to a friendly name/value to show on screen
  const nameMap = {
    searchName: { name: 'Name', getValueText: (v: string) => [v] },
    searchEmail: { name: 'Email', getValueText: (v: string) => [v] },
    searchIsActive: { name: 'Active only', getValueText: (v: boolean) => [v] },
    searchIsAdmin: { name: 'Admins only', getValueText: (v: boolean) => [v] },
  };

  const handleChangeUserActive = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const evtValue = evt.target.checked;
    onUpdateFilterData({ ...filterData, searchIsActive: evtValue });
  };

  const handleChangeUserAdmin = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const evtValue = evt.target.checked;
    onUpdateFilterData({ ...filterData, searchIsAdmin: evtValue });
  };

  const handleChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const evtValue = evt.target.value;
    onUpdateFilterData({ ...filterData, searchName: evtValue });
  };

  const handleChangeEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const evtValue = evt.target.value;
    onUpdateFilterData({ ...filterData, searchEmail: evtValue });
  };

  const getFilterText = (data: any) =>
    Object.keys(data)?.map(filterName => {
      const { name, getValueText } = get(nameMap, [filterName], {});
      return name
        ? {
            filterName: name,
            values: getValueText(data[filterName]),
          }
        : {
            filterName,
            values: [data[filterName]],
          };
    });

  const handleOnSearch = () => {
    // null is required to fetch all records
    const data = {
      searchName: searchName || '',
      searchEmail: searchEmail || '',
      searchIsActive,
      searchIsAdmin,
    };

    search(data, getFilterText(data));
  };

  const handleOnClearSearch = () => {
    onUpdateFilterData({
      ...filterData,
      searchName: '',
      searchEmail: '',
      searchIsActive: true,
      searchIsAdmin: true,
    });

    // Calling Search function to populate grid again with all the data
    const data = {
      searchName: '',
      searchEmail: '',
      searchIsActive: true,
      searchIsAdmin: true,
    };
    search(data, getFilterText(data));
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{
        paddingBottom: 3,
        minHeight: '100%',
        '& .MuiCollapse-horizontal': {
          height: '100%',
        },
        '& .MuiCollapse-wrapper': {
          flex: 1,
        },
        '& .MuiInputLabel-outlined': {
          zIndex: 0,
        },
        '& .MuiCollapse-wrapperInner': {
          width: '100%',
        },
      }}
      data-testid="filter-screen"
    >
      <Grid item xs={12} style={{ height: '100%' }}>
        <Paper
          elevation={2}
          data-testid="filter-collapse-paper"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            '& .MuiCollapse-horizontal': {
              height: '100%',
              width: '100px',
            },
            '& .MuiCollapse-wrapper': {
              flex: 1,
            },
            '& .MuiInputLabel-outlined': {
              zIndex: 0,
            },
            '& .MuiCollapse-wrapperInner': {
              width: '100%',
            },
          }}
        >
          {!isFilterOpen && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                flex: 1,
                '&  .MuiIconButton-root': {
                  flex: 1,
                },
              }}
            >
              <Box
                flex={1}
                flexDirection="row"
                justifyContent="flex-start"
                display="flex"
              >
                <div />
                <div>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      onFilterToggle();
                    }}
                    onMouseDown={() => {}}
                    size="large"
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </div>
              </Box>
            </Box>
          )}
          <>
            <CollapseHorizontal
              orientation="horizontal"
              collapsedSize={48}
              in={isFilterOpen}
              data-testid="collapse-horizontal"
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
              className={''}
              component={undefined}
              onEnter={undefined}
              onEntered={undefined}
              onEntering={undefined}
              onExit={undefined}
              onExited={undefined}
              onExiting={undefined}
              timeout={0}
              TransitionComponent={undefined}
            >
              <Box
                display="flex"
                data-testid="collapse-box"
                flex={1}
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
                sx={{ height: '100%' }}
              >
                {!!isFilterOpen && (
                  <Box
                    display="flex"
                    data-testid="collapse-show-filter-box"
                    flex={1}
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{ height: '100%' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      <Box
                        pl={2}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          noWrap
                          data-testid="header-text"
                        >
                          {t('Filters')}
                        </Typography>

                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            onFilterToggle();
                          }}
                          onMouseDown={() => {}}
                          size="large"
                        >
                          <ChevronLeftIcon />
                        </IconButton>
                      </Box>

                      {/* @ts-ignore */}
                      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                        <Box sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
                          <FlexItemBox
                            py={2}
                            px={2}
                            data-testid="searchvineyards"
                          >
                            <FormControl variant="outlined" margin="dense">
                              <VineaTextField
                                id="outlined-search-id-name"
                                label={t(nameMap.searchName.name)}
                                type="search"
                                variant="outlined"
                                size="small"
                                value={searchName}
                                onChange={handleChangeName}
                                inputRef={firstFilterInputRef}
                              />
                            </FormControl>
                          </FlexItemBox>
                          <FlexItemBox py={2} px={2} data-testid="search-users">
                            <FormControl variant="outlined" margin="dense">
                              <VineaTextField
                                id="outlined-search-id-email"
                                label={t(nameMap.searchEmail.name)}
                                type="search"
                                variant="outlined"
                                size="small"
                                value={searchEmail}
                                onChange={handleChangeEmail}
                              />
                            </FormControl>
                          </FlexItemBox>
                          <FlexItemBox
                            py={1}
                            px={2}
                            data-testid="classification"
                          >
                            <FormControlLabel
                              control={
                                <GreenSwitch
                                  checked={searchIsActive}
                                  onChange={handleChangeUserActive}
                                  name="searchIsActive"
                                />
                              }
                              label={
                                <Typography variant="caption">
                                  {t(nameMap.searchIsActive.name)}
                                </Typography>
                              }
                            />
                          </FlexItemBox>
                          <FlexItemBox
                            py={1}
                            px={2}
                            data-testid="classification"
                          >
                            <FormControlLabel
                              control={
                                <GreenSwitch
                                  checked={searchIsAdmin}
                                  onChange={handleChangeUserAdmin}
                                  name="searchIsAdmin"
                                />
                              }
                              label={
                                <Typography variant="caption">
                                  {t(nameMap.searchIsAdmin.name)}
                                </Typography>
                              }
                            />
                          </FlexItemBox>
                        </Box>
                        <FlexBox
                          py={2}
                          px={2}
                          sx={{ height: '100%', alignItems: 'flex-start' }}
                          data-testid="control-buttons"
                        >
                          <VineaButton
                            aria-label="clear"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            data-testid="filter-clear"
                            color="secondary"
                            variant="text"
                            onClick={handleOnClearSearch}
                          >
                            {t('Clear')}
                          </VineaButton>
                          <Box px={1} py={1} />
                          <VineaButton
                            aria-label="Search"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            data-testid="filter-search"
                            color="secondary"
                            variant="outlined"
                            onClick={handleOnSearch}
                          >
                            {t('Search')}
                          </VineaButton>
                        </FlexBox>
                      </ErrorBoundary>
                    </Box>
                  </Box>
                )}
              </Box>
            </CollapseHorizontal>
          </>
        </Paper>
      </Grid>
    </Grid>
  );
};

FilterScreen.propTypes = {
  onFilterToggle: PropTypes.func.isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
  filterData: PropTypes.any,
  onUpdateFilterData: PropTypes.func.isRequired,
};

FilterScreen.defaultProps = {
  filterData: null,
};

export default FilterScreen;
