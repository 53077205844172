import { createSelector } from 'reselect';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { format, isAfter } from 'date-fns';
import { viewDateFormat, dateFormat } from '../../constants';

const getAllDocuments = VineaNovaSelectors.getLinkedDocumentsEntityData;
const getLkpDocumentType = VineaNovaSelectors.getlookupDocumentTypeEntityData;
const getLkpDocumentStatus =
  VineaNovaSelectors.getlookupDocumentStatusEntityData;

export const getDocumentsGridSelector = createSelector(
  [getAllDocuments, getLkpDocumentType, getLkpDocumentStatus],
  (allDocuments, lkpDocumentType, lkpDocumentStatus) => {
    return allDocuments
      .map(e => ({
        id: e.documentID,
        date: format(new Date(e.documentDate), dateFormat),
        subject: e.documentSubject,
        filename: e.sourceFile,
        status: lkpDocumentStatus.find(
          status => status.id === e.documentStatusID,
        )?.documentStatus,
        statusId: e.documentStatusID,
        typeId: e.documentTypeID,
        type: lkpDocumentType.find(type => type.id === e.documentTypeID)
          ?.documentTypeDescription,
        loadedDate: format(new Date(e.loadedDate), viewDateFormat),
        loadedByUser: e.loadedByUser,
      }))
      .sort((a, b) => (isAfter(new Date(a.date), new Date(b.date)) ? 1 : -1))
      .sort((a, b) => (a.status > b.status ? 1 : -1));
  },
);
