import * as Yup from 'yup';

export const BlockVintageSchema = Yup.object().shape({
  blockVintageStatusID: Yup.string()
    .typeError('Status is required')
    .notOneOf(['Select', 0, '0'], 'Status is required')
    .nullable()
    .required('Status is required'),
  trellisID: Yup.string()
    .typeError('Trellis is required')
    .notOneOf(['Select', 0, '0'], 'Trellis is required')
    .required('Trellis is required'),
});
