import React, { useReducer, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import {
  find,
  isEmpty,
  isNil,
  lowerCase,
  orderBy,
  remove,
  sortBy,
  startCase,
} from 'lodash';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import { Alert } from '@mui/lab';
import Collapse from '@mui/material/Collapse';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTheme, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { FieldLabelInput } from '../../../components/TextField';
import { VineaButton } from '../../../components/VineaButton';
import {
  addGenericReducer,
  types,
  userState,
} from '../../VineyardIdentity/stateReducers';
import {
  getAllActiveIdentityIds,
  getAllUsers,
  getBusinessUnit,
} from '../../../redux/selectors';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { VineaAlert } from '../../../components/VineaAlert';
import { GreenCheckbox } from '../../../components/CheckBox';
import { UserSchema } from '../validations';
import { syncValidator } from '../../../utils/validator';
import {
  apiTypes,
  dateFormat,
  sagaActionTypes,
  reducers,
  vineaDetails,
} from '../../../constants';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: 250,
}));

const CreateUser = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const today = format(new Date(), dateFormat);
  const [formdata, dispatch] = useReducer(addGenericReducer, userState);

  const {
    error: formWriteError,
    hasError: formWriteHasError,
    isLoading: formWriteIsLoading,
    isLoaded: formWriteIsLoaded,
    data: formWriteData,
  } = useSelector(state => state.formWrite);
  const { data: identityContactMethod = [] } = useSelector(
    state => state.entities.identityContactMethod,
  );
  const businessUnitList = useSelector(state => getBusinessUnit(state));
  const allPeople = useSelector(state => getAllActiveIdentityIds(state));
  const { data: existingUsers } = useSelector(getAllUsers);
  const existingUserIDs = existingUsers?.map(({ identityId }) => identityId);
  const availablePeople = allPeople?.filter(
    ({ id }) => !existingUserIDs?.includes(id),
  );

  const [triggerCreateUser, setTriggerCreateUser] = useState(false);
  const [triggerCreatePerson, setTriggerCreatePerson] = useState(false);
  const [hasUserCreated, setHasUserCreated] = useState(false);
  const [hasPersonCreated, setHasPersonCreated] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [inSavingMode, setSaveMode] = useState(false);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);

  const email = find(identityContactMethod, c =>
    c.contactMethodType.toLowerCase().includes('email'),
  );

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleCheckboxChange = event => {
    const {
      target: { checked, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: checked } });
  };

  const handleOnBlur = () => {
    const validationErrors = syncValidator(UserSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  const onCancel = () => {
    navigate('/users');
  };

  const onSave = () => {
    setSaveMode(true);

    const validationErrors = syncValidator(UserSchema)(formdata);
    if (!isEmpty(validationErrors)) {
      setSaveMode(false);
      dispatch({ type: types.ERROR, payload: validationErrors });
    } else {
      // if no identityPersonSelected is false, add new identity Person before saving user
      if (!formdata.identityPersonSelected) {
        setTriggerCreatePerson(true);
      } else {
        setTriggerCreateUser(true);
      }
      setDuplicateEmail(false);
    }
  };

  // Create person
  useEffect(() => {
    if (triggerCreatePerson) {
      const data = {
        IdentityTypeID: 1,
        BusinessUnitID: formdata.businessUnitID,
        FirstName: formdata.firstName,
        LastName: formdata.lastName,
      };

      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.person,
          methodType: apiTypes.POST,
        },
      });
      setTriggerCreatePerson(false);
      setHasPersonCreated(true);
    } else if (formdata.identityId !== 0) {
      setTriggerCreateUser(true);
    } else if (
      hasPersonCreated &&
      !hasUserCreated &&
      formWriteData.firstName === formdata.firstName &&
      formWriteData.lastName === formdata.lastName
    ) {
      dispatch({
        type: 'UPDATE',
        payload: {
          identityId: formWriteData.id,
        },
      });
      setTriggerCreateUser(true);
    }
  }, [triggerCreatePerson, formWriteData]);

  // Create user
  useEffect(() => {
    var userSettings = {
      basicSettings: { businessUnitID: formdata.businessUnitID },
    };
    if (triggerCreateUser) {
      const data = {
        email: formdata.workEmail,
        firstName: formdata.firstName,
        lastName: formdata.lastName,
        created: today,
        isUserActive: formdata.isUserActive,
        isVineaAdmin: formdata.isVineaAdmin,
        identityId: formdata.identityId,
        userSettings: JSON.stringify(userSettings),
        userPersonDto: {
          identityID: formdata.identityId,
          knownAs: formdata.firstName,
          businessUnitID: formdata.businessUnitID,
        },
      };
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.user,
          methodType: apiTypes.POST,
        },
      });
      setTriggerCreateUser(false);
      setHasUserCreated(true);
    }
  }, [triggerCreateUser]);

  //Check there are no errors, if not then navigate to user search screen
  useEffect(() => {
    if (!formWriteIsLoading && formWriteIsLoaded && hasUserCreated) {
      if (formWriteHasError && formWriteError?.status === 400) {
        setDuplicateEmail(true);
        setSaveMode(false);
        setHasPersonCreated(false);
        setHasUserCreated(false);
      } else {
        navigate('/users');
        setDuplicateEmail(false);
      }
      setTriggerCreateUser(false);
      setTriggerCreatePerson(false);
    }
  }, [
    formWriteError,
    formWriteHasError,
    formWriteIsLoading,
    formWriteIsLoaded,
    navigate,
  ]);

  // set email if identity person is chosen
  useEffect(() => {
    if (formdata.identityId !== 0) {
      dispatch({
        type: 'UPDATE',
        payload: {
          workEmail: !isNil(email) ? email.contactMethodDetails : null,
        },
      });
    }
  }, [email, formdata.identityId]);

  // Set First name and last name fields when person identity is selected
  useEffect(() => {
    if (formdata.identityId !== 0) {
      dispatchAPI(
        VineaNovaActions.api.v1.identityContactMethod.get.request({
          queryParams: {
            IdentityID: formdata.identityId,
          },
        }),
      );

      const person = find(allPeople, p => p.id === formdata.identityId);
      const names = person?.value.split(',');

      dispatch({
        type: 'UPDATE',
        payload: !isNil(person)
          ? {
              lastName: startCase(lowerCase(names[0].trim())),
              firstName: startCase(lowerCase(names[1].trim())),
              businessUnitID: find(
                businessUnitList,
                b => b.value === person.businessUnit,
              ).id,
              identityPersonSelected: true,
            }
          : {
              lastName: null,
              firstName: null,
              identityPersonSelected: false,
            },
      });
    }
  }, [formdata.identityId]);

  // Get person and user lookups, and default active to true on page load
  useEffect(() => {
    dispatchAPI({
      type: sagaActionTypes.SEARCH_IDENTITIES,
      name: reducers.searchIdentities,
      payload: {
        IdentityTypeID: 1,
        FindNonActive: true,
        roleTypeId: null,
      },
    });
    dispatchAPI({
      type: sagaActionTypes.SEARCH_USERS,
      name: reducers.searchUsers,
      payload: {
        searchEmail: '',
        searchIsActive: false,
        searchIsAdmin: false,
        searchName: '',
      },
    });
    dispatch({
      type: 'UPDATE',
      payload: {
        isUserActive: true,
      },
    });
  }, []);

  return (
    <div data-testid="create-user" style={{ margin: '12px' }}>
      <Paper
        elevation={0}
        data-testid="detail-info"
        sx={{
          padding: theme.spacing(4),
          margin: theme.spacing(1),
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          px={2}
        >
          <IconButton
            aria-label="toggle password visibility"
            sx={{
              padding: 0,
            }}
            onClick={onCancel}
            color="primary"
          >
            <HighlightOffOutlinedIcon fontSize="large" />
          </IconButton>
        </Box>
        <VineaAlert
          isOpen={!!errorInSubmit}
          onExit={() => setErrorInSubmit(false)}
        />
        <VineaAlert
          isOpen={alertType}
          onExit={() => setAlertType(false)}
          alertType="success"
          message="User Created Successfully"
        />
        <Box my={4}>
          {duplicateEmail && (
            <Collapse in={duplicateEmail}>
              <Alert
                variant="standard"
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setDuplicateEmail(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Error - The email address provided is already in use by an
                existing user
              </Alert>
            </Collapse>
          )}
        </Box>
        <Box px={2}>
          {warningInSubmit && (
            <Collapse in={warningInSubmit}>
              <Alert
                variant="standard"
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setWarningInSubmit(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Warning: Make sure all required fields are filled out
              </Alert>
            </Collapse>
          )}
        </Box>
        <Grid container spacing={2} data-testid="grid-details">
          <Grid container direction="column" mx={2}>
            <StyledFormControl
              error={!!formdata.errors.identityId}
              margin="none"
            >
              <VineaAutoComplete
                value={formdata.identityId || 0}
                onChange={handleChange}
                onBlur={handleOnBlur}
                inlineLabel={false}
                options={availablePeople}
                labelVariant="body1"
                id="identityId"
                name={t('Person')}
                label={t('Person')}
                inputProps={{
                  name: 'identityId',
                }}
              />
              {formdata.errors.identityId && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.identityId}
                </FormHelperText>
              )}
            </StyledFormControl>
            <StyledFormControl
              error={!!formdata.errors.workEmail}
              margin="none"
            >
              <FieldLabelInput
                id="workEmail"
                label={t('Login Email')}
                name="workEmail"
                size="small"
                inlineLabel
                value={formdata.workEmail}
                onChange={handleChange}
                onBlur={handleOnBlur}
                error={formdata.errors.workEmail}
              />
            </StyledFormControl>
            <StyledFormControl
              error={!!formdata.errors.firstName}
              margin="none"
            >
              <FieldLabelInput
                autoComplete="off"
                id="firstname"
                name="firstName"
                label={t('First Name')}
                size="small"
                inlineLabel
                value={formdata.firstName}
                disabled={
                  !isEmpty(formdata.firstName) && formdata.identityId > 0
                }
                onChange={handleChange}
                onBlur={handleOnBlur}
                error={formdata.errors.firstName}
              />
            </StyledFormControl>
            <StyledFormControl error={!!formdata.errors.lastName} margin="none">
              <FieldLabelInput
                id="lastname"
                label={t('Last Name')}
                name="lastName"
                size="small"
                inlineLabel
                value={formdata.lastName}
                disabled={
                  !isEmpty(formdata.lastName) && formdata.identityId > 0
                }
                onChange={handleChange}
                onBlur={handleOnBlur}
                error={formdata.errors.lastName}
              />
            </StyledFormControl>
            <StyledFormControl
              error={!!formdata.errors.businessUnitID}
              margin="none"
            >
              <VineaAutoComplete
                value={formdata.businessUnitID || 0}
                disabled={
                  formdata.businessUnitID > 0 && formdata.identityId > 0
                }
                onChange={handleChange}
                onBlur={handleOnBlur}
                inlineLabel={false}
                options={businessUnitList}
                labelVariant="body1"
                id="businessunit"
                name={t('Business Unit')}
                label={t('Business Unit')}
                inputProps={{
                  name: 'businessUnitID',
                }}
              />
              {formdata.errors.businessUnitID && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.businessUnitID}
                </FormHelperText>
              )}
            </StyledFormControl>
            <StyledFormControl mt={1}>
              <FormControlLabel
                label="User Active"
                control={
                  <GreenCheckbox
                    checked={formdata.isUserActive}
                    onChange={handleCheckboxChange}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                      name: 'isUserActive',
                    }}
                  />
                }
              />
            </StyledFormControl>
            <StyledFormControl mt={1}>
              <FormControlLabel
                label="User Admin"
                control={
                  <GreenCheckbox
                    checked={formdata.isVineaAdmin}
                    onChange={handleCheckboxChange}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                      name: 'isVineaAdmin',
                    }}
                  />
                }
              />
            </StyledFormControl>
          </Grid>
        </Grid>
        <Box display="flex" flexDirection="row" mt={2}>
          <VineaButton
            color="success"
            onClick={onSave}
            variant="contained"
            sx={{
              marginRight: theme.spacing(1),
            }}
            minWidth={100}
          >
            {!inSavingMode && <>{t('Save')}</>}
            {inSavingMode && (
              <>
                <CircularProgress color="secondary" size={20} />
                <span>{t('saving')}</span>
              </>
            )}
          </VineaButton>
          <VineaButton
            color="success"
            onClick={onCancel}
            variant="contained"
            sx={{
              marginRight: theme.spacing(1),
            }}
            minWidth={100}
          >
            {t('Cancel')}
          </VineaButton>
        </Box>
      </Paper>
    </div>
  );
};

export default CreateUser;
