import React, { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';

import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Alert } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import { VineaAlert } from '../../../components/VineaAlert';
import { FieldLabelInput } from '../../../components/TextField';
import { getBusinessUnit, getFormWriteData } from '../../../redux/selectors';
import { accommodationState, addGenericReducer, types } from '../stateReducers';
import { syncValidator } from '../../../utils/validator';
import { AccommodationSchema } from '../validations';
import {
  apiTypes,
  reducers,
  sagaActionTypes,
  vineaDetails,
} from '../../../constants';
import logger from '../../../utils/winstonLogger';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { SplitButton } from '../../../components/SplitButton';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { VineaButton } from '../../../components/VineaButton';
import { DiscardDialog } from '../../../components/Dialog';
import { statechecker } from '../../../utils/statecomparator';
import {
  IdentityActivationSwitch,
  DeleteIdentityAlert,
  DeleteIdentityButton,
} from '../../../components/IdentityComponents';
import { useIdentityStatus } from '../../../hooks/useIdentityStatus';

export const ManageAccommodation = () => {
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [formdata, dispatch] = useReducer(
    addGenericReducer,
    accommodationState,
  );
  const formWriteData = useSelector(state => state.formWrite).data;
  const {
    hasError: hasErrorFormSubmit,
    isLoaded,
    isLoading,
  } = useSelector(getFormWriteData);

  const businessUnitList = useSelector(state => getBusinessUnit(state));
  const identityTypeID = useIdentityTypeId();
  const [apiTriggerStatus, setTriggerStatus] = React.useState(true);
  const [errorInSubmit, setErrorInSubmit] = React.useState(false);
  const [warningInSubmit, setWarningInSubmit] = React.useState(false);
  const [updateApiStatus, setUpdateApiStatus] = React.useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = React.useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);
  const [formWriteReloadState, setFormWriteReloadState] = React.useState(false);
  const [saveIndex, setSaveIndex] = React.useState(0);
  const [deleteHasFailed, setDeleteHasFailed] = React.useState(false);

  const [isDeleting, setIsDeleting] = React.useState(false);
  const {
    isLoading: accommodationIsLoading,
    isLoaded: accommodationIsLoaded,
    hasError: accommodationHasError,
  } = useSelector(VineaNovaSelectors.getIdentityAccommodationEntityMeta);

  const isActive = useIdentityStatus();

  const { id: accommodationID } = useParams();

  const handleComponentChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({
      type: types.UPDATE,
      payload: { [name]: value === 0 ? null : value },
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleOnBlur = () => {
    const validationErrors = syncValidator(AccommodationSchema)(formdata);

    dispatch({
      type: types.ERROR,
      payload:
        !isEmpty(formdata.errors) && !isEmpty(validationErrors)
          ? validationErrors
          : {},
    });
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  const handleClose = () => {
    if (!saveButtonStatus) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleSave = (event, index) => {
    setSaveIndex(index);
    const validationErrors = syncValidator(AccommodationSchema)(formdata);
    const instanceData = formWriteData;
    if (!isEmpty(validationErrors)) {
      dispatch({ type: types.ERROR, payload: validationErrors });
    } else if (
      instanceData.accommodationName === formdata.accommodationName &&
      instanceData.capacity === formdata.capacity &&
      instanceData.businessUnitID === formdata.businessUnitID
    ) {
      setWarningInSubmit(true);
    } else {
      const data = {
        id: accommodationID,
        identityTypeID,
        accommodationName: formdata.accommodationName,
        capacity: formdata.capacity,
        businessUnitID: formdata.businessUnitID || null,
        ts: formWriteData.ts,
      };
      setWarningInSubmit(false);
      setErrorInSubmit(false);
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.accommodation,
          methodType: apiTypes.PUT,
        },
      });
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
      setUpdateApiStatus(true);
    }
  };
  const handleOnToggleActive = () => {
    navigate(`/accommodation/${accommodationID}/managestatus`);
  };

  const handleOnConfirmDelete = () => {
    if (accommodationID) {
      setIsDeleting(true);
      dispatchAPI(
        VineaNovaActions.api.v1.identityAccommodation.delete.request({
          queryParams: {
            IdentityID: accommodationID,
          },
        }),
      );
    }
  };

  React.useEffect(() => {
    if (!hasErrorFormSubmit && isLoaded && updateApiStatus) {
      enqueueSnackbar('Success!', { variant: 'success' });
      if (saveIndex === 0) navigate(`/accommodation/${accommodationID}/more`);
      else if (formWriteReloadState && saveIndex === 1) {
        setFormWriteReloadState(false);
        dispatchAPI({
          type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
          payload: { id: accommodationID, name: reducers.accommodation },
        });
      }
    } else if (hasErrorFormSubmit && isLoaded && updateApiStatus) {
      setErrorInSubmit(true);
      enqueueSnackbar(t('Error'), { variant: 'Error' });
    }
  }, [
    hasErrorFormSubmit,
    isLoaded,
    updateApiStatus,
    enqueueSnackbar,
    t,
    navigate,
    saveIndex,
    accommodationID,
    dispatchAPI,
    formWriteReloadState,
  ]);

  React.useEffect(() => {
    if (
      !isLoading &&
      isLoaded &&
      !isEmpty(formWriteData) &&
      !hasErrorFormSubmit
    ) {
      const stubFormWriteData = { ...formWriteData };
      const stubFormData = omit(formdata, ['errors', 'status']);
      if (statechecker(stubFormData, stubFormWriteData)) {
        setSaveButtonStatus(true);
      } else setSaveButtonStatus(false);
    }
  }, [isLoading, isLoaded, formWriteData, hasErrorFormSubmit, formdata]);

  React.useEffect(() => {
    if (apiTriggerStatus && !isEmpty(formWriteData)) {
      const instanceData = formWriteData;
      dispatch({
        type: types.UPDATE,
        payload: {
          accommodationName: instanceData.accommodationName,
          capacity: instanceData.capacity,
          businessUnitID: instanceData.businessUnitID,
          status: true,
        },
      });
      setTriggerStatus(false);
      logger.debug('After Update');
    }
  }, [apiTriggerStatus, formWriteData]);

  React.useEffect(() => {
    if (isDeleting && !accommodationIsLoading && accommodationIsLoaded) {
      if (accommodationHasError) {
        setDeleteHasFailed(true);
      } else {
        navigate('/accommodation');
      }

      setIsDeleting(false);
    }
  }, [
    accommodationIsLoaded,
    accommodationIsLoading,
    accommodationHasError,
    isDeleting,
    setIsDeleting,
    navigate,
  ]);

  return (
    <Paper
      elevation={0}
      data-testid="manageaccommodation-detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid
        container
        spacing={2}
        data-testid="grid-details-manageaccommodation"
      >
        <Grid item xs={12}>
          <VineaAlert
            isOpen={!!errorInSubmit}
            onExit={() => setErrorInSubmit(false)}
          />
          <DeleteIdentityAlert
            isOpen={deleteHasFailed}
            onExit={() => setDeleteHasFailed(false)}
            identityName="accommodation"
          />
          <Box px={2}>
            {warningInSubmit && (
              <Collapse in={warningInSubmit}>
                <Alert
                  variant="standard"
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarningInSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {t('Warning: Nothing to Update')}
                </Alert>
              </Collapse>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item>
              <Box mx={2} mt={3}>
                <FormControl>
                  <FieldLabelInput
                    autoComplete="off"
                    id="accommodationName"
                    name="accommodationName"
                    label={t('Name')}
                    size="small"
                    inlineLabel
                    value={formdata.accommodationName}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    error={formdata.errors.accommodationName}
                    helperText={formdata.errors.accommodationName}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <FormControl>
                  <FieldLabelInput
                    id="capacity"
                    label={t('Capacity')}
                    name="capacity"
                    size="small"
                    inlineLabel
                    value={formdata.capacity}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    error={formdata.errors.capacity}
                    helperText={formdata.errors.capacity}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box mx={2} mt={3}>
                <FormControl error={!!formdata.errors.businessUnitID}>
                  <VineaAutoComplete
                    value={formdata.businessUnitID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    options={businessUnitList}
                    labelVariant="body1"
                    id="businessunit"
                    name={t('Business Unit')}
                    label={t('Business Unit')}
                    inlineLabel={false}
                    inputProps={{
                      name: 'businessUnitID',
                    }}
                  />
                  {formdata.errors.businessUnitID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.businessUnitID}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Box ml={1} mb={2} style={{ flexDirection: 'column' }}>
          <Box my={2} mr={4}>
            <IdentityActivationSwitch
              handleOnToggleActive={handleOnToggleActive}
              isActive={isActive}
              identityName="accommodation"
            />
          </Box>

          <Box my={2}>
            <DeleteIdentityButton
              handleOnDelete={handleOnConfirmDelete}
              identityName="accommodation"
              isDisabled={deleteHasFailed}
            />
          </Box>
        </Box>

        <DiscardDialog
          open={discardDialogOpen}
          onClose={handleOnDialogClose}
          handleSaveChanges={handleSave}
          handleDiscardChanges={handleCancel}
        />

        <Grid item xs={12} sx={{ display: 'flex' }}>
          <Box mx={2}>
            <SplitButton
              color="success"
              onClick={handleSave}
              variant="contained"
              minWidth={130}
              disabled={saveButtonStatus}
            />
          </Box>
          <Box mx={2}>
            <VineaButton
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              {t('Close')}
            </VineaButton>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
