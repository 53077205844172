/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { sortBy, isEmpty } from 'lodash';
import { VineaTreeViewComponent } from '../Treeview';

export const SamplesBlockTransferList = ({
  sampleBlockVintages = [],
  isLoading = false,
  groups = [],
  onChange,
  minWidth = 300,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [leftSelected, setLeftSelected] = React.useState([]);
  const [rightSelected, setRightSelected] = React.useState([]);
  const [leftBlockVintages, setLeftBlockVintages] = React.useState([]);
  const [rightBlockVintages, setRightBlockVintages] = React.useState([]);

  const rightHeader = 'Selected Blocks';
  const leftHeader = 'Available Blocks';

  //   Set left side options to sample block vintage data
  React.useEffect(() => {
    setLeftBlockVintages(sampleBlockVintages);
  }, [sampleBlockVintages]);

  // If right side options are changed, remove those options from left side
  React.useEffect(() => {
    let leftRemoved = leftBlockVintages.filter(
      f => !rightBlockVintages.includes(f.blockVintageID),
    );

    setLeftBlockVintages(leftRemoved);
  }, [rightBlockVintages]);

  const handleOnChange = React.useCallback(
    selectedValues => {
      const getIds = selectedValues.reduce((acc, val) => {
        const { blockVintageID } = val;
        acc.push(blockVintageID);
        return acc;
      }, []);
      const target = {
        value: getIds,
        name: 'blockVintageIDs',
      };
      onChange({ target });
    },
    [onChange],
  );

  const handleAllRight = () => {
    const newValues = rightBlockVintages.concat(leftBlockVintages);
    setRightBlockVintages(newValues);
    setLeftBlockVintages([]);
    setLeftSelected([]);
    handleOnChange(newValues);
  };

  const handleCheckedRight = () => {
    let copyToRight = leftBlockVintages.filter(f =>
      leftSelected.includes(f.blockVintageID),
    );

    if (isEmpty(copyToRight)) {
      copyToRight = leftBlockVintages.filter(f =>
        leftSelected.includes(f.vineyardName),
      );
    }

    let leftRemoved = leftBlockVintages.filter(
      f => !leftSelected.includes(f.blockVintageID),
    );
    const selectedIds = copyToRight.map(f => f.blockVintageID);

    if (!isEmpty(leftRemoved)) {
      leftRemoved = leftBlockVintages.filter(
        f => !selectedIds.includes(f.blockVintageID),
      );
    }

    const newValues = rightBlockVintages.concat(copyToRight);
    setRightBlockVintages(newValues);
    setLeftBlockVintages(leftRemoved);
    setLeftSelected([]);
    handleOnChange(newValues);
  };

  const sortGroups = sortBy(groups);

  const handleCheckedLeft = () => {
    let copyToLeft = rightBlockVintages.filter(f =>
      rightSelected.includes(f.blockVintageID),
    );
    if (isEmpty(copyToLeft)) {
      copyToLeft = rightBlockVintages.filter(f =>
        rightSelected.includes(f.vineyardName),
      );
    }

    let rightRemoved = rightBlockVintages.filter(
      f => !rightSelected.includes(f.blockVintageID),
    );
    const selectedIds = copyToLeft.map(f => f.blockVintageID);

    if (!isEmpty(rightRemoved)) {
      rightRemoved = rightBlockVintages.filter(
        f => !selectedIds.includes(f.blockVintageID),
      );
    }
    setLeftBlockVintages(leftBlockVintages.concat(copyToLeft));
    setRightBlockVintages(rightRemoved);
    setRightSelected([]);
    handleOnChange(rightRemoved);
  };

  const handleAllLeft = () => {
    setLeftBlockVintages(leftBlockVintages.concat(rightBlockVintages));
    setRightBlockVintages([]);
    setRightSelected([]);
    handleOnChange([]);
  };

  const handleLeftNodeSelect = nodeIds => {
    setLeftSelected(nodeIds);
  };

  const handleRightNodeSelect = nodeIds => {
    setRightSelected(nodeIds);
  };

  const customList = side => (
    <Box minWidth={minWidth}>
      {side === 'left' && (
        <Typography variant="body1">{t(`${leftHeader}`)}</Typography>
      )}
      {side === 'right' && (
        <Typography variant="body1">{t(`${rightHeader}`)}</Typography>
      )}
      <Paper
        sx={{
          width: '100%',
          height: 330,
          overflow: 'auto',
          border: '1px solid rgba(0,0,0,0.23)',
        }}
        data-testid={`simpletransferlist-${side}`}
      >
        <Box
          sx={{
            padding: theme.spacing(1, 2),
          }}
        >
          <VineaTreeViewComponent
            groups={sortGroups}
            blocks={side === 'left' ? leftBlockVintages : rightBlockVintages}
            onNodeSelect={
              side === 'left' ? handleLeftNodeSelect : handleRightNodeSelect
            }
            selected={side === 'left' ? leftSelected : rightSelected}
            isLoading={isLoading}
            side={side}
          />
        </Box>
      </Paper>
    </Box>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        margin: 'auto',
      }}
    >
      <Grid item sx={{ flex: 1 }}>
        {customList('left')}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            sx={{
              margin: theme.spacing(0.5, 0),
            }}
            onClick={handleAllRight}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              margin: theme.spacing(0.5, 0),
            }}
            onClick={handleCheckedRight}
            disabled={leftSelected.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              margin: theme.spacing(0.5, 0),
            }}
            onClick={handleCheckedLeft}
            disabled={rightSelected.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              margin: theme.spacing(0.5, 0),
            }}
            onClick={handleAllLeft}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 1 }}>
        {customList('right')}
      </Grid>
    </Grid>
  );
};
