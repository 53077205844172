import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import PaletteIcon from '@mui/icons-material/Palette';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import {
  toggleThemeMode,
  swapThemeColors,
  isDarkMode,
  isColorSwaped,
} from './settingsReducer';

export default function Setting() {
  const darkMode = useSelector(isDarkMode);
  const colorSwaped = useSelector(isColorSwaped);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div>
      <Typography variant="h5">{t('Settings')}</Typography>
      <Card>
        <CardContent>
          <List>
            <ListItem>
              <ListItemIcon>
                <PaletteIcon />
              </ListItemIcon>
              <ListItemText primary="Dark Mode" />
              <ListItemSecondaryAction>
                <Switch
                  onChange={(e, checked) => dispatch(toggleThemeMode(checked))}
                  checked={darkMode}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CompareArrowsIcon />
              </ListItemIcon>
              <ListItemText primary="Swap Colors" />
              <ListItemSecondaryAction>
                <Switch
                  onChange={(e, checked) => dispatch(swapThemeColors(checked))}
                  checked={colorSwaped}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </div>
  );
}
