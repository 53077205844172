import { createSelector } from 'reselect';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { isEmpty, isObject, transform } from 'lodash';

const getChartsForDashboardPage =
  VineaNovaSelectors.getChartsByPageFiltersEntityData;
const getSingleChart = VineaNovaSelectors.getChartsByChartFiltersEntityData;

const chartFilterOptions = VineaNovaSelectors.getChartFiltersEntityData;

const getAllDashboardPages = VineaNovaSelectors.getDashboardPagesEntityData;
// const lookupDashboardPageFilters =
//   VineaNovaSelectors.getlookupDashboardPageFiltersEntityData;
const lookupDashboardPageFilters =
  VineaNovaSelectors.getDashboardPageFiltersEntityData;

const formatterFunctions = {
  $FORMAT_AS_NUMBER: (val, opts) =>
    `${opts.w.config.series[opts.seriesIndex]} - ${Math.round(val)}%`,
};

// Apply iterator function to all values in deeply nested object
const deepMap = (obj, iterator) =>
  transform(obj, (result, val, key) => {
    // We require mutation here
    // eslint-disable-next-line no-param-reassign
    result[key] = isObject(val) ? deepMap(val, iterator) : iterator(val);
  });

function getChartData(chart) {
  const { chartSettings, seriesData, ...restChartData } = chart;
  const { series, ...labels } = seriesData || {};

  const options = {
    ...JSON.parse(chartSettings),
    // Pie chart labels
    ...labels,
  };

  // replace title in options object with actual chart title
  options.title = { ...options.title, text: restChartData?.chartTitle || '' };

  // add functions to options object
  const optionsWithFunctions = deepMap(
    options,
    val => formatterFunctions[val] || val,
  );

  return {
    options: optionsWithFunctions,
    series,
    ...restChartData,
  };
}

// formats the filter options to be usable in a dropdown menu
function formatFilterOptions(filters) {
  return filters.map(filter => {
    const formattedOptions = filter.filterOptions.map(
      ({ id, filterDescription }) => ({
        id,
        key: id,
        value: filterDescription,
      }),
    );

    return {
      ...filter,
      filterOptions: formattedOptions,
    };
  });
}

export const getAllDashboardPagesSelector = createSelector(
  [getAllDashboardPages, lookupDashboardPageFilters],
  (allPages, lkpPageFilters) => {
    const allPageFilters = formatFilterOptions(lkpPageFilters);
    return (
      allPages
        ?.sort(({ pageSequence: a }, { pageSequence: b }) => a - b)
        .map(p => {
          const filters = allPageFilters.filter(
            f => f.dashboardPageID === p.dashboardPageID,
          );

          return {
            ...p,
            filters,
            hasFilters: !isEmpty(filters),
          };
        }) || []
    );
  },
);

function formatCharts(charts, lkpChartFilters) {
  const allChartFilters = formatFilterOptions(lkpChartFilters);
  return charts.map(c => {
    const chartData = getChartData(c);
    const filters = allChartFilters.filter(f => f.chartID === c.chartID);

    return {
      ...chartData,
      filters,
      hasFilters: !isEmpty(filters),
      isLoading: false,
    };
  });
}

export const getChartsForDashboardPageSelector = createSelector(
  [getChartsForDashboardPage, chartFilterOptions],
  formatCharts,
);

export const getSingleChartSelector = createSelector(
  [getSingleChart, chartFilterOptions],
  formatCharts,
);
