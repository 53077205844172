import React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

type VineaAlertProps = {
  isOpen: boolean;
  onExit: any;
  children: any;
  alertType: 'success' | 'info' | 'warning' | 'error';
  message: string;
};

export const VineaAlert = ({
  isOpen,
  onExit,
  children,
  alertType,
  message,
}: VineaAlertProps) => {
  return (
    <Box px={2}>
      {isOpen && (
        <Collapse
          data-testid="alertcollapse"
          in={isOpen}
          timeout={300}
          onExit={onExit}
        >
          <Alert
            variant="outlined"
            severity={alertType}
            data-testid="alertwithicon"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onExit}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alertType === `error` ? 'Error occured' : message}
            {children}
          </Alert>
        </Collapse>
      )}
    </Box>
  );
};

// VineaAlert.propTypes = {
//   isOpen: PropTypes.bool,
//   onExit: PropTypes.func,
//   children: PropTypes.element,
//   alertType: PropTypes.string,
//   message: PropTypes.string,
// };

// VineaAlert.defaultProps = {
//   isOpen: false,
//   onExit: f => f,
//   children: null,
//   alertType: 'error',
//   message: '',
// };
