import React from 'react';
import PropTypes from 'prop-types';
import { ManageContacts } from '../Manage';
import CreateNewEntities from './CreateNewEntities';

const CreateContactDetails = props => {
  const { identityId } = props;

  return (
    <CreateNewEntities
      label="Manage Contact Details"
      testId="manage-contact-details"
    >
      <ManageContacts identityId={identityId} />
    </CreateNewEntities>
  );
};

CreateContactDetails.propTypes = {
  identityId: PropTypes.number,
};

CreateContactDetails.defaultProps = {
  identityId: 0,
};

export default CreateContactDetails;
