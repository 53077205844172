import * as Yup from 'yup';
import { isValid } from 'date-fns';

export const ActivityDefaultRateSchema = Yup.object().shape({
  effectiveFromDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
  effectiveToDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v))),
  internalWorkCost: Yup.string().nullable(),
  targetVelocity: Yup.number().required('Required'),
  chargeRate: Yup.number().required('Required'),
  costRate: Yup.number().required('Required'),
});
