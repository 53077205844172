import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ManageRoles } from '../Manage/ManageRoles';

const CreateNewRoles = props => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { identityId } = props;

  return (
    <Grid container spacing={2} data-testid="new-classification-grid-container">
      <Grid item xs={12}>
        <Paper elevation={0} data-testid="classification-repeater-paper">
          <Box
            sx={{
              marginLeft: theme.spacing(4),
            }}
          >
            <Typography
              variant="h6"
              color="textPrimary"
              sx={{
                paddingTop: theme.spacing(2),
              }}
            >
              {t('Manage Roles')}
            </Typography>
          </Box>
        </Paper>
        <Paper
          elevation={0}
          data-testid="reference-repeater-paper"
          sx={{
            marginTop: theme.spacing(1),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
          }}
        >
          <Grid
            container
            spacing={2}
            data-testid="new-reference-grid-container"
          >
            <Grid item xs={12}>
              <ManageRoles identityId={identityId} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

CreateNewRoles.propTypes = {
  identityId: PropTypes.number,
};

CreateNewRoles.defaultProps = {
  identityId: null,
};

export default CreateNewRoles;
