import {
  forwardRef,
  useReducer,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/lab/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Datepicker } from '../../../components/Datepicker';
import {
  sagaActionTypes,
  vineaDetails,
  dateFormat,
  viewDateFormat,
  apiTypes,
  IdentityRoleTypes,
  commonGridHeaderNames,
  commonGridColumns,
} from '../../../constants';
import { FieldLabelInput } from '../../../components/TextField';
import { PersonSchema } from '../../VineyardIdentity/validations';

import {
  getFormWriteData,
  getBusinessUnit,
  getLkpEthnicityList,
  getLkpGenderList,
  getLkpLanguageList,
  getLkpNationalityList,
  getLkpSalutationList,
} from '../../../redux/selectors';
import { syncValidator } from '../../../utils/validator';
import {
  addGenericReducer,
  personState,
  types,
} from '../../VineyardIdentity/stateReducers';
import logger from '../../../utils/winstonLogger';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { Stack } from '@mui/material';
import { VineaCommonGrid } from '../../../components/Grid';

const StyledFieldLabelInput = styled(FieldLabelInput)(() => ({
  width: 250,
}));

const StyledFormControl = styled(FormControl)(() => ({
  width: 250,
}));

const CreateNewPerson = forwardRef((props, ref) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const {
    onSaveStepData,
    step,
    stepData,
    isWorker,
    calculateNameToCheck,
    matchingIdentities,
  } = props;
  const [alertOpen, setAlertOpen] = useState(true);

  const formWrite = useSelector(state => state.formWrite);
  const genderList = useSelector(state => getLkpGenderList(state));
  const ethnicList = useSelector(state => getLkpEthnicityList(state));
  const nationalityList = useSelector(state => getLkpNationalityList(state));
  const languageList = useSelector(state => getLkpLanguageList(state));
  const salutationList = useSelector(state => getLkpSalutationList(state));
  const [inSavingMode, setSaveMode] = useState(false);

  const [isIdentityTrigger, setIdentityTrigger] = useState(false);

  const { hasError: hasErrorFormSubmit } = useSelector(getFormWriteData);

  const businessUnitList = useSelector(state => getBusinessUnit(state));
  const [formdata, dispatch] = useReducer(addGenericReducer, personState);
  const today = format(new Date(), dateFormat);
  const { enqueueSnackbar } = useSnackbar();
  const orgId = useIdentityTypeId();
  const [actionTriggered, setActionTriggered] = useState(false);

  useImperativeHandle(ref, () => ({
    saveForm() {
      const validationErrors = syncValidator(PersonSchema)(formdata);
      if (!isEmpty(validationErrors)) {
        dispatch({ type: types.ERROR, payload: validationErrors });
      } else {
        const data = {
          IdentityTypeID: orgId,
          SalutationID: formdata.salutationID || null,
          RoleTypeID: isWorker ? IdentityRoleTypes.WORKER : null,
          DisplayName: formdata.firstName,
          BusinessUnitID: formdata.businessUnitID || null,
          CreatedDate: today,
          FirstName: formdata.firstName,
          MiddleName: formdata.middleName,
          LastName: formdata.lastName,
          KnownAs: formdata.knownAs,
          BirthDate: formdata.birthDate || null,
          GenderID: formdata.genderID || null,
          NationalityID: formdata.nationalityID || null,
          EthnicityID: formdata.ethnicityID || null,
          PreferredLanguageID: formdata.preferredLanguageID || null,
          SecondaryLanguageID: formdata.secondaryLanguageID || null,
          PreferredContactMethodTypeID: null,
        };
        // save to stepper reducer
        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.person,
            methodType: apiTypes.POST,
          },
        });
        setSaveMode(true);
        setActionTriggered(true);
        setIdentityTrigger(true);
      }
    },
  }));

  useEffect(() => {
    calculateNameToCheck(formdata);
  }, [formdata.firstName, formdata.lastName]);

  useEffect(() => {
    const { hasError, data, isLoaded } = formWrite;
    if (isLoaded && actionTriggered) {
      if (!hasError && !isEmpty(data) && isIdentityTrigger) {
        setIdentityTrigger(false);
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        const {
          data: { id, identityTypeID },
        } = formWrite;
        dispatchAPI({
          type: sagaActionTypes.FETCH_IDENTITIES,
          payload: {
            id,
            identityTypeId: identityTypeID,
          },
        });
      }
    }
  });

  useEffect(() => {
    const { data: formWriteData, hasError, isLoading } = formWrite;
    if (inSavingMode && !isLoading) {
      logger.debug(formWrite);
      if (!hasError) {
        setSaveMode(false);
        onSaveStepData(
          { data: formWriteData, isSaving: false, isComplete: true },
          step,
        );
      } else {
        setSaveMode(false);
        onSaveStepData(
          {
            data: { identityId: null },
            isSaving: false,
            isComplete: false,
            hasError: true,
          },
          step,
        );
      }
    }
  }, [inSavingMode, formWrite, onSaveStepData, step]);

  useEffect(() => {
    dispatch({
      type: types.UPDATE,
      payload: {
        ...stepData,
        salutationID: stepData.salutationID,
        birthDate: stepData.birthDate,
        genderID: stepData.genderID,
        nationalityID: stepData.nationalityID,
        ethnicityID: stepData.ethnicityID,
        preferredLanguageID: stepData.preferredLanguageID,
        secondaryLanguageID: stepData.secondaryLanguageID,
        businessUnitID: stepData.businessUnitID,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleComponentChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleOnBlur = () => {
    const validationErrors = syncValidator(PersonSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  return (
    <Grid container spacing={2} data-testid="grid-details">
      <Grid item xs={12}>
        <Paper
          elevation={0}
          data-testid="detail-info"
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <Box px={2}>
            {hasErrorFormSubmit && actionTriggered && (
              <Collapse in={alertOpen}>
                <Alert
                  variant="outlined"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Error occured
                </Alert>
              </Collapse>
            )}
          </Box>
          <Stack direction="row">
            <Grid container direction="row" mt={3}>
              {!isWorker && (
                <Box mx={2} mt={3}>
                  <StyledFormControl
                    error={!!formdata.errors.salutationID}
                    margin="none"
                  >
                    <VineaAutoComplete
                      value={formdata.salutationID || 0}
                      onChange={handleComponentChange}
                      onBlur={handleOnBlur}
                      inlineLabel={false}
                      options={salutationList}
                      labelVariant="body1"
                      id="salutation"
                      label={t('Title')}
                      name={t('Title')}
                      inputProps={{
                        name: 'salutationID',
                      }}
                    />
                    {formdata.errors.salutationID && (
                      <FormHelperText id="component-error-text">
                        {formdata.errors.salutationID}
                      </FormHelperText>
                    )}
                  </StyledFormControl>
                </Box>
              )}
              <Box mx={2} mt={3}>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="firstname"
                  name="firstName"
                  label={t('First Name')}
                  size="small"
                  inlineLabel
                  value={formdata.firstName}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.firstName}
                  helperText={formdata.errors.firstName}
                />
              </Box>
              <Box mx={2} mt={3}>
                <StyledFieldLabelInput
                  id="middlename"
                  label={t('Middle Name')}
                  name="middleName"
                  size="small"
                  inlineLabel
                  value={formdata.middleName}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.middleName}
                  helperText={formdata.errors.middleName}
                />
              </Box>
              <Box mx={2} mt={3}>
                <StyledFieldLabelInput
                  id="lastname"
                  label={t('Last Name')}
                  name="lastName"
                  size="small"
                  inlineLabel
                  value={formdata.lastName}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.lastName}
                  helperText={formdata.errors.lastName}
                />
              </Box>
              <Box mx={2} mt={3}>
                <StyledFieldLabelInput
                  id="knownas"
                  label={t('Known As')}
                  name="knownAs"
                  size="small"
                  inlineLabel
                  value={formdata.knownAs}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.knownAs}
                  helperText={formdata.errors.knownAs}
                />
              </Box>
              <Box mx={2} mt="26px">
                <StyledFormControl
                  error={!!formdata.errors.birthDate}
                  data-testid="date-formcontrol"
                >
                  <Datepicker
                    disableFuture
                    id="dateofbirth"
                    inputFormat={viewDateFormat}
                    placeholder="DD/MM/YYYY"
                    size="small"
                    label={t('Date of Birth')}
                    value={formdata.birthDate}
                    onChange={handleComponentChange}
                    name="birthDate"
                    margin="dense"
                  />
                  {formdata.errors.birthDate && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.birthDate}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
              <Box mx={2} mt={3}>
                <StyledFormControl
                  error={!!formdata.errors.genderID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.genderID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    inlineLabel={false}
                    options={genderList}
                    labelVariant="body1"
                    name={t('Gender')}
                    id="gender"
                    label={t('Gender')}
                    inputProps={{
                      name: 'genderID',
                    }}
                  />
                  {formdata.errors.genderID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.genderID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
              <Box mx={2} mt={3}>
                <StyledFormControl
                  error={!!formdata.errors.nationalityID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.nationalityID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    inlineLabel={false}
                    options={nationalityList}
                    labelVariant="body1"
                    id="nationality"
                    name={t('Nationality')}
                    label={t('Nationality')}
                    inputProps={{
                      name: 'nationalityID',
                    }}
                  />
                  {formdata.errors.nationalityID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.nationalityID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
              <Box mx={2} mt={3}>
                <StyledFormControl
                  error={!!formdata.errors.ethnicityID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.ethnicityID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    inlineLabel={false}
                    options={ethnicList}
                    labelVariant="body1"
                    id="ethnicity"
                    name={t('Ethnicity')}
                    label={t('Ethnicity')}
                    inputProps={{
                      name: 'ethnicityID',
                    }}
                  />
                  {formdata.errors.ethnicityID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.ethnicityID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
              <Box mx={2} mt={3}>
                <StyledFormControl
                  error={!!formdata.errors.preferredLanguageID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.preferredLanguageID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    inlineLabel={false}
                    options={languageList}
                    labelVariant="body1"
                    id="primarylanguage"
                    name={t('Primary Language')}
                    label={t('Primary Language')}
                    inputProps={{
                      name: 'preferredLanguageID',
                    }}
                  />
                  {formdata.errors.preferredLanguageID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.preferredLanguageID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
              <Box mx={2} mt={3}>
                <StyledFormControl
                  error={!!formdata.errors.secondaryLanguageID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.secondaryLanguageID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    inlineLabel={false}
                    options={languageList}
                    labelVariant="body1"
                    id="secondarylanguage"
                    name={t('Secondary Language')}
                    label={t('Secondary Language')}
                    inputProps={{
                      name: 'secondaryLanguageID',
                    }}
                    error={!!formdata.errors.secondaryLanguageID}
                  />
                  {formdata.errors.secondaryLanguageID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.secondaryLanguageID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
              <Box mx={2} mt={3}>
                <StyledFormControl
                  error={!!formdata.errors.businessUnitID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.businessUnitID || 0}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                    inlineLabel={false}
                    options={businessUnitList}
                    labelVariant="body1"
                    id="businessunit"
                    name={t('Business Unit')}
                    label={t('Business Unit')}
                    inputProps={{
                      name: 'businessUnitID',
                    }}
                  />
                  {formdata.errors.businessUnitID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.businessUnitID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Box>
            </Grid>
            <VineaCommonGrid
              heading={t(commonGridHeaderNames.SIMILAR_IDENTITIES)}
              colHeaders={commonGridColumns.SIMILAR_IDENTITIES}
              tableData={matchingIdentities}
              hasManageOption={false}
              hasActiveToggle={false}
            />
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
});

CreateNewPerson.propTypes = {
  stepData: PropTypes.shape({
    birthDate: null,
    businessUnitID: PropTypes.number,
    createdDate: PropTypes.string,
    ethnicityID: PropTypes.number,
    firstName: PropTypes.string,
    genderID: PropTypes.number,
    id: PropTypes.number,
    identityTypeID: PropTypes.number,
    knownAs: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    nationalityID: PropTypes.number,
    preferredContactMethodTypeID: PropTypes.number,
    preferredLanguageID: PropTypes.number,
    roleTypeID: PropTypes.number,
    salutationID: PropTypes.number,
    secondaryLanguageID: PropTypes.number,
    ts: PropTypes.string,
  }),
  onSaveStepData: PropTypes.func,
  step: PropTypes.number,
  isWorker: PropTypes.bool,
  calculateNameToCheck: PropTypes.func,
  matchingIdentities: PropTypes.array,
};

CreateNewPerson.defaultProps = {
  stepData: {},
  onSaveStepData: () => {},
  step: 0,
  isWorker: false,
  calculateNameToCheck: () => {},
  matchingIdentities: [],
};

export default CreateNewPerson;
