import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchJobData } from './useFetchJobData';
import {
  filter,
  find,
  get,
  inRange,
  isEmpty,
  isEqual,
  isNil,
  map,
  orderBy,
  size,
  sumBy,
} from 'lodash';
import { useDispatch } from 'react-redux';
import { isBefore, parseISO } from 'date-fns';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { JobTypeIDs, SiteTypeIDs } from '../../../constants';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { syncValidator } from '../../../utils/validator';
import { JobBlockRowSchema } from '../validations';
import { useSelector } from 'react-redux';
import { MetaDataType } from '../interfaces/jobInterfaces';

export interface JobBlockRowType {
  jobBlockRowID?: number;
  jobBlockID?: number;
  vineyardBlockRowID: number;
  workRecordPieceworkGroupId?: number | null;
  jobTypeID: number;
  blockRowUnits: number;
  selectedUnits: number;
  selected?: boolean;
  notes: string | null;
  isSplit?: boolean;
  variety: string | null;
  rowNumber: string;
  canDelete: boolean;
}

export interface JobBlockType {
  vineyardName: string;
  vineyardID: number;
  blockName: string;
  selectedUnits: number;
  id: number;
  jobID: number;
  vineyardBlockID: number;
  description: string;
  dateStarted: string;
  dateCompleted: string;
  complete: boolean;
  ts?: string;
}

export interface JobBlockValidationType {
  dateCompleted: string;
  selectedUnits: string;
}

export interface EntityType {
  data?: any;
  isLoading: boolean;
  isLoaded: boolean;
  hasError: boolean;
}

export const useJobBlockRowsHook = (jobBlockID: number) => {
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [saveIndex, setSaveIndex] = useState(0);
  const [saveMode, setSaveMode] = useState('JOB_BLOCK');
  const [jobBlockRowDrawerOpen, setJobBlockRowDrawerOpen] = useState(false);
  const [updateJobBlockApiTriggered, setUpdateJobBlockApiTriggered] =
    useState(false);
  const [
    bulkSelectJobBlockRowsApiTriggered,
    setbulkSelectJobBlockRowsApiTriggered,
  ] = useState(false);
  const [updateJobBlockRowApiTriggered, setUpdateJobBlockRowApiTriggered] =
    useState(false);
    
  const { data: jobData } = useSelector(
    VineaNovaSelectors.getIdentityJobEntity,
  ) as MetaDataType;
  const siteTypeID = get(jobData, 'siteTypeID', 1);
  const unitsColumnText = useMemo(() => {
    if (
      siteTypeID === SiteTypeIDs.VINEYARD
    ) return 'Vines'
    else return 'Units'
  }, [siteTypeID]);

  const [validationErrors, setValidationErrors] =
    useState<JobBlockValidationType>();
  const jobBlockRowsColumns = [
    t('Row Number'),
    t('Variety'),
    t(unitsColumnText),
    t('Selected ' + unitsColumnText),
    t('Selected'),
    t('Notes'),
    t('Is Split'),
  ];
  const { jobBlock: jobBlockEntity, rowsByJobBlock: rowsByJobBlockEntity } =
    useFetchJobData({
      jobBlockID: jobBlockID,
    });
  const {
    data: jobBlockData,
    isLoading: jobBlockLoading,
    isLoaded: jobBlockLoaded,
    hasError: jobBlockHasError,
  } = jobBlockEntity as EntityType;

  const {
    data: rowsByJobBlockData,
    isLoading: rowsByJobBlockLoading,
    isLoaded: rowsByJobBlockLoaded,
    hasError: rowsByJobBlockHasError,
  } = rowsByJobBlockEntity as EntityType;

  const {
    isLoaded: jobBlockRowsLoaded,
    isLoading: jobBlockRowsLoading,
    hasError: jobBlockRowsHasError,
  } = useSelector(VineaNovaSelectors.getJobBlockRowsEntityMeta) as MetaDataType;

  const [jobBlockDetails, setJobBlockDetails] = useState<JobBlockType>();
  const [updateJobBlockRowObject, setUpdateJobBlockRowObject] =
    useState<JobBlockRowType>();
  const jobBlockRowsFormatted = useMemo(
    () =>
      map(rowsByJobBlockData, (row: any) => {
        return { ...row, selected: row?.selectedUnits > 0 ? 1 : 0 };
      }),
    [rowsByJobBlockData],
  );
  const [jobBlockRows, setJobBlockRows] = useState<any[]>(
    jobBlockRowsFormatted,
  );

  // Number of selected rows
  const selectedRowsValue = useMemo(() => {
    const updatedSelectedRowsValue = size(
      filter(jobBlockRows, { selected: 1 }),
    );
    return updatedSelectedRowsValue;
  }, [jobBlockRows]);

  // Sum of units of selected rows
  const selectedUnitsValue = useMemo(() => {
    const updatedSelectedUnitsValue =
      sumBy(
        filter(jobBlockRows, { selected: 1, selectedUnits: 0 }),
        'blockRowUnits',
      ) + sumBy(filter(jobBlockRows, { selected: 1 }), 'selectedUnits');
    return updatedSelectedUnitsValue;
  }, [jobBlockRows]);

  const isRowRangeEntered = useMemo(() => {
    const fromRow = get(jobBlockDetails, 'selectFromRow');
    const toRow = get(jobBlockDetails, 'selectToRow');
    if (fromRow && toRow)
      return (
        !isNaN(parseFloat(fromRow)) &&
        isFinite(fromRow) &&
        !isNaN(parseFloat(toRow)) &&
        isFinite(toRow)
      );
    return false;
  }, [jobBlockDetails]);

  const [
    initialSelectedVineyardBlockRowIDs,
    setInitialSelectedVineyardBlockRowIDs,
  ] = useState<number[]>([]);

  const selectedVineyardBlockRowIDs = useMemo(
    () => map(filter(jobBlockRows, { selected: 1 }), 'vineyardBlockRowID'),
    [jobBlockRows],
  );

  const jobBlockHasChanges = useMemo(
    () =>
      get(jobBlockData, 'description') !==
        get(jobBlockDetails, 'description') ||
      get(jobBlockData, 'dateCompleted') !==
        get(jobBlockDetails, 'dateCompleted') ||
      get(jobBlockData, 'complete') !== get(jobBlockDetails, 'complete'),
    [jobBlockData, jobBlockDetails],
  );

  const rowsHasChanges = useMemo(
    () =>
      !isEqual(
        orderBy(initialSelectedVineyardBlockRowIDs),
        orderBy(selectedVineyardBlockRowIDs),
      ),
    [initialSelectedVineyardBlockRowIDs, selectedVineyardBlockRowIDs],
  );

  // functions
  const handleChangeJobBlock = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    const newValue = isNaN(Number(value)) ? value : Number(value);
    //@ts-ignore
    setJobBlockDetails({ ...jobBlockDetails, [name]: newValue });
  };

  const handleChangeJobBlockRow = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { value, name },
    } = event;
    const newValue = isNaN(Number(value)) ? value : Number(value);

    setUpdateJobBlockRowObject({
      ...updateJobBlockRowObject,
      [name]: newValue,
    } as JobBlockRowType);
  };

  const handleOnClickCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { checked, name },
    } = event;
    //@ts-ignore
    setJobBlockDetails({
      ...jobBlockDetails,
      [name]: checked,
    });
  };

  // tick or untick selected checkbox
  const handleOnRowCheckboxClick = (row: any) => {
    // Select / unselect the row
    const updatedRows = map(jobBlockRows, (jobBlockRow: JobBlockRowType) => {
      if (
        jobBlockRow.jobBlockRowID === row?.jobBlockRowID &&
        jobBlockRow.vineyardBlockRowID === row?.vineyardBlockRowID &&
        jobBlockRow?.canDelete === true
      ) {
        if (jobBlockRow.selected) return { ...jobBlockRow, selected: 0 };
        else return { ...jobBlockRow, selected: 1 };
      }
      return jobBlockRow;
    });
    setJobBlockRows(updatedRows);
  };

  // Function to either select or unselect all job block rows
  const handleOnToggleBulkRowSelection = (select: boolean) => {
    const updatedRows = map(jobBlockRows, (jobBlockRow: JobBlockRowType) => {
      if (jobBlockRow?.canDelete) {
        if (select) return { ...jobBlockRow, selected: 1 };
        else return { ...jobBlockRow, selected: 0 };
      } else {
        return jobBlockRow;
      }
    });
    setJobBlockRows(updatedRows);
  };

  const isRowInRange = (x: string) => {
    const leadingNum = x.match(/^\d+/);
    const fromRow = get(jobBlockDetails, 'selectFromRow', 0);
    const toRow = get(jobBlockDetails, 'selectToRow', 0);

    if (!isNil(leadingNum)) {
      const num = parseFloat(leadingNum[0]);
      if (!isNaN(num) && inRange(num, fromRow, toRow + 1)) return true;
    }
    return false;
  };

  // Function to select job block rows in given range, unselecting any rows not in range
  const handleOnToggleRowRange = () => {
    const updatedRows = map(jobBlockRows, (jobBlockRow: JobBlockRowType) => {
      if (isRowInRange(jobBlockRow.rowNumber) && jobBlockRow?.canDelete) {
        if (get(jobBlockRow, 'selected', 0) === 0)
          return { ...jobBlockRow, selected: 1 };
        else return { ...jobBlockRow, selected: 0 };
      } else {
        return jobBlockRow;
      }
    });
    setJobBlockRows(updatedRows);
  };

  // Open edit job block row drawer when row is clicked
  const handleEditJobBlockRow = (row: any) => {
    const selectedJobBlockRow = find(jobBlockRows, {
      jobBlockRowID: row?.jobBlockRowID,
      vineyardBlockRowID: row?.vineyardBlockRowID,
    });

    if (selectedJobBlockRow?.selected === 1) {
      setJobBlockRowDrawerOpen(true);
      setUpdateJobBlockRowObject(selectedJobBlockRow);
    }
  };

  const handleOnCloseJobBlockRowDrawer = () => {
    setJobBlockRowDrawerOpen(!jobBlockRowDrawerOpen);
  };

  const handleOnSaveJobBlock = (event: any, index: number) => {
    setSaveIndex(index);
    setSaveMode('JOB_BLOCK');
    if (jobBlockHasChanges) {
      let jobBlockValidationErrors = {};
      if (!isEmpty(get(jobBlockDetails, 'dateCompleted', ''))) {
        const isBeforeFromDate = isBefore(
          parseISO(get(jobBlockDetails, 'dateCompleted', '')),
          parseISO(get(jobBlockDetails, 'dateStarted', '')),
        );
        if (isBeforeFromDate) {
          jobBlockValidationErrors = {
            dateCompleted: 'Date completed cannot be before date started.',
          };
        }
      }

      if (isEmpty(jobBlockValidationErrors)) {
        dispatchAPI(
          VineaNovaActions.api.v1.jobBlock.put.request({
            postBody: {
              id: jobBlockDetails?.id,
              jobID: jobBlockDetails?.jobID,
              vineyardBlockID: jobBlockDetails?.vineyardBlockID,
              description: jobBlockDetails?.description,
              dateStarted: jobBlockDetails?.dateStarted,
              dateCompleted: jobBlockDetails?.dateCompleted,
              complete: jobBlockDetails?.complete,
              ts: jobBlockDetails?.ts,
            },
          }),
        );
        setUpdateJobBlockApiTriggered(true);
      }
      setValidationErrors(jobBlockValidationErrors as JobBlockValidationType);
    }
    if (rowsHasChanges) {
      const selectedRowString = selectedVineyardBlockRowIDs.join(',');
      // Then need to build ui to update other job block data

      dispatchAPI(
        VineaNovaActions.api.v1.rowsByJobBlock.post.request({
          postBody: {
            jobBlockID: jobBlockID,
            vineyardBlockRowIDs: selectedRowString,
          },
        }),
      );
      setbulkSelectJobBlockRowsApiTriggered(true);
    }
  };

  const handleOnSaveJobBlockRow = () => {
    setSaveMode('JOB_BLOCK_ROW');
    const validationErrors = syncValidator(JobBlockRowSchema)(
      updateJobBlockRowObject,
    );

    if (isEmpty(validationErrors)) {
      dispatchAPI(
        VineaNovaActions.api.v1.jobBlockRows.put.request({
          postBody: {
            id: updateJobBlockRowObject?.jobBlockRowID,
            jobBlockID: updateJobBlockRowObject?.jobBlockID,
            vineyardBlockRowID: updateJobBlockRowObject?.vineyardBlockRowID,
            workRecordPieceworkGroupId:
              updateJobBlockRowObject?.workRecordPieceworkGroupId,
            selectedVines:
              updateJobBlockRowObject?.jobTypeID !== JobTypeIDs.BY_POST &&
              updateJobBlockRowObject?.jobTypeID !== JobTypeIDs.BY_METRE
                ? updateJobBlockRowObject?.selectedUnits
                : 0,
            selectedPosts:
              updateJobBlockRowObject?.jobTypeID === JobTypeIDs.BY_POST
                ? updateJobBlockRowObject?.selectedUnits
                : 0,
            selectedMetres:
              updateJobBlockRowObject?.jobTypeID === JobTypeIDs.BY_METRE
                ? updateJobBlockRowObject?.selectedUnits
                : 0,
            notes: updateJobBlockRowObject?.notes,
            isSplit: updateJobBlockRowObject?.isSplit,
          },
        }),
      );
      setUpdateJobBlockRowApiTriggered(true);
    } else {
      setValidationErrors(validationErrors as JobBlockValidationType);
    }
  };

  // useEffects

  // Set table header column once job block rows data loaded
  useEffect(() => {
    if (rowsByJobBlockLoaded && !rowsByJobBlockLoading) {
      const jobTypeID = get(
        find(rowsByJobBlockData, (row: JobBlockRowType) => {
          !isNil(get(row, 'jobTypeID'));
        }),
        'jobTypeID',
        0,
      );

      const selectedVineyardBlockRowIDs = map(
        filter(jobBlockRowsFormatted, { selected: 1 }),
        'vineyardBlockRowID',
      );
      setInitialSelectedVineyardBlockRowIDs(selectedVineyardBlockRowIDs);
      setJobBlockRows(jobBlockRowsFormatted);
    }
  }, [rowsByJobBlockLoaded, rowsByJobBlockLoading]);

  // Set Job block data once job block get api has loaded
  useEffect(() => {
    if (jobBlockLoaded && !jobBlockLoading) setJobBlockDetails(jobBlockData);
  }, [jobBlockLoaded, jobBlockLoading]);

  // Re-fetch job block data once job block update has loaded
  useEffect(() => {
    if (updateJobBlockApiTriggered && jobBlockLoaded && !jobBlockLoading) {
      if (!jobBlockHasError) {
        dispatchAPI(
          VineaNovaActions.api.v1.jobBlock.get.request({
            queryParams: {
              JobBlockID: jobBlockID,
            },
          }),
        );
      }

      setUpdateJobBlockApiTriggered(false);
    }
  }, [updateJobBlockApiTriggered, jobBlockLoaded, jobBlockLoading]);

  // Re-fetch job block rows once job block rows update has loaded
  useEffect(() => {
    if (
      bulkSelectJobBlockRowsApiTriggered &&
      rowsByJobBlockLoaded &&
      !rowsByJobBlockLoading
    ) {
      if (!rowsByJobBlockHasError) {
        dispatchAPI(
          VineaNovaActions.api.v1.rowsByJobBlock.get.request({
            queryParams: {
              JobBlockID: jobBlockID,
            },
          }),
        );
      }

      setbulkSelectJobBlockRowsApiTriggered(false);
    }
  }, [
    bulkSelectJobBlockRowsApiTriggered,
    rowsByJobBlockLoaded,
    rowsByJobBlockLoading,
  ]);

  // Show snackbar for job block update
  useEffect(() => {
    if (
      updateJobBlockApiTriggered &&
      jobBlockLoaded &&
      !jobBlockLoading &&
      saveMode === 'JOB_BLOCK'
    ) {
      if (!jobBlockHasError) {
        // @ts-ignore
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        if (saveIndex === 0) navigate(-1);
      } else {
        // @ts-ignore
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }

      setUpdateJobBlockApiTriggered(false);
    }
  }, [updateJobBlockApiTriggered, jobBlockLoaded, jobBlockLoading]);

  // show snackbar for job block rows bulk select update
  useEffect(() => {
    if (
      bulkSelectJobBlockRowsApiTriggered &&
      rowsByJobBlockLoaded &&
      !rowsByJobBlockLoading &&
      saveMode === 'JOB_BLOCK'
    ) {
      if (!rowsByJobBlockHasError) {
        // @ts-ignore
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        if (saveIndex === 0) navigate(-1);
      } else {
        // @ts-ignore
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }

      setbulkSelectJobBlockRowsApiTriggered(false);
    }
  }, [
    bulkSelectJobBlockRowsApiTriggered,
    rowsByJobBlockLoaded,
    rowsByJobBlockLoading,
  ]);

  // Show snackbar for job block row update
  useEffect(() => {
    if (
      updateJobBlockRowApiTriggered &&
      jobBlockRowsLoaded &&
      !jobBlockRowsLoading &&
      saveMode === 'JOB_BLOCK_ROW'
    ) {
      if (!jobBlockRowsHasError) {
        // @ts-ignore
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        setJobBlockRowDrawerOpen(false);
        setbulkSelectJobBlockRowsApiTriggered(true);
      } else {
        // @ts-ignore
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }

      setUpdateJobBlockRowApiTriggered(false);
    }
  }, [updateJobBlockRowApiTriggered, jobBlockRowsLoaded, jobBlockRowsLoading]);

  return {
    jobBlockApiTriggered: updateJobBlockApiTriggered,
    jobBlockRowsApiTriggered: bulkSelectJobBlockRowsApiTriggered,
    unitsColumnText,
    jobBlockRowsColumns,
    jobBlockDetails,
    updateJobBlockRowObject,
    selectedRowsValue,
    selectedUnitsValue,
    rowsHasChanges,
    isRowRangeEntered,
    jobBlockHasChanges,
    jobBlockRowsLoaded: rowsByJobBlockLoaded,
    jobBlockRowsLoading: rowsByJobBlockLoading,
    jobBlockRowsHasError: rowsByJobBlockHasError,
    jobBlockRows,
    jobBlockLoaded,
    jobBlockLoading,
    jobBlockHasError,
    validationErrors,
    jobBlockRowDrawerOpen,
    handleChangeJobBlock,
    handleChangeJobBlockRow,
    handleOnClickCheckbox,
    handleOnRowCheckboxClick,
    handleOnToggleBulkRowSelection,
    handleOnToggleRowRange,
    handleOnSaveJobBlock,
    handleOnSaveJobBlockRow,
    setJobBlockApiTriggered: setUpdateJobBlockApiTriggered,
    setJobBlockRowsApiTriggered: setbulkSelectJobBlockRowsApiTriggered,
    handleOnCloseJobBlockRowDrawer,
    handleEditJobBlockRow,
  };
};
