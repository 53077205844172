/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { StyledInput } from './FileUpload';

// ({ handleOnChange }) =>

export const ImageUpload = forwardRef((props, ref) => {
  const { handleOnChange } = props;
  const [image, setImage] = React.useState({
    preview: '',
    raw: '',
    base64: '',
  });

  React.useImperativeHandle(ref, () => ({
    resetImage() {
      setImage({ preview: '', raw: '', base64: '' });
    },
  }));

  const handleChange = e => {
    if (e.target.files.length) {
      const currentImage = {
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      };

      if (e.target.files[0]) {
        getBase64(e.target.files[0]).then(result => {
          const imageWithoutData = result
            .replace('data:', '')
            .replace(/^.+,/, '');
          setImage({ ...currentImage, base64: imageWithoutData });
          handleOnChange({ ...currentImage, base64: imageWithoutData });
        });
      }
    }
  };

  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = '';
      // Make new FileReader
      // eslint-disable-next-line no-undef
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log('Called', reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleUpload = async e => {
    e.preventDefault();
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('image', image.raw);

    // eslint-disable-next-line no-undef
    await fetch('YOUR_URL', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      body: formData,
    });
  };

  return (
    <>
      <label htmlFor="contained-button-file">
        <Grid container justifyContent="center" alignItems="center">
          <Fab
            component="span"
            sx={{
              width: '40px',
              height: '40px',
            }}
          >
            <AddPhotoAlternateIcon fontSize="small" />
          </Fab>
        </Grid>
        <StyledInput
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleChange}
        />
      </label>
    </>
  );
});

ImageUpload.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
};
