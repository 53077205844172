import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useSelector } from 'react-redux';
import maxBy from 'lodash/maxBy';
import { useTheme, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { VineaButton } from '../../../components/VineaButton';
import { VineaAlert } from '../../../components/VineaAlert';
import { FieldLabelInput } from '../../../components/TextField';
// eslint-disable-next-line import/named
import { FieldNameSelect } from '../../../components/Select';
import { getLookupRowSpecialAttribute } from '../../../redux/selectors';
import { useVineyardBlockRowsHook } from '../Hooks/useVineyardBlockRowsHook';
import { SiteTypeIDs } from '../../../constants';

const FormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 250,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

export const AddBlockRowDetail = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { blockId } = useParams();

  const navigate = useNavigate();

  const {
    formWriteLoading,
    errorInSubmit,
    blockRowDetails,
    vineyardBlockLastRowSelected,
    disableEndRow,
    unitName,
    siteTypeID,
    handleOnChange,
    onSaveNew,
    setErrorInSubmit,
    handleOnBlur,
    setVineyardBlockLastRowSelected,
  } = useVineyardBlockRowsHook();

  React.useEffect(() => {
    let lastRowSelected = {};
    let lastRowSelectedFormat = {};

    if (Array.isArray(blockRowDetails) && blockRowDetails.length > 0) {
      lastRowSelected = maxBy(blockRowDetails, 'id');

      if (Number.isNaN(Number(lastRowSelected.rowNumber)) === false) {
        lastRowSelectedFormat = {
          ...lastRowSelected,
          startRow: Number(lastRowSelected.rowNumber) + 1,
          endRow: Number(lastRowSelected.rowNumber) + 1,
        };
      } else {
        lastRowSelectedFormat = {
          ...lastRowSelected,
          startRow: '',
          endRow: '',
        };
      }
    } else {
      lastRowSelectedFormat = {
        startRow: 1,
        endRow: 1,
        plantedUnits: 0,
        actualUnits: 0,
        rowLength: 0,
        postCount: 0,
        specialAttributeID: 0,
        bayCount: 0,
        bayAvgArea: 0,
        blockID: blockId,
      };
    }
    setVineyardBlockLastRowSelected(lastRowSelectedFormat);
  }, [setVineyardBlockLastRowSelected, blockId, blockRowDetails]);

  const formdata = { errors: {}, ...vineyardBlockLastRowSelected };
  // for populating dropddowns
  // get selector values
  const rowSpecialAttributeSelector = useSelector(getLookupRowSpecialAttribute);

  const handleOnCancel = () => {
    navigate(-1);
  };

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12} data-testid="vineaalert">
          <VineaAlert
            isOpen={!!errorInSubmit}
            onExit={() => setErrorInSubmit(false)}
          />
        </Grid>
        <Grid item xs={12} md={10} lg={8} data-id="grid-top-wrapper">
          <Grid container spacing={2} data-testid="grid-field">
            <Grid item xs={6} sm={4} data-testid="grid-inner-fields">
              <FormBoxControl mt={1}>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="startRow"
                  name="startRow"
                  label={t('Start Row')}
                  size="small"
                  inlineLabel
                  value={formdata.startRow}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.startRow}
                />
              </FormBoxControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormBoxControl mt={1}>
                {!disableEndRow && (
                  <StyledFieldLabelInput
                    id="endRow"
                    name="endRow"
                    label={t('End Row')}
                    size="small"
                    inlineLabel
                    value={formdata.endRow}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    error={formdata.errors.endRow}
                  />
                )}
              </FormBoxControl>
            </Grid>
            <Grid item xs={4}>
              {' '}
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormBoxControl mt={1}>
                <StyledFieldLabelInput
                  id="plantedUnits"
                  name="plantedUnits"
                  label={t(`Planted ${unitName}s`)}
                  size="small"
                  inlineLabel
                  value={formdata.plantedUnits}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.plantedUnits}
                />
              </FormBoxControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormBoxControl mt={1}>
                <StyledFieldLabelInput
                  id="actualUnits"
                  name="actualUnits"
                  label={t(`Current ${unitName}s`)}
                  size="small"
                  inlineLabel
                  value={formdata.actualUnits}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.actualUnits}
                />
              </FormBoxControl>
            </Grid>
            <Grid item xs={4}>
              {' '}
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormBoxControl mt={1}>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="postCount"
                  name="postCount"
                  label={t('Post Count')}
                  size="small"
                  inlineLabel
                  value={formdata.postCount}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.postCount}
                />
              </FormBoxControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormBoxControl mt={1}>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="rowLength"
                  name="rowLength"
                  label={t('Row Length (m)')}
                  size="small"
                  inlineLabel
                  value={formdata.rowLength}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.rowLength}
                />
              </FormBoxControl>
            </Grid>
            {siteTypeID === SiteTypeIDs.ORCHARD_KIWIFRUIT && (
              <>
                <Grid item xs={4}>
                  {' '}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormBoxControl mt={1}>
                    <StyledFieldLabelInput
                      autoComplete="off"
                      id="bayCount"
                      name="bayCount"
                      label={t('Bay Count')}
                      size="small"
                      inlineLabel
                      value={formdata.bayCount}
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      error={formdata.errors.bayCount}
                    />
                  </FormBoxControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormBoxControl mt={1}>
                    <StyledFieldLabelInput
                      autoComplete="off"
                      id="bayAvgArea"
                      name="bayAvgArea"
                      label={t('Bay Avg Area')}
                      size="small"
                      inlineLabel
                      value={formdata.bayAvgArea}
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      error={formdata.errors.bayAvgArea}
                    />
                  </FormBoxControl>
                </Grid>
              </>
            )}

            <Grid item xs={4}>
              {' '}
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormBoxControl mt={1}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.specialAttributeID}
                  margin="none"
                >
                  <FieldNameSelect
                    value={formdata.specialAttributeID}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    options={rowSpecialAttributeSelector}
                    label={t('Special Attribute')}
                    inputProps={{
                      name: 'specialAttributeID',
                    }}
                  />
                  {formdata.errors.specialAttributeID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.specialAttributeID}
                    </FormHelperText>
                  )}
                </FormControl>
              </FormBoxControl>
            </Grid>
            <Grid item xs={4}>
              {' '}
            </Grid>
            <Grid item xs={4}>
              {' '}
            </Grid>
            <Grid item xs={4}>
              <FormBoxControl mt={1}>
                <StyledFieldLabelInput
                  id="comments"
                  name="rowComments"
                  label={t('Comments')}
                  size="small"
                  inlineLabel
                  value={formdata.rowComments}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.rowComments}
                  helperText="Error"
                  multiline
                  rowsMax={4}
                  rows={3}
                />
              </FormBoxControl>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
              }}
            >
              <Box mx={2} mt={1}>
                <VineaButton
                  color="success"
                  onClick={onSaveNew}
                  variant="outlined"
                  sx={{
                    marginRight: theme.spacing(1),
                    minWidth: 150,
                  }}
                  minWidth={100}
                  disabled={!!formWriteLoading}
                >
                  Save
                </VineaButton>
              </Box>
              <Box mx={2} mt={1}>
                <VineaButton
                  variant="outlined"
                  onClick={handleOnCancel}
                  disabled={!!formWriteLoading}
                >
                  Cancel
                </VineaButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} />
      </Grid>
    </Paper>
  );
};
