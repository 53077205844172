import * as Yup from 'yup';
import { isValid } from 'date-fns';
import { isNil } from 'lodash';

export const JobSchema = Yup.object().shape({
  jobName: Yup.string()
    .max(200, 'Plan Name too Long')
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .required('Required'),
});

export const JobIdentitySchema = Yup.object().shape({
  jobName: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  jobTypeID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Please select a job type')
    .required('Required'),
  invoiceMethodID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Please select an invoice method')
    .required('Required'),
  workRecordLevelID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Please select a work record level')
    .required('Required'),
  siteTypeID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Please select a site type')
    .required('Required'),
});

export const JobSupervisorSchema = Yup.object().shape({
  jobID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Must have a job')
    .required('Required'),
  supervisorID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Please select a supervisor')
    .required('Required'),
  effectiveFromDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
});

export const JobActivityRateSchema = Yup.object().shape({
  effectiveFrom: Yup.string()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required', 'Please choose an effective from date'),
  effectiveTo: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)) || isNil(v)),
  contractedRate: Yup.mixed().test(
    'is-num-or-empty',
    'Contracted rate must be a number',
    value => value === '' || !isNaN(value),
  ),
  payrollRate: Yup.mixed().test(
    'is-num-or-empty',
    'Payroll rate must be a number',
    value => value === '' || !isNaN(value),
  ),
});

export const JobVineyardStepperSchema = Yup.object().shape({
  poNumber: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable(),
  businessUnitID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Please select a business unit')
    .required('Required'),
});

export const JobActivitiesStepperSchema = Yup.object().shape({
  activityID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Please select a service code')
    .required('Required'),
  jobActivityDescription: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  contractedRate: Yup.mixed().test(
    'is-num-or-empty',
    'Contracted rate must be a number',
    value => value === '' || !isNaN(value),
  ),
  payrollRate: Yup.mixed().test(
    'is-num-or-empty',
    'Payroll rate must be a number',
    value => value === '' || !isNaN(value),
  ),
  effectiveFrom: Yup.string()
    .nullable()
    .test('date', 'Please choose an effective from date', v =>
      isValid(new Date(v)),
    )
    .required('Required'),
});

export const JobBlockRowSchema = Yup.object().shape({
  selectedUnits: Yup.number('Must be a number').required('Required'),
});
