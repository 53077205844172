import React from 'react';
import PropTypes from 'prop-types';
import { ManageReferences } from '../Manage';
import logger from '../../../utils/winstonLogger';
import CreateNewEntities from './CreateNewEntities';

const CreateNewReference = props => {
  logger.debug('Create New Reference');
  const { identityId } = props;

  return (
    <CreateNewEntities
      label="Manage References"
      testId="manage-contact-details"
    >
      <ManageReferences identityId={identityId} />
    </CreateNewEntities>
  );
};

CreateNewReference.propTypes = {
  identityId: PropTypes.number,
};

CreateNewReference.defaultProps = {
  identityId: null,
};

export default CreateNewReference;
