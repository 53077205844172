import React from 'react';
import { useTranslation } from 'react-i18next';

import { VineaAlert } from '../VineaAlert';

type DeleteIdentityAlertProps = {
  isOpen: boolean;
  onExit: any;
  identityName: string;
};

export const DeleteIdentityAlert = ({
  isOpen,
  onExit,
  identityName = 'identity',
}: DeleteIdentityAlertProps) => {
  const { t } = useTranslation();

  return (
    <VineaAlert
      isOpen={isOpen}
      onExit={onExit}
      alertType="warning"
      message={t(
        `This ${identityName} cannot be deleted, as it has related data that must be deleted first. For example notes, crop measure records, sample records, vineyard blocks, etc.`,
      )}
    />
  );
};
