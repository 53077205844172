import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { includes, isEmpty } from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';

import { VineaButton } from '../../components/VineaButton';
import { Datepicker } from '../../components/Datepicker';
import { viewDateFormat } from '../../constants';
import { VineaTextField } from '../../components/TextField';
import { DiscardDialog } from '../../components/Dialog';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

const StyledDrawerControlDiv = styled('div')(() => ({
  width: 350,
}));

export const AssignmentDrawer = ({
  open,
  onClose,
  handleOnSave,
  handleOnChange,
  samplingUsers,
  formdata,
  assignmentOperation,
}) => {
  const theme = useTheme();
  const [state, setState] = React.useState(open);
  const { t } = useTranslation();

  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(null);

  React.useEffect(() => {
    setState(open);
  }, [open]);
  console.log('....>', formdata);
  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      setState(prevState => !prevState);
      onClose();
    }
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnDiscard = () => {
    const { hasChanges } = formdata;
    if (hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    onClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  const list = () => (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleOnClickAway}>
      <StyledDrawerControlDiv>
        <Box
          flex={1}
          flexDirection="row"
          justifyContent="flex-end"
          display="flex"
        >
          <IconButton
            aria-label="toggle sidebar"
            onClick={handleOnDiscard}
            onMouseDown={() => {}}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <div
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          {assignmentOperation === 'Assign' && (
            <Typography
              sx={{
                padding: theme.spacing(2),
              }}
              variant="subtitle2"
            >
              {t('Assign Sample Requests')}
            </Typography>
          )}

          {assignmentOperation === 'DeAssign' && (
            <Typography
              sx={{
                padding: theme.spacing(2),
              }}
              variant="subtitle2"
            >
              {t('De-Assign Sample Requests')}
            </Typography>
          )}

          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />
            {assignmentOperation === 'Assign' && (
              <Typography
                sx={{
                  padding: theme.spacing(2),
                }}
                variant="body1"
              >
                {t('Available Samplers')}
              </Typography>
            )}

            {assignmentOperation === 'DeAssign' && (
              <Typography
                sx={{
                  padding: theme.spacing(2),
                }}
                variant="body1"
              >
                {t('Assigned Samplers')}
              </Typography>
            )}

            <Box
              sx={{
                border: '1px solid black',
                maxHeight: 250,
                overflow: 'overlay',
              }}
              m={1}
            >
              {isEmpty(samplingUsers) ? (
                <Typography
                  sx={{
                    padding: 40,
                  }}
                >
                  {t('No Assigned Person Found')}
                </Typography>
              ) : (
                <FormControl
                  component="fieldset"
                  sx={{
                    padding: theme.spacing(2),
                    flex: 1,
                    display: 'flex',
                    maxHeight: 250,
                  }}
                >
                  <FormGroup>
                    {samplingUsers.map(option => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            onChange={handleOnChange}
                            name={option.id.toString()}
                            key={option.key}
                            checked={includes(
                              formdata.assignedToPersonIDs,
                              option.key.toString(),
                            )}
                          />
                        }
                        label={option.value}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              )}
            </Box>
            {assignmentOperation === 'Assign' && (
              <Box mt={2} ml={1}>
                <Datepicker
                  sx={{
                    marginTop: '16px',
                  }}
                  label={t('Due Date')}
                  disablePast={false}
                  id="effectivefrom"
                  inputFormat={viewDateFormat}
                  placeholder="DD/MM/YYYY"
                  variant="outlined"
                  size="small"
                  value={formdata.dueDate}
                  onChange={handleOnChange}
                  name="effectiveFromDate"
                  inputProps={{
                    'data-testid': 'effectiveFromDate',
                    'data-name': 'effectiveFromDate',
                  }}
                />
                {/* <VineaTextField
                  sx={{
                    marginTop: '16px',
                  }}
                  label={t('Sampling Instructions')}
                  multiline
                  name="sampleInstructions"
                  rows={5}
                  maxRows={10}
                  onChange={handleOnChange}
                  value={formdata.sampleInstructions}
                  fullWidth
                /> */}
              </Box>
            )}

            <StyledButtonControlDiv>
              <div>
                <VineaButton
                  color="success"
                  onClick={handleOnSave}
                  variant="contained"
                  minWidth={100}
                  disabled={isEmpty(formdata.assignedToPersonIDs)}
                >
                  {t('Save')}
                </VineaButton>
              </div>
            </StyledButtonControlDiv>
          </ErrorBoundary>
        </div>

        <DiscardDialog
          open={discardDialogOpen}
          onClose={() => setDiscardDialogOpen(false)}
          handleSaveChanges={handleOnDiscardSaveChanges}
          handleDiscardChanges={handleOnDiscardConfirm}
        />
      </StyledDrawerControlDiv>
    </ClickAwayListener>
  );

  return (
    <Drawer anchor="right" open={state} onClose={toggleDrawer}>
      {list('right')}
    </Drawer>
  );
};

AssignmentDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  samplingUsers: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
      key: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
      value: PropTypes.string,
    }),
  ).isRequired,
  formdata: PropTypes.objectOf(
    PropTypes.shape({
      assignedToPersonIDs: PropTypes.arrayOf(PropTypes.string),
      dueDate: PropTypes.string,
    }),
  ).isRequired,
  assignmentOperation: PropTypes.string.isRequired,
};
