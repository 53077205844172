import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorBoundary } from 'react-error-boundary';
import { VineaButton } from '../../../components/VineaButton';
import { DiscardDialog } from '../../../components/Dialog';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';
import {
  StyledButtonControlDiv,
  StyledCloseDiv,
  StyledFieldLabelInput,
  StyledFormControl,
} from '../../../components/FormComponent/FormComponent';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { get } from 'lodash';
import { SiteTypeIDs } from '../../../constants';

export interface BlockRowType {
  id: number;
  blockID?: number;
  rowNumber?: number;
  plantedUnits?: number;
  actualUnits?: number;
  postCount?: number;
  rowLength?: number;
  specialAttributeID?: number;
  rowComments?: string;
  bayCount?: number | null;
  bayAvgArea?: number | null;
}

export interface LookupOptionType {
  id: number;
  key: string;
  value: string;
}

export function ManageBlockRowDrawer({
  isOpen,
  handleOnClose,
  handleOnSave,
  handleOnChange,
  handleOnDelete,
  blockRowHasChanges,
  blockRowData,
  lkpRowSpecialAttribute,
  validationErrors = {},
  siteTypeID,
}: {
  isOpen: boolean;
  handleOnClose: () => void;
  handleOnSave: () => void;
  handleOnDelete: () => void;
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  blockRowHasChanges: boolean;
  blockRowData?: BlockRowType;
  lkpRowSpecialAttribute: LookupOptionType[];
  validationErrors: any;
  siteTypeID: Number;
}) {
  const { t } = useTranslation();
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const drawerHeader = 'Edit Block Row';
  const hasJobBlockRow = get(blockRowData, 'hasJobBlockRow', false);

  useEffect(() => {
    validationErrors = {};
  }, [isOpen]);

  const toggleDrawer = (event: React.KeyboardEvent) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const onSave = () => {
    handleOnSave();
  };

  const onDelete = () => {
    handleOnDelete();
  };

  const handleOnDiscard = () => {
    handleOnClose();
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={handleOnClickAway}
      >
        <Box sx={{ width: 380 }} role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography sx={{ padding: 2 }} variant="body1">
                {t(drawerHeader)}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOnDiscard}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />
            <Box my={2}>
              <StyledFormControl data-testid="blockrowdrawerrownumber">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="rowNumber"
                  name="rowNumber"
                  label={t('Row Number')}
                  inlineLabel
                  value={blockRowData?.rowNumber}
                  onChange={handleOnChange}
                  error={validationErrors.rowNumber}
                  fullWidth
                  disabled={false}
                  onBlur={undefined}
                  classes={undefined}
                  rows={undefined}
                  sx={undefined}
                />
              </StyledFormControl>
              <StyledFormControl data-testid="blockrowdrawerplantedUnits">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="plantedUnits"
                  name="plantedUnits"
                  label={t('Planted')}
                  inlineLabel
                  value={blockRowData?.plantedUnits}
                  onChange={handleOnChange}
                  error={validationErrors.plantedUnits}
                  fullWidth
                  disabled={false}
                  onBlur={undefined}
                  classes={undefined}
                  rows={undefined}
                  sx={undefined}
                />
              </StyledFormControl>
              <StyledFormControl data-testid="blockrowdraweractualunits">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="actualUnits"
                  name="actualUnits"
                  label={t('Current / Producing')}
                  inlineLabel
                  value={blockRowData?.actualUnits}
                  onChange={handleOnChange}
                  error={validationErrors.actualUnits}
                  fullWidth
                  disabled={false}
                  onBlur={undefined}
                  classes={undefined}
                  rows={undefined}
                  sx={undefined}
                />
              </StyledFormControl>
              <StyledFormControl data-testid="blockrowdrawerpostCount">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="postCount"
                  name="postCount"
                  label={t('Posts')}
                  inlineLabel
                  value={blockRowData?.postCount}
                  onChange={handleOnChange}
                  error={validationErrors.postCount}
                  fullWidth
                  disabled={false}
                  onBlur={undefined}
                  classes={undefined}
                  rows={undefined}
                  sx={undefined}
                />
                {validationErrors?.postCount && (
                  <FormHelperText id="component-error-text">
                    {validationErrors?.postCount}
                  </FormHelperText>
                )}
              </StyledFormControl>
              <StyledFormControl data-testid="blockrowdrawerrowlength">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="rowLength"
                  name="rowLength"
                  label={t('Row Length')}
                  inlineLabel
                  value={blockRowData?.rowLength}
                  onChange={handleOnChange}
                  error={validationErrors.rowLength}
                  fullWidth
                  disabled={false}
                  onBlur={undefined}
                  classes={undefined}
                  rows={1}
                  sx={undefined}
                />
              </StyledFormControl>
              {siteTypeID === SiteTypeIDs.ORCHARD_KIWIFRUIT && (
                <>
                  <StyledFormControl data-testid="blockrowdrawerbayCount">
                    <StyledFieldLabelInput
                      autoComplete="off"
                      id="bayCount"
                      name="bayCount"
                      label={t('Bay Count')}
                      inlineLabel
                      value={blockRowData?.bayCount}
                      onChange={handleOnChange}
                      error={validationErrors.bayCount}
                      fullWidth
                      disabled={false}
                      onBlur={undefined}
                      classes={undefined}
                      rows={1}
                      sx={undefined}
                    />
                  </StyledFormControl>
                  <StyledFormControl data-testid="blockrowdrawerbayAvgArea">
                    <StyledFieldLabelInput
                      autoComplete="off"
                      id="bayAvgArea"
                      name="bayAvgArea"
                      label={t('Bay Avg Area')}
                      inlineLabel
                      value={blockRowData?.bayAvgArea}
                      onChange={handleOnChange}
                      error={validationErrors.bayAvgArea}
                      fullWidth
                      disabled={false}
                      onBlur={undefined}
                      classes={undefined}
                      rows={1}
                      sx={undefined}
                    />
                  </StyledFormControl>
                </>
              )}
              <StyledFormControl
                sx={{
                  minWidth: 250,
                }}
                error={validationErrors.specialAttributeID}
                margin="none"
              >
                <VineaAutoComplete
                  value={blockRowData?.specialAttributeID}
                  onChange={handleOnChange}
                  inlineLabel={false}
                  options={lkpRowSpecialAttribute || []}
                  labelVariant="body1"
                  label={t('Special Attribute')}
                  name="specialAttributeID"
                  id="specialAttributeID"
                  inputProps={{
                    name: 'specialAttributeID',
                  }}
                  fullWidth={false}
                />
                {validationErrors?.specialAttributeID && (
                  <FormHelperText id="component-error-text">
                    {validationErrors.specialAttributeID}
                  </FormHelperText>
                )}
              </StyledFormControl>
              <StyledFormControl data-testid="blockrowdrawerrowcomments">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="rowComments"
                  name="rowComments"
                  label={t('Comments')}
                  inlineLabel
                  value={blockRowData?.rowComments}
                  onChange={handleOnChange}
                  error={validationErrors.rowComments}
                  fullWidth
                  disabled={false}
                  onBlur={undefined}
                  classes={undefined}
                  multiline
                  rows={4}
                  sx={undefined}
                />
              </StyledFormControl>
            </Box>
            <StyledButtonControlDiv>
              <div>
                <VineaButton
                  color="success"
                  onClick={onSave}
                  variant="contained"
                  minWidth={150}
                  disabled={!blockRowHasChanges}
                >
                  {t('Save')}
                </VineaButton>
              </div>
              <StyledCloseDiv>
                <VineaButton
                  color="error"
                  onClick={onDelete}
                  variant="contained"
                  minWidth={150}
                  disabled={!hasJobBlockRow}
                >
                  {t('Delete')}
                </VineaButton>
              </StyledCloseDiv>
              <StyledCloseDiv>
                <VineaButton
                  color="secondary"
                  onClick={handleOnDiscard}
                  variant="outlined"
                  minWidth={150}
                >
                  {t('Close')}
                </VineaButton>
              </StyledCloseDiv>
            </StyledButtonControlDiv>
          </ErrorBoundary>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={() => setDiscardDialogOpen(false)}
            handleSaveChanges={handleOnDiscardSaveChanges}
            handleDiscardChanges={handleOnDiscardConfirm}
          />
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
}
