import React from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Paper, IconButton, Stack } from '@mui/material';
import { isEmpty, isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/lab/Alert';
import { isValid, format, parse } from 'date-fns';
import { VineaButton } from '../../../components/VineaButton';
import {
  areLookupsLoading,
  getUserPreferences,
} from '../../../redux/selectors';
import { PhenologyGridView } from './PhenologyGridView';
import { useTrackingHook } from '../Hooks/useTrackingHook';
import { PhenologyMeasureDrawer } from '../Drawers';
import { dateFormat, viewDateFormat } from '../../../constants';

export const PhenologyDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const {
    cropBlockPhenologyEntityData,
    vintageBlockSelected,
    errorInSubmitPhenology,
    setErrorInSubmitPhenology,
    handleOnUpdatePhenologyData,
    lkpPhenologyMeasure,
    searchFilterData,
    lkpPhenologyMeasureOptions,
    lkpPhenologyMeasureSet,
    handleOnDeleteCropBlockPhenology,
    isLoadingData,
    phenologyNoteImage,
    setPhenologyNoteImage,
  } = useTrackingHook();

  const handleOnCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const isLoadingLookups = useSelector(areLookupsLoading);
  const { trackingDataGrid, basicSettings } = useSelector(state =>
    getUserPreferences(state),
  );

  const { vineyard: vineyardSelected, block: blockSelected } =
    vintageBlockSelected;

  const subTitle1 = `${t('Phenology measure instances for')} ${
    vineyardSelected || ''
  } ${blockSelected ? '/' : ''} ${blockSelected || ''}`;

  const handleOnRowClick = cellProps => {
    const { row } = cellProps;

    const measureDate = isValid(
      parse(row.measureDate, viewDateFormat, new Date()),
    )
      ? format(parse(row.measureData, viewDateFormat, new Date()), dateFormat)
      : row.measureDate;
    setSelectedRow({ ...row, isEdit: true, measureDate });
    handleOnDrawerOpen();
  };

  // open drawer and reset upload image references
  const handleOnDrawerOpen = () => {
    setDrawerOpen(true);
    setPhenologyNoteImage(null);
  };

  const handleOnSavePhenologyMeasure = data => {
    handleOnUpdatePhenologyData(data);
    setDrawerOpen(false);
  };

  const handleOnDeleteRow = () => {
    // delete row
    handleOnDeleteCropBlockPhenology(selectedRow);
    setDrawerOpen(false);
  };

  const handleOnDeleteNoteImage = imageDeleteId => {
    setSelectedRow({
      ...selectedRow,
      noteImage: null,
      imageDeleteId,
      hasChanges: true,
    });
  };

  const handleOnAddRow = () => {
    const { vineyardBlockId, vintageID, vineyardID } = searchFilterData;
    const phenologyMeasure = {
      blockVintageId: 0,
      isAdd: true,
      isEditable: true,
      isPrivate: false,
      measure: null,
      measureDate: null,
      measureValue: null,
      note: null,
      noteImage: null,
      phenologyMeasureDate: format(new Date(), dateFormat),
      phenologyMeasureID: 0,
      phenologyMeasureOptionID: 0,
      phenologyMeasureSet: null,
      phenologyMeasureValue1: null,
      phenologyMeasureValue2: null,
      phenologyMeasureValue3: null,
      vineyardBlockId,
      vintageId: vintageID,
      vineyardID,
    };
    setSelectedRow(phenologyMeasure);
    handleOnDrawerOpen();
  };

  const handleOnImageUpload = data => setPhenologyNoteImage(data);

  const handleOnAdvancedSearch = () => {
    dispatchAPI({
      type: 'BASIC_SETTINGS_UPDATE',
      payload: {
        ...basicSettings,
        filterPanelCollapsed: !basicSettings.filterPanelCollapsed,
      },
    });
  };

  return (
    <Stack data-testid="phenology-details" style={{ height: '100%' }}>
      <Box
        mx={1}
        my={2}
        display="flex"
        justifyContent="space-between"
        data-testid="action-buttons"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          {(vineyardSelected || blockSelected) && (
            <>
              <InfoIcon />
              <Typography
                variant="body2"
                sx={{
                  padding: 2,
                }}
              >
                {subTitle1}
              </Typography>
            </>
          )}
        </Box>
        <Box>
          <VineaButton
            color="secondary"
            variant="contained"
            sx={{
              marginRight: theme.spacing(1),
            }}
            minWidth={100}
            onClick={handleOnAddRow}
            disabled={!isEmpty(selectionModel) || isEmpty(blockSelected)}
          >
            {t('Add Measure')}
          </VineaButton>
        </Box>
      </Box>
      <Box display="flex" flex={1} mx={1} height="100%">
        <Grid
          container
          spacing={0}
          data-testid="organisation-results"
          direction="column"
        >
          <Box>
            {!isNull(errorInSubmitPhenology) && (
              <Collapse in={!!errorInSubmitPhenology}>
                <Alert
                  variant="outlined"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setErrorInSubmitPhenology(null);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {errorInSubmitPhenology}
                </Alert>
              </Collapse>
            )}
          </Box>

          <Grid
            item
            xs={12}
            sx={{
              flex: 1,
            }}
          >
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                height: '100%',
              }}
            >
              <Grid container xs={12} direction="column">
                <PhenologyGridView
                  data={cropBlockPhenologyEntityData}
                  isLoading={isLoadingLookups || isLoadingData}
                  handleOnRowClick={handleOnRowClick}
                  handleOnCellClick={f => f}
                  onSelectionModelChange={newSelection => {
                    setSelectionModel(newSelection.selectionModel);
                  }}
                  selectionModel={selectionModel}
                  actionType="TRACKING_GRID_COLUMN_UPDATE"
                  gridColumnState={trackingDataGrid.phenology}
                  handleOnAdvancedSearch={handleOnAdvancedSearch}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <PhenologyMeasureDrawer
        open={drawerOpen}
        onClose={handleOnCloseDrawer}
        data={selectedRow}
        handleOnSave={handleOnSavePhenologyMeasure}
        vineyardSelected={vineyardSelected}
        blockSelected={blockSelected}
        lkpPhenologyMeasure={lkpPhenologyMeasure}
        lkpPhenologyMeasureOptions={lkpPhenologyMeasureOptions}
        lkpPhenologyMeasureSet={lkpPhenologyMeasureSet}
        onDelete={handleOnDeleteRow}
        handleOnImageUpload={handleOnImageUpload}
        editedNoteImage={phenologyNoteImage}
        onDeleteNoteImage={handleOnDeleteNoteImage}
      />
    </Stack>
  );
};
