import React from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { VineaTextField } from '../../components/TextField';

export const TextFields = () => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      sm={8}
      sx={{
        flexGrow: 1,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
      }}
    >
      <Typography component="p" variant="h3">
        Field Inputs
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            '& > input': {
              margin: theme.spacing(1),
            },
          }}
        >
          <TextField id="outlined-basic" label="Outlined" variant="outlined" />
          <Box mx={2} mt={2} />
          <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            size="small"
          />
          <Box mx={2} mt={2} />
          <TextField
            error
            id="outlined-error"
            label="Error"
            defaultValue="Hello World"
            variant="outlined"
          />
          <Box mx={2} mt={2} />
          <TextField
            error
            id="outlined-error-helper-text"
            label="Error"
            defaultValue="Hello World"
            helperText="Incorrect entry."
            variant="outlined"
          />
          <Box mx={2} mt={2} />
          <VineaTextField id="VineaText" label="Email" />
          <Box mt={2}>
            <InputLabel htmlFor="firstname">First Name</InputLabel>
            <TextField
              id="firstname"
              size="small"
              label=""
              value="welcome"
              onChange={() => {}}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                'data-testid': 'firstname',
                'data-name': 'firstname',
              }}
              variant="outlined"
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
