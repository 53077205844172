import React, { useState } from 'react';
import { round } from 'lodash';
import PhotoIcon from '@mui/icons-material/Photo';
import PhotoDialog from './SamplesImageViewer';
import { Box, IconButton, Typography } from '@mui/material';
import { RenderDataGridHeader } from '../../components/Grid';
import { GreenCheckbox } from '../../components/CheckBox/Checkbox.tsx';

const RenderExcludeCheckbox = params => {
  const { row } = params;
  return <GreenCheckbox checked={row.exclude} />;
};

const RenderPhotoCell = params => {
  const [show, setShow] = useState(false);
  const photo = params.row.photo;
  console.log('params row', params.row);

  if (photo) {
    return (
      <Box display="flex" alignItems="center">
        <IconButton
          onClick={() => {
            setShow(true);
          }}
        >
          <PhotoIcon />
        </IconButton>
        {/* <Typography variant="body2">{photo}</Typography> */}
        <PhotoDialog
          id={params.row.id}
          show={show}
          onClose={() => {
            setShow(false);
          }}
        />
      </Box>
    );
  } else {
    return null;
  }
};

function getAvgFloretCount1(params) {
  const value = Number(params.row.var_B) / Number(params.row.var_C);
  return value;
}

function getAvgFloretCount2(params) {
  const value = Number(params.row.var_E) / Number(params.row.var_D);
  return value;
}

function getAvgBunchWeight1(params) {
  const value = Number(params.row.var_B) / Number(params.row.var_C);
  return value;
}

function getBerriesPerBunch1(params) {
  const value = Number(params.row.var_C) / Number(params.row.var_A);
  return value;
}

function getBerriesPerBunch2(params) {
  const value = Number(params.row.var_B) / Number(params.row.var_C);
  return value;
}

function getBerriesPerBunch3(params) {
  const value = Number(params.row.var_D) * Number(params.row.var_C);
  return value;
}

function getBerriesPerBunch4(params) {
  const value = Number(params.row.var_D) * Number(params.row.var_C);
  const newValue = Number(params.row.var_E) / value;
  return newValue;
}

function getBerriesPerBunch5(params) {
  const value = Number(params.row.var_G) / Number(params.row.var_F);
  return value;
}

function getBerriesPerBunchV21(params) {
  const value = round(Number(params.row.var_B) / Number(params.row.var_C), 2);
  return value;
}

function getBerriesPerBunchV22(params) {
  const value = round(Number(params.row.var_E) / Number(params.row.var_D), 2);
  return value;
}

function getBerriesPerBunchV23(params) {
  const value = Number(params.row.var_G) / Number(params.row.var_F);
  return value;
}

function getBerriesPerBunchV24(params) {
  const c4 = Number(params.row.var_B) / Number(params.row.var_C);
  const c3 = Number(params.row.var_E) / Number(params.row.var_D);
  const c2 = Number(params.row.var_G) / Number(params.row.var_F);
  const newValue = round((c4 - c2) / c3, 2);
  return newValue;
}

function getBerriesPerBunch6(params) {
  const c1 = Number(params.row.var_B) / Number(params.row.var_C);
  const c2 = Number(params.row.var_G) / Number(params.row.var_F);
  const c3 = Number(params.row.var_D) * Number(params.row.var_C);
  const c4 = Number(params.row.var_E) / c3;
  const newValue = (c1 - c2) / c4;
  return newValue;
}

function getMaturityBerryWeight1(params) {
  const value = round(Number(params.row.var_C) * Number(params.row.var_D), 2);
  return value;
}

function getMaturityBerryWeight2(params) {
  const value = Number(params.row.var_C) * Number(params.row.var_D);
  const newValue = round(Number(params.row.var_E) / value, 2);
  return newValue;
}

function getSignOff2VMEBerriesPerBunchCount1(params) {
  const value = Number(params.row.var_D) * Number(params.row.var_C);
  return value;
}

function getSignOff2VMEBerriesPerBunchCount2(params) {
  const value = Number(params.row.var_G) / Number(params.row.var_F);
  return value;
}

function getSignOff2VMEBerriesPerBunchCount3(params) {
  const value = Number(params.row.var_C) / Number(params.row.var_A);
  return value;
}

function getSignOff2VMEBerriesPerBunchCount4(params) {
  const value = Number(params.row.var_B) / Number(params.row.var_C);
  return value;
}

function getSignOff2VMEBerriesPerBunchCount5(params) {
  const value = Number(params.row.var_D) * Number(params.row.var_C);
  const newValue = Number(params.row.var_E) / value;
  return newValue;
}

function getCombinedBunchAndBerryWeight(params) {
  const value = Number(params.row.var_B) / Number(params.row.var_C);
  const newValue = round(value, 2);
  return newValue;
}

const defaultColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Value',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const AverageFloretCountColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Floret Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'photo',
    headerName: 'HasPhoto',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderPhotoCell,
  },
];

const AverageBerryWeightColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_A',
    headerName: 'Actual Sampled Shoot Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_B',
    headerName: 'Total Bunch Weight (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_C',
    headerName: 'Number of Bunches in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_D',
    headerName: 'Number of Berries in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_E',
    headerName: 'Weight of Sampled Berries',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_b/var_c',
    headerName: 'var_b/var_c',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getAvgFloretCount1,
  },
  {
    field: 'var_e/var_d',
    headerName: 'var_e/var_d',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getAvgFloretCount2,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const AverageBunchWeightColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_B',
    headerName: 'Total Bunch Weight (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_C',
    headerName: 'Number of Bunches in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_B/var_C',
    headerName: 'var_B/var_C',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getAvgBunchWeight1,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const BrixSamplingColumns = [
  {
    field: 'var_A',
    headerName: 'Brix',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Collect for Lab',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    editable: false,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
];

const CollectForLabColumns = [
  {
    field: 'exclude',
    headerName: 'Collect for Lab',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    headerAlign: 'center',
    align: 'center',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
    flex: 1,
    hideable: false,
    editable: false,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    editable: false,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
];

const CollectForLabBWColumns = [
  {
    field: 'exclude',
    headerName: 'Collect for Lab',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    headerAlign: 'center',
    align: 'center',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
    flex: 1,
    hideable: false,
    editable: false,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    editable: false,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
];

const BrixAnalysisColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const HarvestBerryWeightColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const HarvestBunchWeightColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Value',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const HarvestedRowMetresColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const BudsPerVineColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Bud Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const ShootsPerVineColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Shoot Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const InfloroescenceCountColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Inflorescence Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const FloretCountColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Floret Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const BunchesPerVineColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Bunch Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const BerriesPerBunchV2Columns = [
  {
    field: 'var_C',
    headerName: 'Total Number of Bunches',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_B',
    headerName: 'Total Weight of Bunches (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'averageBunchWeight',
    headerName: 'Avg Bunch Weight (gms)',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getBerriesPerBunchV21,
  },
  {
    field: 'var_D',
    headerName: 'No of Bunches in Sub Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_E',
    headerName: 'Total Berry Number',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_F',
    headerName: 'Total Berry Weight',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'averageBerryWeight',
    headerName: 'Avg Berry Weight (gms)',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getBerriesPerBunchV22,
  },
  {
    field: 'var_G',
    headerName: 'Total Weight of Rachis and Debris (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'averageRachisWeight',
    headerName: 'Average Rachis /Bunch (gms)',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getBerriesPerBunchV23,
  },
  {
    field: 'averageNumberOfBerries',
    headerName: 'Avg Number of Berries/Bunch',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getBerriesPerBunchV24,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const BerriesPerBunchColumns = [
  {
    field: 'var_A',
    headerName: 'Actual Sampled Shoot Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_B',
    headerName: 'Total Weight of Bunches (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_C',
    headerName: 'Number of Bunches in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'averageBunchesPerShoot',
    headerName: 'Avg Bunches / Shoot',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getBerriesPerBunch1,
  },
  {
    field: 'averageBunchWeight',
    headerName: 'Avg Bunch Weight (incl rachins)',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getBerriesPerBunch2,
  },
  {
    field: 'var_D',
    headerName: 'Sample Berries Per Bunch',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'totalSampledBerries',
    headerName: 'Total Berries',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getBerriesPerBunch3,
  },
  {
    field: 'var_E',
    headerName: 'Weight of Berries (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'averageBerryWeight',
    headerName: 'Avg Berry Weight (gms)',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getBerriesPerBunch4,
  },
  {
    field: 'var_F',
    headerName: 'Bunches Sampled for Rachis',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_G',
    headerName: 'Total Weight of Rachis',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'averageRachisWeight',
    headerName: 'Average Rachis /Bunch (gms)',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getBerriesPerBunch5,
  },
  {
    field: 'averageNumberOfBerries',
    headerName: 'Avg Number of Berries/Bunch',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getBerriesPerBunch6,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const MaturityBerryWeightColumns = [
  {
    field: 'var_C',
    headerName: 'Number of Bunches in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_D',
    headerName: 'Sample Berries Per Bunch',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'totalBerriesPerBunch',
    headerName: 'Total Berries',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getMaturityBerryWeight1,
  },
  {
    field: 'var_E',
    headerName: 'Weight of Sampled Berries(gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'averageBerryWeight',
    headerName: 'Avg Berry Weight (gms)',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getMaturityBerryWeight2,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const InitialAverageBudNumbersPerVineColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Bud Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const SampleAvgInflorescencesPerVineColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Inflorescence Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const SignOff1VMEBunchCountColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Bunch Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const SignOff1GrowerBunchCountColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Inflorescence Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const GrowerSampleBunchCountColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Inflorescence Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const VMESampleBunchCountColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemCane',
    headerName: 'Cane Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemSpur',
    headerName: 'Spur Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Inflorescence Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const InitialAverageShootNumbersPerVineColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Shoot Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const PostHarvestAverageActiveShootCountPerVineColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Shoot Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const SignOff2Columns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_A',
    headerName: 'Actual Sampled Shoot Count',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_B',
    headerName: 'Total Bunch Weight (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_C',
    headerName: 'Number of Bunches in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_D',
    headerName: 'Sample Berries Per Bunch',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_E',
    headerName: 'Weight of Sampled Berries',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_F',
    headerName: 'Bunches Sampled for Rachis',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_G',
    headerName: 'Weight of Sampled Rachis',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_d*var_c',
    headerName: 'C1',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getSignOff2VMEBerriesPerBunchCount1,
  },
  {
    field: 'var_g/var_f',
    headerName: 'C2',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getSignOff2VMEBerriesPerBunchCount2,
  },
  {
    field: 'var_c/var_a',
    headerName: 'C3',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getSignOff2VMEBerriesPerBunchCount3,
  },
  {
    field: 'var_b/var_c',
    headerName: 'C4',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getSignOff2VMEBerriesPerBunchCount4,
  },
  {
    field: 'var_e/c1',
    headerName: 'C5',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getSignOff2VMEBerriesPerBunchCount5,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const SampleAvgMaturityBerryWeightColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_C',
    headerName: 'Number of Bunches in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_D',
    headerName: 'Sample Berries Per Bunch',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_E',
    headerName: 'Weight of Sampled Berries',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const SampleAvgInternalHarvestBerryWeightColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_C',
    headerName: 'Number of Bunches in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_D',
    headerName: 'Sample Berries Per Bunch',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_E',
    headerName: 'Weight of Sampled Berries',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_d*var_c',
    headerName: 'C1',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getSignOff2VMEBerriesPerBunchCount1,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const SampleAvgMaturityBunchWeightColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_B',
    headerName: 'Total Bunch Weight (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_C',
    headerName: 'Number of Bunches in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const SampleAvgInternalHarvestBunchWeightColumns = [
  {
    field: 'itemNumber',
    headerName: 'Obs No',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_C',
    headerName: 'Number of Bunches in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_D',
    headerName: 'Sample Berries Per Bunch',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_E',
    headerName: 'Weight of Sampled Berries',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_d*var_c',
    headerName: 'C1',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getSignOff2VMEBerriesPerBunchCount1,
  },
  {
    field: 'exclude',
    headerName: 'Exclude',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'boolean',
    renderHeader: RenderDataGridHeader,
    renderCell: RenderExcludeCheckbox,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
];

const PestAndDiseaseAssessmentColumns = [
  {
    field: 'itemValue',
    headerName: 'Row Number',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_A',
    headerName: 'Powdery Mildew',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_B',
    headerName: 'Botrytis',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_C',
    headerName: 'Sour Rot',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_D',
    headerName: 'Mealy Bug',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_F',
    headerName: 'Leaf Roller',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_G',
    headerName: 'Other',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
];

const MaturityBunchWeightColumns = [
  {
    field: 'var_C',
    headerName: 'Number of Bunches',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_B',
    headerName: 'Total Weight of Sample Bunches (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Average Bunch Weight (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
];

const CombinedBunchAndBerryWeightColumns = [
  {
    field: 'var_C',
    headerName: 'Number of Bunches in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_B',
    headerName: 'Total Weight of Sampled Bunches (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'AvgBunchWeight',
    headerName: 'Avg Bunch Weight incl Rachis (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
    valueGetter: getCombinedBunchAndBerryWeight,
  },
  {
    field: 'var_D',
    headerName: 'Number of Berries in Sample',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_E',
    headerName: 'Total Weight of Sampled Berries',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'itemValue',
    headerName: 'Avg Berry Weight (gms)',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    editable: false,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
];

const BrixTAPHColumns = [
  {
    field: 'var_A',
    headerName: 'Brix',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_C',
    headerName: 'pH',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'var_B',
    headerName: 'TA',
    flex: 1,
    hideable: false,
    editable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'assignedToName',
    headerName: 'Assigned To',
    flex: 1,
    hideable: false,
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lineNote',
    headerName: 'Notes',
    flex: 2,
    hideable: false,
    editable: true,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    renderHeader: RenderDataGridHeader,
  },
];

export const SampleDetailsColumns = measure => {
  if (measure === 'Average Floret Count') {
    return AverageFloretCountColumns;
  }
  if (measure === 'Average Floret Recount') {
    return AverageFloretCountColumns;
  }
  if (measure === 'Average Berry Weight') {
    return AverageBerryWeightColumns;
  }
  if (measure === 'Average Bunch Weight') {
    return AverageBunchWeightColumns;
  }
  if (measure === 'Brix Sampling') {
    return BrixSamplingColumns;
  }
  if (measure === 'Collect for Lab') {
    return CollectForLabColumns;
  }
  if (measure === 'Collect for Lab (+BW)') {
    return CollectForLabBWColumns;
  }
  if (measure === 'Brix/TA/pH Analysis') {
    return BrixAnalysisColumns;
  }
  if (measure === 'Harvest Berry Weight') {
    return HarvestBerryWeightColumns;
  }
  if (measure === 'Harvest Bunch Weight') {
    return HarvestBunchWeightColumns;
  }
  if (measure === 'Harvested Row Metres') {
    return HarvestedRowMetresColumns;
  }
  if (measure === 'Buds Per Vine') {
    return BudsPerVineColumns;
  }
  if (measure === 'Shoots Per Vine') {
    return ShootsPerVineColumns;
  }
  if (measure === 'Infloroescence Count') {
    return InfloroescenceCountColumns;
  }
  if (measure === 'Floret Count') {
    return FloretCountColumns;
  }
  if (measure === 'Bunches Per Vine') {
    return BunchesPerVineColumns;
  }
  if (measure === 'Berries Per Bunch (V1)') {
    return BerriesPerBunchColumns;
  }
  if (measure === 'BerriesPerBunchV2') {
    return BerriesPerBunchV2Columns;
  }
  if (measure === 'Berries Per Bunch') {
    return BerriesPerBunchColumns;
  }
  if (measure === 'Maturity Berry Weight') {
    return MaturityBerryWeightColumns;
  }
  if (measure === 'Maturity Bunch Weight') {
    return MaturityBunchWeightColumns;
  }
  if (measure === 'Combined Bunch And Berry Weight') {
    return CombinedBunchAndBerryWeightColumns;
  }
  if (measure === 'Initial Average Bud Numbers per vine') {
    return InitialAverageBudNumbersPerVineColumns;
  }
  if (measure === 'Sample Avg Inflorescences Per Vine') {
    return SampleAvgInflorescencesPerVineColumns;
  }
  if (measure === 'Sign Off 1 VME Bunch Count') {
    return SignOff1VMEBunchCountColumns;
  }
  if (measure === 'Sign Off 1 Grower Bunch Count') {
    return SignOff1GrowerBunchCountColumns;
  }
  if (measure === 'Grower Sample Bunch Count') {
    return GrowerSampleBunchCountColumns;
  }
  if (measure === 'VME Sample Bunch Count') {
    return VMESampleBunchCountColumns;
  }
  if (measure === 'Initial Average Shoot Numbers per vine') {
    return InitialAverageShootNumbersPerVineColumns;
  }
  if (measure === 'Post Harvest Average ACTIVE Shoot Count per vine') {
    return PostHarvestAverageActiveShootCountPerVineColumns;
  }
  if (measure === 'Sign Off 2 VME Berries per Bunch Counts') {
    return SignOff2Columns;
  }
  if (measure === 'Sign Off 2 Grower Berries per Bunch Counts') {
    return SignOff2Columns;
  }
  if (measure === 'Sample Avg Maturity Berry Weight') {
    return SampleAvgMaturityBerryWeightColumns;
  }
  if (measure === 'Sample Avg (Internal) Harvest Berry Weight') {
    return SampleAvgInternalHarvestBerryWeightColumns;
  }
  if (measure === 'Sample Avg Maturity Bunch Weight') {
    return SampleAvgMaturityBunchWeightColumns;
  }
  if (measure === 'Sample Avg (internal) Harvest Bunch Weight') {
    return SampleAvgInternalHarvestBunchWeightColumns;
  }
  if (measure === 'Pest and Disease Assessment') {
    return PestAndDiseaseAssessmentColumns;
  }
  if (measure === 'Brix/TA/pH Analysis (Lab)') {
    return BrixTAPHColumns;
  }
  return defaultColumns;
};
