/* eslint-disable no-unused-vars */
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/lab/Alert';
import { FormHelperText, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { VineaButton } from '../../../components/VineaButton';
import { VineaAlert } from '../../../components/VineaAlert';
import {
  DeleteIdentityAlert,
  DeleteIdentityButton,
} from '../../../components/IdentityComponents';
import { IdentityTypeIds, viewDateFormat } from '../../../constants';
import { SplitButton } from '../../../components/SplitButton';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { DiscardDialog } from '../../../components/Dialog';
import { Datepicker } from '../../../components/Datepicker';
import { formatDate } from '../../../constants/formatter';
import { useManageJobHook } from '../hooks/useManageJobHook';
import { FieldLabelInput } from '../../../components/TextField';
import { useTranslation } from 'react-i18next';

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: 300,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  minWidth: 300,
  '& .MuiFormControl-root': {
    minWidth: 300,
  },
  '& .MuiInputBase-input': {
    minWidth: 250,
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  root: {
    marginTop: theme.spacing(1),
    minWidth: 300,
  },
}));

const ManageJob = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    errorInSubmit,
    errorFromAPI,
    alertType,
    deleteHasFailed,
    warningInSubmit,
    updatedFormData,
    validationsErrors,
    lkpJobType,
    workRecordLevelDisabled,
    workRecordLevelOptions,
    quantityTypeDisabled,
    lkpJobStatus,
    lkpJobQuantityType,
    unitTypeDisabled,
    workRecordUnitTypeOptions,
    jobInvoiceMethodOptions,
    invoiceToData,
    hasChanges,
    discardDialogOpen,
    canDelete,
    setErrorInSubmit,
    setAlertType,
    setDeleteHasFailed,
    handleChange,
    handleOnConfirmDelete,
    handleSave,
    handleClose,
    handleOnDialogClose,
    handleCancel,
  } = useManageJobHook();

  return (
    <Paper
      elevation={0}
      data-testid="managejob-detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12}>
          <Box mb={4}>
            <VineaAlert
              isOpen={!!errorInSubmit}
              onExit={() => setErrorInSubmit(false)}
            >
              <p>{errorFromAPI}</p>
            </VineaAlert>
            <VineaAlert
              isOpen={alertType}
              onExit={() => setAlertType(false)}
              alertType="success"
              message="Job Updated"
            />
            <DeleteIdentityAlert
              isOpen={deleteHasFailed}
              onExit={() => setDeleteHasFailed(false)}
              identityName="job"
            />
          </Box>
          <Box px={2}>
            {warningInSubmit && (
              <Collapse in={warningInSubmit}>
                <Alert
                  variant="standard"
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarningInSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Warning: Nothing to Update
                </Alert>
              </Collapse>
            )}
          </Box>
          <Grid container spacing={2} data-testid="grid-details">
            <Stack direction="row" justifyContent="space-around">
              <Box px={2}>
                <StyledFormBoxControl mt={1}>
                  <FormControl>
                    <StyledFieldLabelInput
                      autoComplete="off"
                      id="jobName"
                      name="jobName"
                      label={t('Job Name')}
                      size="small"
                      inlineLabel
                      value={updatedFormData.jobName}
                      onChange={handleChange}
                      error={validationsErrors.jobName}
                    />
                  </FormControl>
                </StyledFormBoxControl>
                <StyledFormBoxControl mt={1}>
                  <FormControl>
                    <StyledFieldLabelInput
                      id="jobDescription"
                      name="jobDescription"
                      label={t('Job Description')}
                      inlineLabel
                      minWidth="250px"
                      value={updatedFormData.jobDescription}
                      onChange={handleChange}
                      error={validationsErrors.jobDescription}
                      multiline
                      rowsMax={4}
                      rows={4}
                    />
                  </FormControl>
                </StyledFormBoxControl>
                <StyledBox mt={3}>
                  <FormControl
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                    }}
                    error={!!validationsErrors.jobTypeID}
                    margin="none"
                  >
                    <VineaAutoComplete
                      value={updatedFormData.jobTypeID || 0}
                      onChange={handleChange}
                      inlineLabel={false}
                      options={lkpJobType}
                      labelVariant="body1"
                      label={t('Job Type')}
                      name={t('Job Type')}
                      id="jobTypeID"
                      displayLabel
                      inputProps={{
                        name: 'jobTypeID',
                      }}
                    />
                    {validationsErrors.jobTypeID && (
                      <FormHelperText id="component-error-text">
                        {t(validationsErrors.jobTypeID)}
                      </FormHelperText>
                    )}
                  </FormControl>
                </StyledBox>
                <StyledBox mt={2}>
                  <FormControl
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                    }}
                    error={!!validationsErrors.workRecordLevelID}
                    margin="none"
                  >
                    <VineaAutoComplete
                      value={updatedFormData.workRecordLevelID}
                      onChange={handleChange}
                      disabled={workRecordLevelDisabled}
                      inlineLabel={false}
                      options={workRecordLevelOptions}
                      labelVariant="body1"
                      label={t('Work Record Level')}
                      name={t('Work Record Level')}
                      id="workRecordLevelID"
                      displayLabel
                      inputProps={{
                        name: 'workRecordLevelID',
                      }}
                    />
                    {validationsErrors.workRecordLevelID && (
                      <FormHelperText id="component-error-text">
                        {t(validationsErrors.workRecordLevelID)}
                      </FormHelperText>
                    )}
                  </FormControl>
                </StyledBox>
                <StyledBox mt={2}>
                  <FormControl
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                    }}
                    error={!!validationsErrors.quantityTypeID}
                    margin="none"
                  >
                    <VineaAutoComplete
                      value={updatedFormData.quantityTypeID || 0}
                      disabled={quantityTypeDisabled}
                      onChange={e => {
                        handleChange(e);
                      }}
                      inlineLabel={false}
                      options={lkpJobQuantityType}
                      labelVariant="body1"
                      label={t('Quantity Type')}
                      name={t('Quantity Type')}
                      id="quantityTypeID"
                      displayLabel
                      inputProps={{
                        name: 'quantityTypeID',
                      }}
                    />
                    {validationsErrors.quantityTypeID && (
                      <FormHelperText id="component-error-text">
                        {t(validationsErrors.quantityTypeID)}
                      </FormHelperText>
                    )}
                  </FormControl>
                </StyledBox>

                <StyledBox mt={2}>
                  <FormControl
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                    }}
                    error={!!validationsErrors.workRecordUnitTypeID}
                    margin="none"
                  >
                    <VineaAutoComplete
                      value={updatedFormData.workRecordUnitTypeID || 0}
                      disabled={unitTypeDisabled}
                      onChange={e => {
                        handleChange(e);
                      }}
                      inlineLabel={false}
                      options={workRecordUnitTypeOptions}
                      labelVariant="body1"
                      label={t('Unit Type')}
                      name={t('Unit Type')}
                      id="workRecordUnitTypeID"
                      displayLabel
                      inputProps={{
                        name: 'workRecordUnitTypeID',
                      }}
                    />
                    {validationsErrors.workRecordUnitTypeID && (
                      <FormHelperText id="component-error-text">
                        {t(validationsErrors.workRecordUnitTypeID)}
                      </FormHelperText>
                    )}
                  </FormControl>
                </StyledBox>
                <StyledBox mt={3}>
                  <StyledFormControl
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                    }}
                    error={!!validationsErrors.invoiceMethodID}
                    data-testid="datePlanted-formcontrol"
                  >
                    <VineaAutoComplete
                      value={updatedFormData.invoiceMethodID}
                      onChange={handleChange}
                      inlineLabel={false}
                      options={jobInvoiceMethodOptions || []}
                      labelVariant="body1"
                      label={t('Invoice Method')}
                      name={t('Invoice Method')}
                      id="invoiceMethodID"
                      displayLabel
                      inputProps={{
                        name: 'invoiceMethodID',
                      }}
                    />
                    {validationsErrors.invoiceMethodID && (
                      <FormHelperText id="component-error-text">
                        {validationsErrors.invoiceMethodID}
                      </FormHelperText>
                    )}
                  </StyledFormControl>
                </StyledBox>
              </Box>
              <Box px={2}>
                <StyledBox mt={4}>
                  <StyledFormControl
                    error={!!validationsErrors.scheduledStartDate}
                    data-testid="scheduledStartDate-formcontrol"
                  >
                    <Datepicker
                      minDate={updatedFormData.scheduledStartDate}
                      id="scheduledStartDate"
                      label={t('Scheduled Start Date')}
                      placeholder="DD/MM/YYYY"
                      inputFormat={viewDateFormat}
                      formatDate={formatDate}
                      variant="outlined"
                      size="small"
                      name="scheduledStartDate"
                      value={updatedFormData.scheduledStartDate}
                      onChange={handleChange}
                      inputProps={{
                        'data-testid': 'scheduledStartDate',
                        'data-name': 'scheduledStartDate',
                      }}
                    />
                    {validationsErrors.scheduledStartDate && (
                      <FormHelperText id="component-error-text">
                        {validationsErrors.scheduledStartDate}
                      </FormHelperText>
                    )}
                  </StyledFormControl>
                </StyledBox>
                <StyledFormBoxControl mt={1}>
                  <FormControl>
                    <StyledFieldLabelInput
                      autoComplete="off"
                      id="poNumber"
                      name="poNumber"
                      label={t('Purchase Order')}
                      size="small"
                      inlineLabel
                      value={updatedFormData.poNumber}
                      onChange={handleChange}
                      error={validationsErrors.poNumber}
                    />
                  </FormControl>
                </StyledFormBoxControl>

                <StyledFormBoxControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!validationsErrors.invoiceToIdentityID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={updatedFormData.invoiceToIdentityID || 0}
                    onChange={e => {
                      handleChange(e);
                    }}
                    inlineLabel={false}
                    options={invoiceToData}
                    labelVariant="body1"
                    label={t('Invoice To')}
                    name={t('Invoice To')}
                    id="invoiceToIdentityID"
                    displayLabel
                    inputProps={{
                      name: 'invoiceToIdentityID',
                    }}
                  />
                  {validationsErrors.invoiceToIdentityID && (
                    <FormHelperText id="component-error-text">
                      {t(validationsErrors.invoiceToIdentityID)}
                    </FormHelperText>
                  )}
                </StyledFormBoxControl>

                <StyledFormBoxControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!validationsErrors.jobStatusID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={updatedFormData.jobStatusID || 0}
                    onChange={e => {
                      handleChange(e);
                    }}
                    inlineLabel={false}
                    options={lkpJobStatus}
                    labelVariant="body1"
                    label={t('Job Status')}
                    name={t('Job Status')}
                    id="jobStatusID"
                    displayLabel
                    inputProps={{
                      name: 'jobStatusID',
                    }}
                  />
                  {validationsErrors.jobStatusID && (
                    <FormHelperText id="component-error-text">
                      {t(validationsErrors.jobStatusID)}
                    </FormHelperText>
                  )}
                </StyledFormBoxControl>
              </Box>
            </Stack>
          </Grid>
          <Box my={2}>
            <DeleteIdentityButton
              handleOnDelete={handleOnConfirmDelete}
              identityName="Job"
              identityTypeID={IdentityTypeIds.JOB}
              isDisabled={!canDelete}
            />
          </Box>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
            }}
          >
            <Box mx={2} mt={1}>
              <SplitButton
                color="success"
                onClick={handleSave}
                variant="contained"
                minWidth={130}
                disabled={!hasChanges}
                data-testid="btnsuccess"
              />
            </Box>
            <Box mx={2} mt={1}>
              <VineaButton
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                {t('Close')}
              </VineaButton>
            </Box>
          </Grid>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={handleOnDialogClose}
            handleSaveChanges={handleSave}
            handleDiscardChanges={handleCancel}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ManageJob;
