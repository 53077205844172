import { createSelector } from 'reselect';

const userAllPreferences = state => state.userPreferences;

export const getUserPreferences = createSelector(
  [userAllPreferences],
  userPreferances => {
    return userPreferances;
  },
);
