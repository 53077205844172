export const reducers = {
  deeplink: 'DEEP_LINK',
  pageDimension: 'PAGE_DIMENSION',
  featureToggles: 'FEATURE_TOGGLES',
  userAccess: 'USER_ACCESS',
  repository: 'IDENTITY_REPOSITORY',
  details: 'IDENTITY_DETAILS',
  references: 'IDENTITY_REFERENCES',
  relationship: 'IDENITITY_RELATIONSHIP',
  relationshipType: 'IDENTITY_RELATIONSHIP_TYPE',
  classifications: 'IDENTITY_CLASSIFICATIONS',
  contactMethods: 'CONTACT_METHODS',
  roles: 'ROLES',
  person: 'PERSON',
  accommodation: 'ACCOMMODATION',
  organisation: 'ORGANISATION',
  vineyard: 'VINEYARD',
  serviceProvider: 'SERVICE_PROVIDER',
  job: 'JOB',
  dataConnection: 'DATA_CONNECTION',
  consumable: 'CONSUMABLE',
  activity: 'ACTIVITY',
  plan: 'PLAN',
  supplyContract: 'SUPPLY_CONTRACT',
  searchIdentities: 'SEARCH_IDENTITIES',
  searchUsers: 'SEARCH_USERS',
  searchPlans: 'SEARCH_PLANS',
  searchSupplyContracts: 'SEARCH_SUPPLY_CONTRACT',
  addDetail: 'ADD_DETAIL',
  addReference: 'ADD_REFERENCE',
  addressDetails: 'ADDRESS_DETAILS',
  lookupIdentityType: 'LOOKUP_IDENTITY_TYPE',
  lookupRoleType: 'LOOKUP_ROLE_TYPE',
  lookupBusinessUnit: 'LOOKUP_BUSINESS_UNIT',
  lookupClassificationType: 'LOOKUP_CLASSIFICATION_TYPE',
  lookupClassificationGroupType: 'LOOKUP_CLASSIFICATION_GROUP_TYPE',
  lookupClassificationGroupIdentityType:
    'LOOKUP_CLASSIFICATION_GROUP_IDENTITY_TYPE',
  lookupReferenceType: 'LOOKUP_REFERENCE_TYPE',
  lookupReferenceTypeIdentityType: 'LOOKUP_REFERENCE_TYPE_IDENTITY_TYPE',
  lookupRelationshipType: 'LOOKUP_RELATIONSHIP_TYPE',
  lookupRelationshipSubType: 'LOOKUP_RELATIONSHIP_SUB_TYPE',
  lookupContactMethod: 'LOOKUP_CONTACT_METHOD',
  lookupGender: 'LOOKUP_GENDER',
  lookupNationality: 'LOOKUP_NATIONALITY',
  lookupEthnicity: 'LOOKUP_ETHNICITY',
  lookupLanguage: 'LOOKUP_LANGUAGE',
  lookupSalutation: 'LOOKUP_SALUTATION',
  lookupCountry: 'LOOKUP_COUNTRY',
  lookupSubRegion: 'LOOKUP_SUB_REGION',
  lookupTown: 'LOOKUP_TOWN',
  lookupVariety: 'LOOKUP_VARIETY',
  lookupBlockVintageStatus: 'LOOKUP_BLOCK_VINTAGE_STATUS',
  lookupBlockVintage: 'LOOKUP_BLOCK_VINTAGE',
  lookupVintage: 'LOOKUP_VINTAGE',
  lookupRootStock: 'LOOKUP_ROOT_STOCK',
  lookupVarietyClone: 'LOOKUP_VARIETY_CLONE',
  lookupPayrollSystem: 'LOOKUP_PAYROLL_SYSTEM',
  lookupDataProvider: 'LOOKUP_DATA_PROVIDER',
  lookupDataProviderType: 'LOOKUP_DATA_PROVIDER_TYPE',
  formWrite: 'FORM_WRITE',
  personOverviewDetails: 'PERSON_OVERVIEW_DETAILS',
  accommodationOverviewDetails: 'ACCOMMODATION_OVERVIEW_DETAILS',
  identityOverviewDetails: 'IDENTITY_OVERVIEW_DETAILS',
  responseState: 'RESPONSE_STATE',
  refreshPages: 'REFRESH_PAGES',
  vineyardBlocks: 'VINEYARD_BLOCKS',
  vineyardBlockRows: 'VINEYARD_BLOCK_ROWS',
  identityNotes: 'IDENTITY_NOTES',
  blockVintages: 'BLOCK_VINTAGES',
  trellis: 'TRELLIS',
  lookUpRowSpecialAttribute: 'LOOKUP_ROW_SPECIAL_ATTRIBUTE',
  lookUpNoteStatus: 'LOOKUP_NOTE_STATUS',
  lookupMeasure: 'LOOKUP_MEASURE',
  lookupMeasureGroup: 'LOOKUP_MEASURE_GROUP',
  lookupMeasureInstance: 'LOOKUP_MEASURE_INSTANCE',
  lookupRegion: 'LOOKUP_REGION',
  sampleSet: 'SAMPLES_SET',
  sampleHeaders: 'SAMPLE_HEADERS',
  sampleBlockVintages: 'SAMPLE_BLOCK_VINTAGES',
  lookUpKeyword: 'LOOKUP_KEYWORD',
  lookupSampleRequestStatus: 'SampleRequestStatus',
  sampleSetSearchFilter: 'SAMPLE_SET_SEARCH_FILTER',
  trackingSearchFilter: 'TRACKING_SEARCH_FILTER',
  vineyardSearchFilter: 'VINEYARD_SEARCH_FILTER',
  organisationSearchFilter: 'ORGANISATION_SEARCH_FILTER',
  serviceProviderSearchFilter: 'SERVICE_PROVIDER_SEARCH_FILTER',
  jobSearchFilter: 'JOB_SEARCH_FILTER',
  personSearchFilter: 'PERSON_SEARCH_FILTER',
  supplyContractSearchFilter: 'SUPPLY_CONTRACT_SEARCH_FILTER',
  connectionSearchFilter: 'CONNECTION_SEARCH_FILTER',
  consumableSearchFilter: 'CONSUMABLE_SEARCH_FILTER',
  vehicleSearchFilter: 'VEHICLE_SEARCH_FILTER',
  accommodationSearchFilter: 'ACCOMMODATION_SEARCH_FILTER',
  vineyardExplorerSearchFilter: 'VINEYARD_EXPLORER_SEARCH_FILTER',
  usersSearchFilter: 'USERS_SEARCH_FILTER',
  plansSearchFilter: 'PLANS_SEARCH_FILTER',
  activitySearchFilter: 'ACTIVITY_SEARCH_FILTER',
  tabChangeLock: 'TAB_CHANGE_LOCK',
  newNoteDocuments: 'NEW_NOTE_DOCUMENTS',
  chartFilter: 'CHART_FILTER',
  dashboardPageFilter: 'DASHBOARD_PAGE_FILTER',
  latestChartData: 'LATEST_CHART_DATA',
  connectionSensorsPageFilter: 'CONNECTION_SENSORS_PAGE_FILTER',
  connectionSitePageFilter: 'CONNECTION_SITE_PAGE_FILTER',
  vineyardBlockSearchFilter: 'VINEYARD_BLOCK_SEARCH_FILTER',
};
