/* eslint-disable */
// @ts-nocheck
import React, { useState } from 'react';
import classNames from 'classnames';
// @ts-ignore
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { useTheme, styled } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import isEqual from 'lodash/isEqual';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { IdentitySubMenu } from '../../components/IdentitySubMenu';
import { FilterScreen } from '../../components/SamplingComponents';
import {
  sagaActionTypes as Types,
  reducers,
  actionTypes,
  featureFlags,
} from '../../constants';
import { IRouteProps } from '../../constants/datatypes';
import { SearchGridResults } from './GridResults/SearchGridResults';
import { useSamplesHook } from './hooks/useSamplesHook';
import useLocationListener from '../../hooks/useLocationListener';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import { getUserPreferences } from '../../redux/selectors';
import withFeatureFlagHOC from '../../hooks/withFeatureFlagHOC';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { sortBy } from 'lodash';

const StyledRoot = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
}));

const StyledBox = styled(Box)(({ theme, isFilterOpen }) => ({
  ...(isFilterOpen && {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flex: '0.4',
    },
    [theme.breakpoints.up('md')]: {
      flex: '0.3',
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0.18',
    },
  }),
  ...(!isFilterOpen && {
    display: 'flex',
    flex: 0,
  }),
}));

interface CommonRoutes {
  routes: IRouteProps[];
}

const SampleSearchResult: React.FC<CommonRoutes> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const locationObj = useLocationListener();
  const { samplesDataGrid, basicSettings } = useSelector(state =>
    getUserPreferences(state),
  );
  const { filterPanelCollapsed } = basicSettings;

  const [filterText, setFilterText] = React.useState([]);
  const firstFilterInputRef = React.useRef();

  const handleOnFilterToggle = () => {
    dispatchAPI({
      type: 'BASIC_SETTINGS_UPDATE',
      payload: {
        ...basicSettings,
        filterPanelCollapsed: !filterPanelCollapsed,
      },
    });
  };

  const handleOnFilterOpen = () => {
    if (filterPanelCollapsed) {
      dispatchAPI({
        type: 'BASIC_SETTINGS_UPDATE',
        payload: { ...basicSettings, filterPanelCollapsed: false },
      });
    } else if (firstFilterInputRef.current) {
      firstFilterInputRef.current?.focus();
    }
  };

  // @ts-ignore
  const [sampleSetData, setSampleSetData] = React.useState([]);
  const [locationKeys, setLocationKeys] = useState<any>([]);
  const { setIsLocked, isLocked } = useTabChangeLock();

  /** Selectors */
  const {
    lkpGrowerRegion,
    lkpMeasure,
    allLookupVintages,
    isLoadingLookups,
    sampleData,
    latestVintageIdValue,
    selectedRegionId,
    searchFilterData,
  } = useSamplesHook();

  const sampleDataSorted = sortBy(sampleData, 'sampleSet');

  /** Dispatches on search button click */
  const onSearchSamples = (searchParams: any, newFilterText: any) => {
    setSampleSetData(searchParams);
    dispatchAPI(
      VineaNovaActions.api.v1.sampleSets.post.request({
        postBody: { ...searchParams },
      }),
    );

    setFilterText(newFilterText);
  };

  //To do (add in below if conditon "&& isExact")
  React.useEffect(() => {
    if (isLocked) {
      setIsLocked(false);
    }
    if (!isEqual(locationKeys, locationObj)) {
      setLocationKeys(locationObj);
      dispatchAPI(
        VineaNovaActions.api.v1.sampleSets.post.request({
          postBody: {
            ...searchFilterData,
          },
        }),
      );
    }
  }, [
    locationObj,
    locationKeys,
    dispatchAPI,
    searchFilterData,
    latestVintageIdValue,
    selectedRegionId,
    isLocked,
    setIsLocked,
  ]);

  const onUpdateFilterData = (data: any) => {
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.sampleSetSearchFilter,
      payload: data,
    });
  };

  /** EOF Dispatches */

  // const isDetailPage = !!pathParam;

  React.useEffect(() => {
    dispatchAPI({
      type: 'SAMPLING_TRACKING_LOOKUPS',
      name: 'SAMPLING_TRACKING_LOOKUPS',
    });
  }, []);

  const handleOnCreateNewSample = () => {
    navigate('/samples/sampling/createnew');
  };

  const handleOnRowClick = (row: any): void => {
    const { id } = row;
    navigate(`/samples/sampling/${id}`, {
      state: { allowLink: true, sampleSetData },
    });
  };

  const handleOnCellClick = () => {};

  return (
    <>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        data-testid="samplesearch-results"
        style={{ height: '100%', padding: 3 }}
      >
        <Paper
          elevation={0}
          data-testid="submenu-sampling"
          sx={{
            padding: 1,
            marginBottom: 3,
          }}
        >
          <IdentitySubMenu
            onNewIdentityClick={handleOnCreateNewSample}
            newButtonText="Add Sample Set"
            newButtonDisabled={false}
            showImport={false}
            showExport={false}
          />
        </Paper>
        <StyledRoot data-testid="samplingcontainer">
          <StyledBox isFilterOpen={!filterPanelCollapsed}>
            <FilterScreen
              filterType="Sampling"
              isFilterOpen={!filterPanelCollapsed}
              onFilterToggle={handleOnFilterToggle}
              search={onSearchSamples}
              lookupVintages={allLookupVintages}
              lookupRegions={lkpGrowerRegion}
              lookupMeasure={lkpMeasure}
              lookupVintageLatestId={latestVintageIdValue}
              selectedRegionId={selectedRegionId}
              onUpdateFilterData={onUpdateFilterData}
              searchFilterData={searchFilterData}
              firstFilterInputRef={firstFilterInputRef}
            />
          </StyledBox>
          <Box display="flex" flex={1} ml={2} pb={3} pr={3}>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
              }}
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <SearchGridResults
                  handleOnRowClick={handleOnRowClick}
                  handleOnCellClick={handleOnCellClick}
                  isLoading={isLoadingLookups}
                  samplesData={sampleDataSorted}
                  actionType={'SAMPLES_GRID_COLUMN_UPDATE'}
                  gridColumnState={samplesDataGrid}
                  filterText={filterText}
                  onFilterOpen={handleOnFilterOpen}
                />
              </ErrorBoundary>
            </Paper>
          </Box>
        </StyledRoot>
      </Box>
    </>
  );
};

// SampleSearchResult.propTypes = {
//   routes: commonRoutePropType.isRequired,
// };

SampleSearchResult.defaultProps = {};

export default withFeatureFlagHOC(featureFlags.SAMPLES)(SampleSearchResult);
