import { isValid, format, parseISO, isAfter, isBefore } from 'date-fns';
import { viewDateFormat, dateFormat } from '.';

export const formatDate = value => {
  if (!value) return value;
  const isValidDate = isValid(new Date(value));
  if (!isValidDate) return value;
  return format(new Date(value), viewDateFormat);
};

export function formatAsViewDateFormat(value) {
  if (!value) return '';
  const parsed = new Date(value);
  if (!isValid(parsed)) return '';

  return format(parsed, viewDateFormat);
}

export function formatAsDateFormat(value) {
  if (!value) return null;
  const parsed = new Date(value);
  if (!isValid(parsed)) return null;

  return format(parsed, dateFormat);
}

export function getIsActive(fromDate, toDate) {
  const today = new Date(format(new Date(), dateFormat));

  // check that fromDate is in past or present (or is falsy)
  const parsedFrom = parseISO(fromDate);
  const isValidFromDate = !!fromDate && isValid(parsedFrom);
  const fromDateBeforeToday = !isValidFromDate || !isAfter(parsedFrom, today);

  // check that toDate is in present or future (or is falsy)
  const parsedTo = parseISO(toDate);
  const isValidToDate = !!toDate && isValid(parsedTo);
  const toDateAfterToday = !isValidToDate || !isBefore(parsedTo, today);

  // isActive is true if fromDate <= today <= toDate
  return fromDateBeforeToday && toDateAfterToday;
}
