import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useTheme, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Alert, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { VineaAlert } from '../../../components/VineaAlert';
import { FieldLabelInput } from '../../../components/TextField';
import {
  addGenericReducer,
  organisationState,
  types,
} from '../../VineyardIdentity/stateReducers';
import { syncValidator } from '../../../utils/validator';
import {
  OrganisationSchema,
  VineyardSchema,
} from '../../VineyardIdentity/validations';
import { getBusinessUnit } from '../../../redux/selectors';
import {
  commonGridColumns,
  commonGridHeaderNames,
  dateFormat,
  IdentityTypeIds,
  sagaActionTypes,
} from '../../../constants';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { VineaCommonGrid } from '../../../components/Grid';

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 300,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 250,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const CreateOrganisation = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const today = format(new Date(), dateFormat);
  const { enqueueSnackbar } = useSnackbar();
  const {
    onSaveStepData,
    step,
    stepData,
    calculateNameToCheck,
    matchingIdentities,
  } = props;
  const [formdata, dispatch] = useReducer(addGenericReducer, organisationState);

  const identityOrganisation = useSelector(
    state => state.entities.identityOrganisation,
  );
  const businessUnitList = useSelector(state => getBusinessUnit(state));

  const [inSavingMode, setSaveMode] = useState(false);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = useState(false);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [isIdentityTrigger, setIdentityTrigger] = useState(false);

  useImperativeHandle(ref, () => ({
    saveForm() {
      const validationErrors = syncValidator(OrganisationSchema)(formdata);
      if (!isEmpty(validationErrors)) {
        dispatch({ type: types.ERROR, payload: validationErrors });
      } else {
        const data = {
          IdentityTypeID: IdentityTypeIds.ORGANISATION,
          OrganisationName: formdata.organisationName,
          OrganisationKnownAs: formdata.organisationKnownAs || '',
          BusinessUnitID: formdata.businessUnitID || null,
          PreferredContactMethodTypeID: null,
          CreatedDate: today,
        };
        dispatchAPI(
          VineaNovaActions.api.v1.identityOrganisation.post.request({
            postBody: {
              ...data,
            },
          }),
        );
        setSaveMode(true);
        setActionTriggered(true);
        setIdentityTrigger(true);
      }
    },
  }));

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleOnBlurOrgName = () => {
    const validationErrors = syncValidator(VineyardSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  useEffect(() => {
    calculateNameToCheck(formdata);
  }, [formdata.organisationName]);

  useEffect(() => {
    const {
      data: organisationData,
      hasError,
      isLoading,
    } = identityOrganisation;
    if (inSavingMode && !isLoading) {
      if (!hasError) {
        setSaveMode(false);
        onSaveStepData(
          { data: organisationData, isSaving: false, isComplete: true },
          step,
        );
      } else {
        setSaveMode(false);
        onSaveStepData(
          {
            data: { identityId: null },
            isSaving: false,
            isComplete: false,
            hasError: true,
          },
          step,
        );
      }
    }
  }, [inSavingMode, onSaveStepData, step, identityOrganisation]);

  useEffect(() => {
    dispatch({
      type: types.UPDATE,
      payload: {
        ...stepData,
        vineyardName: stepData.vineyardName,
        vineyardKnownAs: stepData.vineyardKnownAs,
        subRegionID: stepData.subRegionID,
        businessUnitID: stepData.businessUnitID,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { hasError, data, isLoaded } = identityOrganisation;
    if (isLoaded && actionTriggered) {
      if (!hasError && !isEmpty(data) && isIdentityTrigger) {
        setIdentityTrigger(false);
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        const {
          data: { id, identityTypeID },
        } = identityOrganisation;
        dispatchAPI({
          type: sagaActionTypes.FETCH_IDENTITIES,
          payload: {
            id,
            identityTypeId: identityTypeID,
          },
        });
      }
    }
  });

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <VineaAlert
        isOpen={!!errorInSubmit}
        onExit={() => setErrorInSubmit(false)}
      />
      <Box px={2}>
        {warningInSubmit && (
          <Collapse in={warningInSubmit}>
            <Alert
              variant="standard"
              severity="warning"
              action={
                <IconButton
                  aria-label="close-warning"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setWarningInSubmit(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Warning: Nothing to Update
            </Alert>
          </Collapse>
        )}
      </Box>
      <Stack direction="row">
        <Grid
          item
          xs={12}
          sx={{
            marginTop: theme.spacing(5),
          }}
        >
          <StyledFormBoxControl mt={1}>
            <StyledFieldLabelInput
              autoComplete="off"
              id="name"
              name="organisationName"
              label={t('Organisation Name')}
              size="small"
              inlineLabel
              value={formdata.organisationName}
              onChange={handleChange}
              onBlur={handleOnBlurOrgName}
              error={formdata.errors.organisationName}
              helperText={formdata.errors.organisationName}
            />
          </StyledFormBoxControl>
          <StyledFormBoxControl mt={1}>
            <StyledFieldLabelInput
              id="knownas"
              name="organisationKnownAs"
              label={t('Known As')}
              size="small"
              inlineLabel
              value={formdata.organisationKnownAs}
              onChange={handleChange}
              onBlur={handleOnBlurOrgName}
              error={formdata.errors.organisationKnownAs}
              helperText={formdata.errors.organisationKnownAs}
            />
          </StyledFormBoxControl>
          <StyledFormBoxControl mx={2} mt={1}>
            <FormControl
              sx={{
                marginTop: theme.spacing(1),
                minWidth: 250,
              }}
              margin="none"
              error={!!formdata.errors.businessUnitID}
            >
              <VineaAutoComplete
                value={formdata.businessUnitID || 0}
                onChange={handleChange}
                onBlur={handleOnBlurOrgName}
                options={businessUnitList}
                labelVariant="body1"
                label={t('Business Unit')}
                name={t('Business Unit')}
                inlineLabel
                inputProps={{
                  name: 'businessUnitID',
                }}
              />
              {formdata.errors.businessUnitID && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.businessUnitID}
                </FormHelperText>
              )}
            </FormControl>
          </StyledFormBoxControl>
        </Grid>
        <Box
          sx={{
            marginLeft: theme.spacing(4),
            marginBottom: theme.spacing(4),
          }}
        >
          <VineaCommonGrid
            heading={t(commonGridHeaderNames.SIMILAR_IDENTITIES)}
            colHeaders={commonGridColumns.SIMILAR_IDENTITIES}
            tableData={matchingIdentities}
            hasManageOption={false}
            hasActiveToggle={false}
          />
        </Box>
      </Stack>
    </Paper>
  );
});

CreateOrganisation.propTypes = {
  stepData: PropTypes.shape({
    vineyardName: PropTypes.string,
    vineyardKnownAs: PropTypes.string,
    businessUnitID: PropTypes.number,
    createdDate: PropTypes.string,
    id: PropTypes.number,
    identityTypeID: PropTypes.number,
    plantedArea: PropTypes.number,
    preferredContactMethodTypeID: PropTypes.number,
    subRegionID: PropTypes.number,
    totalVineyardArea: PropTypes.number,
    ts: PropTypes.string,
  }),
  onSaveStepData: PropTypes.func,
  step: PropTypes.number,
  calculateNameToCheck: PropTypes.func,
  matchingIdentities: PropTypes.array,
};

CreateOrganisation.defaultProps = {
  stepData: {},
  onSaveStepData: () => {},
  step: 0,
  calculateNameToCheck: () => {},
  matchingIdentities: [],
};

export default CreateOrganisation;
