/* eslint-disable */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { GridWithFilter, RenderDataGridHeader } from '../../components/Grid';
import {
  areLookupsLoading,
  getAuthenticationResult,
} from '../../redux/selectors';
import { sortBy } from 'lodash';

const dataGridHeaderSupplyContractColumns = [
  {
    field: 'contractName',
    headerName: 'Contract Name',
    flex: 1.3,
    width: 200,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'purchasingParty',
    headerName: 'Purchasing Party',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'supplyingParty',
    headerName: 'Supplying Party',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'supplyContractType',
    headerName: 'Supply Contract Type',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
];

export const SupplyContractsSearchResults = ({
  identities,
  filterText,
  onFilterOpen,
}) => {
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { data = [], isLoading, isLoaded, hasError } = identities;
  const sortedData = sortBy(data, 'contractName');

  const [loadingState, setLoadingState] = React.useState(false);
  const [gridColumnState, setGridColumnState] = React.useState({});

  React.useEffect(() => {
    if (isLoading) setLoadingState(true);
  }, [isLoaded, isLoading, data, hasError]);

  const areLookupLoading = useSelector(areLookupsLoading);
  const { isLoading: authenticationIsLoading } = useSelector(
    getAuthenticationResult,
  );

  const gridXData = {
    columns: dataGridHeaderSupplyContractColumns,
    rows: sortedData,
  };

  const handleOnClick = evt => {
    navigate(`/supplycontract/${evt.id}`);
    dispatchAPI(
      VineaNovaActions.api.v1.blocksForSupplyContract.get.request({
        queryParams: {
          SupplyContractID: evt.id,
        },
      }),
    );
  };

  return (
    <Grid
      container
      spacing={0}
      data-testid="supplycontractsearch-results"
      direction="column"
    >
      <Grid
        item
        xs={12}
        sx={{
          flex: 1,
        }}
      >
        <Paper
          sx={{
            width: '100%',
            height: '100%',
            overflowX: 'auto',
            '& .MuiDataGrid-cell': {
              padding: '0 5px',
            },
            '& .MuiDataGrid-colCell': {
              padding: '0 5px',
            },
          }}
        >
          <GridWithFilter
            data={gridXData}
            onRowClick={handleOnClick}
            rowHeight={30}
            loading={areLookupLoading || isLoading || authenticationIsLoading}
            autoPageSize
            headerHeight={56}
            // actionType={dispatchActionType}
            gridColumnState={gridColumnState}
            handleOnAdvancedSearch={onFilterOpen}
            advancedSearch={true}
            selectedFilterValuesText={filterText}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
