import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link as RouterLink } from 'react-router-dom';
// import Link from '@mui/material/Link';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import times from 'lodash/times';
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox } from '@mui/material';

const StyledTableNumberCell = styled(TableCell)(({ theme }) => ({
  minWidth: 30,
  head: {
    backgroundColor: theme.palette.action.hover,
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0.17px',
  },
  body: {
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.4px',
    fontWeight: 400,
  },
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  minWidth: 60,
  head: {
    backgroundColor: theme.palette.action.hover,
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0.17px',
  },
  body: {
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.4px',
    fontWeight: 400,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: theme.palette.action.hover,
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0.17px',
  },
  body: {
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.4px',
    fontWeight: 400,
  },
}));

const StyledTableCellOpacity = styled(StyledTableCell)(({ theme }) => ({
  background: theme.palette.background.default,
}));

export const LinkBehavior = React.forwardRef((props, ref) => (
  <RouterLink
    ref={ref}
    to="/getting-started/installation/"
    {...props}
    // eslint-disable-next-line react/prop-types
    key={props.to}
  />
));

export const CommonGridData = ({
  data,
  colHeaders,
  isLoading,
  isLink,
  onRowClick,
  tableHeight = 300,
  onCheckboxClick = row => {},
}) => {
  const { t } = useTranslation();

  if (isEmpty(colHeaders) || isNil(colHeaders)) return null;
  const totalColumns = colHeaders.length;
  const hasData = Array.isArray(data) && data.length > 0;

  const handleOnRowClick = useCallback(
    (row, isCell, isLink) => {
      if (isCell || isLink) {
        onRowClick(row);
      }
    },
    [onRowClick, isLink],
  );

  const handleOnCheckboxClick = useCallback(
    row => {
      onCheckboxClick(row);
    },
    [onCheckboxClick],
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: tableHeight,
      }}
      datatestid="commongriddata-tablecontainer"
    >
      <Table
        stickyHeader
        sx={{
          minWidth: 500,
          height: tableHeight,
        }}
        aria-label="customized table"
        size="small"
        datatestid="commongriddata-table"
      >
        <TableHead style={{ height: '30px' }}>
          <TableRow>
            {Array.isArray(colHeaders) &&
              map(colHeaders, val => {
                return (
                  <StyledTableCellOpacity key={val}>
                    {t(val)}
                  </StyledTableCellOpacity>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody datatestid="commongriddata-tablebody">
          {isLoading && (
            <TableRow>
              <StyledTableHeaderCell align="center">
                <CircularProgress />
              </StyledTableHeaderCell>
            </TableRow>
          )}

          {hasData &&
            !isLoading &&
            data.map(row =>
              Object.entries(row).some(e => String(e[1]).trim()) ? (
                <TableRow
                  hover
                  key={row.name}
                  component={isLink && row.link ? LinkBehavior : 'tr'}
                  to={isLink && row.link ? row.link : ''}
                  onClick={() => handleOnRowClick(row, false, isLink)}
                  style={{ height: '26px' }}
                  data-testid="commongriddata-tablerow"
                  data-rowlink={isLink && row.link ? row.link : ''}
                >
                  <StyledTableHeaderCell
                    data-testid={`table-header-cell --- ${row.link}`}
                    data-rowlink={row.link}
                    onClick={() => {
                      handleOnRowClick(row, true, false);
                    }}
                  >
                    {row.link && (
                      <RouterLink component={RouterLink} to={row.link}>
                        {row.col1}
                      </RouterLink>
                    )}
                    {!row.link && row.col1}
                  </StyledTableHeaderCell>
                  <StyledTableNumberCell
                    align="left"
                    onClick={() => {
                      handleOnRowClick(row, true, false);
                    }}
                  >
                    {row.col2}
                  </StyledTableNumberCell>

                  {totalColumns > 2 && row.col3 && (
                    <StyledTableNumberCell
                      align="left"
                      onClick={() => {
                        handleOnRowClick(row, true, false);
                      }}
                    >
                      {row.col3}
                    </StyledTableNumberCell>
                  )}
                  {totalColumns > 3 && row.col4 && (
                    <StyledTableNumberCell
                      align="left"
                      onClick={() => {
                        handleOnRowClick(row, true, false);
                      }}
                    >
                      {row.col4}
                    </StyledTableNumberCell>
                  )}
                  {totalColumns > 4 &&
                    row.col5 &&
                    (row.col5.dataType !== 'boolean' ? (
                      <StyledTableNumberCell
                        align="left"
                        onClick={() => {
                          handleOnRowClick(row, true, false);
                        }}
                      >
                        {row.col5.value}
                      </StyledTableNumberCell>
                    ) : (
                      <Checkbox
                        checked={row.col5.value === 1 ? true : false}
                        onChange={() => {
                          handleOnCheckboxClick(row);
                        }}
                        onClick={() => {}}
                      />
                    ))}
                  {totalColumns > 5 && row.col6 && (
                    <StyledTableNumberCell
                      align="left"
                      onClick={() => {
                        handleOnRowClick(row, true, false);
                      }}
                    >
                      {row.col6}
                    </StyledTableNumberCell>
                  )}
                  {totalColumns > 6 && row.col7 && (
                    <StyledTableNumberCell
                      align="left"
                      onClick={() => {
                        handleOnRowClick(row, true, false);
                      }}
                    >
                      {row.col7}
                    </StyledTableNumberCell>
                  )}
                  {totalColumns > 7 && row.col8 && (
                    <StyledTableNumberCell
                      align="left"
                      onClick={() => {
                        handleOnRowClick(row, true, false);
                      }}
                    >
                      {row.col8}
                    </StyledTableNumberCell>
                  )}
                  {totalColumns > 8 && row.col9 && (
                    <StyledTableNumberCell
                      align="left"
                      onClick={() => {
                        handleOnRowClick(row, true, false);
                      }}
                    >
                      {row.col9}
                    </StyledTableNumberCell>
                  )}
                  {totalColumns > 9 && row.col10 && (
                    <StyledTableNumberCell
                      align="left"
                      onClick={() => {
                        handleOnRowClick(row, true, false);
                      }}
                    >
                      {row.col10}
                    </StyledTableNumberCell>
                  )}
                  {totalColumns > 10 && row.col11 && (
                    <StyledTableNumberCell
                      align="left"
                      onClick={() => {
                        handleOnRowClick(row, true, false);
                      }}
                    >
                      {row.col11}
                    </StyledTableNumberCell>
                  )}
                  {totalColumns > 11 && row.col12 && (
                    <StyledTableNumberCell
                      align="left"
                      onClick={() => {
                        handleOnRowClick(row, true, false);
                      }}
                    >
                      {row.col12}
                    </StyledTableNumberCell>
                  )}
                  {totalColumns > 12 && row.col13 && (
                    <StyledTableNumberCell
                      align="left"
                      onClick={() => {
                        handleOnRowClick(row, true, false);
                      }}
                    >
                      {row.col13}
                    </StyledTableNumberCell>
                  )}
                </TableRow>
              ) : (
                <TableRow style={{ visibility: 'hidden' }}>
                  <StyledTableCell />
                </TableRow>
              ),
            )}
          {!hasData && !isLoading && (
            <TableRow hover>
              {times(totalColumns, n => {
                return <StyledTableHeaderCell key={n} />;
              })}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CommonGridData.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  colHeaders: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  isLink: PropTypes.bool,
};

CommonGridData.defaultProps = {
  data: null,
  colHeaders: [],
  isLoading: false,
  isLink: false,
};
