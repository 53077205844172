import React, { forwardRef, useReducer, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FieldLabelInput } from '../../../components/TextField';
import { FieldNameSelect } from '../../../components/Select';
import {
  sagaActionTypes,
  vineaDetails,
  dateFormat,
  apiTypes,
} from '../../../constants';
import { VineaAlert } from '../../../components/VineaAlert';
import { OrganisationSchema } from '../validations';
import { syncValidator } from '../../../utils/validator';
import { addGenericReducer, organisationState, types } from '../stateReducers';
import logger from '../../../utils/winstonLogger';
import { getBusinessUnit } from '../../../redux/selectors';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(2),
  minWidth: 250,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const CreateNewIdentity = forwardRef((props, ref) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { onSaveStepData, step, stepData } = props;
  const [actionTriggered, setActionTriggered] = React.useState(false);

  // const pathName = history.location.pathname.split('/')[2];
  logger.debug('CreateNewIdentity');
  // const responseId = useSelector(getResponseId);
  const formWrite = useSelector(state => state.formWrite);
  const businessUnitList = useSelector(state => getBusinessUnit(state));
  logger.debug('businessUnitList');
  logger.debug(businessUnitList);

  const [inSavingMode, setSaveMode] = React.useState(false);
  const [errorInSubmit, setErrorInSubmit] = React.useState(false);

  // const { enqueueSnackbar } = useSnackbar();

  const [formdata, dispatch] = useReducer(addGenericReducer, organisationState);
  const today = format(new Date(), dateFormat);
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  const OrgId = useIdentityTypeId(vineyardTypeScreenName);

  useImperativeHandle(ref, () => ({
    saveForm() {
      const validationErrors = syncValidator(OrganisationSchema)(formdata);
      if (!isEmpty(validationErrors)) {
        dispatch({ type: types.ERROR, payload: validationErrors });
      } else {
        const data = {
          IdentityTypeID: OrgId,
          OrganisationName: formdata.name,
          OrganisationKnownAs: formdata.knownAs,
          RoleTypeID: null,
          PreferredContactMethodTypeID: null,
          BusinessUnitID: formdata.businessunit,
          CreatedDate: today,
        };
        // save to stepper reducer
        onSaveStepData(
          {
            ...data,
            name: formdata.name,
            knownAs: formdata.knownAs,
            isSaving: true,
            isComplete: false,
            hasError: false,
          },
          step,
        );
        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.identity,
            methodType: apiTypes.POST,
          },
        });
        setSaveMode(true);
        setActionTriggered(true);
      }
    },
  }));

  React.useEffect(() => {
    const { hasError, data, isLoaded } = formWrite;
    if (isLoaded && actionTriggered) {
      setActionTriggered(false);
      if (!hasError && !isEmpty(data)) {
        enqueueSnackbar(t('Success'), { variant: 'Success' });
      } else if (hasError) {
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }
    }
  }, [formWrite, actionTriggered, enqueueSnackbar, t]);

  React.useEffect(() => {
    const { data: formWriteData, hasError, isLoading } = formWrite;
    if (inSavingMode && !isLoading) {
      logger.debug(formWrite);
      if (!hasError) {
        const { id } = formWriteData;
        setSaveMode(false);
        onSaveStepData(
          { identityId: id, isSaving: false, isComplete: true },
          step,
        );
        enqueueSnackbar('Success', { variant: 'Success' });
      } else {
        setSaveMode(false);
        onSaveStepData(
          {
            data: { identityId: null },
            isSaving: false,
            isComplete: false,
            hasError: true,
          },
          step,
        );
        setErrorInSubmit(true);
        enqueueSnackbar('Error', { variant: 'Error' });
      }
    }
  }, [inSavingMode, formWrite, onSaveStepData, step, enqueueSnackbar]);

  React.useEffect(() => {
    dispatch({ type: types.UPDATE, payload: { ...stepData } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleOnBlurOrgName = () => {
    const validationErrors = syncValidator(OrganisationSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  return (
    <Grid container spacing={2} data-testid="grid-details">
      <Grid item xs={12}>
        <Paper
          elevation={0}
          data-testid="detail-info"
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <VineaAlert
            isOpen={!!errorInSubmit}
            onExit={() => setErrorInSubmit(false)}
          />
          <Box
            mt={1}
            sx={{
              marginLeft: theme.spacing(2),
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(2),
              minWidth: 250,
              '& .MuiFormControl-root': {
                minWidth: 250,
              },
            }}
          >
            <StyledFieldLabelInput
              autoComplete="off"
              id="name"
              name="name"
              label={t('Organisation Name')}
              size="small"
              inlineLabel
              value={formdata.name}
              onChange={handleChange}
              onBlur={handleOnBlurOrgName}
              error={formdata.errors.name}
              helperText={formdata.errors.name}
            />
          </Box>
          <Box
            mt={3}
            sx={{
              marginLeft: theme.spacing(2),
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(2),
              minWidth: 250,
              '& .MuiFormControl-root': {
                minWidth: 250,
              },
            }}
          >
            <StyledFieldLabelInput
              id="knownas"
              label={t('Known As')}
              size="small"
              name="knownAs"
              inlineLabel
              value={formdata.knownAs}
              onChange={handleChange}
            />
          </Box>
          <Box mx={2} mt={3}>
            <FormControl
              sx={{
                marginTop: theme.spacing(2),
                minWidth: 250,
              }}
              margin="none"
            >
              <FieldNameSelect
                value={formdata.businessunit}
                onChange={handleChange}
                options={businessUnitList}
                label={t('Business Unit')}
                inputProps={{
                  name: 'businessunit',
                }}
              />
            </FormControl>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
});

CreateNewIdentity.propTypes = {
  stepData: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  onSaveStepData: PropTypes.func,
  step: PropTypes.number,
};

CreateNewIdentity.defaultProps = {
  stepData: {},
  onSaveStepData: () => {},
  step: 0,
};

export default CreateNewIdentity;
