import { authenticationTypes as actionTypes } from '../../constants';

const initialState = {
  initializing: false,
  initialized: false,
  idToken: null,
  accessToken: null,
  error: null,
  isAuthenticated: false,
  user: null,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.fetching:
      return {
        ...state,
        initializing: true,
        initialized: false,
        hasError: false,
      };
    case actionTypes.success:
      return {
        ...state,
        initializing: false,
        initialized: true,
        hasError: false,
        isAuthenticated: payload.isAuthenticated,
        user: payload.user,
        accessToken: payload.accessToken,
        error: null,
      };
    case actionTypes.token:
      return {
        ...state,
        accessToken: payload.accessToken,
      };
    case actionTypes.error:
      return {
        ...state,
        ...payload,
        initializing: false,
        initialized: true,
        hasError: true,
      };
    case actionTypes.logout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default authReducer;
