import React, { useState, useCallback } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty, get } from 'lodash';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Backdrop } from '@mui/material';
import { ImageUpload } from '../ImageUpload/ImageUpload';
import { VineaButton } from '../VineaButton';
import getCroppedImg from '../../utils/cropImageUtils';

interface LogoUploadCropProps {
  onClose: () => {};
  open: boolean;
  onSave: (
    blobImageUrl: string | null,
    fileName: string,
    fileType: string,
  ) => {};
  // selectedImg: string;
}

export const LogoUploadCrop = ({
  onClose,
  open,
  onSave,
}: LogoUploadCropProps) => {
  const { t } = useTranslation();
  const [selectedImg, setSelectedImg] = useState<any>({});
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<any>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [fileName, setFileName] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');

  // const [success, setSuccess] = useState<boolean>(false);
  const onCropComplete = useCallback((croppedArea, cropAreaPix) => {
    // console.log(croppedArea, cropAreaPix);
    setCroppedAreaPixels(cropAreaPix);
  }, []);

  const handleClose = () => {
    // setCroppedImage(null);
    onClose();
  };
  const handleOnImageUploadData = (imageSet: {
    preview: string;
    base64: string;
    raw: string;
  }) => {
    if (imageSet) {
      setSelectedImg(imageSet);
      const uploadedFileName = get(imageSet, 'raw.name', '');
      const imageFileType = get(imageSet, 'raw.type', '');
      setFileType(imageFileType);
      setFileName(uploadedFileName);
    }
  };

  const handleOnZoom = (event: Event, value: number | number[]) => {
    setZoom(value);
  };

  const handleOnCancel = () => {
    setSelectedImg({});
  };

  const handleBackdropClose = () => {
    setLoading(false);
  };

  const handleOnSave = useCallback(async () => {
    setLoading(true);
    try {
      const croppedImageResult = await getCroppedImg(
        selectedImg.preview,
        croppedAreaPixels,
      );
      onSave(croppedImageResult, fileName, fileType);
      onClose();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [croppedAreaPixels, selectedImg, onSave, onClose, fileName, fileType]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        Upload Image
        <CloseIcon
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 10,
          }}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '10px',
        }}
      >
        {!isEmpty(selectedImg) && (
          <Card
            sx={{
              padding: '10px',
            }}
          >
            {loading && (
              <Backdrop
                open={loading}
                sx={{
                  zIndex: 10,
                  color: '#fff',
                }}
                onClick={handleBackdropClose}
              >
                <CircularProgress
                  size={60}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    opacity: 0.8,
                  }}
                />
              </Backdrop>
            )}
            <CardActionArea
              sx={{
                height: 200,
              }}
            >
              {/* @ts-ignore */}
              <Cropper
                image={selectedImg.preview}
                crop={crop}
                zoom={zoom}
                data-testid="LogoImage"
                aspect={4 / 4}
                cropShape="round"
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </CardActionArea>
            <CardActions>
              <Slider
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={handleOnZoom}
              />
            </CardActions>
          </Card>
        )}
        {selectedImg && (
          <Box display="flex" justifyContent="space-between" marginTop="10px">
            <ImageUpload
              // @ts-ignore
              handleOnChange={handleOnImageUploadData}
              ref={f => f}
            />
            {selectedImg?.preview && (
              <Box>
                <VineaButton
                  color="secondary"
                  variant="body1"
                  data-testid="idCancel"
                  onClick={handleOnCancel}
                >
                  {t('Cancel')}
                </VineaButton>
                <VineaButton
                  color="secondary"
                  variant="contained"
                  data-testid="idSave"
                  onClick={handleOnSave}
                  disabled={loading}
                >
                  {t('Save')}
                </VineaButton>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LogoUploadCrop;
