import * as React from 'react';
import {
  useGridApiContext,
  // gridPageSelector,
  // gridPageCountSelector,
  // useGridSelector,
} from '@mui/x-data-grid-pro';
import Pagination from '@mui/material/Pagination';
import { useSelector } from 'react-redux';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';

export const CustomPagination = React.forwardRef(() => {
  const apiRef = useGridApiContext();
  const allIdentities = useSelector(VineaNovaSelectors.getSearchIdentityEntity);
  const { state } = apiRef.current;
  const { isLoading } = allIdentities;

  //   Reset pagination on loading new data
  React.useEffect(() => {
    if (
      isLoading &&
      apiRef.current &&
      state.pagination.page !== 0 &&
      state.pagination.pageCount !== 0
    ) {
      apiRef.current.setPage(0);
    }
  }, [isLoading, apiRef]);

  return (
    <Pagination
      color="primary"
      showFirstButton
      showLastButton
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
});
