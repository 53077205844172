/* eslint-disable */
import React from 'react';
import shortId from 'shortid';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import get from 'lodash/get';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import VineaAutoComplete from '../../components/ComboBox/ComboBox';
import { GreenCheckbox } from '../../components/CheckBox/Checkbox';
import VineaButton from '../../components/VineaButton/VineaButton';
import {
  getUserPreferences,
  getGrowerRegion,
  getGrowerSubRegion,
  getBusinessUnit,
  getVineyardsbyRegionID,
} from '../../redux/selectors';
import {
  landingPagesList,
  languageList,
  dateTimeFormat,
} from '../../constants';

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 300,
  },
}));

export const UserSettings = () => {
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const { basicSettings } = useSelector(state => getUserPreferences(state));
  const lkpBusinessUnit = useSelector(state => getBusinessUnit(state));
  const tlkpGrowingRegion = useSelector(state => getGrowerRegion(state));
  const tlkpSubGrowingRegion = useSelector(state => getGrowerSubRegion(state));
  const [data, setData] = React.useState({ filterPanelCollapsed: false });
  const today = new Date();
  const tlkpVineyards = useSelector(state =>
    getVineyardsbyRegionID(state, data.defaultSubRegionID),
  );
  const dateValue = format(today, dateTimeFormat);

  const subRegionOptions = tlkpSubGrowingRegion.filter(
    f => f.growingRegionID === data.defaultRegionID,
  );

  React.useEffect(() => {
    setData(basicSettings);
  }, [basicSettings]);

  const handleClose = () => {
    const pagePath = get(
      landingPagesList[data.landingPage],
      'path',
      'dashboard',
    );
    navigate(`/${pagePath}`);
  };

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    setData({ ...data, [name]: value });
  };

  const handleCheckboxChange = event => {
    const {
      target: { checked, name },
    } = event;
    setData({ ...data, [name]: checked });
  };

  const handleOnSave = () => {
    dispatchAPI({
      type: 'BASIC_SETTINGS_UPDATE',
      payload: { ...data, updatedAt: dateValue },
    });
    dispatchAPI({
      type: 'LANGUAGE_CHANGE',
      payload: data.language,
    });
    dispatchAPI({
      type: 'SETTINGS_UPDATE',
    });
    const pagePath = get(
      landingPagesList[data.landingPage],
      'path',
      'dashboard',
    );
    navigate(`/${pagePath}`);
  };

  const handleOnReset = () => {
    dispatchAPI({
      type: 'SETTINGS_COLUMN_PREFERENCES_RESET',
    });
  };

  return (
    <Grid container spacing={0} data-testid="user-settings" direction="column">
      <Paper
        elevation={0}
        data-testid="user-settings-paper"
        sx={{
          padding: theme.spacing(2),
        }}
      >
        <StyledFormBoxControl mt={1}>
          <FormControl
            sx={{
              marginTop: theme.spacing(1),
              minWidth: 250,
            }}
            margin="none"
          >
            <VineaAutoComplete
              value={data.landingPage}
              onChange={handleChange}
              options={landingPagesList}
              labelVariant="body1"
              label={t('Landing Page')}
              name={t('Landing Page')}
              inlineLabel
              inputProps={{
                name: 'landingPage',
              }}
            />
          </FormControl>
        </StyledFormBoxControl>
        <StyledFormBoxControl mt={1}>
          <FormControl
            sx={{
              marginTop: theme.spacing(1),
              minWidth: 250,
            }}
            margin="none"
          >
            <VineaAutoComplete
              value={data.language}
              onChange={handleChange}
              options={languageList}
              labelVariant="body1"
              label={t('Language')}
              name={t('Language')}
              inlineLabel
              inputProps={{
                name: 'language',
              }}
            />
          </FormControl>
        </StyledFormBoxControl>
        <StyledFormBoxControl mt={1}>
          <FormControl
            sx={{
              marginTop: theme.spacing(1),
              minWidth: 250,
            }}
            margin="none"
          >
            <VineaAutoComplete
              value={data.defaultRegionID}
              onChange={handleChange}
              options={tlkpGrowingRegion}
              labelVariant="body1"
              label={t('Default Region')}
              name={t('Default Region')}
              inlineLabel
              inputProps={{
                name: 'defaultRegionID',
              }}
            />
          </FormControl>
        </StyledFormBoxControl>
        <StyledFormBoxControl mt={1}>
          <FormControl
            sx={{
              marginTop: theme.spacing(1),
              minWidth: 250,
            }}
            margin="none"
          >
            <VineaAutoComplete
              value={data.defaultSubRegionID}
              onChange={handleChange}
              options={subRegionOptions}
              labelVariant="body1"
              label={t('Default Sub Region')}
              name={t('Default Sub Region')}
              inlineLabel
              inputProps={{
                name: 'defaultSubRegionID',
              }}
            />
          </FormControl>
        </StyledFormBoxControl>
        <StyledFormBoxControl mt={1}>
          <FormControl
            sx={{
              marginTop: theme.spacing(1),
              minWidth: 250,
            }}
            margin="none"
          >
            <VineaAutoComplete
              value={data.defaultVineyardID}
              onChange={handleChange}
              options={tlkpVineyards}
              labelVariant="body1"
              label={t('Default Vineyard')}
              name={t('Default Vineyard')}
              inlineLabel
              inputProps={{
                name: 'defaultVineyardID',
              }}
            />
          </FormControl>
        </StyledFormBoxControl>
        <StyledFormBoxControl mt={1}>
          <FormControl
            sx={{
              marginTop: theme.spacing(1),
              minWidth: 250,
            }}
            margin="none"
          >
            <VineaAutoComplete
              value={data.businessUnitID}
              onChange={handleChange}
              options={lkpBusinessUnit}
              labelVariant="body1"
              label={t('Business Unit')}
              name={t('Business Unit')}
              inlineLabel
              inputProps={{
                name: 'businessUnitID',
              }}
            />
          </FormControl>
        </StyledFormBoxControl>
        <StyledFormBoxControl mt={1}>
          <FormControlLabel
            label="Filter Panel Collapsed"
            data-testid="filterpanelsettings"
            control={
              <GreenCheckbox
                data-testid="filterpanelsettings"
                checked={data.filterPanelCollapsed}
                onChange={handleCheckboxChange}
                inputProps={{
                  'aria-label': 'primary checkbox',
                  name: 'filterPanelCollapsed',
                }}
              />
            }
          />
        </StyledFormBoxControl>
        <StyledFormBoxControl>
          <Typography variant="overline">
            Updated on: {data.updatedAt}
          </Typography>
        </StyledFormBoxControl>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
          }}
        >
          <Box
            mx={2}
            mt={1}
            sx={{
              display: 'flex',
            }}
          >
            <VineaButton
              variant="contained"
              color="primary"
              size="large"
              data-testid="btnsave"
              onClick={handleOnSave}
            >
              {t('Save')}
            </VineaButton>
          </Box>
          <Box
            mx={2}
            mt={1}
            sx={{
              display: 'flex',
            }}
          >
            <VineaButton
              variant="contained"
              color="secondary"
              size="large"
              data-testid="btnreset"
              onClick={handleOnReset}
            >
              {t('Reset')}
            </VineaButton>
          </Box>
          <Box mx={2} mt={1}>
            <VineaButton
              variant="outlined"
              color="secondary"
              size="large"
              data-testid="btnclose"
              onClick={handleClose}
            >
              {t('Close')}
            </VineaButton>
          </Box>
        </Grid>
      </Paper>
    </Grid>
  );
};
