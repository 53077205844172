/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import get from 'lodash/get';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import first from 'lodash/first';
import omit from 'lodash/omit';
import logger from '../../utils/winstonLogger';

// const apiUrl = 'https://okex00vdqa.execute-api.us-east-1.amazonaws.com/dev/api';
let apiUrl = '';
// const apiUrl = 'https://fn-vn-dev-common.azurewebsites.net/api/v1';
// const apiUrl = 'https://vn-mockserver-dev1.azurewebsites.net/api/v1';
// const mockApiUrl = 'http://localhost:3001/api/v1';
const mockApiUrl = 'https://vn-mockserver-dev1.azurewebsites.net/api/v1';

class ServiceConfigClass {
  initConfig(config) {
    this.config = config;
  }

  isTestEnv() {
    const isTest = get(this.config, 'environment.isTest', null);
    const isProd = get(this.config, 'environment.isProd', null);
    const isDev = !isTest && !isProd;
    const isLocalHost = get(this.config, 'environment.isLocalHost', null);
    return isTest || isDev || isLocalHost;
  }

  getBaseUri(serviceType) {
    // logger.debug('myserviceconfig', this.config);
    const userEmail = get(this.config, 'user.email', null);
    const isDemoUser =
      userEmail === 'VineaNovaDemoAdmin.com' && serviceType !== 'login';

    if (serviceType === 'mock' || isDemoUser) {
      apiUrl = mockApiUrl;
    } else if (serviceType === 'login') {
      apiUrl = this.isTestEnv()
        ? process.env.REACT_APP_COMMON_USER_API
        : process.env.REACT_APP_PROD_COMMON_USER_API;
    } else {
      apiUrl = this.isTestEnv()
        ? process.env.REACT_APP_CORE_API
        : process.env.REACT_APP_PROD_CORE_API;
    }
    return apiUrl;
  }

  onSuccess(response) {
    // logger.debug(this.config);
    return response.data;
  }

  onError(error) {
    // logger.debug(this.config);
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      logger.error('error:', { error: error.response });
    } else {
      // Something else happened while setting up the request
      // triggered the error
      // const errorMsg = error?.message;
      logger.error('Error Message:', error.message);
    }
    const errorValue = omit(error.response, ['config', 'headers', 'request']);
    return Promise.reject(errorValue);
  }

  request(options, serviceType) {
    // logger.debug('currentConfig', this.config);
    logger.debug('serviceType', { serviceType });
    const { accessToken, orgDetails } = this.config.user;
    const isTest = this.isTestEnv();
    if (isEmpty(accessToken) || isNil(accessToken) || isEmpty(orgDetails)) {
      throw new Error('serviceConfig -> No Access token available');
    }

    const { value } = orgDetails;
    const { displayName, id } = first(value);

    const baseUri = this.getBaseUri(serviceType);
    logger.debug(baseUri);
    const client = axios.create({
      baseURL: baseUri,
    });

    client.interceptors.request.use(
      config => {
        // Do something before request is sent
        const appCode = isTest
          ? process.env.REACT_APP_FUNCTION_CODE
          : process.env.REACT_APP_PROD_FUNCTION_CODE;
        if (config.params) {
          config.params.code = appCode;
        } else {
          config.params = {
            code: appCode,
          };
        }

        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers['x-orgname'] = displayName;
        config.headers['x-tid'] = id;
        // console.log('running interceptors', config);
        return config;
      },
      error => {
        // Do something with request error
        return Promise.reject(error);
      },
    );
    return client(options).then(this.onSuccess).catch(this.onError);
  }
}

const serviceConfig = new ServiceConfigClass();
export default serviceConfig;
