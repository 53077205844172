import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty, pickBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { VineaButton } from '../../../components/VineaButton';
import { VineaTextField } from '../../../components/TextField';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { Datepicker } from '../../../components/Datepicker';
import { DiscardDialog } from '../../../components/Dialog';
import {
  getSensorGridSelector,
  getLkpSensorStatus,
  getSensorBlocksGrid,
  getSensorBlockGroups,
} from '../../../redux/selectors';
import { formatDate } from '../../../constants/formatter';
import { viewDateFormat } from '../../../constants';
import { SensorBlocksTransferList } from '../../../components/IdentityComponents';
import { syncValidator } from '../../../utils/validator';
import { SensorSchema } from '../../VineyardIdentity/validations';

// The available options for sensor status dropdown
// These differ for each SensorStatusID that the sensor can have
const sensorStatusOptionsMap = {
  1: [1, 2, 4],
  2: [1, 2, 4],
  3: [1, 2, 3, 4],
  4: [1, 2, 4],
  5: [2, 4, 5],
};

export const ManageSensor = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();

  const [sensorData, setSensorData] = React.useState({ errors: {} });
  const [hasChanges, setHasChanges] = React.useState(false);
  const [statusOptions, setStatusOptions] = React.useState([]);

  const { id: dataConnectorId, sensorId } = useParams();
  const allSensors = useSelector(getSensorGridSelector);
  const { sensorName = '', siteName = '' } = sensorData;
  const lkpSensorStatus = useSelector(getLkpSensorStatus);
  const sensorBlocksGrid = useSelector(getSensorBlocksGrid);
  const sensorBlockGroups = useSelector(getSensorBlockGroups);

  const { isLoading: sensorsLoading } = useSelector(
    VineaNovaSelectors.getSensorsEntityMeta,
  );
  const { isLoading: blocksLoading } = useSelector(
    VineaNovaSelectors.getSensorBlocksEntityMeta,
  );

  const goBack = () => {
    navigate(-1);
  };

  const handleOnClose = () => {
    if (hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      goBack();
    }
  };

  const handleOnSave = () => {
    const validationErrors = syncValidator(SensorSchema)(sensorData);

    setSensorData({
      ...sensorData,
      errors: validationErrors,
    });

    if (!isEmpty(pickBy(validationErrors, v => v))) {
      setDiscardDialogOpen(false);
      return;
    }

    dispatchAPI(
      VineaNovaActions.api.v1.sensors.put.request({
        postBody: {
          SensorID: sensorData.id,
          ProviderSensorRef: sensorData.providerSensorRef,
          SensorName: sensorData.sensorName,
          FriendlySensorName: sensorData.friendlySensorName,
          UnitOfMeasureID: sensorData.unitOfMeasureID,
          SensorStatusID: sensorData.sensorStatusID,
          CollectionStartDate: sensorData.startDate,
          TimeZone: sensorData.timeZone,
          SensorTypeID: sensorData.sensorTypeID,
          LastSummaryImportDate: sensorData.lastSummaryImportDate,
          ts: sensorData.ts,
          VineyardBlockIDs: sensorData.blockIDs,
        },
      }),
    );

    goBack();
  };

  const handleOnChange = event => {
    const {
      target: { name, value },
    } = event;

    if (name === 'blockVintageIDs') {
      setSensorData({ ...sensorData, blockIDs: value });
    } else {
      setSensorData({ ...sensorData, [name]: value });
    }

    setHasChanges(true);
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  React.useEffect(() => {
    const data = allSensors.find(row => row.id === Number(sensorId));

    if (data) {
      setSensorData(data);
      const originalStatusID = data.sensorStatusID;
      const options = sensorStatusOptionsMap[originalStatusID]?.map(id =>
        lkpSensorStatus.find(s => s.id === id),
      );
      setStatusOptions(options);
    }
  }, [allSensors, lkpSensorStatus, sensorId]);

  React.useEffect(() => {
    if (sensorId) {
      dispatchAPI(
        VineaNovaActions.api.v1.sensorBlocks.get.request({
          queryParams: {
            SensorID: sensorId,
          },
        }),
      );
    }

    if (dataConnectorId) {
      dispatchAPI(
        VineaNovaActions.api.v1.sensors.get.request({
          queryParams: {
            DataConnectorID: dataConnectorId,
          },
        }),
      );
    }
  }, [sensorId, dataConnectorId, dispatchAPI]);

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{ paddingX: theme.spacing(4), paddingY: theme.spacing(2) }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 5,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ width: '50%' }}>
                  <FormControl fullWidth>
                    <VineaAutoComplete
                      label={t('Status')}
                      value={sensorData.sensorStatusID}
                      onChange={handleOnChange}
                      options={statusOptions}
                      labelVariant="body1"
                      name="sensorStatusID"
                      error={sensorData?.errors?.sensorStatusID}
                      inputProps={{
                        name: 'sensorStatusID',
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: '50%' }}>
                  <FormControl fullWidth>
                    <Datepicker
                      label={t('Start Date')}
                      placeholder="DD/MM/YYYY"
                      inputFormat={viewDateFormat}
                      formatDate={formatDate}
                      name="startDate"
                      disablePast={false}
                      value={sensorData?.startDate}
                      error={sensorData?.errors?.startDate}
                      onChange={handleOnChange}
                      fullWidth
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: '50%' }}>
                  <FormControl fullWidth>
                    <VineaTextField
                      label={t('Type')}
                      value={sensorData.sensorType}
                      fullWidth
                      disabled
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 5,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ width: '50%' }}>
                  <FormControl fullWidth>
                    <VineaTextField
                      label={t('Name')}
                      value={sensorData.sensorName}
                      fullWidth
                      disabled
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: '50%' }}>
                  <FormControl fullWidth>
                    <VineaTextField
                      label={t('Known As')}
                      name="friendlySensorName"
                      value={sensorData.friendlySensorName}
                      onChange={handleOnChange}
                      fullWidth
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: '50%' }}>
                  <FormControl fullWidth>
                    <VineaTextField
                      label={t('Units')}
                      value={sensorData.sensorMeasureUnit}
                      fullWidth
                      disabled
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flex: 2 }}>
              <Typography variant="p">{t('Select related blocks')}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SensorBlocksTransferList
            allBlocks={sensorBlocksGrid}
            selectedBlockIDs={sensorData?.blockIDs}
            isLoading={sensorsLoading || blocksLoading}
            groups={sensorBlockGroups}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <Box mx={2} mt={1}>
            <VineaButton
              variant="contained"
              color="success"
              onClick={handleOnSave}
            >
              {t('Save')}
            </VineaButton>
          </Box>
          <Box mx={2} mt={1}>
            <VineaButton
              variant="outlined"
              color="secondary"
              onClick={handleOnClose}
            >
              {t('Close')}
            </VineaButton>
          </Box>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={handleOnDialogClose}
            handleSaveChanges={handleOnSave}
            handleDiscardChanges={goBack}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
