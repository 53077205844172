/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/named */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { VineaNovaActions } from 'vineanova-redux-artifacts';

import { VineaButton } from '../../../components/VineaButton';
import { FieldLabelInput } from '../../../components/TextField';
import { DiscardDialog } from '../../../components/Dialog';
import { VineaAutoComplete } from '../../../components/ComboBox';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';
import { useDispatch } from 'react-redux';
import { IdentityTypeIds } from '../../../constants';
import { isNil } from 'lodash';

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 200,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
  '& .MuiInputBase-input': {
    minWidth: 200,
  },
}));

export function ActivityMappingDrawer({
  isOpen,
  handleOnClose,
  handleOnSave,
  handleOnChange,
  data = {},
  selectedIdentityActivityID,
  activityOptions,
  hasChanges,
}) {
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const onSave = () => {
    // Need to check that valid identity activity has been chosen
    handleOnSave(data);
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnDiscard = () => {
    if (hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      handleOnClose();
    }
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  // Get all active identity activities on load
  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.searchIdentity.post.request({
        postBody: {
          IdentityTypeID: IdentityTypeIds.ACTIVITY,
          FindNonActive: false,
        },
      }),
    );
  }, []);

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={handleOnClickAway}
      >
        <Box sx={{ width: 350 }} role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography sx={{ padding: 2 }} variant="body1">
                {t('Map Activity Code')}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOnDiscard}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            {t('Default Rate')}
          </Typography>

          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />

            <Box my={2}>
              <StyledFormBoxControl mt={1}>
                <FormControl>
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="activityCode"
                    name="activityCode"
                    label={t('Activity Code')}
                    size="large"
                    inlineLabel
                    value={data?.activityCode}
                    disabled={true}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl>
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="unmatchedTransactionCount"
                    name="unmatchedTransactionCount"
                    label={t('Unmatched Transactions')}
                    size="large"
                    inlineLabel
                    value={
                      isNil(data?.unmatchedTransactionCount) ||
                      data?.unmatchedTransactionCount === 0
                        ? '0'
                        : data?.unmatchedTransactionCount
                    }
                    disabled={true}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl margin="none">
                  <VineaAutoComplete
                    value={selectedIdentityActivityID}
                    onChange={handleOnChange}
                    inlineLabel
                    options={activityOptions}
                    labelVariant="body1"
                    label={t('Matching Code')}
                    name={t('MatchingCode')}
                    displayLabel
                    inputProps={{
                      name: 'identityActivityID',
                    }}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flex: 1,
                  margin: 2,
                }}
              >
                <VineaButton
                  color="success"
                  onClick={onSave}
                  variant="contained"
                  minWidth={150}
                >
                  {t('Save')}
                </VineaButton>
                <VineaButton
                  color="secondary"
                  onClick={handleOnDiscard}
                  variant="outlined"
                  minWidth={150}
                >
                  {t('Close')}
                </VineaButton>
              </Box>
            </Box>
          </ErrorBoundary>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={() => setDiscardDialogOpen(false)}
            handleSaveChanges={handleOnDiscardSaveChanges}
            handleDiscardChanges={handleOnDiscardConfirm}
          />
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
}
