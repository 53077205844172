import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  get } from 'lodash';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { blobToFile, dataUrlToBlob } from '../../../utils/cropImageUtils';
import logger from '../../../utils/winstonLogger';
import useImageUtils from '../../../hooks/ImageUtils';

export const useProfileImageUpload = (identityTypeId, identityId) => {
  const [documentObject, setDocumentObject] = React.useState(null);
  const [documentId, setDocumentId] = React.useState(null);
  const { imageDataUrl } = useImageUtils(documentObject);

  const [imageUploadInProgress, setImageUploadInProgress] =
    React.useState(false);
  const [imageDeleteInProgress, setImageDeleteInProgress] =
    React.useState(false);
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // Profile Photo Image meta data
  const { isLoading, hasError, isLoaded, error } = useSelector(
    VineaNovaSelectors.getObjectLogoEntityMeta,
  );

  const identityObjectLogo = useSelector(state => state.entities.objectLogo);
  const imageIdentityID = get(identityObjectLogo, 'data.sourceObjectID');
  const IDENTITY_OBJ_TYPE = 1;

  React.useEffect(() => {
    dispatchAPI(VineaNovaActions.api.v1.objectLogo.get.cleardata());
    dispatchAPI(
      VineaNovaActions.api.v1.objectLogo.get.request({
        queryParams: {
          //   // TODO: replace block id
          SourceObjectID: identityId, // identity ID
          ObjectTypeID: IDENTITY_OBJ_TYPE, // identity
        },
      }),
    );
  }, [identityTypeId, identityId, dispatchAPI]);

  React.useEffect(() => {
    logger.debug('object logo');
    const documentObjectFromRedux = get(
      identityObjectLogo,
      'data.documentObject',
      null,
    );
    const documentIdFromRedux = get(
      identityObjectLogo,
      'data.documentID',
      null,
    );
    setDocumentObject(documentObjectFromRedux);
    setDocumentId(documentIdFromRedux);
  }, [identityObjectLogo]);

  React.useEffect(() => {
    if (imageUploadInProgress) {
      if (!isLoading && isLoaded) {
        if (hasError) {
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
        }
        dispatchAPI(
          VineaNovaActions.api.v1.objectLogo.get.request({
            queryParams: {
              SourceObjectID: identityId, // identity ID
              ObjectTypeID: IDENTITY_OBJ_TYPE, // identity
            },
          }),
        );
        setImageUploadInProgress(false);
      }
    }
  }, [
    imageUploadInProgress,
    isLoading,
    isLoaded,
    hasError,
    error,
    dispatchAPI,
    enqueueSnackbar,
    identityId,
    identityTypeId,
    t,
  ]);

  React.useEffect(() => {
    if (imageDeleteInProgress) {
      if (!isLoading && isLoaded) {
        if (hasError) {
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
        }
        setImageUploadInProgress(false);
      }
    }
  }, [
    imageDeleteInProgress,
    isLoading,
    isLoaded,
    hasError,
    error,
    enqueueSnackbar,
    t,
  ]);

  /**
   * callback to save the profile photo images
   */
  const handleSaveCroppedImage = React.useCallback(
    imagedata => {
      const { image, fileName } = imagedata;
      // eslint-disable-next-line no-undef
      const formUploadData = new FormData();

      const blobObject = dataUrlToBlob(image);
      const fileObject = blobToFile(blobObject, fileName);
      formUploadData.append('image', fileObject);
      if (!documentId) {
        dispatchAPI(
          VineaNovaActions.api.v1.objectLogo.post.request({
            queryParams: {
              SourceObjectID: identityId, // identity ID
              ObjectTypeID: IDENTITY_OBJ_TYPE, // identity
              SubjectDate: new Date().toISOString(), // today's date
              DocumentTypeID: 2, // from lookupDocumentType
              SourceFile: fileName,
            },
            postBody: formUploadData,
            options: {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formUploadData,
            },
          }),
        );
      } else {
        dispatchAPI(
          VineaNovaActions.api.v1.objectLogo.put.request({
            queryParams: {
              DocumentID: documentId, // identity
            },
            postBody: formUploadData,
            options: {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: formUploadData,
            },
          }),
        );
      }
      setImageUploadInProgress(true);
    },
    [dispatchAPI, identityId, identityTypeId, documentId],
  );

  const handleOnDeleteImage = React.useCallback(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.objectLogo.delete.request({
        postBody: {
          SourceObjectID: 0, // identity ID
          ObjectTypeID: 0, // identity
          SubjectDate: new Date().toISOString(), // today's date
          DocumentTypeID: 2, // from lookupDocumentType
          SourceFile: '',
          documentId,
        },
      }),
    );
    setImageDeleteInProgress(true);
  }, [dispatchAPI, documentId]);

  return {
    handleSaveCroppedImage,
    handleOnDeleteImage,
    imageDataUrl,
    imageIdentityID,
  };
};
