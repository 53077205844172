import { React } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import { LandingHeader } from '../Header';
import { ReactComponent as Logo } from '../../assets/logo-inverse.svg';

const Unauthorized = ({ message, logout }) => {
  const { line1, line2, line3 } = message;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
    logout();
  };

  return (
    <Paper
      data-testid="unauthorised-parent-paper"
      elevation={0}
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
      }}
    >
      <Box
        data-testid="unauthorised-appbar"
        sx={{ color: 'palette.primary.contrastText' }}
      >
        <LandingHeader login={handleClick} />
      </Box>
      <Stack
        data-testid="unauthorised-middle-stack"
        alignItems="center"
        justifyContent="center"
        height="100%"
        backgroundColor="white"
        sx={{ p: 3 }}
      >
        <Stack
          data-testid="unauthorised-inner-stack"
          direction="row"
          sx={{ justifyContent: 'center', flexWrap: 'wrap', gap: 12 }}
        >
          <SvgIcon
            component={Logo}
            viewBox="0 0 512 512"
            sx={{
              height: 200,
              width: 200,
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            maxWidth="calc(600px + 20vh)"
          >
            <Typography
              fontWeight={500}
              fontSize={48}
              lineHeight="50px"
              marginBottom={5}
            >
              {line1}
            </Typography>
            <Typography fontSize={24} lineHeight="30px">
              {line2}
            </Typography>
            <Typography
              fontSize={24}
              lineHeight="30px"
              style={{ marginTop: '50px' }}
            >
              {line3}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

Unauthorized.propTypes = {
  message: PropTypes.shape({
    line1: PropTypes.string.isRequired,
    line2: PropTypes.string.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
};

export default Unauthorized;
