import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format, isValid, parse, isBefore } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import shortId from 'shortid';
import get from 'lodash/get';

import find from 'lodash/find';
import isNull from 'lodash/isNull';
import {
  getLookupReferencesByOrgId,
  // getFormWriteData,
} from '../../../redux/selectors';
import { ReferenceSchema } from '../validations';
import { syncValidator } from '../../../utils/validator';
import { validDateFormat } from '../../../utils/DateUtil';
import {
  reducers,
  sagaActionTypes,
  vineaDetails,
  actionTypes,
  IdentityTypeIds,
  apiTypes,
  dateFormat,
} from '../../../constants';
import logger from '../../../utils/winstonLogger';

const useIdReferenceHook = (
  identityTypeId = IdentityTypeIds.VINEYARD,
  identityId = null,
  setDisableIcon = f => f,
) => {
  // state
  const [previous, setPrevious] = useState([]);
  const [identityReferences, setIdentityReferences] = useState([]);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [seqToSave, setSeqToSave] = useState(null);
  const [formLoadingStatus, setFormLoadingStatus] = useState(false);
  const [alert, setAlert] = useState('');

  // dispatches;
  const dispatchAPI = useDispatch();
  //   const navigate = useNavigate();
  const { id: pathParamIdentityId = identityId } = useParams();

  // selectors
  const lkpReferences = useSelector(state =>
    getLookupReferencesByOrgId(state, identityTypeId),
  );
  const {
    isLoading,
    hasError,
    isLoaded,
    data: referencesData,
  } = useSelector(state => state.identityReferences);
  const {
    isLoading: formLoading,
    hasError: formError,
    isLoaded: formLoaded,
    data: formWriteData,
  } = useSelector(state => state.formWrite);

  useEffect(() => {
    if (formLoading && actionTriggered) setFormLoadingStatus(true);
    if (
      !formLoading &&
      formLoaded &&
      !isEmpty(formWriteData) &&
      !formError &&
      formLoadingStatus
    ) {
      setFormLoadingStatus(false);
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
    }
  }, [
    formLoading,
    actionTriggered,
    formLoaded,
    formWriteData,
    formError,
    formLoadingStatus,
    dispatchAPI,
  ]);

  // useEffects
  useEffect(() => {
    if (isLoading || hasError || !isLoaded) {
      setIdentityReferences([]);
    } else {
      const newRows = referencesData.map(f => ({
        id: f.id,
        referenceTypeID: f.referenceTypeID,
        referenceType: f.referenceType,
        referenceValue: f.referenceValue,
        effectiveFromDate: f.effectiveFromDate,
        effectiveToDate: f.effectiveToDate,
        isEditMode: false,
        methodType: f.methodType || apiTypes.PUT,
        errors: {},
        ts: f.ts,
      }));
      setIdentityReferences(newRows);
    }
  }, [referencesData, isLoading, hasError, isLoaded]);

  const onAddNewReference = useCallback(() => {
    const newRows = identityReferences.concat([
      {
        id: shortId.generate(),
        referenceTypeID: 0,
        referenceType: '',
        referenceValue: '',
        effectiveFromDate: format(new Date(), dateFormat),
        effectiveToDate: null,
        isEditMode: true,
        methodType: apiTypes.POST,
        errors: {},
        ts: null,
      },
    ]);
    setIdentityReferences(newRows);
    setDisableIcon(true);
  }, [identityReferences, setDisableIcon]);

  /** changes to the row data */
  const handleOnChangeRowData = useCallback(
    (e, rowdata) => {
      const {
        target: { value, name },
      } = e;
      const { id } = rowdata;
      const isFromDateModified = name === 'effectiveFromDate';

      const newRowsNameValue = identityReferences.map(row => {
        if (row.id === id) {
          const { warning, ...restCols } = row;
          return {
            ...restCols,
            effectiveToDate: isFromDateModified ? null : row.effectiveToDate,
            [name]: value,
          };
        }
        return row;
      });

      let newRows = newRowsNameValue.map(row => {
        if (id === row.id) {
          const { warning, errors, ...restCols } = row;
          const validationErrors = syncValidator(ReferenceSchema)(row);
          let newRowValue = {};

          if (!isEmpty(errors)) {
            newRowValue = {
              ...restCols,
              errors: validationErrors,
            };
          } else {
            newRowValue = {
              ...restCols,
              errors,
            };
          }

          return newRowValue;
        }
        return row;
      });

      // duplicate row validation
      const currentRowData = newRows.find(f => f.id === id);

      const duplicateRows = newRows.filter(
        f =>
          f.referenceTypeID === currentRowData.referenceTypeID &&
          f.id !== currentRowData.id &&
          ((f.effectiveToDate >= currentRowData.effectiveFromDate &&
            f.effectiveFromDate <= currentRowData.effectiveFromDate &&
            currentRowData.effectiveFromDate !== null &&
            f.effectiveToDate !== null &&
            f.effectiveFromDate !== '') ||
            (f.effectiveFromDate <= currentRowData.effectiveFromDate &&
              f.effectiveToDate === null &&
              currentRowData.effectiveFromDate !== null) ||
            (f.effectiveFromDate >= currentRowData.effectiveFromDate &&
              currentRowData.effectiveToDate === null &&
              currentRowData.effectiveFromDate !== null) ||
            (f.effectiveToDate >= currentRowData.effectiveToDate &&
              f.effectiveFromDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate !== null &&
              f.effectiveFromDate !== null &&
              f.effectiveToDate !== null) ||
            (f.effectiveFromDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate === null) ||
            (f.effectiveFromDate >= currentRowData.effectiveFromDate &&
              f.effectiveToDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate !== null &&
              currentRowData.effectiveFromDate !== null &&
              f.effectiveFromDate !== null &&
              f.effectiveToDate !== null)),
      );
      // update validation errors
      const hasDuplicateRowValue = duplicateRows.length > 0;
      if (hasDuplicateRowValue) {
        newRows = identityReferences.map(row => {
          if (id === row.id) {
            return {
              ...currentRowData,
              warning:
                'You cannot create a duplicate record. Please, update details or reuse the existing one.',
            };
          }
          return row;
        });
      }

      if (name === 'referenceTypeID') {
        newRows = newRows.map(row => {
          if (row.id === id) {
            const typeName = get(
              lkpReferences.find(f => f.id === value),
              'value',
              null,
            );
            return { ...row, referenceType: typeName };
          }
          return row;
        });
      }

      if (name && ['effectiveFromDate', 'effectiveToDate'].includes(name)) {
        const currentRow = newRows.find(f => f.id === id);
        const { effectiveFromDate, effectiveToDate } = currentRow || {};
        
        const fromDate = isValid(
          validDateFormat(effectiveFromDate),
        )
          ? validDateFormat(effectiveFromDate)
          : null;
        const toDate = isValid(validDateFormat(effectiveToDate))
          ? validDateFormat(effectiveToDate)
          : null;
        const isBeforeStartDate =
          fromDate && toDate && isBefore(toDate, fromDate);

        newRows = newRows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              errors: isBeforeStartDate ? { effectiveToDate: true } : {},
            };
          }
          return row;
        });
      }

      // final update
      setIdentityReferences(newRows);
    },
    [identityReferences, lkpReferences],
  );

  const onToggleEditMode = useCallback((id, rows) => {
    setPrevious(rows);
    setIdentityReferences(() => {
      return rows.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  }, []);

  const handleRevert = useCallback(
    id => {
      let newRows;
      const currentRow = find(identityReferences, f => f.id === id);
      if (isNull(currentRow.ts)) {
        newRows = identityReferences.filter(f => f.id !== id);
      } else {
        newRows = identityReferences.map(row => {
          if (row.id === id) {
            const prevState = find(previous, f => f.id === id);
            return {
              ...row,
              referenceTypeID: prevState.referenceTypeID,
              referenceType: prevState.referenceType,
              referenceValue: prevState.referenceValue,
              effectiveFromDate: prevState.effectiveFromDate,
              effectiveToDate: prevState.effectiveToDate,
              warning: '',
            };
          }
          return row;
        });
      }
      setIdentityReferences(newRows);
      onToggleEditMode(id, newRows);
    },
    [identityReferences, onToggleEditMode, previous],
  );

  const handleOnSaveRow = id => {
    const dataToSave = identityReferences.find(f => f.id === id);
    const dataToValidate = {
      referenceType: dataToSave.referenceTypeID,
      referenceValue: dataToSave.referenceValue,
    };
    const validationErrors = syncValidator(ReferenceSchema)(dataToValidate);
    if (!isEmpty(dataToSave.errors)) {
      logger.error('cannot save duplicate data');
    } else if (!isEmpty(validationErrors)) {
      const newRows = identityReferences.map(row => {
        if (row.id === id) {
          return { ...row, errors: validationErrors };
        }
        return row;
      });
      setIdentityReferences(newRows);
    } else {
      let timeStamp;
      if (!isNull(referencesData) && dataToSave.methodType === apiTypes.PUT) {
        const selectedRecord = find(referencesData, f => f.id === id);
        timeStamp = selectedRecord.ts;
      } else timeStamp = null;
      let data = {};
      if (dataToSave.methodType === apiTypes.POST) {
        data = {
          IdentityID: pathParamIdentityId,
          referenceValue: dataToSave.referenceValue,
          referenceTypeID: dataToSave.referenceTypeID,
          effectiveFromDate: dataToSave.effectiveFromDate,
          effectiveToDate: dataToSave.effectiveToDate,
        };
      } else {
        data = {
          ID: id,
          IdentityID: pathParamIdentityId,
          referenceValue: dataToSave.referenceValue,
          referenceTypeID: dataToSave.referenceTypeID,
          effectiveFromDate: dataToSave.effectiveFromDate,
          effectiveToDate: dataToSave.effectiveToDate,
          ts: timeStamp,
        };
      }

      dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.reference,
          methodType: dataToSave.methodType,
        },
      });
      setSeqToSave(id);
      setActionTriggered(true);
      if (dataToSave.methodType === 'PUT') {
        setAlert('Update');
      } else {
        setAlert('Created');
      }
    }
  };

  const handleOnDeleteRow = useCallback(
    id => {
      const selectedRecord = find(identityReferences, f => f.id === id);
      if (isEmpty(selectedRecord.ts) || isNull(selectedRecord.ts)) {
        const updatedRows = identityReferences.filter(f => f.id !== id);
        setIdentityReferences(updatedRows);
      } else {
        const data = {
          ID: id,
          ts: selectedRecord.ts,
        };
        dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.reference,
            methodType: apiTypes.DELETE,
          },
        });

        setActionTriggered(true);
        setAlert('Delete');
      }
    },
    [dispatchAPI, identityReferences],
  );

  return {
    lkpReferences,
    identityId: pathParamIdentityId,
    identityReferences,
    setIdentityReferences,
    onAddNewReference,
    isLoadingIdReference: isLoading,
    hasErrorIdReference: hasError,
    handleOnChangeRowData,
    onToggleEditMode,
    handleRevert,
    handleOnSaveRow,
    setSeqToSave,
    setActionTriggered,
    actionTriggered,
    seqToSave,
    alert,
    handleOnDeleteRow,
  };
};

export { useIdReferenceHook };
