/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { styled } from '@mui/material/styles';

export const StyledInput = styled('input')(() => ({ display: 'none' }));

export const FileUpload = ({ handleOnChange, acceptedFileExtensions }) => {
  const handleChange = e => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      if (file) {
        getBase64(file).then(base64 => {
          handleOnChange(file, base64);
        });
      }
    }
  };

  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = '';
      // Make new FileReader
      // eslint-disable-next-line no-undef
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <>
      <label htmlFor="contained-button-file">
        <Grid container justifyContent="center" alignItems="center">
          <Fab component="span">
            <NoteAddIcon />
          </Fab>
        </Grid>
        <StyledInput
          accept={acceptedFileExtensions}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleChange}
        />
      </label>
    </>
  );
};

FileUpload.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  acceptedFileExtensions: PropTypes.string.isRequired,
};
