import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { isEqual, isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { DefaultRateGrid } from '../../components/Grid';
import {
  getUserPreferences,
  getDefaultRateSelector,
  getIdentityOverviewDetailsFromContact,
} from '../../redux/selectors';
import { DefaultRatesDrawer } from './DefaultRatesDrawer';
import { useSnackbar } from 'notistack';

// import { commonRoutePropType } from '../../utils/commonPropTypes';

// eslint-disable-next-line react/prop-types
const ActivityDefaultRatesComponent = ({ pathParamId }) => {
  const { t } = useTranslation();
  //   const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedRow, setSelectedRow] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [open, setOpen] = useState(false);
  const [noEffectiveDateError, setNoEffectiveDateError] = useState(false);
  const [overLapError, setOverLapError] = useState(false);
  const [manage, setManage] = useState(false);
  const [add, setAdd] = useState(false);
  const [apiTrigger, setApiTrigger] = useState(false);
  const [overLapDates, setOverLapDates] = useState([]);

  const dataGridColumnsPreferences = useSelector(state =>
    getUserPreferences(state),
  );
  const defaultRateData = useSelector(getDefaultRateSelector);
  const { isLoading, isLoaded, hasError } = useSelector(
    VineaNovaSelectors.getActivityDefaultRatesEntityMeta,
  );
  const {
    activityWorkUnit,
    activityCostingUnit,
    activityDuration,
    activityWorkUnitSingle,
  } = useSelector(state => getIdentityOverviewDetailsFromContact(state));

  const handleOnAdd = () => {
    setOverLapError(false);
    const noEffectiveDateDefaultRate = defaultRateData.find(
      d => d.effectiveToDate === '',
    );
    const noEffectiveToDates = defaultRateData.some(
      d => d.effectiveToDate === '',
    );
    if (noEffectiveToDates) {
      setSelectedRow(noEffectiveDateDefaultRate);
      setNoEffectiveDateError(true);
      setOpen(true);
    } else {
      setSelectedRow({});
      setAdd(true);
      setNoEffectiveDateError(false);
      setOpen(true);
    }
  };

  const handleOnManage = e => {
    setSelectedRow(e.row);
    setOpen(true);
    setManage(true);
  };

  const handleOnSave = () => {
    if (manage || isEmpty(overLapDates) || noEffectiveDateError || add) {
      if (noEffectiveDateError || manage) {
        setNoEffectiveDateError(false);
        setOverLapError(false);
        setManage(false);
        const oldData = defaultRateData.find(d => d.id === selectedRow.id);
        dispatchAPI(
          VineaNovaActions.api.v1.activityDefaultRates.put.request({
            postBody: {
              ...oldData,
              activityID: Number(pathParamId),
              defaultRateNote: selectedRow.internalWorkCost || '',
              effectiveFromDate: new Date(selectedRow.effectiveFromDate),
              effectiveToDate:
                selectedRow.effectiveToDate === ''
                  ? ''
                  : new Date(selectedRow.effectiveToDate),
              defaultCostRate: Number(selectedRow.defaultCostRate) || 0,
              defaultChargeOutRate:
                Number(selectedRow.defaultChargeOutRate) || 0,
              targetVelocity: Number(selectedRow.targetVelocity) || 0,
            },
          }),
        );
        setOpen(false);
        setApiTrigger(true);
        setHasChanges(false);
      } else {
        dispatchAPI(
          VineaNovaActions.api.v1.activityDefaultRates.post.request({
            postBody: {
              activityID: Number(pathParamId),
              defaultRateNote: Number(selectedRow.internalWorkCost),
              effectiveFromDate: new Date(selectedRow.effectiveFromDate),
              effectiveToDate: new Date(selectedRow.effectiveToDate),
              defaultCostRate: Number(selectedRow.defaultCostRate),
              defaultChargeOutRate: Number(selectedRow.defaultChargeOutRate),
              targetVelocity: Number(selectedRow.targetVelocity),
            },
          }),
        );
        setOpen(false);
        setAdd(false);
        setApiTrigger(true);
        setHasChanges(false);
      }
    }
  };

  const handleOnDelete = () => {
    if (!isEmpty(defaultRateData)) {
      const oldData = defaultRateData.find(d => d.id === selectedRow.id);
      dispatchAPI(
        VineaNovaActions.api.v1.activityDefaultRates.delete.request({
          postBody: {
            ...oldData,
            activityID: Number(pathParamId),
            defaultRateNote: selectedRow.internalWorkCost,
            effectiveFromDate: new Date(selectedRow.effectiveFromDate),
            effectiveToDate:
              selectedRow.effectiveToDate === ''
                ? ''
                : new Date(selectedRow.effectiveToDate),
            defaultCostRate: Number(selectedRow.defaultCostRate),
            defaultChargeOutRate: Number(selectedRow.defaultChargeOutRate),
            targetVelocity: Number(selectedRow.targetVelocity),
          },
        }),
      );
      setSelectedRow({});
      setOpen(false);
      setApiTrigger(true);
      setHasChanges(false);
    }
  };

  const handleOnCloseDrawer = () => {
    setOpen(false);
    setManage(false);
  };

  const handleOnChange = event => {
    const {
      target: { name, value },
    } = event;
    const overLap = defaultRateData.filter(
      f =>
        (f.effectiveToDate >= selectedRow.effectiveFromDate &&
          f.effectiveFromDate <= selectedRow.effectiveFromDate &&
          selectedRow.effectiveFromDate !== null &&
          f.effectiveToDate !== null &&
          f.effectiveFromDate !== '') ||
        (f.effectiveFromDate <= selectedRow.effectiveFromDate &&
          f.effectiveToDate === null &&
          selectedRow.effectiveFromDate !== null) ||
        (f.effectiveFromDate >= selectedRow.effectiveFromDate &&
          selectedRow.effectiveToDate === null &&
          selectedRow.effectiveFromDate !== null) ||
        (f.effectiveToDate >= selectedRow.effectiveToDate &&
          f.effectiveFromDate <= selectedRow.effectiveToDate &&
          selectedRow.effectiveToDate !== null &&
          f.effectiveFromDate !== null &&
          f.effectiveToDate !== null) ||
        (f.effectiveFromDate <= selectedRow.effectiveToDate &&
          selectedRow.effectiveToDate === null) ||
        (f.effectiveFromDate >= selectedRow.effectiveFromDate &&
          f.effectiveToDate <= selectedRow.effectiveToDate &&
          selectedRow.effectiveToDate !== null &&
          selectedRow.effectiveFromDate !== null &&
          f.effectiveFromDate !== null &&
          f.effectiveToDate !== null),
    );
    setOverLapDates(overLap);
    if (!isEmpty(overLap) && !noEffectiveDateError && !manage) {
      if (name === effectiveToDate) {
        setNoEffectiveDateError(false);
      } else {
        setOverLapError(true);
      }
    }
    setSelectedRow({ ...selectedRow, [name]: value });
    setHasChanges(true);
  };

  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.activityDefaultRates.get.request({
        queryParams: { ActivityID: pathParamId },
      }),
    );
  }, [pathParamId]);

  useEffect(() => {
    if (isLoaded && !isLoading && apiTrigger) {
      setApiTrigger(false);
      if (!hasError) {
        enqueueSnackbar(t('Success'), { variant: 'success' });
        dispatchAPI(
          VineaNovaActions.api.v1.activityDefaultRates.get.request({
            queryParams: { ActivityID: pathParamId },
          }),
        );
      } else {
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }
    }
  }, [isLoading, isLoaded, apiTrigger]);

  return (
    <>
      <Box data-testid="grid-blocks" sx={{ height: '100%', padding: 3 }}>
        <DefaultRateGrid
          heading={t('Default Rate')}
          add={handleOnAdd}
          hasAddOption
          tableData={defaultRateData}
          pathParamId={pathParamId}
          isLoading={isLoading}
          onRowClick={handleOnManage}
          gridColumnState={
            dataGridColumnsPreferences.activityDefaultRateDataGrid
          }
          activityWorkUnit={activityWorkUnit}
          activityCostingUnit={activityCostingUnit}
          activityDuration={activityDuration}
          activityWorkUnitSingle={activityWorkUnitSingle}
        />
      </Box>
      <DefaultRatesDrawer
        isOpen={open}
        handleOnSave={handleOnSave}
        handleOnClose={handleOnCloseDrawer}
        handleOnChange={handleOnChange}
        handleOnDelete={handleOnDelete}
        data={selectedRow}
        hasChanges={hasChanges}
        activityWorkUnit={activityWorkUnit}
        activityCostingUnit={activityCostingUnit}
        activityDuration={activityDuration}
        noEffectiveDateError={noEffectiveDateError}
        overLapError={overLapError}
        isManage={manage}
      />
    </>
  );
};

function pathNameAreEqual(prevProps, nextProps) {
  return isEqual(prevProps.pathParamId, nextProps.pathParamId);
}

export const ActivityDefaultRates = React.memo(
  ActivityDefaultRatesComponent,
  pathNameAreEqual,
);
