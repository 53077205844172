import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './ColorPickerComponent.css';

export default function ColorPickerComponent(props: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { color, onChange, label = 'Color' } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChange = (newColor: string) => {
    onChange(newColor);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <Typography variant="body1" sx={{ marginRight: 1 }}>
        {label}
      </Typography>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{
          backgroundColor: color,
          color: 'white',
          // borderRadius: 4,
          overflow: 'hidden',
        }}
        title="Pick a Color"
      >
        {color}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        data-testid="detail-popver"
        style={{
          height: 'auto',
          overflow: 'hidden !important',
        }}
      >
        <HexColorPicker color={color} onChange={handleChange} />
      </Popover>
    </Box>
  );
}
