/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Paper, IconButton, Stack } from '@mui/material';
import { isEmpty, isNull, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { format } from 'date-fns';

import { VineaButton } from '../../../components/VineaButton';
import {
  areLookupsLoading,
  getUserPreferences,
} from '../../../redux/selectors';
import { useTrackingHook } from '../Hooks/useTrackingHook';
import { OtherAttributeDrawer } from '../Drawers';
import { dateFormat } from '../../../constants';
import { OtherAttributeGridView } from './OtherAttributeGridView';

export const OtherAttributesContainer = ({
  isVineyardBlockScreen = false,
  dataColumns,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const {
    cropBlockSettingEntityData,
    vintageBlockSelected,
    errorInSubmitPhenology,
    setErrorInSubmitPhenology,
    handleOnUpdateCropSettingsData,
    lkpPhenologyMeasure,
    searchFilterData,
    lkpCropSettingOption,
    lkpCropSettingInstance,
    isLoadingData,
    phenologyNoteImage,
    setPhenologyNoteImage,
    handleOnDeleteBulk,
  } = useTrackingHook();

  const buttonDisabled = useMemo(() => {
    if (isVineyardBlockScreen) return false;
    else return !isEmpty(selectionModel) || isEmpty(vintageBlockSelected.block);
  }, [selectionModel, blockSelected]);

  const gridXData = {
    columns: dataColumns,
    rows: cropBlockSettingEntityData,
  };

  const handleOnCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const usedAttributeOptions = map(
    cropBlockSettingEntityData,
    'cropSettingOption',
  );

  const usedAttributeInstance = map(
    cropBlockSettingEntityData,
    'cropSettingInstance',
  );
  const isLoadingLookups = useSelector(areLookupsLoading);
  const { trackingDataGrid, basicSettings } = useSelector(state =>
    getUserPreferences(state),
  );

  const { vineyard: vineyardSelected, block: blockSelected } =
    vintageBlockSelected;

  const subTitle1 = `${t('Attributes of')} ${vineyardSelected || ''} ${
    blockSelected ? '/' : ''
  } ${blockSelected || ''}`;

  // open drawer and reset upload image references
  const handleOnDrawerOpen = () => {
    setDrawerOpen(true);
    setPhenologyNoteImage(null);
  };

  const handleOnSaveCropSettingData = data => {
    handleOnUpdateCropSettingsData(data);
    setDrawerOpen(false);
  };

  const handleOnRowClick = cellProps => {
    const { row } = cellProps;
    setSelectedRow({ ...row, isEdit: true });
    handleOnDrawerOpen();
  };

  const handleOnAddRow = () => {
    const { vineyardBlockId, vintageID, vineyardID } = searchFilterData;
    const cropSettings = {
      blockVintageId: 0,
      isAdd: true,
      isEditable: false,
      cropSettingInstanceID: 0,
      cropSettingOptionID: 0,
      cropSettingDate: format(new Date(), dateFormat),
      cropSettingSort: 0,
      cropSettingInstance: '',
      cropSettingOption: '',
      cropSettingOptionSortOrder: 0,
      vineyardBlockId,
      vintageId: vintageID,
      vineyardID,
    };
    setSelectedRow(cropSettings);
    handleOnDrawerOpen();
  };

  const handleOnAdvancedSearch = () => {
    dispatchAPI({
      type: 'BASIC_SETTINGS_UPDATE',
      payload: {
        ...basicSettings,
        filterPanelCollapsed: !basicSettings.filterPanelCollapsed,
      },
    });
  };

  const handleOnClickDelete = () => {
    handleOnDeleteBulk(selectionModel);
  };

  return (
    <Stack data-testid="phenology-details" sx={{ height: '100%' }}>
      <Box
        mx={1}
        my={2}
        display="flex"
        justifyContent="space-between"
        data-testid="action-buttons"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          {(vineyardSelected || blockSelected) && (
            <>
              <InfoIcon />
              <Typography
                variant="body2"
                sx={{
                  padding: 2,
                }}
              >
                {subTitle1}
              </Typography>
            </>
          )}
        </Box>

        <Box>
          <VineaButton
            color="secondary"
            variant="text"
            sx={{
              marginRight: theme.spacing(3),
            }}
            minWidth={100}
            onClick={handleOnClickDelete}
            disabled={isEmpty(selectionModel)}
          >
            {t('Delete')}
          </VineaButton>
          <VineaButton
            color="secondary"
            variant="contained"
            sx={{
              marginRight: theme.spacing(1),
            }}
            minWidth={100}
            onClick={handleOnAddRow}
            disabled={buttonDisabled}
          >
            {t('Add Attribute')}
          </VineaButton>
        </Box>
      </Box>
      <Box display="flex" flex={1} mx={1} sx={{ height: '100%' }}>
        <Grid
          container
          spacing={0}
          data-testid="organisation-results"
          direction="column"
        >
          <Box>
            {!isNull(errorInSubmitPhenology) && (
              <Collapse in={!!errorInSubmitPhenology}>
                <Alert
                  variant="outlined"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setErrorInSubmitPhenology(null);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {errorInSubmitPhenology}
                </Alert>
              </Collapse>
            )}
          </Box>

          <Grid
            item
            xs={12}
            sx={{
              flex: 1,
            }}
          >
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                height: 500,
              }}
            >
              <Grid container xs={12} direction="column">
                <OtherAttributeGridView
                  gridXData={gridXData}
                  // data={cropBlockSettingEntityData}
                  isLoading={isLoadingLookups || isLoadingData}
                  handleOnRowClick={handleOnRowClick}
                  handleOnCellClick={f => f}
                  onSelectionModelChange={newSelectionModel => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  actionType="TRACKING_GRID_COLUMN_UPDATE"
                  gridColumnState={trackingDataGrid.otherAttributes}
                  handleOnAdvancedSearch={handleOnAdvancedSearch}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <OtherAttributeDrawer
        open={drawerOpen}
        onClose={handleOnCloseDrawer}
        data={selectedRow}
        handleOnSave={handleOnSaveCropSettingData}
        vineyardSelected={vineyardSelected}
        blockSelected={blockSelected}
        lkpPhenologyMeasure={lkpPhenologyMeasure}
        lkpCropSettingOption={lkpCropSettingOption}
        lkpCropSettingInstance={lkpCropSettingInstance}
        editedNoteImage={phenologyNoteImage}
        usedAttributeOptions={usedAttributeOptions}
        usedAttributeInstance={usedAttributeInstance}
      />
    </Stack>
  );
};
