/* eslint-disable react/prop-types */
import React from 'react';
import { GridWithFilter } from '../../../components/Grid';

export const OtherAttributeGridView = ({
  handleOnRowClick,
  handleOnCellClick,
  isLoading = false,
  //data = [],
  gridXData = {},
  className = '',
  ...props
}) => {
  return (
    <GridWithFilter
      data={gridXData}
      onRowClick={handleOnRowClick}
      onCellClick={handleOnCellClick}
      checkboxSelection
      rowHeight={30}
      hideFooterSelectedRowCount
      autoPageSize
      loading={isLoading}
      className={className}
      // advancedSearch
      {...props}
    />
  );
};
