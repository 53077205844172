import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import shortId from 'shortid';
import sortBy from 'lodash/sortBy';
import { filter, get, isNull, map } from 'lodash';
import { isValid, format } from 'date-fns';
import { commonFieldSelectOption, viewDateFormat } from '../../constants';

// import logger from '../../utils/winstonLogger';

const getIdentityReference = state => state.identityReferences;
const getIdentityRelationship = state => state.identityRelationship;
const getIdentityRelationshipEntity = state =>
  state.entities.identityRelationship;
const getIdentityClassifications = state => state.identityClassifications;
const getIdentityContactMethods = state => state.identityContactMethods;
const getIdentityRoles = state => state.identityRoles;
const getIdentityDetails = state => state.identityDetails;
const getIdentitySearchData = state => state.searchIdentities;
const getIdentityAddress = state => state.identityAddressDetails;
const getIdentityRepositoryData = state => state.identityRepository;
const getVineyardSearchData = state => state.entities.searchIdentity;
const getBlocksForJobsData = state => state.entities.blocksForJob;

export const getBlocksForJobs = createSelector(
  [getBlocksForJobsData],
  blocksForJobsData => {
    const { data = [] } = blocksForJobsData;
    const updatedData = map(data, block => {
      let status = 'Not Started';
      if (block?.complete === true) status = 'Ready to Bill';
      else if (
        Math.ceil(get(block, 'completedUnits', 0)) === block?.selectedUnits
      )
        status = 'Completed';
      else if (block?.completedUnits > 0) status = 'In Progress';
      else status = 'Not Started';

      return {
        ...block,
        status: status,
      };
    });

    return {
      ...blocksForJobsData,
      data: isEmpty(updatedData) ? [] : updatedData,
    };
  },
);

export const getAllIdentities = createSelector(
  [getIdentitySearchData],
  identitySearchData => {
    const { data = [] } = identitySearchData;
    return {
      ...identitySearchData,
      data: isEmpty(data) ? [] : data,
    };
  },
);

export const getRepositoryData = createSelector(
  [getIdentityRepositoryData],
  rep => {
    const { data = [] } = rep;
    const { id, identityTypeID, displayName } = data;
    return {
      id,
      identityTypeID,
      displayName,
    };
  },
);

export const getAllActiveVineyards = createSelector(
  [getVineyardSearchData],
  searchResults => {
    const { data = [] } = searchResults;
    return commonFieldSelectOption.concat(filter(data, f => f.isActive));
  },
);

export const getAllVineyards = createSelector(
  [getVineyardSearchData],
  searchResults => {
    const { data = [] } = searchResults;
    const vineyardList = commonFieldSelectOption.concat(
      sortBy(
        data.map(f => {
          return {
            id: f.id,
            key: shortId.generate(),
            value: f.name,
            subRegionID: f.subRegionID,
            hasVineyardGeometry: f.hasVineyardGeometry,
            hasBlockGeometry: f.hasBlockGeometry,
          };
        }),
        'value',
      ),
    );
    return vineyardList;
  },
);

export const getVineyardsbyRegionID = (state, subRegionID) => {
  const activeVineyards = state.entities.searchIdentity;
  const { data: vineyardData = [] } = activeVineyards;
  return commonFieldSelectOption.concat(
    sortBy(
      filter(vineyardData, f => f.subRegionID === subRegionID).map(row => {
        return {
          id: row.id,
          key: row.id,
          value: row.name,
        };
      }),
      ['value'],
    ),
  );
};

export const getAllActivities = state => {
  const activities = state.entities.searchIdentity;
  const { data: activityData = [] } = activities;
  const activityList = map(activityData, f => {
    return {
      key: shortId.generate(),
      ...f,
    };
  });
  return activityList;
};

export const getIdentityLookups = createSelector(
  [
    getIdentityReference,
    getIdentityRelationship,
    getIdentityClassifications,
    getIdentityContactMethods,
    getIdentityRoles,
    getIdentityDetails,
  ],
  (
    references,
    relationships,
    classifications,
    contactMethods,
    roles,
    details,
  ) => {
    const { isLoading: referenceLoading } = references;
    const { isLoading: relationshipLoading } = relationships;
    const { isLoading: classificationsLoading } = classifications;
    const { isLoading: contactMethodsLoading } = contactMethods;
    const { isLoading: rolesLoading } = roles;
    const { isLoading: detailsLoading } = details;

    let isLoadingData = false;
    if (
      referenceLoading ||
      relationshipLoading ||
      classificationsLoading ||
      contactMethodsLoading ||
      rolesLoading ||
      detailsLoading
    ) {
      isLoadingData = true;
    }

    return {
      isLoading: isLoadingData,
      data: [],
    };
  },
);

export const getIdentityReferences = createSelector(
  [getIdentityReference],
  reference => {
    const { data = [] } = reference;
    const refData = isEmpty(data) ? [] : data;
    if (!Array.isArray(refData)) return [];

    const formattedData = sortBy(
      refData.map(d => {
        const toDate =
          !isEmpty(d.effectiveToDate) && isValid(new Date(d.effectiveToDate))
            ? format(new Date(d.effectiveToDate), viewDateFormat)
            : '';
        const fromDate =
          !isEmpty(d.effectiveFromDate) &&
          isValid(new Date(d.effectiveFromDate))
            ? format(new Date(d.effectiveFromDate), viewDateFormat)
            : '';
        const seperator = toDate ? '-' : '';
        const fromToDate = `${fromDate} ${seperator} ${toDate}`;
        return {
          id: d.id,
          identityID: d.identityID,
          referenceType: d.referenceType,
          referenceTypeID: d.referenceTypeID,
          referenceValue: d.referenceValue,
          effectiveFromDate: d.effectiveFromDate,
          effectiveToDate: d.effectiveToDate,
          fromDate: d.effectiveFromDate,
          toDate: d.effectiveToDate,
          fromTo: fromToDate,
          isActive: d.isActive,
          ts: d.ts,
          displayOrder: d.displayOrder,
        };
      }),
      ['referenceValue'],
    );
    return {
      ...reference,
      data: formattedData,
    };
  },
);

export const getIdentityRelationshipData = createSelector(
  [getIdentityRelationship],
  relationship => {
    const { data = [] } = relationship;
    const refData = isEmpty(data) ? [] : data;
    if (!Array.isArray(refData)) return [];

    const formattedData = refData.map(d => {
      const toDate =
        !isEmpty(d.effectiveToDate) && isValid(new Date(d.effectiveToDate))
          ? format(new Date(d.effectiveToDate), viewDateFormat)
          : '';
      const fromDate =
        !isEmpty(d.effectiveFromDate) && isValid(new Date(d.effectiveFromDate))
          ? format(new Date(d.effectiveFromDate), viewDateFormat)
          : '';
      const seperator = toDate ? '-' : '';
      const fromToDate = `${fromDate} ${seperator} ${toDate}`;
      return {
        ...d,
        id: d.id,
        relatesTo: d.relatesTo,
        relationshipName: d.relationshipName,
        relationshipTypeID: d.relationshipTypeID,
        ownerIdentityID: d.ownerIdentityID,
        dependentIdentityID: d.dependentIdentityID,
        effectiveFromDate: d.effectiveFromDate,
        effectiveToDate: d.effectiveToDate,
        fromTo: fromToDate,
        comment: d.comment,
        isDisplayOnPanel: d.isDisplayOnPanel,
        isActive: d.isActive,
        ts: d.ts,
      };
    });
    return {
      ...relationship,
      data: formattedData,
    };
  },
);

export const getIdentityRelationshipEntityData = createSelector(
  [getIdentityRelationshipEntity],
  relationship => {
    const { data = [] } = relationship;
    const refData = isEmpty(data) ? [] : data;
    if (!Array.isArray(refData)) return [];

    const formattedData = refData.map(d => {
      const toDate =
        !isEmpty(d.effectiveToDate) && isValid(new Date(d.effectiveToDate))
          ? format(new Date(d.effectiveToDate), viewDateFormat)
          : '';
      const fromDate =
        !isEmpty(d.effectiveFromDate) && isValid(new Date(d.effectiveFromDate))
          ? format(new Date(d.effectiveFromDate), viewDateFormat)
          : '';
      const seperator = toDate ? '-' : '';
      const fromToDate = `${fromDate} ${seperator} ${toDate}`;
      return {
        ...d,
        id: d.id,
        relatesTo: d.relatesTo,
        relationshipName: d.relationshipName,
        relationshipTypeID: d.relationshipTypeID,
        ownerIdentityID: d.ownerIdentityID,
        dependentIdentityID: d.dependentIdentityID,
        effectiveFromDate: d.effectiveFromDate,
        effectiveToDate: d.effectiveToDate,
        fromTo: fromToDate,
        comment: d.comment,
        isDisplayOnPanel: d.isDisplayOnPanel,
        isActive: d.isActive,
        ts: d.ts,
      };
    });
    return {
      ...relationship,
      data: formattedData,
    };
  },
);

export const getAllIdentityRoles = createSelector([getIdentityRoles], roles => {
  const { data = [] } = roles;
  const refData = isEmpty(data) ? [] : data;
  if (!Array.isArray(refData)) return [];
  const formattedData = refData.map(d => {
    const toDate =
      !isEmpty(d.effectiveToDate) && isValid(new Date(d.effectiveToDate))
        ? format(new Date(d.effectiveToDate), viewDateFormat)
        : '';
    const fromDate =
      !isEmpty(d.effectiveFromDate) && isValid(new Date(d.effectiveFromDate))
        ? format(new Date(d.effectiveFromDate), viewDateFormat)
        : '';
    const seperator = toDate ? '-' : '';
    const fromToDate = `${fromDate} ${seperator} ${toDate}`;
    return {
      id: d.id,
      roleTypeID: d.roleTypeID,
      roleType: d.roleType,
      effectiveFromDate: d.effectiveFromDate,
      effectiveToDate: d.effectiveToDate,
      fromDate: d.effectiveFromDate,
      toDate: d.effectiveToDate,
      fromTo: fromToDate,
      isActive: d.isActive,
      ts: d.ts,
    };
  });

  return {
    ...roles,
    data: formattedData,
  };
});

export const getAllIdentityClassifications = createSelector(
  [getIdentityClassifications],
  classification => {
    const { data = [] } = classification;
    const refData = isEmpty(data) ? [] : data;
    if (!Array.isArray(refData)) return [];

    const formattedData = refData.map(d => {
      const toDate =
        !isEmpty(d.effectiveToDate) && isValid(new Date(d.effectiveToDate))
          ? format(new Date(d.effectiveToDate), viewDateFormat)
          : '';
      const fromDate =
        !isEmpty(d.effectiveFromDate) && isValid(new Date(d.effectiveFromDate))
          ? format(new Date(d.effectiveFromDate), viewDateFormat)
          : '';
      const seperator = toDate ? '-' : '';
      const fromToDate = `${fromDate} ${seperator} ${toDate}`;
      return {
        id: d.id,
        classification: d.classification,
        classificationGroup: d.classificationGroup,
        classificationID: d.classificationID,
        classificationGroupID: d.classificationGroupID,
        effectiveFromDate: d.effectiveFromDate,
        effectiveToDate: d.effectiveToDate,
        fromDate: d.effectiveFromDate,
        toDate: d.effectiveToDate,
        fromTo: fromToDate,
        isActive: d.isActive,
        ts: d.ts,
      };
    });

    return {
      ...classification,
      data: formattedData,
    };
  },
);

export const getClassificationsById = createSelector(
  [getIdentityClassifications] /** replace selector with above one */,
  classifications => {
    const { data: clsData } = classifications;
    if (!Array.isArray(clsData)) return [];
    const formattedData = clsData.map(d => {
      const toDate =
        !isEmpty(d.effectiveToDate) && isValid(new Date(d.effectiveToDate))
          ? format(new Date(d.effectiveToDate), viewDateFormat)
          : '';
      const fromDate =
        !isEmpty(d.effectiveFromDate) && isValid(new Date(d.effectiveFromDate))
          ? format(new Date(d.effectiveFromDate), viewDateFormat)
          : '';
      const seperator = toDate ? '-' : '';
      const fromToDate = `${fromDate} ${seperator} ${toDate}`;
      return {
        id: d.id,
        classification: d.classification,
        group: d.classificationGroup,
        effectiveFromDate: d.effectiveFromDate,
        effectiveToDate: d.effectiveToDate,
        fromDate: d.effectiveFromDate,
        toDate: d.effectiveToDate,
        isActive: d.isActive,
        fromTo: fromToDate,
      };
    });
    return {
      ...classifications,
      data: formattedData,
    };
  },
);

export const getAllIdentityContactMethods = createSelector(
  [
    getIdentityContactMethods,
    getIdentityAddress,
  ] /** replace selector with above one */,
  (contactMethod, address) => {
    const { data: contactData } = contactMethod;
    const { data: addressData } = address;

    if (!Array.isArray(contactData) || isNull(addressData)) return [];
    const formattedData = contactData.map(d => {
      const toDate =
        !isEmpty(d.effectiveToDate) && isValid(new Date(d.effectiveToDate))
          ? format(new Date(d.effectiveToDate), viewDateFormat)
          : '';
      const fromDate =
        !isEmpty(d.effectiveFromDate) && isValid(new Date(d.effectiveFromDate))
          ? format(new Date(d.effectiveFromDate), viewDateFormat)
          : '';
      const seperator = toDate ? '-' : '';
      const fromToDate = `${fromDate} ${seperator} ${toDate}`;
      return {
        id: d.id,
        identityID: addressData.identityID,
        contactMethodType: d.contactMethodType,
        contactMethodDetails: d.contactMethodDetails,
        contactMethodTypeId: d.contactMethodTypeID,
        isPrimaryContact: d.isPrimaryContact,
        addressLine1: d.id === addressData.id ? addressData.addressLine1 : null,
        addressLine2: d.id === addressData.id ? addressData.addressLine2 : null,
        suburb: d.id === addressData.id ? addressData.suburb : null,
        postCode: d.id === addressData.id ? addressData.postCode : null,
        townID: d.id === addressData.id ? addressData.townID : null,
        countryID: d.id === addressData.id ? addressData.countryID : null,
        addressTS: d.id === addressData.id ? addressData.ts : null,
        effectiveFromDate: d.effectiveFromDate,
        effectiveToDate: d.effectiveToDate,
        fromDate: d.effectiveFromDate,
        toDate: d.effectiveToDate,
        fromTo: fromToDate,
        isActive: d.isActive,
        ts: d.ts,
      };
    });

    return {
      ...contactMethod,
      data: formattedData,
    };
  },
);

export const getIdentitySubDetailsIsLoading = createSelector(
  [
    getIdentityClassifications,
    getIdentityContactMethods,
    getIdentityRoles,
    getIdentityDetails,
    getIdentityReference,
    getIdentityRelationship,
  ],
  (
    identityClassifications,
    identityContactMethods,
    identityRoles,
    identityDetails,
    identityReferences,
    identityRelationship,
  ) => {
    const { isLoading: classificationIsLoading } = identityClassifications;
    const { isLoading: contactMethodsIsLoading } = identityContactMethods;
    const { isLoading: identityRolesIsLoading } = identityRoles;
    const { isLoading: identityDetailsIsLoading } = identityDetails;
    const { isLoading: identityReferenceIsLoading } = identityReferences;
    const { isLoading: identityRelationshipIsLoading } = identityRelationship;

    return (
      classificationIsLoading ||
      contactMethodsIsLoading ||
      identityRolesIsLoading ||
      identityDetailsIsLoading ||
      identityReferenceIsLoading ||
      identityRelationshipIsLoading
    );
  },
);
