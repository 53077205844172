import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { format, parseISO } from 'date-fns';
import Alert from '@mui/lab/Alert';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { isNull, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/lab/Skeleton';
import { useParams } from 'react-router';

// Icons
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';
import VineaAlertMessage from './VineaAlertMessage';
import { DeleteDialog } from '../../../components/Dialog';
import { Datepicker } from '../../../components/Datepicker';
import { StyledTableCell } from './StyledTableCell';
import {
  VineaButton,
  IconToolTipButton,
} from '../../../components/VineaButton';

import logger from '../../../utils/winstonLogger';
import { getFormWriteData } from '../../../redux/selectors';
import { useIdClassificationHook } from '../hooks/useIdClassificationHook';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { sagaActionTypes, viewDateFormat } from '../../../constants';
import { formatDate } from '../../../constants/formatter';
import { useIdRolesHook } from '../hooks/useIdRolesHook';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

const groupSelectOption = [
  {
    key: 0,
    id: 0,
    value: 'Select',
  },
];

const CustomTableCell = ({
  row,
  name,
  onChange,
  classificationGroupOptions,
}) => {
  const { isEditMode, errors } = row;
  const [classificationsNames, setClassificationsNames] =
    useState(groupSelectOption);

  const lkpclassifications = useSelector(
    VineaNovaSelectors.getlookupClassificationEntityData,
  );

  React.useEffect(() => {
    const classificationTypeOptions = groupSelectOption.concat(
      filter(
        lkpclassifications,
        cls => cls.classificationGroupID === row.classificationGroupID,
      ).map(lkpCls => {
        return {
          id: lkpCls.id,
          key: lkpCls.id,
          value: lkpCls.classification,
        };
      }),
    );
    setClassificationsNames(classificationTypeOptions);
  }, [row, lkpclassifications]);

  const handleOnChange = e => {
    onChange(e, row);
  };

  if (name === 'name' && !isEditMode) {
    const classValue = get(
      classificationsNames.find(f => f.id === row.classificationID),
      'value',
      null,
    );
    // eslint-disable-next-line no-param-reassign
    row.name = classValue;
  }

  return (
    <StyledTableCell align="left" manageStyle>
      {isEditMode && name === 'group' && (
        <>
          <VineaAutoComplete
            value={row.classificationGroupID}
            onChange={handleOnChange}
            inlineLabel
            options={classificationGroupOptions}
            labelVariant="body1"
            name="classificationGroupID"
            displayLabel={false}
            inputProps={{
              name: 'classificationGroupID',
            }}
          />
          {errors.classificationGroupID && (
            <FormHelperText id="component-error-text">Required</FormHelperText>
          )}
        </>
      )}
      {isEditMode && name === 'name' && (
        <>
          <VineaAutoComplete
            value={row.classificationID}
            onChange={handleOnChange}
            inlineLabel
            options={classificationsNames}
            labelVariant="body1"
            name="classificationID"
            displayLabel={false}
            inputProps={{
              name: 'classificationID',
            }}
          />
          {errors.classificationID && (
            <FormHelperText id="component-error-text">
              {errors.classificationID}
            </FormHelperText>
          )}
        </>
      )}
      {isEditMode && name === 'effectiveFromDate' && (
        <>
          <Datepicker
            name="effectiveFromDate"
            disablePast={false}
            id="effectivefrom"
            placeholder="DD/MM/YYYY"
            inputFormat={viewDateFormat}
            value={row.effectiveFromDate}
            onChange={handleOnChange}
            inputProps={{
              'data-testid': 'effectiveFromDate',
              'data-name': 'effectiveFromDate',
            }}
          />
          {errors.effectiveFromDate && (
            <FormHelperText id="component-error-text">
              {errors.effectiveFromDate}
            </FormHelperText>
          )}
        </>
      )}
      {isEditMode && name === 'effectiveToDate' && (
        <>
          <Datepicker
            disablePast={false}
            minDate={
              row.effectiveFromDate === null
                ? format(new Date(), viewDateFormat)
                : parseISO(row.effectiveFromDate)
            }
            id="effectiveto"
            placeholder="DD/MM/YYYY"
            inputFormat={viewDateFormat}
            value={row.effectiveToDate}
            onChange={handleOnChange}
            name="effectiveToDate"
            inputProps={{
              'data-testid': 'effectiveToDate',
              'data-name': 'effectiveToDate',
            }}
          />
          {errors.effectiveToDate && (
            <FormHelperText id="component-error-text">
              {errors.effectiveToDate}
            </FormHelperText>
          )}
        </>
      )}
      {!isEditMode &&
        name !== 'effectiveFromDate' &&
        name !== 'effectiveToDate' &&
        row[name]}
      {!isEditMode &&
        (name === 'effectiveFromDate' || name === 'effectiveToDate') &&
        formatDate(row[name])}
    </StyledTableCell>
  );
};

export const ManageClassifications = props => {
  logger.debug('Manage Classifications.jsx');
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatchAPI = useDispatch();
  const [alertOpen, setAlertOpen] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [disableIcon, setDisableIcon] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [alertType, setAlertType] = useState(false);
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  const identityTypeId = useIdentityTypeId(vineyardTypeScreenName);
  const { id: pathParam } = useParams();

  let { identityId } = props;
  if (isNull(identityId)) {
    identityId = pathParam;
  }

  const { identityRoles } = useIdRolesHook(identityTypeId, identityId);
  const identityRoleTypeIDs = map(identityRoles, 'roleTypeID');

  const {
    classificationGroupOptions,
    identityClassifications: rows,
    onAddNew,
    isLoadingIdClassification: isLoading,
    handleOnChangeRowData,
    onToggleEditMode,
    handleOnSaveRow,
    handleRevert,
    setSeqToSave,
    alert,
    setActionTriggered,
    actionTriggered,
    seqToSave,
    handleOnDeleteRow,
  } = useIdClassificationHook(identityTypeId, identityId, identityRoleTypeIDs);

  const {
    isLoading: isFormSubmitLoading,
    hasError: hasErrorFormSubmit,
    error: formErrorMessage,
    isLoaded,
  } = useSelector(getFormWriteData);

  const onAdd = data => {
    setDisableIcon(true);
    onAddNew(data);
  };

  const onDelete = id => {
    setRowId(id);
    setDialogOpen(true);
    setDisableIcon(false);
  };

  const onSave = id => {
    handleOnSaveRow(id);
  };

  const onEdit = id => {
    setDisableIcon(true);
    onToggleEditMode(id, rows);
  };

  const onRevert = id => {
    setDisableIcon(false);
    handleRevert(id);
  };

  React.useEffect(() => {
    /** When saving the selected reference id */
    if (actionTriggered) {
      if (!isFormSubmitLoading && isLoaded) {
        if (hasErrorFormSubmit) {
          // set form submitting has errors;
          setAlertOpen(true);
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else if (!hasErrorFormSubmit) {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          setAlertType(true);
          setDisableIcon(false);
          onToggleEditMode(seqToSave, rows);
          if (!isNull(identityId)) {
            dispatchAPI({
              type: sagaActionTypes.FETCH_IDENTITIES,
              payload: {
                id: identityId,
                identityTypeId,
              },
            });
          }
        }
        setActionTriggered(false);
        setSeqToSave(null);
      }
    }
  }, [
    actionTriggered,
    setActionTriggered,
    setSeqToSave,
    isFormSubmitLoading,
    hasErrorFormSubmit,
    isLoaded,
    enqueueSnackbar,
    onToggleEditMode,
    seqToSave,
    t,
    rows,
    dispatchAPI,
    identityTypeId,
    identityId,
  ]);

  const onCloseAlert = () => {
    setAlertType(false);
    setAlertOpen(false);
  };

  return (
    <>
      <Paper
        sx={{
          width: '100%',
          margin: theme.spacing(3),
          overflowX: 'auto',
        }}
      >
        <Box paddingTop={1} />
        <Box px={2}>
          {hasErrorFormSubmit && (
            <Collapse in={alertOpen}>
              <Alert
                key="error-alert"
                variant="outlined"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {formErrorMessage}
              </Alert>
            </Collapse>
          )}
          <VineaAlertMessage
            alertType={alertType}
            onExit={onCloseAlert}
            alert={alert}
            type="Classification"
          />
        </Box>
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label="caption table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" />
              <TableCell align="left">{t('Group')}</TableCell>
              <TableCell align="left">{t('Name')}</TableCell>
              <TableCell align="left">{t('Effective From')}</TableCell>
              <TableCell align="left">{t('Effective To')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow sx={{ height: 20 }}>
                <TableCell colSpan={11}>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              rows.map((row, idx) => (
                <>
                  <TableRow
                    key={row.id}
                    data-testid={row.id}
                    data-testrow="table-row"
                    data-seq={idx + 1}
                    data-newrow={row.isEditMode}
                    sx={{ height: 20 }}
                  >
                    <StyledTableCell iconStyle>
                      {row.isEditMode ? (
                        <>
                          <IconToolTipButton
                            size="small"
                            tooltip="Save"
                            label="done"
                            data-testid="iconsave"
                            onClick={() => {
                              onSave(row.id);
                            }}
                            sx={{
                              color: theme.palette.success.main,
                            }}
                            disabled={!!row.warning || !isEmpty(row.errors)}
                          >
                            <DoneAllIcon />
                          </IconToolTipButton>
                          <IconToolTipButton
                            size="small"
                            tooltip="Cancel"
                            data-testid="iconcancel"
                            onClick={() => {
                              onRevert(row.id);
                            }}
                            sx={{
                              color: theme.palette.error.main,
                            }}
                          >
                            <NotInterestedOutlinedIcon />
                          </IconToolTipButton>
                        </>
                      ) : (
                        <>
                          <IconToolTipButton
                            size="small"
                            disabled={disableIcon}
                            tooltip="Edit"
                            data-testid="iconedit"
                            onClick={() => {
                              onEdit(row.id);
                            }}
                            sx={{
                              marginTop: theme.spacing(2) * -1,
                              color: '#404040',
                            }}
                          >
                            <ModeEditOutlineOutlinedIcon />
                          </IconToolTipButton>
                          <IconToolTipButton
                            size="small"
                            disabled={disableIcon}
                            tooltip="Delete"
                            label="Delete"
                            data-testid="icondelete"
                            onClick={() => {
                              onDelete(row.id);
                            }}
                            sx={{
                              color: theme.palette.error.main,
                              marginTop: theme.spacing(2) * -1,
                            }}
                          >
                            <DeleteIcon />
                          </IconToolTipButton>
                        </>
                      )}
                    </StyledTableCell>
                    <CustomTableCell
                      {...{
                        row,
                        classificationGroupOptions,
                        name: 'group',
                        onChange: handleOnChangeRowData,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: 'name',
                        onChange: handleOnChangeRowData,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: 'effectiveFromDate',
                        onChange: handleOnChangeRowData,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: 'effectiveToDate',
                        onChange: handleOnChangeRowData,
                      }}
                    />
                  </TableRow>
                  {row.warning && (
                    <TableRow>
                      <TableCell colSpan={11}>
                        <Alert severity="warning">{row.warning}</Alert>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
          </TableBody>
        </Table>
        <DeleteDialog
          open={dialogOpen}
          dialogHeader={t('Delete Classification')}
          onClose={() => setDialogOpen(false)}
          dialogActionTrigger={handleOnDeleteRow}
          rowId={rowId}
        />
      </Paper>
      <Box paddingTop={1} />
      <VineaButton
        color="secondary"
        sx={{ marginLeft: theme.spacing(3) }}
        onClick={onAdd}
        variant="contained"
        minWidth={100}
        disabled={rows.some(row => row.isEditMode)}
        data-testid="addclassification"
      >
        {t('Add Classification')}
      </VineaButton>
    </>
  );
};

ManageClassifications.propTypes = {
  identityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ManageClassifications.defaultProps = {
  identityId: null,
};

CustomTableCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    classificationID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    classificationGroupID: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    effectiveToDate: PropTypes.string,
    effectiveFromDate: PropTypes.string,
    classificationGroup: PropTypes.string,
    classificationName: PropTypes.string,
    errors: PropTypes.shape({
      name: PropTypes.string,
      classificationID: PropTypes.string,
      classificationGroupID: PropTypes.string,
      effectiveToDate: PropTypes.string,
      effectiveFromDate: PropTypes.string,
    }),
    isEditMode: PropTypes.bool,
  }),
  classificationGroupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    }),
  ),
  name: PropTypes.string,
  onChange: PropTypes.func,
};

CustomTableCell.defaultProps = {
  row: {},
  name: '',
  onChange: f => f,
  classificationGroupOptions: [],
};
