/* eslint-disable react/require-default-props */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
// import { TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import { VineaTextField } from '../TextField';

type FieldNameSelectProps = {
  options: {
    key: string | number;
    id: number;
    value: string | number;
  }[];
  children?: any;
  inputProps?: {
    id: string;
  };
};

export const FieldNameSelect = ({
  options,
  children,
  ...restProps
}: FieldNameSelectProps) => {
  const getComputedOptions = (incomingOptions: any) => {
    const placeHolderOptions = [{ id: -999, key: '-999', value: 'select' }];
    if (isEmpty(incomingOptions) || !Array.isArray(incomingOptions)) {
      return placeHolderOptions;
    }
    return incomingOptions;
  };

  const memoizedOptions = React.useMemo(
    () => getComputedOptions(options),
    [options],
  );

  return (
    <VineaTextField select {...restProps}>
      {options &&
        memoizedOptions.map(val => {
          return (
            <MenuItem value={val.id} key={val.id}>
              {val.value}
            </MenuItem>
          );
        })}
      {!options && children}
    </VineaTextField>
  );
};

// FieldNameSelect.propTypes = {
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   onChange: PropTypes.func,
//   name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   error: PropTypes.bool.isRequired,
//   inlineLabel: PropTypes.bool,
//   id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   helperText: PropTypes.string.isRequired,
//   options: PropTypes.arrayOf(
//     PropTypes.shape({
//       key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//       id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//       value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//     }),
//   ).isRequired,
//   onBlur: PropTypes.func,
//   displayEmpty: PropTypes.bool.isRequired,
//   disabled: PropTypes.bool,
//   displayLabel: PropTypes.bool,
//   formControlMargin: PropTypes.oneOf(['dense', 'normal', 'none']),
// };

// FieldNameSelect.defaultProps = {
//   value: '',
//   onChange: () => {},
//   name: '',
//   inlineLabel: false,
//   onBlur: () => {},
//   disabled: false,
//   displayLabel: true,
//   formControlMargin: 'dense',
// };
