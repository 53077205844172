import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import { Box, Paper, ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useRefDimensions from '../../hooks/useRefDimensions';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { syncValidator } from '../../utils/validator';
import { FrappeGanttSchema } from '../../components/FrappeGantt/validations';
import { VineaAutoComplete } from '../../components/ComboBox';
import {
  getAllPlanActivitiesData,
  getAllPlanActivities,
  areLookupsLoading,
} from '../../redux/selectors';
import useDeepEffect from '../../hooks/useDeepEffect';
import LookupLayout from '../../layouts/LookupLayout';
import { PAGE_LOOKSUPS } from '../../constants';
import useDebounce from '../../hooks/useDebounce';
  import { FrappeGantt, ViewMode } from '../../components/FrappeGantt';

const PlanActivites = ({ dimensions = {} }) => {
  const dispatchAPI = useDispatch();
  const { id: pathParam } = useParams();
  const navigate = useNavigate();
  const planActivitiesRef = React.useRef(null);

  const paperDimensions = useRefDimensions(planActivitiesRef);
  // Selectors
  const ganttData = useSelector(state => getAllPlanActivitiesData(state));
  const allPlanActivities = useSelector(getAllPlanActivities);
  const planAcitiviesMeta = useSelector(
    VineaNovaSelectors.getPlanActivitiesEntityMeta,
  );

  const areLookupLoading = useSelector(areLookupsLoading);

  // Local State
  const [apiTrigger, setApiTrigger] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [sortOptionID, setSortOptionID] = useState('date');
  const [state, setState] = useState({ mode: ViewMode.Month });
  const [open, setOpen] = React.useState(false);
  const [loadingState, setLoadingState] = React.useState(false);
  const [viewReady, setViewReady] = React.useState(false);
  const allPlanActivityDataRef = React.useRef([{}]);
  const [activityTaskData, setActivityTaskData] = React.useState([]);
  const [selectedGantt, setSelectedGantt] = React.useState(null);
  const [validationsErrors, setValidationErrors] = useState({});
  const [taskDetails, setTaskDetails] = useState({
    id: '',
    name: '',
    start: new Date(),
    end: new Date(),
    progress: 0,
    dependencies: [],
  });

  const apiTriggerDebounce = useDebounce(apiTrigger, 200);

  React.useEffect(() => {
    const timeOut = setTimeout(() => {
      setViewReady(true);
    }, 1500);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  React.useEffect(() => {
    allPlanActivityDataRef.current = allPlanActivities;
  }, [allPlanActivities]);

  const sortOptions = [
    {
      id: 'date',
      value: 'Date',
      label: 'Date',
    },
    {
      id: 'sequence',
      value: 'Sort Sequence',
      label: 'Sort Sequence',
    },
  ];

  const handleChangeSort = e => {
    setSortOptionID(e.target.value);
  };

  const handleNavigateToEditPlanActivity = editId => {
    navigate(`/plans/${pathParam}/edit-plan-activity/${editId}`);
  };

  const handleViewChange = event => {
    setState({ mode: event.target.value });
  };

  const handleNameChange = event => {
    setTaskDetails({ ...taskDetails, name: event.target.value });
  };

  const handleStartDateChange = event => {
    setTaskDetails({ ...taskDetails, start: event.target.value });
  };

  const handleEndDateChange = event => {
    setTaskDetails({ ...taskDetails, end: event.target.value });
  };

  const handleProgressChange = event => {
    setTaskDetails({ ...taskDetails, progress: event.target.value });
  };

  const handleDependencyChange = event => {
    setTaskDetails({ ...taskDetails, dependencies: event.target.value });
  };

  const handleOnSearch = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.planActivitiesByPlan.get.request({
        queryParams: {
          planID: Number(pathParam),
        },
      }),
    );
  };

  const handleClickOpen = () => {
    navigate(`/plans/${pathParam}/add-plan-activity`);
  };

  const handleCloseAddDrawer = () => {
    setAddDrawerOpen(false);
  };

  const handleAdd = () => {
    const currentErrors = syncValidator(FrappeGanttSchema)(taskDetails);
    setValidationErrors(currentErrors);
    if (isEmpty(currentErrors)) {
      const allNewTasks = activityTaskData.concat([taskDetails]);
      setActivityTaskData(allNewTasks);
      setOpen(false);
    }
  };

  const handleOnOpenEditDrawer = task => {
    handleNavigateToEditPlanActivity(task.id);
  };

  useEffect(() => {
    const { isLoading } = planAcitiviesMeta;
    if (apiTrigger && !isLoading) {
      handleOnSearch();
      setApiTrigger(false);
    }
  }, [apiTriggerDebounce, planAcitiviesMeta]);

  useDeepEffect(() => {
    // const diff = difference(ganttData, )
    if (!isEmpty(ganttData)) {
      console.log('running gantt data xyz');
      // const modTasks = ganttData.map(t => ({ ...t, id: t.id?.toString() }));
      setActivityTaskData(ganttData);
    } else {
      setActivityTaskData([]);
    }
  }, [ganttData]);

  const { height: pageHeight } = paperDimensions;

  return (
    <LookupLayout
      id={Number(pathParam)}
      lookups={[PAGE_LOOKSUPS.PLAN_ACTIVITIES_LOOKUPS]}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          height: '100%',
        }}
        ref={planActivitiesRef}
      >
        {!areLookupLoading && viewReady && (
          <Paper
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              height: '100%',
              padding: 3,
            }}
          >
            <Box>
              <Box
                margin={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  maxHeight: '40px',
                  alignItems: 'center',
                }}
              >
                <ButtonGroup
                  id="view-btn-group"
                  variant="outlined"
                  aria-label="outlined button group"
                  size="small"
                  sx={{
                    height: '40px',
                  }}
                >
                  <Button
                    color={'secondary'}
                    variant={
                      state.mode === ViewMode.Day ? 'contained' : 'outlined'
                    }
                    value={ViewMode.Day}
                    onClick={handleViewChange}
                    size="small"
                  >
                    Day
                  </Button>
                  <Button
                    color={'secondary'}
                    variant={
                      state.mode === ViewMode.Week ? 'contained' : 'outlined'
                    }
                    value={ViewMode.Week}
                    onClick={handleViewChange}
                    size="small"
                  >
                    Week
                  </Button>
                  <Button
                    color={'secondary'}
                    variant={
                      state.mode === ViewMode.Month ? 'contained' : 'outlined'
                    }
                    value={ViewMode.Month}
                    onClick={handleViewChange}
                    size="small"
                  >
                    Month
                  </Button>
                </ButtonGroup>

                <Box
                  mx={2}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <VineaAutoComplete
                    value={sortOptionID}
                    onChange={handleChangeSort}
                    inlineLabel
                    options={sortOptions}
                    labelVariant="body1"
                    label={'Sort by'}
                    name={'Sort by'}
                    displayLabel
                    inputProps={{
                      name: 'sortPlans',
                    }}
                  />
                  <Box mx={1} sx={{ justifyContent: 'space-around' }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleClickOpen}
                    >
                      Add Activity
                    </Button>
                  </Box>
                  <Box mx={1} sx={{ justifyContent: 'space-around' }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={isEmpty(selectedGantt)}
                    >
                      Create Task
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ minWidth: 120 }}>
                {/* <Typography component="p" variant="h3">
              ADD Task{' '}
            </Typography>
            <Button variant="outlined" onClick={handleClickOpen}>
              Add a New Task
            </Button> */}
                <Dialog open={open} onClose={handleCloseAddDrawer}>
                  <DialogTitle>New Task</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please fill the following details to create a new task
                    </DialogContentText>
                    {/* <FormControl error={!!validationsErrors.name}> */}
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Task Name"
                      type="text"
                      fullWidth
                      variant="standard"
                      placeholder="Enter Task Name"
                      onChange={handleNameChange}
                      error={validationsErrors.name}
                    />
                    {/* {validationsErrors && (
              <FormHelperText id="name">
                {validationsErrors.name}
              </FormHelperText>
            )}
          </FormControl> */}
                    <TextField
                      autoFocus
                      margin="dense"
                      id="start"
                      label="Start Date"
                      type="date"
                      fullWidth
                      variant="standard"
                      onChange={handleStartDateChange}
                    />

                    <TextField
                      autoFocus
                      margin="dense"
                      id="end"
                      label="End Date"
                      type="date"
                      fullWidth
                      variant="standard"
                      onChange={handleEndDateChange}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="progress"
                      label="Task Progress"
                      type="number"
                      fullWidth
                      variant="standard"
                      placeholder="Task Progress"
                      onChange={handleProgressChange}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="dependency"
                      label="Task Dependency"
                      type="text"
                      fullWidth
                      variant="standard"
                      placeholder="Enter Task Dependencies"
                      onChange={handleDependencyChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseAddDrawer}>Cancel</Button>
                    <Button onClick={handleAdd}>Add</Button>
                  </DialogActions>
                </Dialog>
              </Box>
              {viewReady && (
                <Box
                  data-testid="frappegant"
                  width={`${Math.max(200, Number(dimensions.width) - 80)}px`}
                  sx={[
                    {
                      '&>div': {
                        width: `${Math.max(
                          200,
                          Number(dimensions.width) - 80,
                        )}px`,
                        height: `${Math.max(100, Number(pageHeight) - 100)}px`,
                      },
                    },
                  ]}
                >
                  <FrappeGantt
                    tasks={activityTaskData}
                    viewMode={state.mode}
                    onClick={handleOnOpenEditDrawer}
                    onDateChange={(task, start, end) =>
                      console.log(task, start, end)
                    }
                    onProgressChange={(task, progress) =>
                      console.log(task, progress)
                    }
                    onTasksChange={taskChange => console.log(taskChange)}
                    sortOptionID={sortOptionID}
                    ganttHeight={Math.max(100, Number(pageHeight) - 200)}
                    setSelectedGantt={setSelectedGantt}
                  />
                </Box>
              )}
            </Box>
          </Paper>
        )}
      </Box>
    </LookupLayout>
  );
};

export default PlanActivites;
