import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
// import { DropzoneArea } from 'react-mui-dropzone';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useTranslation } from 'react-i18next';
import { VineaButton } from '../VineaButton';

type DropZoneProps = {
  open: boolean;
  onClose: any;
};

export const DropZoneGrid = styled(Grid)(({ theme }) => ({
  '& .MuiDropzoneArea-text': {
    margin: theme.spacing(6),
  },
  '& .MuiTypography-h5': {
    fontSize: '13px',
  },
}));

export default function DropZone({ open, onClose }: DropZoneProps) {
  const { t } = useTranslation();
  const handleCancel = () => {
    onClose();
  };
  const handleOk = () => {
    onClose();
  };
  return (
    <div>
      <Dialog
        data-testid="dialog"
        open={open}
        onClose={onClose}
        maxWidth="xs"
        disableEscapeKeyDown
        aria-labelledby="dropzone-dialog-title"
        aria-describedby="dropzone-dialog-description"
        color="primary"
      >
        <DialogTitle id="dropzone-dialog-title">
          <Grid container xs={12} direction="row">
            <AttachFileIcon />
            <Typography variant="h5" color="textPrimary">
              {t('Attach Details')}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12}>
            <DropZoneGrid item xs={12}>
              {/* <DropzoneArea onChange={files => console.log('Files:', files)} /> */}
            </DropZoneGrid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item>
            <VineaButton
              data-testid="BtnCancel"
              autoFocus
              onClick={handleCancel}
              color="secondary"
              variant="text"
              textTransform="capitalize"
            >
              {t('Cancel')}
            </VineaButton>
            <VineaButton
              onClick={handleOk}
              color="success"
              variant="contained"
              data-testid="BtnAttach"
            >
              {t('Attach')}
            </VineaButton>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// DropZone.propTypes = {
//   open: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
// };
