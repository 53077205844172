/* eslint-disable react/prop-types */
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// import { loadCss } from 'esri-loader';
import CssBaseline from '@mui/material/CssBaseline';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LicenseInfo } from '@mui/x-license-pro';
import theme from './theme';
import { history } from './redux/store';
import Loader from './components/Loader/Loader';
import { getAppInsights } from './utils/TelemetryService';
import TelemetryProvider from './utils/TelemetryProvider';
import { AuthContextProvider } from './hooks/AuthContext';
import ErrorBoundaryFallback from './layouts/FullScreenErrorBoundary';
import CustomBrowserRouter from './hooks/CustomBrowerRouter';
import { RoutesLayout } from './Routes/Routes';
import i18n from './utils/i18n';
// providers

// in the file where you are creating the theme (invoking the function `createTheme()`)

// import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked',
      retryDelay: 1000,
      refetchInterval: 1000 * 20,
      refetchIntervalInBackground: 1000 * 20,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '93.8vh',
    '&>div': {
      height: '100vh',
    },
    width: 'auto',
  },
});

/** ESRI CSS loader */
// setDefaultOptions({ css: true });
// loadCss();

// eslint-disable-next-line no-unused-vars
let appInsights = null;

const App = () => {
  const analyticsInstrumentKeyId = process.env.REACT_APP_AI_INSTRUMENTID;
  const appConfigConn = process.env.REACT_APP_APPCONFIG_CONN;
  console.log('APPCONFIG', appConfigConn);
  LicenseInfo.setLicenseKey(
    '425cbd6057c138490e23f5a50fbbe324T1JERVI6NDM2NzUsRVhQSVJZPTE2ODQwNDEwNzQwMDAsS0VZVkVSU0lPTj0x',
  );
  const classes = useStyles();
  return (
    <Suspense fallback={<Loader />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <I18nextProvider i18n={i18n}>
            <AuthContextProvider>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                preventDuplicate
              >
                <div className={classes.root}>
                  <CustomBrowserRouter history={history}>
                    <ErrorBoundary
                      FallbackComponent={ErrorBoundaryFallback}
                      onReset={() => {}}
                    >
                      <TelemetryProvider
                        instrumentationKey={analyticsInstrumentKeyId}
                        after={() => {
                          // eslint-disable-next-line no-unused-vars
                          appInsights = getAppInsights();
                        }}
                        data-testid="telemetryprovider"
                      >
                        <QueryClientProvider client={queryClient}>
                          <RoutesLayout />
                        </QueryClientProvider>
                      </TelemetryProvider>
                    </ErrorBoundary>
                  </CustomBrowserRouter>
                </div>
              </SnackbarProvider>
            </AuthContextProvider>
          </I18nextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};

export default App;
