import React, { useEffect, useMemo, useState } from 'react';
import { filter, get, isEmpty, isNil, map } from 'lodash';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  VineaNovaSelectors,
  VineaNovaActions,
  VineaHooks,
} from 'vineanova-redux-artifacts';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';

import { useDispatch } from 'react-redux';
import CheckboxSelectionGrid from '../../components/Grid/CheckboxSelectionGrid';
import {
  IdentityRoleTypes,
  IdentityTypeIds,
  RelationshipTypeIDs,
  dateFormat,
} from '../../constants';
import { SplitButton } from '../../components/SplitButton';
import { MetaDataType } from './interfaces/jobInterfaces';
import { useNavigate } from 'react-router';
import { getBusinessUnitDashboardEnabled } from '../../redux/selectors';
import { StyledFormControl } from '../../components/FormComponent/FormComponent';
import { VineaAutoComplete } from '../../components/ComboBox';
import { useFetchJobData } from './hooks/useFetchJobData';

const CreateJobSupervisors = (identityID: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const today = format(new Date(), dateFormat);
  const [saveIndex, setSaveIndex] = useState(0);
  const [stepperDataSaved, setStepperDataSaved] = useState(false);
  const [apiTriggered, setApiTriggered] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const lkpBusinessUnit = useSelector(state =>
    getBusinessUnitDashboardEnabled(state),
  );
  const {
    isLoaded: insertLoaded,
    isLoading: insertLoading,
    error: insertError,
  } = useSelector(
    VineaNovaSelectors.getDependentIdentityRelationshipBulkEntityMeta,
  ) as MetaDataType;

  const { identityJob: jobData } = useFetchJobData({
    jobId: identityID.identityId,
  });
  const jobBusinessUnitID = get(jobData, 'data.businessUnitID', 0);

  const { data: roleSupervisors } = VineaHooks.useFetchIdentitiesByRole({
    queryParams: {
      RoleTypeIDs: IdentityRoleTypes.SUPERVISOR,
    },
  });

  const [businessUnit, setBusinessUnit] = useState(jobBusinessUnitID);

  const supervisorOptions = useMemo(() => {
    if (isNil(roleSupervisors) || isEmpty(roleSupervisors)) return [];
    const supervisors = map(
      filter(roleSupervisors as object[], (s: any) => {
        return (
          (businessUnit === 0 || s.businessUnitID === businessUnit) &&
          s.identityTypeID === IdentityTypeIds.PERSON
        );
      }),
      (v: any) => {
        return {
          id: v.id,
          displayName: v.displayName,
        };
      },
    );
    return supervisors;
  }, [roleSupervisors, businessUnit]);

  const supervisorGridDataColumns = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
    },
    {
      field: 'displayName',
      headerName: 'Supervisor',
      minwidth: '150px',
      flex: 0.3,
      resizable: false,
      hideable: false,
    },
  ];

  const supervisorGridXData = {
    columns: supervisorGridDataColumns,
    rows: supervisorOptions,
  };

  const [selectedSupervisors, setSelectedSupervisors] = useState<number[]>([]);

  // Save supervisor selection
  const handleOnSave = (event: any, index: number) => {
    setSaveIndex(index);
    if (!isEmpty(selectedSupervisors)) {
      dispatchAPI(
        VineaNovaActions.api.v1.dependentIdentityRelationshipBulk.post.request({
          postBody: {
            relationshipName: 'Job Supervisor',
            ownerIdentityID: identityID.identityId,
            dependentIdentityIDs: selectedSupervisors.join(','),
            relationshipTypeID: RelationshipTypeIDs.JOB_SUPERVISOR,
            comment: 'Created from New Job Wizard',
            effectiveFromDate: today,
          },
        }),
      );
      setApiTriggered(true);
    }
    setSaveClicked(true);
  };

  const handleOnSelectionChange = (selectedSupervisors: number[]) => {
    setSelectedSupervisors(selectedSupervisors);
    setStepperDataSaved(false);
  };

  const handleOnChangeBusinessUnit = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setBusinessUnit(Number(event.target.value));
  };

  useEffect(() => {
    setBusinessUnit(jobBusinessUnitID);
  }, [jobBusinessUnitID]);

  // Show snackbars based on update job and insert job blocks api responses
  useEffect(() => {
    if (saveClicked && apiTriggered && insertLoaded && !insertLoading) {
      if (!insertError) {
        // @ts-ignore
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        if (saveIndex === 0) navigate(`/job/${identityID.identityId}`);
        setStepperDataSaved(true);
        setSaveClicked(false);
        setApiTriggered(false);
      } else {
        // @ts-ignore
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }
    }
    if (saveClicked && !apiTriggered) navigate(`/job/${identityID.identityId}`);
  }, [apiTriggered, saveClicked, insertLoaded, insertLoading, insertError]);

  return (
    <Paper
      elevation={0}
      data-testid="new-job-paper-supervisors"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Box sx={{ minHeight: 455, width: 400, margin: 4 }}>
        <StyledFormControl data-testid="managejobsupervisor-businessUnitID">
          <VineaAutoComplete
            value={businessUnit || 0}
            onChange={handleOnChangeBusinessUnit}
            options={lkpBusinessUnit || []}
            labelVariant="body1"
            inlineLabel={false}
            id="businessUnitID"
            name={t('businessUnitID')}
            label={t('Business Unit')}
            inputProps={{
              name: 'businessUnitID',
            }}
            fullWidth={false}
          />
        </StyledFormControl>
        <Box sx={{ height: 300, marginLeft: 2 }}>
          {' '}
          <CheckboxSelectionGrid
            gridXData={supervisorGridXData}
            handleOnSelectionModelChange={handleOnSelectionChange}
            selectionModel={selectedSupervisors}
          />
        </Box>

        <Box sx={{ marginTop: 4, marginLeft: 2 }}>
          <SplitButton
            color="success"
            onClick={handleOnSave}
            variant="contained"
            minWidth={130}
            className={undefined}
            isExtraSmall={false}
            children={undefined}
            disabled={stepperDataSaved}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default CreateJobSupervisors;
