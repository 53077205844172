import { createSelector } from 'reselect';

const planAllPreferences = state => state.planPreferences;

export const getPlanPreferences = createSelector(
  [planAllPreferences],
  planPreferances => {
    return planPreferances;
  },
);
