import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Alert } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { VineaAlert } from '../../../components/VineaAlert';
import { FieldLabelInput } from '../../../components/TextField';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { addGenericReducer, types, activityState } from '../stateReducers';
import {
  getLkpVehicleType,
  getLkpVehicleMake,
  getBusinessUnit,
} from '../../../redux/selectors';
import { syncValidator } from '../../../utils/validator';
import { VehicleSchema } from '../validations';
import {
  apiTypes,
  commonGridColumns,
  commonGridHeaderNames,
  IdentityTypeIds,
  sagaActionTypes,
  viewDateFormat,
  vineaDetails,
} from '../../../constants';
import { formatDate } from '../../../constants/formatter';
import { Datepicker } from '../../../components/Datepicker';
import { VineaCommonGrid } from '../../../components/Grid';

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  //marginTop: theme.spacing(1),
  minWidth: 200,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  minWidth: 200,
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    minWidth: 200,
  },
}));

const CreateNewVehicle = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    onSaveStepData,
    step,
    stepData,
    calculateNameToCheck,
    matchingIdentities,
  } = props;
  const [formdata, dispatch] = useReducer(addGenericReducer, activityState);
  const businessUnitList = useSelector(state => getBusinessUnit(state));
  const vehicleTypeOptions = useSelector(state => getLkpVehicleType(state));
  const vehicleMakeOptions = useSelector(state => getLkpVehicleMake(state));
  const formWrite = useSelector(state => state.formWrite);
  const [alertType, setAlertType] = useState(false);
  const [inSavingMode, setSaveMode] = useState(false);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = useState(false);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [isIdentityTrigger, setIdentityTrigger] = useState(false);

  useImperativeHandle(ref, () => ({
    saveForm() {
      const validationErrors = syncValidator(VehicleSchema)(formdata);
      if (!isEmpty(validationErrors)) {
        dispatch({ type: types.ERROR, payload: validationErrors });
      } else {
        const data = {
          identityTypeID: IdentityTypeIds.VEHICLE,
          vehicleName: formdata.vehicleName,
          businessUnitID: formdata.businessUnitID,
          vehicleTypeID: formdata.vehicleTypeID,
          vehicleMakeID: formdata.vehicleMakeID,
          vehicleModel: formdata.vehicleModel,
          registrationNo: formdata.registrationNo,
          vehicleCapacity: formdata.vehicleCapacity,
          dateOfPurchase: formdata.dateOfPurchase,
          fleetNumber: formdata.fleetNumber,
          tonnageWeight: formdata.tonnageWeight,
          tareWeight: formdata.tareWeight,
          vin: formdata.vin,
          gpS_ID: formdata.gpS_ID,
          hubOdometerNo: formdata.hubOdometerNo,
        };
        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.vehicle,
            methodType: apiTypes.POST,
          },
        });
        setSaveMode(true);
        setActionTriggered(true);
        setIdentityTrigger(true);
      }
    },
  }));

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleOnBlurOrgName = () => {
    const validationErrors = syncValidator(VehicleSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  useEffect(() => {
    calculateNameToCheck(formdata);
  }, [formdata.vehicleName]);

  useEffect(() => {
    const { data: formWriteData, hasError, isLoading } = formWrite;
    if (inSavingMode && !isLoading) {
      if (!hasError) {
        setSaveMode(false);
        onSaveStepData(
          { data: formWriteData, isSaving: false, isComplete: true },
          step,
        );
      } else {
        setSaveMode(false);
        onSaveStepData(
          {
            data: { identityId: null },
            isSaving: false,
            isComplete: false,
            hasError: true,
          },
          step,
        );
      }
    }
  }, [inSavingMode, formWrite, onSaveStepData, step]);

  useEffect(() => {
    dispatch({
      type: types.UPDATE,
      payload: {
        ...stepData,
        vehicleName: formdata.vehicleName,
        businessUnitID: formdata.businessUnitID,
        vehicleTypeID: formdata.vehicleTypeID,
        vehicleMakeID: formdata.vehicleMakeID,
        vehicleModel: formdata.vehicleModel,
        registrationNo: formdata.registrationNo,
        vehicleCapacity: formdata.vehicleCapacity,
        dateOfPurchase: formdata.dateOfPurchase,
        fleetNumber: formdata.fleetNumber,
        tonnageWeight: formdata.tonnageWeight,
        tareWeight: formdata.tareWeight,
        vin: formdata.vin,
        gpS_ID: formdata.gpS_ID,
        hubOdometerNo: formdata.hubOdometerNo,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { hasError, data, isLoaded } = formWrite;
    if (isLoaded && actionTriggered) {
      if (!hasError && !isEmpty(data) && isIdentityTrigger) {
        setIdentityTrigger(false);
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        setAlertType(true);
        const {
          data: { id, identityTypeID },
        } = formWrite;
        dispatchAPI({
          type: sagaActionTypes.FETCH_IDENTITIES,
          payload: {
            id,
            identityTypeId: identityTypeID,
          },
        });
      }
    }
  }, [
    formWrite,
    actionTriggered,
    isIdentityTrigger,
    enqueueSnackbar,
    t,
    dispatchAPI,
  ]);

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Stack>
        <VineaAlert
          isOpen={!!errorInSubmit}
          onExit={() => setErrorInSubmit(false)}
        />
        <VineaAlert
          isOpen={alertType}
          onExit={() => setAlertType(false)}
          alertType="success"
          message="Activity Created Successfully"
        />
        <Box px={2}>
          {warningInSubmit && (
            <Collapse in={warningInSubmit}>
              <Alert
                variant="standard"
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setWarningInSubmit(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Warning: Nothing to Update
              </Alert>
            </Collapse>
          )}
        </Box>
        <Stack direction="row" justifyContent="space-evenly" width={'100%'}>
          {/* First Column */}
          <Stack direction="column" spacing={theme.spacing(1)}>
            <StyledFormBoxControl>
              <FormControl>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="vehicleName"
                  name="vehicleName"
                  label={t('Name')}
                  size="small"
                  inlineLabel
                  value={formdata.vehicleName}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  error={formdata.errors.vehicleName}
                />
              </FormControl>
            </StyledFormBoxControl>
            <StyledFormBoxControl>
              <FormControl
                margin="none"
                error={!!formdata.errors.businessUnitID}
              >
                <VineaAutoComplete
                  value={formdata.businessUnitID || 0}
                  onChange={handleChange}
                  inlineLabel={false}
                  options={businessUnitList}
                  labelVariant="body1"
                  id="businessunitID"
                  name={t('businessUnitID')}
                  label={t('Business Unit')}
                  inputProps={{
                    name: 'businessUnitID',
                  }}
                />
                {formdata.errors.businessUnitID && (
                  <FormHelperText id="component-error-text">
                    {formdata.errors.businessUnitID}
                  </FormHelperText>
                )}
              </FormControl>
            </StyledFormBoxControl>
            <StyledFormBoxControl>
              <FormControl error={!!formdata.errors.vehicleTypeID}>
                <VineaAutoComplete
                  value={formdata.vehicleTypeID || 0}
                  onChange={handleChange}
                  inlineLabel={false}
                  options={vehicleTypeOptions}
                  labelVariant="body1"
                  id="vehicleTypeID"
                  name={t('vehicleTypeID')}
                  onBlur={handleOnBlurOrgName}
                  label={t('Type')}
                  inputProps={{
                    name: 'vehicleTypeID',
                  }}
                />
                {formdata.errors.vehicleTypeID && (
                  <FormHelperText id="component-error-text">
                    {formdata.errors.vehicleTypeID}
                  </FormHelperText>
                )}
              </FormControl>
            </StyledFormBoxControl>
            <StyledFormBoxControl>
              <FormControl error={!!formdata.errors.vehicleMakeID}>
                <VineaAutoComplete
                  value={formdata.vehicleMakeID || 0}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  inlineLabel={false}
                  options={vehicleMakeOptions}
                  labelVariant="body1"
                  id="vehicleMakeID"
                  name={t('vehicleMakeID')}
                  label={t('Make')}
                  inputProps={{
                    name: 'vehicleMakeID',
                  }}
                />
                {formdata.errors.vehicleMakeID && (
                  <FormHelperText id="component-error-text">
                    {formdata.errors.vehicleMakeID}
                  </FormHelperText>
                )}
              </FormControl>
            </StyledFormBoxControl>
          </Stack>

          {/* Second Column */}
          <Stack direction="column" spacing={theme.spacing(1)}>
            <StyledFormBoxControl>
              <FormControl>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="vehicleModel"
                  name="vehicleModel"
                  label={t('Model')}
                  size="small"
                  inlineLabel
                  value={formdata.vehicleModel}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  error={formdata.errors.vehicleModel}
                />
              </FormControl>
            </StyledFormBoxControl>
            <StyledFormBoxControl>
              <FormControl>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="vehicleCapacity"
                  name="vehicleCapacity"
                  label={t('Capacity')}
                  size="small"
                  inlineLabel
                  value={formdata.vehicleCapacity}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  error={formdata.errors.vehicleCapacity}
                />
              </FormControl>
            </StyledFormBoxControl>
            <StyledFormBoxControl>
              <FormControl>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="registrationNo"
                  name="registrationNo"
                  label={t('Registration Number')}
                  size="small"
                  inlineLabel
                  value={formdata.registrationNo}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  error={formdata.errors.registrationNo}
                />
              </FormControl>
            </StyledFormBoxControl>
            <StyledFormBoxControl>
              <FormControl>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="fleetNumber"
                  name="fleetNumber"
                  label={t('Fleet Number')}
                  size="small"
                  inlineLabel
                  value={formdata.fleetNumber}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  error={formdata.errors.fleetNumber}
                />
              </FormControl>
            </StyledFormBoxControl>
          </Stack>

          {/* Third Column */}
          <Stack direction="column" spacing={theme.spacing(1)}>
            <StyledFormBoxControl>
              <FormControl>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="tonnageWeight"
                  name="tonnageWeight"
                  label={t('Tonnage Weight')}
                  size="small"
                  inlineLabel
                  value={formdata.tonnageWeight}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  error={formdata.errors.tonnageWeight}
                />
              </FormControl>
            </StyledFormBoxControl>
            <StyledFormBoxControl>
              <FormControl>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="tareWeight"
                  name="tareWeight"
                  label={t('Tare Weight')}
                  size="small"
                  inlineLabel
                  value={formdata.tareWeight}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  error={formdata.errors.tareWeight}
                />
              </FormControl>
            </StyledFormBoxControl>
            <StyledFormBoxControl>
              <FormControl>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="vin"
                  name="vin"
                  label={t('VIN')}
                  size="small"
                  inlineLabel
                  value={formdata.vin}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  error={formdata.errors.vin}
                />
              </FormControl>
            </StyledFormBoxControl>
          </Stack>
          {/* Fourth Column */}
          <Stack direction="column" spacing={theme.spacing(1)}>
            <StyledFormBoxControl>
              <FormControl>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="gpS_ID"
                  name="gpS_ID"
                  label={t('GPS ID')}
                  size="small"
                  inlineLabel
                  value={formdata.gpS_ID}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  error={formdata.errors.gpS_ID}
                />
              </FormControl>
            </StyledFormBoxControl>
            <StyledFormBoxControl>
              <FormControl>
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="hubOdometerNo"
                  name="hubOdometerNo"
                  label={t('Hub Odometer  No')}
                  size="small"
                  inlineLabel
                  value={formdata.hubOdometerNo}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  error={formdata.errors.hubOdometerNo}
                />
              </FormControl>
            </StyledFormBoxControl>
            <StyledFormBoxControl>
              <FormControl
                error={!!formdata.errors.dateOfPurchase}
                data-testid="dateOfPurchase-formcontrol"
              >
                <FormControl />
                <Datepicker
                  id="dateOfPurchase"
                  label={t('Date of Purchase')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  size="small"
                  name="dateOfPurchase"
                  value={formdata.dateOfPurchase}
                  onChange={handleChange}
                  inputProps={{
                    'data-testid': 'dateOfPurchase',
                    'data-name': 'dateOfPurchase',
                  }}
                />
                {formdata.errors.dateOfPurchase && (
                  <FormHelperText id="component-error-text">
                    {formdata.errors.dateOfPurchase}
                  </FormHelperText>
                )}
              </FormControl>
            </StyledFormBoxControl>
          </Stack>
        </Stack>
        <Box
          sx={{
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(2),
          }}
        >
          <VineaCommonGrid
            heading={t(commonGridHeaderNames.SIMILAR_IDENTITIES)}
            colHeaders={commonGridColumns.SIMILAR_IDENTITIES}
            tableData={matchingIdentities}
            hasManageOption={false}
            hasActiveToggle={false}
            tableHeight={200}
          />
        </Box>
      </Stack>
    </Paper>
  );
});

CreateNewVehicle.propTypes = {
  stepData: PropTypes.shape({
    vehicleName: PropTypes.string,
    businessUnitID: PropTypes.number,
    vehicleTypeID: PropTypes.number,
    vehicleMakeID: PropTypes.number,
    vehicleModel: PropTypes.string,
    registrationNo: PropTypes.string,
    vehicleCapacity: PropTypes.number,
    dateOfPurchase: PropTypes.string,
    fleetNumber: PropTypes.string,
    tonnageWeight: PropTypes.number,
    tareWeight: PropTypes.number,
    vin: PropTypes.string,
    gpS_ID: PropTypes.string,
    hubOdometerNo: PropTypes.string,
  }),
  onSaveStepData: PropTypes.func,
  step: PropTypes.number,
  calculateNameToCheck: PropTypes.func,
  matchingIdentities: PropTypes.array,
};

CreateNewVehicle.defaultProps = {
  stepData: {},
  onSaveStepData: () => {},
  step: 0,
  calculateNameToCheck: () => {},
  matchingIdentities: [],
};

export default CreateNewVehicle;
