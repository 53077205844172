import * as Yup from 'yup';
import { isValid } from 'date-fns';

export const ActivityProgressSearchSchema = Yup.object().shape({
  growingSubRegionID: Yup.string()
    .typeError('Please choose a sub-region')
    .required('Please choose a sub-region'),
  fromDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
  activityGroupIDs: Yup.array()
    .of(
      Yup.number()
        .integer()
        .required('Please choose at least one activity group'),
    )
    .min(1, 'Please choose at least one activity group')
    .required('Please choose at least one activity group'),
});
