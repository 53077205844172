import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import isEmpty from 'lodash/isEmpty';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DropzoneArea } from 'react-mui-dropzone';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { grey } from '@mui/material/colors';
import { FieldNameSelect } from '../../../components/Select';
import { VineaTextField } from '../../../components/TextField';
import { WithAppContainer } from '../../common/WithAppContainer';
import { VineaButton } from '../../../components/VineaButton';
import { IdentityDialog } from '../../../components/Dialog';
import logger from '../../../utils/winstonLogger';

const dropAreaStyle = ({ theme }) =>
  css`
  padding: ${theme.spacing(2)},
  '& .MuiDropzoneArea-root': {
    minHeight: '100px',
  },
  '& .MuiDropzoneArea-text': {
    margin: theme.spacing(3),
  },
  '& .MuiTypography-h5': {
    fontSize: '13px',
  },
`;

const StyledBox = styled(Box)`
  ${dropAreaStyle}
`;

const formControlStyle = ({ theme }) =>
  css`
margin: ${theme.spacing(1)},
'& .MuiButton-root': {
  padding: ${theme.spacing(1)},
  paddingRight: ${theme.spacing(2)},
  textTransform: 'capitalize',
  fontSize: '11px',
  margin: ${theme.spacing(1)},
},
`;

const StyledTypography = styled(Typography)`
  ${formControlStyle}
`;

const StyledFormControl = styled(FormControl)`
  ${formControlStyle}
`;

const StyledGrid = styled(Grid)`
  ${formControlStyle}
`;

const options = [
  {
    id: 0,
    key: 0,
    value: 'Select',
  },
];
const CreateNewOrganisation = ({ onClose, open }) => {
  const theme = useTheme();
  const [subDialogOpen, setSubDialogOpen] = React.useState(false);
  const [buttonOnFocus, setButtonOnFocus] = React.useState('');
  const [dialogProperties, setDialogProperties] = React.useState({
    name: '',
    attachment: false,
    options: null,
    disable: false,
  });
  const [btnTriggered, setBtnTriggered] = React.useState(false);
  const generateDialogProperties = React.useCallback(
    buttonName => {
      switch (buttonName) {
        case 'Reference':
          return {
            ...dialogProperties,
            name: 'Reference',
            attachment: true,
            options,
          };
        case 'Classification':
          return {
            ...dialogProperties,
            name: 'Classification',
            attachment: false,
            options,
          };
        case 'BankAccount':
          return {
            ...dialogProperties,
            name: 'Bank Account',
            attachment: true,
            disable: true,
          };
        default:
          return { ...dialogProperties };
      }
    },
    [dialogProperties],
  );
  const handleCancel = () => {
    onClose();
  };
  const handleOk = () => {
    onClose();
  };
  const handleSubDialogClose = () => {
    setSubDialogOpen(false);
  };
  const handleClick = evt => {
    evt.preventDefault();
    setButtonOnFocus(evt.currentTarget.value);
    handleSubDialogOpen();
    setBtnTriggered(true);
  };
  const handleSubDialogOpen = () => {
    setSubDialogOpen(true);
  };
  React.useEffect(() => {
    if (!isEmpty(buttonOnFocus) && btnTriggered) {
      setBtnTriggered(false);
      logger.debug(buttonOnFocus);
      logger.debug('buttonOnFocus');
      setDialogProperties(generateDialogProperties(buttonOnFocus));
    }
  }, [btnTriggered, buttonOnFocus, dialogProperties, generateDialogProperties]);
  return (
    <WithAppContainer>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle
          id="confirmation-dialog-title"
          sx={{
            textAlign: 'center',
          }}
        >
          Create New Vineyard
        </DialogTitle>
        <DialogActions>
          <Grid container data-testid="grid-details">
            <Grid container xs={12} direction="row">
              <Grid
                item
                xs={4}
                sx={{
                  padding: theme.spacing(2),
                }}
              >
                <StyledBox border={1} borderColor={grey[400]}>
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText="Drag and drop an image here or click"
                    onChange={files => logger.debug('Files:', files)}
                  />
                  <StyledTypography variant="h5" color="textPrimary">
                    Add vineyard logo
                  </StyledTypography>
                  <StyledTypography variant="subtitle2" color="textPrimary">
                    Not more than 500K
                  </StyledTypography>
                </StyledBox>
                <StyledTypography variant="h6" color="textPrimary">
                  Basic Details
                </StyledTypography>
                <StyledFormControl>
                  <VineaTextField
                    fullWidth
                    autoComplete="off"
                    id="organisationName"
                    size="small"
                    label="Vineyard Name"
                  />
                </StyledFormControl>
                <FormControl>
                  <VineaTextField
                    fullWidth
                    autoComplete="off"
                    id="knownAs"
                    size="small"
                    label="Known As"
                  />
                </FormControl>
                <StyledFormControl>
                  <FieldNameSelect displayEmpty options={options} />
                </StyledFormControl>
              </Grid>
              <Divider orientation="vertical" />
              <StyledGrid item xs={3}>
                <Grid item>
                  <StyledTypography variant="h6" color="textPrimary">
                    Contact Details
                  </StyledTypography>
                  <StyledFormControl>
                    <VineaTextField
                      fullWidth
                      autoComplete="off"
                      id="contactperson"
                      size="small"
                      label="Primary Contact Person"
                    />
                  </StyledFormControl>
                  <StyledFormControl>
                    <VineaTextField
                      autoComplete="off"
                      id="primaryEmail"
                      size="small"
                      label="Primary Email"
                    />
                  </StyledFormControl>
                  <StyledFormControl>
                    <VineaTextField
                      autoComplete="off"
                      id="primaryPhone"
                      size="small"
                      label="Primary Phone"
                    />
                  </StyledFormControl>
                  <StyledFormControl>
                    <VineaTextField
                      autoComplete="off"
                      id="secondaryPhone"
                      size="small"
                      label="Secondary Phone"
                    />
                  </StyledFormControl>
                </Grid>
                <StyledGrid item>
                  <Box border={1} borderColor={grey[400]}>
                    <StyledTypography variant="h6" color="textPrimary">
                      Office Address
                    </StyledTypography>
                    <StyledFormControl>
                      <VineaTextField
                        autoComplete="off"
                        id="address1"
                        size="small"
                        label="Address line 1"
                      />
                    </StyledFormControl>
                    <StyledFormControl>
                      <VineaTextField
                        autoComplete="off"
                        id="address2"
                        size="small"
                        label="Address line 2"
                      />
                    </StyledFormControl>
                    <StyledFormControl>
                      <VineaTextField
                        autoComplete="off"
                        id="suburb"
                        size="small"
                        label="Suburb"
                      />
                    </StyledFormControl>
                    <StyledFormControl>
                      <VineaTextField
                        autoComplete="off"
                        id="city"
                        size="small"
                        label="City"
                      />
                    </StyledFormControl>
                    <StyledFormControl>
                      <VineaTextField
                        autoComplete="off"
                        id="country"
                        size="small"
                        label="Country"
                      />
                    </StyledFormControl>
                    <StyledFormControl>
                      <VineaTextField
                        autoComplete="off"
                        id="zipCode"
                        size="small"
                        label="Post/ Zip code"
                      />
                    </StyledFormControl>
                  </Box>
                </StyledGrid>
                <StyledGrid item>
                  <VineaButton
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                    id="ContactDetails"
                    value="ContactDetails"
                  >
                    Add Contact Details
                  </VineaButton>
                </StyledGrid>
              </StyledGrid>
              <Divider orientation="vertical" />
              <StyledGrid item xs={4}>
                <Grid container direction="column">
                  <Grid item>
                    <StyledTypography variant="h6" color="textPrimary">
                      References
                    </StyledTypography>
                    <VineaButton
                      onClick={handleClick}
                      variant="contained"
                      color="primary"
                      id="BankAccount"
                      value="BankAccount"
                    >
                      Add Bank account
                    </VineaButton>
                    <VineaButton
                      onClick={handleClick}
                      variant="contained"
                      color="primary"
                      id="IRDAccount"
                      value="IRDAccount"
                    >
                      Add IRD Account
                    </VineaButton>
                    <VineaButton
                      onClick={handleClick}
                      variant="contained"
                      color="primary"
                      id="ACCAccount"
                      value="ACCAccount"
                    >
                      Add ACC Account
                    </VineaButton>
                    <VineaButton
                      onClick={handleClick}
                      variant="contained"
                      color="primary"
                      id="Reference"
                      value="Reference"
                      data-testid="addreferences"
                    >
                      Add Reference
                    </VineaButton>
                  </Grid>
                  <Grid item>
                    <StyledTypography variant="h6" color="textPrimary">
                      Classification
                    </StyledTypography>
                    <VineaButton
                      onClick={handleClick}
                      variant="contained"
                      color="primary"
                      id="Classification"
                      value="Classification"
                      data-testid="addclassification"
                    >
                      Add classification
                    </VineaButton>
                  </Grid>
                  <Grid item>
                    <StyledTypography variant="h6" color="textPrimary">
                      Relationships
                    </StyledTypography>
                    <VineaButton
                      onClick={handleClick}
                      variant="contained"
                      color="primary"
                      id="Relationship"
                      value="Relationship"
                    >
                      Add Relationship
                    </VineaButton>
                  </Grid>
                  <Grid item>
                    <StyledTypography variant="h6" color="textPrimary">
                      Documents
                    </StyledTypography>
                    <VineaButton
                      onClick={handleClick}
                      variant="contained"
                      color="primary"
                      id="uploadDocuments"
                      value="uploadDocuments"
                    >
                      Upload Documents
                    </VineaButton>
                  </Grid>
                </Grid>
              </StyledGrid>
            </Grid>
            <VineaButton autoFocus onClick={handleCancel} color="primary">
              Cancel
            </VineaButton>
            <VineaButton onClick={handleOk} color="primary">
              OK
            </VineaButton>
          </Grid>
          <IdentityDialog
            open={subDialogOpen}
            onClose={handleSubDialogClose}
            name={dialogProperties.name}
            attachment={dialogProperties.attachment}
            options={dialogProperties.options}
            disable={dialogProperties.disable}
          />
        </DialogActions>
      </Dialog>
    </WithAppContainer>
  );
};

CreateNewOrganisation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateNewOrganisation;
