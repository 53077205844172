import React, {
  forwardRef,
  useReducer,
  useImperativeHandle,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Alert } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import {
  sagaActionTypes,
  dateFormat,
  apiTypes,
  vineaDetails,
  commonGridHeaderNames,
  commonGridColumns,
} from '../../../constants';
import { FieldLabelInput } from '../../../components/TextField';
import { AccommodationSchema } from '../validations';

import { getBusinessUnit } from '../../../redux/selectors';
import { syncValidator } from '../../../utils/validator';
import { addGenericReducer, accommodationState, types } from '../stateReducers';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { VineaAlert } from '../../../components/VineaAlert';
import { VineaCommonGrid } from '../../../components/Grid';
import { Stack } from '@mui/material';

const CreateNewAccommodation = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const today = format(new Date(), dateFormat);
  const { enqueueSnackbar } = useSnackbar();
  const {
    onSaveStepData,
    step,
    stepData,
    calculateNameToCheck,
    matchingIdentities,
  } = props;
  const [formdata, dispatch] = useReducer(
    addGenericReducer,
    accommodationState,
  );
  const businessUnitList = useSelector(state => getBusinessUnit(state));
  const formWrite = useSelector(state => state.formWrite);

  const [alertType, setAlertType] = React.useState(false);
  const [inSavingMode, setSaveMode] = React.useState(false);
  const [errorInSubmit, setErrorInSubmit] = React.useState(false);
  const [warningInSubmit, setWarningInSubmit] = React.useState(false);
  const [actionTriggered, setActionTriggered] = React.useState(false);
  const [isIdentityTrigger, setIdentityTrigger] = React.useState(false);

  const orgId = useIdentityTypeId();

  useImperativeHandle(ref, () => ({
    saveForm() {
      const validationErrors = syncValidator(AccommodationSchema)(formdata);
      if (!isEmpty(validationErrors)) {
        dispatch({ type: types.ERROR, payload: validationErrors });
      } else {
        const data = {
          IdentityTypeID: orgId,
          DisplayName: formdata.accommodationName,
          BusinessUnitID: formdata.businessUnitID || null,
          CreatedDate: today,
          AccommodationName: formdata.accommodationName,
          Capacity: formdata.capacity,
        };
        // save to stepper reducer
        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.accommodation,
            methodType: apiTypes.POST,
          },
        });
        setSaveMode(true);
        setActionTriggered(true);
        setIdentityTrigger(true);
      }
    },
  }));

  useEffect(() => {
    calculateNameToCheck(formdata);
  }, [formdata.accommodationName]);

  useEffect(() => {
    const { hasError, data, isLoaded } = formWrite;
    if (isLoaded && actionTriggered) {
      if (!hasError && !isEmpty(data) && isIdentityTrigger) {
        setIdentityTrigger(false);
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        setAlertType(true);
        const {
          data: { id, identityTypeID },
        } = formWrite;
        dispatchAPI({
          type: sagaActionTypes.FETCH_IDENTITIES,
          payload: {
            id,
            identityTypeId: identityTypeID,
          },
        });
      }
    }
  });

  useEffect(() => {
    const { data: formWriteData, hasError, isLoading } = formWrite;
    if (inSavingMode && !isLoading) {
      if (!hasError) {
        setSaveMode(false);
        onSaveStepData(
          { data: formWriteData, isSaving: false, isComplete: true },
          step,
        );
      } else {
        setSaveMode(false);
        onSaveStepData(
          {
            data: { identityId: null },
            isSaving: false,
            isComplete: false,
            hasError: true,
          },
          step,
        );
      }
    }
  }, [inSavingMode, formWrite, onSaveStepData, step]);

  useEffect(() => {
    dispatch({
      type: types.UPDATE,
      payload: {
        ...stepData,
        accommodationName: stepData.accommodationName,
        capacity: stepData.capacity,
        businessUnitID: stepData.businessUnitID,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleComponentChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleOnBlur = () => {
    const validationErrors = syncValidator(AccommodationSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <VineaAlert
        isOpen={!!errorInSubmit}
        onExit={() => setErrorInSubmit(false)}
      />
      <VineaAlert
        isOpen={alertType}
        onExit={() => setAlertType(false)}
        alertType="success"
        message="Accommodation Created Successfully"
      />
      <Box px={2}>
        {warningInSubmit && (
          <Collapse in={warningInSubmit}>
            <Alert
              variant="outlined"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setWarningInSubmit(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {t('Error occured')}
            </Alert>
          </Collapse>
        )}
      </Box>
      <Stack direction="row">
        <Grid
          item
          xs={12}
          sx={{
            marginTop: theme.spacing(4),
          }}
        >
          <Box mx={2} mt={3}>
            <FieldLabelInput
              autoComplete="off"
              id="accommodationname"
              name="accommodationName"
              label={t('Accommodation Name')}
              size="small"
              inlineLabel
              value={formdata.accommodationName}
              onChange={handleComponentChange}
              onBlur={handleOnBlur}
              error={formdata.errors.accommodationName}
              helperText={formdata.errors.accommodationName}
              sx={{ width: '250px' }}
            />
          </Box>
          <Box mx={2} mt={3}>
            <FieldLabelInput
              id="capacity"
              label={t('Capacity')}
              name="capacity"
              size="small"
              inlineLabel
              value={formdata.capacity}
              onChange={handleComponentChange}
              onBlur={handleOnBlur}
              error={formdata.errors.capacity}
              helperText={formdata.errors.capacity}
              sx={{ width: '250px' }}
            />
          </Box>
          <Box mx={2} mt={3}>
            <FormControl error={!!formdata.errors.businessUnitID} margin="none">
              <VineaAutoComplete
                value={formdata.businessUnitID || 0}
                onChange={handleComponentChange}
                onBlur={handleOnBlur}
                inlineLabel={false}
                options={businessUnitList}
                labelVariant="body1"
                id="businessunit"
                name={t('Business Unit')}
                label={t('Business Unit')}
                inputProps={{
                  name: 'businessUnitID',
                }}
              />
              {formdata.errors.businessUnitID && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.businessUnitID}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>
        <Box
          sx={{
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(2),
          }}
        >
          <VineaCommonGrid
            heading={t(commonGridHeaderNames.SIMILAR_IDENTITIES)}
            colHeaders={commonGridColumns.SIMILAR_IDENTITIES}
            tableData={matchingIdentities}
            hasManageOption={false}
            hasActiveToggle={false}
          />
        </Box>
      </Stack>
    </Paper>
  );
});

CreateNewAccommodation.propTypes = {
  stepData: PropTypes.shape({
    businessUnitID: PropTypes.number,
    createdDate: PropTypes.string,
    capacity: PropTypes.number,
    accommodationName: PropTypes.string,
    ts: PropTypes.string,
  }),
  onSaveStepData: PropTypes.func,
  step: PropTypes.number,
  calculateNameToCheck: PropTypes.func,
  matchingIdentities: PropTypes.array,
};

CreateNewAccommodation.defaultProps = {
  stepData: {},
  onSaveStepData: () => {},
  step: 0,
  calculateNameToCheck: () => {},
  matchingIdentities: [],
};

export default CreateNewAccommodation;
