import filter from 'lodash/filter';

const initialState = {
  basicSettings: {},
  personDataGrid: {},
  supplyContractDataGrid: {},
  organisationDataGrid: {},
  serviceProviderDataGrid: {},
  jobDataGrid: {},
  jobSiteDataGrid: {},
  vineyardDataGrid: {},
  siteDataGrid: {},
  blocksDataGrid: {},
  samplesDataGrid: {},
  trackingDataGrid: {},
  vehicleDataGrid: {},
  sensorsDataGrid: {
    id: false,
    sensorName: true,
    siteName: true,
    sensorType: true,
    sensorStatus: true,
    sensorMeasureUnit: true,
    startDate: true,
    lastUpdatedDate: true,
    blocks: true,
  },
  sitesDataGrid: {
    id: false,
    siteName: true,
    status: true,
    location: true,
    vineyard: true,
    createdDate: true,
    lastUpdatedDate: true,
  },
  usersDataGrid: {},
  favouriteIdentity: [],
  sampleSetDataGrid: {
    id: true,
    blockFullName: true,
    assignedTo: true,
    recommendedSampleSize: true,
    actualSampleSize: false,
    requestSendDate: true,
    requestDueDate: true,
    requestCompletionDate: false,
    result: true,
    status: true,
  },
  securityGroup: {
    isTabletUser: false,
    isDesktopUser: true,
    isAdministrator: false,
  },
  activityMappingDataGrid: {
    activityCode: true,
    unmatchedTransactionCount: true,
    matchedActivityCode: true,
  },
};

const userPreferencesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'USER_SETTINGS_UPDATE':
      return {
        ...payload,
      };
    case 'PERSON_GRID_COLUMN_UPDATE':
      return {
        ...state,
        personDataGrid: { ...state.personDataGrid, ...payload },
      };
    case 'VEHICLE_GRID_COLUMN_UPDATE':
      return {
        ...state,
        vehicleDataGrid: { ...state.vehicleDataGrid, ...payload },
      };
    case 'SUPPLY_CONTRACT_GRID_COLUMN_UPDATE':
      return {
        ...state,
        supplyContractDataGrid: { ...state.supplyContractDataGrid, ...payload },
      };
    case 'ORGANISATION_GRID_COLUMN_UPDATE':
      return {
        ...state,
        organisationDataGrid: { ...state.organisationDataGrid, ...payload },
      };
    case 'SERVICE_PROVIDER_GRID_COLUMN_UPDATE':
      return {
        ...state,
        serviceProviderDataGrid: {
          ...state.serviceProviderDataGrid,
          ...payload,
        },
      };
    case 'JOB_GRID_COLUMN_UPDATE':
      return {
        ...state,
        jobDataGrid: {
          ...state.jobDataGrid,
          ...payload,
        },
      };
    case 'JOB_SITE_GRID_COLUMN_UPDATE':
      return {
        ...state,
        jobSiteDataGrid: {
          ...state.jobSiteDataGrid,
          ...payload,
        },
      };
    case 'VINEYARD_GRID_COLUMN_UPDATE':
      return {
        ...state,
        vineyardDataGrid: { ...state.vineyardDataGrid, ...payload },
      };
    case 'SITE_GRID_COLUMN_UPDATE':
      return {
        ...state,
        siteDataGrid: { ...state.siteDataGrid, ...payload },
      };
    case 'BLOCKS_GRID_COLUMN_UPDATE':
      return {
        ...state,
        blocksDataGrid: { ...state.trackingDataGrid, ...payload },
      };
    case 'SAMPLES_GRID_COLUMN_UPDATE':
      return {
        ...state,
        samplesDataGrid: { ...state.samplesDataGrid, ...payload },
      };
    case 'TRACKING_GRID_COLUMN_UPDATE':
      return {
        ...state,
        trackingDataGrid: { ...state.trackingDataGrid, ...payload },
      };

    case 'SENSORS_GRID_COLUMN_UPDATE':
      return {
        ...state,
        sensorsDataGrid: { ...state.sensorsDataGrid, ...payload },
      };
    case 'SITES_GRID_COLUMN_UPDATE':
      return {
        ...state,
        sitesDataGrid: { ...state.sitesDataGrid, ...payload },
      };
    case 'USERS_GRID_COLUMN_UPDATE':
      return {
        ...state,
        usersDataGrid: { ...state.usersDataGrid, ...payload },
      };
    case 'ACTIVITY_GRID_COLUMN_UPDATE':
      return {
        ...state,
        activityDataGrid: { ...state.activityDataGrid, ...payload },
      };
    case 'ACTIVITY_DEFAULT_RATE_GRID_COLUMN_UPDATE':
      return {
        ...state,
        activityDefaultRateDataGrid: {
          ...state.activityDefaultRateDataGrid,
          ...payload,
        },
      };
    case 'BASIC_SETTINGS_UPDATE':
      return {
        ...state,
        basicSettings: { ...state.basicSettings, ...payload },
      };
    case 'FAVOURITE_IDENTITY_UPDATE':
      const updatedFavIdentities = filter(
        state.favouriteIdentity,
        f => f?.id !== payload.id,
      );
      if (payload.isFavourite) {
        updatedFavIdentities.push(payload);
      }

      return {
        ...state,
        favouriteIdentity: updatedFavIdentities,
      };
    case 'SAMPLE_SET_DATA_GRID_UPDATE':
      return {
        ...state,
        sampleSetDataGrid: { ...state.sampleSetDataGrid, ...payload },
      };
    case 'USER_SECURITY_GROUP_SETTINGS_UPDATE':
      return {
        ...state,
        securityGroup: { ...state.securityGroup, ...payload },
      };
    default:
      return state;
  }
};

export default userPreferencesReducer;
