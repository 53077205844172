import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import { isEmpty, get, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import VineaChart from './VineaChart';
import { FilterScreen } from '../../components/DashboardComponents';
import { FilterTextDescription } from '../../components/DashboardComponents/FilterTextDescription';
import { DashboardPageTypes } from '../../constants';

const DashboardPage = ({
  chartFilters,
  pageData,
  chartsData,
  handleApplyChartFilter,
  handleApplyDashboardPageFilter,
  handleUpdateChartFilter,
  handleDashboardPageFilterChange,
  pageType,
  basicSettings,
}) => {
  const { hasFilters, dashboardPageID, filters } = pageData;
  const dispatchAPI = useDispatch();

  const [triggerFocusFirstFilterInput, setTriggerFocusFirstFilterInput] =
    useState(null);

  const intialChartFilters = useMemo(() => {
    return chartFilters;
  }, []);

  const chartFiltersChanged = useMemo(() => {
    return !isEqual(intialChartFilters, chartFilters);
  }, [chartFilters]);

  // const { data: chartFilters } = useSelector(state => state.chartFilter);
  const { data: allPageFilters = {} } = useSelector(
    state => state.dashboardPageFilter,
  );
  /** Selectors */
  const { data: searchFilterData = {} } = useSelector(
    state => state.dashboardPageFilter,
  );

  const { filterPanelCollapsed = true } = basicSettings;

  const {
    isLoading: chartsByPageFiltersLoading,
    isLoaded: chartsByPageFiltersLoaded,
  } = useSelector(VineaNovaSelectors.getChartsByPageFiltersEntityMeta);

  const showSkeletons =
    chartsByPageFiltersLoading &&
    !chartsByPageFiltersLoaded &&
    isEmpty(chartsData);

  const selectedFilterValuesText = filters.map(
    ({ filterName, filterParameter, filterOptions }) => ({
      filterName,
      // map from value IDs to strings
      values: get(
        searchFilterData,
        [dashboardPageID, filterParameter, 'value'],
        [],
      ).map(id => filterOptions.find(o => o.id === id)?.value),
    }),
  );

  const handleOnFilterToggle = useCallback(() => {
    dispatchAPI({
      type: 'BASIC_SETTINGS_UPDATE',
      payload: {
        ...basicSettings,
        filterPanelCollapsed: !filterPanelCollapsed,
      },
    });
  }, [filterPanelCollapsed, basicSettings, dispatchAPI]);

  const handleOpenFilter = useCallback(() => {
    if (filterPanelCollapsed) {
      handleOnFilterToggle();
    } else {
      setTriggerFocusFirstFilterInput(!triggerFocusFirstFilterInput);
    }
  }, [
    filterPanelCollapsed,
    handleOnFilterToggle,
    triggerFocusFirstFilterInput,
  ]);

  useEffect(() => {
    handleApplyDashboardPageFilter(dashboardPageID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dashboardPageID,
    chartFiltersChanged,
    allPageFilters,
  ]);

  return (
    <Stack
      direction="row"
      sx={{ width: '100%', height: '100%' }}
      data-testid="dashboardpage-boxwrapper"
    >
      <Box className={classNames('x')}>
        {hasFilters && pageType !== DashboardPageTypes.HOME && (
          <Box pt={3} pb={3} sx={{ height: '100%' }}>
            <FilterScreen
              isFilterOpen={!filterPanelCollapsed}
              onFilterToggle={handleOnFilterToggle}
              pageData={pageData}
              handleApplyDashboardPageFilter={handleApplyDashboardPageFilter}
              handleDashboardPageFilterChange={handleDashboardPageFilterChange}
              handleOpen
              triggerFocusFirstInput={triggerFocusFirstFilterInput}
              basicSettings={basicSettings}
            />
          </Box>
        )}
      </Box>

      <Stack pt={3} sx={{ width: '100%' }}>
        {!showSkeletons && pageType !== DashboardPageTypes.HOME && (
          <>
            <Paper
              elevation={1}
              sx={{
                width: 'max-content',
                padding: 2,
                marginLeft: 2,
                cursor: 'pointer',
              }}
              onClick={handleOpenFilter}
            >
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <FilterTextDescription
                  selectedValuesText={selectedFilterValuesText}
                  showBlankValues
                  isLarge
                  iconColour="primary"
                />
              </Stack>
            </Paper>

            <Box mt={2}>
              <Divider />
            </Box>
          </>
        )}

        <Grid
          container
          pl={2}
          data-testid="dashboardpage-grid-wrapper"
          sx={{
            alignContent: 'flex-start',
            overflowY: 'auto',
            height: '100%',
          }}
          pb={1}
          pt={showSkeletons ? 0 : 2}
        >
          {showSkeletons ? (
            [1, 2, 2, 2].map((aspectRatio, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item key={key} mb={2} mr={2}>
                <Skeleton
                  variant="rectangular"
                  sx={{
                    height: 'calc(180px + 20vh)',
                    minHeight: '100px',
                    aspectRatio: `${aspectRatio}`,
                  }}
                />
              </Grid>
            ))
          ) : (
            <>
              {chartsData.map(chart => (
                <Grid
                  item
                  key={chart.chartID}
                  data-testid="dashboardpage-grid-item"
                  mb={2}
                  mr={2}
                >
                  <Paper
                    elevation={1}
                    data-testid="dashboardpage-paper-wrapper"
                  >
                    <VineaChart
                      chartData={chart}
                      handleApplyChartFilter={handleApplyChartFilter}
                      handleUpdateChartFilter={handleUpdateChartFilter}
                      pageType={pageType}
                    />
                  </Paper>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Stack>
    </Stack>
  );
};

DashboardPage.propTypes = {
  pageData: PropTypes.shape({
    dashboardPageID: PropTypes.number,
    isContentLocked: PropTypes.bool,
    pageDescription: PropTypes.string,
    pageName: PropTypes.string,
    pageSequence: PropTypes.number,
    hasFilters: PropTypes.bool,
  }).isRequired,
  chartsData: PropTypes.arrayOf(
    PropTypes.shape({
      chartAspectRatio: PropTypes.number,
      chartID: PropTypes.number,
      chartSequence: PropTypes.number,
      chartSettings: PropTypes.string,
      chartTitle: PropTypes.string,
      chartTypeID: PropTypes.number,
      dashboardPageID: PropTypes.number,
    }),
  ).isRequired,
  handleApplyChartFilter: PropTypes.func.isRequired,
  handleApplyDashboardPageFilter: PropTypes.func.isRequired,
};

export default DashboardPage;
