import React, { useCallback, useEffect, useMemo } from 'react';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  isArray,
  groupBy,
  keyBy,
  flatMap,
  map,
  mapValues,
  omit,
  isNil,
  get,
  compact,
  filter,
  first,
  isEmpty,
  find,
  uniq,
  reduce,
} from 'lodash';
import { actionTypes, DashboardPageTypes, reducers } from '../../constants';
import {
  getChartsForDashboardPageSelector,
  getSingleChartSelector,
  getUserPreferences,
} from '../../redux/selectors';
import useUserResponsiveLayout from '../../hooks/useUserResponsiveLayout';

export const useDashboardHook = () => {
  const dispatchAPI = useDispatch();
  const { pathname } = useLocation();
  const {
    isBelowLaptop: isTabletDevice,
    isAllowedOnTablet,
    isSupervisor,
    userIdentityID,
  } = useUserResponsiveLayout();

  const pageType = useMemo(() => {
    if (pathname === '/home') return DashboardPageTypes.HOME;
    else if (isTabletDevice && isAllowedOnTablet && isSupervisor)
      return DashboardPageTypes.SUPERVISOR;
    else return DashboardPageTypes.DASHBOARD;
  }, [pathname, isTabletDevice, isAllowedOnTablet, isSupervisor]);

  const { isLoaded: identityRoleIsLoaded, isLoading: identityRoleIsLoading } =
    useSelector(VineaNovaSelectors.getIdentityRoleEntityMeta);

  const {
    isLoaded: dashboardPagesIsLoaded,
    isLoading: dashboardPagesIsLoading,
  } = useSelector(VineaNovaSelectors.getDashboardPagesEntityMeta);

  const chartFilterOptions = useSelector(
    VineaNovaSelectors.getChartFiltersEntityData,
  );

  const { isLoaded: chartFilterOptionsIsLoaded } = useSelector(
    VineaNovaSelectors.getChartFiltersEntityMeta,
  );

  const { isLoaded: pageFilterLookupsLoaded } = useSelector(
    VineaNovaSelectors.getDashboardPageFiltersEntityMeta,
  );

  const { data: lkpDashboardPageFilters } = useSelector(
    VineaNovaSelectors.getDashboardPageFiltersEntity,
  );

  const { data: chartFilters } = useSelector(state => state.chartFilter);
  const { data: pageFilters } = useSelector(state => state.dashboardPageFilter);
  const {
    basicSettings,
    favouriteIdentity = {},
    ...restUserPref
  } = useSelector(state => getUserPreferences(state));

  const { data: latestChartData = {} } = useSelector(
    state => state.latestChartData,
  );

  const singleChartData = useSelector(getSingleChartSelector);
  const chartsForDashboardPageData = useSelector(
    getChartsForDashboardPageSelector,
  );

  // Can display dashboard immeditately if not on tablet, otherwise wait for
  // identity roles and dashboard pages to load, as these will only be displayed on tablet
  // if user is a supervisor
  const canDisplayDashboard = useMemo(() => {
    if (
      !isTabletDevice ||
      (!identityRoleIsLoading &&
        identityRoleIsLoaded &&
        !dashboardPagesIsLoading &&
        dashboardPagesIsLoaded)
    )
      return true;
    else return false;
  }, [
    identityRoleIsLoading,
    identityRoleIsLoaded,
    dashboardPagesIsLoading,
    dashboardPagesIsLoaded,
  ]);

  const getChartFilterOptions = currentDashboardPageID => {
    var pageFiltersData = [];
    const filterParams = uniq(map(lkpDashboardPageFilters, 'filterParameter'));

    filterParams.forEach(filterParam => {
      pageFiltersData = pageFiltersData
        .concat(
          compact(map(flatMap(pageFilters), filterParam)).map(pf => ({
            dashboardPageID: pf.dashboardPageID,
            value: pf.value?.join(',') || '',
            pageLevelFilterID: find(lkpDashboardPageFilters, {
              dashboardPageID: pf.dashboardPageID,
              filterParameter: pf.filterParameter,
            })?.filterID,
          })),
        )
        .filter(pf => pf.dashboardPageID === currentDashboardPageID);
    });

    if (!isEmpty(pageFiltersData)) {
      dispatchAPI(
        VineaNovaActions.api.v1.chartFilters.post.request({
          postBody: {
            pageFilterValues: pageFiltersData,
          },
        }),
      );
    }
  };

  const handleApplyDashboardPageFilter = React.useCallback(
    dashboardPageID => {
      getChartFilterOptions(dashboardPageID);
      // set all the charts to be loading
      const newLatestChartData = {
        ...latestChartData,
        [dashboardPageID]: latestChartData?.[dashboardPageID]?.map(c => ({
          ...c,
          isLoading: true,
        })),
      };
      dispatchAPI({
        type: actionTypes.updateData,
        name: reducers.latestChartData,
        payload: newLatestChartData,
        pageType: pageType,
      });

      // convert from object to array, and convert options to string
      // TODO: don't send all this data for every request
      const chartFiltersArray = flatMap(
        flatMap(omit(chartFilters, ['defaults'])).map(chart => flatMap(chart)),
      ).map(({ chartID, filterParameter, value }) => ({
        ChartID: chartID,
        FilterParameter: filterParameter,
        FilterValue: value?.join(',') || '',
      }));

      // same for page filters
      const pageFiltersArray = flatMap(
        flatMap(pageFilters[dashboardPageID]),
      ).map(({ filterParameter, value }) => ({
        DashboardPageID: dashboardPageID,
        FilterParameter: filterParameter,
        FilterValue: value?.join(',') || '',
      }));

      const favIds = reduce(
        favouriteIdentity,
        (acc, value) => {
          const result = acc + ',' + value.id;
          return result;
        },
        '0',
      );

      dispatchAPI(
        VineaNovaActions.api.v1.chartsByPageFilters.post.request({
          postBody: {
            DashboardPageID: dashboardPageID,
            ChartFilters: chartFiltersArray,
            PageFilters: pageFiltersArray,
            FavVineyardList: pageType === DashboardPageTypes.HOME ? favIds : '',
          },
        }),
      );
    },
    [pageType, chartFilters, pageFilters, dispatchAPI, favouriteIdentity],
  );

  const handleApplyChartFilter = React.useCallback(
    (chartID, dashboardPageID) => {
      // set the chart to be loading
      const newLatestChartData = {
        ...latestChartData,
        [dashboardPageID]: latestChartData?.[dashboardPageID]?.map(c => ({
          ...c,
          isLoading: c.chartID === chartID,
        })),
      };
      dispatchAPI({
        type: actionTypes.updateData,
        name: reducers.latestChartData,
        payload: newLatestChartData,
        pageType: pageType,
      });

      const chartFiltersArray = flatMap(flatMap(chartFilters[chartID])).map(
        ({ filterParameter, value }) => ({
          ChartID: chartID,
          FilterParameter: filterParameter,
          FilterValue: value?.join(',') || '',
        }),
      );

      const pageFiltersArray = flatMap(
        omit(pageFilters?.[dashboardPageID], ['defaults']),
      ).map(({ filterParameter, value }) => ({
        DashboardPageID: dashboardPageID,
        FilterParameter: filterParameter,
        FilterValue: value?.join(',') || '',
      }));

      dispatchAPI(
        VineaNovaActions.api.v1.chartsByChartFilters.post.request({
          postBody: {
            ChartID: chartID,
            ChartFilters: chartFiltersArray,
            PageFilters: pageFiltersArray,
          },
        }),
      );
    },
    [pageType, pageFilters, chartFilters, dispatchAPI],
  );

  const handleDashboardPageFilterChange = useCallback(
    dashboardPageID => {
      // same for page filters
      const pageFiltersArray = flatMap(
        flatMap(pageFilters[dashboardPageID]),
      ).map(({ filterParameter, value }) => ({
        DashboardPageID: dashboardPageID,
        FilterParameter: filterParameter,
        FilterValue: value?.join(',') || '',
      }));

      dispatchAPI(
        VineaNovaActions.api.v1.dashboardPageFilters.post.request({
          postBody: {
            pageFilterValues: pageFiltersArray,
          },
        }),
      );
    },
    [pageType, pageFilters, dispatchAPI],
  );

  // Initialise chart filters, setting them to their defaults
  useEffect(() => {
    if (!isArray(chartFilterOptions) || !chartFilterOptionsIsLoaded) return;

    // default the selected option to the first option in the list
    const defaultChartFilterValues = mapValues(
      groupBy(
        filter(
          chartFilterOptions,
          db => !isNil(get(first(db.filterOptions, {}), 'id')),
        ).map(({ chartID, filterParameter, filterOptions }) => ({
          chartID,
          filterParameter,
          value: [filterOptions[0].id],
        })),
        'chartID',
      ),
      optionsArray => keyBy(optionsArray, 'filterParameter'),
    );

    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.chartFilter,
      payload: {
        ...defaultChartFilterValues,
        defaults: defaultChartFilterValues,
      },
      pageType: pageType,
    });
  }, [pageType, dispatchAPI, chartFilterOptions, chartFilterOptionsIsLoaded]);

  // Initialise page filters, setting them to their defaults
  useEffect(() => {
    if (!isArray(lkpDashboardPageFilters) || !pageFilterLookupsLoaded) return;

    const defaultBusinessUnit = get(
      basicSettings,
      'searchFilterBusinessUnitID',
      get(basicSettings, 'businessUnitID'),
    );

    // default the selected option to the first option in the list
    const defaultPageFilterValues = mapValues(
      groupBy(
        filter(
          lkpDashboardPageFilters,
          db => !isNil(get(first(db.filterOptions, {}), 'id')),
        ).map(({ dashboardPageID, filterParameter, filterOptions }) => ({
          dashboardPageID,
          filterParameter,
          value:
            filterParameter === 'BusinessUnitID' && !isNil(defaultBusinessUnit)
              ? [defaultBusinessUnit]
              : [filterOptions[0].id],
        })),
        'dashboardPageID',
      ),
      optionsArray => keyBy(optionsArray, 'filterParameter'),
    );

    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.dashboardPageFilter,
      payload: {
        ...defaultPageFilterValues,
        defaults: defaultPageFilterValues,
      },
      pageType: pageType,
    });
  }, [
    pageType,
    basicSettings,
    dispatchAPI,
    lkpDashboardPageFilters,
    pageFilterLookupsLoaded,
  ]);

  // if new single chart data comes in, add it into the latest chart data object
  useEffect(() => {
    if (!isEmpty(singleChartData)) {
      const chart = singleChartData?.[0];

      // assume that the chart's dashboard page already exists
      if (chart?.chartID && chart?.dashboardPageID) {
        const newLatestChartData = {
          ...latestChartData,
          [chart.dashboardPageID]: [
            ...latestChartData?.[chart.dashboardPageID]?.filter(
              c => c?.chartID !== chart?.chartID,
            ),
            chart,
          ],
        };

        dispatchAPI({
          type: actionTypes.updateData,
          name: reducers.latestChartData,
          payload: newLatestChartData,
          pageType: pageType,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType, singleChartData, dispatchAPI]);

  // if new dashboard page chart data comes in, add it into the latest chart data object
  useEffect(
    () => {
      const dashboardPageID = chartsForDashboardPageData?.[0]?.dashboardPageID;

      if (dashboardPageID) {
        const chartIDs = chartsForDashboardPageData.map(c => c.chartID);
        const oldChartDataForDashboardPage =
          latestChartData?.[dashboardPageID] || [];
        const newLatestChartData = {
          ...latestChartData,
          [dashboardPageID]: [
            ...oldChartDataForDashboardPage.filter(
              c => !chartIDs.includes(c.chartID),
            ),
            ...chartsForDashboardPageData,
          ],
        };

        dispatchAPI({
          type: actionTypes.updateData,
          name: reducers.latestChartData,
          payload: newLatestChartData,
          pageType: pageType,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageType, chartsForDashboardPageData, dispatchAPI],
  );

  // If not on tablet, then immediately fetch dashboard pages and filters,
  // otherwise, wait for identity roles to load before fetching
  useEffect(() => {
    if (!isTabletDevice || (identityRoleIsLoaded && !identityRoleIsLoading)) {
      dispatchAPI(
        VineaNovaActions.api.v1.dashboardPages.get.request({
          queryParams: {
            pageType: pageType,
          },
        }),
      );

      dispatchAPI(
        VineaNovaActions.api.v1.dashboardPageFilters.get.request({
          queryParams: {
            pageType: pageType,
            supervisorID:
              pageType === DashboardPageTypes.SUPERVISOR
                ? userIdentityID
                : null,
          },
        }),
      );
    }
  }, [
    pageType,
    isTabletDevice,
    userIdentityID,
    identityRoleIsLoaded,
    identityRoleIsLoading,
  ]);

  return {
    chartFilters,
    basicSettings,
    canDisplayDashboard,
    pageType,
    handleApplyChartFilter,
    handleApplyDashboardPageFilter,
    handleDashboardPageFilterChange,
  };
};
