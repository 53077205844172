import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ErrorBoundary } from 'react-error-boundary';

import { formatDate } from '../../../constants/formatter';
import { VineaButton } from '../../../components/VineaButton';
import { ImageDialog } from '../../../components/ImageDialog';
import { Datepicker } from '../../../components/Datepicker';
import { VineaTextField } from '../../../components/TextField';
import { ImageUpload } from '../../../components/ImageUpload';
import useImageUtils from '../../../hooks/ImageUtils';
import { DiscardDialog, DeleteDialog } from '../../../components/Dialog';
import { viewDateFormat } from '../../../constants';
import { GreenSwitch } from '../../../components/Switch';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';

const StyledDiv = styled('div')(() => ({
  marginTop: 1,
}));

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

const StyledDrawerControl = styled('div')(() => ({
  width: 350,
}));

const StyledCloseDiv = styled('div')(() => ({
  paddingLeft: 10,
}));

export const NoteDrawer = ({
  isOpen,
  handleOnClose,
  handleOnDelete,
  handleOnSave,
  handleOnChange,
  handleOnImageUpload,
  handleOnDeleteImage,
  handleOnTogglePrivate,
  data,
  isEdit,
  blockSelected,
  vineyardSelected,
}) => {
  const theme = useTheme();
  const [state, setState] = React.useState(isOpen);
  const { t } = useTranslation();

  const [deleteNoteDialog, setDeleteNoteDialog] = React.useState(null);
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(null);

  const [isFullScreenImage, setIsFullScreenImage] = React.useState(false);

  const [validationErrors] = React.useState({});

  const { imageDataUrl } = useImageUtils(
    data.noteImage?.base64 || data.noteImage,
  );

  const vineyardBlockSelected = `${vineyardSelected || ''} ${
    blockSelected ? '/' : ''
  } ${blockSelected || ''}`;

  React.useEffect(() => {
    setState(isOpen);
  }, [isOpen]);

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnImageUploadData = imageSet => {
    if (imageSet && imageSet.base64) {
      handleOnImageUpload(imageSet);
    }
  };

  const handleOnCloseNoteDeleteDialog = () => {
    setDeleteNoteDialog(false);
  };

  const handleOnNoteDeleteRequest = () => {
    setDeleteNoteDialog(true);
  };

  const handleOnNoteDeleteConfirm = () => {
    handleOnDelete();
  };

  const handleOnCloseImage = () => {
    setIsFullScreenImage(false);
  };

  const handleOnOpenImage = () => {
    setIsFullScreenImage(true);
  };

  const handleOnPressSwitch = () => {
    if (data.isEditable) {
      handleOnTogglePrivate();
    }
  };

  const handleOnDiscard = () => {
    const { hasChanges } = data;
    if (hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      handleOnClose();
    }
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  const list = () => (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleOnClickAway}>
      <StyledDrawerControl>
        <StyledDiv
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography
                sx={{
                  padding: theme.spacing(2),
                }}
                variant="body1"
              >
                {vineyardBlockSelected}
              </Typography>
            </Box>
            <Box>
              <IconButton
                aria-label="toggle sidebar"
                onClick={handleOnDiscard}
                onMouseDown={() => {}}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {isEdit && (
            <Typography
              variant="subtitle2"
              sx={{
                paddingLeft: theme.spacing(2),
              }}
            >
              {t(`${data.isEditable ? 'Edit' : 'View'} Note`)}
            </Typography>
          )}

          {!isEdit && (
            <Typography
              variant="subtitle2"
              sx={{
                paddingLeft: theme.spacing(2),
              }}
            >
              {t('Add Note to Block')}
            </Typography>
          )}

          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />

            {!data.isEditable && (
              <Typography
                sx={{
                  padding: theme.spacing(2),
                  color: 'red',
                }}
                variant="subtitle2"
              >
                {t('This note is read-only')}
              </Typography>
            )}

            <FormControl
              sx={{
                padding: theme.spacing(2),
                flex: 1,
                display: 'flex',
                marginTop: 6,
              }}
              data-testid="datepicker-formcontrol"
              error={validationErrors.date}
            >
              <Datepicker
                label={t('Date')}
                placeholder="DD/MM/YYYY"
                inputFormat={viewDateFormat}
                formatDate={formatDate}
                variant="outlined"
                size="small"
                name="date"
                disablePast={false}
                value={data.noteDate}
                onChange={handleOnChange}
                fullWidth
                inputProps={{
                  readOnly: !data.isEditable,
                }}
              />
              {validationErrors.date && (
                <FormHelperText id="component-error-text">
                  {validationErrors.date}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              sx={{
                padding: theme.spacing(2),
                flex: 1,
                display: 'flex',
              }}
            >
              <VineaTextField
                multiline
                label={t('Note')}
                name="note"
                rows={5}
                maxRows={10}
                onChange={handleOnChange}
                value={data.note}
                fullWidth
                inputProps={{
                  readOnly: !data.isEditable,
                }}
              />

              <Box mt={2}>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={data.isPrivate}
                      onChange={handleOnPressSwitch}
                      name="private"
                    />
                  }
                  label={
                    <Typography variant="caption">
                      {t('Internal use only')}
                    </Typography>
                  }
                />
              </Box>
            </FormControl>

            <FormControl
              sx={{
                padding: theme.spacing(2),
                flex: 1,
                display: 'flex',
              }}
            >
              {data.isEditable && (
                <ImageUpload handleOnChange={handleOnImageUploadData} />
              )}
            </FormControl>
            <Box>
              {imageDataUrl && (
                <FormControl
                  sx={{
                    padding: theme.spacing(2),
                    flex: 1,
                    display: 'flex',
                  }}
                >
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      boxShadow: 'none',
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        sx={{
                          height: '300px',
                          width: '300px',
                          flex: 1,
                          flexDirection: 'column',
                        }}
                        image={imageDataUrl}
                        title="Note image"
                        onClick={handleOnOpenImage}
                      />
                    </CardActionArea>
                    <CardActions>
                      {data.isEditable && (
                        <IconButton
                          aria-label="delete"
                          onClick={handleOnDeleteImage}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </CardActions>
                  </Card>
                </FormControl>
              )}
            </Box>
            {data.isEditable && (
              <StyledButtonControlDiv>
                <div>
                  <VineaButton
                    color="success"
                    onClick={handleOnSave}
                    variant="contained"
                    minWidth={100}
                  >
                    {t('Save ')}
                  </VineaButton>
                </div>
                {isEdit && (
                  <StyledCloseDiv>
                    <VineaButton
                      variant="contained"
                      onClick={handleOnNoteDeleteRequest}
                      color="error"
                    >
                      {t('Delete')}
                    </VineaButton>
                  </StyledCloseDiv>
                )}
              </StyledButtonControlDiv>
            )}
          </ErrorBoundary>
        </StyledDiv>

        <DeleteDialog
          onClose={handleOnCloseNoteDeleteDialog}
          dialogHeader="Delete note"
          open={deleteNoteDialog}
          rowId={0}
          dialogActionTrigger={handleOnNoteDeleteConfirm}
        />
        <DiscardDialog
          open={discardDialogOpen}
          onClose={() => setDiscardDialogOpen(false)}
          handleSaveChanges={handleOnDiscardSaveChanges}
          handleDiscardChanges={handleOnDiscardConfirm}
        />
        <ImageDialog
          isOpen={isFullScreenImage}
          handleClose={handleOnCloseImage}
          image={imageDataUrl}
        />
      </StyledDrawerControl>
    </ClickAwayListener>
  );

  return (
    <Drawer anchor="right" open={state} onClose={toggleDrawer}>
      {list('right')}
    </Drawer>
  );
};

NoteDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnImageUpload: PropTypes.func.isRequired,
  handleOnDeleteImage: PropTypes.func.isRequired,
  handleOnTogglePrivate: PropTypes.func.isRequired,
  data: PropTypes.shape({
    note: PropTypes.string,
    noteImage: PropTypes.shape({
      base64: PropTypes.string,
    }),
    isPrivate: PropTypes.bool,
    noteDate: PropTypes.string,
    hasChanges: PropTypes.bool,
    isEditable: PropTypes.bool,
  }).isRequired,
  isEdit: PropTypes.bool.isRequired,
  blockSelected: PropTypes.string,
  vineyardSelected: PropTypes.string,
};

NoteDrawer.defaultProps = {
  vineyardSelected: '',
  blockSelected: '',
};
