import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { stringSimilarity } from 'string-similarity-js';
import { forEach, get, isEmpty, isNil, orderBy } from 'lodash';

interface IdentityMatchType {
  IdentityID: number;
  IdentityName: string;
  Similarity: number;
  businessUnit: string;
  status: boolean;
}

interface IdentitySearchType {
  id: number;
  identityTypeID: number;
  name: string;
  status: boolean;
  businessUnit: string;
}

const useFindDuplicateIdentitiesHook = (
  identityTypeID: number,
  searchIdentityName: string,
  numberOfMatches: number = 5,
) => {
  const dispatchAPI = useDispatch();
  const {
    data: identities,
    isLoading,
    isLoaded,
  } = useSelector(VineaNovaSelectors.getSearchIdentityEntity) as any;

  const [matchingIdentities, setMatchingIdentities] = useState<
    IdentityMatchType[]
  >([]);

  const findMatchingIdentities = useCallback(() => {
    if (!isNil(searchIdentityName) && searchIdentityName.length > 0) {
      const matches: IdentityMatchType[] = [];

      forEach(identities, (identity: IdentitySearchType) => {
        const similarityScore = stringSimilarity(
          get(identity, 'name', '').replace(/,/g, ''),
          searchIdentityName,
        );
        if (similarityScore > 0.5) {
          matches.push({
            IdentityID: identity.id,
            IdentityName: identity.name,
            Similarity: similarityScore,
            businessUnit: identity.businessUnit,
            status: identity.status,
          });
        }
      });

      const topMatches = orderBy(matches, ['Similarity'], ['desc']).slice(
        0,
        numberOfMatches,
      );
      setMatchingIdentities([...topMatches]);
    }
  }, [identities, searchIdentityName]);

  // Fetch all identities of the given type
  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.searchIdentity.post.request({
        postBody: {
          IdentityTypeID: identityTypeID,
          FindNonActive: true,
        },
      }),
    );
  }, [identityTypeID]);

  // Find matching identities
  useEffect(() => {
    if (
      isEmpty(identities) ||
      isLoading ||
      !isLoaded ||
      isNil(searchIdentityName)
    ) {
      setMatchingIdentities([]);
    } else {
      findMatchingIdentities();
    }
  }, [identities, searchIdentityName, isLoaded, isLoading]);

  return { matchingIdentities };
};

export default useFindDuplicateIdentitiesHook;
