import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { isNull } from 'lodash';
import LookupsHook from '../hooks/LookupHook';

const LookupLayout = props => {
  const { children, lookups, id } = props;
  const { isReady } = isNull(id)
    ? LookupsHook(lookups)
    : LookupsHook(lookups, id);

  return (
    <>
      {!isReady && <CircularProgress />}
      {isReady && children}
    </>
  );
};

export default LookupLayout;
