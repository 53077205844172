import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    padding: 0,
    display: 'flex',
  },
}));

export const ImageDialog = ({ isOpen, handleClose, image }) => {
  return (
    <>
      {isOpen && (
        <StyledDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isOpen}
        >
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'black',
                background: 'white',
                opacity: 0.3,
                '&:hover': {
                  background: 'white',
                  opacity: 1,
                },
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <img alt="Full-size view" width="100%" src={image} />
          </DialogContent>
        </StyledDialog>
      )}
    </>
  );
};

ImageDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
};
