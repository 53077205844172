/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { FieldNameSelect } from '../../../components/Select';
import { getLookupTrellis } from '../../../redux/selectors';

export const TrellisStatusDropdown = ({ id, name, value, onChange, rowId }) => {
  const trellisOptions = useSelector(getLookupTrellis);

  const handleOnValueChange = evt => {
    if (onChange) {
      onChange(evt, rowId);
    }
  };

  return (
    <FieldNameSelect
      sx={{
        width: '90px',
      }}
      id={id}
      value={value}
      onChange={handleOnValueChange}
      displayLabel={false}
      options={trellisOptions}
      inputProps={{
        name,
      }}
    />
  );
};
