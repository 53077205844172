import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { FrappeGantt, ViewMode } from '../../components/FrappeGantt';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { syncValidator } from '../../utils/validator';
import { FrappeGanttSchema } from '../../components/FrappeGantt/validations';
import FormHelperText from '@mui/material/FormHelperText';
import GrantTaskReact from '../../components/FrappeGantt/grantTaskReact';
import { isEmpty } from 'lodash';

const tasks = [
  {
    start: '2023-01-15T13:00:00.000Z',
    end: '2023-01-22T13:00:00.000Z',
    name: 'Redesign website',
    id: 'Task 0',
    progress: 1,
    dependencies: [],
  },
  {
    start: '2023-01-16T13:00:00.000Z',
    end: '2023-01-19T13:00:00.000Z',
    name: 'Write new content',
    id: 'Task 1',
    progress: 30,
    dependencies: ['Task 0'],
  },
  {
    start: '2023-01-18T13:00:00.000Z',
    end: '2023-01-21T13:00:00.000Z',
    name: 'Apply new styles',
    id: 'Task 2',
    progress: 11,
    dependencies: ['Task 1'],
  },
  {
    start: '2023-01-22T13:00:00.000Z',
    end: '2023-01-22T13:00:00.000Z',
    name: 'Review',
    id: 'Task 3',
    progress: 66,
    dependencies: ['Task 2'],
  },
  {
    start: '2023-01-23T13:00:00.000Z',
    end: '2023-01-24T13:00:00.000Z',
    name: 'Deploy',
    id: 'Task 4',
    progress: 16,
    dependencies: [],
  },
  {
    start: '2023-01-25T13:00:00.000Z',
    end: '2023-01-25T13:00:00.000Z',
    name: 'Go Live!',
    id: 'Task 5',
    progress: 2,
    dependencies: ['Task 0'],
    custom_class: 'bar-milestone',
  },
];

// @ts-nocheck

const FrappeGanttSample = () => {
  const [state, setState] = useState({ mode: ViewMode.Day });
  const [open, setOpen] = React.useState(false);
  const [validationsErrors, setValidationErrors] = useState({});
  const [taskDetails, setTaskDetails] = useState({
    id: '',
    name: '',
    start: new Date(),
    end: new Date(),
    progress: 0,
    dependencies: [],
  });
  // const theme = useTheme();
  const [task, setTask] = useState(tasks);
  // const [view, setView] = React.useState('');
  // const [addTask, setAddTask] = useState({ tasks });

  //   state = { mode: ViewMode.Month };
  //   componentDidMount() {
  //     console.log("test");

  //     setTimeout(() => {
  //       console.log("Setting State!");
  //       this.setState({ mode: ViewMode.Week });

  //       setTimeout(() => {
  //         console.log("Setting State!");
  //         this.setState({ mode: ViewMode.HalfDay });
  //       }, 3000);
  //     }, 3000);
  //   }
  const handleViewChange = event => {
    setState({ mode: event.target.value });
  };

  const handleNameChange = event => {
    setTaskDetails({ ...taskDetails, name: event.target.value });
  };

  const handleStartDateChange = event => {
    setTaskDetails({ ...taskDetails, start: event.target.value });
  };

  const handleEndDateChange = event => {
    setTaskDetails({ ...taskDetails, end: event.target.value });
  };

  const handleProgressChange = event => {
    setTaskDetails({ ...taskDetails, progress: event.target.value });
  };

  const handleDependencyChange = event => {
    setTaskDetails({ ...taskDetails, dependencies: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    const currentErrors = syncValidator(FrappeGanttSchema)(taskDetails);
    console.log('current errors', currentErrors);
    setValidationErrors(currentErrors);
    if (isEmpty(currentErrors)) {
      const allNewTasks = task.concat([taskDetails]);
      setTask(allNewTasks);
      setOpen(false);
    }
  };

  return (
    <Grid item xs={12} sm={10}>
      <Box maxWidth="800px">
        <Typography component="p" variant="h3">
          Frappe Grantt Chart
        </Typography>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="selectlabel">Select</InputLabel>
            <Select
              labelId="selectlabel"
              id="selectview"
              value={state.mode}
              label="Select"
              onChange={handleViewChange}
            >
              <MenuItem value={ViewMode.QuarterDay}>QuarterDay</MenuItem>
              <MenuItem value={ViewMode.HalfDay}>HalfDay</MenuItem>
              <MenuItem value={ViewMode.Day}>Day</MenuItem>
              <MenuItem value={ViewMode.Week}>Week</MenuItem>
              <MenuItem value={ViewMode.Month}>Month</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 120 }}>
          <Typography component="p" variant="h3">
            ADD Task{' '}
          </Typography>
          <Button variant="outlined" onClick={handleClickOpen}>
            Add a New Task
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>New Task</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please fill the following details to create a new task
              </DialogContentText>
              {/* <FormControl error={!!validationsErrors.name}> */}
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Task Name"
                type="text"
                fullWidth
                variant="standard"
                placeholder="Enter Task Name"
                onChange={handleNameChange}
                error={validationsErrors.name}
              />
              {/* {validationsErrors && (
                  <FormHelperText id="name">
                    {validationsErrors.name}
                  </FormHelperText>
                )}
              </FormControl> */}
              <TextField
                autoFocus
                margin="dense"
                id="start"
                label="Start Date"
                type="date"
                fullWidth
                variant="standard"
                onChange={handleStartDateChange}
              />

              <TextField
                autoFocus
                margin="dense"
                id="end"
                label="End Date"
                type="date"
                fullWidth
                variant="standard"
                onChange={handleEndDateChange}
              />
              <TextField
                autoFocus
                margin="dense"
                id="progress"
                label="Task Progress"
                type="number"
                fullWidth
                variant="standard"
                placeholder="Task Progress"
                onChange={handleProgressChange}
              />
              <TextField
                autoFocus
                margin="dense"
                id="dependency"
                label="Task Dependency"
                type="text"
                fullWidth
                variant="standard"
                placeholder="Enter Task Dependencies"
                onChange={handleDependencyChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleAdd}>Add</Button>
            </DialogActions>
          </Dialog>
        </Box>
        <FrappeGantt
          tasks={task}
          viewMode={state.mode}
          onClick={task => console.log(task)}
          onDateChange={(task, start, end) => console.log(task, start, end)}
          onProgressChange={(task, progress) => console.log(task, progress)}
          onTasksChange={taskChange => console.log(taskChange)}
        />
        <GrantTaskReact />
      </Box>
    </Grid>
  );
};

export default FrappeGanttSample;
