// HistoryListener.js
// import { BrowserHistory } from 'history';
import { useEffect, useState, useContext } from 'react';
// eslint-disable-next-line camelcase
import { UNSAFE_NavigationContext } from 'react-router-dom';

const useLocationListener = () => {
  const [locationKeys, setLocationKeys] = useState([]);
  const navigation = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    return navigation.listen(({ location }) => {
      if (navigation.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (navigation.action === 'POP') {
        if (locationKeys[1] === location.key) {
          // eslint-disable-next-line no-unused-vars
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          setLocationKeys(keys => [location.key, ...keys]);
          // Handle back event
        }
      }
    });
  }, [locationKeys, navigation]);

  return {
    locationKeys,
  };
};

export default useLocationListener;
