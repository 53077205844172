/* eslint-disable import/named */
import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import { ErrorBoundary } from 'react-error-boundary';

import { formatDate } from '../../../constants/formatter';
import { VineaButton } from '../../../components/VineaButton';
import { Datepicker } from '../../../components/Datepicker';
import { VineaTextField } from '../../../components/TextField';
import { FileUpload } from '../../../components/ImageUpload';
import { DiscardDialog } from '../../../components/Dialog';
import { viewDateFormat } from '../../../constants';
import { VineaAutoComplete } from '../../../components/ComboBox';
// eslint-disable-next-line
import type { DocumentDataType } from './DocumentsGrid';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';

const StyledButtonControlDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
  marginTop: theme.spacing(8),
}));

const StyledDrawerControlDiv = styled('div')(() => ({
  width: 350,
}));

const StyledCloseButtonDiv = styled('div')(() => ({
  paddingLeft: 10,
}));

type AttachedDocumentsDrawerProps = {
  isOpen: boolean;
  data: DocumentDataType;
  handleOnClose: () => void;
  handleOnSave: () => void;
  handleOnChange: (evt: any) => void;
  handleOnSelectDocument: (file: File, base64: string) => void;
  handleOnPressDownload: () => void;
  hasChanges: boolean;
  hasSelectedDocument: boolean;
  isEdit: boolean;
  documentStatusOptions: { id: number; key: number; value: string }[];
  acceptedFileExtensions: string;
};

export function DocumentsDrawer({
  isOpen,
  handleOnClose,
  handleOnSave,
  handleOnChange,
  handleOnSelectDocument,
  handleOnPressDownload,
  data,
  hasChanges,
  hasSelectedDocument,
  isEdit,
  documentStatusOptions,
  acceptedFileExtensions,
}: AttachedDocumentsDrawerProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [discardDialogOpen, setDiscardDialogOpen] =
    React.useState<boolean>(false);

  const isAdd = !isEdit;
  let acceptedFileExtensionsArray = acceptedFileExtensions;
  if (isEmpty(acceptedFileExtensions)) {
    acceptedFileExtensionsArray =
      'text/*, image/*, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }
  const toggleDrawer = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnDiscard = () => {
    if (hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      handleOnClose();
    }
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={handleOnClickAway}
      >
        <StyledDrawerControlDiv role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography
                sx={{
                  padding: theme.spacing(2),
                }}
                variant="body1"
              >
                {`${t('Identity Notes')} / ${t('Linked Documents')}`}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOnDiscard} size="large">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography
            variant="subtitle2"
            sx={{
              paddingLeft: theme.spacing(2),
            }}
          >
            {isEdit ? t('Edit Document') : t('Upload Document')}
          </Typography>

          {/* @ts-ignore */}
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />
            <FormControl
              sx={{
                padding: theme.spacing(2),
                flex: 1,
                display: 'flex',
                marginTop: 6,
              }}
              data-testid="datepicker-formcontrol"
            >
              <Datepicker
                label={t('Date')}
                placeholder="DD/MM/YYYY"
                inputFormat={viewDateFormat}
                formatDate={formatDate}
                variant="outlined"
                size="small"
                name="date"
                disablePast={false}
                value={data?.date || ''}
                onChange={handleOnChange}
                fullWidth
                id={''}
                inputProps={{
                  'data-testid': 'documentdrawerdatepicker',
                  'data-name': 'date',
                }}
              />
              {data.errors.date && (
                <FormHelperText>{data.errors.date}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              sx={{
                padding: theme.spacing(2),
                flex: 1,
                display: 'flex',
              }}
            >
              <VineaTextField
                label={t('Subject')}
                name="subject"
                onChange={handleOnChange}
                value={data.subject}
                fullWidth
              />
              {data.errors.subject && (
                <FormHelperText>{data.errors.subject}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              sx={{
                padding: theme.spacing(2),
                flex: 1,
                display: 'flex',
              }}
            >
              <VineaAutoComplete
                label={t('Status')}
                value={data.statusId}
                onChange={handleOnChange}
                options={documentStatusOptions}
                labelVariant="body1"
                name="statusId"
                inputProps={{
                  name: 'statusId',
                }}
                fullWidth={false}
              />
              {data.errors.statusId && (
                <FormHelperText>{data.errors.statusId}</FormHelperText>
              )}
            </FormControl>

            {isAdd && (
              <Box mt={2} mb={3}>
                <FileUpload
                  // @ts-ignore
                  handleOnChange={handleOnSelectDocument}
                  // @ts-ignore
                  acceptedFileExtensions={acceptedFileExtensionsArray}
                />
              </Box>
            )}

            {hasSelectedDocument && (
              <>
                <FormControl
                  sx={{
                    padding: theme.spacing(2),
                    flex: 1,
                    display: 'flex',
                  }}
                >
                  <VineaTextField
                    label={t('Filename')}
                    value={data.filename}
                    name="filename"
                    fullWidth
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                  <Box pl={2}>
                    <FormHelperText>
                      {t('This field is read-only')}
                    </FormHelperText>
                  </Box>
                </FormControl>

                <FormControl
                  sx={{
                    padding: theme.spacing(2),
                    flex: 1,
                    display: 'flex',
                  }}
                >
                  <VineaTextField
                    label={t('Type')}
                    value={data.type || 'Unknown'}
                    name="type"
                    fullWidth
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                  <Box pl={2}>
                    <FormHelperText>
                      {t('This field is read-only')}
                    </FormHelperText>
                  </Box>
                </FormControl>
              </>
            )}

            {isEdit && (
              <>
                <FormControl
                  sx={{
                    padding: theme.spacing(2),
                    flex: 1,
                    display: 'flex',
                  }}
                >
                  <VineaTextField
                    label={t('Uploaded By')}
                    value={data.loadedByUser}
                    fullWidth
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                  <Box pl={2}>
                    <FormHelperText>
                      {t('This field is read-only')}
                    </FormHelperText>
                  </Box>
                </FormControl>

                <FormControl
                  sx={{
                    padding: theme.spacing(2),
                    flex: 1,
                    display: 'flex',
                  }}
                >
                  <VineaTextField
                    label={t('Upload Date')}
                    value={data.loadedDate}
                    fullWidth
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                  <Box pl={2}>
                    <FormHelperText>
                      {t('This field is read-only')}
                    </FormHelperText>
                  </Box>
                </FormControl>
              </>
            )}

            <StyledButtonControlDiv>
              <VineaButton
                color="success"
                onClick={handleOnSave}
                variant="contained"
                minWidth={100}
              >
                {t('Save')}
              </VineaButton>

              {hasSelectedDocument && (
                <StyledCloseButtonDiv>
                  <VineaButton
                    variant="contained"
                    color="secondary"
                    onClick={handleOnPressDownload}
                  >
                    {t('Download')}
                  </VineaButton>
                </StyledCloseButtonDiv>
              )}
            </StyledButtonControlDiv>
          </ErrorBoundary>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={() => setDiscardDialogOpen(false)}
            handleSaveChanges={handleOnDiscardSaveChanges}
            handleDiscardChanges={handleOnDiscardConfirm}
          />
        </StyledDrawerControlDiv>
      </ClickAwayListener>
    </Drawer>
  );
}
