import { RenderDataGridHeader } from '../../../components/Grid';

export const noteGridColumn = () => [
  {
    field: 'id',
    headerName: 'Note ID',
    hideable: false,
    hide: true,
    width: 75,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'noteSubject',
    headerName: 'Subject',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'noteStatus',
    headerName: 'Status',
    flex: 0.75,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'relatesTo',
    headerName: 'Relates To',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'noteDate',
    headerName: 'Note Date',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
];
