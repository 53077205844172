import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';

const Root = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap',
  position: 'relative',
  '& .fa:nth-of-type(1)': {
    animation: '$spin 4s infinite',
  },
  '& .fa:nth-of-type(2)': {
    position: 'absolute',
    top: '-0.2em',
    right: '-0.45em',
    animation: '$spinreverse 2s infinite',
  },
  '& .fa:nth-of-type(3)': {
    position: 'absolute',
    top: 'auto',
    bottom: '-0.2em',
    right: '-0.45em',
    animation: '$spinreverse 2s infinite',
  },
  '& .fa': {
    color: 'white',
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  '@keyframes spinreverse': {
    from: { transform: 'rotate(360deg)' },
    to: { transform: 'rotate(0deg)' },
  },
}));

export const ThreeCogs = () => {
  return (
    <Root data-testid="threecogs">
      <SettingsIcon style={{ fontSize: 55 }} className="fa" />
      <SettingsIcon className="fa" />
      <SettingsIcon className="fa" />
    </Root>
  );
};
