export const createImage = url =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export function dataUrlToBlob(dataURL) {
  // // convert base64 to raw binary data held in a string
  // // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  // // eslint-disable-next-line no-undef
  // const byteString = window.atob(dataURL.split(',')[1]);

  // // separate out the mime component
  // const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

  // // write the bytes of the string to an ArrayBuffer
  // const ab = new ArrayBuffer(byteString.length);

  // // create a view into the buffer
  // const ia = new Uint8Array(ab);

  // // set the bytes of the buffer to the correct values
  // for (let i = 0; i < byteString.length; i += 1) {
  //   ia[i] = byteString.charCodeAt(i);
  // }
  // write the ArrayBuffer to a blob, and you're done
  // eslint-disable-next-line no-undef
  // const blobXyz = new Blob([ab], { type: mimeString });
  const BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts = dataURL.split(',');
    const contentType = parts[0].split(':')[1];
    const raw = decodeURIComponent(parts[1]);
    // eslint-disable-next-line no-undef
    return new Blob([raw], { type: contentType });
  }
  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(':')[1];
  // eslint-disable-next-line no-undef
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; i += 1) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  // eslint-disable-next-line no-undef
  return new Blob([uInt8Array], { type: contentType });
}

/**
 * Converts Blob to File object
 */
export function blobToFile(theBlob, fileName) {
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  // eslint-disable-next-line no-param-reassign
  theBlob.lastModifiedDate = new Date();
  // eslint-disable-next-line no-param-reassign
  theBlob.name = fileName;
  return theBlob;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) {
  const image = await createImage(imageSrc);
  // eslint-disable-next-line no-undef
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  return canvas.toDataURL('image/jpeg');

  // As a blob
  // return new Promise(resolve => {
  //   canvas.toBlob(file => {
  //     resolve(URL.createObjectURL(file));
  //   }, 'image/jpeg');
  // });
}
