import logger from '../utils/winstonLogger';
import serviceConfig from './class/serviceConfig';

const userPreferences = async (data = {}) => {
  try {
    const response = await serviceConfig.request(
      {
        url: `/usersettings?code=q02YCVvfNJKdQAAfPXLyNrdndLRgsX7XAxkAiKKuXNev9GRlS6kf6w==`,
        method: 'PUT',
        data: JSON.stringify({ ...data }),
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000', // (* or a specific host)
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
      'login',
    );
    return response;
  } catch (err) {
    logger.error(err);
    throw err;
  }
};

export { userPreferences };
