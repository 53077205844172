import {
  filter,
  get,
  includes,
  isEmpty,
  isEqual,
  isNil,
  map,
  orderBy,
  uniqBy,
} from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  VineaNovaActions,
  VineaHooks,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { format } from 'date-fns';
import { useFetchJobData } from './useFetchJobData';
import { VineyardBlocksGridType } from './useJobsHook';
import { IdentityRoleTypes, SiteTypeIDs, dateFormat } from '../../../constants';
import { useSelector } from 'react-redux';
import { MetaDataType } from '../interfaces/jobInterfaces';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useJobBlocksHook = (jobID: number) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const today = format(new Date(), dateFormat);

  const [insertAPITrigger, setInsertAPITrigger] = useState(false);

  const { data: identityVineyards } = VineaHooks.useFetchIdentityVineyard({});

  const { data: jobData } = useSelector(
    VineaNovaSelectors.getIdentityJobEntity,
  ) as MetaDataType;
  const siteTypeID = get(jobData, 'siteTypeID', 1);
  const siteName = useMemo(() => {
    if (
      siteTypeID === SiteTypeIDs.ORCHARD_TREES ||
      siteTypeID === SiteTypeIDs.ORCHARD_KIWIFRUIT
    )
      return 'Orchard';
    else return 'Vineyard';
  }, [siteTypeID]);

  const {
    isLoaded: blocksInsertLoaded,
    isLoading: blocksInsertLoading,
    hasError: blocksInsertHasError,
  } = useSelector(
    VineaNovaSelectors.getJobBlocksBulkEntityMeta,
  ) as MetaDataType;

  // job blocks
  const { blocksForJob: blocksForJobEntity } = useFetchJobData({
    jobId: jobID,
  });

  //@ts-ignore
  const { data: jobBlocks } = blocksForJobEntity;
  const jobVineyardBlockIDs = useMemo(
    () => map(uniqBy(jobBlocks, 'vineyardBlockID'), 'vineyardBlockID'),
    [jobBlocks],
  );
  const jobVineyardIDs = useMemo(
    () => map(uniqBy(jobBlocks, 'vineyardID'), 'vineyardID'),
    [jobBlocks],
  );

  // Filter vineyards by business unit
  const vineyards = useMemo(() => {
    if (isNil(jobData) || isEmpty(identityVineyards)) return [];

    const filteredVineyards = orderBy(
      filter(identityVineyards as object[], {
        siteTypeID: siteTypeID,
        businessUnitID: get(jobData, 'businessUnitID', 0),
      }),
      'vineyardName',
    );
    return filteredVineyards;
  }, [identityVineyards, jobData]);

  const { isLoading: blockVintagesLoading, isLoaded: blockVintagesLoaded } =
    useSelector(
      VineaNovaSelectors.getSampleBlockVintagesEntityMeta,
    ) as MetaDataType;

  const { data: sampleBlockVintages } = VineaHooks.useFetchSampleBlockVintages({
    queryParams: {
      SampleSetID: 0,
      RegionID: 0,
      VintageID: 0,
    },
  });

  const [allVineyardBlocks, setAllVineyardBlocks] = useState<
    VineyardBlocksGridType[]
  >([]);
  const [availableVineyardBlocks, setAvailableVineyardBlocks] = useState<
    VineyardBlocksGridType[]
  >([]);

  const [selectedVineyards, setSelectedVineyards] = useState<number[]>([]);
  const [selectedBlocks, setSelectedBlocks] =
    useState<number[]>(jobVineyardBlockIDs);

  const hasChanges = useMemo(
    () => !isEqual(orderBy(selectedBlocks), orderBy(jobVineyardBlockIDs)),
    [selectedBlocks, jobVineyardBlockIDs],
  );

  const vineyardGridDataColums = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
    },
    {
      field: 'vineyardName',
      headerName: `${siteName}s`,
      minwidth: '150px',
      flex: 0.3,
      resizable: false,
      hideable: false,
    },
  ];

  const vineyardGridXData = {
    columns: vineyardGridDataColums,
    rows: vineyards,
  };

  const blockGridDataColums = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Blocks',
      minwidth: '150px',
      flex: 0.3,
      resizable: false,
      hideable: false,
    },
  ];
  const blockGridXData = {
    columns: blockGridDataColums,
    rows: availableVineyardBlocks,
  };

  // functions

  // Call API to add and or remove job blocks to job
  const handleSave = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.jobBlocksBulk.post.request({
        postBody: {
          jobID: jobID,
          vineyardBlockIDs: selectedBlocks.join(','),
          description: '',
          dateStarted: today,
          complete: false,
        },
      }),
    );
    setInsertAPITrigger(true);
  };

  const handleOnVineyardSelectionChange = (selectedVineyards: number[]) => {
    setSelectedVineyards(selectedVineyards);
  };

  const handleOnBlockSelectionChange = (selectedBlocks: number[]) => {
    setSelectedBlocks(selectedBlocks);
  };

  // useEffects

  // Initial data load
  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.identitiesByRole.get.request({
        queryParams: {
          RoleTypeIDs: IdentityRoleTypes.SUPERVISOR,
        },
      }),
    );
  }, []);

  // Set available blocks in ui checkbox once sample blocks api has loaded
  useEffect(() => {
    if (blockVintagesLoaded && !blockVintagesLoading) {
      const blocks = orderBy(
        map(sampleBlockVintages as any[], (block: any) => {
          return {
            name: block.blockName,
            id: block.vineyardBlockID,
            vineyardID: block.vineyardID,
          };
        }),
        'name',
      );

      setAllVineyardBlocks(blocks);
    }
  }, [blockVintagesLoaded, blockVintagesLoading]);

  // selected blocks defaults to current job blocks
  useEffect(() => {
    setSelectedBlocks(jobVineyardBlockIDs);
  }, [jobVineyardBlockIDs]);

  // Filter available blocks based on chosen vineyards
  useEffect(() => {
    const blocks = orderBy(
      filter(allVineyardBlocks, block =>
        includes(selectedVineyards, block.vineyardID),
      ),
      'name',
    );
    setAvailableVineyardBlocks(blocks);
  }, [selectedVineyards]);

  // pre-select vineyards if job already has blocks
  useEffect(() => {
    if (!isEmpty(jobVineyardIDs)) setSelectedVineyards(jobVineyardIDs);
  }, [jobVineyardIDs]);

  // Show snackbars based on update job and insert job blocks api responses
  useEffect(() => {
    if (insertAPITrigger && blocksInsertLoaded && !blocksInsertLoading) {
      if (!blocksInsertHasError) {
        // @ts-ignore
        enqueueSnackbar(t('Success'), { variant: 'Success' });
      } else {
        // @ts-ignore
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }
      setInsertAPITrigger(false);
    }
  }, [
    insertAPITrigger,
    blocksInsertLoaded,
    blocksInsertLoading,
    blocksInsertHasError,
  ]);

  return {
    vineyardGridXData,
    selectedVineyards,
    blockGridXData,
    selectedBlocks,
    hasChanges,
    siteName,
    handleSave,
    handleOnVineyardSelectionChange,
    handleOnBlockSelectionChange,
  };
};
