import React, { useState } from 'react';
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import '@mui/material/styles';

const GrantTaskReact = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTask, setSelectedTask] = React.useState(null);

  const handleClick = (event, task) => {
    console.log('task click', task);
    // setAnchorEl(event.currentTarget);
    setAnchorEl({ x: event.clientX, y: event.clientY });
    setSelectedTask(task);
  };

  const viewMode = ViewMode.Day;
  const tasks = [
    {
      start: new Date(2020, 1, 1),
      end: new Date(2020, 1, 2),
      name: 'Idea',
      id: 'Task 0',
      type: 'task',
      progress: 45,
      isDisabled: true,
      styles: {
        TaskListTable: null,
        progressColor: '#ffbb54',
        progressSelectedColor: '#ff9e0d',
      },
    },
    {
      start: new Date(2020, 1, 17),
      end: new Date(2020, 11, 2),
      name: 'Idea 2',
      id: 'Task 1',
      type: 'task',
      progress: 45,
      isDisabled: true,
      styles: {
        TaskListTable: null,
        progressColor: '#ffbb54',
        progressSelectedColor: '#ff9e0d',
      },
    },
    {
      start: new Date(2020, 1, 11),
      end: new Date(2020, 1, 2),
      name: 'Idea 3',
      id: 'Task 2',
      type: 'task',
      progress: 45,
      isDisabled: true,
      styles: {
        TaskListTable: null,
        progressColor: '#ffbb54',
        progressSelectedColor: '#ff9e0d',
      },
    },
  ];

  const onTaskChange = () => {
    console.log('task change');
  };

  const onTaskDelete = () => {
    console.log('onTaskDelete');
  };

  const onProgressChange = () => {
    console.log('onProgressChange');
  };

  const onDblClick = () => {
    console.log('onDblClick');
  };

  const onClick = () => {
    console.log('onClick');
  };

  const TaskListHeader = () => {
    return <div></div>;
  };

  const TooltipContent = () => {
    return <div></div>;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = 'task-popover';

  return (
    <>
      <Gantt
        tasks={tasks}
        viewMode={viewMode}
        onDateChange={onTaskChange}
        onTaskDelete={onTaskDelete}
        onProgressChange={onProgressChange}
        onDoubleClick={onDblClick}
        onClick={handleClick}
        // onClick={(event, task) => handleClick(event, task)}
        TaskListHeader={TaskListHeader}
        TooltipContent={TooltipContent}
        TaskListTable={TaskListHeader}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>
          Task "{selectedTask?.name}" was clicked.
        </Typography>
      </Popover>
    </>
  );
};

export default GrantTaskReact;
