import { useLocation } from 'react-router-dom';
import startsWith from 'lodash/startsWith';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { getLkpRoleTypeList } from '../redux/selectors';
import { IdentityTypeIds } from '../constants';

const useIdentityTypeId = vineyardTypeScreenName => {
  const { pathname } = useLocation();
  if (startsWith(pathname, '/organisation/serviceproviders'))
    return IdentityTypeIds.SERVICE_PROVIDER;
  if (startsWith(pathname, '/job')) return IdentityTypeIds.JOB;
  if (startsWith(pathname, '/person') || startsWith(pathname, '/worker'))
    return IdentityTypeIds.PERSON;
  if (startsWith(pathname, '/consumable')) return IdentityTypeIds.CONSUMABLE;
  if (startsWith(pathname, '/connection'))
    return IdentityTypeIds.DATA_CONNECTION;
  if (startsWith(pathname, `/accommodation`))
    return IdentityTypeIds.ACCOMMODATION;
  if (startsWith(pathname, '/activity')) return IdentityTypeIds.ACTIVITY;
  if (startsWith(pathname, `/organisation/${vineyardTypeScreenName}s`))
    return IdentityTypeIds.VINEYARD;
  if (startsWith(pathname, '/organisation/other-organisations'))
    return IdentityTypeIds.ORGANISATION;
  if (startsWith(pathname, '/supplycontract'))
    return IdentityTypeIds.SUPPLY_CONTRACT;
  if (startsWith(pathname, '/vehicle')) return IdentityTypeIds.VEHICLE;
  return 0;
};

const useRoleTypeId = () => {
  const lkpRoleTypes = useSelector(state => getLkpRoleTypeList(state));
  const { pathname } = useLocation();

  if (Array.isArray(lkpRoleTypes) && lkpRoleTypes.length > 1) {
    let roleTypeOption = null;
    if (pathname.includes('/organisation/growers')) {
      roleTypeOption = 'Grower';
    } else if (pathname.includes('/organisation/suppliers')) {
      roleTypeOption = 'Supplier';
    }

    if (roleTypeOption) {
      const roleTypeId = get(
        lkpRoleTypes.find(f => f.value === roleTypeOption),
        'id',
        null,
      );
      return roleTypeId;
    }
  }
  return null;
};

const getPathByIdentityTypeId = (
  xIdentityTypeId,
  xIdentityId,
  vineyardTypeScreenName,
) => {
  switch (xIdentityTypeId) {
    case IdentityTypeIds.PERSON:
      return `/person/${xIdentityId}`;
    case IdentityTypeIds.ORGANISATION:
      return `/organisation/other-organisations/${xIdentityId}`;
    case IdentityTypeIds.VINEYARD:
      return `/organisation/${vineyardTypeScreenName}s/${xIdentityId}`;
    case IdentityTypeIds.SERVICE_PROVIDER:
      return `/organisation/serviceproviders/${xIdentityId}`;
    case IdentityTypeIds.JOB:
      return `/job/${xIdentityId}/overview`;
    case IdentityTypeIds.DATA_CONNECTION:
      return `/connections/${xIdentityId}`;
    case IdentityTypeIds.VEHICLE:
      return `/vehicle/${xIdentityId}`;
    case IdentityTypeIds.ACCOMMODATION:
      return `/accommodation/${xIdentityId}`;
    case IdentityTypeIds.CONSUMABLE:
      return `/consumable/${xIdentityId}`;
    case IdentityTypeIds.ACTIVITY:
      return `/activity/${xIdentityId}`;
    case IdentityTypeIds.SUPPLY_CONTRACT:
      return `supplycontract/${xIdentityId}`;
    case IdentityTypeIds.PLAN:
      return `/plans/${xIdentityId}/overview`;
    default:
      return null;
  }
};

const getPathByScreenType = (
  xIdentityTypeId,
  xIdentityId,
  xScreenType,
  vineyardTypeScreenName,
) => {
  switch (xIdentityTypeId) {
    case IdentityTypeIds.PERSON:
      if (xScreenType.toLowerCase() === 'person')
        return `/person/${xIdentityId}`;
      if (xScreenType.toLowerCase() === 'worker')
        return `/worker/${xIdentityId}`;
    case IdentityTypeIds.ORGANISATION:
      return `/organisation/other-organisations/${xIdentityId}`;
    case IdentityTypeIds.VINEYARD:
      return `/organisation/${vineyardTypeScreenName}s/${xIdentityId}`;
    case IdentityTypeIds.SERVICE_PROVIDER:
      return `/organisation/serviceproviders/${xIdentityId}`;
    case IdentityTypeIds.JOB:
      return `/job/${xIdentityId}/overview`;
    case IdentityTypeIds.DATA_CONNECTION:
      return `/connections/${xIdentityId}`;
    case IdentityTypeIds.VEHICLE:
      return `/vehicle/${xIdentityId}`;
    case IdentityTypeIds.ACCOMMODATION:
      return `/accommodation/${xIdentityId}`;
    case IdentityTypeIds.CONSUMABLE:
      return `/consumable/${xIdentityId}`;
    case IdentityTypeIds.ACTIVITY:
      return `/activity/${xIdentityId}`;
    case IdentityTypeIds.SUPPLY_CONTRACT:
      return `supplycontract/${xIdentityId}`;
    case IdentityTypeIds.PLAN:
      return `/plans/${xIdentityId}/overview`;
    default:
      return null;
  }
};

const getIdentityType = xIdentityTypeId => {
  switch (xIdentityTypeId) {
    case IdentityTypeIds.PERSON:
      return `Person`;
    case IdentityTypeIds.ORGANISATION:
      return `Organisation`;
    case IdentityTypeIds.VINEYARD:
      return `Vineyards`;
    case IdentityTypeIds.SERVICE_PROVIDER:
      return `Service Provider`;
    case IdentityTypeIds.JOB:
      return `Job`;
    case IdentityTypeIds.DATA_CONNECTION:
      return `Connection`;
    case IdentityTypeIds.VEHICLE:
      return `Vehicle`;
    case IdentityTypeIds.ACCOMMODATION:
      return `Accommodation`;
    case IdentityTypeIds.CONSUMABLE:
      return `Consumable`;
    case IdentityTypeIds.ACTIVITY:
      return `Activity`;
    case IdentityTypeIds.SUPPLY_CONTRACT:
      return `Supply Contract`;
    case IdentityTypeIds.PLAN:
      return `Plans`;
    default:
      return null;
  }
};

const doesIdentityDisplayBusinessUnit = xIdentityTypeId => {
  switch (xIdentityTypeId) {
    case IdentityTypeIds.VINEYARD:
    case IdentityTypeIds.SERVICE_PROVIDER:
    case IdentityTypeIds.ORGANISATION:
    case IdentityTypeIds.ACCOMMODATION:
    case IdentityTypeIds.JOB:
      return true;
    case IdentityTypeIds.PERSON:
    case IdentityTypeIds.DATA_CONNECTION:
    case IdentityTypeIds.VEHICLE:
    case IdentityTypeIds.CONSUMABLE:
    case IdentityTypeIds.ACTIVITY:
    case IdentityTypeIds.SUPPLY_CONTRACT:
      return false;
    default:
      return true;
  }
};

const doesIdentityDisplayRoles = xIdentityTypeId => {
  switch (xIdentityTypeId) {
    case IdentityTypeIds.VINEYARD:
    case IdentityTypeIds.SERVICE_PROVIDER:
    case IdentityTypeIds.ORGANISATION:
    case IdentityTypeIds.PERSON:
      return true;
    case IdentityTypeIds.DATA_CONNECTION:
    case IdentityTypeIds.JOB:
    case IdentityTypeIds.VEHICLE:
    case IdentityTypeIds.CONSUMABLE:
    case IdentityTypeIds.ACTIVITY:
    case IdentityTypeIds.SUPPLY_CONTRACT:
    case IdentityTypeIds.ACCOMMODATION:
      return false;
    default:
      return false;
  }
};

export {
  useIdentityTypeId,
  useRoleTypeId,
  getPathByIdentityTypeId,
  getPathByScreenType,
  getIdentityType,
  doesIdentityDisplayBusinessUnit,
  doesIdentityDisplayRoles,
};
