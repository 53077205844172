/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { isEmpty, map, mapValues } from 'lodash';
import { useTheme, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { ErrorBoundary } from 'react-error-boundary';

import { VineaButton } from '../../components/VineaButton';
import { VineaAutoComplete } from '../../components/ComboBox';
import { MultiSelectCheckbox } from '../../components/MultiSelectCheckbox/MultiSelectCheckbox';
import { syncValidator } from '../../utils/validator';
import { generateReportRequestSchema } from './validations';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { VineaNovaActions } from 'vineanova-redux-artifacts';

const StyledButtonControlDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
  marginTop: theme.spacing(8),
}));

const StyledDrawerControlDiv = styled('div')(() => ({
  width: 350,
}));

export function DownloadDrawer({
  isOpen,
  handleOnClose,
  handleGenerateReport,
  reportData,
  documentTypeOptions,
  isReportGenerating,
  setReportFiltersUpdate,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();

  const {
    reportName = '',
    filters = [],
    defaultFilters = {},
    supportedOutputTypes = [],
  } = reportData || {};

  const filtersWithOptions = filters.filter(f => !isEmpty(f.filterOptions));
  const supportedOutputFormatOptions = documentTypeOptions.filter(
    d => supportedOutputTypes.includes(d.id) || d.id === 0,
  );

  const [reportOptions, setReportOptions] = useState();
  const [filterValues, setFilterValues] = useState();
  const [validationErrors, setValidationErrors] = useState();

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnClose();
    }
  };

  const handleChangeOutputFormat = e => {
    const {
      target: { value, name },
    } = e;

    setReportOptions({ ...reportOptions, [name]: value });
  };

  const handleChangeSingleSelect = e => {
    const {
      target: { value, name },
    } = e;

    const newFilterValues = {
      ...filterValues,
      [name]: { ...filterValues[name], value: [value] },
    };

    setFilterValues(newFilterValues);
    onFilterValueChange(newFilterValues);
  };

  const handleChangeMultiSelect = (value, name) => {
    const newValueHasAll = value.includes(-1);
    const currentValueHasAll = filterValues[name].value.includes(-1);

    const updatedValue =
      newValueHasAll && !currentValueHasAll
        ? [-1] // deselect everything that isn't <All>
        : newValueHasAll && value.length > 1
        ? value.filter(i => i !== -1) // deselect <All>
        : value;

    const newFilterValues = {
      ...filterValues,
      [name]: { ...filterValues[name], value: updatedValue },
    };

    setFilterValues(newFilterValues);
    onFilterValueChange(newFilterValues);
  };

  const onGenerate = () => {
    // only filters with options should be required
    const reportRequestSchema = generateReportRequestSchema(
      filtersWithOptions?.map(f => f?.filterParameter) || [],
    );
    const valuesToCheck = {
      ...reportOptions,
      ...mapValues(filterValues, f => f?.value?.[0]),
    };
    const currentErrors = syncValidator(reportRequestSchema)(valuesToCheck);
    setValidationErrors(currentErrors);
    if (isEmpty(currentErrors)) {
      handleGenerateReport(reportData, reportOptions, filterValues);
    }
  };

  const onFilterValueChange = newFilterValues => {
    const reportFilters = map(newFilterValues, value => ({
      reportFilterID: value.reportFilterID,
      value: value.value[0],
    }));
    dispatchAPI(
      VineaNovaActions.api.v1.reportFilters.post.request({
        postBody: {
          reportFilterValues: reportFilters,
        },
      }),
    );
    setReportFiltersUpdate(true);
  };

  useEffect(() => {
    if (isOpen && !isEmpty(supportedOutputFormatOptions) && !reportOptions) {
      const outputFormat =
        supportedOutputFormatOptions.length === 2
          ? supportedOutputFormatOptions[1].id
          : 0;
      setReportOptions({
        outputFormat,
      });
      setFilterValues(defaultFilters);
    }
  }, [isOpen, defaultFilters, supportedOutputFormatOptions, reportOptions]);

  useEffect(() => {
    if (!isOpen) {
      setReportOptions(null);
      setFilterValues(null);
    }
  }, [isOpen]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleOnClose}>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleOnClose}>
        <StyledDrawerControlDiv role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
            data-testid="reportdrawer"
          >
            <Box>
              <Typography
                sx={{
                  padding: theme.spacing(2),
                }}
                variant="body1"
                data-testid="generatereporttext"
              >
                {t('Generate Report')}
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={handleOnClose}
                size="large"
                data-testid="closeicon"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography
            variant="subtitle2"
            sx={{
              paddingLeft: theme.spacing(2),
            }}
            data-testid="reportnametitle"
          >
            {reportName}
          </Typography>

          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />

            <Box p={2} mt={1}>
              <FormControl
                margin="none"
                sx={{ width: '100%' }}
                data-testid="formcontrol"
              >
                <VineaAutoComplete
                  onChange={handleChangeOutputFormat}
                  options={supportedOutputFormatOptions}
                  name="outputFormat"
                  label={t('Output Format')}
                  value={reportOptions?.outputFormat}
                  inputProps={{
                    name: 'outputFormat',
                  }}
                  data-testid="autocompletereportselector"
                />
                {validationErrors?.outputFormat && (
                  <FormHelperText id="component-error-text">
                    {validationErrors.outputFormat}
                  </FormHelperText>
                )}
              </FormControl>

              {filtersWithOptions.map(
                ({
                  filterOptions,
                  filterName,
                  filterParameter,
                  isMultiSelect,
                  filterID,
                }) => (
                  <Box
                    key={filterID}
                    mt={1}
                    sx={{
                      marginBottom: theme.spacing(1),
                      marginTop: theme.spacing(2),
                    }}
                    data-testid="reportfilteroptions"
                  >
                    {isMultiSelect ? (
                      <FormControl margin="none" sx={{ width: '100%' }}>
                        <MultiSelectCheckbox
                          onChange={val => {
                            return handleChangeMultiSelect(
                              val,
                              filterParameter,
                            );
                          }}
                          options={filterOptions}
                          name={filterParameter}
                          label={t(filterName)}
                          value={filterValues?.[filterParameter]?.value || []}
                          inputProps={{
                            name: filterParameter,
                          }}
                          data-testid="reportmulticheckbox"
                        />
                        {validationErrors?.[filterParameter] && (
                          <FormHelperText id="component-error-text">
                            {validationErrors?.[filterParameter]}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : (
                      <FormControl margin="none" sx={{ width: '100%' }}>
                        <VineaAutoComplete
                          onChange={handleChangeSingleSelect}
                          options={filterOptions}
                          name={filterParameter}
                          label={t(filterName)}
                          value={filterValues?.[filterParameter]?.value?.[0]}
                          inputProps={{
                            name: filterParameter,
                          }}
                          data-testid="vineaautocomplete"
                        />
                        {validationErrors?.[filterParameter] && (
                          <FormHelperText id="component-error-text">
                            {validationErrors?.[filterParameter]}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </Box>
                ),
              )}
            </Box>

            <StyledButtonControlDiv data-testid="styledbutton">
              <VineaButton
                variant="contained"
                color="secondary"
                onClick={onGenerate}
                data-testid="generatereportbtn"
              >
                {isReportGenerating ? (
                  <>
                    <CircularProgress color="inherit" size={20} />
                    <span>{t('Generating')}</span>
                  </>
                ) : (
                  t('Generate Report')
                )}
              </VineaButton>
            </StyledButtonControlDiv>
          </ErrorBoundary>
        </StyledDrawerControlDiv>
      </ClickAwayListener>
    </Drawer>
  );
}
