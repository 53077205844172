import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { customBreadcrumbs, IdentityTypeIds } from '../constants';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { getIdentityOverviewDetailsFromContact } from '../redux/selectors';
import useUserResponsiveLayout from './useUserResponsiveLayout';
import useIdentityTypeScreenNameHook from './useIdentityTypeScreenNameHook';

export interface EntityType {
  data: any;
  isLoading: boolean;
  isLoaded: boolean;
  hasError: boolean;
}

const useCustomBreadCrumbsHook = () => {
  const {
    data: identityData,
    isLoading: identityDataLoading,
    isLoaded: identityDataLoaded,
  } = useSelector(VineaNovaSelectors.getIdentityDetailsEntity) as EntityType;
  const { id: identityID, identityTypeID } = identityData;

  const identityOverviewDetails = useSelector(state =>
    getIdentityOverviewDetailsFromContact(state),
  );
  const { planName } = identityOverviewDetails;
  const { pathname } = useLocation();
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();

  const getSubstringUpToColon = useCallback((str: string) => {
    const colonIndex = str.indexOf(':');
    if (colonIndex !== -1) {
      return str.substring(0, colonIndex);
    }
    return str;
  }, []);

  const isPlan = useMemo(() => {
    return pathname.includes('plans');
  }, [pathname]);

  const identityBreadCrumbName = useMemo(() => {
    if (
      !identityDataLoading &&
      identityDataLoaded &&
      get(identityData, 'displayName', '') !== '' &&
      !isPlan
    ) {
      if (identityTypeID === IdentityTypeIds.JOB) return `${identityID}`;
      else if (identityTypeID === IdentityTypeIds.ACTIVITY)
        return getSubstringUpToColon(identityData?.displayName);
      else if (identityData?.displayName.length > 30)
        return identityData?.displayName.substring(0, 30) + '...';
      else return identityData?.displayName;
    } else if (isPlan && planName) {
      return planName;
    } else return null;
  }, [
    identityData,
    identityDataLoading,
    identityDataLoaded,
    pathname,
    isPlan,
    planName,
  ]);

  const customBCs = customBreadcrumbs(
    identityBreadCrumbName,
    vineyardTypeScreenName,
  );

  return { customBCs };
};

export default useCustomBreadCrumbsHook;
