// @ts-nocheck
import React from 'react';
import Grid from '@mui/material/Grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO, format, isValid } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { InputBaseComponentProps } from '@mui/material';
import { dateFormat, viewDateFormat } from '../../constants';
import { VineaTextField } from '../TextField';

type DatePickerProps = {
  id: string;
  name: string;
  onChange: any;
  value: string;
  size?: 'small' | 'medium';
  placeholder?: string;
  disablePast?: boolean;
  className?: string;
  label?: string;
  variant?: string;
  inputProps: InputBaseComponentProps;
  fullWidth?: boolean;
  disabled?: boolean;
  inputFormat?: string;
  formatDate?: (value: any) => any;
};

const defaultProps = {
  placeholder: 'DD/MM/YYYY',
  disablePast: false,
  size: 'small',
  className: null,
  label: '',
  variant: 'inline',
  fullWidth: false,
};

const DatepickerComponent = ({
  id,
  name,
  onChange,
  placeholder,
  disablePast,
  value,
  className,
  variant,
  fullWidth,
  inputProps: { readOnly = false, ...inputProps } = {},
  ...props
}: DatePickerProps) => {
  const handleDateChange = (date: any) => {
    if (readOnly) return;

    if (date === null) {
      const evt = { target: { value: '', name, id } };
      if (onChange) {
        onChange(evt, '');
      }
    } else {
      const selectedFormatDate = isValid(new Date(date))
        ? format(new Date(date), dateFormat)
        : date;
      const evt = { target: { value: selectedFormatDate, name, id } };
      if (onChange) {
        onChange(evt, selectedFormatDate);
      }
    }
  };

  const selectedDate = value ? parseISO(value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid
        container
        sx={{
          '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
          },
          '& .MuiSvgIcon-root path:nth-of-type(1)': {
            color: '#babacc',
          },
        }}
        justifyContent="space-between"
        data-id="date-grid-container"
      >
        <DatePicker
          data-testid="keyboardDatePicker"
          value={selectedDate}
          onChange={handleDateChange}
          closeOnSelect
          renderInput={params => (
            <VineaTextField
              variant="outlined"
              {...params}
              readOnly
              fullWidth={fullWidth}
              {...inputProps}
            />
          )}
          inputFormat={viewDateFormat}
          name={name}
          id={id}
          placeholder={placeholder}
          disablePast={disablePast}
          {...props}
        />
      </Grid>
    </LocalizationProvider>
  );
};

DatepickerComponent.defaultProps = defaultProps;
export default DatepickerComponent;
