import List from '@mui/material/List';
import { MuiListItemLink } from '../../components/Link';
import { WithAppContainer } from '../common';
import useIdentityTypeScreenNameHook from '../../hooks/useIdentityTypeScreenNameHook';
import { capitalize } from 'lodash';

const Organisation = () => {
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  return (
    <WithAppContainer>
      <div>
        <List component="nav" aria-label="organisations, vineyards, growers">
          <MuiListItemLink
            to={`/organisation/${vineyardTypeScreenName}s`}
            text={`${capitalize(vineyardTypeScreenName)}s`}
          />
          <MuiListItemLink
            to="/organisation/serviceproviders"
            text="Service Providers"
          />
          <MuiListItemLink
            to="/organisation/other-organisations"
            text="Other organisations"
          />
        </List>
      </div>
    </WithAppContainer>
  );
};

Organisation.propTypes = {};

export default Organisation;
