import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import {isValid, format} from 'date-fns';
import isNil from 'lodash/isNil';
import has from 'lodash/has';


export const getSampleHeaderInstructions = state => state.entities.sampleHeaderInstructions;

export const getSampleHeaderInstructionsMeta = createSelector(
  [getSampleHeaderInstructions],
  reference => {
    const { isLoading, isLoaded, hasError, error } = reference;
    return {
      isLoading,
      isLoaded,
      hasError,
      error,
    };
  },
);
