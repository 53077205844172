/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useQuickSearchIdentityHoook } from '../../hooks/useQuickSearchIdentityHook';
import { VineaTextField as SearchInput } from '../TextField';
import useDebounce from '../../hooks/useDebounce';

import { getPathByScreenType } from '../../hooks/useIdentityTypeId';
import {
  GridNoRowMessage,
  GridWithFilter,
  RenderDataGridHeader,
} from '../Grid';

type HistoryDrawerProps = {
  open: boolean;
  setOpen: any;
};

const dataGridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    hide: true,
    hideable: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    hideable: false,
    type: 'string',
    flex: 2,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'screenType',
    headerName: 'Type',
    hideable: false,
    type: 'string',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
];

export default function QuickSearchIdentity({
  open,
  setOpen,
}: HistoryDrawerProps) {
  // states
  const [searchText, setSearchText] = React.useState('');

  // hooks
  const debounceSearchText = useDebounce(searchText, 500);

  const { quickSearchData, fetchQuickSearchIdentity } =
    useQuickSearchIdentityHoook();
  const navigate = useNavigate();
  const toggleClose = React.useCallback(() => {
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridData = {
    columns: dataGridColumns,
    rows: quickSearchData,
  };

  const NoRowsOverlayMessage = () => (
    <Box mt={10} data-testid="blockgrid-norowmessage">
      <GridNoRowMessage message="Sorry, there were no records found." />
    </Box>
  );

  const toggleDrawer = (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      toggleClose();
    }
  };

  const handleOnClickAway = () => {
    toggleClose();
  };

  React.useEffect(() => {
    if (debounceSearchText) {
      fetchQuickSearchIdentity(debounceSearchText);
    }
  }, [fetchQuickSearchIdentity, debounceSearchText]);

  const handleOnCellClick = React.useCallback(
    (event: any) => {
      const { field, row = {} } = event;
      if (field === 'name') {
        const { id: identityID, identityTypeID, screenType } = row;
        const path = getPathByScreenType(
          Number(identityTypeID),
          Number(identityID),
          screenType,
        );
        if (path) {
          toggleClose();
          navigate(path);
        }
      }
    },
    [navigate, toggleClose],
  );

  const DrawerList = (
    <Box
      sx={{ height: '100%', width: 650, position: 'relative' }}
      role="presentation"
    >
      <Typography sx={{ px: 2 }} variant="h6">
        Quick Search
      </Typography>

      <Box
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        width="100%"
      >
        <SearchInput
          placeholder="Search"
          value={searchText}
          onChange={(e: any) => setSearchText(e.target.value)}
          size="small"
          id="quicksearch-identity"
        />
        <IconButton aria-label="clear" onClick={() => setSearchText('')}>
          <HighlightOffIcon />
        </IconButton>
      </Box>

      <Box sx={{ padding: 4, height: '100%' }} width="100%">
        {/** @ts-ignore */}
        <GridWithFilter
          data={{
            ...gridData,
          }}
          advancedSearch={false}
          autoPageSize
          onRowClick={() => {}}
          onCellClick={handleOnCellClick}
          rowHeight={30}
          headerHeight={56}
          noRowsOverlay={NoRowsOverlayMessage}
          actionType="xyz"
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
                identityID: false,
                userIdentityID: false,
              },
            },
          }}
        />
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer open={open} onClose={toggleDrawer} anchor="right">
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={handleOnClickAway}
        >
          {DrawerList}
        </ClickAwayListener>
      </Drawer>
    </div>
  );
}
