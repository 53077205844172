import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { VineaButton } from '../../components/VineaButton';
import { DiscardDialog } from '../../components/Dialog';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { IdentityDrawerTypes } from '../../constants';

import CheckboxSelectionGrid from '../../components/Grid/CheckboxSelectionGrid';
import { useJobBlocksHook } from './hooks/useJobBlocksHook';

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

const StyledCloseDiv = styled('div')(() => ({
  paddingLeft: 10,
}));

export function EditJobBlocksDrawer({
  isOpen,
  handleOnClose,
  jobID,
}: {
  isOpen: boolean;
  handleOnClose: (drawerType: number) => void;
  jobID: number;
  jobBlocks: Array<any>;
}) {
  const { t } = useTranslation();
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const {
    vineyardGridXData,
    selectedVineyards,
    blockGridXData,
    selectedBlocks,
    hasChanges,
    siteName,
    handleSave,
    handleOnVineyardSelectionChange,
    handleOnBlockSelectionChange,
  } = useJobBlocksHook(jobID);

  // Call API to add and or remove job blocks to job
  const handleOnSave = () => {
    handleSave();
    handleOnClose(IdentityDrawerTypes.JOB_BLOCK_EDIT);
  };

  const toggleDrawer = (event: React.KeyboardEvent) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const handleOnDiscard = () => {
    handleOnClose(IdentityDrawerTypes.JOB_BLOCK_EDIT);
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose(IdentityDrawerTypes.JOB_BLOCK_EDIT);
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={handleOnClickAway}
      >
        <Box sx={{ width: 380 }} role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography sx={{ padding: 2 }} variant="body1">
                {t('Edit Blocks')}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOnDiscard}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />
            <Box sx={{ mt: 2, height: 300 }}>
              <CheckboxSelectionGrid
                gridXData={vineyardGridXData}
                handleOnSelectionModelChange={handleOnVineyardSelectionChange}
                selectionModel={selectedVineyards}
                rowUnit={siteName}
              />
            </Box>
            <Divider />
            <Box sx={{ mt: 2, height: 300 }}>
              <CheckboxSelectionGrid
                gridXData={blockGridXData}
                handleOnSelectionModelChange={handleOnBlockSelectionChange}
                selectionModel={selectedBlocks}
                rowUnit={'block'}
              />
            </Box>
            <StyledButtonControlDiv>
              <div>
                <VineaButton
                  color="success"
                  onClick={handleOnSave}
                  variant="contained"
                  minWidth={150}
                  disabled={!hasChanges}
                >
                  {t('Save')}
                </VineaButton>
              </div>
              <StyledCloseDiv>
                <VineaButton
                  color="secondary"
                  onClick={handleOnDiscard}
                  variant="outlined"
                  minWidth={150}
                >
                  {t('Close')}
                </VineaButton>
              </StyledCloseDiv>
            </StyledButtonControlDiv>
          </ErrorBoundary>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={() => setDiscardDialogOpen(false)}
            handleSaveChanges={handleOnDiscardSaveChanges}
            handleDiscardChanges={handleOnDiscardConfirm}
          />
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
}
