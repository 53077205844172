import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { find, omit } from 'lodash';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import {
  VineaNovaSelectors,
  VineaNovaActions,
} from 'vineanova-redux-artifacts';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  getVineyardBlockRows,
  getVineyardBlockRowsInfo,
  getLookupRowSpecialAttribute,
  getIdentityOverviewDetailsFromContact,
} from '../../../redux/selectors';
import { syncValidator } from '../../../utils/validator';
import {
  BlockRowAddSchema,
  BlockRowUpdateSchema,
} from '../ManageBlockRows/validation';
import {
  reducers,
  sagaActionTypes,
  vineaDetails,
  actionTypes,
  apiTypes,
  SiteTypeIDs,
} from '../../../constants';
import { RenderDataGridHeader } from '../../../components/Grid';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

export const useVineyardBlockRowsHook = () => {
  const [blockRowDetails, setBlockRowDetails] = useState([]);
  const [vineyardBlockLastRowSelected, setVineyardBlockLastRowSelected] =
    useState({});
  const [actionTriggeredDelete, setActionTriggeredDelete] = useState(false);
  const [actionTriggeredAdd, setActionTriggeredAdd] = useState(false);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [previous, setPrevious] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [disableEndRow, setdisableEndRow] = useState(false);
  const [editBlockRowDrawerOpen, setEditBlockRowDrawerOpen] = useState(false);
  const [blockRowToEdit, setBlockRowToEdit] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [updateBlockRowApiTriggered, setUpdateBlockRowApiTriggered] =
    useState(false);

  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();

  const blockRowHasChanges = useMemo(() => {
    const selectedBlockRow = find(blockRowDetails, {
      id: blockRowToEdit?.id,
    });

    const hasChanges =
      blockRowToEdit?.rowNumber !== selectedBlockRow?.rowNumber ||
      blockRowToEdit?.plantedUnits !== selectedBlockRow?.plantedUnits ||
      blockRowToEdit?.actualUnits !== selectedBlockRow?.actualUnits ||
      blockRowToEdit?.postCount !== selectedBlockRow?.postCount ||
      blockRowToEdit?.rowLength !== selectedBlockRow?.rowLength ||
      blockRowToEdit?.specialAttributeID !==
        selectedBlockRow?.specialAttributeID ||
      blockRowToEdit?.rowComments !== selectedBlockRow?.rowComments ||
      blockRowToEdit?.bayCount !== selectedBlockRow?.bayCount ||
      blockRowToEdit?.bayAvgArea !== selectedBlockRow?.bayAvgArea;

    return hasChanges;
  }, [blockRowToEdit]);

  const { siteTypeID } = useSelector(state =>
    getIdentityOverviewDetailsFromContact(state),
  );

  const unitName = useMemo(() => {
    if (siteTypeID === SiteTypeIDs.VINEYARD) return 'Vine';
    else return 'Unit';
  }, [siteTypeID]);

  const { enqueueSnackbar } = useSnackbar();
  const dispatchAPI = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: pathParamId, blockId } = useParams();

  /** selectors */
  const allBlockRows = useSelector(getVineyardBlockRows);
  const lkpRowSpecialAttribute = useSelector(getLookupRowSpecialAttribute);
  const { isLoading, hasError, isLoaded } = useSelector(
    getVineyardBlockRowsInfo,
  );

  const {
    isLoaded: blockRowUpdateLoaded,
    isLoading: blockRowUpdateLoading,
    hasError: blockRowUpdateHasError,
  } = useSelector(VineaNovaSelectors.getIdentityVineyardBlockRowEntityMeta);

  const vineyardBlockRowsColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      resizable: false,
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'rowNumber',
      headerName: 'Row Number',
      width: 200,
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'plantedUnits',
      headerName: 'Planted',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'actualUnits',
      headerName: 'Current/Producing',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'postCount',
      headerName: 'Posts',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'rowLength',
      headerName: 'Row Length',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'rowSpecialAttribute',
      headerName: 'Special Attribute',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'rowComments',
      headerName: 'Comments',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
  ];

  const orchardKiwifruitBlockRowsColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      resizable: false,
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'rowNumber',
      headerName: 'Row Number',
      width: 200,
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'plantedUnits',
      headerName: 'Planted',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'actualUnits',
      headerName: 'Current/Producing',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'bayCount',
      headerName: 'Bay Count',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'bayAvgArea',
      headerName: 'Bay Avg Area',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'postCount',
      headerName: 'Poles',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'rowLength',
      headerName: 'Row Length',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'rowSpecialAttribute',
      headerName: 'Special Attribute',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'rowComments',
      headerName: 'Comments',
      flex: 1,
      renderHeader: RenderDataGridHeader,
    },
  ];

  const blockRowColumns = useMemo(() => {
    if (siteTypeID === SiteTypeIDs.ORCHARD_KIWIFRUIT)
      return orchardKiwifruitBlockRowsColumns;
    else return vineyardBlockRowsColumns;
  }, [siteTypeID]);

  /** selectors ** */
  const {
    isLoading: formWriteLoading,
    hasError: formWriteError,
    isLoaded: formWriteLoaded,
    data: formWriteData,
  } = useSelector(state => state.formWrite);

  const handleOnCloseBlockRowDrawer = () => {
    setEditBlockRowDrawerOpen(!editBlockRowDrawerOpen);
  };

  const handleChangeBlockRow = event => {
    const {
      target: { value, name },
    } = event;

    setBlockRowToEdit({
      ...blockRowToEdit,
      [name]: value,
    });
  };

  const handleOnSaveBlockRow = () => {
    const validationErrors =
      syncValidator(BlockRowUpdateSchema)(blockRowToEdit);

    if (isEmpty(validationErrors)) {
      dispatchAPI(
        VineaNovaActions.api.v1.identityVineyardBlockRow.put.request({
          postBody: {
            id: blockRowToEdit?.id,
            blockID: blockRowToEdit?.blockID,
            rowNumber: blockRowToEdit?.rowNumber,
            plantedUnits: blockRowToEdit?.plantedUnits,
            actualUnits: blockRowToEdit?.actualUnits,
            postCount: blockRowToEdit?.postCount,
            rowLength: blockRowToEdit?.rowLength,
            specialAttributeID: blockRowToEdit?.specialAttributeID,
            rowComments: blockRowToEdit?.rowComments,
            bayCount: blockRowToEdit?.bayCount,
            bayAvgArea: blockRowToEdit?.bayAvgArea,
            ts: blockRowToEdit?.ts,
          },
        }),
      );
      setUpdateBlockRowApiTriggered(true);
    } else {
      setValidationErrors(validationErrors);
    }
  };

  // for adding new rows
  const onSaveNew = () => {
    let newVineyardBlockLastRowSelected = {};
    if (Array.isArray(blockRowDetails) && blockRowDetails.length > 0) {
      const maxRow = maxBy(blockRowDetails, 'rowNumber');
      const minRow = minBy(blockRowDetails, 'rowNumber');

      if (
        Number.isNaN(Number(vineyardBlockLastRowSelected.endRow)) === false &&
        Number.isNaN(Number(vineyardBlockLastRowSelected.startRow)) === false &&
        Number.isNaN(Number(maxRow.rowNumber)) === false &&
        Number.isNaN(Number(minRow.rowNumber)) === false
      ) {
        if (
          !(
            Number(vineyardBlockLastRowSelected.endRow) >
              Number(maxRow.rowNumber) ||
            Number(vineyardBlockLastRowSelected.endRow) <
              Number(minRow.rowNumber)
          ) ||
          !(
            Number(vineyardBlockLastRowSelected.startRow) >
              Number(maxRow.rowNumber) ||
            Number(vineyardBlockLastRowSelected.startRow) <
              Number(minRow.rowNumber)
          )
        ) {
          newVineyardBlockLastRowSelected = {
            ...vineyardBlockLastRowSelected,
            inRange: true,
          };
        } else {
          newVineyardBlockLastRowSelected = {
            ...vineyardBlockLastRowSelected,
            inRange: false,
          };
        }
      } else {
        newVineyardBlockLastRowSelected = {
          ...vineyardBlockLastRowSelected,
          inRange: false,
        };
      }
    } else {
      newVineyardBlockLastRowSelected = {
        ...vineyardBlockLastRowSelected,
        inRange: false,
      };
    }

    // continue validations
    const validationErrors = syncValidator(BlockRowAddSchema)(
      newVineyardBlockLastRowSelected,
    );

    if (!isEmpty(validationErrors)) {
      const newValue = {
        ...newVineyardBlockLastRowSelected,
        errors: validationErrors,
      };
      setVineyardBlockLastRowSelected(newValue);
    } else {
      const dataToSubmit = {
        ...omit(newVineyardBlockLastRowSelected, ['errors']),
        specialAttributeID:
          newVineyardBlockLastRowSelected.specialAttributeID !== 0 &&
          newVineyardBlockLastRowSelected.specialAttributeID !== null
            ? newVineyardBlockLastRowSelected.specialAttributeID
            : null,
      };

      dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data: dataToSubmit,
          name: vineaDetails.blockRows,
          methodType: apiTypes.POST,
        },
      });
      setActionTriggeredAdd(true);
    }
  };

  const handleOnAddRow = () => {
    navigate(
      `/organisation/${vineyardTypeScreenName}s/${pathParamId}/blocks/${blockId}/blockRows/createRows`,
    );
  };

  // Open edit block row drawer when row is clicked
  const handleEditJobBlockRow = row => {
    setDataUpdated(!dataUpdated);
    const selectedBlockRow = find(blockRowDetails, {
      id: row?.id,
    });

    setEditBlockRowDrawerOpen(true);
    setBlockRowToEdit(selectedBlockRow);
  };

  /** changes to the row data */
  const handleOnChange = useCallback(
    e => {
      const {
        target: { value, name },
      } = e;
      const newValue = { ...vineyardBlockLastRowSelected, [name]: value };
      let newValue1 = newValue;

      if (
        name === 'startRow' &&
        Number.isNaN(Number(newValue.startRow)) === true
      ) {
        setdisableEndRow(true);
        newValue1 = {
          ...newValue,
          endRow: '0',
        };
      } else if (
        name === 'startRow' &&
        Number.isNaN(Number(newValue.startRow)) === false
      ) {
        setdisableEndRow(false);
      }

      setVineyardBlockLastRowSelected(newValue1);
    },
    [vineyardBlockLastRowSelected],
  );

  const handleOnUpdateBlockRows = useCallback(
    rows => {
      setBlockRowDetails(rows);
    },
    [blockRowDetails],
  );

  const handleOnBlur = () => {
    // continue validations
    const validationErrors = syncValidator(BlockRowAddSchema)(
      vineyardBlockLastRowSelected,
    );
    const { errors } = vineyardBlockLastRowSelected;

    let errors1 = [];

    if (!isEmpty(errors)) {
      if (
        Number.isNaN(Number(vineyardBlockLastRowSelected.startRow)) === false &&
        Number.isNaN(Number(vineyardBlockLastRowSelected.endRow)) === false &&
        Number(vineyardBlockLastRowSelected.endRow) <
          Number(vineyardBlockLastRowSelected.startRow)
      ) {
        errors1 = {
          ...validationErrors,
          endRow: 'Must be greater than or equal to Start Row',
        };
      } else {
        errors1 = {
          ...validationErrors,
        };
      }

      const newValue = {
        ...vineyardBlockLastRowSelected,
        errors: errors1,
      };
      setVineyardBlockLastRowSelected(newValue);
    }
  };

  const onCancel = () => {
    const updatedState = previous.map(row => {
      return { ...row, editRowId: null };
    });
    setBlockRowDetails(updatedState);
  };

  const handleOnDeleteRow = () => {
    const data = {
      ID: blockRowToEdit.id,
      ts: blockRowToEdit.ts,
    };

    dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
    dispatchAPI({
      type: sagaActionTypes.FORM_SUBMIT,
      payload: {
        data,
        name: vineaDetails.blockRows,
        methodType: apiTypes.DELETE,
      },
    });
    setActionTriggeredDelete(true);
  };

  useEffect(() => {
    if (!isLoading && !hasError && isLoaded) {
      if (Array.isArray(allBlockRows) && allBlockRows.length > 0) {
        if (firstLoad) {
          setFirstLoad(false);
        }
        const allBlockRowsUpdate = allBlockRows.map(f => {
          return {
            ...f,
            firstLoad,
          };
        });

        setBlockRowDetails(allBlockRowsUpdate);
      } else {
        setBlockRowDetails([]);
      }
    }
  }, [allBlockRows, isLoading, hasError, isLoaded, firstLoad]);

  // Show snackbar for block row update
  useEffect(() => {
    if (
      updateBlockRowApiTriggered &&
      blockRowUpdateLoaded &&
      !blockRowUpdateLoading
    ) {
      if (!blockRowUpdateHasError) {
        // @ts-ignore
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        setEditBlockRowDrawerOpen(false);
        setDataUpdated(!dataUpdated);
      } else {
        // @ts-ignore
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }

      setUpdateBlockRowApiTriggered(false);
    }
  }, [updateBlockRowApiTriggered, blockRowUpdateLoaded, blockRowUpdateLoading]);

  // for adding new rows
  useEffect(() => {
    if (actionTriggeredAdd) {
      if (!formWriteLoading && formWriteLoaded) {
        if (formWriteError) {
          // set form submitting has errors;
          setErrorInSubmit(true);
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          setActionTriggeredAdd(false);
          navigate(-1);
        }
      }
    }
  }, [
    t,
    enqueueSnackbar,
    setActionTriggeredDelete,
    actionTriggeredAdd,
    formWriteLoading,
    formWriteLoaded,
    formWriteError,
    navigate,
  ]);

  // for delete
  useEffect(() => {
    if (actionTriggeredDelete) {
      if (!formWriteLoading && formWriteLoaded) {
        if (formWriteError) {
          // set form submitting has errors;
          setErrorInSubmit(true);
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else {
          dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
          setDataUpdated(!dataUpdated);
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          setActionTriggeredDelete(false);
        }
      }
    }
  }, [
    t,
    enqueueSnackbar,
    setActionTriggeredDelete,
    actionTriggeredDelete,
    formWriteLoading,
    formWriteLoaded,
    formWriteError,
    dispatchAPI,
    blockId,
  ]);

  return {
    blockRowDetails,
    vineyardBlockLastRowSelected,
    errorInSubmit,
    dataUpdated,
    disableEndRow,
    isLoading,
    blockRowColumns,
    blockRowToEdit,
    editBlockRowDrawerOpen,
    lkpRowSpecialAttribute,
    validationErrors,
    blockRowHasChanges,
    siteTypeID,
    unitName,
    handleOnSaveBlockRow,
    handleOnCloseBlockRowDrawer,
    handleChangeBlockRow,
    handleEditJobBlockRow,
    handleOnUpdateBlockRows,
    onCancel,
    setErrorInSubmit,
    handleOnDeleteRow,
    handleOnChange,
    handleOnBlur,
    onSaveNew,
    handleOnAddRow,
    setVineyardBlockLastRowSelected,
  };
};
