import React, { useState, useEffect } from 'react';
import { Moment } from 'moment';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import { isEmpty, orderBy } from 'lodash';

import './styles.scss';

export type FrappeGanttProps = {
  tasks: Task[];
  viewMode: ViewMode.Month;
  onClick: (task: Task) => void;
  onDateChange?: (task: Task, start: Moment, end: Moment) => void;
  sortOptionID?: string;
  ganttHeight?: number;
  setSelectedGantt: (task: Task) => void;
};

const FrappeGantt = (props: FrappeGanttProps) => {
  const {
    tasks,
    viewMode,
    ganttHeight = 300,
    sortOptionID,
    onClick,
    setSelectedGantt,
  } = props;
  const [gantTasks, setGantTasks] = useState<Task[]>([]);
  const [gantView, setGantView] = useState<ViewMode>(viewMode);

  const sortOrderOptions =
    sortOptionID === 'date'
      ? ['start', 'sortSequence']
      : ['sortSequence', 'start'];

  let columnWidth = 65;
  if (gantView === ViewMode.Year) {
    columnWidth = 350;
  } else if (gantView === ViewMode.Month) {
    columnWidth = 300;
  } else if (gantView === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    setGantView(viewMode);
  }, [viewMode]);

  useEffect(() => {
    const modifiedTask = orderBy(
      tasks.map(el => {
        return {
          ...el,
          start: new Date(el.start),
          end: new Date(el.end),
          styles: {
            // @ts-ignore
            progressColor: el.colourHexCode || '#aeb8c2',
            // @ts-ignore
            progressSelectedColor: el.colourHexCode || '#aeb8c2',
            // @ts-ignore
            backgroundColor: el.colourHexCode || '#aeb8c2',
            // @ts-ignore
            backgroundSelectedColor: el.colourHexCode || '#aeb8c2',
          },
        } as Task;
      }),
      sortOrderOptions,
      ['asc', 'asc'],
    ) as Task[];
    setGantTasks(modifiedTask);
    console.log('modifiedtask', modifiedTask);
  }, [tasks]);

  const handleOnClick = (task: Task) => {
    console.log('onClick');
    setSelectedGantt(task);
  };

  const onDblClick = (task: Task) => {
    console.log('onDblClick');
    if (onClick) {
      onClick(task);
    }
  };

  const TaskListHeader = () => {
    return <div></div>;
  };

  if (isEmpty(gantTasks)) {
    return <div></div>;
  }

  return (
    <Gantt
      tasks={gantTasks}
      viewMode={gantView}
      onDoubleClick={onDblClick}
      onClick={handleOnClick}
      TaskListHeader={TaskListHeader}
      TaskListTable={TaskListHeader}
      listCellWidth={'155px'}
      ganttHeight={ganttHeight}
      rowHeight={30}
      columnWidth={columnWidth}
    />
  );
};

const FrappeContainer = (props: FrappeGanttProps) => {
  const { tasks } = props;
  if (isEmpty(tasks)) return null;
  if (Array.isArray(tasks) && tasks.length === 0) return null;
  return <FrappeGantt {...props} />;
};

export { FrappeContainer as FrappeGantt };
