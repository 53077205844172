/* eslint-disable */
import React from 'react';
import { useDispatch } from 'react-redux';
import { has, find, isNil } from 'lodash';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import DoneIcon from '@mui/icons-material/Done';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { GridWithFilter, RenderDataGridHeader } from '../../components/Grid';
import { IdentityTypeIds, viewDateFormat } from '../../constants';
import {
  areLookupsLoading,
  getAuthenticationResult,
  getBusinessUnit,
  getUserPreferences,
} from '../../redux/selectors';

function getFullName(params) {
  return `${params.row.lastName || ''}, ${params.row.firstName || ''}`;
}

const RenderCellActive = params => {
  const { value } = params;
  if (value) {
    return <DoneIcon />;
  } else return <></>;
};

const dataGridHeaderUsersColumns = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'string',
    width: 100,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'name',
    headerName: 'Full Name',
    flex: 1.3,
    width: 200,
    hideable: false,
    resizable: false,
    renderHeader: RenderDataGridHeader,
    valueGetter: getFullName,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'businessUnit',
    headerName: 'Business Unit',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'isUserActive',
    headerName: 'User Active',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    renderCell: RenderCellActive,
  },
  {
    field: 'isVineaAdmin',
    headerName: 'Admin',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    renderCell: RenderCellActive,
  },
];

export const UserSearchResults = ({ identities, filterText, onFilterOpen }) => {
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const lkpBusinessUnit = useSelector(state => getBusinessUnit(state));
  const userPreferences = useSelector(state => getUserPreferences(state));
  const { basicSettings } = userPreferences;
  const { data = [], isLoading, isLoaded, hasError } = identities;

  const [loadingState, setLoadingState] = React.useState(false);
  const [dispatchActionType, setDispatchActionType] = React.useState('');
  const [gridColumnState, setGridColumnState] = React.useState({});

  const dataWithBusinessUnit = data.map(user => {
    if (!isNil(user.userSettings && user.userSettings !== '')) {
      const setting = JSON.parse(user.userSettings);

      if (has(setting, 'basicSettings')) {
        const businessSetting = find(
          lkpBusinessUnit,
          b => b.id === setting.basicSettings.businessUnitID,
        );
        const businessUnitName = businessSetting?.value;
        if (!isNil(businessUnitName))
          return { ...user, businessUnit: businessUnitName };
      }
    }

    return { ...user };
  });

  React.useEffect(() => {
    setDispatchActionType('USERS_GRID_COLUMN_UPDATE');
    setGridColumnState(userPreferences.usersDataGrid);
  });

  React.useEffect(() => {
    if (isLoading) setLoadingState(true);
  }, [isLoaded, isLoading, data, hasError]);

  const areLookupLoading = useSelector(areLookupsLoading);
  const { isLoading: authenticationIsLoading } = useSelector(
    getAuthenticationResult,
  );

  const gridXData = {
    columns: dataGridHeaderUsersColumns,
    rows: dataWithBusinessUnit,
  };
  const handleOnClick = evt => {
    navigate(`/users/${evt.id}/manageuser`);
  };

  return (
    <Grid
      container
      spacing={0}
      data-testid="organisation-results"
      direction="column"
    >
      <Grid
        item
        xs={12}
        sx={{
          flex: 1,
        }}
      >
        <Paper
          sx={{
            width: '100%',
            height: '100%',
            overflowX: 'auto',
            '& .MuiDataGrid-cell': {
              padding: '0 5px',
            },
            '& .MuiDataGrid-colCell': {
              padding: '0 5px',
            },
          }}
        >
          <GridWithFilter
            data={gridXData}
            onRowClick={handleOnClick}
            rowHeight={30}
            loading={areLookupLoading || isLoading || authenticationIsLoading}
            autoPageSize
            headerHeight={56}
            actionType={dispatchActionType}
            gridColumnState={gridColumnState}
            handleOnAdvancedSearch={onFilterOpen}
            advancedSearch={true}
            selectedFilterValuesText={filterText}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
