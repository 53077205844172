/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { filter, isNull } from 'lodash';
import logger from '../../utils/winstonLogger';
import { ILookupProps } from '../../constants/datatypes';
import { VineaTextField } from '../TextField';
import { FieldNameSelect } from '../Select';

interface IFormDataProps {
  countryID?: number;
  postCode?: number | string;
  addressLine1?: string;
  addressLine2?: string;
  suburb?: string;
  townID?: string;
}

interface IErrorProps {
  countryID?: number;
  postCode?: number | string;
  addressLine1?: string;
  addressLine2?: string;
  suburb?: string;
  townID?: string;
}
interface ILookupPropsExtended extends ILookupProps {
  countryID: number;
}
interface IAddressProps {
  formdata: IFormDataProps;
  lkpTown: ILookupPropsExtended[];
  lkpCountry: ILookupProps[];
  errors: IErrorProps;
  onBlur?: (
    e: React.ChangeEvent<{ value: unknown }>,
    formdata: IFormDataProps,
  ) => void;
  onChange?: (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    formdata: IFormDataProps,
  ) => void;
}

interface ISelectorProps {
  id: number;
  key: number;
  value: string;
}

export const AddressComponent: React.FC<IAddressProps> = ({
  formdata = {},
  onBlur,
  errors = {},
  lkpCountry = [],
  lkpTown = [],
  onChange,
}): JSX.Element => {
  const { t } = useTranslation();
  const [townList, setTownList] = useState<Array<ISelectorProps>>([
    { id: 0, key: 0, value: 'Select' },
  ]);
  const [townListTrigger, setTrigger] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!townListTrigger && !isNull(formdata.countryID)) {
      setTrigger(true);
      const validTownList =
        formdata.countryID !== 0
          ? filter(lkpTown, cls => cls.countryID === formdata.countryID)
          : lkpTown;
      const selectTownList = [{ id: 0, key: 0, value: 'Select' }].concat(
        validTownList.map((lkpCls: ILookupPropsExtended) => {
          return {
            id: lkpCls.id,
            key: lkpCls.id,
            value: lkpCls.value,
          };
        }),
      );
      setTownList(selectTownList);
      logger.debug('townList');
      logger.debug(townList);
    }
  }, [townList, lkpTown, formdata.countryID, townListTrigger]);

  const handleOnAddressChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ): void => {
    // dispatch({ type: types.UPDATE, payload: { [name]: value } }); comments
    const {
      target: { name },
    } = event;
    if (name === 'countryID') setTrigger(false);
    if (onChange) {
      onChange(event, formdata);
    }
  };

  const handleOnBlur = (event: React.ChangeEvent<{ value: unknown }>): void => {
    if (onBlur) {
      onBlur(event, formdata);
    }
  };

  return (
    <>
      <FormControl
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& .MuiFormControl-root': {
            marginBottom: theme.spacing(1),
          },
        }}
        error={!!errors.countryID}
        variant="outlined"
        margin="dense"
      >
        <FieldNameSelect
          value={formdata.countryID || ''}
          onChange={handleOnAddressChange}
          onBlur={handleOnBlur}
          label={t('Country')}
          id="countryID"
          options={lkpCountry}
          inputProps={{
            //@ts-ignore
            name: 'countryID',
          }}
        />
      </FormControl>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& .MuiFormControl-root': {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
          },
        }}
      >
        <FormControl
          error={!!errors.addressLine1}
          variant="outlined"
          margin="dense"
        >
          <VineaTextField
            label={t('Address')}
            type="search"
            variant="outlined"
            size="small"
            id="addressLine1"
            name="addressLine1"
            value={formdata.addressLine1 || ''}
            onChange={handleOnAddressChange}
            error={!!errors.addressLine1}
          />
        </FormControl>
        <VineaTextField
          label={t('Address')}
          type="search"
          variant="outlined"
          size="small"
          id="addressLine2"
          name="addressLine2"
          value={formdata.addressLine2 || ''}
          onChange={handleOnAddressChange}
        />
        <FormControl error={!!errors.townID} variant="outlined">
          <FieldNameSelect
            value={formdata.townID || ''}
            onChange={handleOnAddressChange}
            onBlur={handleOnBlur}
            label={t('Town')}
            id="townID"
            options={townList}
            inputProps={{
              //@ts-ignore
              name: 'townID',
            }}
          />
        </FormControl>

        <VineaTextField
          label={t('Suburb')}
          type="search"
          variant="outlined"
          size="small"
          id="suburb"
          name="suburb"
          value={formdata.suburb || ''}
          onChange={handleOnAddressChange}
          onBlur={handleOnBlur}
          error={!!errors.suburb}
        />

        <VineaTextField
          type="search"
          variant="outlined"
          size="small"
          label={t('Postcode')}
          id="postCode"
          name="postCode"
          value={formdata.postCode || ''}
          onChange={handleOnAddressChange}
          onBlur={handleOnBlur}
          error={!!errors.postCode}
        />
      </Box>
    </>
  );
};
