import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, useTheme } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

type IdentityStatusPillProps = {
  state: 'Active' | 'Inactive' | '';
};

const IdentityStatusPill = ({ state = '' }: IdentityStatusPillProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const pillClass =
    state === 'Active'
      ? {
          color: theme.palette.success.dark,
          fontSize: '12px',
          '& .MuiChip-icon': {
            color: theme.palette.success.dark,
          },
        }
      : {
          color: theme.palette.error.dark,
          fontSize: '12px',
          '& .MuiChip-icon': {
            color: theme.palette.error.dark,
          },
        };

  return (
    <Chip
      sx={pillClass}
      icon={<FiberManualRecordIcon />}
      label={t(state)}
      variant="outlined"
      size="small"
      data-testid="active-chip"
    />
  );
};

export default IdentityStatusPill;
