import React, { useContext, useEffect } from 'react';
import { AzureMapsContext } from 'react-azure-maps';
import { isEmpty } from 'lodash';
import {
  source,
  layer,
  math,
  control,
  data,
  HtmlMarker,
} from 'azure-maps-control';
import Box from '@mui/material/Box';
import MapComponent from './MapComponent';
import { newZealandBoundingBox } from './mapConstants';

const zoomLevels = {
  VINEYARD_MARKERS: 'VINEYARD_MARKERS',
  VINEYARD_GEOMETRIES: 'VINEYARD_GEOMETRIES',
  BLOCK_GEOMETRIES: 'BLOCK_GEOMETRIES',
};

const selectedPolygonLayerOptions = {
  fillColor: ['get', 'color'],
};

const blockPolygonLayerOptions = {
  fillColor: ['get', 'ffffff'],
  fillOpacity: 0.2,
};

const symbolLayerOptions = {
  iconOptions: {
    // Hide the default image.
    image: 'none',
  },
  textOptions: {
    // Reference the labelText property of the polygon as the text field.
    color: '#ffffff',
    size: 12,
    textField: ['get', 'labelText'],
    font: ['SegoeUi-Regular'],
  },
};

const vineyardsPolygonDataSource = new source.DataSource();
const vineyardsPolygonLayer = new layer.PolygonLayer(
  vineyardsPolygonDataSource,
  null,
  {},
);
const vineyardsSymbolLayer = new layer.SymbolLayer(
  vineyardsPolygonDataSource,
  null,
  symbolLayerOptions,
);

const vineyardsLineDataSource = new source.DataSource();
const vineyardsLineLayer = new layer.LineLayer(vineyardsLineDataSource, null, {
  strokeColor: 'white',
  strokeWidth: 2,
});
// a transparent layer, so that we can click on a vineyard when showing block geometries
const vineyardsClickTargetLayer = new layer.PolygonLayer(
  vineyardsLineDataSource,
  null,
  {
    fillOpacity: 0,
  },
);
const blocksDataSource = new source.DataSource();
const blocksLayer = new layer.PolygonLayer(
  blocksDataSource,
  null,
  blockPolygonLayerOptions,
);
const blocksSymbolLayer = new layer.SymbolLayer(
  blocksDataSource,
  null,
  symbolLayerOptions,
);

const selecteddataSourceRef = new source.DataSource();
const selectedLayer = new layer.PolygonLayer(
  selecteddataSourceRef,
  null,
  selectedPolygonLayerOptions,
);

const MapController = props => {
  const {
    vineyardsGeoJson,
    blocksGeoJson,
    getBlockGeometries,
    handleOnClickVineyard,
    isFilterOpen,
    selectedGeoJson,
  } = props;
  const { mapRef, isMapReady } = useContext(AzureMapsContext);
  const [zoomLevel, setZoomLevel] = React.useState();

  const getZoomLevelFromZoom = zoom => {
    if (zoom > 11.5) {
      return zoomLevels.BLOCK_GEOMETRIES;
    }
    if (zoom > 9) {
      return zoomLevels.VINEYARD_GEOMETRIES;
    }
    return zoomLevels.VINEYARD_MARKERS;
  };

  const handleOnMapZoom = React.useCallback(() => {
    const { zoom } = mapRef?.getCamera();
    const newZoomLevel = getZoomLevelFromZoom(zoom);
    if (zoom && newZoomLevel !== zoomLevel) {
      setZoomLevel(newZoomLevel);
    }
  }, [mapRef, zoomLevel]);

  const handleOnMapClick = React.useCallback(
    event => {
      const { shapes = [] } = event;
      const vineyardFeature = shapes.find(
        ({ properties }) => properties?.type === 'VINEYARD',
      );
      if (vineyardFeature) {
        const { id, vineyardName } = vineyardFeature?.properties;
        handleOnClickVineyard({ id, vineyardName });
      }
    },
    [handleOnClickVineyard],
  );

  useEffect(() => {
    if (isMapReady && mapRef) {
      // VINEYARD_GEOMETRIES zoom level
      mapRef.sources.add(vineyardsPolygonDataSource);
      mapRef.layers.add(vineyardsPolygonLayer);
      mapRef.layers.add(vineyardsSymbolLayer);

      // BLOCK_GEOMETRIES zoom level
      mapRef.sources.add(vineyardsLineDataSource);
      mapRef.layers.add(vineyardsLineLayer);
      mapRef.layers.add(vineyardsClickTargetLayer);
      mapRef.sources.add(blocksDataSource);
      mapRef.layers.add(blocksLayer);
      mapRef.layers.add(blocksSymbolLayer);
      mapRef.sources.add(selecteddataSourceRef);
      mapRef.layers.add(selectedLayer);

      mapRef.setStyle({ style: 'satellite' });

      mapRef.events.add('ready', () =>
        mapRef.controls.add(
          [
            new control.ZoomControl(),
            new control.CompassControl(),
            new control.PitchControl(),
          ],
          {
            position: 'top-right',
          },
        ),
      );

      mapRef.events.add('zoomend', handleOnMapZoom);
      mapRef.events.add('click', handleOnMapClick);
    }
    return () => {
      vineyardsLineDataSource.clear();
      vineyardsPolygonDataSource.clear();
      blocksDataSource.clear();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapReady]);

  useEffect(() => {
    if (isMapReady && mapRef) {
      if (!isEmpty(vineyardsGeoJson?.features)) {
        // zoom to the extent of all vineyards
        const bounds = data.BoundingBox.fromData(
          math.getConvexHull(vineyardsGeoJson),
        );
        mapRef.setCamera({ bounds, padding: 100 });

        // set an initial zoom level
        const { zoom } = mapRef.getCamera();
        setZoomLevel(getZoomLevelFromZoom(zoom));
      } else {
        // Zoom to all of New Zealand for now
        mapRef.setCamera({
          bounds: newZealandBoundingBox,
          padding: 100,
        });
      }
    }
  }, [vineyardsGeoJson, isMapReady, mapRef]);

  React.useEffect(() => {
    if (!isEmpty(selectedGeoJson) && isMapReady) {
      selecteddataSourceRef.setShapes(selectedGeoJson);
    }
  }, [selectedGeoJson, isMapReady]);

  React.useEffect(() => {
    if (mapRef && zoomLevel) {
      // clear everything
      mapRef.markers.clear();
      vineyardsLineDataSource.clear();
      vineyardsPolygonDataSource.clear();
      blocksDataSource.clear();

      if (zoomLevel === zoomLevels.VINEYARD_MARKERS) {
        vineyardsGeoJson?.features?.forEach(feature => {
          const hull = math.getConvexHull(feature);
          const boundingBox = data.BoundingBox.fromData(hull);
          const centroid = data.BoundingBox.getCenter(boundingBox);
          mapRef.markers.add(new HtmlMarker({ position: centroid }));
        });
      } else if (zoomLevel === zoomLevels.VINEYARD_GEOMETRIES) {
        vineyardsPolygonDataSource.setShapes(vineyardsGeoJson);
      } else if (zoomLevel === zoomLevels.BLOCK_GEOMETRIES) {
        getBlockGeometries(); // only triggers API if it's not loaded
        vineyardsLineDataSource.setShapes(vineyardsGeoJson);
        blocksDataSource.setShapes(blocksGeoJson);
      }
    }
  }, [
    zoomLevel,
    mapRef,
    vineyardsGeoJson,
    getBlockGeometries,
    blocksGeoJson,
    isFilterOpen,
  ]);

  return (
    <>
      {vineyardsGeoJson && (
        <Box sx={{ width: '100%', height: '100%' }}>
          <MapComponent />
        </Box>
      )}
    </>
  );
};

export default MapController;
