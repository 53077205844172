import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { VineaNovaSelectors, VineaHooks } from 'vineanova-redux-artifacts';
import { find, get, isNil, map } from 'lodash';
import { useTheme, styled } from '@mui/material/styles';
import { Paper, Stack, Box } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { FieldLabelInput } from '../../components/TextField';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';

import { VineaCommonGrid } from '../../components/Grid';
import { commonGridHeaderNames, viewDateFormat } from '../../constants';
import { VineaButton } from '../../components/VineaButton';
import { format, parseISO } from 'date-fns';
import { MetaDataType } from '../Jobs/interfaces/jobInterfaces';

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: 250,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const WorkerWorkRecords = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { id: workerID, payPeriodId: payPeriodID } = useParams();

  const { isLoading: isWorkerWorkRecordLoading } = useSelector(
    VineaNovaSelectors.getWorkerWorkRecordsEntityMeta,
  ) as MetaDataType;

  const { data: workerWorkRecordData } = VineaHooks.useFetchWorkerWorkRecords({
    queryParams: {
      WorkerID: Number(workerID),
      PayrollBatchID: Number(payPeriodID),
    },
  });

  const { data: workerPayPeriodsData } = VineaHooks.useFetchWorkerPayPeriods({
    queryParams: {
      WorkerID: Number(workerID),
    },
  });

  const workerWorkRecordsFormatted = useMemo(() => {
    const data = map(workerWorkRecordData, record => {
      return {
        ...record,
        date: !isNil(record?.date)
          ? format(parseISO(record.date), viewDateFormat)
          : ' ',
        referenceValue:
          !isNil(record?.referenceValue) && record.referenceValue.length > 15
            ? record.referenceValue.substring(0, 7) +
              ' ... ' +
              record.referenceValue.slice(-7)
            : record?.referenceValue,
      };
    });

    return data;
  }, [workerWorkRecordData]);

  const workerPayPeriod = find(workerPayPeriodsData, {
    id: Number(payPeriodID),
  });

  const workerWorkRecordColumns = [
    t('Type'),
    t('Date'),
    t('Block'),
    t('Reference Type'),
    t('Reference Value'),
    t('Unit Type'),
    t('Quantity'),
    t('Job'),
    t('Payroll Period ID'),
    t('Notes'),
  ];

  const handleOnClose = () => {
    navigate(-1);
  };

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Stack>
        <Box
          flex={1}
          flexDirection="row"
          justifyContent="center"
          display="flex"
        >
          <StyledFieldLabelInput
            autoComplete="off"
            id="payrollStatus"
            name="payrollStatus"
            label={t('Payroll Status')}
            size="small"
            inlineLabel
            value={get(workerPayPeriod, 'payrollStatus', '')}
            disabled={true}
            onChange={undefined}
            onBlur={undefined}
            classes={undefined}
            error={undefined}
            rows={undefined}
            sx={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }}
          />
          <StyledFieldLabelInput
            autoComplete="off"
            id="records"
            name="records"
            label={t('Records')}
            size="small"
            inlineLabel
            value={get(workerPayPeriod, 'records', '')}
            disabled={true}
            onChange={undefined}
            onBlur={undefined}
            classes={undefined}
            error={undefined}
            rows={undefined}
            sx={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }}
          />
          <StyledFieldLabelInput
            autoComplete="off"
            id="payPeriod"
            name="payPeriod"
            label={t('Pay Period')}
            size="small"
            inlineLabel
            value={get(workerPayPeriod, 'payPeriod', '')}
            disabled={true}
            onChange={undefined}
            onBlur={undefined}
            classes={undefined}
            error={undefined}
            rows={undefined}
            sx={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }}
          />
        </Box>
        <Box
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          display="flex"
        >
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <VineaCommonGrid
              heading={t(commonGridHeaderNames.WORKER_WORK_RECORDS)}
              colHeaders={workerWorkRecordColumns}
              isLoading={isWorkerWorkRecordLoading}
              tableData={workerWorkRecordsFormatted}
              hasManageOption={false}
              hasActiveToggle={false}
              tableHeight={window.outerHeight * 0.6}
            />
          </ErrorBoundary>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <VineaButton
            color="secondary"
            onClick={handleOnClose}
            variant="contained"
            minWidth={150}
          >
            {t('Close')}
          </VineaButton>
        </Box>
      </Stack>
    </Paper>
  );
};

export default WorkerWorkRecords;
