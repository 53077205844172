import * as Yup from 'yup';
import { isValid } from 'date-fns';

const sampleSizeSchema = Yup.number()
  .required('Required')
  .typeError('Must be a number')
  .max(1000, 'Cannot be greater than 1000')
  .nullable();

export const NewSamplesSetSchema = Yup.object().shape({
  regionId: Yup.string().nullable().required('Required'),
  measureGroupId: Yup.string().nullable().required('Required'),
  measureInstanceId: Yup.string().nullable().required('Required'),
  defaultSampleSize: sampleSizeSchema,
  sampleSize: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'Must be numbers'),
  plannedStartDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
});

export const AddSampleRequestBlocksSchema = Yup.object().shape({
  recommendedSampleSize: sampleSizeSchema,
});

export const UpdateSampleSizeSchema = Yup.object().shape({
  recommendedSampleSize: sampleSizeSchema,
});
