import React from 'react';
import PropTypes from 'prop-types';
import { VineaAlert } from '../../../components/VineaAlert';

const VineaAlertMessage = ({ alert, alertType, onExit, type }) => {
  const handleAlertMessage = () => {
    let alertMessage = '';
    if (alert === 'Delete') {
      alertMessage = ` ${type} Deleted `;
    } else if (alert === 'Created') {
      alertMessage = `New ${type} created`;
    } else if (alert === 'Update') {
      alertMessage = `The ${type} details updated`;
    }
    return alertMessage;
  };
  return (
    <VineaAlert
      isOpen={alertType}
      onExit={onExit}
      alertType="success"
      message={alertType && handleAlertMessage()}
    />
  );
};

VineaAlertMessage.propTypes = {
  alert: PropTypes.string,
  alertType: PropTypes.bool,
  onExit: PropTypes.func,
  type: PropTypes.string,
};

VineaAlertMessage.defaultProps = {
  onExit: f => f,
  alertType: false,
  alert: '',
  type: '',
};

export default VineaAlertMessage;
