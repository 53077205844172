import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, Typography } from '@mui/material';
import { startCase } from 'lodash';
import { GreenSwitch } from '../Switch';

type IdentityActivationSwitchProps = {
  handleOnToggleActive: () => void;
  isActive: boolean;
  identityName: string;
};

export const IdentityActivationSwitch = ({
  handleOnToggleActive,
  isActive,
  identityName = 'Identity',
}: IdentityActivationSwitchProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        ml={2}
        sx={{
          display: 'flex',
          width: 370,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="body2">
            {t(`${startCase(identityName)} Status`)}
          </Typography>
        </Box>
        <FormControlLabel
          control={
            <GreenSwitch
              checked={isActive}
              onChange={handleOnToggleActive}
              name="active"
            />
          }
          labelPlacement="bottom"
          label={
            <Typography variant="caption">
              {t(isActive ? 'Active' : 'Inactive')}
            </Typography>
          }
        />
      </Box>
    </>
  );
};
