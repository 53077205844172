import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Alert from '@mui/lab/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// import { VineaNovaSelectors } from 'vineanova-redux-artifacts';

import { VineaButton } from '../../components/VineaButton';
import logger from '../../utils/winstonLogger';
import { SamplesBlockTransferList } from '../../components/SamplingComponents';
import { getSampleSetById } from '../../redux/selectors';
import { useSamplesHook } from './hooks/useSamplesHook';
import { VineaTextField } from '../../components/TextField';

export const AddSampleRequestBlocks = () => {
  logger.debug('AddSampleHeaderBlocks');
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { id: sampleSetId } = useParams();

  /** state */
  const [isBlockApiReq, setBlockAPiReq] = React.useState(false);
  const mountedRef = React.useRef(null);

  /** selectors */
  const {
    id: sampleSetIdInfo,
    regionID,
    sampleSet = '',
  } = useSelector(state => getSampleSetById(state, sampleSetId));

  const {
    latestVintage: { id: latestVintageId } = {},
    handleOnChange,
    handleOnFetchSampleBlockVintages,
    sampleBVNotInSet,
    blockVintagesLoading,
    blockVintagesGroups,
    handleOnSaveBlocks,
    addBlocksFormData,
    handleOnChangeAddBlocksForm,
    warningInSubmit,
    setWarningInSubmit,
  } = useSamplesHook();

  const { recommendedSampleSize, errors } = addBlocksFormData;
  const handleOnCancel = () => {
    navigate(-1);
  };

  const handleOnSave = () => {
    handleOnSaveBlocks({
      sampleSetId: sampleSetIdInfo,
      recommendedSampleSize,
    });
  };

  React.useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
    }
  }, []);

  React.useEffect(() => {
    if (
      mountedRef.current &&
      !isNil(latestVintageId) &&
      !isNil(sampleSetIdInfo) &&
      !isNil(regionID) &&
      !isBlockApiReq
    ) {
      handleOnFetchSampleBlockVintages({
        id: sampleSetIdInfo,
        vintageID: latestVintageId,
        regionID,
        isInSampleSet: true,
      });

      setBlockAPiReq(true);
    }
  }, [
    mountedRef,
    latestVintageId,
    sampleSetIdInfo,
    regionID,
    isBlockApiReq,
    handleOnFetchSampleBlockVintages,
  ]);

  return (
    <Paper
      elevation={0}
      data-testid="samplerheader-addblocks"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="addblocks-grid">
        <Grid item xs={12}>
          <Box m={2}>
            <Typography variant="subtitle1">
              {`${t('Add blocks for sample set')} ${sampleSet}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={2}>
            {warningInSubmit && (
              <Collapse in={warningInSubmit}>
                <Alert
                  variant="standard"
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarningInSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Warning: Choose at least one block to create a Sample Request
                </Alert>
              </Collapse>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} data-testid="addblock-griditem">
          <SamplesBlockTransferList
            sampleBlockVintages={sortBy(sampleBVNotInSet, [
              'vineyardName',
              'blockName',
            ])}
            isLoading={blockVintagesLoading}
            groups={blockVintagesGroups}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid item>
          <Box mx={2} my={3} minWidth={250}>
            <VineaTextField
              label={t('Recommended sample size')}
              fullWidth
              value={recommendedSampleSize}
              onChange={handleOnChangeAddBlocksForm}
              name="recommendedSampleSize"
              error={errors.recommendedSampleSize}
            />
          </Box>
        </Grid>
        <Grid item xs={12} data-testid="buttongroup">
          <Box mx={2} mt={1}>
            <VineaButton
              color="success"
              onClick={handleOnSave}
              variant="contained"
              sx={{
                marginRight: theme.spacing(1),
                minWidth: 150,
              }}
              minWidth={100}
              // disabled={!!formWriteLoading}
            >
              {t('Save')}
            </VineaButton>
            <VineaButton
              variant="outlined"
              color="secondary"
              onClick={handleOnCancel}
              // disabled={!!formWriteLoading}
            >
              {t('Cancel')}
            </VineaButton>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
