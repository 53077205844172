import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { format, parseISO } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { isNull } from 'lodash';
// import filter from 'lodash/filter';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@mui/material/FormHelperText';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/lab/Alert';
import Skeleton from '@mui/lab/Skeleton';
import { useParams } from 'react-router';

// Icons
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import { Datepicker } from '../../../components/Datepicker';
import { VineaTextField } from '../../../components/TextField';
import {
  VineaButton,
  IconToolTipButton,
} from '../../../components/VineaButton';
import logger from '../../../utils/winstonLogger';
import { getFormWriteData } from '../../../redux/selectors';
import { useIdReferenceHook } from '../hooks/useIdReferenceHook';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { DeleteDialog } from '../../../components/Dialog';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { sagaActionTypes, viewDateFormat } from '../../../constants';
import { formatDate } from '../../../constants/formatter';
import VineaAlertMessage from './VineaAlertMessage';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

const CustomTableCell = ({ row, name, onChange, lkpReferences }) => {
  const theme = useTheme();
  const { isEditMode, errors, referenceTypeID, referenceValue } = row;
  const handleOnChange = e => {
    onChange(e, row);
  };

  return (
    <TableCell
      align="left"
      sx={{
        maxWidth: 130,
        height: 40,
        verticalAlign: 'center',
        '& .MuiFormControl-root': {
          minWidth: 100,
          marginTop: 0,
        },
        '& .MuiFormHelperText-root': {
          color: theme.palette.error.main,
        },
      }}
    >
      {isEditMode && name === 'referenceType' && (
        <>
          <VineaAutoComplete
            value={referenceTypeID || '0'}
            onChange={handleOnChange}
            inlineLabel
            options={lkpReferences}
            labelVariant="body1"
            name="referenceTypeID"
            displayLabel={false}
            inputProps={{
              name: 'referenceTypeID',
              'data-name': 'referenceTypeID',
            }}
            formControlMargin="dense"
          />
          {errors.referenceType && (
            <FormHelperText id="component-error-text">
              {errors.referenceType}
            </FormHelperText>
          )}
        </>
      )}
      {isEditMode && name === 'referenceValue' && (
        <>
          <VineaTextField
            autoComplete="off"
            id="referenceValue"
            size="small"
            inlineLabel
            value={referenceValue}
            name="referenceValue"
            onChange={handleOnChange}
          />
          {errors.referenceValue && (
            <FormHelperText id="component-error-text">
              {errors.referenceValue}
            </FormHelperText>
          )}
        </>
      )}
      {isEditMode && name === 'effectiveFromDate' && (
        <Datepicker
          disablePast={false}
          id="effectivefrom"
          placeholder="DD/MM/YYYY"
          inputFormat={viewDateFormat}
          value={row.effectiveFromDate}
          onChange={handleOnChange}
          name="effectiveFromDate"
          inputProps={{
            'data-testid': 'effectiveFrom',
            'data-name': 'effectiveFrom',
          }}
        />
      )}
      {isEditMode && name === 'effectiveToDate' && (
        <Datepicker
          disablePast={false}
          minDate={
            row.effectiveFromDate === null
              ? format(new Date(), viewDateFormat)
              : parseISO(row.effectiveFromDate)
          }
          id="effectiveto"
          placeholder="DD/MM/YYYY"
          inputFormat={viewDateFormat}
          value={row.effectiveToDate}
          onChange={handleOnChange}
          name="effectiveToDate"
          inputProps={{
            'data-testid': 'effectiveTo',
            'data-name': 'effectiveTo',
          }}
        />
      )}
      {!isEditMode &&
        name !== 'effectiveFromDate' &&
        name !== 'effectiveToDate' &&
        row[name]}
      {!isEditMode &&
        (name === 'effectiveFromDate' || name === 'effectiveToDate') &&
        formatDate(row[name])}
    </TableCell>
  );
};

export const ManageReferences = props => {
  logger.debug('Manage References.jsx');
  const theme = useTheme();
  const { t } = useTranslation('translation');
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  const OrgId = useIdentityTypeId(vineyardTypeScreenName);
  const dispatchAPI = useDispatch();
  const [alertOpen, setAlertOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [disableIcon, setDisableIcon] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [alertType, setAlertType] = useState(false);
  const { id: pathParam } = useParams();

  let { identityId } = props;
  if (isNull(identityId)) {
    identityId = pathParam;
  }

  const {
    lkpReferences,
    identityReferences: rows,
    onAddNewReference,
    isLoadingIdReference: isLoading,
    handleOnChangeRowData,
    onToggleEditMode,
    actionTriggered,
    setSeqToSave,
    setActionTriggered,
    handleRevert,
    seqToSave,
    alert,
    handleOnSaveRow,
    handleOnDeleteRow,
  } = useIdReferenceHook(OrgId, identityId, setDisableIcon);

  const { enqueueSnackbar } = useSnackbar();

  /** Selectors */
  const {
    isLoading: isFormSubmitLoading,
    hasError: hasErrorFormSubmit,
    isLoaded,
    error,
  } = useSelector(getFormWriteData);

  let errorFromAPI = '';

  if (error) {
    errorFromAPI = error.data;
  }

  const onAdd = id => {
    setDisableIcon(true);
    onAddNewReference(id);
  };

  const onDelete = id => {
    setRowId(id);
    setDialogOpen(true);
    setDisableIcon(false);
  };

  const onSave = id => {
    handleOnSaveRow(id);
  };

  const onEdit = id => {
    setDisableIcon(true);
    onToggleEditMode(id, rows);
  };

  const onRevert = id => {
    setDisableIcon(false);
    handleRevert(id);
  };

  React.useEffect(() => {
    /** When saving the selected reference id */
    if (actionTriggered) {
      if (!isFormSubmitLoading && isLoaded) {
        if (hasErrorFormSubmit) {
          // set form submitting has errors;
          setAlertOpen(true);
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else if (!hasErrorFormSubmit) {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          setAlertType(true);
          setDisableIcon(false);
          onToggleEditMode(seqToSave, rows);
          if (!isNull(identityId)) {
            dispatchAPI({
              type: sagaActionTypes.FETCH_IDENTITIES,
              payload: {
                id: identityId,
                identityTypeId: OrgId,
              },
            });
          }
        }
        setActionTriggered(false);
        setSeqToSave(null);
      }
    }
  }, [
    actionTriggered,
    setActionTriggered,
    setSeqToSave,
    isFormSubmitLoading,
    hasErrorFormSubmit,
    isLoaded,
    enqueueSnackbar,
    onToggleEditMode,
    seqToSave,
    t,
    rows,
    OrgId,
    dispatchAPI,
    identityId,
  ]);

  const onCloseAlert = () => {
    setAlertType(false);
    setAlertOpen(false);
  };

  return (
    <>
      <Paper
        sx={{
          width: '100%',
          margin: theme.spacing(3),
          overflowX: 'auto',
        }}
      >
        <Box paddingTop={1} />
        <Box px={2}>
          {alertOpen && (
            <Collapse
              in={alertOpen}
              timeout={300}
              onExit={() => setAlertOpen(false)}
              onExited={() => setAlertOpen(false)}
            >
              <Alert
                variant="outlined"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <p>{errorFromAPI}</p>
              </Alert>
            </Collapse>
          )}
          <VineaAlertMessage
            alertType={alertType}
            onExit={onCloseAlert}
            alert={alert}
            type="Reference"
          />
        </Box>
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label="caption table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" />
              <TableCell align="left">{t('Type')}</TableCell>
              <TableCell align="left">{t('Value')}</TableCell>
              <TableCell align="left">{t('Effective From')}</TableCell>
              <TableCell align="left">{t('Effective To')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow key="skeletontbody">
                <TableCell colSpan={11}>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              rows.map((row, idx) => (
                <>
                  <TableRow
                    key={row.id || idx}
                    data-testid={row.id}
                    data-testrow="table-row"
                    data-seq={idx + 1}
                    data-newrow={row.isEditMode}
                    sx={{ height: '20px' }}
                  >
                    <TableCell
                      sx={{
                        width: 160,
                      }}
                      data-testid="icons"
                    >
                      {row.isEditMode ? (
                        <>
                          <IconToolTipButton
                            size="small"
                            tooltip="Save"
                            label="done"
                            data-testid="iconsave"
                            onClick={() => {
                              onSave(row.id);
                            }}
                            sx={{
                              color: theme.palette.success.main,
                            }}
                            disabled={!!row.warning || !isEmpty(row.errors)}
                          >
                            <DoneAllIcon />
                          </IconToolTipButton>
                          <IconToolTipButton
                            size="small"
                            tooltip="Cancel"
                            data-testid="iconcancel"
                            onClick={() => {
                              onRevert(row.id);
                            }}
                            sx={{
                              color: theme.palette.error.main,
                            }}
                          >
                            <NotInterestedOutlinedIcon />
                          </IconToolTipButton>
                        </>
                      ) : (
                        <>
                          <IconToolTipButton
                            size="small"
                            tooltip="Edit"
                            data-testid="iconedit"
                            disabled={disableIcon}
                            onClick={() => {
                              onEdit(row.id);
                            }}
                            sx={{
                              color: '#404040',
                              marginTop: theme.spacing(2) * -1,
                            }}
                          >
                            <ModeEditOutlineOutlinedIcon />
                          </IconToolTipButton>
                          <IconToolTipButton
                            size="small"
                            tooltip="Delete"
                            label="Delete"
                            data-testid="icondelete"
                            disabled={disableIcon}
                            onClick={() => {
                              onDelete(row.id);
                            }}
                            sx={{
                              color: theme.palette.error.main,
                              marginTop: theme.spacing(2) * -1,
                            }}
                          >
                            <DeleteIcon />
                          </IconToolTipButton>
                        </>
                      )}
                    </TableCell>
                    <CustomTableCell
                      {...{
                        row,
                        name: 'referenceType',
                        onChange: handleOnChangeRowData,
                        lkpReferences,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: 'referenceValue',
                        onChange: handleOnChangeRowData,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: 'effectiveFromDate',
                        onChange: handleOnChangeRowData,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: 'effectiveToDate',
                        onChange: handleOnChangeRowData,
                      }}
                    />
                  </TableRow>
                  {row.warning && (
                    <TableRow>
                      <TableCell colSpan={11}>
                        <Alert severity="warning">{row.warning}</Alert>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
          </TableBody>
        </Table>
        <DeleteDialog
          open={dialogOpen}
          dialogHeader="Delete References"
          onClose={() => setDialogOpen(false)}
          dialogActionTrigger={handleOnDeleteRow}
          rowId={rowId}
        />
      </Paper>
      <Box paddingTop={1} />
      <VineaButton
        color="secondary"
        sx={{ marginLeft: theme.spacing(3) }}
        onClick={onAdd}
        variant="contained"
        minWidth={100}
        disabled={rows.some(row => row.isEditMode)}
        data-testid="addreferences"
      >
        {t('Add References')}
      </VineaButton>
    </>
  );
};

ManageReferences.propTypes = {
  identityId: PropTypes.string,
};

ManageReferences.defaultProps = {
  identityId: null,
};

CustomTableCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    effectiveToDate: PropTypes.string,
    effectiveFromDate: PropTypes.string,
    classificationGroup: PropTypes.string,
    classificationName: PropTypes.string,
    errors: PropTypes.shape({
      name: PropTypes.string,
      referenceType: PropTypes.string,
      referenceValue: PropTypes.string,
    }),
    isEditMode: PropTypes.bool,
    referenceType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    referenceValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    referenceTypeID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    methodType: PropTypes.string,
  }),
  name: PropTypes.string,
  onChange: PropTypes.func,
  lkpReferences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    }),
  ),
};

CustomTableCell.defaultProps = {
  row: {},
  name: '',
  onChange: f => f,
  lkpReferences: [],
};
