import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { VineaButton } from '../../../components/VineaButton';
import GridWithFilter from '../../../components/Grid/GridWithFilter';
import { GridNoRowMessage } from '../../../components/Grid/GridNoRowMessage';
import { sensorsGridColumn } from './sensorGridColumn';
import {
  getSensorGridSelector,
  getUserPreferences,
} from '../../../redux/selectors';
import { GreenSwitch } from '../../../components/Switch';
import { reducers, actionTypes } from '../../../constants';

export const SensorsGrid = ({ pathParamId, hasSensors, tabIsLoading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const [activeOnly, setActiveOnly] = React.useState(true);
  const [showNewSensorsAlert, setShowNewSensorsAlert] = React.useState(false);
  const [triggerGetSensors, setTriggerGetSensors] = React.useState(false);

  // get selector values
  const sensorsData = useSelector(getSensorGridSelector);
  const dataGridColumnsPreferences = useSelector(state =>
    getUserPreferences(state),
  );
  const { data: connectionSensorsPageFilter = {} } = useSelector(
    state => state.connectionSensorsPageFilter,
  );

  const lgColumnsTable = sensorsGridColumn();
  const gridXData = {
    columns: lgColumnsTable,
    rows: activeOnly
      ? sensorsData.filter(s => s.sensorStatusID === 1)
      : sensorsData,
  };

  const { isLoading: sensorsLoading, isLoaded: sensorsLoaded } = useSelector(
    VineaNovaSelectors.getSensorsEntityMeta,
  );

  const handleOnRowClick = evt => {
    const { id } = evt;
    navigate(`/connections/${pathParamId}/sensors/${id}`);
  };

  const NoRowsOverlayMessage = () => (
    <Box mt={10}>
      <GridNoRowMessage message="No sensors found. Sites must be activated for their sensors to be available." />
    </Box>
  );

  const getNumNewSensors = React.useCallback(
    () => sensorsData.filter(s => s.sensorStatusID === 3).length,
    [sensorsData],
  );

  const getAlertMessage = () => {
    const numSites = getNumNewSensors();
    const msg1 =
      numSites > 1
        ? t('new sensors have been found')
        : t('new sensor has been found');
    const msg2 = t('Sensors must be enabled in order to be used');

    return `${numSites} ${msg1}. ${msg2}`;
  };

  const dismissAllNewSensors = () => {
    sensorsData
      .filter(sensor => sensor.sensorStatusID === 3)
      .forEach(sensor => {
        dispatchAPI(
          VineaNovaActions.api.v1.sensors.put.request({
            postBody: {
              SensorID: sensor.id,
              SensorStatusID: 2, // 2 = Ignore/Not Required
              CollectionStartDate: sensor.startDate,
              VineyardBlockIDs: sensor.blockIDs,
              SensorName: sensor.sensorName,
              UnitOfMeasureID: sensor.unitOfMeasureID,
              ts: sensor.ts,
            },
          }),
        );
      });

    setTriggerGetSensors(true);
  };

  const handleRefresh = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.sensors.get.request({
        queryParams: {
          DataConnectorID: pathParamId,
          TriggerSync: true,
        },
      }),
    );
  };

  React.useEffect(() => {
    // if we got to this component somehow but it does not support sensors,
    // navigate back to the overview tab
    if (!tabIsLoading && !hasSensors) {
      navigate(`/connections/${pathParamId}/more`);
    }
  }, [tabIsLoading, hasSensors, navigate, pathParamId]);

  React.useEffect(() => {
    setShowNewSensorsAlert(getNumNewSensors() > 0);
  }, [getNumNewSensors]);

  React.useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.sensors.get.request({
        queryParams: {
          DataConnectorID: pathParamId,
        },
      }),
    );
  }, [dispatchAPI, pathParamId]);

  React.useEffect(() => {
    if (triggerGetSensors && !sensorsLoading && sensorsLoaded) {
      dispatchAPI(
        VineaNovaActions.api.v1.sensors.get.request({
          queryParams: {
            DataConnectorID: pathParamId,
          },
        }),
      );
      setTriggerGetSensors(false);
    }
  }, [
    triggerGetSensors,
    sensorsLoading,
    sensorsLoaded,
    dispatchAPI,
    pathParamId,
  ]);

  const onUpdateFilterData = data => {
    /// setFilterData(data);
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.connectionSensorsPageFilter,
      payload: data,
    });
  };

  return (
    <Box sx={{ flexGrow: 1, minHeight: 100 }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper elevation={0}>
            {showNewSensorsAlert && (
              <Box px={2} pt={2}>
                <Alert
                  variant="outlined"
                  severity="info"
                  action={
                    <>
                      <VineaButton
                        variant="text"
                        color="secondary"
                        onClick={dismissAllNewSensors}
                      >
                        {t('Dismiss All')}
                      </VineaButton>
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setShowNewSensorsAlert(false)}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    </>
                  }
                >
                  {getAlertMessage()}
                </Alert>
              </Box>
            )}

            <Box
              display="flex"
              flex={1}
              flexDirection="row"
              justifyContent="space-between"
              p={2}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6" color="textPrimary">
                  {t('Sensors')}
                </Typography>
              </Box>
              <Box display="inline-flex">
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={activeOnly}
                      onChange={() => setActiveOnly(!activeOnly)}
                    />
                  }
                  label={
                    <Typography variant="caption">
                      {t('Active only')}
                    </Typography>
                  }
                />
                <VineaButton
                  variant="text"
                  color="secondary"
                  sx={{ marginRight: 1 }}
                  onClick={handleRefresh}
                >
                  {t('Refresh')}
                </VineaButton>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ height: 450 }}>
            <GridWithFilter
              data={gridXData}
              onRowClick={handleOnRowClick}
              rowHeight={30}
              autoPageSize
              loading={sensorsLoading}
              components={{
                NoRowsOverlay: NoRowsOverlayMessage,
              }}
              filterModel={connectionSensorsPageFilter}
              filterUpdateActionType={reducers.connectionSensorsPageFilter}
              actionType="SENSORS_GRID_COLUMN_UPDATE"
              gridColumnState={dataGridColumnsPreferences.sensorsDataGrid}
              advancedSearch={false}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

SensorsGrid.propTypes = {
  pathParamId: PropTypes.number.isRequired,
  hasSensors: PropTypes.bool.isRequired,
  tabIsLoading: PropTypes.bool.isRequired,
};
