/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
export const DEBUG = true;

export const getSWConstants = () => {
  const isTest = self.location.href.includes('vineanovawebapptest');
  const isProd = self.location.href.includes('myvinea');
  // const isLocalHost = window.location.href.includes('localhost:');

  const domain = isTest
    ? 'vineanovawebapptest.azurewebsites.net'
    : isProd
    ? 'myvinea.net'
    : 'vineanovawebappdev.azurewebsites.net';

  const baseUrl = 'http://localhost:3001/';

  const protocol = 'https';
  const domainName = domain; // 'vineanovawebapptest.azurewebsites.net';

  const CORSOrigins = `*.${domainName}`;

  const URL_SITE = `${protocol}://${domainName}`;
  const URL_API = `${protocol}://api.${domainName}`;
  const URL_CDN = `${protocol}://cdn.${domainName}`;
  const PUBLIC_URL = URL_SITE;

  // const resumeUrl = `${URL_CDN}/doc/Resume-Lawrence-McDaniel-202211b.pdf`;
  // const backendUrl = `${URL_API}/wp-json/wp/v2/`;

  // Wordpress API Content URL end points
  // const URL_API_SPECIALTIES = `${backendUrl}posts?categories=43&_embed&per_page=100`;
  // const URL_API_PORTFOLIO = `${backendUrl}posts?categories=47&_embed&per_page=100`;
  // const URL_API_EDUCATION = `${backendUrl}posts?categories=44&_embed&per_page=100`;
  // const URL_API_RECOMMENDATIONS = `${backendUrl}posts?categories=45&_embed&per_page=100`;
  // const URL_API_PROJECTS = `${backendUrl}media?include=2324,2320,2319,2300,2295,2296,2297,2298,2299,2301,2302,2303`;
  // const URL_API_CLIENTS = `${backendUrl}posts?categories=46&_embed&per_page=100`;

  return {
    baseUrl,
    CORSOrigins,
    protocol,
    URL_SITE,
    URL_API,
    URL_CDN,
    DEBUG,
    PUBLIC_URL,
  };
};
