/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import withAuthProvider from '../../hooks/AuthProvider';

const Login = ({ login, logout }) => {
  // const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div>
        <Card
          sx={{
            backgroundColor: theme.palette.white,
            display: 'flex',
          }}
        >
          <CardContent>
            <Typography variant="h5">{t('Redux Example')}</Typography>
            <Typography align="center" variant="subtitle1">
              Counter:
            </Typography>
          </CardContent>
          <CardActions>
            <Button color="secondary" variant="contained" onClick={login}>
              Login
            </Button>
            <Button color="secondary" variant="outlined" onClick={logout}>
              Logout
            </Button>
          </CardActions>
        </Card>
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default withAuthProvider(Login);
