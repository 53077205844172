import React from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import PreviewIcon from '@mui/icons-material/Preview';
import { useSelector } from 'react-redux';

import {
  RenderDataGridHeader,
  GridValueDateFormatter,
} from '../../components/Grid';
import palette from '../../theme/palette';
import { MeasureValueFormatter } from './Grid/PhenologyValueFormatter';
import { getLookupVintage } from '../../redux/selectors';
import { find, get } from 'lodash';

const renderImageIcon = params => {
  const { value } = params;
  return value ? (
    <AttachFileIcon htmlColor={palette.palette.text.disabled} />
  ) : null;
};

export const dataGridVintageColumn = [
  {
    field: 'measureDate',
    headerName: 'Date',
    type: 'dateTime',
    flex: 0.5,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueFormatter: ({ value }) => GridValueDateFormatter(value),
  },
  {
    field: 'measure',
    headerName: 'Measure',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueFormatter: data => MeasureValueFormatter(data),
  },
  {
    field: 'measureValue',
    headerName: 'Value',
    flex: 0.5,
    hideable: false,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'cropMeasurementComment',
    headerName: 'Comment',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'noteImage',
    headerName: 'Image',
    flex: 0.4,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    renderCell: renderImageIcon,
  },
];

export const dataGridPhenologyColumn = [
  {
    field: 'phenologyMeasureDate',
    headerName: 'Date',
    type: 'dateTime',
    flex: 0.5,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueFormatter: ({ value }) => GridValueDateFormatter(value),
  },
  {
    field: 'measure',
    headerName: 'Measure',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueFormatter: data => MeasureValueFormatter(data),
  },
  {
    field: 'measureValue',
    headerName: 'Value',
    flex: 0.5,
    hideable: false,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'phenologyComment',
    headerName: 'Comment',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'noteImage',
    headerName: 'Image',
    flex: 0.4,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    renderCell: renderImageIcon,
  },
];

export const dataGridNotesColumn = [
  {
    field: 'noteDate',
    headerName: 'Date',
    type: 'dateTime',
    flex: 0.5,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueFormatter: ({ value }) => GridValueDateFormatter(value),
  },
  {
    field: 'note',
    headerName: 'Note Preview',
    flex: 2,
    hideable: false,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'measure',
    headerName: 'Referred measure instance',
    flex: 1,
    hideable: false,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    flex: 1,
    hideable: false,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'modifiedBy',
    headerName: 'Updated by',
    flex: 1,
    hideable: false,
    type: 'string',
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'noteImage',
    headerName: 'Image',
    flex: 0.4,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    renderCell: renderImageIcon,
  },
];

export const dataGridOtherAttributeTrackingColumns = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'string',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'cropSettingInstance',
    headerName: 'Attribute',
    type: 'string',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'cropSettingOption',
    headerName: 'Value',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
];

export const dataGridOtherAttributeVineyardBlockColumns = [
  {
    field: 'vintage',
    headerName: 'Vintage',
    type: 'string',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'cropSettingInstance',
    headerName: 'Attribute',
    type: 'string',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'cropSettingOption',
    headerName: 'Value',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
];

export const dataGridAttachedDocumentsColumn = [
  {
    field: 'date',
    headerName: 'Date',
    type: 'dateTime',
    width: 100,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    valueFormatter: ({ value }) => GridValueDateFormatter(value),
  },
  {
    field: 'subject',
    headerName: 'Subject',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'filename',
    headerName: 'Filename',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'viewDocButton',
    headerName: '',
    width: 45,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    renderCell: props => {
      const { filename } = props.row;
      const previewList = ['pdf', 'txt', 'csv', 'jpg', 'png', 'jpeg'];
      const fileExtension = filename.split('.').pop();
      const isPreviewable = find(
        previewList,
        ext => ext === fileExtension?.toLowerCase(),
      );
      if (!isPreviewable) {
        return null;
      }
      return (
        <IconButton size="small">
          <PreviewIcon htmlColor={palette.palette.text.primary} />
        </IconButton>
      );
    },
  },
  {
    field: 'downloadButton',
    headerName: '',
    width: 45,
    hideable: false,
    renderHeader: RenderDataGridHeader,
    renderCell: () => {
      return (
        <IconButton size="small">
          <GetAppIcon htmlColor={palette.palette.text.primary} />
        </IconButton>
      );
    },
  },
];
