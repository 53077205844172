/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export const VineaTreeViewComponent = ({
  groups = [],
  blocks = [],
  onNodeSelect,
  selected = [],
  isLoading = false,
  side = 'left',
}) => {
  const [expanded, setExpanded] = React.useState([]);

  const handleToggle = React.useCallback((event, nodeIds) => {
    setExpanded(nodeIds);
  }, []);

  const handleSelect = React.useCallback(
    (event, nodeIds) => {
      if (nodeIds) onNodeSelect(nodeIds || []);
    },
    [onNodeSelect],
  );

  const childNodeElements =
    Array.isArray(blocks) && blocks.length > 0 ? blocks : [];

  const validChildNodeElements = childNodeElements.map(f => {
    if (groups.includes(f.vineyardName)) {
      return f.vineyardName;
    }
    return '';
  });

  const availableGroups = groups.filter(vintage =>
    validChildNodeElements.includes(vintage),
  );

  return (
    <TreeView
      sx={{
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
      }}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
      multiSelect
    >
      {isLoading && (
        <Box width="100%" display="inline-flex" px={1}>
          <Box width="100%" px={1}>
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="100%" height={20} />
          </Box>
        </Box>
      )}
      {availableGroups &&
        side === 'left' &&
        !isLoading &&
        availableGroups.map((vintage, idx) => {
          return (
            <TreeItem
              nodeId={vintage}
              key={vintage}
              label={vintage}
              data-testid={`left-blockvintages-${idx}`}
            >
              {childNodeElements
                .filter(f => f.vineyardName === vintage)
                .map(mp => {
                  return (
                    <TreeItem
                      key={mp.blockVintageID}
                      nodeId={mp.blockVintageID}
                      label={mp.blockName}
                      data-testid={mp.blockName}
                    />
                  );
                })}
            </TreeItem>
          );
        })}
      {availableGroups &&
        side === 'right' &&
        !isLoading &&
        availableGroups.map((vintage, idx) => {
          return (
            <TreeItem
              nodeId={vintage}
              key={vintage}
              label={vintage}
              data-testid={`right-blockvintages-${idx}`}
            >
              {childNodeElements
                .filter(f => f.vineyardName === vintage)
                .map(mp => {
                  return (
                    <TreeItem
                      key={mp.blockVintageID}
                      nodeId={mp.blockVintageID}
                      label={mp.blockName}
                      data-testid={mp.blockName}
                    />
                  );
                })}
            </TreeItem>
          );
        })}
    </TreeView>
  );
};
