/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  isEqual,
  isEmpty,
  get,
  forEach,
  isNull,
  filter,
  isNil,
  map,
  includes,
  sortBy,
  clone,
  capitalize,
} from 'lodash';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { AzureMapsProvider } from 'react-azure-maps';
import { wktToGeoJSON } from '@terraformer/wkt';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import iwanthue from 'iwanthue';
import withFeatureFlagHOC from '../../hooks/withFeatureFlagHOC';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import { featureFlags, reducers, actionTypes } from '../../constants';
import { VineaDialog } from '../../components/Dialog';
import AzureMaps from '../../components/MapsComponent/VineyardLocationMapController';
import {
  getUserPreferences,
  areLookupsLoaded,
  getGrowerRegion,
  getAllVineyards,
  getDisplayAttribute,
  getGrowerSubRegionWithAll,
} from '../../redux/selectors';
import { FilterTextDescription } from '../../components/DashboardComponents/FilterTextDescription';
import VineyardExplorerFilterScreen, {
  LookupPropType,
} from '../../components/MapsComponent/VineyardExplorerFilterScreen';
import useLocationListener from '../../hooks/useLocationListener';
import useIdentityTypeScreenNameHook from '../../hooks/useIdentityTypeScreenNameHook';

const RootDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: '50%',
}));

const ALL_REGIONS_ID = 0;

type BoxType = {
  theme?: any;
  isFilterOpen: boolean;
};

const StyledBox = styled(Box)(({ theme, isFilterOpen }: BoxType) => ({
  ...(isFilterOpen && {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flex: '0.4',
    },
    [theme.breakpoints.up('md')]: {
      flex: '0.3',
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0.18',
    },
  }),
  ...(!isFilterOpen && { display: 'flex', flex: 0 }),
}));

type ClickedVineyard = {
  id: number;
  vineyardName: string;
};

function VineyardLocationMap() {
  const dispatchAPI = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const newLocationKeys: any = useLocationListener();
  const { setIsLocked, isLocked } = useTabChangeLock();
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  const lkpDisplayAttribute: any = useSelector(state =>
    getDisplayAttribute(state),
  );
  // hue pallate setup
  const palette = useMemo(() => {
    return iwanthue(50, {
      clustering: 'force-vector',
      seed: 'cool-palette',
      quality: 100,
    });
  }, []);

  // Local State
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [locationKeys, setLocationKeys] = useState([]);
  const [filterText, setFilterText] = useState<any>([]);
  const [clickedVineyard, setClickedVineyard] =
    useState<ClickedVineyard | null>(null);
  const [filters, setFilters] = useState<any[]>([]);

  const getVarietyColor = (filter: any) => {
    const v = filters.find(f => f.filterName === filter);
    if (v !== undefined) {
      return v.colour;
    }
    return '';
  };

  // Selectors
  const isLookupsLoaded = useSelector(areLookupsLoaded);
  let lkpGrowingRegion = useSelector(state => getGrowerRegion(state));
  lkpGrowingRegion[0] = { id: 0, key: 0, value: 'All' }; // Change first option from 'Select' to 'All'

  const lkpGrowingSubRegion = useSelector(state =>
    getGrowerSubRegionWithAll(state),
  );
  const { basicSettings } = useSelector(state => getUserPreferences(state));
  const { filterPanelCollapsed, defaultRegionID, defaultVineyardID } =
    basicSettings;

  const allVineyards = useSelector(state => getAllVineyards(state));

  const { data: filterData = {} }: any = useSelector(
    (state: any) => state.vineyardExplorerSearchFilter,
  );

  const subRegionOptions = useMemo(() => {
    if (get(filterData, 'regionId', 0) <= 0) {
      return lkpGrowingSubRegion;
    }
    const options = filter(
      lkpGrowingSubRegion,
      sr =>
        get(sr, 'growingRegionID') === filterData.regionId ||
        get(sr, 'id') === 0,
    );

    return options;
  }, [filterData.regionId]) as LookupPropType[];

  const vineyardOptions = useMemo(() => {
    // Filter sub region by regionID, but get all subregions if regionID = 0 (All) is selected
    const subRegionIDs = map(subRegionOptions, 'id');

    const vineyardsInSubRegion = filter(
      allVineyards,
      v =>
        includes(subRegionIDs, get(v, 'subRegionID')) &&
        (get(v, 'subRegionID') === filterData.subRegionId ||
          get(filterData, 'subRegionId', 0) <= 0) &&
        (get(v, 'hasVineyardGeometry', false) ||
          get(v, 'hasBlockGeometry', false)),
    );

    // Sort by value
    let vineyardsFormatted = sortBy(
      map(vineyardsInSubRegion, v => ({
        id: get(v, 'id'),
        key: get(v, 'id'),
        value: get(v, 'value'),
      })),
      'value',
    );

    // Insert all option at top of list
    const vineyardsWithAll = clone(vineyardsFormatted);
    if (filterData.regionId !== ALL_REGIONS_ID) {
      vineyardsWithAll.splice(0, 0, {
        id: 0,
        key: 0,
        value: 'All',
      });
    }

    return vineyardsWithAll;
  }, [subRegionOptions, filterData.subRegionId, allVineyards]);

  const vineyardGeometryData: any = useSelector(
    VineaNovaSelectors.getVineyardGeometryEntityData,
  );

  const vineyardGeometries = filter(
    vineyardGeometryData,
    geoData => !isNil(geoData.geometry),
  );
  const blockGeometries: any = useSelector(
    VineaNovaSelectors.getVineyardBlocksGeometryEntityData,
  );

  const {
    isLoading: blockGeometriesLoading,
    isLoaded: blockGeometriesLoaded,
  }: any = useSelector(VineaNovaSelectors.getVineyardBlocksGeometryEntityMeta);

  const defaultFilterValues = useMemo(
    () => ({
      regionId: defaultRegionID,
      vineyardId: defaultVineyardID,
      displayAttribute: 1,
    }),
    [defaultRegionID, defaultVineyardID],
  );
  const filterNameMap = useMemo(() => {
    const getLkpText = (id: number, lkp: any[]) =>
      id === 0 ? [] : [lkp?.find((v: any) => v?.id === id)?.value];

    return {
      regionId: {
        name: 'Region',
        getValueText: (v: any) => getLkpText(v, lkpGrowingRegion),
      },
      subRegionId: {
        name: 'Sub-Region',
        getValueText: (v: any) => getLkpText(v, lkpGrowingSubRegion),
      },
      vineyardId: {
        name: `${capitalize(vineyardTypeScreenName)}`,
        getValueText: (v: any) => getLkpText(v, allVineyards),
      },
      displayAttribute: {
        name: 'Display Attribute',
        getValueText: (v: any) => getLkpText(v, lkpDisplayAttribute),
      },
    };
  }, [lkpGrowingRegion, allVineyards, lkpDisplayAttribute]);

  const handleOnClearFilters = () => {
    setSelectedFilters([]);
  };

  const handleOnShowFilters = () => {
    setSelectedFilters(filters);
  };

  const handleOnFilterSelect = (val: any) => {
    const selected = selectedFilters.findIndex(
      filter => val.colour === filter.colour,
    );
    if (selected === -1) {
      setSelectedFilters([...selectedFilters, val]);
    } else {
      const newSelected: any = selectedFilters.filter(
        (filter: any) => val.colour !== filter.colour,
      );
      setSelectedFilters(newSelected);
    }
  };

  const selected = useMemo(() => {
    const f = lkpDisplayAttribute?.find(
      (l: any) => l.id === filterData?.displayAttribute,
    );
    if (!isNull(f) && f !== undefined) {
      if (f.value === 'Variety') {
        return 'varietyName';
      } else if (f.value === 'Post Type') {
        return 'postType';
      } else if (f.value === 'Floret Counts') {
        return 'floretCounts';
      }
      return 'caneNumber';
    }
    return 'postType';
  }, [filterData.displayAttribute]);

  const vineyardsGeoJson = useMemo(
    () => ({
      type: 'FeatureCollection',
      features:
        vineyardGeometries?.map(({ id, geometry, vineyardName }: any) => ({
          type: 'Feature',
          properties: {
            id,
            vineyardName,
            labelText: vineyardName,
            type: 'VINEYARD',
          },
          geometry: wktToGeoJSON(geometry),
        })) || [],
    }),
    [vineyardGeometries],
  );

  const blocksGeoJson = useMemo(
    () => ({
      type: 'FeatureCollection',
      features:
        blockGeometries?.map(
          ({
            id,
            geometry,
            blockName,
            vineyardID,
            variety,
            postType,
            caneNumber,
            floretCounts,
          }: any) => ({
            type: 'Feature',
            properties: {
              id,
              blockName,
              vineyardID,
              labelText: blockName,
              color:
                selected === 'varietyName'
                  ? getVarietyColor(variety)
                  : selected === 'postType'
                  ? getVarietyColor(postType)
                  : selected === 'caneNumber'
                  ? getVarietyColor(caneNumber)
                  : getVarietyColor(floretCounts),
              varietyName: variety,
              caneNumber,
              postType,
              floretCounts,
              type: 'BLOCK',
            },
            geometry: wktToGeoJSON(geometry),
          }),
        ) || [],
    }),
    [blockGeometries, filters, filterData.displayAttribute, selected],
  );
  const selectedFilterBlocks = React.useMemo(() => {
    if (isEmpty(blocksGeoJson)) {
      return [];
    }
    const selectedFeatures = blocksGeoJson.features.filter((feature: any) =>
      selectedFilters.some(e => e.filterName === feature.properties[selected]),
    );
    const selectedgeoJson = {
      type: 'FeatureCollection',
      features: selectedFeatures,
    };
    return selectedgeoJson;
  }, [blocksGeoJson, selectedFilters]);

  const getBlockGeometries = () => {
    if (!blockGeometriesLoaded && !blockGeometriesLoading) {
      dispatchAPI(
        VineaNovaActions.api.v1.vineyardBlocksGeometry.get.request({
          queryParams: {
            VineyardID: null, // get blocks from all vineyards
          },
        }),
      );
    }
  };

  const handleOnClickVineyard = (vineyard: ClickedVineyard) => {
    setClickedVineyard(vineyard);
  };

  const handleOnNavigateToVineyard = () => {
    navigate(`/organisation/${vineyardTypeScreenName}s/${clickedVineyard?.id}`);
  };

  const handleOnFilterToggle = React.useCallback(() => {
    dispatchAPI({
      type: 'BASIC_SETTINGS_UPDATE',
      payload: {
        ...basicSettings,
        filterPanelCollapsed: !filterPanelCollapsed,
      },
    });
  }, [filterPanelCollapsed, basicSettings, dispatchAPI]);

  const handleOpenFilter = React.useCallback(() => {
    if (filterPanelCollapsed) {
      handleOnFilterToggle();
    }
  }, [filterPanelCollapsed, handleOnFilterToggle]);

  const onUpdateFilterData = React.useCallback(
    data => {
      dispatchAPI({
        type: actionTypes.updateData,
        name: reducers.vineyardExplorerSearchFilter,
        payload: data,
      });
      const newFilterText: any = Object.keys(data)?.map(filterName => {
        const { name, getValueText } = get(filterNameMap, [filterName], {});
        return name
          ? { filterName: name, values: getValueText(data[filterName]) }
          : { filterName, values: [data[filterName]] };
      });
      setFilterText(newFilterText);
    },
    [dispatchAPI, filterNameMap],
  );

  useEffect(() => {
    let colorIterator = 0;
    if (filterData.displayAttribute !== 0) {
      const newFilters: any[] = [];
      if (filterData.displayAttribute === 1) {
        forEach(blockGeometries, b => {
          const isPresent = newFilters.some(f => f.filterName === b.postType);
          if (!isPresent && !isNull(b.postType)) {
            newFilters.push({
              filterName: b.postType,
              colour: palette[colorIterator],
            });
            colorIterator += 1;
          }
        });
      }
      if (filterData.displayAttribute === 2) {
        forEach(blockGeometries, b => {
          const isPresent = newFilters.some(f => f.filterName === b.caneNumber);
          if (!isPresent && !isNull(b.caneNumber)) {
            newFilters.push({
              filterName: b.caneNumber,
              colour: palette[colorIterator],
            });
            colorIterator += 1;
          }
        });
      }
      if (filterData.displayAttribute === 3) {
        forEach(blockGeometries, b => {
          const isPresent = newFilters.some(f => f.filterName === b.variety);
          if (!isPresent && !isNull(b.variety)) {
            newFilters.push({
              filterName: b.variety,
              colour: palette[colorIterator],
            });
            colorIterator += 1;
          }
        });
      }
      if (filterData.displayAttribute === 4) {
        forEach(blockGeometries, b => {
          const isPresent = newFilters.some(
            f => f.filterName === b.floretCounts,
          );
          if (!isPresent && !isNull(b.floretCounts)) {
            newFilters.push({
              filterName: b.floretCounts,
              colour: palette[colorIterator],
            });
            colorIterator += 1;
          }
        });
      }
      setFilters(newFilters);
    }
  }, [blockGeometries, filterData.displayAttribute]);

  useEffect(() => {
    if (isLocked) {
      setIsLocked(false);
    }
    if (!isEqual(locationKeys, newLocationKeys) && isLookupsLoaded) {
      setLocationKeys(newLocationKeys);
      let initialFilterData = {};
      // set defaults when first visiting (overridden if visited again)
      if (isEmpty(filterData)) {
        initialFilterData = { ...defaultFilterValues };
      } else {
        initialFilterData = { ...filterData, ...defaultFilterValues };
      }
      onUpdateFilterData(initialFilterData);
    }
  }, [
    defaultFilterValues,
    dispatchAPI,
    filterData,
    isLookupsLoaded,
    locationKeys,
  ]);

  useEffect(() => {
    //Then call APIs to fetch data for newly selected vineyard
    dispatchAPI(
      VineaNovaActions.api.v1.vineyardGeometry.get.request({
        queryParams: {
          VineyardID: filterData.vineyardId,
          RegionID: filterData.regionId,
          SubRegionID: filterData.subRegionId,
        },
      }),
    );
    dispatchAPI(
      VineaNovaActions.api.v1.vineyardBlocksGeometry.get.request({
        queryParams: {
          VineyardID: filterData.vineyardId,
          RegionID: filterData.regionId,
          SubRegionID: filterData.subRegionId,
        },
      }),
    );
    dispatchAPI(VineaNovaActions.api.v1.vineyardBlocksGeometry.get.cleardata());
  }, [filterData]);

  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.getLookUp.get.request({
        queryParams: {
          LookUpName: 'GrowingRegion',
        },
      }),
    );
  }, []);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      data-testid="vineyard-explorer-parent-container"
      height="100%"
    >
      <RootDiv data-testid="vineyard-explorer-root-div">
        <StyledBox isFilterOpen={!filterPanelCollapsed}>
          <VineyardExplorerFilterScreen
            isFilterOpen={!filterPanelCollapsed}
            onFilterToggle={handleOnFilterToggle}
            filterData={filterData}
            lkpGrowingRegion={lkpGrowingRegion}
            lkpGrowingSubRegion={subRegionOptions}
            lkpVineyards={vineyardOptions}
            lkpDisplayAttributes={lkpDisplayAttribute}
            onUpdateFilterData={onUpdateFilterData}
            nameMap={filterNameMap}
            filters={filters}
            selectedFilters={selectedFilters}
            handleOnFilterSelect={handleOnFilterSelect}
            handleOnClearFilters={handleOnClearFilters}
            handleOnShowAll={handleOnShowFilters}
            vineyardTypeScreenName={vineyardTypeScreenName}
          />
        </StyledBox>
        <Stack sx={{ width: '100%' }}>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Paper
              elevation={1}
              sx={{
                width: 'max-content',
                padding: 2,
                marginLeft: 2,
                cursor: 'pointer',
              }}
              onClick={handleOpenFilter}
            >
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <FilterTextDescription
                  selectedValuesText={filterText}
                  showBlankValues
                  isLarge
                  iconColour="primary"
                />
              </Stack>
            </Paper>
          </Stack>
          <Box mt={2}>
            <Divider />
          </Box>
          <Box
            sx={{ width: '100%', height: '100%' }}
            data-testid="vinea-block-maps"
          >
            <AzureMapsProvider>
              <AzureMaps
                vineyardsGeoJson={vineyardsGeoJson}
                blocksGeoJson={blocksGeoJson}
                getBlockGeometries={getBlockGeometries}
                selectedGeoJson={selectedFilterBlocks}
                handleOnClickVineyard={handleOnClickVineyard}
                isFilterOpen={!filterPanelCollapsed}
              />
            </AzureMapsProvider>
            <VineaDialog
              open={clickedVineyard !== null}
              dialogHeader={`${t('Navigate to')} ${
                clickedVineyard?.vineyardName
              }?`}
              dialogContent={`${t('Go to the Manage Vineyard screen for')} ${
                clickedVineyard?.vineyardName
              }`}
              primaryAction={handleOnNavigateToVineyard}
              onClose={() => setClickedVineyard(null)}
              primaryText={t('Navigate')}
              secondaryAction={undefined}
            />
          </Box>
        </Stack>
      </RootDiv>
    </Box>
  );
}

export default withFeatureFlagHOC(featureFlags.VINEYARD_LOCATION)(
  VineyardLocationMap,
);
