import { createSelector } from 'reselect';
import { filter, find, map, orderBy } from 'lodash';
import { IdentityTypeIds } from '../../constants';

const getResponseData = state => state.formWrite;
const referenceTypeData = state => state.entities.lookupReferenceType.data;
const referenceTypeIdentityTypeData = state =>
  state.entities.lookupReferenceTypeIdentityType.data;

export const getResponseLoadedState = state => state.formWrite.isLoaded;

export const getFormResponse = createSelector(
  [getResponseData],
  formResponse => {
    const { isLoading, isLoaded, hasError, data } = formResponse;
    const { id = null } = data;
    return {
      isLoading,
      hasError,
      isLoaded,
      referenceId: id,
    };
  },
);

export const getReferenceOptions = createSelector(
  [referenceTypeData, referenceTypeIdentityTypeData],
  (type, idType) => {
    const options = [
      {
        key: '0',
        id: '0',
        value: 'Select',
      },
    ];
    const personTypeValues = filter(idType, record => {
      return record.identityTypeID === IdentityTypeIds.PERSON;
    }).map(option => {
      return {
        order: option.displayOrder,
        referenceTypeID: option.referenceTypeID,
      };
    });
    const findOrderBasedOnRefernceTypeID = (array, id) => {
      const selectedValue = find(
        array,
        record => record.referenceTypeID === id,
      );
      const { order } = selectedValue;
      return order;
    };

    const referenceResultValues = filter(type, record => {
      return find(
        personTypeValues,
        param => record.id === param.referenceTypeID,
      );
    }).map(option => {
      return {
        order: findOrderBasedOnRefernceTypeID(personTypeValues, option.id),
        referenceTypeID: option.id,
        referenceType: option.referenceType,
      };
    });

    const referenceOptions = options.concat(
      map(orderBy(referenceResultValues, ['order']), option => {
        return {
          key: option.referenceTypeID,
          id: option.referenceTypeID,
          value: option.referenceType,
        };
      }),
    );
    return referenceOptions;
  },
);
