/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { useTheme } from '@mui/material';
import ilogo from '../../assets/placeholder.png';

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  flex: 'none',
  marginRight: theme.spacing(3),
}));
export default function SampleHeaderDetailsExt1({
  sampleSet,
  region,
  createdBy,
  performance,
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        boxShadow: 'none',
      }}
      data-id="card-rooot"
    >
      <CardMedia
        sx={{
          width: 100,
        }}
        image={ilogo}
        title="Sample header"
        data-id="card-media"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
        data-id="card-details"
      >
        <Box
          sx={{
            flex: '1 0 auto',
            paddingTop: 1,
            paddingBottom: 1,
            marginLeft: theme.spacing(1),
          }}
        >
          <Typography variant="h6">{sampleSet}</Typography>
          <List
            sx={{
              backgroundColor: theme.palette.background.paper,
              paddingTop: 0,
              paddingBottom: 0,
              '& .MuiListItem-root': {
                alignItems: 'flex-start',
              },
            }}
            dense
          >
            <ListItem disableGutters>
              <StyledListItemText id="total-area">
                <Typography variant="body2">{`${t('Region')}:`}</Typography>
              </StyledListItemText>
              <StyledListItemText>
                <Typography variant="body1" align="left" data-testid="region">
                  {region}
                </Typography>
              </StyledListItemText>
            </ListItem>
            <ListItem disableGutters>
              <StyledListItemText id="planted-area" data-testid="planted-area">
                <Typography variant="body2">{`${t('Created By')}:`}</Typography>
              </StyledListItemText>
              <StyledListItemText>
                <Typography variant="body1" align="left">
                  {createdBy}
                </Typography>
              </StyledListItemText>
            </ListItem>
            <ListItem disableGutters>
              <StyledListItemText id="planted-area">
                <Typography variant="body2">
                  {`${t('Performance')}:`}
                </Typography>
              </StyledListItemText>
              <StyledListItemText>
                <Typography
                  variant="body1"
                  align="left"
                  data-testid="performance"
                >
                  {performance}
                </Typography>
              </StyledListItemText>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Card>
  );
}

SampleHeaderDetailsExt1.propTypes = {};

SampleHeaderDetailsExt1.defaultProps = {
  identityOverviewDetails: {},
};
