import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { FieldLabelInput } from '../../../components/TextField';
import { VineaButton } from '../../../components/VineaButton';
import { DiscardDialog } from '../../../components/Dialog';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';
import { JobBlockRowType } from '../hooks/useJobBlockRowsHook';
import {
  StyledButtonControlDiv,
  StyledCloseDiv,
  StyledFieldLabelInput,
  StyledFormControl,
} from '../../../components/FormComponent/FormComponent';

export function ManageJobBlockRowDrawer({
  isOpen,
  handleOnClose,
  handleOnSave,
  handleOnChange,
  jobBlockRowData,
  unitsColumnText,
  validationErrors = {},
}: {
  isOpen: boolean;
  handleOnClose: () => void;
  handleOnSave: () => void;
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  jobBlockRowData?: JobBlockRowType;
  unitsColumnText: string;
  validationErrors: any;
}) {
  const { t } = useTranslation();
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const drawerHeader = 'Edit Job Block Row';

  useEffect(() => {
    validationErrors = {};
  }, [isOpen]);

  const toggleDrawer = (event: React.KeyboardEvent) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const onSave = () => {
    handleOnSave();
  };

  const handleOnDiscard = () => {
    handleOnClose();
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={handleOnClickAway}
      >
        <Box sx={{ width: 380 }} role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography sx={{ padding: 2 }} variant="body1">
                {t(drawerHeader)}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOnDiscard}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />
            <Box my={2}>
              <StyledFormControl data-testid="jobblockdrawerrownumber">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="rowNumber"
                  name="rowNumber"
                  label={t('Row Number')}
                  inlineLabel
                  value={jobBlockRowData?.rowNumber}
                  onChange={handleOnChange}
                  error={validationErrors.rowNumber}
                  fullWidth
                  disabled={true}
                  onBlur={undefined}
                  classes={undefined}
                  rows={undefined}
                  sx={undefined}
                />
              </StyledFormControl>
              <StyledFormControl data-testid="jobblockdrawervariety">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="variety"
                  name="variety"
                  label={t('Variety')}
                  inlineLabel
                  value={jobBlockRowData?.variety}
                  onChange={handleOnChange}
                  error={validationErrors.variety}
                  fullWidth
                  disabled={true}
                  onBlur={undefined}
                  classes={undefined}
                  rows={undefined}
                  sx={undefined}
                />
              </StyledFormControl>
              <StyledFormControl data-testid="jobblockdrawerblockRowUnits">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="blockRowUnits"
                  name="blockRowUnits"
                  label={t(unitsColumnText)}
                  inlineLabel
                  value={jobBlockRowData?.blockRowUnits}
                  onChange={handleOnChange}
                  error={validationErrors.blockRowUnits}
                  fullWidth
                  disabled={true}
                  onBlur={undefined}
                  classes={undefined}
                  rows={undefined}
                  sx={undefined}
                />
              </StyledFormControl>
              <StyledFormControl
                data-testid="jobblockdrawerselectedUnits"
                error={validationErrors?.selectedUnits}
              >
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="selectedUnits"
                  name="selectedUnits"
                  label={t('Selected ' + unitsColumnText)}
                  inlineLabel
                  value={jobBlockRowData?.selectedUnits}
                  onChange={handleOnChange}
                  error={validationErrors.selectedUnits}
                  fullWidth
                  disabled={false}
                  onBlur={undefined}
                  classes={undefined}
                  rows={undefined}
                  sx={undefined}
                />
                {validationErrors?.selectedUnits && (
                  <FormHelperText id="component-error-text">
                    {validationErrors?.selectedUnits}
                  </FormHelperText>
                )}
              </StyledFormControl>
              <StyledFormControl data-testid="jobblockdrawersnotes">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="notes"
                  name="notes"
                  label={t('Notes')}
                  inlineLabel
                  value={jobBlockRowData?.notes}
                  onChange={handleOnChange}
                  error={validationErrors.notes}
                  fullWidth
                  disabled={false}
                  onBlur={undefined}
                  classes={undefined}
                  multiline
                  rows={4}
                  sx={undefined}
                />
              </StyledFormControl>
            </Box>
            <StyledButtonControlDiv>
              <div>
                <VineaButton
                  color="success"
                  onClick={onSave}
                  variant="contained"
                  minWidth={150}
                >
                  {t('Save')}
                </VineaButton>
              </div>
              <StyledCloseDiv>
                <VineaButton
                  color="secondary"
                  onClick={handleOnDiscard}
                  variant="outlined"
                  minWidth={150}
                >
                  {t('Close')}
                </VineaButton>
              </StyledCloseDiv>
            </StyledButtonControlDiv>
          </ErrorBoundary>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={() => setDiscardDialogOpen(false)}
            handleSaveChanges={handleOnDiscardSaveChanges}
            handleDiscardChanges={handleOnDiscardConfirm}
          />
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
}
