/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Box,
  FormControl,
  Popover, // Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty, first, get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { VineaAutoComplete } from '../../components/ComboBox';
import { MultiSelectCheckbox } from '../../components/MultiSelectCheckbox/MultiSelectCheckbox';
import { VineaButton } from '../../components/VineaButton';
import { actionTypes, reducers } from '../../constants';
import { FilterTextDescription } from '../../components/DashboardComponents/FilterTextDescription';

const TextDescriptionContainer = styled(Box)(({ theme, anchorEl }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  cursor: 'pointer',
  textAlign: 'center',
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  ...(anchorEl && {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }),
}));

const ChartFilter = ({
  filters,
  chartTitle,
  anchorEl,
  handleOpen,
  handleClose,
  handleApplyChartFilter,
  handleUpdateChartFilter,
  dashboardPageID,
}) => {
  const { chartID } = !isEmpty(filters) ? filters[0] : {};

  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();

  const isFirstRender = React.useRef(true);
  const [filterValues, setFilterValues] = React.useState({});

  const { data: allChartFilters = {} } = useSelector(
    state => state.chartFilter,
  );
  const { [chartID]: chartFilters = {}, defaults = {} } = allChartFilters;

  const selectedValuesText = filters.map(
    ({ filterName, filterParameter, filterOptions }) => ({
      filterName,
      // map from value IDs to strings
      values: get(filterValues, [filterParameter, 'value'], []).map(
        id => filterOptions.find(o => o.id === id)?.value,
      ),
    }),
  );

  const handleChangeSingleSelect = e => {
    const {
      target: { value, name },
    } = e;

    setFilterValues({
      ...filterValues,
      [name]: { ...filterValues[name], value: [value] },
    });
  };

  const handleChangeMultiSelect = (value, name) => {
    const newValueHasAll = value.includes(-1);
    const currentValueHasAll = filterValues[name].value.includes(-1);

    const updatedValue =
      newValueHasAll && !currentValueHasAll
        ? [-1] // deselect everything that isn't <All>
        : newValueHasAll && value.length > 1
        ? value.filter(i => i !== -1) // deselect <All>
        : value;

    setFilterValues({
      ...filterValues,
      [name]: { ...filterValues[name], value: updatedValue },
    });
  };

  const handleApplyFilters = () => {
    setFilterValues(chartFilters);
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.chartFilter,
      payload: {
        ...allChartFilters,
        [chartID]: {
          ...filterValues,
        },
      },
    });
    handleUpdateChartFilter();

    handleClose();
  };

  const handleClearFilters = () => {
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.chartFilter,
      payload: {
        ...allChartFilters,
        [chartID]: {
          ...defaults[chartID],
        },
      },
    });

    handleClose();
  };

  React.useEffect(() => {
    setFilterValues(chartFilters);
  }, [chartFilters]);

  React.useEffect(() => {
    // prevent this from triggering on mount
    // we only want to call it when filters change
    if (!isFirstRender.current && !isEmpty(chartFilters)) {
      handleApplyChartFilter(chartID, dashboardPageID);
    }
    isFirstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartFilters]);

  return (
    <>
      {/* <Tooltip title={t('Chart Filters')} arrow> */}
      <TextDescriptionContainer anchorEl={anchorEl} onClick={handleOpen} p={1}>
        <FilterTextDescription
          selectedValuesText={selectedValuesText}
          showBlankValues
        />
      </TextDescriptionContainer>
      {/* </Tooltip> */}

      <Popover
        id="dashboardPopover"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Box m={3}>
          <Box marginBottom={4}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
              }}
            >
              {`${t('Filters for')} ${chartTitle}`}
            </Typography>
          </Box>
          {filters.map(
            ({
              filterOptions,
              filterName,
              filterParameter,
              isMultiSelect,
              filterID,
            }) => (
              <Box
                key={filterID}
                mt={1}
                sx={{
                  marginBottom: theme.spacing(1),
                  marginTop: theme.spacing(2),
                  minWidth: 250,
                  '& .MuiFormControl-root': {
                    minWidth: 300,
                  },
                }}
              >
                {isMultiSelect ? (
                  <FormControl
                    sx={{
                      width: 250,
                    }}
                    margin="none"
                  >
                    <MultiSelectCheckbox
                      onChange={val => {
                        return handleChangeMultiSelect(val, filterParameter);
                      }}
                      options={filterOptions}
                      name={filterParameter}
                      label={t(filterName)}
                      value={get(filterValues, [filterParameter, 'value'], [])}
                      inputProps={{
                        name: filterParameter,
                      }}
                    />
                  </FormControl>
                ) : (
                  <FormControl
                    sx={{
                      width: 250,
                    }}
                    margin="none"
                  >
                    <VineaAutoComplete
                      onChange={handleChangeSingleSelect}
                      options={filterOptions}
                      name={filterParameter}
                      label={t(filterName)}
                      value={first(
                        get(filterValues, [filterParameter, 'value'], []),
                      )}
                      inputProps={{
                        name: filterParameter,
                      }}
                    />
                  </FormControl>
                )}
              </Box>
            ),
          )}
          <Box
            mt={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: theme.spacing(2),
            }}
          >
            <VineaButton variant="text" color="secondary" onClick={handleClose}>
              {t('Cancel')}
            </VineaButton>

            <VineaButton
              variant="text"
              color="secondary"
              onClick={handleClearFilters}
            >
              {t('Clear')}
            </VineaButton>

            <VineaButton
              variant="contained"
              color="secondary"
              onClick={handleApplyFilters}
            >
              {t('Apply')}
            </VineaButton>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

ChartFilter.propTypes = {
  anchorEl: PropTypes.object,
  chartTitle: PropTypes.string.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      chartID: PropTypes.number,
      filterID: PropTypes.number,
      filterName: PropTypes.string,
      filterParameter: PropTypes.string,
      isMultiSelect: PropTypes.bool,
      filterOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          key: PropTypes.number,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  handleApplyChartFilter: PropTypes.func.isRequired,
  dashboardPageID: PropTypes.number.isRequired,
};

ChartFilter.defaultProps = { anchorEl: null };

export default ChartFilter;
