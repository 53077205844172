import React from 'react';
import { AzureMapsContext } from 'react-azure-maps';
import { layer, source, data, math, control } from 'azure-maps-control';
import { isEmpty, values } from 'lodash';
import Box from '@mui/material/Box';
import MapComponent from './MapComponent';
import palette from '../../theme/palette';
import { ColourLegend } from './ColourLegend';
import { newZealandBoundingBox } from './mapConstants';

const colours = {
  notStarted: { colour: 'white', label: 'Not started/no jobs' },
  inProgress: { colour: palette.palette.warning.main, label: 'In Progress' },
  completed: { colour: palette.palette.success.main, label: 'Completed' },
};

const symbolLayerOptions = {
  iconOptions: {
    // Hide the default image.
    image: 'none',
  },
  textOptions: {
    // Reference the label property of the polygon as the text field.
    color: '#ffffff',
    size: 12,
    textField: ['get', 'labelText'],
    font: ['SegoeUi-Regular'],
  },
};

const vineyardsDataSource = new source.DataSource();
const vineyardsLayer = new layer.LineLayer(vineyardsDataSource, null, {
  strokeColor: 'white',
  strokeWidth: 2,
});

const notStartedBlocksDataSource = new source.DataSource();
const notStartedBlocksLayer = new layer.PolygonLayer(
  notStartedBlocksDataSource,
  null,
  {
    fillColor: colours.notStarted.colour,
    fillOpacity: 0.3,
  },
);

const inProgressBlocksDataSource = new source.DataSource();
const inProgessBlocksLayer = new layer.PolygonLayer(
  inProgressBlocksDataSource,
  null,
  {
    fillColor: colours.inProgress.colour,
    fillOpacity: 0.6,
  },
);
const inProgressBlocksSymbolLayer = new layer.SymbolLayer(
  inProgressBlocksDataSource,
  null,
  symbolLayerOptions,
);

const completedBlocksDataSource = new source.DataSource();
const completedBlocksLayer = new layer.PolygonLayer(
  completedBlocksDataSource,
  null,
  {
    fillColor: colours.completed.colour,
    fillOpacity: 0.6,
  },
);
const completedBlocksSymbolLayer = new layer.SymbolLayer(
  completedBlocksDataSource,
  null,
  symbolLayerOptions,
);

const MapController = props => {
  const {
    vineyardsGeoJson,
    notStartedBlocksGeoJson,
    inProgressBlocksGeoJson,
    completedBlocksGeoJson,
  } = props;
  const { mapRef, isMapReady } = React.useContext(AzureMapsContext);

  React.useEffect(() => {
    if (isMapReady && mapRef) {
      mapRef.sources.add(vineyardsDataSource);
      mapRef.layers.add(vineyardsLayer);

      mapRef.sources.add(completedBlocksDataSource);
      mapRef.layers.add(completedBlocksLayer);
      mapRef.layers.add(completedBlocksSymbolLayer);

      mapRef.sources.add(inProgressBlocksDataSource);
      mapRef.layers.add(inProgessBlocksLayer);
      mapRef.layers.add(inProgressBlocksSymbolLayer);

      mapRef.sources.add(notStartedBlocksDataSource);
      mapRef.layers.add(notStartedBlocksLayer);

      mapRef.setStyle({ style: 'satellite' });

      // Controls for the map
      mapRef.events.add('ready', function mapControlsFunction() {
        mapRef.controls.add(
          [
            new control.ZoomControl(),
            new control.CompassControl(),
            new control.PitchControl(),
          ],
          {
            position: 'top-right',
          },
        );
      });
    }
    return () => {
      completedBlocksDataSource.clear();
      inProgressBlocksDataSource.clear();
      notStartedBlocksDataSource.clear();
      vineyardsDataSource.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapReady]);

  React.useEffect(() => {
    if (isMapReady) {
      completedBlocksDataSource.setShapes(completedBlocksGeoJson);
    }
  }, [completedBlocksGeoJson, isMapReady]);

  React.useEffect(() => {
    if (isMapReady) {
      inProgressBlocksDataSource.setShapes(inProgressBlocksGeoJson);
    }
  }, [inProgressBlocksGeoJson, isMapReady]);

  React.useEffect(() => {
    if (isMapReady) {
      notStartedBlocksDataSource.setShapes(notStartedBlocksGeoJson);
    }
  }, [notStartedBlocksGeoJson, isMapReady]);

  React.useEffect(() => {
    if (isMapReady) {
      vineyardsDataSource.setShapes(vineyardsGeoJson);
    }
  }, [vineyardsGeoJson, isMapReady]);

  React.useEffect(() => {
    if (isMapReady && mapRef) {
      if (!isEmpty(vineyardsGeoJson?.features)) {
        vineyardsDataSource.setShapes(vineyardsGeoJson);

        // we cannot get the BoundingBox directly from a FeatureCollection with no bbox
        // so we need to calculate a hull first
        const bounds = data.BoundingBox.fromData(
          math.getConvexHull(vineyardsGeoJson),
        );

        mapRef.setCamera({ bounds, padding: 100 });
      } else {
        // Zoom to all of New Zealand for now
        mapRef.setCamera({
          bounds: newZealandBoundingBox,
          padding: 100,
        });
      }
    }
  }, [vineyardsGeoJson, isMapReady, mapRef]);

  return (
    <>
      {vineyardsGeoJson &&
        completedBlocksGeoJson &&
        inProgressBlocksGeoJson &&
        notStartedBlocksGeoJson && (
          <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
            {!isEmpty(vineyardsGeoJson?.features) && (
              <ColourLegend colours={values(colours)} />
            )}
            <MapComponent />
          </Box>
        )}
    </>
  );
};

export default MapController;
