import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import { format, parseISO } from 'date-fns';
import { find, isNull } from 'lodash';
import {
  dateFormat,
  IdentityTypeIds,
  PlanStatusIDs,
  viewDateFormat,
} from '../../constants';
import { useIdentityContactMethodHook } from '../../hooks/useIdentityContactMethodHook';

const searchIdentityResults = state => state.searchIdentities;
const idOverview = state => state.identityOverviewDetails;
const personOverview = state => state.personOverviewDetails;
const lkpNationality = state => state.entities.lookupNationality.data;
const lkpEthnicity = state => state.entities.lookupEthnicity.data;
const lkpGender = state => state.entities.lookupGender.data;
const lkpLanguage = state => state.entities.lookupLanguage.data;
const lkpBusinessUnit = state => state.entities.lookupBusinessUnit.data;
const lkpSubRegion = state => state.entities.lookupGrowingSubRegion.data;
const lkpContactMethod = state => state.entities.lookupContactMethod.data;
const manageContactResults = state => state.identityContactMethods;
const lkpSiteType = state => state.entities.lookupSiteType.data;
const lkpPayrollSystem = state => state.entities.lookupPayrollSystem.data;
const lookupDataProvider = state => state.entities.lookupDataProvider.data;
const lookupDataProviderType = state =>
  state.entities.lookupDataProviderType.data;
const lookupRoles = state => state.entities.identityRole.data;
const lookupConsumable = state => state.entities.lookupConsumableType.data;
const lkpResourceTypeUnit = state => state.entities.lookupResourceTypeUnit.data;
const lkpResourceType = state => state.entities.lookupResourceType.data;
const lkpDurationUnit = state => state.entities.lookupDuration.data;
const lkpWorkUnit = state => state.entities.lookupWorkUnit.data;
const lkpCostingUnit = state => state.entities.lookupCostingUnit.data;
const lkpActivityGroup = state => state.entities.lookupActivityGroup.data;
const lkpPlanStatus = state => state.entities.lookupPlanStatus.data;
const lkpPlanType = state => state.entities.lookupPlanType.data;
/** Jobs Selectors */
const lkpJobType = state => state.entities.lookupJobType.data;
const lkpJobStatus = state => state.entities.lookupJobStatus.data;
const lkpJobQuantityType = state => state.entities.lookupJobQuantityType.data;
const lkpWorkRecordLevel = state => state.entities.lookupWorkRecordLevel.data;
const lkpJobTypeWorkRecordType = state =>
  state.entities.lookupJobTypeWorkRecordType.data;
const lkpJobWorkUnit = state => state.entities.lookupJobWorkUnit.data;
const lkpPayrollRecordType = state =>
  state.entities.lookupPayrollRecordType.data;
const lkpJobInvoiceMethod = state => state.entities.lookupJobInvoiceMethod.data;
const identities = state => state.entities.identity.data;
const lkpWorkRecordUnitType = state =>
  state.entities.lookupWorkRecordUnitType.data;
// Vehicle selectors
const lkpVehicleType = state => state.entities.lookupVehicleType.data;
const lkpVehicleMake = state => state.entities.lookupVehicleMake.data;
const lkpVintage = state => state.entities.lookupVintage.data;

export const getIsIdentityOverviewLoading = createSelector(
  [idOverview, personOverview],
  (overviewDetails, personDetails) => {
    const { isLoading: overviewLoading } = overviewDetails;
    const { isLoading: personLoading } = personDetails;
    return overviewLoading || personLoading;
  },
);

export const getIsIdentityOverviewLoaded = createSelector(
  [idOverview, personOverview],
  (overviewDetails, personDetails) => {
    const { isLoaded: overviewLoaded } = overviewDetails;
    const { isLoaded: personLoaded } = personDetails;
    return overviewLoaded || personLoaded;
  },
);

export const getIdentityOverviewDetails = createSelector(
  [
    idOverview,
    lkpNationality,
    lkpEthnicity,
    lkpGender,
    lkpLanguage,
    lkpBusinessUnit,
    lkpSubRegion,
    searchIdentityResults,
  ],
  (
    idOverviewDetails,
    nationalityMapData,
    ethnicityMapData,
    genderMapData,
    languageMapData,
    businessUnitMapData,
    subRegionMapData,
    searchResults,
  ) => {
    const { data } = idOverviewDetails;
    if (!data) return {};
    if (isEmpty(data)) return {};
    const {
      id,
      displayName,
      firstName,
      lastName,
      birthDate,
      ethnicityID,
      nationalityID,
      genderID,
      preferredLanguageID: languageID = 0,
      knownAs,
      identityTypeID,
      vineyardName,
      vineyardKnownAs,
      plantedArea,
      totalVineyardArea,
      businessUnitID,
      subRegionID,
      contractName,
    } = data;
    const { data: searchData } = searchResults;
    let phone = '';
    let email = '';
    let address = '';
    const selectedSearchData = find(searchData, f => f.id === id);
    if (!isEmpty(selectedSearchData)) {
      phone = selectedSearchData.phone || '';
      email = selectedSearchData.email || '';
      address = selectedSearchData.address || '';
    }
    const dateExists = isEmpty(birthDate);
    const formattedBirthDate = dateExists
      ? ''
      : format(parseISO(birthDate), viewDateFormat);

    const mapLookupData = (arrayData, arrayId, arrayKey) => {
      if (!isArray(arrayData)) return null;
      if (isEmpty(arrayData)) return null;
      if (!arrayData) return null;
      const val = get(
        arrayData.find(f => f.id === arrayId),
        `${arrayKey}`,
        '',
      );
      return val;
    };

    const isPersonIdentity = identityTypeID === IdentityTypeIds.PERSON;
    let ethinicityValue = '';
    let nationalityValue;
    let genderValue;
    let languageValue;
    if (isPersonIdentity) {
      ethinicityValue = mapLookupData(
        ethnicityMapData,
        ethnicityID,
        'ethnicity',
      );
      nationalityValue = mapLookupData(
        nationalityMapData,
        nationalityID,
        'nationality',
      );
      genderValue = mapLookupData(genderMapData, genderID, 'genderDescription');
      languageValue = mapLookupData(languageMapData, languageID, 'language');
    }
    const businessUnitMapValue = mapLookupData(
      businessUnitMapData,
      businessUnitID,
      'businessUnit',
    );
    const subRegionMapValue = mapLookupData(
      subRegionMapData,
      subRegionID,
      'growingSubRegion',
    );

    let displayNameValue = '';
    if (identityTypeID === IdentityTypeIds.PERSON) {
      displayNameValue = `${firstName} ${lastName}`;
    } else if (identityTypeID === IdentityTypeIds.VINEYARD) {
      displayNameValue = vineyardName;
    } else if (identityTypeID === IdentityTypeIds.SUPPLY_CONTRACT) {
      displayNameValue = contractName;
    } else {
      displayNameValue = displayName;
    }

    return {
      id,
      identityTypeID,
      isPersonIdentity,
      email,
      phone,
      displayName: displayNameValue,
      knownAs: knownAs || vineyardKnownAs,
      plantedArea: plantedArea ? `${plantedArea} Ha` : '',
      totalArea: totalVineyardArea ? `${totalVineyardArea} Ha` : '',
      birthDate: formattedBirthDate,
      ethnicity: ethinicityValue,
      nationality: nationalityValue,
      gender: genderValue,
      language: languageValue,
      subregion: subRegionMapValue,
      address,
      roles: '',
      businessUnit: businessUnitMapValue,
    };
  },
);

export const getIdentityOverviewDetailsFromContact = createSelector(
  [
    idOverview,
    lkpNationality,
    lkpEthnicity,
    lkpGender,
    lkpLanguage,
    lkpBusinessUnit,
    lkpSubRegion,
    lkpSiteType,
    lkpContactMethod,
    manageContactResults,
    lkpPayrollSystem,
    lookupDataProvider,
    lookupDataProviderType,
    lookupRoles,
    lookupConsumable,
    lkpResourceType,
    lkpResourceTypeUnit,
    lkpDurationUnit,
    lkpWorkUnit,
    lkpCostingUnit,
    lkpActivityGroup,
    lkpPlanStatus,
    lkpPlanType,
    identities,
    lkpJobType,
    lkpJobStatus,
    lkpJobQuantityType,
    lkpWorkRecordLevel,
    lkpJobTypeWorkRecordType,
    lkpJobWorkUnit,
    lkpPayrollRecordType,
    lkpJobInvoiceMethod,
    lkpWorkRecordUnitType,
    lkpVehicleType,
    lkpVehicleMake,
    lkpVintage,
  ],
  (
    idOverviewDetails,
    nationalityMapData,
    ethnicityMapData,
    genderMapData,
    languageMapData,
    businessUnitMapData,
    subRegionMapData,
    siteTypeMapData,
    contactMethodLookUp = [],
    manageContactResultList,
    payrollData,
    lkpDataProvider,
    lkpDataProviderType,
    lkpRoles = [],
    lkpConsumableType,
    lkpActivityResourceType,
    lookupActivityResourceTypeUnit,
    lkpActivityDuration,
    lkpActivityWorkUnit,
    lkpActivityCostingUnit,
    lkpActivityGroupType,
    lkpPlanStatusValues,
    lkpPlanTypeValues,
    identityValues,
    flkpJobType,
    flkpJobStatus,
    flkpJobQuantityType,
    flkpWorkRecordLevel,
    flkpJobTypeWorkRecordType,
    flkpJobWorkUnit,
    flkpPayrollRecordType,
    flkpJobInvoiceMethod,
    flkpWorkRecordUnitType,
    flkpVehicleType,
    flkpVehicleMake,
    flkpVintage,
  ) => {
    const { data: overviewData } = idOverviewDetails;
    const data = Array.isArray(overviewData) ? overviewData[0] : overviewData;
    const { data: dataContact } = manageContactResultList;

    if (!data) return {};
    if (isEmpty(data)) return {};

    const { getContactMethod } = useIdentityContactMethodHook();
    const {
      id,
      displayName,
      firstName,
      lastName,
      birthDate,
      ethnicityID,
      nationalityID,
      genderID,
      preferredLanguageID: primaryLanguageID = 0,
      secondaryLanguageID,
      knownAs,
      identityTypeID,
      organisationName,
      organisationKnownAs,
      vineyardName,
      vineyardKnownAs,
      plantedArea,
      totalVineyardArea,
      businessUnitID,
      subRegionID,
      serviceProviderName,
      serviceProviderKnownAs,
      isPrimary,
      payrollSystemID,
      dataProviderID,
      lastUpdatedDate,
      preferredContactMethodTypeID,
      consumableName,
      consumableTypeID,
      accommodationName,
      capacity,
      username,
      apiKey,
      apI_URL: apiURL,
      activityCode,
      activityGroupID,
      costingUnitID,
      velocityDurationID,
      workUnitID,
      resourceUnitID,
      colourHexCode,
      planName,
      planTypeID,
      planStatusID,
      planStartDate,
      planEndDate,
      planVintageID,
      contractName,
      primaryContactIdentityID,
      supplyingPartyIdentityID,
      dateSigned,
      dateEnded,
      primaryContactEmail,
      primaryContactPhone,
      primaryContactAddress,
      currentContractArea,
      subRegions,
      contractedVineyards,
      jobName,
      jobTypeID,
      jobStatusID,
      invoiceMethodID,
      quantityTypeID,
      workRecordLevelID,
      workRecordUnitTypeID,
      invoiceToIdentityID,
      latestEvent,
      poNumber,
      scheduledStartDate,
      vehicleName,
      vehicleMakeID,
      vehicleModel,
      vehicleTypeID,
      registrationNo,
      fleetNumber,
      vehicleCapacity,
      tonnageWeight,
      tareWeight,
      vin,
      gpS_ID,
      hubOdometerNo,
      dateOfPurchase,
      isPlannable,
      jobDescription,
      activityDescription,
      connectionDescription,
      consumableDescription,
      siteTypeID,
    } = data;
    let phone = '';
    let email = '';
    let address = '';
    let preferredContact = '';
    let roles = [];
    if (!isEmpty(dataContact) && !isEmpty(contactMethodLookUp)) {
      // get the Phone number
      const phoneContactMethodListIds = contactMethodLookUp
        .filter(row => row.isPhone === true)
        .map(value => value.id);

      // get the email
      const emailContactMethodListIds = contactMethodLookUp
        .filter(row => row.isEmail === true)
        .map(value => value.id);

      // get the address details
      const addressContactMethodListIds = contactMethodLookUp
        .filter(row => row.isAddress === true)
        .map(value => value.id);

      phone = getContactMethod(dataContact, phoneContactMethodListIds);
      email = getContactMethod(dataContact, emailContactMethodListIds);
      address = getContactMethod(dataContact, addressContactMethodListIds);
    }

    const description =
      jobDescription ||
      activityDescription ||
      connectionDescription ||
      consumableDescription;

    if (
      !isEmpty(lkpRoles) &&
      Array.isArray(lkpRoles) &&
      [
        IdentityTypeIds.PERSON,
        IdentityTypeIds.SERVICE_PROVIDER,
        IdentityTypeIds.VINEYARD,
        IdentityTypeIds.ORGANISATION,
      ].includes(identityTypeID)
    ) {
      roles = lkpRoles
        .filter(
          value =>
            value.effectiveToDate === null ||
            format(parseISO(value.effectiveToDate), dateFormat) >=
              format(new Date(), dateFormat),
        )
        .map(f => f.roleType);
    }
    const dateExists = isEmpty(birthDate);
    const formattedBirthDate = dateExists
      ? ''
      : format(parseISO(birthDate), viewDateFormat);

    const planStartDateExists = isEmpty(planStartDate);
    const formattedPlanStartDate = planStartDateExists
      ? ''
      : format(parseISO(planStartDate), viewDateFormat);

    const planEndDateExists = isEmpty(planEndDate);
    const formattedPlanEndDate = planEndDateExists
      ? ''
      : format(parseISO(planEndDate), viewDateFormat);

    const formattedScheduledStartDate = scheduledStartDate
      ? format(parseISO(scheduledStartDate), viewDateFormat)
      : '';

    const mapLookupData = (arrayData, arrayId, arrayKey) => {
      if (!isArray(arrayData)) return null;
      if (isEmpty(arrayData)) return null;
      if (!arrayData) return null;
      const val = get(
        arrayData.find(f => f.id === arrayId),
        `${arrayKey}`,
        '',
      );
      return val;
    };

    const isPersonIdentity = identityTypeID === IdentityTypeIds.PERSON;
    let ethinicityValue = '';
    let nationalityValue;
    let genderValue;
    let languageValue;
    let secondaryLanguageValue;
    if (identityTypeID === IdentityTypeIds.PERSON) {
      ethinicityValue = mapLookupData(
        ethnicityMapData,
        ethnicityID,
        'ethnicity',
      );
      nationalityValue = mapLookupData(
        nationalityMapData,
        nationalityID,
        'nationality',
      );
      genderValue = mapLookupData(genderMapData, genderID, 'genderDescription');
      if (primaryLanguageID) {
        languageValue = mapLookupData(
          languageMapData,
          primaryLanguageID,
          'language',
        );
      }
      if (secondaryLanguageID) {
        secondaryLanguageValue = mapLookupData(
          languageMapData,
          secondaryLanguageID,
          'language',
        );
      }
      if (!isEmpty(languageValue) && !isEmpty(secondaryLanguageValue)) {
        languageValue += `, ${secondaryLanguageValue}`;
      } else if (!isEmpty(secondaryLanguageValue)) {
        languageValue = secondaryLanguageValue;
      }
    }
    const businessUnitMapValue = mapLookupData(
      businessUnitMapData,
      businessUnitID,
      'businessUnit',
    );
    const subRegionMapValue = mapLookupData(
      subRegionMapData,
      subRegionID,
      'growingSubRegion',
    );

    const siteTypeMapValue = mapLookupData(
      siteTypeMapData,
      siteTypeID,
      'siteType',
    );

    const payrollSystemData = mapLookupData(
      payrollData,
      payrollSystemID,
      'payrollSystem',
    );

    const consumableTypeMapValue = mapLookupData(
      lkpConsumableType,
      consumableTypeID,
      'consumableType',
    );

    const activityResourceUnitMapValue = mapLookupData(
      lookupActivityResourceTypeUnit,
      resourceUnitID,
      'resourceUnit',
    );

    const resourceType = mapLookupData(
      lookupActivityResourceTypeUnit,
      resourceUnitID,
      'resourceTypeID',
    );

    const activityResourceTypeMapValue = mapLookupData(
      lkpActivityResourceType,
      resourceType,
      'resourceType',
    );

    const activityDurationUnitMapValue = mapLookupData(
      lkpActivityDuration,
      velocityDurationID,
      'duration',
    );

    const activityGroupMapValue = mapLookupData(
      lkpActivityGroupType,
      activityGroupID,
      'activityGroup',
    );

    const activityCostingUnitMapValue = mapLookupData(
      lkpActivityCostingUnit,
      costingUnitID,
      'costingUnit',
    );

    const activityWorkUnitMapValue = mapLookupData(
      lkpActivityWorkUnit,
      workUnitID,
      'workUnitPlural',
    );

    const activityWorkUnitSingleMapValue = mapLookupData(
      lkpActivityWorkUnit,
      workUnitID,
      'workUnit',
    );

    const planStatusMapValue = mapLookupData(
      lkpPlanStatusValues,
      planStatusID,
      'planStatus',
    );

    const planActiveStatus =
      planStatusID !== PlanStatusIDs.INACTIVE ? true : false;

    const planTypeMapValue = mapLookupData(
      lkpPlanTypeValues,
      planTypeID,
      'planType',
    );

    const planVintageMapValue = mapLookupData(
      flkpVintage,
      planVintageID,
      'vintage',
    );

    const primaryContactIdentityValue = mapLookupData(
      identityValues,
      primaryContactIdentityID,
      'displayName',
    );

    const jobTypeMapValue = mapLookupData(flkpJobType, jobTypeID, 'jobType');

    const jobStatusMapValue = mapLookupData(
      flkpJobStatus,
      jobStatusID,
      'jobStatus',
    );

    const jobInvoiceMethodMapValue = mapLookupData(
      flkpJobInvoiceMethod,
      invoiceMethodID,
      'invoiceMethod',
    );

    const jobInvoiceToIdentity = mapLookupData(
      identityValues,
      invoiceToIdentityID,
      'displayName',
    );

    const jobQuantityTypeMapValue = mapLookupData(
      flkpJobQuantityType,
      quantityTypeID,
      'description',
    );

    const jobWorkRecordLevelMapValue = mapLookupData(
      flkpWorkRecordLevel,
      workRecordLevelID,
      'description',
    );

    const jobWorkRecordUnitTypeMapValue = mapLookupData(
      flkpWorkRecordUnitType,
      workRecordUnitTypeID,
      'workRecordUnitType',
    );

    const vehicleMakeMapValue = mapLookupData(
      flkpVehicleMake,
      vehicleMakeID,
      'vehicleMake',
    );

    const vehicleTypeMapValue = mapLookupData(
      flkpVehicleType,
      vehicleTypeID,
      'vehicleType',
    );

    const dateOfPurchaseExists = isEmpty(dateOfPurchase);
    const formattedDateOfPurchase = dateOfPurchaseExists
      ? ''
      : format(parseISO(dateOfPurchase), viewDateFormat);

    const dateSignedExists = isEmpty(dateSigned);
    const formattedDateSigned = dateSignedExists
      ? ''
      : format(parseISO(dateSigned), viewDateFormat);

    const dateEndedExists = isEmpty(dateEnded);
    const formattedDateEnded = dateEndedExists
      ? ''
      : format(parseISO(dateEnded), viewDateFormat);

    const nameProp = prop => {
      if (prop === IdentityTypeIds.PERSON) return `${firstName} ${lastName}`;
      if (prop === IdentityTypeIds.VINEYARD) return vineyardName;
      if (prop === IdentityTypeIds.SERVICE_PROVIDER) return serviceProviderName;
      if (prop === IdentityTypeIds.DATA_CONNECTION)
        return connectionDescription;
      if (prop === IdentityTypeIds.ORGANISATION) return organisationName;
      if (prop === IdentityTypeIds.CONSUMABLE) return consumableName;
      if (prop === IdentityTypeIds.ACTIVITY) return activityCode;
      if (prop === IdentityTypeIds.ACCOMMODATION) return accommodationName;
      if (prop === IdentityTypeIds.SUPPLY_CONTRACT) return contractName;
      if (prop === IdentityTypeIds.JOB) return jobName;
      return displayName;
    };

    const knownAsProp = prop => {
      if (prop === IdentityTypeIds.VINEYARD) return vineyardKnownAs;
      if (prop === IdentityTypeIds.SERVICE_PROVIDER)
        return serviceProviderKnownAs;
      if (prop === IdentityTypeIds.ORGANISATION) return organisationKnownAs;
      return knownAs;
    };

    const preferredContactType = isArray(contactMethodLookUp)
      ? contactMethodLookUp?.find(
          st => st.id === preferredContactMethodTypeID,
        ) || null
      : null;

    if (!isNull(preferredContactType)) {
      const { isAddress, isPhone, isEmail } = preferredContactType;
      if (isAddress) preferredContact = address;
      else if (isPhone) preferredContact = phone;
      else if (isEmail) preferredContact = email;
    }

    let dataProviderName = '';
    let dataProviderTypeName = '';
    let dataProviderTypeID;
    const dataProviderList = lkpDataProvider;
    const dataProviderTypeList = lkpDataProviderType;
    if (identityTypeID === IdentityTypeIds.DATA_CONNECTION) {
      const currentDataProvider = find(
        dataProviderList,
        f => f.id === dataProviderID,
      );
      dataProviderName = currentDataProvider?.dataProviderName;
      dataProviderTypeID = currentDataProvider?.dataProviderTypeID;
      dataProviderTypeName =
        find(
          dataProviderTypeList,
          f => f.id === currentDataProvider?.dataProviderTypeID,
        )?.providerType || '';
    }

    const identityName =
      nameProp(identityTypeID) || planName || vehicleName || jobName || knownAs;

    return {
      id,
      identityTypeID,
      identityName,
      isPersonIdentity,
      email,
      phone,
      displayName: nameProp(identityTypeID),
      knownAs: knownAsProp(identityTypeID),
      connectionDescription,
      apiKey,
      apiURL,
      plantedArea: plantedArea ? `${plantedArea} Ha` : '',
      totalArea: totalVineyardArea ? `${totalVineyardArea} Ha` : '',
      birthDate: formattedBirthDate,
      ethnicity: ethinicityValue,
      nationality: nationalityValue,
      gender: genderValue,
      language: languageValue,
      subregion: subRegionMapValue,
      siteType: siteTypeMapValue,
      address,
      roles,
      businessUnit: businessUnitMapValue,
      businessUnitID,
      isPrimary,
      payrollSystem: payrollSystemData,
      connectionType: dataProviderTypeName,
      dataProviderID,
      dataProviderTypeID,
      provider: dataProviderName,
      lastUpdatedDate,
      preferredContact,
      consumableDescription,
      consumableType: consumableTypeMapValue,
      capacity,
      activityDescription,
      activityResourceType: activityResourceTypeMapValue,
      activityResourceUnit: activityResourceUnitMapValue,
      activityDuration: activityDurationUnitMapValue,
      activityGroup: activityGroupMapValue,
      activityCostingUnit: activityCostingUnitMapValue,
      activityWorkUnit: activityWorkUnitMapValue,
      activityWorkUnitSingle: activityWorkUnitSingleMapValue,
      activityColor: colourHexCode,
      planName,
      planStatus: planStatusMapValue,
      planActiveStatus,
      planType: planTypeMapValue,
      planStartDate: formattedPlanStartDate,
      planEndDate: formattedPlanEndDate,
      planVintage: planVintageMapValue,
      supplyContractPrimaryContact: primaryContactIdentityValue,
      supplyContractDateSigned: formattedDateSigned,
      supplyContractDateEnded: formattedDateEnded,
      supplyContractEmail: primaryContactEmail,
      supplyContractPhone: primaryContactPhone,
      supplyContractAddress: primaryContactAddress,
      supplyContractCurrentContractedArea: currentContractArea,
      supplyContractSubRegions: subRegions,
      supplyContractContractedVineyards: contractedVineyards,
      supplyingPartyIdentityID,
      jobName,
      jobDescription,
      jobTypeID,
      jobStatusID,
      invoiceMethodID,
      quantityTypeID,
      workRecordLevelID,
      workRecordUnitTypeID,
      invoiceToIdentityID,
      poNumber,
      jobTypeMapValue,
      jobQuantityTypeMapValue,
      jobWorkRecordLevelMapValue,
      jobWorkRecordUnitTypeMapValue,
      jobInvoiceMethodMapValue,
      jobInvoiceToIdentity,
      jobLatestEvent: latestEvent,
      jobStatusMapValue,
      scheduledStartDate: formattedScheduledStartDate,
      vehicleName,
      vehicleMakeMapValue,
      vehicleModel,
      vehicleTypeMapValue,
      registrationNo,
      fleetNumber,
      vehicleCapacity,
      tonnageWeight,
      tareWeight,
      vin,
      gpS_ID,
      hubOdometerNo,
      formattedDateOfPurchase,
      isPlannable,
      description,
      siteTypeID,
    };
  },
);
