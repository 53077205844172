import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import Radio from '@mui/material/Radio';

const GreenRadio = styled(Radio)(() => ({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
}));

export const Radios = () => {
  const [selectedValue, setSelectedValue] = React.useState('a');
  const theme = useTheme();

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  return (
    <Grid
      item
      xs={12}
      sm={8}
      sx={{
        flexGrow: 1,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
      }}
    >
      <Typography component="p" variant="h3">
        Radios
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            '& > button': {
              margin: theme.spacing(1),
            },
          }}
        >
          <Radio
            checked={selectedValue === 'a'}
            onChange={handleChange}
            value="a"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
          />
          <Radio
            checked={selectedValue === 'b'}
            onChange={handleChange}
            value="b"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'B' }}
          />
          <GreenRadio
            checked={selectedValue === 'c'}
            onChange={handleChange}
            value="c"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'C' }}
          />
          <Radio
            checked={selectedValue === 'd'}
            onChange={handleChange}
            value="d"
            color="default"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'D' }}
          />
          <Radio
            checked={selectedValue === 'e'}
            onChange={handleChange}
            value="e"
            color="default"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'E' }}
            size="small"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
