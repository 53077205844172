import React, { useState } from 'react';

const useImageUtils = image => {
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [imageWithoutDataUrl, setImageWithoutDataUrl] = useState(null);

  React.useEffect(() => {
    if (image && image.startsWith('data')) {
      setImageDataUrl(image);

      const withoutImageDataUrl = image
        .replace('data:', '')
        .replace(/^.+,/, '');
      setImageWithoutDataUrl(withoutImageDataUrl);
    }

    if (image && !image.startsWith('data') && image.length > 10) {
      setImageWithoutDataUrl(image);
      const BASE64_MAKER = 'data:image/jpeg;base64,';
      const dataUrl = BASE64_MAKER + image;
      setImageDataUrl(dataUrl);
    }

    if (!image) {
      setImageDataUrl(null);
      setImageWithoutDataUrl(null);
    }
  }, [image]);

  return {
    imageDataUrl,
    imageWithoutDataUrl,
  };
};

export default useImageUtils;
