/* eslint-disable react/require-default-props */
/* **
 * Material UI  Chip component used in Pills.
 * https://material-ui.com/components/chips/#chip
 * All the props are passed down to chip component
 */
import React from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

type StyledBoxProps = {
  variantClass: boolean;
};

const StyledBox = styled(Box)<StyledBoxProps>(({ variantClass }) => ({
  ...(variantClass && {
    '& .MuiChip-root': {
      borderRadius: 4,
    },
  }),
  ...(!variantClass && {
    '& .MuiChip-root': {
      borderRadius: 16,
    },
  }),
  success: {},
}));

type BoxProps = {
  ml: string;
};

type PillProps = ChipProps & {
  shape: 'circle' | 'box';
  boxProps: BoxProps;
  pillClasses: string | Function;
};

const Pill = ({ shape, boxProps, pillClasses, color, ...props }: PillProps) => {
  const variantClass = shape === 'box';

  return (
    <StyledBox variantClass={variantClass} {...boxProps}>
      <Chip color={color} {...props} />
    </StyledBox>
  );
};

export default Pill;
