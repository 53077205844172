import { createTheme } from '@mui/material/styles';
import palette from './palette';
import './fonts.css';

const vineaNovaTheme = createTheme({
  ...palette,
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.40) ',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.only('xs')]: {
            maxWidth: 540,
          },
          [theme.breakpoints.only('sm')]: {
            maxWidth: 720,
          },
          [theme.breakpoints.only('md')]: {
            maxWidth: 960,
          },
          [theme.breakpoints.only('lg')]: {
            maxWidth: 1140,
          },
        }),
      },
      maxWidthLg: ({ theme }) => ({
        [theme.breakpoints.up('lg')]: {
          maxWidth: 1140,
        },
      }),
    },

    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          minWidth: '0',
          wordWrap: 'break-word',
          backgroundColor: '#fff',
          backgroundClip: 'border-box',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          flex: '1 1 auto',
          padding: '1.25rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: '0.5rem',
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.snow,
          color: theme.palette.primary.main,
        }),
        selected: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main,
        }),
      },
      page: {
        styleOverrides: {
          '&.Mui-selected': ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main,
            border: `1px solid ${theme.palette.primary.main}`,
          }),
          '&.Mui-selected:hover': ({ theme }) => ({
            color: theme.palette.secondary.main,
          }),
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: 36,
          [theme.breakpoints.up('xs')]: {
            minHeight: 36,
          },
          [theme.breakpoints.up('md')]: {
            minHeight: 42,
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& a:hover': {
            color: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        markLabelActive: ({ theme }) => ({
          color: theme.palette.secondary.main,
        }),
      },
    },
  },
});

// eslint-disable-next-line no-console
// console.log('theme', theme);
export default vineaNovaTheme;
