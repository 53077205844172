import { Pagination, PaginationItem, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import {
  DataGridPro,
  GridFooter,
  GridFooterContainer,
} from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
export interface CheckboxSelectionGridProps {
  gridXData: any;
  handleOnSelectionModelChange: (newSelectionModel: any) => void;
  selectionModel: any;
  rowUnit?: string;
  isLoading?: boolean;
}

export default function CheckboxSelectionGrid({
  gridXData,
  handleOnSelectionModelChange,
  selectionModel = [],
  rowUnit = 'row',
  isLoading = false,
}: CheckboxSelectionGridProps) {
  const footerText =
    selectionModel.length === 1
      ? `${selectionModel.length} ${rowUnit} selected`
      : `${selectionModel.length} ${rowUnit}s selected`;
  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Typography variant="body2" sx={{ ml: 2 }}>
          {footerText}
        </Typography>
        <GridFooter
          sx={{
            border: 'none',
          }}
        />
      </GridFooterContainer>
    );
  };

  return (
    <Paper
      elevation={0}
      data-testid="vineyard-blocksonly"
      sx={{ height: '100%' }}
    >
      {/* @ts-ignore */}
      <DataGridPro
        {...gridXData}
        rows={isLoading ? [] : gridXData.rows}
        rowHeight={30}
        headerHeight={56}
        advancedSearch={false}
        checkboxSelection
        // @ts-ignore
        onSelectionModelChange={(newSelectionModel: any) => {
          handleOnSelectionModelChange(newSelectionModel);
        }}
        selectionModel={selectionModel}
        hideFooterSelectedRowCount={true}
        hideFooterRowCount={true}
        components={{
          Footer: CustomFooter,
        }}
        loading={isLoading}
      />
    </Paper>
  );
}
