import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy, map, filter, isNil, uniqBy, round } from 'lodash';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import {
  VineaNovaSelectors,
  VineaNovaActions,
} from 'vineanova-redux-artifacts';

import { FieldLabelInput } from '../../components/TextField';
import { VineaButton } from '../../components/VineaButton';
import { DiscardDialog } from '../../components/Dialog';
import { Datepicker } from '../../components/Datepicker';
import { VineaAutoComplete } from '../../components/ComboBox';
import { formatDate } from '../../constants/formatter';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { viewDateFormat, IdentityRelationshipTypes, IdentityDrawerTypes } from '../../constants';

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

const StyledCloseDiv = styled('div')(() => ({
  paddingLeft: 10,
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
  display: 'flex',
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  minWidth: 150,
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

export function EditSupplyContractBlockDrawer({
  isOpen,
  handleOnClose,
  handleOnSave,
  handleOnChange,
  handleOnDelete,
  supplyContractBlockData = {},
  supplyingPartyIdentityID,
  validationErrors = {},
}) {
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);

  // Block options
  const [blockOptions, setBlockOptions] = React.useState([]);
  const {
    isLoaded: blocksLoaded,
    isLoading: blocksLoading,
    data: blockData,
  } = useSelector(state => state.entities.identityVineyardBlocks);

  useEffect(() => {
    validationErrors = {};
  }, [isOpen]);

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const onSave = () => {
    handleOnSave();
  };

  const handleDelete = () => {
    handleOnDelete();
  };

  const handleOnDiscard = () => {
    handleOnClose(IdentityDrawerTypes.SUPPLY_CONTRACT_BLOCK_EDIT);
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose(IdentityDrawerTypes.SUPPLY_CONTRACT_BLOCK_EDIT);
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  // set relatedVineyards
  useEffect(() => {
    dispatchAPI(VineaNovaActions.api.v1.identity.get.request({}));
    dispatchAPI(
      VineaNovaActions.api.v1.identityRelationship.get.request({
        queryParams: {
          IdentityID: supplyingPartyIdentityID,
        },
      }),
    );
  }, []);

  // Call blocks api when vineyard is selected
  useEffect(() => {
    if (
      !isNil(supplyContractBlockData?.vineyardID) &&
      supplyContractBlockData?.vineyardID !== 0
    ) {
      dispatchAPI(
        VineaNovaActions.api.v1.identityVineyardBlocks.get.request({
          queryParams: {
            IdentityID: supplyContractBlockData.vineyardID,
          },
        }),
      );
    }
  }, [supplyContractBlockData.vineyardID]);

  // When api is loaded, set block options
  useEffect(() => {
    if (blocksLoaded && !blocksLoading) {
      const blocks = map(blockData, b => {
        return { id: b.id, key: b.id, value: b.blockFullName };
      });
      setBlockOptions(blocks);
    }
  }, [blocksLoaded, blocksLoading, blockData]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={handleOnClickAway}
      >
        <Box sx={{ width: 380 }} role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography sx={{ padding: 2 }} variant="body1">
                {t('Edit Contracted Block')}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOnDiscard}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />
            <Box my={2}>
              <StyledFormControl>
                <VineaAutoComplete
                  value={supplyContractBlockData.vineyardBlockID || 0}
                  disabled
                  onChange={handleOnChange}
                  options={blockOptions}
                  labelVariant="body1"
                  inlineLabel={false}
                  id="vineyardBlockID"
                  name={t('vineyardBlockID')}
                  label={t('Block')}
                  inputProps={{
                    name: 'vineyardBlockID',
                  }}
                />
              </StyledFormControl>
              <StyledFormControl data-testid="supplycontractblockarea-styledformcontrol">
                <StyledFieldLabelInput
                  autoComplete="off"
                  disabled
                  id="contractedArea"
                  name="contractedArea"
                  label={t('Area')}
                  inlineLabel
                  value={supplyContractBlockData?.contractedArea}
                  onChange={handleOnChange}
                  error={validationErrors.contractedArea}
                  fullWidth
                />
              </StyledFormControl>
              <StyledFormControl data-testid="supplycontractblocktha-styledformcontrol">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="contractedWeightPerAreaUnit"
                  name="contractedWeightPerAreaUnit"
                  label={t('Tonnes/Hectare')}
                  inlineLabel
                  value={supplyContractBlockData?.contractedWeightPerAreaUnit}
                  onChange={handleOnChange}
                  error={validationErrors.contractedWeightPerAreaUnit}
                  fullWidth
                />
              </StyledFormControl>
              <StyledFormControl>
                <StyledFieldLabelInput
                  autoComplete="off"
                  disabled
                  id="contractedTonnes"
                  name="contractedTonnes"
                  label={t('Contracted Tonnes')}
                  inlineLabel
                  value={round(
                    Number(supplyContractBlockData.contractedArea) *
                      Number(
                        supplyContractBlockData.contractedWeightPerAreaUnit,
                      ),
                    2,
                  )}
                  onChange={handleOnChange}
                  fullWidth
                />
              </StyledFormControl>
              <StyledFormControl data-testid="datepicker-formcontrol">
                <Datepicker
                  id="fromDate"
                  disabled
                  label={t('Date From')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  name="fromDate"
                  value={supplyContractBlockData?.fromDate || null}
                  onChange={handleOnChange}
                  inputProps={{
                    'data-id': 'fromDate',
                    'data-name': 'fromDate',
                  }}
                  fullWidth
                />
              </StyledFormControl>
              <StyledFormControl
                data-testid="datepicker-formcontrol"
                error={validationErrors.toDate}
              >
                <Datepicker
                  id="toDate"
                  label={t('Date To')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  name="toDate"
                  value={supplyContractBlockData?.toDate}
                  onChange={handleOnChange}
                  inputProps={{
                    'data-id': 'toDate',
                    'data-name': 'toDate',
                  }}
                  fullWidth
                />
                {validationErrors.toDate && (
                  <FormHelperText id="component-error-text">
                    {validationErrors.toDate}
                  </FormHelperText>
                )}
              </StyledFormControl>
            </Box>
            <StyledButtonControlDiv>
              <div>
                <VineaButton
                  color="success"
                  onClick={onSave}
                  variant="contained"
                  minWidth={150}
                >
                  {t('Save')}
                </VineaButton>
              </div>
              <StyledCloseDiv>
                <VineaButton
                  color="secondary"
                  onClick={handleOnDiscard}
                  variant="outlined"
                  minWidth={150}
                >
                  {t('Close')}
                </VineaButton>
              </StyledCloseDiv>
              <StyledCloseDiv>
                <VineaButton
                  color="error"
                  onClick={handleDelete}
                  variant="text"
                  minWidth={150}
                >
                  {t('Delete')}
                </VineaButton>
              </StyledCloseDiv>
            </StyledButtonControlDiv>
          </ErrorBoundary>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={() => setDiscardDialogOpen(false)}
            handleSaveChanges={handleOnDiscardSaveChanges}
            handleDiscardChanges={handleOnDiscardConfirm}
          />
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
}
