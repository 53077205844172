import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
// import orderBy from 'lodash/orderBy';
import { format, isValid } from 'date-fns';
import { getLkpNoteStatus } from './lookupSelectors';
import { viewDateFormat } from '../../constants';

const getIdentityNotes = state => state.identityNotes;

export const getIdentityNotesSelector = createSelector(
  [getIdentityNotes, getLkpNoteStatus],
  (notes, lkpNoteStatus) => {
    const { data } = notes;
    if (isEmpty(notes)) return [];
    if (!Array.isArray(data) || isNull(data)) return [];
    // massaging the data for showing it on the grid
    let gridFormattedTableData = [];
    if (data.length > 0) {
      gridFormattedTableData = data.map(f => {
        const noteStatus =
          !isEmpty(lkpNoteStatus) &&
          get(
            lkpNoteStatus.find(vn => vn.id === f.noteStatusID),
            'value',
            ' ',
          );
        const subjectDate = isValid(new Date(f.subjectDate || ''))
          ? f.subjectDate.split('T')[0]
          : '';
        const noteDate = isValid(new Date(f.subjectDate || ''))
          ? format(new Date(f.subjectDate), viewDateFormat)
          : '';

        const reviewDate = isValid(new Date(f.reviewDate || ''))
          ? f.reviewDate.split('T')[0]
          : '';

        let keywordListID = [];

        if (!isNull(f.keywordID)) {
          keywordListID = f.keywordID
            .split(',')
            .map(item => parseInt(item, 10));
        }

        return {
          ...f,
          noteStatus,
          subjectDate,
          reviewDate,
          noteDate,
          keywordListID,
        };
        // output
      });
    }
    return gridFormattedTableData;
  },
);

export const getIdentityNotesInfo = createSelector(
  [getIdentityNotes],
  notes => {
    const { isLoading, isLoaded, hasError, error } = notes;
    return {
      isLoading,
      isLoaded,
      hasError,
      error,
    };
  },
);
