import React from 'react';
import { useLocation } from 'react-router';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

const ErrorBoundary = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const originalPathname = React.useRef(pathname);

  // Reset error boundary when user navigates away
  React.useEffect(() => {
    if (pathname !== originalPathname.current) {
      resetErrorBoundary();
    }
  }, [pathname, resetErrorBoundary]);

  if (error) {
    return (
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          flex: 1,
          padding: 2,
        }}
      >
        <Stack sx={{ alignItems: 'center', gap: 1 }}>
          <Stack
            mb={1}
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              columnGap: 2,
              rowGap: 1,
              flexWrap: 'wrap',
            }}
          >
            <WarningOutlinedIcon color="error" />
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              {t('error.unexpected.title')}
            </Typography>
          </Stack>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {t('error.unexpected.subtitle')}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            {t('error.unexpected.contactDetails')}
          </Typography>
        </Stack>
      </Paper>
    );
  }
  return null;
};

export default ErrorBoundary;
