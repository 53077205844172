/* eslint-disable no-nested-ternary */
import React from 'react';
import { Paper, Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { ErrorBoundary } from 'react-error-boundary';

import ChartFilter from './ChartFilter';
import { KPITile, DataGridChart } from '../../components/DashboardComponents';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { DashboardPageTypes } from '../../constants';

const VineaChart = ({
  chartData,
  handleApplyChartFilter,
  handleUpdateChartFilter,
  pageType,
}) => {
  const {
    options,
    series,
    chartAspectRatio,
    filters,
    hasFilters,
    chartTitle = '',
    isLoading,
    isLoaded,
    hasError,
    chartTypeID,
    dashboardPageID,
  } = chartData;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenFilterMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };

  return hasError ? null : (
    <Paper
      sx={{
        height:
          chartTypeID === 12 && pageType !== DashboardPageTypes.HOME
            ? '75vh'
            : 'calc(180px + 20vh)',
        minHeight: chartTypeID === 12 ? 'none' : '280px',
        aspectRatio: `${chartAspectRatio}`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      elevation={0}
      data-testid="vineachart-paper-wrapper"
    >
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        {!isLoaded && isLoading ? (
          <LinearProgress />
        ) : (
          <Box
            sx={{ height: '4px', width: '100%' }}
            data-testid="vineachart-box-wrapper"
          />
        )}
        {chartTypeID === 11 ? (
          <Box
            m={3}
            sx={{
              height: '100%',
              minHeight: 0,
            }}
            data-testid="vineachart-box-nested1-wrapper"
          >
            <KPITile options={options} series={series} title={chartTitle} />
          </Box>
        ) : chartTypeID === 12 ? (
          <Box
            sx={{
              height: '100%',
              minHeight: 0,
            }}
          >
            <DataGridChart
              options={options}
              series={series}
              chartTitle={chartTitle}
            />
          </Box>
        ) : (
          <Box m={2} sx={{ height: '100%' }}>
            <Chart
              options={options}
              series={series}
              type={options?.chart?.type}
              height="100%"
              width="100%"
            />
          </Box>
        )}
        {hasFilters && (
          <ChartFilter
            filters={filters}
            chartTitle={chartTitle}
            anchorEl={anchorEl}
            handleOpen={handleOpenFilterMenu}
            handleClose={handleCloseFilterMenu}
            handleApplyChartFilter={handleApplyChartFilter}
            handleUpdateChartFilter={handleUpdateChartFilter}
            dashboardPageID={dashboardPageID}
          />
        )}
      </ErrorBoundary>
    </Paper>
  );
};

VineaChart.propTypes = {
  chartData: PropTypes.shape({
    chartAspectRatio: PropTypes.number,
    chartID: PropTypes.number,
    chartSequence: PropTypes.number,
    chartSettings: PropTypes.string,
    chartTitle: PropTypes.string,
    chartTypeID: PropTypes.number,
    dashboardPageID: PropTypes.number,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    series: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        chartID: PropTypes.number,
        filterID: PropTypes.number,
        filterName: PropTypes.string,
        filterParameter: PropTypes.string,
        isMultiSelect: PropTypes.bool,
        filterOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            key: PropTypes.number,
            value: PropTypes.string,
          }),
        ),
      }),
    ),
    hasFilters: PropTypes.bool,
    options: PropTypes.shape({
      chart: PropTypes.shape({ type: PropTypes.string }),
      title: PropTypes.shape({ text: PropTypes.string }),
    }),
  }).isRequired,
  handleApplyChartFilter: PropTypes.func.isRequired,
  pageType: PropTypes.string,
};

export default VineaChart;
