/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { CollapseHorizontal } from '../CollapseHorizontal';
import { commonLookupPropTypes } from '../../utils/commonPropTypes';
// eslint-disable-next-line import/no-unresolved
import { VineaAutoComplete } from '../ComboBox';
import { VineaButton } from '../VineaButton';
import { capitalize, sortBy } from 'lodash';

export interface LookupPropType {
  key: any;
  value: any;
  id: number;
}

type FilterScreenProps = {
  onFilterToggle: any;
  isFilterOpen: boolean;
  filterData: any;
  onUpdateFilterData: any;
  lkpDisplayAttributes: LookupPropType[];
  lkpGrowingRegion: LookupPropType[];
  lkpGrowingSubRegion: LookupPropType[];
  lkpVineyards: LookupPropType[];
  nameMap: any;
  filters: any;
  selectedFilters: any;
  handleOnFilterSelect: any;
  handleOnClearFilters: any;
  handleOnShowAll: any;
  vineyardTypeScreenName: string;
};

const VineyardExplorerFilterScreen = ({
  onFilterToggle,
  isFilterOpen,
  filterData,
  onUpdateFilterData,
  lkpDisplayAttributes,
  lkpGrowingRegion,
  lkpGrowingSubRegion,
  lkpVineyards,
  nameMap,
  filters,
  selectedFilters,
  handleOnFilterSelect,
  handleOnClearFilters,
  handleOnShowAll,
  vineyardTypeScreenName,
}: FilterScreenProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { displayAttribute, regionId, subRegionId, vineyardId } =
    filterData || {};

  const handleFilterToggle = () => {
    onFilterToggle();
  };

  const handleOnChangeDropdown = (
    evt: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value, name } = evt.target;
    onUpdateFilterData({ ...filterData, [name]: value });
  };

  //Default all checkboxes to checked when filters change
  useEffect(() => {
    handleOnShowAll();
  }, [filters]);

  return !isFilterOpen ? null : (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <CollapseHorizontal
        orientation="horizontal"
        collapsedSize={48}
        in={isFilterOpen}
        className={''}
        style={undefined}
        component={undefined}
        onEnter={undefined}
        onEntered={undefined}
        onEntering={undefined}
        onExit={undefined}
        onExited={undefined}
        onExiting={undefined}
        timeout={0}
        TransitionComponent={undefined}
      >
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box>
            <Box
              pl={2}
              sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <FilterAltIcon color="primary" style={{ marginRight: 5 }} />
                <Typography variant="body2">
                  {t(`${capitalize(vineyardTypeScreenName)} Map Filters`)}
                </Typography>
              </Box>
              <Tooltip title={t('Minimise')}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    handleFilterToggle();
                  }}
                  onMouseDown={() => {}}
                  size="large"
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {/* <ErrorBoundary FallbackComponent={FallbackComponent}> */}
            <Box
              py={2}
              px={2}
              sx={{
                flex: 1,
                '& > *': {
                  flex: 1,
                  display: 'flex',
                },
              }}
              data-testid="vineyard-explorer-map-filters"
            >
              <Box
                mt={1}
                data-testid="region"
                sx={{
                  marginBottom: theme.spacing(1),
                  marginTop: theme.spacing(2),
                  minWidth: 250,
                  '& .MuiFormControl-root': {
                    minWidth: 250,
                  },
                }}
              >
                <FormControl
                  sx={{
                    width: 250,
                  }}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={regionId}
                    onChange={handleOnChangeDropdown}
                    inlineLabel
                    options={lkpGrowingRegion}
                    labelVariant="body1"
                    label={t(nameMap.regionId.name)}
                    name={t(nameMap.regionId.name)}
                    inputProps={{
                      name: 'regionId',
                    }}
                    fullWidth={false}
                  />
                </FormControl>
              </Box>
              <Box
                mt={1}
                data-testid="subregion-vineyardexplorer"
                sx={{
                  marginBottom: theme.spacing(1),
                  marginTop: theme.spacing(2),
                  minWidth: 250,
                  '& .MuiFormControl-root': {
                    minWidth: 250,
                  },
                }}
              >
                <FormControl
                  sx={{
                    width: 250,
                  }}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={subRegionId}
                    onChange={handleOnChangeDropdown}
                    inlineLabel
                    options={lkpGrowingSubRegion}
                    labelVariant="body1"
                    label={t(nameMap.subRegionId.name)}
                    name={t(nameMap.subRegionId.name)}
                    inputProps={{
                      name: 'subRegionId',
                    }}
                    fullWidth={false}
                  />
                </FormControl>
              </Box>
              <Box
                mt={1}
                data-testid="vineyard"
                sx={{
                  marginBottom: theme.spacing(1),
                  marginTop: theme.spacing(2),
                  minWidth: 250,
                  '& .MuiFormControl-root': {
                    minWidth: 250,
                  },
                }}
              >
                <FormControl
                  sx={{
                    width: 250,
                  }}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={vineyardId}
                    onChange={handleOnChangeDropdown}
                    inlineLabel
                    options={lkpVineyards}
                    labelVariant="body1"
                    label={capitalize(vineyardTypeScreenName)}
                    name={t(nameMap.vineyardId.name)}
                    inputProps={{
                      name: 'vineyardId',
                    }}
                    fullWidth={false}
                  />
                </FormControl>
              </Box>
              <Box
                mt={1}
                data-testid="display-attribute"
                sx={{
                  marginBottom: theme.spacing(1),
                  marginTop: theme.spacing(2),
                  minWidth: 250,
                  '& .MuiFormControl-root': {
                    minWidth: 250,
                  },
                }}
              >
                <FormControl
                  sx={{
                    width: 250,
                  }}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={displayAttribute}
                    onChange={handleOnChangeDropdown}
                    inlineLabel
                    options={lkpDisplayAttributes}
                    labelVariant="body1"
                    label={t(nameMap.displayAttribute.name)}
                    name={t(nameMap.displayAttribute.name)}
                    inputProps={{
                      name: 'displayAttribute',
                    }}
                    fullWidth={false}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box>
              <Stack
                sx={{
                  height: '100%',
                  overflowY: 'auto',
                }}
              >
                {sortBy(filters, 'filterName').map((f: any) => (
                  <FormControl>
                    <MenuItem key={f.filterName} value={f.colour}>
                      <ListItemText primary={f.filterName} />
                      <Checkbox
                        checked={selectedFilters.some(
                          (filter: any) => filter.filterName === f.filterName,
                        )}
                        onClick={() => {
                          handleOnFilterSelect(f);
                        }}
                      />
                      <Brightness1Icon
                        fontSize="medium"
                        sx={{
                          color: `${f.colour}`,
                        }}
                      />
                    </MenuItem>
                  </FormControl>
                ))}
              </Stack>
            </Box>
            <Box
              py={2}
              px={1}
              mx={1}
              sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-around', // justifyContent: 'flex-end'
                flexDirection: 'row',
              }}
              data-testid="control-buttons"
            >
              <VineaButton
                aria-label="Search"
                aria-controls="simple-menu"
                aria-haspopup="true"
                data-testid="filter-search"
                color="secondary"
                variant="outlined"
                onClick={handleOnShowAll}
              >
                {t('Select All')}
              </VineaButton>
              <Box px={1} py={1} />
              <VineaButton
                aria-label="clear"
                aria-controls="simple-menu"
                aria-haspopup="true"
                data-testid="filter-clear"
                color="secondary"
                variant="outlined"
                onClick={handleOnClearFilters}
              >
                {t('Clear')}
              </VineaButton>
            </Box>
            {/* </ErrorBoundary> */}
          </Box>
        </Box>
      </CollapseHorizontal>
    </Paper>
  );
};

VineyardExplorerFilterScreen.propTypes = {
  onFilterToggle: PropTypes.func.isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  filterData: PropTypes.objectOf(
    PropTypes.shape({
      businessUnitId: PropTypes.number,
      regionId: PropTypes.number,
      vineyardId: PropTypes.number,
    }),
  ),
  onUpdateFilterData: PropTypes.func.isRequired,
  lkpDisplayAttributes: commonLookupPropTypes,
  lkpGrowingRegion: commonLookupPropTypes,
  lkpVineyards: commonLookupPropTypes,
};

VineyardExplorerFilterScreen.defaultProps = {
  lkpDisplayAttributes: [],
  lkpGrowingRegion: [],
  lkpVineyards: [],
  filterData: null,
};

export default VineyardExplorerFilterScreen;
