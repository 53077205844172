import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Checkbox from '@mui/material/Checkbox';
// import isEmpty from 'lodash/isEmpty';
import { useUserHistoryHook } from '../../hooks/useUserHistoryHook';
import { getPathByScreenType } from '../../hooks/useIdentityTypeId';
import {
  GridNoRowMessage,
  GridWithFilter,
  RenderDataGridHeader,
  GridValueDateFormatter,
} from '../Grid';
import useIdentityTypeScreenNameHook from '../../hooks/useIdentityTypeScreenNameHook';

type HistoryDrawerProps = {
  open: boolean;
  setOpen: any;
};

const RenderPinInterest = ({ value }: { value: any }) => {
  return <Checkbox checked={!!value} disabled />;
};

const dataGridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    hide: true,
    hideable: true,
  },
  {
    field: 'identityName',
    headerName: 'Name',
    hideable: false,
    type: 'string',
    flex: 2,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'screenType',
    headerName: 'Type',
    hideable: false,
    type: 'string',
    flex: 1,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'lastAccessDateTime',
    headerName: 'Date Last Visited',
    hideable: false,
    flex: 1,
    renderHeader: RenderDataGridHeader,
    valueFormatter: ({ value }: { value: any }) =>
      GridValueDateFormatter(value),
  },
  {
    field: 'isPinned',
    headerName: 'Pin to List',
    hideable: false,
    type: 'boolean',
    flex: 1,
    renderHeader: RenderDataGridHeader,
    renderCell: RenderPinInterest,
    valueFormatter: ({ value }: { value: any }) => value,
  },
  {
    field: 'userIdentityID',
    headerName: 'User Identity ID',
    hideable: true,
    hide: true,
    type: 'string',
    flex: 1,
    renderHeader: RenderDataGridHeader,
    valueFormatter: ({ value }: { value: any }) => value,
  },
  {
    field: 'identityID',
    headerName: 'Identity ID',
    hideable: true,
    hide: true,
    type: 'string',
    flex: 1,
    renderHeader: RenderDataGridHeader,
    valueFormatter: ({ value }: { value: any }) => value,
  },
];

const gridColumnState = {
  id: false,
  identityName: true,
  screenType: true,
  lastAccessDateTime: true,
  isPinned: true,
  userIdentityID: false,
  identityID: false,
  identityTypeID: false,
};

export default function HistoryDrawer({ open, setOpen }: HistoryDrawerProps) {
  const { history, fetchUserHistory, updateUserHistory } = useUserHistoryHook();
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  const navigate = useNavigate();
  const toggleClose = React.useCallback(() => {
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (open) {
      fetchUserHistory();
    }
  }, [fetchUserHistory, open]);

  const historyGridData = {
    columns: dataGridColumns,
    rows: history,
  };

  const NoRowsOverlayMessage = () => (
    <Box mt={10} data-testid="blockgrid-norowmessage">
      <GridNoRowMessage message="Sorry, there were no records found." />
    </Box>
  );

  const toggleDrawer = (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      toggleClose();
    }
  };

  const handleOnClickAway = () => {
    toggleClose();
  };

  const handleOnCellClick = React.useCallback(
    (event: any) => {
      const { field, id, row = {} } = event;
      if (id && field === 'isPinned') {
        updateUserHistory({
          ...row,
          isPinned: !row.isPinned,
        });
      } else if (field === 'identityName') {
        const { identityTypeID, identityID, screenType } = row;
        const path = getPathByScreenType(
          Number(identityTypeID),
          Number(identityID),
          screenType,
          vineyardTypeScreenName,
        );
        if (path) {
          toggleClose();
          navigate(path);
        }
      }
    },
    [updateUserHistory, navigate, toggleClose],
  );

  const DrawerList = (
    <Box
      sx={{ height: '100%', width: 650, position: 'relative' }}
      role="presentation"
    >
      <Typography sx={{ px: 2 }} variant="h6">
        History
      </Typography>

      <Box sx={{ padding: 4, height: '100%' }} width="100%">
        {/** @ts-ignore */}
        <GridWithFilter
          data={{
            ...historyGridData,
          }}
          advancedSearch={false}
          autoPageSize
          onRowClick={() => {}}
          onCellClick={handleOnCellClick}
          rowHeight={30}
          headerHeight={56}
          noRowsOverlay={NoRowsOverlayMessage}
          actionType="xyz"
          gridColumnState={gridColumnState}
        />
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer open={open} onClose={toggleDrawer} anchor="right">
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={handleOnClickAway}
        >
          {DrawerList}
        </ClickAwayListener>
      </Drawer>
    </div>
  );
}
