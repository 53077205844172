/* eslint-disable react/forbid-prop-types */
import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const SpacedBox = styled(Box)(() => ({
  '&::before': {
    content: '" "',
  },
  '&::after': {
    content: '" "',
  },
}));

export function FilterTextDescription({
  selectedValuesText = [],
  showBlankValues = true,
  isLarge = false,
  iconColour,
  excludeBusinessUnit = false, // new prop to indicate which filter value to exclude
}) {
  let selectedValuesFiltered = showBlankValues
    ? selectedValuesText
    : selectedValuesText.filter(({ values }) => values?.some(e => e));
  if (excludeBusinessUnit) {
    selectedValuesFiltered = selectedValuesFiltered.filter(
      ({ filterName }) => filterName !== 'Business Unit',
    );
  }

  const fontSize = isLarge ? '14px' : '12px';
  const lineHeight = isLarge ? '16px' : '14px';

  return (
    <>
      <FilterAltIcon
        color={iconColour}
        sx={{ marginRight: 1 }}
        data-testid="grid-filter-icon"
      />
      <Box>
        {selectedValuesFiltered.map(({ filterName, values }, index) => (
          <React.Fragment key={filterName}>
            {index !== 0 && <SpacedBox component="span">•</SpacedBox>}
            {!showBlankValues && get(values, 0) === true ? (
              // boolean values should just show the filterName if true
              <Box
                component="span"
                sx={{ fontSize, lineHeight, fontWeight: 500 }}
                data-testid="grid-filter-name"
              >
                {filterName}
              </Box>
            ) : (
              <>
                <Box
                  component="span"
                  sx={{ fontSize, lineHeight, fontWeight: 500 }}
                  data-testid="grid-filter-name"
                >
                  {`${filterName}:`}
                </Box>
                <Box component="span" data-testid="grid-filter-separator">
                  {' '}
                </Box>
                <Box
                  component="span"
                  sx={{ fontSize, lineHeight, fontWeight: 400 }}
                  data-testid="grid-filter-values"
                >
                  {values?.join(', ')}
                </Box>
              </>
            )}
          </React.Fragment>
        ))}
      </Box>
    </>
  );
}

FilterTextDescription.propTypes = {
  selectedValuesText: PropTypes.arrayOf(
    PropTypes.shape({
      filterName: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  showBlankValues: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool,
  iconColour: PropTypes.string,
  excludeBusinessUnit: PropTypes.bool, // new prop to indicate which filter value to exclude
};

FilterTextDescription.defaultProps = {
  iconColour: undefined,
  isLarge: false,
  excludeBusinessUnit: false, // default to not excluding any filter value
};
