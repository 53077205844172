import React from 'react';
import Box from '@mui/material/Box';
import { TrellisStatusDropdown } from './TrellisStatusDropdown';

export const RenderCellWithTrellis = params => {
  const {
    row: { id, isEditMode, trellisID, trellisStatus } = {},
    colDef: { onChange } = {},
  } = params || {};

  return (
    <Box
      display="flex"
      flex="1"
      sx={{
        width: '100%',
        '& .MuiFormControl-root': {
          width: '100%',
        },
        '& .MuiInputBase-root': {
          width: '100%',
        },
      }}
    >
      {!!isEditMode && (
        <TrellisStatusDropdown
          value={trellisID}
          onChange={onChange}
          name="trellisID"
          id="trellisID"
          rowId={id}
        />
      )}

      {!isEditMode && <div>{trellisStatus}</div>}
    </Box>
  );
};
