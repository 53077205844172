import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty, map, get, find, isNil, filter, has, some } from 'lodash';
import {
  VineaNovaSelectors,
  VineaNovaActions,
  VineaHooks,
} from 'vineanova-redux-artifacts';
import { isBefore, isValid } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getJobWorkUnits, getLkpJobType } from '../../../redux/selectors';
import {
  IdentityActivityDropdownType,
  JobActivityAndRateType,
  JobActivityTableType,
  MetaDataType,
} from '../interfaces/jobInterfaces';
import { useTranslation } from 'react-i18next';
import { validDateFormat } from '../../../utils/DateUtil';
import { syncValidator } from '../../../utils/validator';
import { JobActivitiesStepperSchema } from '../validations';
import { formatDate } from '../../../constants/formatter';
import { commonFieldSelectOption } from '../../../constants';

export const useCreateJobHook = (identityObject: any) => {
  const dispatchAPI = useDispatch();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const noErrors = {
    jobActivityRateID: false,
    jobActivityID: false,
    jobID: false,
    activityID: false,
    jobActivityDescription: false,
    jobActivityCode: false,
    isDefault: false,
    rowAttributeID: false,
    effectiveFrom: false,
    effectiveTo: false,
    contractedRate: false,
    payrollRate: false,
  };

  const EmptyJobActivityRateObject = {
    jobActivityRateID: null,
    jobActivityID: null,
    jobID: identityObject.identityId,
    activityID: null,
    jobActivityCode: null,
    jobActivityDescription: null,
    isDefault: false,
    canDeleteActivity: false,
    rowAttributeID: null,
    effectiveFrom: null,
    effectiveTo: null,
    contractedRate: null,
    payrollRate: null,
  };

  const [validationErrors, setValidationErrors] = useState({
    ...noErrors,
  });

  const [activityAPITrigger, setActivityAPITrigger] = useState(false);
  const [activityRateAPITrigger, setActivityRateAPITrigger] = useState(false);
  const [refreshJobActivityAPITrigger, setRefreshJobActivityAPITrigger] =
    useState(false);
  const [jobActivityDrawerOpen, setJobActivityDrawerOpen] = useState(false);
  const [isAddDrawer, setIsAddDrawer] = useState(true);
  const { data: jobData } = useSelector(
    (state: any) => state.entities.identityJob,
  );
  const { isLoaded: jobDataLoaded, isLoading: jobDataLoading } = useSelector(
    VineaNovaSelectors.getIdentityJobEntityMeta,
  ) as MetaDataType;

  const defaultRateDate = useMemo(() => {
    let rateDate = get(jobData, 'scheduledStartDate');
    if (isNil(rateDate)) {
      rateDate = new Date().toISOString();
    }
    return rateDate.slice(0, 10);
  }, [jobData]);
  const { data: identityActivities } = VineaHooks.useFetchIdentityActivity({
    queryParams: {
      DefaultRateDate: defaultRateDate,
    },
  });
  const lkpJobWorkUnits = useSelector(state => getJobWorkUnits(state));
  const lkpJobType = useSelector(state => getLkpJobType(state));
  const [activityOptions, setActivityOptions] = useState<
    IdentityActivityDropdownType[]
  >([]);

  // API data
  const { data: jobActivityRates } = useSelector(
    (state: any) => state.entities.getJobActivityRates,
  );
  const {
    isLoaded: jobActivityRatesLoaded,
    isLoading: jobActivityRatesLoading,
  } = useSelector(
    VineaNovaSelectors.getGetJobActivityRatesEntityMeta,
  ) as MetaDataType;
  // A list of all job's activities
  const [jobActivityRatesData, setJobActivityRatesData] = useState<
    JobActivityTableType[]
  >([]);
  // Selected job activity rate object
  const [jobActivityRateObject, setJobActivityRateObject] =
    useState<JobActivityAndRateType>(EmptyJobActivityRateObject);
  const {
    isLoaded: jobActivityRateLoaded,
    isLoading: jobActivityRateLoading,
    error: jobActivityRateError,
  } = useSelector(
    VineaNovaSelectors.getJobActivityRateEntityMeta,
  ) as MetaDataType;
  const { data: jobActivityUpdate } = useSelector(
    (state: any) => state.entities.jobActivity,
  );
  const {
    isLoaded: jobActivityLoaded,
    isLoading: jobActivityLoading,
    error: jobActivityError,
  } = useSelector(VineaNovaSelectors.getJobActivityEntityMeta) as MetaDataType;

  const { data: jobRowSpecialAttributes } =
    VineaHooks.useFetchJobRowSpecialAttributes({
      queryParams: {
        JobID: identityObject.identityId,
      },
    });

  const rowSpecialAttributeOptions = useMemo(
    () =>
      commonFieldSelectOption.concat(
        map(jobRowSpecialAttributes, (j: any) => {
          return { id: j?.id, key: j?.id, value: j?.rowSpecialAttribute };
        }),
      ),
    [jobRowSpecialAttributes],
  );

  const hasDefault = some(
    jobActivityRates,
    j =>
      j?.isDefault === true &&
      j?.jobActivityRateID !== jobActivityRateObject?.jobActivityRateID,
  );

  const columnHeaders = [
    t('Code'),
    t('Description'),
    t('Contracted Rate'),
    t('Payroll Rate'),
    t('Default'),
    t('Active From'),
    t('Active To'),
  ];

  // Open drawer to edit selected job activity rate
  const handleEditJobActivityRate = (row: any) => {
    const selectedActivityRate = find(jobActivityRates, {
      jobActivityRateID: row?.jobActivityRateID,
    });
    setJobActivityDrawerOpen(true);
    setJobActivityRateObject(selectedActivityRate);
    setIsAddDrawer(false);
    setValidationErrors({ ...noErrors });
  };

  // Open drawer to add new activity rate to job
  const handleAddJobActivityRate = () => {
    setJobActivityDrawerOpen(true);
    setIsAddDrawer(true);
    setValidationErrors({ ...noErrors });
  };

  // Close job activity rate drawer
  const handleOnCloseDrawer = () => {
    setJobActivityDrawerOpen(false);
    setJobActivityRateObject(EmptyJobActivityRateObject);
    setValidationErrors({ ...noErrors });
  };

  // Update job activity rate object data that will be sent to API
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    if (name === 'activityID') {
      const act = find(identityActivities, {
        id: Number(value),
      }) as IdentityActivityDropdownType;

      //@ts-ignore
      setJobActivityRateObject({
        ...jobActivityRateObject,
        [name]: Number(value),
        jobActivityDescription: get(act, 'activityDescription', act?.value),
        contractedRate: get(
          act,
          'defaultChargeOutRate',
          jobActivityRateObject?.contractedRate,
        ),
        payrollRate: get(
          act,
          'defaultCostRate',
          jobActivityRateObject?.payrollRate,
        ),
      });
    } else {
      //@ts-ignore
      setJobActivityRateObject({
        ...jobActivityRateObject,
        [name]: value,
      });
    }
  };

  const handleOnChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { checked, name },
    } = event;
    //@ts-ignore
    setJobActivityRateObject({
      ...jobActivityRateObject,
      [name]: checked,
    });
  };

  // Need to work out delete logic, do we delete a job activity rate or a job activity too?
  const handleOnDelete = () => {
    setJobActivityDrawerOpen(false);
  };

  // Either insert or update both job activity and job activity rates
  const handleOnSave = () => {
    let validationErrors = syncValidator(JobActivitiesStepperSchema)(
      jobActivityRateObject,
    );

    if (hasDefault && jobActivityRateObject?.isDefault) {
      validationErrors = {
        ...validationErrors,
        isDefault: 'Job already has a default activity',
      };
    }

    if (jobActivityRateObject?.isDefault) {
      const activityWorkUnitID = get(
        find(identityActivities, { id: jobActivityRateObject?.activityID }),
        'workUnitID',
      );
      const jobTypeDefaultWorkUnit = find(lkpJobType, {
        id: get(jobData, 'jobTypeID'),
      });

      const jobTypeDefaultWorkUnitID = get(
        jobTypeDefaultWorkUnit,
        'defaultWorkUnitID',
        null,
      );

      if (
        !isNil(jobTypeDefaultWorkUnitID) &&
        !isNil(jobTypeDefaultWorkUnit) &&
        jobTypeDefaultWorkUnitID !== activityWorkUnitID
      ) {
        validationErrors = {
          ...validationErrors,
          isDefault:
            'The default activity must be of type: ' +
            get(jobTypeDefaultWorkUnit, 'defaultWorkUnitValue'),
        };
      }
    }

    if (
      isEmpty(validationErrors) &&
      !isNil(jobActivityRateObject?.effectiveTo)
    ) {
      const effectiveFrom = isValid(
        validDateFormat(get(jobActivityRateObject, 'effectiveFrom')),
      )
        ? validDateFormat(get(jobActivityRateObject, 'effectiveFrom'))
        : null;
      const effectiveTo = isValid(get(jobActivityRateObject, 'effectiveTo'))
        ? validDateFormat(get(jobActivityRateObject, 'effectiveTo'))
        : null;
      const isBeforeFromDate =
        effectiveFrom && effectiveTo && isBefore(effectiveTo, effectiveFrom);

      if (isBeforeFromDate) {
        validationErrors = {
          activeTo: 'Active To date is before Active From date',
        };
      }
    }

    if (isEmpty(validationErrors)) {
      if (isAddDrawer) {
        dispatchAPI(
          VineaNovaActions.api.v1.jobActivity.post.request({
            postBody: {
              jobID: jobActivityRateObject?.jobID,
              activityID: jobActivityRateObject?.activityID,
              jobActivityDescription:
                jobActivityRateObject?.jobActivityDescription,
              isDefault: get(jobActivityRateObject, 'isDefault', false),
              rowAttributeID: jobActivityRateObject?.rowAttributeID,
            },
          }),
        );
        setActivityAPITrigger(true);
      } else {
        dispatchAPI(
          VineaNovaActions.api.v1.jobActivity.put.request({
            postBody: {
              id: jobActivityRateObject?.jobActivityID,
              jobID: jobActivityRateObject?.jobID,
              activityID: jobActivityRateObject?.activityID,
              jobActivityDescription:
                jobActivityRateObject?.jobActivityDescription,
              isDefault: get(jobActivityRateObject, 'isDefault', false),
              rowAttributeID: jobActivityRateObject?.rowAttributeID,
            },
          }),
        );

        dispatchAPI(
          VineaNovaActions.api.v1.jobActivityRate.put.request({
            postBody: {
              id: jobActivityRateObject?.jobActivityRateID,
              jobActivityID: jobActivityRateObject?.jobActivityID,
              effectiveFrom: isNil(jobActivityRateObject?.effectiveFrom)
                ? null
                : //@ts-ignore
                  new Date(jobActivityRateObject?.effectiveFrom),
              effectiveTo: isNil(jobActivityRateObject?.effectiveTo)
                ? null
                : //@ts-ignore
                  new Date(jobActivityRateObject?.effectiveTo),
              contractedRate: get(jobActivityRateObject, 'contractedRate', 0),
              payrollRate: get(jobActivityRateObject, 'payrollRate', 0),
            },
          }),
        );
        setActivityRateAPITrigger(true);
        setJobActivityRateObject(EmptyJobActivityRateObject);
      }
      setJobActivityDrawerOpen(false);
      setValidationErrors({ ...noErrors });
    }
    setValidationErrors(validationErrors);
  };

  // Initial data load
  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.getJobActivityRates.get.request({
        queryParams: {
          JobID: identityObject.identityId,
        },
      }),
    );
    dispatchAPI(
      VineaNovaActions.api.v1.identityJob.get.request({
        queryParams: {
          IdentityID: identityObject.identityId,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set job activity rates data once api has loaded
  useEffect(() => {
    if (jobActivityRatesLoaded && !jobActivityRatesLoading) {
      const jobActivityRatesData = map(jobActivityRates, (j: any) => {
        const activityCode = get(
          find(identityActivities, { id: j?.activityID }),
          'activityCode',
          '',
        );
        return {
          jobActivityRateID: j?.jobActivityRateID,
          jobActivityID: j?.jobActivityID,
          activityID: j?.activityID,
          jobActivityDescription: j?.jobActivityDescription,
          jobActivityCode: activityCode,
          isDefault: j?.isDefault === 'true' ? 'Yes' : 'No',
          effectiveFrom: formatDate(j?.effectiveFrom),
          effectiveTo: formatDate(j?.effectiveTo),
          contractedRate: j?.contractedRate,
          payrollRate: j?.payrollRate,
        };
      });

      setJobActivityRatesData(jobActivityRatesData);
    }
  }, [jobActivityRatesLoaded, jobActivityRatesLoading]);

  useEffect(() => {
    if (
      refreshJobActivityAPITrigger &&
      jobActivityRatesLoaded &&
      !jobActivityRatesLoading
    ) {
      const jobActivityRatesMapped = map(
        jobActivityRates,
        (j: JobActivityAndRateType) => {
          const activityCode = get(
            find(identityActivities, { id: j.activityID }),
            'activityCode',
            '',
          );
          return {
            jobActivityRateID: j.jobActivityRateID,
            jobActivityID: j.jobActivityID,
            activityID: j.activityID,
            jobActivityDescription: j.jobActivityDescription,
            jobActivityCode: activityCode,
            isDefault: j.isDefault ? 'Yes' : 'No',
            effectiveFrom: formatDate(j.effectiveFrom),
            effectiveTo: formatDate(j.effectiveTo),
            contractedRate: j.contractedRate,
            payrollRate: j.payrollRate,
          };
        },
      );
      setJobActivityRatesData(jobActivityRatesMapped);
      setRefreshJobActivityAPITrigger(false);
    }
  }, [
    refreshJobActivityAPITrigger,
    jobActivityRatesLoaded,
    jobActivityRatesLoading,
  ]);

  // Add job activity rate once job activity has loaded
  useEffect(() => {
    if (
      jobActivityLoaded &&
      !jobActivityLoading &&
      activityAPITrigger &&
      has(jobActivityUpdate, 'id')
    ) {
      dispatchAPI(
        VineaNovaActions.api.v1.jobActivityRate.post.request({
          postBody: {
            jobActivityID: jobActivityUpdate?.id,
            effectiveFrom: isNil(jobActivityRateObject?.effectiveFrom)
              ? null
              : //@ts-ignore
                new Date(jobActivityRateObject?.effectiveFrom),
            effectiveTo: isNil(jobActivityRateObject?.effectiveTo)
              ? null
              : //@ts-ignore
                new Date(jobActivityRateObject?.effectiveTo),
            contractedRate: jobActivityRateObject?.contractedRate,
            payrollRate: jobActivityRateObject?.payrollRate,
          },
        }),
      );
      setActivityRateAPITrigger(true);
      setJobActivityRateObject(EmptyJobActivityRateObject);
    }
  }, [activityAPITrigger, jobActivityLoaded, jobActivityLoading]);

  // Set activity options once job data loads
  useEffect(() => {
    if (jobDataLoaded && !jobDataLoading && has(jobData, 'jobTypeID')) {
      const jobTypeID = get(jobData, 'jobTypeID');
      const validWorkUnitIDs = map(
        filter(lkpJobWorkUnits, {
          jobTypeID: jobTypeID,
        }),
        (a: any) => {
          return a.workUnitID;
        },
      );
      const validActivities = map(
        filter(identityActivities, (i: any) => {
          return validWorkUnitIDs.includes(i.workUnitID);
        }),
        identityActivity => {
          return {
            ...identityActivity,
            key: identityActivity.id,
            value: identityActivity.activityCode,
          };
        },
      ) as IdentityActivityDropdownType[];
      setActivityOptions(validActivities);
    }
  }, [jobDataLoaded, jobActivityLoading]);

  // Show snackbars based on update job and insert job blocks api responses
  useEffect(() => {
    if (
      activityRateAPITrigger &&
      jobActivityLoaded &&
      !jobActivityLoading &&
      jobActivityRateLoaded &&
      !jobActivityRateLoading
    ) {
      if (!jobActivityError && !jobActivityRateError) {
        dispatchAPI(
          VineaNovaActions.api.v1.getJobActivityRates.get.request({
            queryParams: {
              JobID: identityObject.identityId,
            },
          }),
        );
        // @ts-ignore
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        setRefreshJobActivityAPITrigger(true);
        setActivityAPITrigger(false);
        setActivityRateAPITrigger(false);
      } else {
        // @ts-ignore
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }
    }
  }, [
    activityRateAPITrigger,
    jobActivityLoaded,
    jobActivityLoading,
    jobActivityRateLoaded,
    jobActivityRateLoading,
    jobActivityError,
    jobActivityRateError,
  ]);

  return {
    columnHeaders,
    jobActivityRatesLoading,
    jobActivityRatesData,
    jobActivityDrawerOpen,
    isAddDrawer,
    jobActivityRateObject,
    activityOptions,
    validationErrors,
    rowSpecialAttributeOptions,
    handleEditJobActivityRate,
    handleAddJobActivityRate,
    handleOnCloseDrawer,
    handleOnSave,
    handleOnChange,
    handleOnChangeCheckbox,
    handleOnDelete,
  };
};
