import { format, parse } from 'date-fns';

const validDateFormat = (date) => {   
    
if (date != null && date.toString().includes("T"))
    {
        return parse(date.toString().substring(0,10),"yyyy-MM-dd",new Date());
    }
    else if (date != null)
    {
        return parse(date, "yyyy-MM-dd", new Date());
    }   
    else
    {
        return null;
    }

  };
  
  export {validDateFormat};
