/* eslint-disable no-unused-vars */
import React, { useReducer, useEffect, useState } from 'react';
import { isEmpty, omit, map, isNil, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import {
  addGenericReducer,
  types,
  jobState,
} from '../../VineyardIdentity/stateReducers';
import { syncValidator } from '../../../utils/validator';
import { JobSchema } from '../validations';
import {
  apiTypes,
  reducers,
  sagaActionTypes,
  vineaDetails,
  ClassificationIDs,
  JobStatusIDs,
  commonFieldSelectOption,
} from '../../../constants';
import {
  getLkpJobQuantityType,
  getLkpJobType,
  getJobStatus,
} from '../../../redux/selectors';
import { statechecker } from '../../../utils/statecomparator';
import { useJobHook } from './useJobsHook';
import { MetaDataType } from '../interfaces/jobInterfaces';
import { DropDownOption } from '../../../constants/contantTypes';

export const useManageJobHook = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = React.useState(false);
  const [initialAPITrigger, setInitialAPITrigger] = useState(true);
  const [formLoadingState, setFormLoadingState] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [saveIndex, setSaveIndex] = useState(0);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [formWriteReloadState, setFormWriteReloadState] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [formdata, dispatch] = useReducer(addGenericReducer, jobState);
  const [deleteHasFailed, setDeleteHasFailed] = useState(false);
  const [validationsErrors, setValidationErrors] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  const lkpJobType = useSelector(state => getLkpJobType(state));
  const lkpJobStatus = useSelector(state => getJobStatus(state));
  const lkpJobQuantityType = useSelector(state => getLkpJobQuantityType(state));
  const {
    isLoaded: identitiesByClassificationLoaded,
    isLoading: identitiesByClassificationLoading,
  } = useSelector(
    VineaNovaSelectors.getIdentitiesByClassificationEntityMeta,
  ) as MetaDataType;
  const { data: identitiesByClassificationData } = useSelector(
    //@ts-ignore;
    state => state.entities.identitiesByClassification,
  );
  const [invoiceToData, setInvoiceToData] = useState<DropDownOption[]>(
    commonFieldSelectOption,
  );

  const { id: pathParam } = useParams();
  const {
    isLoading,
    isLoaded,
    hasError,
    data: jobData,
    error,
    //@ts-ignore;
  } = useSelector(state => state.formWrite);
  const canDelete = get(jobData, 'canDelete', false);

  const {
    formData: updatedFormData,
    jobInvoiceMethodOptions,
    workRecordLevelOptions,
    workRecordUnitTypeOptions,
    workRecordLevelDisabled,
    unitTypeDisabled,
    quantityTypeDisabled,
    setUpdatedFormData,
  } = useJobHook(formdata);

  let errorFromAPI = '';

  if (error) {
    console.log('error', error);
    errorFromAPI = error.data;
  }

  const [updateApiStatus, setUpdateApiStatus] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlertType(false);
    const {
      target: { value, name },
    } = event;
    setUpdatedFormData({ ...updatedFormData, [name]: value });

    // If job has no job status and a scheduled start date is chosen, set job status to scheduled
    if (
      name === 'scheduledStartDate' &&
      (updatedFormData.jobStatusID === JobStatusIDs.UNSCHEDULED ||
        isNil(updatedFormData.jobStatusID))
    ) {
      //@ts-ignore
      dispatch({
        type: types.UPDATE,
        payload: { jobStatusID: JobStatusIDs.SCHEDULED },
      });
    }

    setHasChanges(true);
  };

  const handleSave = (event: any, index: number) => {
    setSaveIndex(index);

    if (index === 1 || hasChanges) {
      const validationErrors = syncValidator(JobSchema)({
        ...updatedFormData,
      });

      console.log('ValidationErrors', validationErrors);

      if (!isEmpty(validationErrors)) {
        setValidationErrors(validationErrors);
      } else if (
        jobData.jobName === updatedFormData.jobName &&
        jobData.jobDescription === updatedFormData.jobDescription &&
        jobData.identityTypeID === updatedFormData.identityTypeID &&
        jobData.jobTypeID === updatedFormData.jobTypeID &&
        jobData.jobStatusID === updatedFormData.jobStatusID &&
        jobData.invoiceMethodID === updatedFormData.invoiceMethodID &&
        jobData.quantityTypeID === updatedFormData.quantityTypeID &&
        jobData.workRecordUnitTypeID === updatedFormData.workRecordUnitTypeID &&
        jobData.workRecordLevelID === updatedFormData.workRecordLevelID &&
        jobData.invoiceToIdentityID === updatedFormData.invoiceToIdentityID &&
        jobData.poNumber === updatedFormData.poNumber &&
        jobData.scheduledStartDate === updatedFormData.scheduledStartDate &&
        jobData.businessUnitID === updatedFormData.businessUnitID
      ) {
        setWarningInSubmit(true);
      } else {
        setWarningInSubmit(false);
        setErrorInSubmit(false);
        const data = omit(
          {
            id: pathParam,
            ...updatedFormData,
          },
          'errors',
        );
        dispatchAPI({
          // need to change payload
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.job,
            methodType: apiTypes.PUT,
          },
        });
        setUpdateApiStatus(true);
        setFormWriteReloadState(true);
      }
    } else {
      navigate(-1);
    }
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleClose = () => {
    if (!saveButtonStatus && hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleOnConfirmDelete = () => {
    if (pathParam) {
      setIsDeleting(true);
      dispatchAPI(
        VineaNovaActions.api.v1.identityJob.delete.request({
          queryParams: {
            IdentityID: Number(pathParam),
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (isDeleting) {
      navigate('/job');
      setIsDeleting(false);
    }
  }, [isDeleting]);

  useEffect(() => {
    if (!isLoading && isLoaded && !isEmpty(jobData) && !hasError) {
      const stubJobData = {
        ...jobData,
      };
      const stubFormData = omit(updatedFormData, 'errors');
      if (statechecker(stubFormData, stubJobData)) {
        setSaveButtonStatus(true);
      } else setSaveButtonStatus(false);
    }
  }, [isLoading, isLoaded, jobData, hasError, updatedFormData]);

  useEffect(() => {
    if (isLoaded && !hasError && initialAPITrigger) {
      setInitialAPITrigger(false);
      setFormLoadingState(false);
      //@ts-ignore
      dispatch({
        type: types.UPDATE,
        payload: {
          jobName: jobData.jobName,
          jobDescription: jobData.jobDescription,
          identityTypeID: jobData.identityTypeID,
          businessUnitID: jobData.businessUnitID,
          jobTypeID: jobData.jobTypeID,
          jobStatusID: jobData.jobStatusID,
          invoiceMethodID: jobData.invoiceMethodID,
          quantityTypeID: jobData.quantityTypeID,
          workRecordLevelID: jobData.workRecordLevelID,
          workRecordUnitTypeID: jobData.workRecordUnitTypeID,
          invoiceToIdentityID: jobData.invoiceToIdentityID,
          poNumber: jobData.poNumber,
          scheduledStartDate: jobData.scheduledStartDate,
        },
      });
    }
  }, [
    isLoaded,
    hasError,
    initialAPITrigger,
    jobData.jobName,
    jobData.jobDescription,
    jobData.businessUnitID,
    jobData.jobTypeID,
    jobData.jobStatusID,
    jobData.invoiceMethodID,
    jobData.quantityTypeID,
    jobData.workRecordLevelID,
    jobData.workRecordUnitTypeID,
    jobData.invoiceToIdentityID,
    jobData.identityTypeID,
    jobData.poNumber,
    jobData.scheduledStartDate,
    isLoading,
    formLoadingState,
  ]);

  useEffect(() => {
    if (isLoading) {
      setFormLoadingState(true);
    }
    if (!hasError && isLoaded && updateApiStatus && formLoadingState) {
      enqueueSnackbar('Success!', { variant: 'success' });
      setHasChanges(false);
      setAlertType(true);
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
      if (saveIndex === 0) navigate(-1);
      else if (formWriteReloadState && saveIndex === 1) {
        setFormWriteReloadState(false);
        dispatchAPI({
          type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
          payload: { id: pathParam, name: reducers.job },
        });
      }
      setFormLoadingState(false);
    } else if (hasError && isLoaded && updateApiStatus) {
      setErrorInSubmit(true);
      //@ts-ignore
      enqueueSnackbar(t('Error'), { variant: 'Error' });
    }
  }, [
    isLoaded,
    updateApiStatus,
    enqueueSnackbar,
    t,
    navigate,
    hasError,
    jobData.id,
    saveIndex,
    warningInSubmit,
    isLoading,
    formLoadingState,
    dispatchAPI,
    formWriteReloadState,
    pathParam,
  ]);

  // Set invoice to options once identities by classification api has loaded
  useEffect(() => {
    if (
      identitiesByClassificationLoaded &&
      !identitiesByClassificationLoading
    ) {
      const invoiceTo = map(identitiesByClassificationData, identity => {
        return {
          id: identity.id,
          key: identity.id,
          value: identity.displayName,
        };
      });
      setInvoiceToData(invoiceTo);
    }
  }, [identitiesByClassificationLoaded, identitiesByClassificationLoading]);

  // Load payers initially
  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.identitiesByClassification.get.request({
        queryParams: {
          ClassificationIDs: `${ClassificationIDs.PAYER}`,
        },
      }),
    );
  }, []);

  return {
    errorInSubmit,
    errorFromAPI,
    alertType,
    deleteHasFailed,
    warningInSubmit,
    updatedFormData,
    validationsErrors,
    lkpJobType,
    workRecordLevelDisabled,
    workRecordLevelOptions,
    quantityTypeDisabled,
    lkpJobStatus,
    lkpJobQuantityType,
    unitTypeDisabled,
    workRecordUnitTypeOptions,
    jobInvoiceMethodOptions,
    invoiceToData,
    hasChanges,
    discardDialogOpen,
    canDelete,
    setErrorInSubmit,
    setAlertType,
    setDeleteHasFailed,
    handleChange,
    handleOnConfirmDelete,
    handleSave,
    handleClose,
    handleOnDialogClose,
    handleCancel,
  };
};
