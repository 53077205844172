import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import FilterScreen from './FilterScreen.tsx';
import { UserSearchResults } from './UserSearchResults';
import { IdentitySubMenu } from '../../components/IdentitySubMenu';
import {
  areLookupsLoaded,
  getUserPreferences,
  getAllUsers,
} from '../../redux/selectors';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import {
  sagaActionTypes as Types,
  reducers,
  actionTypes,
  sagaActionTypes,
  // featureFlags,
} from '../../constants';
import useLocationListener from '../../hooks/useLocationListener';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import useUserResponsiveLayout from '../../hooks/useUserResponsiveLayout.jsx';

const RootDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: '100%',
}));

const StyledBox = styled(Box)(({ theme, isFilterOpen }) => ({
  ...(isFilterOpen && {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flex: '0.4',
    },
    [theme.breakpoints.up('md')]: {
      flex: '0.3',
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0.18',
    },
  }),
  ...(!isFilterOpen && { display: 'flex', flex: 0 }),
}));

const Users = () => {
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const newLocationKeys = useLocationListener();
  const { t } = useTranslation();
  const [locationKeys, setLocationKeys] = useState([]);
  const [filterData, setFilterData] = useState({});
  const { setIsLocked, isLocked } = useTabChangeLock();

  const [filterText, setFilterText] = React.useState();
  const firstFilterInputRef = React.useRef();

  /** Selectors */

  const { basicSettings } = useSelector(state => getUserPreferences(state));
  const { filterPanelCollapsed } = basicSettings;
  const allUsers = useSelector(state => getAllUsers(state));
  const { data: usersSearchFilter = {} } = useSelector(
    state => state.usersSearchFilter,
  );

  const isLookupsLoaded = useSelector(areLookupsLoaded);
  const { isAdministrator } = useUserResponsiveLayout();
  const defaultFilterValues = useMemo(() => {
    return {
      searchName: '',
      searchEmail: '',
      searchIsActive: true,
      searchIsAdmin: false,
    };
  }, []);

  /** Dispatches */
  const onSearchIdentities = (searchParams, newFilterText) => {
    setFilterData(searchParams);
    dispatchAPI({
      type: Types.SEARCH_USERS,
      name: reducers.searchUsers,
      payload: { ...searchParams },
    });

    setFilterText(newFilterText);
  };

  const onUpdateFilterData = data => {
    setFilterData(data);
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.usersSearchFilter,
      payload: data,
    });
  };

  const handleOnFilterToggle = () => {
    dispatchAPI({
      type: 'BASIC_SETTINGS_UPDATE',
      payload: {
        ...basicSettings,
        filterPanelCollapsed: !filterPanelCollapsed,
      },
    });
  };

  const handleOnFilterOpen = () => {
    if (filterPanelCollapsed) {
      dispatchAPI({
        type: 'BASIC_SETTINGS_UPDATE',
        payload: {
          ...basicSettings,
          filterPanelCollapsed: false,
        },
      });
    } else if (firstFilterInputRef.current) {
      firstFilterInputRef.current?.focus();
    }
  };

  /** Only trigger if below apiTrigger Status is already initiated */

  useEffect(() => {
    setFilterData(defaultFilterValues);
    dispatchAPI({
      type: Types.SEARCH_USERS,
      name: reducers.searchUsers,
      payload: { ...defaultFilterValues },
    });
  }, []);

  useEffect(() => {
    if (isLocked) {
      setIsLocked(false);
    }
    if (!isEqual(locationKeys, newLocationKeys) && isLookupsLoaded) {
      setLocationKeys(newLocationKeys);
      setFilterData({
        ...defaultFilterValues,
      });
      dispatchAPI({
        type: Types.SEARCH_USERS,
        name: reducers.searchUsers,
        payload: {
          ...defaultFilterValues,
        },
      });
    }
  }, [
    newLocationKeys,
    locationKeys,
    dispatchAPI,
    isLookupsLoaded,
    setIsLocked,
    isLocked,
    usersSearchFilter,
    defaultFilterValues,
  ]);

  /** EOF Dispatches */

  const handleOnNewUser = () => {
    navigate('/users/newuser');
  };

  const handleOnExport = () => {
    dispatchAPI({ type: sagaActionTypes.EXPORT_EXCEL_REPORT });
  };

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      data-testid="user-search-screen"
      height="100%"
    >
      <Paper
        elevation={0}
        data-testid="submenu-user-search"
        sx={{
          padding: 1,
          marginBottom: 3,
        }}
      >
        <IdentitySubMenu
          onNewIdentityClick={handleOnNewUser}
          newButtonText={t('Add User')}
          newButtonDisabled={!isAdministrator}
          onExportClick={handleOnExport}
        />
      </Paper>
      <RootDiv data-testid="user-search-table">
        <StyledBox isFilterOpen={!filterPanelCollapsed}>
          <FilterScreen
            isFilterOpen={!filterPanelCollapsed}
            onFilterToggle={handleOnFilterToggle}
            search={onSearchIdentities}
            filterData={filterData}
            onUpdateFilterData={onUpdateFilterData}
            firstFilterInputRef={firstFilterInputRef}
          />
        </StyledBox>
        <Box display="flex" flex={1} ml={2} pb={3} mr={2}>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <UserSearchResults
              identities={allUsers}
              filterText={filterText}
              onFilterOpen={handleOnFilterOpen}
            />
          </ErrorBoundary>
        </Box>
      </RootDiv>
    </Box>
  );
};

export default Users;
