import { isEmpty, orderBy } from 'lodash';

//export const get

export const getAllPlanActivitiesData = state => {
  const planActivities = state.entities.planActivitiesByPlan;
  const { data: planActivitiesData = [] } = planActivities;
  let ganttData = [];
  if (!isEmpty(planActivitiesData)) {
    ganttData = orderBy(
      planActivitiesData.map(f => {
        return {
          start: f.targetStartDate,
          end: f.targetEndDate,
          name: f.planActivityDescription,
          id: f.id,
          progress: 1,
          dependencies: [],
          sortSequence: f.sortSequence,
          colourHexCode: f.colourHexCode,
        };
      }),
      ['start', 'sortSequence'],
      ['asc', 'asc'],
    );
  }
  return ganttData;
};

export const getAllPlanActivities = state => {
  const allPlanActivities = state.entities.planActivitiesByPlan;
  const { data: allPlanActivitiesData = [] } = allPlanActivities;
  return allPlanActivitiesData;
};
