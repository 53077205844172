import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// eslint-disable
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import { format } from 'date-fns';
import omit from 'lodash/omit';
// import filter from 'lodash/filter';
import {
  getLookupVintage,
  getGrowerRegion,
  getGrowerSubRegion,
  getLkpMeasure,
  getLkpMeasureGroup,
  areLookupsLoading,
  getSamplesData,
  getIsLoadingBlockVintages,
  getSampleBlockVintageGroups,
  getSampleBlockVineyardsData,
  getLkpMeasureInstance,
  getBVNotInSampleSet,
} from '../../../redux/selectors';
import logger from '../../../utils/winstonLogger';
import {
  sagaActionTypes as Types,
  reducers,
  actionTypes,
  apiTypes,
  vineaDetails,
  dateFormat,
} from '../../../constants';
import { syncValidator } from '../../../utils/validator';
import {
  AddSampleRequestBlocksSchema,
  NewSamplesSetSchema,
} from '../validations';

export const useSamplesHook = () => {
  const dispatchAPI = useDispatch();
  const navigate = useNavigate();
  const { id: pathParamSampleSetId } = useParams();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [latestVintage, setLatestVintage] = useState({});
  const [latestVintageIdValue, setLatestVintageIdValue] = useState(0);
  const [sampleSetData, setSampleSetData] = useState({});
  const [lkpMeasure, setLkpMeasure] = useState([]);
  const [lkpMeasureInstance, setLkpMeasureInstance] = useState({});
  const [actionTriggered, setActionTriggered] = useState(false);
  const [errorInSubmit, setErrorInSubmit] = useState(false);

  /** selectors */
  const allLookupVintages = useSelector(getLookupVintage);
  // const lookupVintages = useSelector(getLookupVintage);
  const lkpGrowerRegion = useSelector(getGrowerRegion);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [selectedRegionId, setSelectedRegionId] = useState(0);
  const lkpGrowerSubRegion = useSelector(getGrowerSubRegion);
  const getLookupMeasure = useSelector(getLkpMeasure);
  const lkpMeasureGroup = useSelector(getLkpMeasureGroup);
  const isLoadingLookups = useSelector(areLookupsLoading);
  const sampleData = useSelector(getSamplesData);
  const sampleBlockVintages = useSelector(
    VineaNovaSelectors.getSampleBlockVintagesEntityData,
  );
  const blockVintagesLoading = useSelector(getIsLoadingBlockVintages);
  const blockVintagesGroups = useSelector(getSampleBlockVintageGroups);
  const blockVintagesVineyards = useSelector(getSampleBlockVineyardsData);
  const lookupMeasureInstance = useSelector(getLkpMeasureInstance);
  const sampleBVNotInSet = useSelector(getBVNotInSampleSet);
  const [warningInSubmit, setWarningInSubmit] = useState(false);
  const {
    isLoading: formWriteLoading,
    hasError: formWriteError,
    isLoaded: formWriteLoaded,
    data: formWriteData,
  } = useSelector(state => state.formWrite);
  const { basicSettings } = useSelector(state => state.userPreferences);
  const { data: searchFilterData = {} } = useSelector(
    state => state.sampleSetSearchFilter,
  );

  const sampleSets = useSelector(VineaNovaSelectors.getSampleSetsEntityData);

  const [addBlocksFormData, setAddBlocksFormData] = useState({
    recommendedSampleSize: '0',
    errors: {},
  });

  /** on initial load clean up data */
  useEffect(() => {
    dispatchAPI(VineaNovaActions.api.v1.sampleBlockVintages.get.cleardata());
  }, [dispatchAPI]);

  useEffect(() => {
    if (!isEmpty(allLookupVintages)) {
      const activeVintage = first(allLookupVintages.filter(f => f.isActive));
      const activeVintageID = get(activeVintage, 'id', 0);
      setLatestVintage(activeVintage);
      setLatestVintageIdValue(activeVintageID);
      logger.debug('vintage', activeVintage);
    }
  }, [allLookupVintages]);

  useEffect(() => {
    if (!isEmpty(lkpGrowerRegion)) {
      const selected = first(
        lkpGrowerRegion.filter(f => f.id === basicSettings.defaultRegionID),
      );
      const regionSelectedID = get(selected, 'id', 0);
      setSelectedRegion(selected);
      setSelectedRegionId(regionSelectedID);
      logger.debug('Regions', selected);
    }
  }, [lkpGrowerRegion]);

  useEffect(() => {
    setLkpMeasure(getLookupMeasure);
  }, [getLookupMeasure]);

  useEffect(() => {
    if (actionTriggered) {
      if (!formWriteLoading && formWriteLoaded) {
        if (formWriteError) {
          // set form submitting has errors;
          setErrorInSubmit(true);
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else {
          // const { id } = formWriteData;
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          if (pathParamSampleSetId) {
            // go back
            navigate(-1);
          } else {
            navigate('/samples/sampling', { replace: true });
          }
        }
        setActionTriggered(false);
        // setSeqToSave(null);
      }
    }
  }, [
    actionTriggered,
    formWriteLoading,
    formWriteLoaded,
    formWriteError,
    formWriteData,
    enqueueSnackbar,
    t,
    navigate,
    pathParamSampleSetId,
    latestVintageIdValue,
    selectedRegionId,
    searchFilterData,
    dispatchAPI,
  ]);

  // fetch specific block vintages;
  const handleOnFetchSampleBlockVintages = useCallback(
    ({ id, regionID, vintageID }) => {
      dispatchAPI(
        VineaNovaActions.api.v1.sampleBlockVintages.get.request({
          queryParams: {
            SampleSetID: id,
            RegionID: regionID,
            VintageID: vintageID,
          },
        }),
      );
    },
    [dispatchAPI],
  );

  // useEffect(() => {
  //   handleOnFetchSampleBlockVintages({ id: 1, regionID: 1, vintageID: 8 });
  // }, [handleOnFetchSampleBlockVintages]);
  useEffect(() => {
    setSampleSetData({ ...sampleSetData, regionId: selectedRegionId });
    const { id: latestVintageId } = latestVintage;
    handleOnFetchSampleBlockVintages({
      id: 0,
      regionID: selectedRegionId,
      vintageID: latestVintageId,
    });
  }, [selectedRegionId]);

  const handleOnChange = useCallback(
    evt => {
      const {
        target: { value, name },
      } = evt;
      const currentData = { ...sampleSetData, [name]: value };
      setSampleSetData(currentData);
      logger.debug('handleChange', { currentData });
      if (name === 'measureGroupId') {
        const measuresInstancesByGroupid = lookupMeasureInstance.filter(
          f => f.measureGroupID === value,
        );
        const measuresByGroupid = getLookupMeasure.filter(
          f => f.measureGroupID === value,
        );
        setLkpMeasure(measuresByGroupid);

        setLkpMeasureInstance(measuresInstancesByGroupid);
        const updatedSample = { ...currentData, measureId: null };
        setSampleSetData(updatedSample);
      }
      /** fetch latest block vintages based on region id */
      const { id: latestVintageId } = latestVintage;
      if (name === 'regionId' && value > 0) {
        handleOnFetchSampleBlockVintages({
          id: 0,
          regionID: value,
          vintageID: latestVintageId,
        });
      }

      if (name === 'measureId') {
        const getMeasureInstanceByMeasure = lookupMeasureInstance.find(
          f => f.id === currentData.measureId,
        );
        if (getMeasureInstanceByMeasure) {
          const {
            defaultSampleSize,
            id: measureInstanceId,
            isEditable,
          } = getMeasureInstanceByMeasure;
          const updateData = {
            ...currentData,
            defaultSampleSize,
            defaultSampleSizeFromMeasureInstance: defaultSampleSize,
            measureInstanceId,
            isEditable,
            vintageID: latestVintageId,
          };

          setSampleSetData(updateData);
        }
      }
    },
    [
      sampleSetData,
      setSampleSetData,
      lookupMeasureInstance,
      getLookupMeasure,
      setLkpMeasure,
      setLkpMeasureInstance,
      latestVintage,
      handleOnFetchSampleBlockVintages,
    ],
  );

  const handleOnBlur = () => {
    // continue validations
    const validationErrors = syncValidator(NewSamplesSetSchema)(sampleSetData);

    const { errors } = sampleSetData;

    if (!isEmpty(errors)) {
      const newValue = {
        ...sampleSetData,
        errors: validationErrors,
      };
      setSampleSetData(newValue);
    }
  };

  const handleOnSave = () => {
    const validationErrors = syncValidator(NewSamplesSetSchema)(sampleSetData);
    const { blockVintageIDs } = sampleSetData;
    if (!isEmpty(validationErrors)) {
      const newValue = {
        ...sampleSetData,
        errors: validationErrors,
      };
      setSampleSetData(newValue);
    } else if (
      isNull(blockVintageIDs) ||
      typeof blockVintageIDs === 'undefined'
    ) {
      setWarningInSubmit(true);
    } else {
      const dataToSubmit = {
        ...omit(sampleSetData, ['errors', 'isEditable', 'measureId']),
        defaultSampleSize: sampleSetData.defaultSampleSize,
        recommendedSampleSize: 0,
        createdDate: format(new Date(), dateFormat),
        createdBy: 'random-id',
        vintageID: latestVintageIdValue,
      };
      dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
      dispatchAPI({
        type: Types.FORM_SUBMIT,
        payload: {
          data: dataToSubmit,
          name: vineaDetails.samplesSet,
          methodType: apiTypes.POST,
        },
      });
      setActionTriggered(true);
    }
  };

  /** only use when adding sample blocks to a sample headers */
  const handleOnSaveBlocks = sampleInfo => {
    const validationErrors = syncValidator(AddSampleRequestBlocksSchema)(
      sampleInfo,
    );
    setAddBlocksFormData({ ...addBlocksFormData, errors: validationErrors });

    const { sampleSetId, recommendedSampleSize } = sampleInfo;
    const { blockVintageIDs } = sampleSetData;
    const dataToSumbit = {
      id: sampleSetId,
      recommendedSampleSize,
      blockVintageIDs,
    };
    if (!isEmpty(blockVintageIDs) && isEmpty(validationErrors)) {
      dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
      dispatchAPI({
        type: Types.FORM_SUBMIT,
        payload: {
          data: dataToSumbit,
          name: vineaDetails.postSampleHeaderBlocks,
          methodType: apiTypes.POST,
        },
      });
      setActionTriggered(true);
    } else if (isEmpty(validationErrors)) {
      setWarningInSubmit(true);
    }
  };

  const handleOnChangeAddBlocksForm = evt => {
    const {
      target: { name, value },
    } = evt;

    setAddBlocksFormData({
      ...addBlocksFormData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (pathParamSampleSetId) {
      const sampleSet = sampleSets.find(
        s => s.id === Number(pathParamSampleSetId),
      );
      const defaultSize = sampleSet?.defaultSampleSize || '0';
      setAddBlocksFormData({
        ...addBlocksFormData,
        recommendedSampleSize: defaultSize,
      });
    }
  }, [pathParamSampleSetId]);

  return {
    latestVintage,
    latestVintageIdValue,
    selectedRegion,
    selectedRegionId,
    lkpGrowerRegion,
    lkpGrowerSubRegion,
    lkpMeasureInstance,
    lkpMeasure,
    lkpMeasureGroup,
    allLookupVintages,
    handleOnChange,
    sampleSetData,
    isLoadingLookups,
    sampleData,
    handleOnFetchSampleBlockVintages,
    sampleBlockVintages,
    blockVintagesLoading,
    blockVintagesGroups,
    handleOnSave,
    errorInSubmit,
    sampleBVNotInSet,
    handleOnSaveBlocks,
    searchFilterData,
    handleOnBlur,
    warningInSubmit,
    setWarningInSubmit,
    addBlocksFormData,
    handleOnChangeAddBlocksForm,
  };
};
