import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import { Datepicker } from '../Datepicker';
import { FieldLabelInput } from '../TextField';
import { apiTypes, viewDateFormat } from '../../constants';

export const RelationshipComponent = ({ formdata = {}, onChange, name }) => {
  const { methodType } = formdata;
  const handleOnChange = event => {
    if (onChange) {
      onChange(event, formdata);
    }
  };

  return (
    <>
      {name === 'relatesTo' &&
        !isEmpty(formdata) &&
        methodType === apiTypes.PUT &&
        formdata.relationshipFrom}

      {name === 'parent' && methodType === apiTypes.PUT && (
        <>
          {formdata.direction === 'Owner' && formdata.dependentName}
          {formdata.direction === 'Dependent' && formdata.ownerName}
        </>
      )}
      {name === 'relationshipType' &&
        methodType === apiTypes.PUT &&
        formdata[name]}
      {name === 'additionalText' &&
        formdata.isAdditionalText &&
        formdata.additionalText}

      {name === 'relationshipName' && (
        <>
          {methodType === apiTypes.POST && (
            <FieldLabelInput
              autoComplete="off"
              id="relationshipName"
              name="relationshipName"
              size="small"
              inlineLabel
              value={formdata.relationshipName}
              onChange={handleOnChange}
            />
          )}
          {methodType === apiTypes.PUT && formdata[name]}
        </>
      )}
      {name === 'comment' && (
        <FieldLabelInput
          autoComplete="off"
          id="comment"
          name="comment"
          size="small"
          inlineLabel
          value={formdata.comment}
          onChange={handleOnChange}
        />
      )}
      {name === 'effectiveFromDate' && (
        <Datepicker
          disablePast={false}
          id="effectivefrom"
          inputFormat={viewDateFormat}
          placeholder="DD/MM/YYYY"
          size="small"
          value={formdata.effectiveFromDate}
          onChange={handleOnChange}
          name="effectiveFromDate"
          inputProps={{
            'data-testid': 'effectiveFromDate',
            'data-name': 'effectiveFromDate',
          }}
        />
      )}
      {name === 'effectiveToDate' && (
        <Datepicker
          disablePast={false}
          minDate={
            formdata.effectiveFromDate === null
              ? format(new Date(), viewDateFormat)
              : formdata.effectiveFromDate
          }
          id="effectiveto"
          inputFormat={viewDateFormat}
          placeholder="DD/MM/YYYY"
          variant="outlined"
          size="small"
          value={formdata.effectiveToDate}
          onChange={handleOnChange}
          name="effectiveToDate"
          inputProps={{
            'data-testid': 'effectiveToDate',
            'data-name': 'effectiveToDate',
          }}
        />
      )}
    </>
  );
};

RelationshipComponent.propTypes = {
  formdata: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    identityID: PropTypes.number,
    relatesTo: PropTypes.number,
    relationshipName: PropTypes.string,
    relationshipTypeID: PropTypes.number,
    relationshipFrom: PropTypes.string,
    relationshipType: PropTypes.string,
    ownerIdentityID: PropTypes.number,
    ownerName: PropTypes.string,
    dependentIdentityID: PropTypes.number,
    dependentName: PropTypes.string,
    comment: PropTypes.string,
    isDisplayOnPanel: PropTypes.bool,
    effectiveFromDate: PropTypes.string,
    effectiveToDate: PropTypes.string,
    isEditMode: PropTypes.bool,
    methodType: PropTypes.string,
    isActive: PropTypes.bool,
    dependent: PropTypes.string,
    direction: PropTypes.string,
    isAdditionalText: PropTypes.bool,
    additionalText: PropTypes.string,
    errors: PropTypes.string,
    ts: PropTypes.string,
  }),
  onChange: PropTypes.func,
  name: PropTypes.string,
};

RelationshipComponent.defaultProps = {
  formdata: {},
  onChange: f => f,
  name: '',
};
