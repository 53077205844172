import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
// import logger from '../../utils/winstonLogger';

const StyledDiv = styled('div')(({ theme }) => ({
  // flexGrow: 1,
  paddingTop: theme.spacing(2),
  overflow: 'hidden',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(16),
  },
}));

export const WithAppContainer = ({ children }) => {
  // logger.debug('WithAppContainer');
  return <StyledDiv data-testid="with-app-container">{children}</StyledDiv>;
};

WithAppContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
};
