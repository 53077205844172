import React from 'react';
import List from '@mui/material/List';
import { MuiListItemLink } from '../../components/Link';
// import logger from '../../utils/winstonLogger';
import { WithAppContainer } from '../common';

const VineyardSamplingContainer = () => {
  return (
    <WithAppContainer>
      <div data-testid="samplingcontainer-root">
        <List component="nav" aria-label="Sampling, Tracking">
          <MuiListItemLink to="/samples/sampling" text="Sampling" />
          <MuiListItemLink to="/samples/tracking" text="Tracking" />
        </List>
      </div>
    </WithAppContainer>
  );
};

VineyardSamplingContainer.propTypes = {};

export default VineyardSamplingContainer;
