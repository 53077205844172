/**
 * @module Sagas/LoginUser
 * @desc LoginUser
 */
import { put, call, select, takeLatest, all } from 'redux-saga/effects';
import { reducers, sagaActionTypes } from '../../constants';
import { userAccess } from '../../services/userAccess';
/**
 * Login User Access
 */
export function* loginUserAccess() {
  try {
    yield put({
      type: 'FETCHING',
      name: reducers.userAccess,
    });
    const accessTokenSelector = state => state.authentication.user.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const response = yield call(userAccess, accessToken);
    const updatedUserSettings = JSON.parse(response.userSettings);

    yield put({
      type: 'SUCCESS',
      payload: { ...response, userSettings: updatedUserSettings },
      name: reducers.userAccess,
    });
    yield put({
      type: 'SETTINGS_ADD',
      payload: { userSettings: updatedUserSettings },
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: reducers.userAccess,
    });
  }
}

export default function* root() {
  yield all([takeLatest(sagaActionTypes.USER_LOGIN, loginUserAccess)]);
}
