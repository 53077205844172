import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import { useTheme, styled } from '@mui/material/styles';
import {
  Paper,
  Stack,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { FieldLabelInput } from '../../../components/TextField';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';
import { VineaCommonGrid } from '../../../components/Grid';
import {
  IdentityTypeIds,
  commonGridHeaderNames,
  viewDateFormat,
} from '../../../constants';
import { VineaButton } from '../../../components/VineaButton';
import { SplitButton } from '../../../components/SplitButton';
import { Datepicker } from '../../../components/Datepicker';
import { formatDate } from '../../../constants/formatter';
//@ts-ignore
import { GreenCheckbox } from '../../../components/CheckBox';
import { DiscardDialog } from '../../../components/Dialog';
import { useJobBlockRowsHook } from '../hooks/useJobBlockRowsHook';
import { ManageJobBlockRowDrawer } from '../drawers/ManageJobBlockRowDrawer';

const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'left',
  flex: 1,
  margin: 10,
}));

const StyledCloseDiv = styled('div')(() => ({
  paddingLeft: 10,
}));

const StyledSelectDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  paddingLeft: 10,
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(3),
  width: 250,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
  display: 'flex',
}));

const ManageJobBlockRows = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { id: jobID, jobBlockID: jobBlockID } = useParams();
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);

  const {
    unitsColumnText,
    jobBlockRowsColumns,
    jobBlockDetails,
    updateJobBlockRowObject,
    selectedRowsValue,
    selectedUnitsValue,
    rowsHasChanges,
    isRowRangeEntered,
    jobBlockHasChanges,
    validationErrors,
    jobBlockRowsLoading,
    jobBlockRows,
    jobBlockRowDrawerOpen,
    handleChangeJobBlock,
    handleChangeJobBlockRow,
    handleOnClickCheckbox,
    handleOnRowCheckboxClick,
    handleOnToggleBulkRowSelection,
    handleOnToggleRowRange,
    handleOnSaveJobBlock,
    handleOnSaveJobBlockRow,
    handleOnCloseJobBlockRowDrawer,
    handleEditJobBlockRow,
  } = useJobBlockRowsHook(Number(jobBlockID));

  const handleCancel = () => {
    navigate(-1);
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  const handleOnClose = () => {
    if (rowsHasChanges || jobBlockHasChanges) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Stack>
        <Box
          flex={1}
          flexDirection="row"
          alignItems="flex-start"
          display="flex"
        >
          <Stack>
            <StyledFieldLabelInput
              autoComplete="off"
              id="vineyardName"
              name="vineyardName"
              label={t('Vineyard')}
              size="small"
              inlineLabel
              value={get(jobBlockDetails, 'vineyardName', '')}
              disabled={true}
              onChange={undefined}
              onBlur={undefined}
              classes={undefined}
              error={undefined}
              rows={undefined}
              sx={undefined}
            />
            <StyledFieldLabelInput
              autoComplete="off"
              id="blockName"
              name="blockName"
              label={t('Block')}
              size="small"
              inlineLabel
              value={get(jobBlockDetails, 'blockName', '')}
              disabled={true}
              onChange={undefined}
              onBlur={undefined}
              classes={undefined}
              error={undefined}
              rows={undefined}
              sx={undefined}
            />
            <StyledFormControl>
              <FormControlLabel
                label="Ready to Bill"
                control={
                  <GreenCheckbox
                    checked={get(jobBlockDetails, 'complete', false)}
                    onChange={handleOnClickCheckbox}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                      name: 'complete',
                    }}
                  />
                }
              />
            </StyledFormControl>
          </Stack>
          <Stack>
            <StyledFieldLabelInput
              autoComplete="off"
              id="description"
              name="description"
              label={t('Work Description')}
              size="small"
              inlineLabel
              value={get(jobBlockDetails, 'description', '')}
              onChange={handleChangeJobBlock}
              onBlur={undefined}
              classes={undefined}
              error={undefined}
              rows={4}
              multiline
              sx={undefined}
              disabled={false}
            />
          </Stack>
          <Stack>
            <FormControl sx={{ width: '250px', marginTop: 3, marginLeft: 3 }}>
              {/* @ts-ignore */}
              <Datepicker
                label={t('Start Date')}
                placeholder="DD/MM/YYYY"
                inputFormat={viewDateFormat}
                formatDate={formatDate}
                name="dateStarted"
                disablePast={false}
                value={get(jobBlockDetails, 'dateStarted', '')}
                fullWidth
                id={'dateStarted'}
                onChange={undefined}
                disabled={true}
              />
            </FormControl>

            <FormControl sx={{ width: '250px', marginTop: 3, marginLeft: 3 }}>
              {/* @ts-ignore */}
              <Datepicker
                label={t('Completed On')}
                placeholder="DD/MM/YYYY"
                inputFormat={viewDateFormat}
                formatDate={formatDate}
                name="dateCompleted"
                disablePast={false}
                value={get(jobBlockDetails, 'dateCompleted', '')}
                fullWidth
                id={'dateCompleted'}
                onChange={handleChangeJobBlock}
              />
              {!isEmpty(validationErrors?.dateCompleted) && (
                <FormHelperText id="component-error-text">
                  {validationErrors?.dateCompleted}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
          <Stack>
            <StyledFieldLabelInput
              autoComplete="off"
              id="selectedRows"
              name="selectedRows"
              label={t('Selected Rows')}
              size="small"
              inlineLabel
              value={selectedRowsValue || '0'}
              onChange={undefined}
              onBlur={undefined}
              classes={undefined}
              error={undefined}
              rows={1}
              sx={undefined}
              disabled={true}
            />
            <StyledFieldLabelInput
              autoComplete="off"
              id="selectedUnits"
              name="selectedUnits"
              label={t('Selected ' + unitsColumnText)}
              size="small"
              inlineLabel
              value={selectedUnitsValue || '0'}
              onChange={undefined}
              onBlur={undefined}
              classes={undefined}
              error={undefined}
              rows={1}
              sx={undefined}
              disabled={true}
            />
          </Stack>
        </Box>
        <Box
          flex={1}
          flexDirection="column"
          justifyContent="space-between"
          display="flex"
        >
          <StyledButtonControlDiv>
            <FieldLabelInput
              autoComplete="off"
              id="selectFromRow"
              name="selectFromRow"
              label={t('Select From Row')}
              size="small"
              inlineLabel
              value={get(jobBlockDetails, 'selectFromRow', '')}
              onChange={handleChangeJobBlock}
              onBlur={undefined}
              classes={undefined}
              error={undefined}
              rows={1}
              sx={{ marginLeft: theme.spacing(1) }}
              disabled={false}
            />
            <FieldLabelInput
              autoComplete="off"
              id="selectToRow"
              name="selectToRow"
              label={t('Select To Row')}
              size="small"
              inlineLabel
              value={get(jobBlockDetails, 'selectToRow', '')}
              onChange={handleChangeJobBlock}
              onBlur={undefined}
              classes={undefined}
              error={undefined}
              rows={1}
              sx={{ marginLeft: theme.spacing(2) }}
              disabled={false}
            />
            <StyledSelectDiv sx={{ marginLeft: theme.spacing(2) }}>
              <VineaButton
                color="secondary"
                onClick={handleOnToggleRowRange}
                variant="outlined"
                minWidth={150}
                disabled={!isRowRangeEntered}
              >
                {t('Toggle Range')}
              </VineaButton>
            </StyledSelectDiv>{' '}
            <StyledSelectDiv>
              <VineaButton
                color="secondary"
                onClick={() => handleOnToggleBulkRowSelection(true)}
                variant="outlined"
                minWidth={150}
              >
                {t('Select All')}
              </VineaButton>
            </StyledSelectDiv>
            <StyledSelectDiv>
              <VineaButton
                color="secondary"
                onClick={() => handleOnToggleBulkRowSelection(false)}
                variant="outlined"
                minWidth={150}
              >
                {t('Deselect All')}
              </VineaButton>
            </StyledSelectDiv>
          </StyledButtonControlDiv>

          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <VineaCommonGrid
              heading={t(commonGridHeaderNames.JOB_BLOCK_ROWS)}
              colHeaders={jobBlockRowsColumns}
              isLoading={jobBlockRowsLoading}
              tableData={jobBlockRows}
              hasManageOption={false}
              identityTypeId={IdentityTypeIds.JOB}
              onCheckboxClick={handleOnRowCheckboxClick}
              //@ts-ignore
              onRowClick={handleEditJobBlockRow}
              hasActiveToggle={false}
              tableHeight={500}
            />
          </ErrorBoundary>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <StyledButtonControlDiv>
            <SplitButton
              color="success"
              onClick={handleOnSaveJobBlock}
              variant="contained"
              minWidth={130}
              className={undefined}
              isExtraSmall={false}
              children={undefined}
              disabled={!(rowsHasChanges || jobBlockHasChanges)}
            />
            <StyledCloseDiv>
              <VineaButton
                color="secondary"
                onClick={handleOnClose}
                variant="contained"
                minWidth={150}
              >
                {t('Close')}
              </VineaButton>
            </StyledCloseDiv>
          </StyledButtonControlDiv>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={handleOnDialogClose}
            handleSaveChanges={handleOnSaveJobBlock}
            handleDiscardChanges={handleCancel}
          />
        </Box>
      </Stack>
      <ManageJobBlockRowDrawer
        isOpen={jobBlockRowDrawerOpen}
        handleOnClose={handleOnCloseJobBlockRowDrawer}
        handleOnSave={handleOnSaveJobBlockRow}
        handleOnChange={handleChangeJobBlockRow}
        jobBlockRowData={updateJobBlockRowObject}
        unitsColumnText={unitsColumnText}
        validationErrors={validationErrors}
      />
    </Paper>
  );
};

export default ManageJobBlockRows;
