import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
// import CardActionArea from '@mui/material/CardActionArea';
// import CardActions from '@mui/material/CardActions';
import DialogContent from '@mui/material/DialogContent';
// import CircularProgress from '@mui/material/CircularProgress';
// import Cropper from 'react-easy-crop';
// import Slider from '@mui/material/Slider';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
// import { Backdrop } from '@mui/material';
import { VineaButton } from '../VineaButton';

interface LogoChangeProps {
  onClose: () => {};
  open: boolean;
  imageDataUrl: string;
  onDelete: () => {};
  imageReplace: () => {};
}

export const LogoChange = ({
  onClose,
  open,
  imageDataUrl,
  onDelete,
  imageReplace,
}: LogoChangeProps) => {
  const { t } = useTranslation();
  // const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    // setCroppedImage(null);
    onClose();
  };

  const handleOnDelete = () => {
    onClose();
    onDelete();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        Update Image
        <CloseIcon
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 10,
          }}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '10px',
        }}
      >
        <Card
          sx={{
            padding: '10px',
          }}
        >
          <CardMedia
            image={imageDataUrl}
            title="Logo Image"
            sx={{
              height: 200,
            }}
          />
        </Card>
        <Box
          display="flex"
          marginTop="10px"
          alignItems="right"
          width="280px"
          justifyContent="space-between"
          marginLeft="175px"
        >
          <VineaButton
            color="secondary"
            variant="body1"
            data-testid="idCancel"
            onClick={handleClose}
          >
            {t('Cancel')}
          </VineaButton>
          <VineaButton
            color="secondary"
            variant="contained"
            data-testid="idReplace"
            onClick={imageReplace}
          >
            {t('Replace')}
          </VineaButton>
          <VineaButton
            color="error"
            variant="contained"
            data-testid="idDelete"
            onClick={handleOnDelete}
          >
            {t('Delete')}
          </VineaButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LogoChange;
