import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { CircularProgress, FormControl, FormHelperText } from '@mui/material';
import { isNull, isEmpty } from 'lodash';
import { VineaTextField } from '../TextField';

type AutoCompleteOption = {
  key: string | number;
  id: number;
  value: string;
};

type VineaAutoCompleteProps = {
  value?: number;
  onChange?: any;
  name?: string;
  inlineLabel?: boolean;
  helperText?: string;
  error?: boolean;
  options: AutoCompleteOption[];
  id?: string;
  onBlur?: any;
  disabled?: boolean;
  formControlMargin?: 'dense' | 'normal' | 'none';
  className?:
    | 'flexRow'
    | 'flexColumn'
    | 'flexItemTitle'
    | 'flexItemContent'
    | 'formControlLabel'
    | 'error'
    | 'select'
    | 'option'
    | 'fieldControl';
  placeholder?: string;
  inputProps: {
    name: string;
    readOnly?: boolean;
  };
  loading?: boolean;
  label?: string;
  labelVariant: string;
  fullWidth: boolean;
  // eslint-disable-next-line no-undef
  inputRef?: React.RefObject<HTMLInputElement>;
};

const StyledAutoComplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
    width: '100%',
  },
  '& .MuiAutocomplete-option': {
    // Hover with light-grey
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
      borderColor: 'transparent',
    },
    // Selected has dark-grey
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.action.selected,
      borderColor: 'transparent',
    },
  },
}));

export default function VineaAutoComplete({
  id,
  value,
  options,
  onChange,
  label,
  onBlur,
  name,
  className,
  placeholder,
  loading,
  inlineLabel,
  error,
  formControlMargin,
  helperText,
  disabled,
  inputProps,
  fullWidth,
  inputRef,
  ...restProps
}: VineaAutoCompleteProps) {
  const ref0 = useRef();

  let autoCompleteInputValue: AutoCompleteOption | null = null;
  if (Array.isArray(options)) {
    autoCompleteInputValue = options.find(f => f.id === value) || null;
  }
  const handleChange = (e: React.ChangeEvent<{}>, newVal: {} | any) => {
    const event = {
      target: {
        name: inputProps.name,
        value: isNull(newVal) ? null : newVal?.id,
      },
    };
    onChange(event);
  };

  const getComputedOptions = (incomingOptions: AutoCompleteOption[]) => {
    const placeHolderOptions = [{ id: -999, key: '-999', value: 'select' }];
    if (isEmpty(incomingOptions) || !Array.isArray(incomingOptions)) {
      return placeHolderOptions;
    }
    return incomingOptions;
  };

  const memoizedOptions: AutoCompleteOption[] = React.useMemo(
    () => getComputedOptions(options),
    [options],
  );

  if (inputProps?.readOnly) {
    return (
      <VineaTextField
        label={label}
        value={autoCompleteInputValue?.value || ''}
        inputProps={{ readOnly: true }}
        {...restProps}
      />
    );
  }

  return (
    <>
      <FormControl
        variant="outlined"
        margin={formControlMargin}
        disabled={disabled}
        fullWidth={fullWidth}
        error={error}
      >
        <StyledAutoComplete
          id={`auto-complete ${id}`}
          ref={ref0}
          value={autoCompleteInputValue}
          disabled={disabled}
          options={memoizedOptions || []}
          // @ts-ignore
          getOptionLabel={(option: AutoCompleteOption) => option?.value || ''}
          loading={loading}
          onChange={handleChange}
          renderInput={params => (
            <VineaTextField
              {...params}
              onBlur={onBlur}
              placeholder={placeholder}
              name={name}
              label={label}
              inputRef={inputRef}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                readOnly: inputProps?.readOnly,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          // @ts-ignore
          renderOption={(props, option: AutoCompleteOption, { inputValue }) => {
            const matches = match(option.value, inputValue);
            const parts = parse(option.value, matches);
            return (
              <li {...props} key={`${option.value}-${option.id}`}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
          size="small"
          {...restProps}
        />
        <FormHelperText
          sx={{
            '&.MuiFormHelperText-root': {
              // color: theme.palette.error.main,
            },
            marginLeft: 0,
          }}
        >
          {helperText}
        </FormHelperText>
        {error && (
          <FormHelperText
            id="component-error-text"
            sx={{
              '&.MuiFormHelperText-root': {
                // color: theme.palette.error.main,
              },
              marginLeft: 0,
            }}
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
}

// VineaAutoComplete.propTypes = {
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   onChange: PropTypes.func,
//   name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   inlineLabel: PropTypes.bool,
//   helperText: PropTypes.string.isRequired,
//   error: PropTypes.bool.isRequired,
//   options: PropTypes.arrayOf(
//     PropTypes.shape({
//       key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//       id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//       value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//     }),
//   ),
//   id: PropTypes.string,
//   onBlur: PropTypes.func,
//   disabled: PropTypes.bool,
//   displayLabel: PropTypes.bool,
//   formControlMargin: PropTypes.oneOf(['dense', 'normal', 'none']),
//   className: PropTypes.string,
//   placeholder: PropTypes.string,
//   inputProps: PropTypes.shape({
//     name: PropTypes.string,
//   }).isRequired,
//   loading: PropTypes.bool,
//   label: PropTypes.string,
// };

const defaultProps = {
  value: '',
  onChange: () => {},
  name: '',
  label: '',
  inlineLabel: false,
  onBlur: () => {},
  disabled: false,
  formControlMargin: 'dense',
  className: '',
  placeholder: '',
  id: '',
  loading: false,
  helperText: '',
  error: false,
  inputRef: undefined,
};

VineaAutoComplete.defaultProps = defaultProps;
