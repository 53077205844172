/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { isEmpty, find, filter, has, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import {
  Alert,
  Collapse,
  FormControlLabel,
  FormHelperText,
  IconButton,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { useJobActivityHook } from './hooks/useJobActivityHook';
import { FieldLabelInput } from '../../components/TextField';
import { VineaButton } from '../../components/VineaButton';
import { VineaAutoComplete } from '../../components/ComboBox';
//@ts-ignore
import { GreenCheckbox } from '../../components/CheckBox/Checkbox';
import {
  GridNoRowMessage,
  GridWithFilter,
  RenderDataGridHeader,
  GridValueDateFormatter,
  GridValuePriceFormatter,
  GridValuePercentageFormatter,
} from '../../components/Grid';
import { SplitButton } from '../../components/SplitButton';
import { JobActivityAndRateType } from './interfaces/jobInterfaces';
import { useJobActivityRatesHook } from './hooks/useJobActivityRateHook';
import { EditJobActivityRateDrawer } from './EditJobActivityRateDrawer';
import { DeleteDialog } from '../../components/Dialog';

export const dataGridNotesColumn = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    hide: true,
    hideable: true,
  },
  {
    field: 'effectiveFrom',
    headerName: 'Active From',
    hideable: false,
    flex: 1,
    renderHeader: RenderDataGridHeader,
    valueFormatter: ({ value }: { value: any }) =>
      GridValueDateFormatter(value),
  },
  {
    field: 'effectiveTo',
    headerName: 'Active To',
    hideable: false,
    type: 'string',
    flex: 1,
    renderHeader: RenderDataGridHeader,
    valueFormatter: ({ value }: { value: any }) =>
      GridValueDateFormatter(value),
  },
  {
    field: 'contractedRate',
    headerName: 'Contracted Rate',
    hideable: false,
    type: 'number',
    flex: 1,
    valueFormatter: ({ value }: { value: any }) =>
      GridValuePriceFormatter(value),
  },
  {
    field: 'payrollRate',
    headerName: 'Payroll Rate',
    hideable: false,
    type: 'number',
    flex: 1,
    valueFormatter: ({ value }: { value: any }) =>
      GridValuePriceFormatter(value),
  },
  {
    field: 'gstRate',
    headerName: 'GST Rate',
    hideable: false,
    type: 'number',
    flex: 1,
    valueFormatter: ({ value }: { value: any }) =>
      GridValuePercentageFormatter(value),
  },
];

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: 300,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  root: {
    marginTop: theme.spacing(1),
    minWidth: 300,
  },
}));

export const ManageJobActivity = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, jobactivityid } = useParams();

  const [jobActivityRateData, setJobActivityRateData] = useState<
    JobActivityAndRateType | any
  >([]);

  const jobID = Number(id);
  const handleOnClose = () => {};

  const {
    jobActivityRateObject: data,
    lkpIdentityActivity,
    setJobActivityRateObject,
    validationErrors,
    rowSpecialAttributeOptions,
    deleteDialogOpen,
    warningInSubmit,
    showWarning,
    deleteActivityConfirmation,
    setShowWarning,
    setDeleteDialogOpen,
    handleOnSave,
    handleOnChange,
    handleOnChangeCheckbox,
    handleOnDeleteActivity,
    setIsAddDrawer,
  } = useJobActivityHook(jobID, false, handleOnClose);

  const {
    jobActivityRate,
    jobActivityRateLoading,
    isRateDrawerOpen,
    selectedActivityRate,
    isNewRate,
    activityRateValidationErrors,
    onRowClick,
    handleOnCloseDrawer,
    handleOnDeleteActivityRate,
    handleOnSaveActivityRate,
    handleOnChangeActivityRateCheckbox,
    handleOnChangeActivityRate,
    onAddRateButtonClick,
  } = useJobActivityRatesHook(jobID, Number(jobactivityid));

  const ratesGridData = {
    columns: dataGridNotesColumn,
    rows: jobActivityRateData,
  };

  React.useEffect(() => {
    if (!isEmpty(jobActivityRate)) {
      const findOne = find(jobActivityRate, {
        jobActivityID: Number(jobactivityid),
      });

      const updatedData = { ...data };

      Object.keys(data).forEach(key => {
        if (data[key] === null) {
          updatedData[key] = get(findOne, key);
        }
      });

      setJobActivityRateObject(updatedData);
      setIsAddDrawer(false);
    }
  }, [setIsAddDrawer, jobActivityRate, jobactivityid]);

  React.useEffect(() => {
    if (!isEmpty(jobActivityRate)) {
      // @ts-ignore
      const findData = filter(
        jobActivityRate,
        // @ts-ignore
        f => f.jobActivityID === Number(jobactivityid) && f.id !== 0,
      );
      // @ts-ignore
      setJobActivityRateData(findData);
    }
  }, [jobActivityRate, jobactivityid]);

  const NoRowsOverlayMessage = () => (
    <Box mt={10} data-testid="blockgrid-norowmessage">
      <GridNoRowMessage message="Sorry, there were no records found." />
    </Box>
  );

  const handleClose = () => {
    navigate(-1);
  };

  const onDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleOnConfirmDelete = () => {
    handleOnDeleteActivity();
  };

  return (
    <Paper
      elevation={0}
      data-testid="manageplan-detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      {showWarning && (
        <Collapse in={showWarning}>
          <Alert
            variant="standard"
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {warningInSubmit}
          </Alert>
        </Collapse>
      )}
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12} sm={3}>
          <Box
            my={2}
            sx={{ padding: 4 }}
            display="flex"
            flexDirection="column"
            maxWidth={300}
          >
            <StyledFormControl data-testid="createjobactivitydraweractivityid">
              <VineaAutoComplete
                value={data?.activityID}
                disabled={data?.isDefault}
                onChange={handleOnChange}
                options={lkpIdentityActivity || []}
                labelVariant="body1"
                inlineLabel={false}
                id="activityID"
                name={t('activityID')}
                label={t('Code')}
                inputProps={{
                  name: 'activityID',
                }}
                fullWidth={false}
              />
            </StyledFormControl>
            <StyledFormControl data-testid="rowAttributeID">
              <VineaAutoComplete
                value={data?.rowAttributeID}
                onChange={handleOnChange}
                options={rowSpecialAttributeOptions}
                labelVariant="body1"
                inlineLabel={false}
                id="rowAttributeID"
                name={t('rowAttributeID')}
                label={t('Related Row Attribute')}
                inputProps={{
                  name: 'rowAttributeID',
                }}
                fullWidth={false}
              />
            </StyledFormControl>
            <StyledFormControl data-testid="createjobactivitydrawerjobactivitydesc">
              {/* @ts-ignore */}
              <StyledFieldLabelInput
                autoComplete="off"
                id="jobActivityDescription"
                name="jobActivityDescription"
                label={t('Description')}
                inlineLabel
                value={data?.jobActivityDescription}
                onChange={handleOnChange}
                fullWidth
                onBlur={undefined}
                classes={undefined}
                rows={4}
                sx={undefined}
                disabled={undefined}
                multiline
                rowsMax={4}
              />
            </StyledFormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box
            my={2}
            sx={{ padding: 4 }}
            display="flex"
            flexDirection="column"
            maxWidth={300}
          >
            <StyledFormControl error={validationErrors?.isDefault}>
              <FormControlLabel
                label="Default Activity"
                control={
                  <GreenCheckbox
                    checked={data?.isDefault}
                    onChange={handleOnChangeCheckbox}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                      name: 'isDefault',
                    }}
                  />
                }
              />
              {validationErrors?.isDefault && (
                <FormHelperText id="component-error-text">
                  {validationErrors?.isDefault}
                </FormHelperText>
              )}
            </StyledFormControl>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <></>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        data-testid="managejobactivity-grid"
        minHeight={300}
      >
        <Grid item xs={12} data-testid="managejobactivity-grid-grid">
          <Grid
            container
            spacing={2}
            data-testid="managejobactivity-grid-grid-grid"
            minHeight={300}
          >
            <Box
              sx={{ padding: 4 }}
              width="100%"
              minHeight={300}
              data-testid="managejobactivity-grid-grid-grid-box"
            >
              {/** @ts-ignore */}
              <GridWithFilter
                data={ratesGridData}
                onRowClick={onRowClick}
                rowHeight={30}
                headerHeight={56}
                loading={jobActivityRateLoading}
                noRowsOverlay={NoRowsOverlayMessage}
                actionType="xyz"
                hasSearch={false}
                advancedSearch={false}
                hasButton={true}
                onButtonClick={onAddRateButtonClick}
                buttonText={'Add Rate'}
                resetPagination={jobActivityRateLoading}
                // gridColumnState={gridColumnState}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      id: false,
                    },
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
          }}
        >
          <Box mx={2} mt={1}>
            <SplitButton
              color="success"
              onClick={handleOnSave}
              variant="contained"
              minWidth={130}
              disabled={false}
              data-testid="btnsuccess"
              className={undefined}
              isExtraSmall={false}
            />
          </Box>
          <Box mx={2} mt={1}>
            <VineaButton
              color="error"
              onClick={onDelete}
              variant="contained"
              minWidth={150}
              disabled={!data?.canDeleteActivity}
            >
              {t('Delete')}
            </VineaButton>
          </Box>

          <Box mx={2} mt={1}>
            <VineaButton
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              {t('Close')}
            </VineaButton>
          </Box>
        </Grid>
      </Grid>
      <DeleteDialog
        onClose={() => setDeleteDialogOpen(false)}
        dialogHeader={t(`Delete Job Activity`)}
        open={deleteDialogOpen}
        dialogActionTrigger={handleOnConfirmDelete}
        dialogContent={deleteActivityConfirmation}
        rowId={0}
      />
      <EditJobActivityRateDrawer
        isOpen={isRateDrawerOpen}
        isNew={isNewRate}
        handleOnClose={handleOnCloseDrawer}
        handleOnSave={handleOnSaveActivityRate}
        handleOnChange={handleOnChangeActivityRate}
        handleOnChangeCheckbox={handleOnChangeActivityRateCheckbox}
        handleOnDelete={handleOnDeleteActivityRate}
        jobActivityRateData={selectedActivityRate}
        validationErrors={activityRateValidationErrors}
      />
    </Paper>
  );
};
