import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { some } from 'lodash';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { PAGE_LOOKSUPS } from '../constants';

function LookupsHook(lookup, id = null) {
  const dispatchAPI = useDispatch();
  const [isReady, setIsReady] = React.useState(false);
  const { isLoading: contactLoading, isLoaded: contactLoaded } = useSelector(
    VineaNovaSelectors.getlookupContactMethodEntityMeta,
  );
  const { isLoading: vintageLoading, isLoaded: vintageLoaded } = useSelector(
    VineaNovaSelectors.getlookupVintageEntityMeta,
  );
  const { isLoading: roleLoading, isLoaded: roleLoaded } = useSelector(
    VineaNovaSelectors.getlookupRoleTypeEntityMeta,
  );
  const { isLoading: classficationLoading, isLoaded: classificationLoaded } =
    useSelector(VineaNovaSelectors.getlookupClassificationEntityMeta);

  const { isLoading: siteTypeLoading, isLoaded: siteTypeLoaded } =
    useSelector(VineaNovaSelectors.getlookupSiteTypeEntityMeta);

  const { isLoading: blocksLoading, isLoaded: blockLoaded } = useSelector(
    VineaNovaSelectors.getlookupBlockVintageStatusEntityMeta,
  );
  const { isLoading: measureLoading, isLoaded: measureLoaded } = useSelector(
    VineaNovaSelectors.getlookupMeasureEntityMeta,
  );
  const { isLoading: consumableLoading, isLoaded: consumableLoaded } =
    useSelector(VineaNovaSelectors.getlookupConsumableTypeEntityMeta);
  const { isLoading: vehicleTypeLoading, isLoaded: vehicleTypeLoaded } =
    useSelector(VineaNovaSelectors.getlookupVehicleTypeEntityMeta);
  const { isLoading: vehicleMakeLoading, isLoaded: vehicleMakeLoaded } =
    useSelector(VineaNovaSelectors.getlookupVehicleMakeEntityMeta);
  const { isLoading: sensorStatusLoading, isLoaded: sensorStatusLoaded } =
    useSelector(VineaNovaSelectors.getlookupSensorStatusEntityMeta);
  const { isLoading: geometryLoading, isLoaded: geometryLoaded } = useSelector(
    VineaNovaSelectors.getVineyardGeometryEntityMeta,
  );
  const { isLoading: businessUnitLoading, isLoaded: businessUnitLoaded } =
    useSelector(VineaNovaSelectors.getlookupBusinessUnitEntityMeta);
  const { isLoading: jobStatusLoading, isLoaded: jobStatusLoaded } =
    useSelector(VineaNovaSelectors.getlookupJobStatusEntityMeta);
  const {
    isLoading: RowSpecialAttributeLoading,
    isLoaded: RowSpecialAttributeLoaded,
  } = useSelector(VineaNovaSelectors.getlookupRowSpecialAttributeEntityMeta);
  const { isLoading: SystemDataLoading, isLoaded: SystemDataLoaded } =
    useSelector(VineaNovaSelectors.getlookupSystemDataEntityMeta);
  const {
    isLoading: supplyContractTypeLoading,
    isLoaded: supplyContractTypeLoaded,
  } = useSelector(VineaNovaSelectors.getlookupSupplyContractTypeEntityMeta);
  const { isLoading: dataProviderLoading, isLoaded: dataProviderLoaded } =
    useSelector(VineaNovaSelectors.getlookupDataProviderEntityMeta);
  const { isLoading: activityLoading, isLoaded: activityLoaded } = useSelector(
    VineaNovaSelectors.getlookupActivityEntityMeta,
  );
  const { isLoading: activityGroupLoading, isLoaded: activityGroupLoaded } =
    useSelector(VineaNovaSelectors.getlookupActivityGroupEntityMeta);
  const { isLoading: planStatusLoading, isLoaded: planStatusLoaded } =
    useSelector(VineaNovaSelectors.getlookupPlanStatusEntityMeta);
  const { isLoading: planActivitiesLoading, isLoaded: planActivitiesLoaded } =
    useSelector(VineaNovaSelectors.getPlanActivitiesByPlanEntityMeta);

  const { isLoading: identitiesLoading, isLoaded: identitiesLoaded } =
    useSelector(VineaNovaSelectors.getSearchIdentityEntityMeta);

  const {
    isLoading: displayAttributeLoading,
    isLoaded: displayAttributeLoaded,
  } = useSelector(VineaNovaSelectors.getlookupDisplayAttributeEntityMeta);

  const { isLoading: growingRegionLoading, isLoaded: growingRegionLoaded } =
    useSelector(VineaNovaSelectors.getlookupGrowingRegionEntityMeta);

  const {
    isLoading: growingSubRegionLoading,
    isLoaded: growingSubRegionLoaded,
  } = useSelector(VineaNovaSelectors.getlookupGrowingSubRegionEntityMeta);

  const {
    isLoading: dashboardPageFiltersLoading,
    isLoaded: dashboardPageFiltersLoaded,
  } = useSelector(VineaNovaSelectors.getlookupDashboardPageFiltersEntityMeta);

  const identitiesData = useSelector(
    VineaNovaSelectors.getSearchIdentityEntityData,
  );
  React.useEffect(() => {
    if (
      !blocksLoading &&
      !classficationLoading &&
      !roleLoading &&
      !measureLoading &&
      !contactLoading &&
      !consumableLoading &&
      !vehicleTypeLoading &&
      !vehicleMakeLoading &&
      !sensorStatusLoading &&
      !geometryLoading &&
      !businessUnitLoading &&
      !supplyContractTypeLoading &&
      !jobStatusLoading &&
      !RowSpecialAttributeLoading &&
      !SystemDataLoading &&
      !dataProviderLoading &&
      !activityLoading &&
      !activityGroupLoading &&
      !planStatusLoading &&
      !planActivitiesLoading &&
      !displayAttributeLoading &&
      !growingRegionLoading &&
      !growingSubRegionLoading &&
      !identitiesLoading &&
      !vintageLoading &&
      !dashboardPageFiltersLoading &&
      !siteTypeLoading
    ) {
      const allLoders = lookup.map(element => {
        if (element === PAGE_LOOKSUPS.DASHBOARD_LOOKUPS) {
          return contactLoaded;
        }
        if (element === PAGE_LOOKSUPS.IDENTITY_LOOKUPS) {
          return classificationLoaded && siteTypeLoaded;
        }
        if (element === PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS) {
          return contactLoaded && roleLoaded;
        }
        if (element === PAGE_LOOKSUPS.BLOCK_LOOKUPS) {
          return blockLoaded;
        }
        if (element === PAGE_LOOKSUPS.SAMPLING_TRACKING_LOOKUPS) {
          return measureLoaded;
        }
        if (element === PAGE_LOOKSUPS.CONSUMABLE_LOOKUPS) {
          return consumableLoaded;
        }
        if (element === PAGE_LOOKSUPS.VEHICLE_LOOKUPS) {
          return vehicleTypeLoaded && vehicleMakeLoaded;
        }
        if (element === PAGE_LOOKSUPS.SENSORS_LOOKUPS) {
          return sensorStatusLoaded;
        }
        if (element === PAGE_LOOKSUPS.BLOCK_MAPS_LOOKUPS) {
          return geometryLoaded;
        }
        if (element === PAGE_LOOKSUPS.USER_LOOKUPS) {
          return businessUnitLoaded;
        }
        if (element === PAGE_LOOKSUPS.DATA_CONNECTION_LOOKUPS) {
          return dataProviderLoaded;
        }
        if (element === PAGE_LOOKSUPS.ACTIVITY_PROGRESS_LOOKUPS) {
          return activityLoaded && vintageLoaded;
        }
        if (element === PAGE_LOOKSUPS.IDENTITY_ACTIVITY_LOOKUPS) {
          return activityGroupLoaded;
        }
        if (element === PAGE_LOOKSUPS.PLANS_LOOKUPS) {
          return planStatusLoaded;
        }
        if (element === PAGE_LOOKSUPS.PLAN_ACTIVITIES_LOOKUPS) {
          return planActivitiesLoaded;
        }
        if (element === PAGE_LOOKSUPS.VINEYARD_EXPLORER_LOOKUPS) {
          return (
            some(identitiesData, 'plantedArea') &&
            displayAttributeLoaded &&
            growingRegionLoaded &&
            growingSubRegionLoaded
          );
        }
        if (element === PAGE_LOOKSUPS.USER_SETTINGS_LOOKUPS) {
          return some(identitiesData, 'plantedArea');
        }
        if (element === PAGE_LOOKSUPS.JOB_LOOKUPS) {
          return (
            jobStatusLoaded && RowSpecialAttributeLoaded && SystemDataLoaded
          );
        }
        if (element === PAGE_LOOKSUPS.DASHBOARD_LOOKUPS) {
          return dashboardPageFiltersLoaded;
        }
        return false;
      });

      const notLoaded = allLoders.filter(f => f !== true).length;

      if (notLoaded > 0) {
        lookup.forEach(each => {
          if (each === PAGE_LOOKSUPS.DASHBOARD_LOOKUPS) {
            dispatchAPI({ type: each, name: each });
          } else if (each === PAGE_LOOKSUPS.IDENTITY_LOOKUPS) {
            dispatchAPI({ type: each, name: each });
          } else if (each === PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS) {
            dispatchAPI({ type: each, name: each });
          } else if (each === PAGE_LOOKSUPS.BLOCK_LOOKUPS) {
            dispatchAPI({ type: each, name: each });
          } else if (each === PAGE_LOOKSUPS.SAMPLING_TRACKING_LOOKUPS) {
            dispatchAPI({ type: each, name: each });
          } else if (each === PAGE_LOOKSUPS.CONSUMABLE_LOOKUPS) {
            dispatchAPI({ type: each, name: each });
          } else if (each === PAGE_LOOKSUPS.VEHICLE_LOOKUPS) {
            dispatchAPI({ type: each, name: each });
          } else if (each === PAGE_LOOKSUPS.SENSORS_LOOKUPS) {
            dispatchAPI({ type: each, name: each });
          } else if (each === PAGE_LOOKSUPS.BLOCK_MAPS_LOOKUPS) {
            dispatchAPI({ type: each, payload: { name: each, id } });
          } else if (each === PAGE_LOOKSUPS.USER_LOOKUPS) {
            dispatchAPI({ type: each, payload: { name: each, id } });
          } else if (each === PAGE_LOOKSUPS.DATA_CONNECTION_LOOKUPS) {
            dispatchAPI({ type: each, payload: each });
          } else if (each === PAGE_LOOKSUPS.ACTIVITY_PROGRESS_LOOKUPS) {
            dispatchAPI({ type: each, payload: each });
          } else if (each === PAGE_LOOKSUPS.IDENTITY_ACTIVITY_LOOKUPS) {
            dispatchAPI({ type: each, payload: each });
          } else if (each === PAGE_LOOKSUPS.PLANS_LOOKUPS) {
            dispatchAPI({ type: each, payload: each });
          } else if (each === PAGE_LOOKSUPS.PLAN_ACTIVITIES_LOOKUPS) {
            dispatchAPI({ type: each, payload: each });
          } else if (each === PAGE_LOOKSUPS.VINEYARD_EXPLORER_LOOKUPS) {
            dispatchAPI({ type: each, payload: each });
          } else if (each === PAGE_LOOKSUPS.USER_SETTINGS_LOOKUPS) {
            dispatchAPI({ type: each, payload: each });
          } else if (each === PAGE_LOOKSUPS.JOB_LOOKUPS) {
            dispatchAPI({ type: each, payload: each });
          } else if (each === PAGE_LOOKSUPS.DASHBOARD_LOOKUPS) {
            dispatchAPI({ type: each, payload: each });
          }
        });
      } else {
        setIsReady(true);
      }
    }
  }, [
    contactLoading,
    measureLoading,
    classficationLoading,
    blocksLoading,
    contactLoaded,
    measureLoaded,
    classificationLoaded,
    blockLoaded,
    geometryLoading,
    dispatchAPI,
    lookup,
    consumableLoading,
    vehicleTypeLoading,
    vehicleMakeLoading,
    sensorStatusLoading,
    consumableLoaded,
    vehicleTypeLoaded,
    sensorStatusLoaded,
    geometryLoaded,
    id,
    businessUnitLoading,
    businessUnitLoaded,
    supplyContractTypeLoading,
    supplyContractTypeLoaded,
    dataProviderLoading,
    dataProviderLoaded,
    activityLoading,
    activityLoaded,
    roleLoading,
    roleLoaded,
    activityGroupLoading,
    activityGroupLoaded,
    planStatusLoaded,
    planStatusLoading,
    planActivitiesLoaded,
    planActivitiesLoading,
    identitiesLoaded,
    identitiesLoading,
    jobStatusLoading,
    RowSpecialAttributeLoaded,
    SystemDataLoaded,
    vintageLoaded,
    dashboardPageFiltersLoaded,
    dashboardPageFiltersLoading,
    siteTypeLoaded
  ]);

  return {
    isReady,
  };
}
export default LookupsHook;
