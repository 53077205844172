/**
 * @module Sagas/UserSettingsUpdate
 * @desc LoginUser
 */
import { isEmpty, has } from 'lodash';
import { put, call, select, takeLatest, all, delay } from 'redux-saga/effects';
import { format } from 'date-fns';
import { dateTimeFormat, sagaActionTypes } from '../../constants';
import { userPreferences } from '../../services/userPreferences';
import { getUserPreferences } from '../selectors';
/**
 * User Settings Update
 */
const today = new Date();
const dateValue = format(today, dateTimeFormat);

const defaultSettingsPreferences = {
  basicSettings: {
    landingPage: 1,
    filterPanelCollapsed: false,
    language: 'en',
    defaultRegionID: 1,
    defaultSubRegionID: 0,
    defaultVineyardID: 0,
    businessUnitID: 1,
    searchFilterBusinessUnitID: null,
    updatedAt: dateValue,
  },
  personDataGrid: {
    name: true,
    phone: true,
    email: true,
    address: true,
    businessUnit: true,
    knownAs: false,
  },
  supplyContractDataGrid: {
    name: true,
    purchasingParty: true,
    supplyingParty: true,
    supplyContractType: true,
  },
  vineyardDataGrid: {
    name: true,
    siteType: false,
    numberOfBlocks: false,
    totalArea: false,
    plantedArea: false,
    phone: true,
    primaryContact: true,
    email: true,
    region: false,
    businessUnit: false,
    address: true,
    knownAs: false,
  },
  siteDataGrid: {
    name: true,
    siteType: true,
    numberOfBlocks: false,
    totalArea: false,
    plantedArea: false,
    phone: true,
    primaryContact: true,
    email: true,
    region: false,
    businessUnit: false,
    address: true,
    knownAs: false,
  },
  organisationDataGrid: {
    name: true,
    roles: false,
    phone: false,
    email: true,
    subRegion: false,
    businessUnit: false,
    address: false,
    knownAs: true,
  },
  serviceProviderDataGrid: {
    name: true,
    isPrimary: false,
    phone: false,
    email: false,
    address: false,
    businessUnit: true,
    payrollSystem: true,
    knownAs: true,
    subRegion: false,
  },
  jobDataGrid: {
    jobID: true,
    jobName: true,
    contractType: true,
    status: true,
    location: true,
    payer: true,
    startDate: true,
    endDate: true,
  },
  jobSiteDataGrid: {
    jobID: true,
    jobName: true,
    siteType: true,
    jobType: true,
    status: true,
    location: true,
    payer: true,
    startDate: true,
    endDate: true,
  },
  blocksDataGrid: {
    blockFullName: true,
    varietyName: true,
    plantedAreaHa: true,
    Spacing: true,
    latestVintageYear: true,
    startEndRow: true,
    blockStatus: true,
    varietyClone: false,
    rootStock: false,
    plantedVines: true,
    formTo: false,
    version: false,
    datePlantedFormat: false,
    id: false,
  },
  samplesDataGrid: {
    sampleSet: true,
    createdBy: true,
    samples: true,
    unassigned: true,
    active: true,
    complete: true,
    closed: true,
    id: false,
    sampleSetNote: false,
    region: false,
    starting: false,
  },
  trackingDataGrid: {
    vintage: {
      measureDate: true,
      measure: true,
      measureValue: true,
      note: true,
      noteImage: true,
    },
    phenology: {
      phenologyMeasureDate: true,
      measure: true,
      measureValue: true,
      note: true,
      noteImage: true,
    },
    notes: {
      noteDate: true,
      note: true,
      measure: true,
      createdBy: true,
      modifiedBy: true,
      noteImage: true,
    },
    otherAttributes: {
      date: true,
      subject: true,
      filename: true,
      type: true,
      status: true,
      downloadButton: true,
    },
  },
  sensorsDataGrid: {
    id: false,
    sensorName: true,
    siteName: true,
    sensorStatus: true,
    sensorType: true,
    sensorMeasureUnit: false,
    startDate: false,
    lastUpdatedDate: true,
    blocks: false,
  },
  sitesDataGrid: {
    id: false,
    siteName: true,
    status: true,
    location: false,
    vineyard: true,
    createdDate: false,
    lastUpdatedDate: true,
  },
  usersDataGrid: {
    id: false,
    name: true,
    phone: false,
    email: true,
    businessUnit: true,
    isUserActive: true,
    isVineaAdmin: true,
  },
  activityDataGrid: {
    id: false,
    activityCode: true,
    description: true,
    activityGroup: true,
    unitType: true,
    resourceGroup: true,
    payrollCode: false,
    accountCode: false,
    costCentre: false,
  },
  activityDefaultRateDataGrid: {
    id: false,
    effectiveFromDate: true,
    effectiveToDate: true,
    defaultRateNote: true,
    targetVelocity: true,
    defaultChargeOutRate: true,
  },
};

function* userSettingsPreferencesAdd(action) {
  const { payload } = action;
  const { userSettings } = payload;
  const data = userSettings;
  const isBasicSettingsPresent = has(data, 'basicSettings');
  const isVineyardGridPresent = has(data, 'vineyardDataGrid');
  const isSiteGridPresent = has(data, 'siteDataGrid');
  const isPersonGridPresent = has(data, 'personDataGrid');
  const isVehicleGridPresent = has(data, 'vehicleDataGrid');
  const isSupplyContractGridPresent = has(data, 'supplyContractDataGrid');
  const isOrganisationGridPresent = has(data, 'organisationDataGrid');
  const isServiceProviderGridPresent = has(data, 'serviceProviderDataGrid');
  const isJobGridPresent = has(data, 'jobDataGrid');
  const isJobSiteGridPresent = has(data, 'jobSiteDataGrid');
  const isSamplesGridPresent = has('samplesDataGrid', data);
  const isTrackingGridPresent = has('trackingDataGrid', data);
  const isBlocksGridPresent = has('blocksDataGrid', data);
  const isSensorsGridPresent = has('sensorsDataGrid', data);
  const isSitesGridPresent = has('sitesDataGrid', data);
  const isUserGridPresent = has('usersDataGrid', data);
  const isActivityGridPresent = has('activityDataGrid', data);
  const isActivityDefaultRateGridPresent = has(
    'activityDefaultRateDataGrid',
    data,
  );
  try {
    yield put({
      type: 'FETCHING',
      name: 'userSettingsPreferences',
    });

    if (isEmpty(userSettings)) {
      const response = yield call(userPreferences, defaultSettingsPreferences);
      yield put({
        type: 'USER_SETTINGS_UPDATE',
        payload: defaultSettingsPreferences,
      });
      console.log('dataGrid', response);
    } else if (
      !isBasicSettingsPresent ||
      !isVineyardGridPresent ||
      !isPersonGridPresent ||
      !isOrganisationGridPresent ||
      !isServiceProviderGridPresent ||
      !isSamplesGridPresent ||
      !isTrackingGridPresent ||
      !isBlocksGridPresent ||
      !!isSensorsGridPresent ||
      !isSitesGridPresent ||
      !isUserGridPresent ||
      !isActivityGridPresent ||
      !isActivityDefaultRateGridPresent
    ) {
      let allData = { ...data };
      if (!isBasicSettingsPresent) {
        allData = {
          ...allData,
          basicSettings: defaultSettingsPreferences.basicSettings,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isVineyardGridPresent) {
        allData = {
          ...allData,
          vineyardDataGrid: defaultSettingsPreferences.vineyardDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isSiteGridPresent) {
        allData = {
          ...allData,
          siteDataGrid: defaultSettingsPreferences.siteDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isPersonGridPresent) {
        allData = {
          ...allData,
          personDataGrid: defaultSettingsPreferences.personDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isVehicleGridPresent) {
        allData = {
          ...allData,
          vehicleDataGrid: defaultSettingsPreferences.vehicleDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isSupplyContractGridPresent) {
        allData = {
          ...allData,
          supplyContractDataGrid:
            defaultSettingsPreferences.supplyContractDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isOrganisationGridPresent) {
        allData = {
          ...allData,
          organisationDataGrid: defaultSettingsPreferences.organisationDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isServiceProviderGridPresent) {
        allData = {
          ...allData,
          serviceProviderDataGrid:
            defaultSettingsPreferences.serviceProviderDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isJobGridPresent) {
        allData = {
          ...allData,
          jobDataGrid: defaultSettingsPreferences.jobDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isJobSiteGridPresent) {
        allData = {
          ...allData,
          jobSiteDataGrid: defaultSettingsPreferences.jobSiteDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isSamplesGridPresent) {
        allData = {
          ...allData,
          samplesDataGrid: defaultSettingsPreferences.samplesDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isTrackingGridPresent) {
        allData = {
          ...allData,
          trackingDataGrid: defaultSettingsPreferences.trackingDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isBlocksGridPresent) {
        allData = {
          ...allData,
          blocksDataGrid: defaultSettingsPreferences.blocksDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isSensorsGridPresent) {
        allData = {
          ...allData,
          sensorsDataGrid: defaultSettingsPreferences.sensorsDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isSitesGridPresent) {
        allData = {
          ...allData,
          sitesDataGrid: defaultSettingsPreferences.sitesDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isUserGridPresent) {
        allData = {
          ...allData,
          usersDataGrid: defaultSettingsPreferences.usersDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isActivityGridPresent) {
        allData = {
          ...allData,
          activityDataGrid: defaultSettingsPreferences.activityDataGrid,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
      if (!isActivityDefaultRateGridPresent) {
        allData = {
          ...allData,
          isActivityDefaultRateGridPresent:
            defaultSettingsPreferences.isActivityDefaultRateGridPresent,
        };
        yield put({
          type: 'USER_SETTINGS_UPDATE',
          payload: allData,
        });
      }
    } else {
      yield put({
        type: 'USER_SETTINGS_UPDATE',
        payload: data,
      });
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'userSettingsPreferences',
    });
  }
}

function* userSettingsPreferencesUpdate() {
  try {
    yield put({
      type: 'FETCHING',
      name: 'userSettingsPreferences',
    });

    const data = yield select(getUserPreferences);
    const response = yield call(userPreferences, data);
    yield put({
      type: 'USER_SETTINGS_UPDATE',
      payload: JSON.parse(response),
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'userSettingsPreferences',
    });
  }
}

function* userSettingsColumnsPreferencesReset() {
  try {
    yield put({
      type: 'FETCHING',
      name: 'userSettingsPreferences',
    });

    yield delay(100); // Wait for 1 second
    const { basicSettings } = yield select(getUserPreferences);
    const newSettings = { ...defaultSettingsPreferences, basicSettings };
    const response = yield call(userPreferences, newSettings);
    yield put({
      type: 'USER_SETTINGS_UPDATE',
      payload: JSON.parse(response),
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'userSettingsPreferences',
    });
  }
}

export default function* root() {
  yield all([takeLatest('SETTINGS_ADD', userSettingsPreferencesAdd)]);
  yield all([takeLatest('SETTINGS_UPDATE', userSettingsPreferencesUpdate)]);
  yield all([
    takeLatest(
      sagaActionTypes.USER_FAV_SETTINGS_UPDATE,
      userSettingsPreferencesUpdate,
    ),
  ]);
  yield all([
    takeLatest(
      'SETTINGS_COLUMN_PREFERENCES_RESET',
      userSettingsColumnsPreferencesReset,
    ),
  ]);
}
