import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { format, parseISO } from 'date-fns';
import Alert from '@mui/lab/Alert';
import isEmpty from 'lodash/isEmpty';
import { isNull } from 'lodash';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/lab/Skeleton';
import { useParams } from 'react-router';

// Icons
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';
import { Datepicker } from '../../../components/Datepicker';
import { StyledTableCell } from './StyledTableCell';
import {
  VineaButton,
  IconToolTipButton,
} from '../../../components/VineaButton';

import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { DeleteDialog } from '../../../components/Dialog';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { sagaActionTypes, viewDateFormat } from '../../../constants';
import { formatDate } from '../../../constants/formatter';
import { useIdRolesHook } from '../hooks/useIdRolesHook';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

const CustomTableCell = ({ row, name, onChange, roleTypeList }) => {
  const { isEditMode, errors } = row;

  const handleOnChange = e => {
    onChange(e, row);
  };

  return (
    <StyledTableCell align="left" manageStyle>
      {isEditMode && name === 'roleType' && (
        <>
          <VineaAutoComplete
            value={row.roleTypeID}
            onChange={handleOnChange}
            inlineLabel
            options={roleTypeList}
            labelVariant="body1"
            name="roleTypeID"
            displayLabel={false}
            inputProps={{
              name: 'roleTypeID',
            }}
          />
          {errors.roleTypeID && (
            <FormHelperText id="component-error-text">
              {errors.roleTypeID}
            </FormHelperText>
          )}
        </>
      )}
      {isEditMode && name === 'effectiveFromDate' && (
        <>
          <Datepicker
            disablePast={false}
            id="effectivefrom"
            inputFormat={viewDateFormat}
            placeholder="DD/MM/YYYY"
            value={row.effectiveFromDate}
            onChange={handleOnChange}
            name="effectiveFromDate"
            inputProps={{
              'data-testid': 'effectiveFromDate',
              'data-name': 'effectiveFromDate',
            }}
          />
          {errors.effectiveFromDate && (
            <FormHelperText id="component-error-text">
              {errors.effectiveFromDate}
            </FormHelperText>
          )}
        </>
      )}
      {isEditMode && name === 'effectiveToDate' && (
        <>
          <Datepicker
            disablePast={false}
            minDate={
              row.effectiveFromDate === null
                ? format(new Date(), viewDateFormat)
                : parseISO(row.effectiveFromDate)
            }
            id="effectiveto"
            inputFormat={viewDateFormat}
            placeholder="DD/MM/YYYY"
            value={row.effectiveToDate}
            onChange={handleOnChange}
            name="effectiveToDate"
            inputProps={{
              'data-testid': 'effectiveToDate',
              'data-name': 'effectiveToDate',
            }}
          />
          {errors.effectiveToDate && (
            <FormHelperText id="component-error-text">
              {errors.effectiveToDate}
            </FormHelperText>
          )}
        </>
      )}
      {!isEditMode &&
        name !== 'effectiveFromDate' &&
        name !== 'effectiveToDate' &&
        row[name]}
      {!isEditMode &&
        (name === 'effectiveFromDate' || name === 'effectiveToDate') &&
        formatDate(row[name])}
    </StyledTableCell>
  );
};

export const ManageRoles = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatchAPI = useDispatch();
  const [alertOpen, setAlertOpen] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [disableIcon, setDisableIcon] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [rowId, setRowId] = useState(null);
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  const identityTypeId = useIdentityTypeId(vineyardTypeScreenName);
  const { id: pathParam } = useParams();

  let { identityId } = props;
  if (isNull(identityId)) {
    identityId = pathParam;
  }

  const {
    roleTypeList,
    identityRoles: rows,
    onAddNew,
    isLoadingIdRoles: isLoading,
    isLoadedIdRoles: isLoaded,
    hasErrorIdRoles: hasError,
    errorMessage,
    handleOnChangeRowData,
    onToggleEditMode,
    handleOnSaveRow,
    handleRevert,
    setSeqToSave,
    setActionTriggered,
    actionTriggered,
    seqToSave,
    handleOnDeleteRow,
  } = useIdRolesHook(identityTypeId, identityId);

  const onAdd = data => {
    setDisableIcon(true);
    onAddNew(data);
  };

  const onDelete = id => {
    setRowId(id);
    setDialogOpen(true);
    setDisableIcon(false);
  };

  const onSave = id => {
    handleOnSaveRow(id);
  };

  const onEdit = id => {
    setDisableIcon(true);
    onToggleEditMode(id, rows);
  };

  const onRevert = id => {
    setDisableIcon(false);
    handleRevert(id);
  };

  React.useEffect(() => {
    /** When saving the selected reference id */
    if (actionTriggered) {
      if (!isLoading && isLoaded) {
        if (hasError) {
          const { data: { error } = [] } = errorMessage;
          const { summary = '' } = error[0];
          setAlertMessage(summary);
          // set form submitting has errors;
          setAlertOpen(true);
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else if (!hasError) {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          onToggleEditMode(seqToSave, rows);
          setDisableIcon(false);
          if (!isNull(identityId)) {
            dispatchAPI({
              type: sagaActionTypes.FETCH_IDENTITIES,
              payload: {
                id: identityId,
                identityTypeId,
              },
            });
            dispatchAPI(
              VineaNovaActions.api.v1.identityRole.get.request({
                queryParams: {
                  IdentityID: identityId,
                },
              }),
            );
          }
        }
        setActionTriggered(false);
        setSeqToSave(null);
      }
    }
  }, [
    actionTriggered,
    setActionTriggered,
    setSeqToSave,
    isLoaded,
    enqueueSnackbar,
    onToggleEditMode,
    seqToSave,
    t,
    rows,
    identityId,
    dispatchAPI,
    identityTypeId,
    isLoading,
    hasError,
    errorMessage,
  ]);

  return (
    <>
      <Paper
        sx={{
          width: '100%',
          margin: theme.spacing(3),
          overflowX: 'auto',
        }}
      >
        <Box paddingTop={1} />
        <Box px={2}>
          {hasError && (
            <Collapse in={alertOpen}>
              <Alert
                variant="outlined"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {alertMessage}
              </Alert>
            </Collapse>
          )}
        </Box>
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label="caption table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" />
              <TableCell align="left">{t('Role')}</TableCell>
              <TableCell align="left">{t('Effective From')}</TableCell>
              <TableCell align="left">{t('Effective To')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={11}>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              rows.map((row, idx) => (
                <>
                  <TableRow
                    key={row.id}
                    data-testid={row.id}
                    data-testrow="table-row"
                    data-seq={idx + 1}
                    data-newrow={row.isEditMode}
                    sx={{ height: '20px' }}
                  >
                    <StyledTableCell iconStyle>
                      {row.isEditMode ? (
                        <>
                          <IconToolTipButton
                            size="small"
                            tooltip="Save"
                            label="done"
                            data-testid="iconsave"
                            onClick={() => {
                              onSave(row.id);
                            }}
                            sx={{
                              color: theme.palette.success.main,
                            }}
                            disabled={!!row.warning || !isEmpty(row.errors)}
                          >
                            <DoneAllIcon />
                          </IconToolTipButton>
                          <IconToolTipButton
                            size="small"
                            tooltip="Cancel"
                            data-testid="iconcancel"
                            onClick={() => {
                              onRevert(row.id);
                            }}
                            sx={{
                              color: theme.palette.error.main,
                            }}
                          >
                            <NotInterestedOutlinedIcon />
                          </IconToolTipButton>
                        </>
                      ) : (
                        <>
                          <IconToolTipButton
                            size="small"
                            disabled={disableIcon}
                            tooltip="Edit"
                            data-testid="iconedit"
                            onClick={() => {
                              onEdit(row.id);
                            }}
                            sx={{
                              marginTop: theme.spacing(2) * -1,
                              color: '#404040',
                            }}
                          >
                            <ModeEditOutlineOutlinedIcon />
                          </IconToolTipButton>
                          <IconToolTipButton
                            size="small"
                            disabled={disableIcon}
                            tooltip="Delete"
                            label="Delete"
                            data-testid="icondelete"
                            onClick={() => {
                              onDelete(row.id);
                            }}
                            sx={{
                              color: theme.palette.error.main,
                              marginTop: theme.spacing(2) * -1,
                            }}
                          >
                            <DeleteIcon />
                          </IconToolTipButton>
                        </>
                      )}
                    </StyledTableCell>
                    <CustomTableCell
                      {...{
                        row,
                        roleTypeList,
                        name: 'roleType',
                        onChange: handleOnChangeRowData,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: 'effectiveFromDate',
                        onChange: handleOnChangeRowData,
                      }}
                    />
                    <CustomTableCell
                      {...{
                        row,
                        name: 'effectiveToDate',
                        onChange: handleOnChangeRowData,
                      }}
                    />
                  </TableRow>
                  {row.warning && (
                    <TableRow>
                      <TableCell colSpan={11}>
                        <Alert severity="warning">{row.warning}</Alert>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
          </TableBody>
        </Table>
        <DeleteDialog
          open={dialogOpen}
          dialogHeader={t('Delete Role')}
          onClose={() => setDialogOpen(false)}
          dialogActionTrigger={handleOnDeleteRow}
          rowId={rowId}
        />
      </Paper>
      <Box paddingTop={1} />
      <VineaButton
        color="secondary"
        sx={{ marginLeft: theme.spacing(3) }}
        onClick={onAdd}
        variant="contained"
        minWidth={100}
        disabled={rows.some(row => row.isEditMode)}
      >
        {t('Add Role')}
      </VineaButton>
    </>
  );
};

ManageRoles.propTypes = {
  identityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ManageRoles.defaultProps = {
  identityId: null,
};

CustomTableCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    roleTypeID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    effectiveToDate: PropTypes.string,
    effectiveFromDate: PropTypes.string,
    errors: PropTypes.shape({
      name: PropTypes.string,
      roleTypeID: PropTypes.string,
      effectiveToDate: PropTypes.string,
      effectiveFromDate: PropTypes.string,
    }),
    isEditMode: PropTypes.bool,
  }),
  roleTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    }),
  ),
  name: PropTypes.string,
  onChange: PropTypes.func,
};

CustomTableCell.defaultProps = {
  row: {},
  name: '',
  onChange: f => f,
  roleTypeList: [],
};
