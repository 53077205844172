import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { FieldLabelInput } from '../TextField';

export const StyledButtonControlDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  margin: 10,
}));

export const StyledCloseDiv = styled('div')(() => ({
  paddingLeft: 10,
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
  display: 'flex',
}));

export const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  minWidth: 150,
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));
