/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/named */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { ErrorBoundary } from 'react-error-boundary';

import { VineaButton } from '../../components/VineaButton';
import { VineaTextField, FieldLabelInput } from '../../components/TextField';
import { DiscardDialog } from '../../components/Dialog';
import { Datepicker } from '../../components/Datepicker';
// import { VineaAutoComplete } from '../../../components/ComboBox';
import { formatDate } from '../../constants/formatter';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { viewDateFormat } from '../../constants';
import { syncValidator } from '../../utils/validator';
import { ActivityDefaultRateSchema } from './validations';
import { DeleteDialog } from '../../components/Dialog';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
  display: 'flex',
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 200,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
  '& .MuiInputBase-input': {
    minWidth: 200,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  minWidth: 300,
  '& .MuiFormControl-root': {
    minWidth: 300,
  },
}));

export function DefaultRatesDrawer({
  isOpen,
  handleOnClose,
  handleOnSave,
  handleOnChange,
  data = {},
  hasChanges,
  activityWorkUnit,
  activityCostingUnit,
  activityDuration,
  handleOnDelete,
  noEffectiveDateError,
  overLapError,
  isManage,
}) {
  const { t } = useTranslation();

  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState({});

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const onSave = () => {
    const currentErrors = syncValidator(ActivityDefaultRateSchema)(data);
    setValidationErrors(currentErrors);
    handleOnSave(data);
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnDiscard = () => {
    if (hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      handleOnClose();
    }
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  const onDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleOnConfirmDelete = () => {
    handleOnDelete();
    setDeleteDialogOpen(false);
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={handleOnClickAway}
      >
        <Box sx={{ width: 350 }} role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography sx={{ padding: 2 }} variant="body1">
                {t('Activity / Default Rates')}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOnDiscard}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            {t('Default Rate')}
          </Typography>

          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />

            <Box my={2}>
              <StyledFormControl
                data-testid="datepicker-formcontrol"
                error={validationErrors.effectiveFromDate}
              >
                <Datepicker
                  id="effectiveFromDate"
                  label={t('Effective From')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  size="small"
                  name="effectiveFromDate"
                  value={data?.effectiveFromDate || ''}
                  onChange={handleOnChange}
                  inputProps={{
                    'data-id': 'effectiveFromDate',
                    'data-name': 'effectiveFromDate',
                  }}
                  fullWidth
                />
                {validationErrors.effectiveFromDate && (
                  <FormHelperText id="component-error-text">
                    {validationErrors.effectiveFromDate}
                  </FormHelperText>
                )}
                {overLapError && (
                  <FormHelperText error id="component-error-text">
                    {t(
                      'You cannot have more than one rate active at a time. Please select a date range when no other dates are active.',
                    )}
                  </FormHelperText>
                )}
              </StyledFormControl>
              <StyledFormControl
                data-testid="datepicker-formcontrol"
                error={validationErrors.effectiveToDate}
              >
                <Datepicker
                  id="effectiveToDate"
                  label={t('Effective To')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  size="small"
                  name="effectiveToDate"
                  emptyLabel="custom label"
                  value={data?.effectiveToDate || null}
                  onChange={handleOnChange}
                  inputProps={{
                    'data-id': 'effectiveToDate',
                    'data-name': 'effectiveToDate',
                  }}
                  fullWidth
                />
                {validationErrors.effectiveToDate && (
                  <FormHelperText id="component-error-text">
                    {validationErrors.effectiveToDate}
                  </FormHelperText>
                )}
                {noEffectiveDateError && (
                  <FormHelperText error id="component-error-text">
                    {t(
                      'You cannot have more than one rate active at a time. Please add an "Effective To" date to this rate before adding a new one.',
                    )}
                  </FormHelperText>
                )}
                {overLapError && (
                  <FormHelperText error id="component-error-text">
                    {t(
                      'You cannot have more than one rate active at a time. Please select a date range when no other dates are active.',
                    )}
                  </FormHelperText>
                )}
              </StyledFormControl>
              <StyledFormBoxControl mt={1}>
                <FormControl>
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="defaultCostRate"
                    name="defaultCostRate"
                    label={t('Cost Rate')}
                    size="large"
                    inlineLabel
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {activityCostingUnit}/{activityWorkUnit}
                        </InputAdornment>
                      ),
                    }}
                    value={data?.defaultCostRate}
                    onChange={handleOnChange}
                    error={validationErrors.defaultCostRate}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl>
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="targetVelocity"
                    name="targetVelocity"
                    label={t('Target Velocity')}
                    size="large"
                    inlineLabel
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {activityWorkUnit}/{activityDuration}
                        </InputAdornment>
                      ),
                    }}
                    value={data?.targetVelocity}
                    onChange={handleOnChange}
                    error={validationErrors.targetVelocity}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl>
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="defaultChargeOutRate"
                    name="defaultChargeOutRate"
                    label={t('Charge Rate')}
                    size="large"
                    inlineLabel
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {activityCostingUnit}/{activityWorkUnit}
                        </InputAdornment>
                      ),
                    }}
                    value={data?.defaultChargeOutRate}
                    onChange={handleOnChange}
                    error={validationErrors.defaultChargeOutRate}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledBox mt={1}>
                <VineaTextField
                  margin="dense"
                  autoComplete="off"
                  id="internalWorkCost"
                  name="internalWorkCost"
                  label={t('Note')}
                  value={data?.internalWorkCost}
                  onChange={handleOnChange}
                  error={validationErrors.internalWorkCost}
                  multiline
                  maxRows={6}
                  minRows={4}
                />
              </StyledBox>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flex: 1,
                margin: 5,
                marginTop: 8,
              }}
            >
              <VineaButton
                color="success"
                onClick={onSave}
                variant="contained"
                minWidth={150}
              >
                {t('Save')}
              </VineaButton>
              {isManage && (
                <VineaButton
                  color="error"
                  onClick={onDelete}
                  variant="contained"
                  minWidth={150}
                >
                  {t('Delete')}
                </VineaButton>
              )}
              <VineaButton
                color="secondary"
                onClick={handleOnDiscard}
                variant="outlined"
                minWidth={150}
              >
                {t('Close')}
              </VineaButton>
            </Box>
          </ErrorBoundary>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={() => setDiscardDialogOpen(false)}
            handleSaveChanges={handleOnDiscardSaveChanges}
            handleDiscardChanges={handleOnDiscardConfirm}
          />
          <DeleteDialog
            onClose={() => setDeleteDialogOpen(false)}
            dialogHeader={t(`Delete Activity Rate`)}
            open={deleteDialogOpen}
            dialogActionTrigger={handleOnConfirmDelete}
          />
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
}
