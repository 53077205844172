export const actionTypes = {
  fetching: 'FETCHING',
  success: 'SUCCESS',
  error: 'ERROR',
  clear: 'CLEAR_DATA',
  updateData: 'UPDATE_DATA',
};

export const authenticationTypes = {
  fetching: 'AUTH_FETCHING',
  success: 'AUTH_SUCCESS',
  error: 'AUTH_ERROR',
  logout: 'AUTH_LOGOUT',
  token: 'AUTH_TOKEN',
};

export const sagaActionTypes = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',
  USER_LOGOUT: 'USER_LOGOUT',
  FETCH_IDENTITIES: 'FETCH_IDENTITIES',
  FETCH_SPECIFIC_IDENTITY: 'FETCH_SPECIFIC_IDENTITY',
  FETCH_IDENTITY_VALUES: 'FETCH_IDENTITY_VALUES',
  SEARCH_IDENTITIES: 'SEARCH_IDENTITIES',
  ADD_IDENTITIES: 'ADD_IDENTITIES',
  UPDATE_IDENTITIES: 'UPDATE_IDENTITIES',
  SEARCH_USERS: 'SEARCH_USERS',
  ADD_USERS: 'ADD_USERS',
  UPDATE_USERS: 'UPDATE_USERS',
  FETCH_ALL_LOOKUPS: 'FETCH_ALL_LOOKUPS',
  FORM_SUBMIT: 'FORM_SUBMIT',
  CLEAR_IDENTITIES: 'CLEAR_IDENTITIES',
  REFRESH_PAGE_DATA: 'REFRESH_PAGE_DATA',
  FETCH_ADDRESS: 'FETCH_ADDRESS',
  FETCH_VINEYARD_BLOCKS: 'FETCH_VINEYARD_BLOCKS',
  FETCH_VINEYARD_BLOCK_ROWS: 'FETCH_VINEYARD_BLOCK_ROWS',
  FETCH_IDENTITY_NOTES: 'FETCH_IDENTITY_NOTES',
  FETCH_BLOCK_VINTAGES: 'FETCH_BLOCK_VINTAGES',
  FETCH_ALL_IDENTITIES: 'FETCH_ALL_IDENTITIES',
  SEARCH_SAMPLES: 'SEARCH_SAMPLES',
  FETCH_SAMPLE_HEADERS: 'FETCH_SAMPLE_HEADERS',
  FETCH_SAMPLE_BLOCK_VINTAGES: 'FETCH_SAMPLE_BLOCK_VINTAGES',
  CREATE_NOTE_WITH_DOCUMENTS: 'CREATE_NOTE_WITH_DOCUMENTS',
  EXPORT_EXCEL_REPORT: 'EXPORT_EXCEL_REPORT',
  ADD_PLAN: 'ADD_PLAN',
  SEARCH_PLAN: 'SEARCH_PLAN',
  ADD_SUPPLY_CONTRACT: 'ADD_SUPPLY_CONTRACT',
  SEARCH_SUPPLY_CONTRACT: 'SEARCH_SUPPLY_CONTRACT',
  USER_FAV_SETTINGS_UPDATE: 'USER_FAV_SETTINGS_UPDATE',
};

export const urlMap = {
  1: `person`,
  2: `organisation/other-organisations`,
  3: `organisation/vineyards`,
  4: `organisation/serviceproviders`,
  5: `job`,
  8: `connections`,
  20: `vehicle`,
  21: `accommodation`,
  23: `consumable`,
  25: `activity`,
  26: `supplycontract`,
};
