/* eslint-disable no-undef */
import { saveAs } from 'file-saver';
import serviceConfig from './class/serviceConfig';

export const reportFileDownloadService = async (postBody, filename) => {
  try {
    const res = await serviceConfig
      .request({
        url: `/ReportFile`,
        method: 'POST',
        data: JSON.stringify(postBody),
        responseType: 'blob', // don't forget this
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        saveAs(url, filename);
      });
    return res;
  } catch (err) {
    console.log('Error in downloading file');
    console.error(err);
    throw err;
  }
};
