// eslint-disable
import React from 'react';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

export const IconToolTipButton = ({
  children,
  tooltip,
  onClick,
  className,
  label,
  disabled,
  ...restProps
}) => {
  if (tooltip && !disabled) {
    return (
      <Tooltip title={tooltip}>
        <IconButton
          aria-label="done"
          onClick={onClick}
          className={classNames(className)}
          disabled={disabled}
          size="large"
          {...restProps}
        >
          {children}
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <IconButton
      aria-label={label}
      onClick={onClick}
      className={classNames(className)}
      disabled={disabled}
      size="large"
      {...restProps}
    >
      {children}
    </IconButton>
  );
};

IconToolTipButton.propTypes = {
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

IconToolTipButton.defaultProps = {
  tooltip: null,
  onClick: f => f,
  className: '',
  label: '',
  disabled: false,
  size: 'large',
};
