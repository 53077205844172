import { createSelector } from 'reselect';
import { isNull, isEmpty, get, toNumber, some } from 'lodash';
import { isValid, format } from 'date-fns';
import { getLkpSampleReqStatus } from './lookupSelectors';
import { viewDateFormat, commonFieldSelectOption } from '../../constants';
import { validDateFormat } from '../../utils/DateUtil';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];
const getSamples = state => state.entities.sampleSets;
const getSampleHeaders = state => state.entities.sampleHeaders;
const lkpRegion = state => state.entities.lookupGrowingRegion;
const getSamplingUsers = state => state.entities.samplingUsers;

export const getSamplesData = createSelector(
  [getSamples, lkpRegion],
  (samplesData, lookupRegions) => {
    const { isLoading, data = [] } = samplesData;
    const { isLoading: isRegionLoading, data: regionData = [] } = lookupRegions;

    if (isLoading || isRegionLoading) {
      return EMPTY_ARRAY;
    }
    if (!Array.isArray(data) || isNull(data)) return EMPTY_ARRAY;
    if (isEmpty(data)) return EMPTY_ARRAY;

    if (!isEmpty(regionData)) {
      const samplesWithRegion = data.map(val => {
        const regionName = get(
          regionData.find(f => f.id === val.regionID),
          'growingRegion',
          '',
        );

        const actualStartDate = isValid(validDateFormat(val.actualStartDate))
          ? format(validDateFormat(val.actualStartDate), viewDateFormat)
          : null;
        const lastSampleDate = isValid(validDateFormat(val.lastSampleDate))
          ? format(validDateFormat(val.lastSampleDate), viewDateFormat)
          : null;
        const plannedStartDate = isValid(validDateFormat(val.plannedStartDate))
          ? format(validDateFormat(val.plannedStartDate), viewDateFormat)
          : null;

        return {
          ...val,
          region: regionName,
          actualStartDate,
          lastSampleDate,
          plannedStartDate,
        };
      });
      return samplesWithRegion;
    }
    return data;
  },
);

export const getSampleHeaderOptions = createSelector(
  [getSampleHeaders],
  samplesData => {
    const { data = [] } = samplesData;
    if (!Array.isArray(data) || isNull(data)) return [];
    if (isEmpty(data)) return [];

    return commonFieldSelectOption.concat(
      data
        .map(({ id, blockFullName }) => ({
          id,
          key: id,
          value: blockFullName,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
  },
);

export const getSampleHeadersData = createSelector(
  [getSampleHeaders, getLkpSampleReqStatus],
  (samplesData, sampleReqStatus) => {
    const { isLoading, data = [] } = samplesData;
    if (isLoading) {
      return [];
    }
    if (!Array.isArray(data) || isNull(data)) return [];
    if (isEmpty(data)) return [];
    if (!isEmpty(sampleReqStatus)) {
      const sampleHeaderData = data.map(f => {
        const getStatus = get(
          sampleReqStatus.find(st => st.id === f.sampleRequestStatusID),
          'value',
          '',
        );
        return {
          ...f,
          status: getStatus,
        };
      });
      return sampleHeaderData;
    }
    return data;
  },
);

export const getSamplingUsersList = createSelector(
  [getSamplingUsers],
  samplesData => {
    const { isLoading, data = [] } = samplesData;
    if (isLoading) {
      return [];
    }
    if (!Array.isArray(data) || isNull(data)) return [];
    if (isEmpty(data)) return [];
    const sampleUserOptions = data.map(f => {
      return {
        id: f.id,
        key: f.id,
        value: f.displayName,
      };
    });
    return sampleUserOptions;
  },
);

const getSamplesId = (state, id) => id;

export const getSampleSetById = createSelector(
  [getSamplesData, getSamplesId],
  (data, id) => {
    if (!id) {
      return EMPTY_OBJECT;
    }
    if (!Array.isArray(data) || isNull(data)) return EMPTY_OBJECT;
    if (isEmpty(data)) return EMPTY_OBJECT;
    const filteredData = data.find(f => f.id === toNumber(id));
    return filteredData || EMPTY_OBJECT;
  },
);

const getSampleBV = state => state.entities.sampleBlockVintages;
export const getIsLoadingBlockVintages = state =>
  state.entities.sampleBlockVintages.isLoading;
export const getIsLoadedBlockVintages = state =>
  state.entities.sampleBlockVintages.isLoaded;
// just return vineyardname as groups
export const getSampleBlockVintageGroups = createSelector([getSampleBV], bv => {
  const { isLoading, data = [] } = bv;
  if (isLoading) {
    return EMPTY_ARRAY;
  }
  if (!Array.isArray(data) || isNull(data)) return EMPTY_ARRAY;
  if (isEmpty(data)) return EMPTY_ARRAY;
  const groups = data.reduce((acc, val) => {
    const { vineyardName } = val;
    if (!acc.includes(vineyardName)) {
      acc.push(vineyardName);
    }
    return acc;
  }, []);
  return groups;
});

// Return vineyards with business unit
export const getSampleBlockVineyardsData = createSelector([getSampleBV], bv => {
  const { isLoading, data = [] } = bv;
  if (isLoading) {
    return EMPTY_ARRAY;
  }
  if (!Array.isArray(data) || isNull(data)) return EMPTY_ARRAY;
  if (isEmpty(data)) return EMPTY_ARRAY;
  const groups = data.reduce((acc, val) => {
    const { vineyardName, vineyardID, businessUnitID } = val;
    if (!some(acc, { vineyardName: vineyardName })) {
      acc.push({ vineyardName, vineyardID, businessUnitID });
    }
    return acc;
  }, []);
  return groups;
});

export const getBVNotInSampleSet = createSelector([getSampleBV], bv => {
  const { isLoading, data = [] } = bv;
  if (isLoading) {
    return EMPTY_ARRAY;
  }
  if (!Array.isArray(data) || isNull(data)) return EMPTY_ARRAY;
  if (isEmpty(data)) return EMPTY_ARRAY;
  const notInSet = data.filter(f => !f.isInSampleSet);
  return notInSet;
});
