import { createSelector } from 'reselect';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { isEmpty, isNull } from 'lodash';

const EMPTY_ARRAY = [];
const getAllPlanBlocks = VineaNovaSelectors.getPlanBlocksEntityData;
const getAvailablePlanBlocks =
  VineaNovaSelectors.getGetAvailablePlanBlocksEntityData;

export const getAllPlanBlocksSelector = createSelector(
  [getAllPlanBlocks],
  allPlanBlocks => {
    return allPlanBlocks;
  },
);

export const getAvailablePlanBlocksSelector = createSelector(
  [getAvailablePlanBlocks],
  availablePlanBlocks => {
    return availablePlanBlocks;
  },
);

export const getIsLoadingAvailablePlanBlocks = state =>
  state.entities.getAvailablePlanBlocks.isLoading;

// just return vineyardname as groups
export const getPlanBlocksGroupsSelector = createSelector(
  [getAvailablePlanBlocks],
  pb => {
    const { isLoading, data = [] } = pb;
    if (isLoading) {
      return EMPTY_ARRAY;
    }
    if (!Array.isArray(data) || isNull(data)) return EMPTY_ARRAY;
    if (isEmpty(data)) return EMPTY_ARRAY;
    const groups = data.reduce((acc, val) => {
      const { vineyardName } = val;
      if (!acc.includes(vineyardName)) {
        acc.push(vineyardName);
      }
      return acc;
    }, []);
    return groups;
  },
);
