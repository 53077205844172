import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { isValid, isAfter } from 'date-fns';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  getVineyardBlocks,
  getVineyardBlocksInfo,
} from '../../../redux/selectors';
import useDeepEffect from '../../../hooks/useDeepEffect';
import { syncValidator } from '../../../utils/validator';
import { AdhocBlockSchema, BlockSchema } from '../validations';
import {
  reducers,
  sagaActionTypes,
  vineaDetails,
  actionTypes,
  apiTypes,
  BlockVintageStatusIDs,
} from '../../../constants';
import { get } from 'lodash';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

const useVineyardBlocksHook = () => {
  const { id: pathParamId, blockId: pathParamBlockId } = useParams();
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // state
  const [allVineyadBlocksData, setAllVineyardBlocksData] = useState([]);
  const [vineyardBlockSelected, setVineyardBlockSelected] = useState({});
  const [actionTriggered, setActionTriggered] = useState(false);
  const [isNewBlock, setIsNewBlock] = useState(false);
  const [saveIndex, setSaveIndex] = useState(0);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const blockIsAdHoc = useMemo(() => {
    if (
      get(vineyardBlockSelected, 'blockVintageStatusID') ===
      BlockVintageStatusIDs.ADHOC
    )
      return true;
    else return false;
  }, [vineyardBlockSelected]);

  // dispatches;
  const dispatchAPI = useDispatch();
  const navigate = useNavigate();

  /** selectors ** */
  const {
    isLoading: formWriteLoading,
    hasError: formWriteError,
    isLoaded: formWriteLoaded,
    data: formWriteData,
    error: errorAPI,
  } = useSelector(state => state.formWrite);

  const blocksData = useSelector(getVineyardBlocks);

  let errorFromAPI = '';

  if (errorAPI) {
    const { data } = errorAPI;
    if (data) {
      const { error } = data;
      errorFromAPI = error[0].summary;
    }
  }

  const {
    isLoading: blocksIsLoading,
    isLoaded: blocksLoaded,
    hasError: blocksHasError,
  } = useSelector(getVineyardBlocksInfo);

  // useEffects
  useDeepEffect(() => {
    if (!blocksIsLoading && blocksLoaded && !blocksHasError) {
      setAllVineyardBlocksData(blocksData);
    } else setAllVineyardBlocksData([]);
  }, [blocksIsLoading, blocksLoaded, blocksHasError, blocksData]);

  useEffect(() => {
    let getSelectedBlockById = {};
    if (pathParamBlockId === 'create') {
      getSelectedBlockById = {};
      setIsNewBlock(true);
    } else if (
      pathParamBlockId &&
      Array.isArray(allVineyadBlocksData) &&
      allVineyadBlocksData.length > 0
    ) {
      getSelectedBlockById = allVineyadBlocksData.find(
        f => f.id === parseInt(pathParamBlockId, 10),
      );
    }
    setVineyardBlockSelected(getSelectedBlockById);
  }, [pathParamBlockId, allVineyadBlocksData, blocksData]);

  React.useEffect(() => {
    if (!blocksIsLoading && blocksLoaded && !blocksHasError) {
      const orginalStoreObj = allVineyadBlocksData.find(
        f => f.id === vineyardBlockSelected.id,
      );
      if (compareObjects(vineyardBlockSelected, orginalStoreObj, ['errors'])) {
        setSaveButtonStatus(true);
      } else setSaveButtonStatus(false);
    }
  });

  useEffect(() => {
    if (actionTriggered) {
      if (!formWriteLoading && formWriteLoaded) {
        if (formWriteError) {
          // set form submitting has errors;
          setErrorInSubmit(true);
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else {
          // const { id } = formWriteData;

          const activeToDate =
            isValid(formWriteData.activeToDate) &&
            isAfter(formWriteData.activeToDate, '1900-01-01')
              ? formWriteData.activeToDate
              : '';
          const datePlanted =
            isValid(formWriteData.datePlanted) &&
            isAfter(formWriteData.datePlanted, '1900-01-01')
              ? formWriteData.datePlanted
              : '';

          const selectedBlockValue = {
            ...formWriteData,
            activeToDate,
            datePlanted,
          };

          setVineyardBlockSelected(selectedBlockValue);
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          dispatchAPI({
            type: sagaActionTypes.FETCH_VINEYARD_BLOCKS,
            name: reducers.vineyardBlocks,
            payload: {
              id: pathParamId,
              name: reducers.vineyardBlocks,
            },
          });
          if (saveIndex === 0)
            navigate(`/organisation/${vineyardTypeScreenName}s/${pathParamId}/blocks`);

          if (isNewBlock) {
            navigate(`/organisation/${vineyardTypeScreenName}s/${pathParamId}/blocks`);
          }
        }
        setActionTriggered(false);
      }
    }
  }, [
    actionTriggered,
    formWriteLoading,
    formWriteLoaded,
    formWriteError,
    formWriteData,
    enqueueSnackbar,
    t,
    vineyardBlockSelected,
    setVineyardBlockSelected,
    isNewBlock,
    navigate,
    pathParamId,
    allVineyadBlocksData,
    saveIndex,
    dispatchAPI,
  ]);

  const handleOnBlur = () => {
    // continue validations
    const validationErrors = syncValidator(BlockSchema)(vineyardBlockSelected);

    const { errors } = vineyardBlockSelected;

    if (!isEmpty(errors)) {
      const newValue = {
        ...vineyardBlockSelected,
        errors: validationErrors,
      };
      setVineyardBlockSelected(newValue);
    }
  };

  /** changes to the row data */
  const handleOnChange = useCallback(
    e => {
      const {
        target: { value, name },
      } = e;

      const newValue = { ...vineyardBlockSelected, [name]: value };

      if (name === 'startRow' || name === 'endRow') {
        const rowCount =
          Number.isNaN(Number(newValue.endRow)) === false &&
          Number.isNaN(Number(newValue.startRow)) === false &&
          Number(newValue.endRow) > Number(newValue.startRow)
            ? Number(newValue.endRow) - Number(newValue.startRow) + 1
            : '';

        const newValue1 = {
          ...newValue,
          blockFullName: `${newValue.blockRef} ${newValue.sectionRef || ''} ${
            newValue.subSectionRef || ''
          }`,
          row_Count: rowCount,
        };
        setVineyardBlockSelected(newValue1);
      } else if (
        name === 'plantedArea' ||
        name === 'rowSpacing' ||
        name === 'plantSpacing'
      ) {
        const totalPlantedVines =
          Number.isNaN(Number(newValue.plantedArea)) === false &&
          Number.isNaN(Number(newValue.rowSpacing)) === false &&
          Number.isNaN(Number(newValue.plantSpacing)) === false &&
          Number(newValue.plantedArea) > 0 &&
          Number(newValue.rowSpacing) > 0 &&
          Number(newValue.plantSpacing) > 0
            ? parseInt(
                (Number(newValue.plantedArea) * 10000) /
                  (Number(newValue.rowSpacing) * Number(newValue.plantSpacing)),
                10,
              )
            : '';

        const newValue1 = {
          ...newValue,
          blockFullName: `${newValue.blockRef} ${newValue.sectionRef || ''} ${
            newValue.subSectionRef || ''
          }`,
          plantedVines: totalPlantedVines.toString(),
        };
        setVineyardBlockSelected(newValue1);
      } else {
        const newValue2 = {
          ...newValue,
          blockFullName: `${newValue.blockRef} ${newValue.sectionRef || ''} ${
            newValue.subSectionRef || ''
          }`,
        };
        setVineyardBlockSelected(newValue2);
      }
    },
    [vineyardBlockSelected],
  );

  const onSaveNew = () => {
    // continue validations
    const validationErrors = !blockIsAdHoc
      ? syncValidator(BlockSchema)(vineyardBlockSelected)
      : syncValidator(AdhocBlockSchema)(vineyardBlockSelected);

    if (!isEmpty(validationErrors)) {
      const newValue = {
        ...vineyardBlockSelected,
        errors: validationErrors,
      };
      setVineyardBlockSelected(newValue);
    } else {
      const dataToSubmit = {
        ...omit(vineyardBlockSelected, ['errors']),
        vineyardID: pathParamId,
        varietyID:
          vineyardBlockSelected.varietyID !== 0
            ? vineyardBlockSelected.varietyID
            : null,
        cloneID:
          vineyardBlockSelected.cloneID !== 0
            ? vineyardBlockSelected.cloneID
            : null,
        rootStockID:
          vineyardBlockSelected.rootStockID !== 0
            ? vineyardBlockSelected.rootStockID
            : null,
      };
      dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data: dataToSubmit,
          name: vineaDetails.blocks,
          methodType: apiTypes.POST,
        },
      });
      setActionTriggered(true);
    }
  };

  const onUpdate = (event, index) => {
    setSaveIndex(index);
    // continue validations
    const validationErrors = !blockIsAdHoc
      ? syncValidator(BlockSchema)(vineyardBlockSelected)
      : syncValidator(AdhocBlockSchema)(vineyardBlockSelected);
    const orginalStoreObj = allVineyadBlocksData.find(
      f => f.id === vineyardBlockSelected.id,
    );

    if (!isEmpty(validationErrors)) {
      const newValue = { ...vineyardBlockSelected, errors: validationErrors };
      setVineyardBlockSelected(newValue);
    } else if (
      compareObjects(vineyardBlockSelected, orginalStoreObj, ['errors'])
    ) {
      enqueueSnackbar(t('No data has been modified!'), { variant: 'Info' });
    } else {
      const dataToSubmit = {
        ...omit(vineyardBlockSelected, ['errors']),
        varietyID:
          vineyardBlockSelected.varietyID !== 0
            ? vineyardBlockSelected.varietyID
            : null,
        cloneID:
          vineyardBlockSelected.cloneID !== 0
            ? vineyardBlockSelected.cloneID
            : null,
        rootStockID:
          vineyardBlockSelected.rootStockID !== 0
            ? vineyardBlockSelected.rootStockID
            : null,
      };

      dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data: dataToSubmit,
          name: vineaDetails.blocks,
          methodType: apiTypes.PUT,
        },
      });
      setActionTriggered(true);
    }
  };

  const onDelete = () => {
    const dataToDelete = {
      ID: vineyardBlockSelected.id,
      ts: vineyardBlockSelected.ts,
    };

    dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
    dispatchAPI({
      type: sagaActionTypes.FORM_SUBMIT,
      payload: {
        data: dataToDelete,
        name: vineaDetails.blocks,
        methodType: apiTypes.DELETE,
      },
    });
    setActionTriggered(true);
  };

  const compareObjects = (obj1, obj2, fields) => {
    const result = isEqual(omit(obj1, [...fields]), omit(obj2, [...fields]));
    return result;
  };

  return {
    vineyardBlockSelected,
    blockIsAdHoc,
    errorFromAPI,
    saveButtonStatus,
    actionTriggered,
    formWriteLoading,
    formWriteError,
    formWriteLoaded,
    formWriteData,
    isNewBlock,
    errorInSubmit,
    handleOnBlur,
    handleOnChange,
    onSaveNew,
    onUpdate,
    setErrorInSubmit,
    onDelete,
  };
};

export { useVineyardBlocksHook };
