import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Alert from '@mui/lab/Alert';
import isEmpty from 'lodash/isEmpty';
import { isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/lab/Skeleton';
import { useParams } from 'react-router';

// Icons
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';

import VineaAlertMessage from './VineaAlertMessage';
import { StyledTableCell } from './StyledTableCell';
import {
  VineaButton,
  IconToolTipButton,
} from '../../../components/VineaButton';
import { getFormWriteData } from '../../../redux/selectors';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { useIdRelationshipHook } from '../hooks/useIdRelationshipHook';
import {
  RelationshipComponent,
  RelationshipCreate,
} from '../../../components/RelationshipComponent';
import { types } from '../stateReducers';
import { sagaActionTypes } from '../../../constants';
import { formatDate } from '../../../constants/formatter';
import useUserResponsiveLayout from '../../../hooks/useUserResponsiveLayout';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

const CustomTableCell = ({ row, name, onChange }) => {
  const { isEditMode } = row;
  const handleOnChange = e => {
    onChange(e, row);
  };

  return (
    <StyledTableCell align="left" manageStyle>
      {isEditMode && (
        <RelationshipComponent
          name={name}
          formdata={row}
          onChange={handleOnChange}
        />
      )}
      {!isEditMode &&
        name !== 'parent' &&
        name !== 'relatesTo' &&
        name !== 'effectiveFromDate' &&
        name !== 'effectiveToDate' &&
        row[name]}
      {!isEditMode &&
        (name === 'effectiveFromDate' || name === 'effectiveToDate') &&
        formatDate(row[name])}
      {!isEditMode && name === 'relatesTo' && row.relationshipFrom}
      {!isEditMode && name === 'parent' && (
        <>
          {row.direction === 'Owner' && row.dependentName}
          {row.direction === 'Dependent' && row.ownerName}
        </>
      )}
    </StyledTableCell>
  );
};

export const ManageRelationships = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatchAPI = useDispatch();
  const [alertOpen, setAlertOpen] = useState(true);
  const [disableIcon, setDisableIcon] = useState(false);
  const [expandedCreate, setCreateExpanded] = React.useState(false);
  const [expandedManage, setManageExpanded] = React.useState(true);
  const [addAccordionState, setAddAccordionState] = useState(false);
  const [formWriteLoadingStatus, setFormWriteLoading] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  const identityTypeId = useIdentityTypeId(vineyardTypeScreenName);
  const { id: pathParam } = useParams();

  let { identityId } = props;
  if (isNull(identityId)) {
    identityId = pathParam;
  }

  const { isAdministrator } = useUserResponsiveLayout();

  const {
    identityRelationship: rows,
    relatedIdentityOptions,
    lkpRelationship,
    relatesToList,
    formdata,
    alert,
    duplicateWarning,
    isLoadingIdRelationship: isLoading,
    isLoadedIdRelationship,
    hasErrorIdRelationship,
    actionTriggered,
    seqToSave,
    canDeleteRelationship,
    handleRevert,
    handleOnBlur,
    handleOnSaveRow,
    handleOnDeleteRow,
    setSeqToSave,
    setActionTriggered,
    handleOnChangeRowData,
    handleOnChangeNewRowData,
    dispatch,
    onToggleEditMode,
  } = useIdRelationshipHook(identityId);

  const {
    isLoading: isFormSubmitLoading,
    hasError: hasErrorFormSubmit,
    error: formErrorMessage,
    isLoaded,
  } = useSelector(getFormWriteData);

  const onSave = id => {
    handleOnSaveRow(id);
  };

  const onDelete = id => {
    handleOnDeleteRow(id);
  };

  const handleOnAddNew = () => {
    setDisableIcon(true);
    setAddAccordionState(true);
    setCreateExpanded(true);
  };

  const onEdit = id => {
    setDisableIcon(true);
    onToggleEditMode(id, rows);
  };

  const onRevert = id => {
    setDisableIcon(false);
    handleRevert(id);
  };

  const handleCancel = () => {
    dispatch({
      type: types.INITIAL,
      name: 'relationship',
    });
    setDisableIcon(false);
    setAddAccordionState(false);
    setCreateExpanded(false);
  };

  const handleAccordionCreateChange = panel => (event, isExpanded) => {
    setCreateExpanded(isExpanded ? panel : false);
  };
  const handleAccordionManageChange = panel => (event, isExpanded) => {
    setManageExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (actionTriggered) {
      setDisableIcon(false);
      if (isFormSubmitLoading) setFormWriteLoading(true);
      if (
        !isFormSubmitLoading &&
        formWriteLoadingStatus &&
        isLoaded &&
        !hasErrorFormSubmit
      ) {
        setFormWriteLoading(false);
        if (!isNull(identityId)) {
          dispatchAPI({
            type: sagaActionTypes.FETCH_IDENTITIES,
            payload: {
              id: identityId,
              identityTypeId,
            },
          });
        }
      }
    }
  });

  useEffect(() => {
    /** When saving the selected reference id */
    if (actionTriggered) {
      if (!isLoading && isLoadedIdRelationship) {
        if (hasErrorIdRelationship) {
          // set form submitting has errors;
          setAlertOpen(true);
          setDisableIcon(false);
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else if (!hasErrorIdRelationship) {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          setAlertType(true);
          onToggleEditMode(seqToSave, rows);
          dispatch({
            type: types.INITIAL,
            name: 'relationship',
          });
          setAddAccordionState(false);
          setCreateExpanded(false);
          setManageExpanded(true);
        }

        setActionTriggered(false);
        setSeqToSave(null);
      }
    }
  }, [
    actionTriggered,
    setActionTriggered,
    setSeqToSave,
    isFormSubmitLoading,
    hasErrorFormSubmit,
    isLoaded,
    enqueueSnackbar,
    seqToSave,
    t,
    rows,
    onToggleEditMode,
    dispatch,
    formdata,
    isLoading,
    isLoadedIdRelationship,
    hasErrorIdRelationship,
  ]);

  const onCloseAlert = () => {
    setAlertType(false);
    setAlertOpen(false);
  };

  return (
    <Accordion
      expanded={expandedManage === true}
      onChange={handleAccordionManageChange(true)}
      data-testid="accordion-table"
      sx={{
        '& .Mui-expanded': {
          marginTop: '-16px',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="accordion-table-summary"
        data-testid="accordion-table-summary"
      >
        <Typography variant="h6">{t('Manage Relationships')}</Typography>
      </AccordionSummary>
      <Paper
        sx={{
          width: '100%',
          overflowX: 'auto',
        }}
        data-tesitd="paper-outline"
      >
        <Box paddingTop={1} />
        <Box px={2}>
          {hasErrorFormSubmit && (
            <Collapse in={alertOpen}>
              <Alert
                variant="outlined"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {formErrorMessage}
              </Alert>
            </Collapse>
          )}
          <VineaAlertMessage
            alertType={alertType}
            onExit={onCloseAlert}
            alert={alert}
            type="Relationship"
          />
        </Box>
        <Table
          sx={{
            minWidth: 550,
          }}
          aria-label="relationships"
          size="small"
          data-testid="relationshiprows"
          id="edit-relationships"
        >
          <TableHead data-testid="tablehead">
            <TableRow>
              <TableCell align="left" />
              <TableCell align="left">{t('Relationship From')}</TableCell>
              <TableCell align="left">{t('Dependent/Owner')}</TableCell>
              <TableCell align="left">{t('')}</TableCell>
              <TableCell align="left">{t('Comments')}</TableCell>
              <TableCell align="left">{t('Effective From')}</TableCell>
              <TableCell align="left">{t('Effective To')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={11}>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              rows.map((row, idx) => (
                <>
                  <TableRow
                    key={row.id}
                    data-testid={row.id}
                    data-testrow="table-row"
                    data-seq={idx + 1}
                    data-newrow={row.isEditMode}
                    sx={{ height: 20 }}
                  >
                    <StyledTableCell iconStyle>
                      {row.isEditMode ? (
                        <>
                          <Box
                            sx={{
                              display: 'flex',
                              flex: 1,
                            }}
                          >
                            <IconToolTipButton
                              size="small"
                              tooltip="Save"
                              label="done"
                              data-testid="iconsave"
                              onClick={() => {
                                onSave(row.id);
                              }}
                              sx={{
                                color: theme.palette.success.main,
                              }}
                              disabled={!!row.warning || !isEmpty(row.errors)}
                            >
                              <DoneAllIcon />
                            </IconToolTipButton>
                            <IconToolTipButton
                              size="small"
                              tooltip="Cancel"
                              data-testid="iconcancel"
                              onClick={() => {
                                onRevert(row.id);
                              }}
                              sx={{
                                color: theme.palette.error.main,
                              }}
                            >
                              <NotInterestedOutlinedIcon />
                            </IconToolTipButton>
                            {isAdministrator && canDeleteRelationship && (
                              <IconToolTipButton
                                size="small"
                                tooltip="Delete"
                                data-testid="icondeletemanagerelationships"
                                onClick={() => {
                                  onDelete(row.id);
                                }}
                                sx={{
                                  color: theme.palette.error.main,
                                }}
                              >
                                <DeleteIcon />
                              </IconToolTipButton>
                            )}
                          </Box>
                        </>
                      ) : (
                        <>
                          <IconToolTipButton
                            size="small"
                            disabled={disableIcon}
                            tooltip="Edit"
                            data-testid="iconedit"
                            onClick={() => {
                              onEdit(row.id);
                            }}
                            sx={{
                              color: '#404040',
                              marginTop: theme.spacing(2) * -1,
                            }}
                          >
                            <ModeEditOutlineOutlinedIcon />
                          </IconToolTipButton>
                        </>
                      )}
                    </StyledTableCell>
                    {[
                      'relatesTo',
                      'parent',
                      'additionalText',
                      'comment',
                      'effectiveFromDate',
                      'effectiveToDate',
                    ].map(cls => {
                      return (
                        <CustomTableCell
                          {...{
                            row,
                            name: cls,
                            onChange: handleOnChangeRowData,
                          }}
                        />
                      );
                    })}
                  </TableRow>
                  {row.warning && (
                    <TableRow>
                      <TableCell colSpan={11}>
                        <Alert severity="warning">{row.warning}</Alert>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
          </TableBody>
        </Table>

        {addAccordionState && (
          <Accordion
            expanded={expandedCreate === true}
            onChange={handleAccordionCreateChange(true)}
            data-testid="relationship-accordion"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="addrelationshipsummary"
            >
              {t('Add Relationship Data')}
            </AccordionSummary>

            <RelationshipCreate
              t={t}
              formdata={formdata}
              relatesToList={relatesToList}
              onChange={handleOnChangeNewRowData}
              onSave={onSave}
              onBlur={handleOnBlur}
              onCancel={handleCancel}
              relatedIdentityOptions={relatedIdentityOptions}
              lkpRelationship={lkpRelationship}
              duplicateWarning={duplicateWarning}
            />
          </Accordion>
        )}
        {!addAccordionState && (
          <Box py={1}>
            <VineaButton
              color="secondary"
              sx={{ marginLeft: theme.spacing(3) }}
              onClick={handleOnAddNew}
              variant="contained"
              minWidth={100}
              onBlur={handleOnBlur}
              data-testid="addnewrelationship"
              disabled={rows.some(row => row.isEditMode)}
            >
              {t('Add Relationships')}
            </VineaButton>
          </Box>
        )}
      </Paper>
    </Accordion>
  );
};

ManageRelationships.propTypes = {
  identityId: PropTypes.string,
};

ManageRelationships.defaultProps = {
  identityId: null,
};

CustomTableCell.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    identityID: PropTypes.number,
    relatesTo: PropTypes.number,
    relationshipName: PropTypes.string,
    relationshipTypeID: PropTypes.number,
    relationshipFrom: PropTypes.string,
    relationshipType: PropTypes.string,
    ownerIdentityID: PropTypes.number,
    ownerName: PropTypes.string,
    dependentIdentityID: PropTypes.number,
    dependentName: PropTypes.string,
    comment: PropTypes.string,
    isDisplayOnPanel: PropTypes.bool,
    effectiveFromDate: PropTypes.string,
    effectiveToDate: PropTypes.string,
    isEditMode: PropTypes.bool,
    methodType: PropTypes.string,
    isActive: PropTypes.bool,
    dependent: PropTypes.string,
    direction: PropTypes.string,
    isAdditionalText: PropTypes.bool,
    additionalText: PropTypes.string,
    errors: PropTypes.string,
    ts: PropTypes.string,
  }),
};

CustomTableCell.defaultProps = {
  row: {},
  name: '',
  onChange: f => f,
};
