import React, { useReducer, useEffect, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import { filter, get, isEmpty, isEqual, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import { FormHelperText } from '@mui/material';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';

import { FieldLabelInput } from '../../../components/TextField';
import {
  addGenericReducer,
  types,
  serviceproviderState,
} from '../../VineyardIdentity/stateReducers';
import { syncValidator } from '../../../utils/validator';
import { ServiceProviderSchema } from '../../VineyardIdentity/validations';
import { VineaButton } from '../../../components/VineaButton';
import { VineaAlert } from '../../../components/VineaAlert';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import {
  apiTypes,
  dateFormat,
  IdentityTypeIds,
  reducers,
  sagaActionTypes,
  vineaDetails,
} from '../../../constants';
import {
  getBusinessUnit,
  getPayrollSystemData,
} from '../../../redux/selectors';
import { SplitButton } from '../../../components/SplitButton';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { DiscardDialog } from '../../../components/Dialog';
import { statechecker } from '../../../utils/statecomparator';
import {
  IdentityActivationSwitch,
  DeleteIdentityAlert,
  DeleteIdentityButton,
} from '../../../components/IdentityComponents';
import { useIdentityStatus } from '../../../hooks/useIdentityStatus';
// import { useIdVineyardHook } from '../hooks/useIdVineyardHook';

// import logger from '../../../utils/winstonLogger';

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 350,
  '& .MuiFormControl-root': {
    minWidth: 350,
  },
}));

export const ManageServiceProvider = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const today = format(new Date(), dateFormat);
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = React.useState(false);
  const [initialAPITrigger, setInitialAPITrigger] = useState(true);
  const [formLoadingState, setFormLoadingState] = useState(false);
  const [saveIndex, setSaveIndex] = useState(0);
  const identityTypeIdVal = useIdentityTypeId();
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [formWriteReloadState, setFormWriteReloadState] = useState(false);
  const payrollSystemOptions = useSelector(getPayrollSystemData);
  const businessUnitList = useSelector(state => getBusinessUnit(state));
  const { data: rolesData = [] } = useSelector(
    state => state.entities.identityRole,
  );
  const activeRoles = useMemo(() => {
    return filter(rolesData, role => isEmpty(get(role, 'effectiveToDate')));
  }, [rolesData]);
  const [deleteHasFailed, setDeleteHasFailed] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const {
    isLoading: serviceProviderIsLoading,
    isLoaded: serviceProviderIsLoaded,
    hasError: serviceProviderHasError,
  } = useSelector(VineaNovaSelectors.getIdentityServiceProviderEntityMeta);

  const isActive = useIdentityStatus();

  const { id: pathParam } = useParams();

  const [formdata, dispatch] = useReducer(
    addGenericReducer,
    serviceproviderState,
  );
  const {
    isLoading,
    isLoaded,
    hasError,
    data: serviceproviderData,
    error,
  } = useSelector(state => state.formWrite);

  let errorFromAPI = '';

  if (error) {
    errorFromAPI = error.data;
  }

  const [updateApiStatus, setUpdateApiStatus] = React.useState(false);

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleManageRoles = () => {
    navigate(`/organisation/serviceproviders/${pathParam}/manageroles`);
  };

  const handleOnBlurOrgName = () => {
    const validationErrors = syncValidator(ServiceProviderSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  const compareObjects = (obj1, obj2, fields) => {
    const result = isEqual(omit(obj1, [...fields]), omit(obj2, [...fields]));
    return result;
  };

  const handleSave = (event, index) => {
    setSaveIndex(index);
    const validationErrors = syncValidator(ServiceProviderSchema)(formdata);
    if (!isEmpty(validationErrors)) {
      dispatch({ type: types.ERROR, payload: validationErrors });
    } else if (compareObjects(serviceproviderData, formdata, ['errors'])) {
      setWarningInSubmit(true);
    } else {
      setWarningInSubmit(false);
      setErrorInSubmit(false);
      const data = {
        id: serviceproviderData.id,
        identityTypeID: IdentityTypeIds.SERVICE_PROVIDER,
        serviceProviderName: formdata.serviceProviderName,
        serviceProviderKnownAs: formdata.serviceProviderKnownAs || '',
        payrollSystemID: formdata.payrollSystemID,
        businessUnitID: formdata.businessUnitID,
        preferredContactMethodTypeID: null,
        isPrimary: formdata.isPrimary,
        createdDate: today,
        ts: serviceproviderData.ts,
      };
      dispatchAPI({
        // need to change payload
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.serviceProvider,
          methodType: apiTypes.PUT,
        },
      });
      setUpdateApiStatus(true);
      setFormWriteReloadState(true);
    }
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleClose = () => {
    if (!saveButtonStatus) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleOnToggleActive = () => {
    navigate(`/organisation/serviceproviders/${pathParam}/managestatus`);
  };

  const handleOnConfirmDelete = () => {
    if (pathParam) {
      setIsDeleting(true);
      dispatchAPI(
        VineaNovaActions.api.v1.identityServiceProvider.delete.request({
          queryParams: {
            IdentityID: pathParam,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (!isLoading && isLoaded && !isEmpty(serviceproviderData) && !hasError) {
      const stubFormData = omit(formdata, 'errors');
      if (statechecker(stubFormData, serviceproviderData)) {
        setSaveButtonStatus(true);
      } else setSaveButtonStatus(false);
    }
  }, [isLoading, isLoaded, hasError, formdata, serviceproviderData]);

  useEffect(() => {
    if (isLoaded && !hasError && initialAPITrigger) {
      setInitialAPITrigger(false);
      setFormLoadingState(false);
      dispatch({
        type: types.UPDATE,
        payload: serviceproviderData,
      });
    }
  }, [
    isLoaded,
    hasError,
    initialAPITrigger,
    isLoading,
    formLoadingState,
    serviceproviderData,
  ]);

  useEffect(() => {
    if (isLoading) {
      setFormLoadingState(true);
    }
    if (!hasError && isLoaded && updateApiStatus && formLoadingState) {
      enqueueSnackbar('Success!', { variant: 'success' });
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
      if (saveIndex === 0)
        navigate(`/organisation/serviceproviders/${serviceproviderData.id}`);
      else if (formWriteReloadState && saveIndex === 1) {
        setFormWriteReloadState(false);
        dispatchAPI({
          type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
          payload: { id: serviceproviderData.id, name: reducers.vineyard },
        });
      }
      setFormLoadingState(false);
    } else if (hasError && isLoaded && updateApiStatus) {
      setErrorInSubmit(true);
      enqueueSnackbar(t('Error'), { variant: 'Error' });
    }
  }, [
    isLoaded,
    updateApiStatus,
    enqueueSnackbar,
    t,
    navigate,
    hasError,
    saveIndex,
    warningInSubmit,
    isLoading,
    formLoadingState,
    dispatchAPI,
    identityTypeIdVal,
    formWriteReloadState,
    serviceproviderData.id,
  ]);

  useEffect(() => {
    if (isDeleting && !serviceProviderIsLoading && serviceProviderIsLoaded) {
      if (serviceProviderHasError) {
        setDeleteHasFailed(true);
      } else {
        navigate('/organisation/serviceproviders');
      }

      setIsDeleting(false);
    }
  }, [
    serviceProviderIsLoaded,
    serviceProviderIsLoading,
    serviceProviderHasError,
    isDeleting,
    setIsDeleting,
  ]);

  return (
    <Paper
      elevation={0}
      data-testid="managevineyard-detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12}>
          <Box mb={4}>
            <VineaAlert
              isOpen={!!errorInSubmit}
              onExit={() => setErrorInSubmit(false)}
            >
              <p>{errorFromAPI}</p>
            </VineaAlert>
            <DeleteIdentityAlert
              isOpen={deleteHasFailed}
              onExit={() => setDeleteHasFailed(false)}
              identityName="service provider"
            />
          </Box>
          <Box px={2}>
            {warningInSubmit && (
              <Collapse in={warningInSubmit}>
                <Alert
                  variant="standard"
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarningInSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {t('Warning: Nothing to Update')}
                </Alert>
              </Collapse>
            )}
          </Box>
          <StyledBox mt={1}>
            <FieldLabelInput
              sx={{
                marginTop: theme.spacing(1),
                minWidth: 350,
                top: 'auto',
                left: 'auto',
                position: 'unset',
                transform: 'none',
                display: 'block',
                'transform-origin': 'top left',
              }}
              autoComplete="off"
              id="name"
              name="serviceProviderName"
              label={t('Name')}
              size="small"
              inlineLabel
              value={formdata.serviceProviderName}
              onChange={handleChange}
              onBlur={handleOnBlurOrgName}
              error={formdata.errors.serviceProviderName}
              helperText={formdata.errors.serviceProviderName}
            />
          </StyledBox>
          <StyledBox mt={1}>
            <FieldLabelInput
              sx={{
                marginTop: theme.spacing(1),
                minWidth: 350,
                top: 'auto',
                left: 'auto',
                position: 'unset',
                transform: 'none',
                display: 'block',
                'transform-origin': 'top left',
              }}
              id="knownas"
              name="serviceProviderKnownAs"
              label={t('Known As')}
              size="small"
              inlineLabel
              value={formdata.serviceProviderKnownAs}
              onChange={handleChange}
              onBlur={handleOnBlurOrgName}
              error={formdata.errors.serviceProviderKnownAs}
              helperText={formdata.errors.serviceProviderKnownAs}
            />
          </StyledBox>
          <Box mx={2} mt={1}>
            <FormControl
              sx={{
                marginTop: theme.spacing(1),
                minWidth: 350,
              }}
              margin="none"
              error={!!formdata.errors.payrollSystemID}
            >
              <VineaAutoComplete
                value={formdata.payrollSystemID}
                onBlur={handleOnBlurOrgName}
                onChange={handleChange}
                options={payrollSystemOptions}
                labelVariant="body1"
                name={t('Payroll System ID')}
                label={t('Payroll System ID')}
                inlineLabel={false}
                inputProps={{
                  name: 'payrollSystemID',
                }}
              />
              {formdata.errors.payrollSystemID && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.payrollSystemID}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <StyledBox mx={2} mt={1}>
            <FormControl
              sx={{
                marginTop: theme.spacing(1),
                minWidth: 350,
              }}
              margin="none"
              error={!!formdata.errors.businessUnitID}
            >
              <VineaAutoComplete
                value={formdata.businessUnitID}
                onChange={handleChange}
                onBlur={handleOnBlurOrgName}
                options={businessUnitList}
                labelVariant="body1"
                name={t('Business Unit')}
                label={t('Business Unit')}
                inlineLabel={false}
                inputProps={{
                  name: 'businessUnitID',
                }}
              />
              {formdata.errors.businessUnitID && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.businessUnitID}
                </FormHelperText>
              )}
            </FormControl>
          </StyledBox>
          <Box
            m={2}
            sx={{
              width: '350px',
              minWidth: '250px',
            }}
          >
            <div>{t('Current Role')}</div>
            <div>
              <div>
                <ol>
                  {!isEmpty(activeRoles) &&
                    Array.isArray(activeRoles) &&
                    activeRoles.map(item => (
                      <li key={item.id}>{item.roleType}</li>
                    ))}
                </ol>
              </div>
              <div>
                <VineaButton
                  data-testid="BtnManageRoles"
                  autoFocus
                  onClick={handleManageRoles}
                  variant="text"
                  color="secondary"
                  textTransform="capitalize"
                >
                  {t('Manage Roles')}
                </VineaButton>
              </div>
            </div>
          </Box>
          <IdentityActivationSwitch
            handleOnToggleActive={handleOnToggleActive}
            isActive={isActive}
            identityName="service provider"
          />
          <Box my={2}>
            <DeleteIdentityButton
              handleOnDelete={handleOnConfirmDelete}
              identityName="service provider"
              isDisabled={deleteHasFailed}
            />
          </Box>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
            }}
          >
            <Box mx={2} mt={1}>
              <SplitButton
                color="success"
                onClick={handleSave}
                variant="contained"
                minWidth={130}
                disabled={saveButtonStatus}
                data-testid="btnsuccess"
              />
            </Box>
            <Box mx={2} mt={1}>
              <VineaButton
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                {t('Close')}
              </VineaButton>
            </Box>
          </Grid>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={handleOnDialogClose}
            handleSaveChanges={handleSave}
            handleDiscardChanges={handleCancel}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
