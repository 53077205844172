import React, { useReducer, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { isEmpty, omit, find, max, map, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/lab/Alert';
import { useSnackbar } from 'notistack';
import { FormHelperText, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  VineaNovaActions,
  // VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { FieldLabelInput } from '../../../components/TextField';
import {
  addGenericReducer,
  types,
  planState,
} from '../../VineyardIdentity/stateReducers';
import { syncValidator } from '../../../utils/validator';
import { PlanSchema } from '../validations';
import { VineaButton } from '../../../components/VineaButton';
import { VineaAlert } from '../../../components/VineaAlert';
import {
  DeleteIdentityAlert,
  DeleteIdentityButton,
} from '../../../components/IdentityComponents';
import {
  apiTypes,
  viewDateFormat,
  reducers,
  sagaActionTypes,
  vineaDetails,
} from '../../../constants';
import {
  getBusinessUnit,
  getLookupVintage,
  getPlanStatus,
  getPlanType,
} from '../../../redux/selectors';
import { SplitButton } from '../../../components/SplitButton';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { DiscardDialog } from '../../../components/Dialog';
import { statechecker } from '../../../utils/statecomparator';
import { Datepicker } from '../../../components/Datepicker';
import { formatDate } from '../../../constants/formatter';

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: 300,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 300,
  '& .MuiFormControl-root': {
    minWidth: 300,
  },
  '& .MuiInputBase-input': {
    minWidth: 250,
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  root: {
    marginTop: theme.spacing(1),
    minWidth: 300,
  },
}));

const ManagePlan = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = React.useState(false);
  const [initialAPITrigger, setInitialAPITrigger] = useState(true);
  const [formLoadingState, setFormLoadingState] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [saveIndex, setSaveIndex] = useState(0);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [formWriteReloadState, setFormWriteReloadState] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [formdata, dispatch] = useReducer(addGenericReducer, planState);
  const lkpPlanStatus = useSelector(state => getPlanStatus(state));
  const lkpPlanType = useSelector(state => getPlanType(state));
  const lkpBusinessUnit = useSelector(state => getBusinessUnit(state));
  const lkpVintage = useSelector(getLookupVintage);
  const [deleteHasFailed, setDeleteHasFailed] = useState(false);
  const [validationsErrors, setValidationErrors] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [refreshVintageDates, setRefreshVintageDates] = React.useState(false);

  const latestVintageID = max(map(lkpVintage, 'id'));
  const selectedVintage = isNil(formdata.planVintageID)
    ? find(lkpVintage, v => v?.id === latestVintageID)
    : find(lkpVintage, v => v?.id === formdata.planVintageID);

  const { id: pathParam } = useParams();
  const {
    isLoading,
    isLoaded,
    hasError,
    data: planData,
    error,
  } = useSelector(state => state.formWrite);

  let errorFromAPI = '';

  if (error) {
    console.log('erroe', error);
    errorFromAPI = error.data;
  }

  const [updateApiStatus, setUpdateApiStatus] = React.useState(false);

  useEffect(() => {
    if (isDeleting) {
      navigate('/plans');
      setIsDeleting(false);
    }
  }, [isDeleting]);

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
    setHasChanges(true);
  };

  const handleSave = (event, index) => {
    const findDate = lkpVintage.find(obj => obj.id === formdata.planVintageID);
    setSaveIndex(index);
    // we are creating 2 new object elements without changing formdata and then assigning the start and end date of findDate to new object elements
    const validationErrors = syncValidator(PlanSchema)({
      ...formdata,
      yearStart: new Date(findDate.fromDate), // Pass in the updated value of yearStart, or an empty string if it's undefined
      yearEnd: new Date(findDate.toDate), // Pass in the updated value of yearEnd, or an empty string if it's undefined
    });
    console.log('ValidationErrors', validationErrors);

    if (!isEmpty(validationErrors)) {
      setValidationErrors(validationErrors);
    } else if (
      planData.planName === formdata.planName &&
      planData.planDescription === formdata.planDescription &&
      planData.planTypeID === formdata.planTypeID &&
      planData.planStatusID === formdata.planStatusID &&
      planData.isLocked === formdata.isLocked &&
      planData.planStartDate === formdata.planStartDate &&
      planData.planEndDate === formdata.planEndDate &&
      planData.businessUnitID === formdata.businessUnitID &&
      planData.planVintageID === formdata.planVintageID
    ) {
      setWarningInSubmit(true);
    } else {
      setWarningInSubmit(false);
      setErrorInSubmit(false);
      const data = {
        id: planData.id,
        planName: formdata.planName,
        planDescription: formdata.planDescription,
        planTypeID: formdata.planTypeID,
        planStatusID: formdata.planStatusID,
        isLocked: formdata.isLocked,
        planStartDate: new Date(formdata.planStartDate),
        planEndDate: new Date(formdata.planEndDate),
        planVintageID: formdata.planVintageID,
        durationID: 1,
        businessUnitID: formdata.businessUnitID,
        blocks: [],
        ts: planData.ts,
      };
      dispatchAPI({
        // need to change payload
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.plans,
          methodType: apiTypes.PUT,
        },
      });
      setUpdateApiStatus(true);
      setFormWriteReloadState(true);
    }
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleClose = () => {
    if (!saveButtonStatus && hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleOnConfirmDelete = () => {
    if (pathParam) {
      setIsDeleting(true);
      dispatchAPI(
        VineaNovaActions.api.v1.plans.delete.request({
          postBody: {
            id: pathParam,
            ts: planData.ts,
          },
        }),
      );
    }
  };

  const handleOnToggleActive = () => {
    navigate(`/plans/${pathParam}/managestatus`);
  };

  useEffect(() => {
    if (!isLoading && isLoaded && !isEmpty(planData) && !hasError) {
      const stubPlanData = {
        ...planData,
      };
      const stubFormData = omit(formdata, 'errors');
      if (statechecker(stubFormData, stubPlanData)) {
        setSaveButtonStatus(true);
      } else setSaveButtonStatus(false);
    }
  }, [isLoading, isLoaded, planData, hasError, formdata]);

  useEffect(() => {
    if (isLoaded && !hasError && initialAPITrigger) {
      setInitialAPITrigger(false);
      setFormLoadingState(false);
      dispatch({
        type: types.UPDATE,
        payload: {
          planName: planData.planName,
          planDescription: planData.planDescription,
          planTypeID: planData.planTypeID,
          planStatusID: planData.planStatusID,
          isLocked: planData.isLocked,
          planStartDate: planData.planStartDate,
          planEndDate: planData.planEndDate,
          planVintageID: planData.planVintageID,
          durationID: 1,
          businessUnitID: planData.businessUnitID,
          blocks: [],
        },
      });
    }
  }, [
    isLoaded,
    hasError,
    initialAPITrigger,
    planData.planName,
    planData.planDescription,
    planData.planTypeID,
    planData.planStatusID,
    planData.isLocked,
    planData.planStartDate,
    planData.planEndDate,
    planData.planVintageID,
    planData.businessUnitID,
    isLoading,
    formLoadingState,
  ]);

  useEffect(() => {
    if (isLoading) {
      setFormLoadingState(true);
    }
    if (!hasError && isLoaded && updateApiStatus && formLoadingState) {
      enqueueSnackbar('Success!', { variant: 'success' });
      setAlertType(true);
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
      if (saveIndex === 0) navigate(-1);
      else if (formWriteReloadState && saveIndex === 1) {
        setFormWriteReloadState(false);
        dispatchAPI({
          type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
          payload: { id: pathParam, name: reducers.plan },
        });
      }
      setFormLoadingState(false);
    } else if (hasError && isLoaded && updateApiStatus) {
      setErrorInSubmit(true);
      enqueueSnackbar(t('Error'), { variant: 'Error' });
    }
  }, [
    isLoaded,
    updateApiStatus,
    enqueueSnackbar,
    t,
    navigate,
    hasError,
    planData.id,
    saveIndex,
    warningInSubmit,
    isLoading,
    formLoadingState,
    dispatchAPI,
    formWriteReloadState,
    pathParam,
  ]);

  // // Plan start and end dates default to start and end dates of vintage
  useEffect(() => {
    if (refreshVintageDates) {
      const vintage = find(lkpVintage, v => v?.id === formdata.planVintageID);
      handleChange({
        target: { name: 'planStartDate', value: vintage.fromDate },
      });
      handleChange({
        target: { name: 'planEndDate', value: vintage.toDate },
      });
    }
  }, [refreshVintageDates]);

  return (
    <Paper
      elevation={0}
      data-testid="manageplan-detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12}>
          <Box mb={4}>
            <VineaAlert
              isOpen={!!errorInSubmit}
              onExit={() => setErrorInSubmit(false)}
            >
              <p>{errorFromAPI}</p>
            </VineaAlert>
            <VineaAlert
              isOpen={alertType}
              onExit={() => setAlertType(false)}
              alertType="success"
              message="Plan Details Updated"
            />
            <DeleteIdentityAlert
              isOpen={deleteHasFailed}
              onExit={() => setDeleteHasFailed(false)}
              identityName="plan"
            />
          </Box>
          <Box px={2}>
            {warningInSubmit && (
              <Collapse in={warningInSubmit}>
                <Alert
                  variant="standard"
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarningInSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Warning: Nothing to Update
                </Alert>
              </Collapse>
            )}
          </Box>
          <Grid container spacing={2} data-testid="grid-details">
            <Stack direction="row" justifyContent="space-around">
              <Box px={2}>
                <StyledFormBoxControl mt={1}>
                  <FormControl>
                    <StyledFieldLabelInput
                      autoComplete="off"
                      id="planName"
                      name="planName"
                      label={t('Plan Name')}
                      size="small"
                      inlineLabel
                      value={formdata.planName}
                      onChange={handleChange}
                      error={validationsErrors.planName}
                    />
                  </FormControl>
                </StyledFormBoxControl>
                <StyledFormBoxControl mt={1}>
                  <FormControl>
                    <StyledFieldLabelInput
                      id="planDescription"
                      name="planDescription"
                      label={t('Plan Description')}
                      inlineLabel
                      minWidth="250px"
                      value={formdata.planDescription}
                      onChange={handleChange}
                      error={validationsErrors.planDescription}
                      multiline
                      rowsMax={4}
                      rows={13}
                    />
                  </FormControl>
                </StyledFormBoxControl>
              </Box>
              <Box px={2}>
                <StyledBox mt={3}>
                  <FormControl
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                    }}
                    error={!!validationsErrors.planTypeID}
                    margin="none"
                  >
                    <VineaAutoComplete
                      value={formdata.planTypeID}
                      onChange={handleChange}
                      inlineLabel={false}
                      options={lkpPlanType}
                      labelVariant="body1"
                      label={t('Plan Type')}
                      name={t('Plan Type')}
                      id="planType"
                      displayLabel
                      inputProps={{
                        name: 'planTypeID',
                      }}
                    />
                    {validationsErrors.planTypeID && (
                      <FormHelperText id="component-error-text">
                        {t(validationsErrors.planTypeID)}
                      </FormHelperText>
                    )}
                  </FormControl>
                </StyledBox>
                <StyledBox mt={3}>
                  <FormControl
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                    }}
                    error={!!validationsErrors.planStatusID}
                    margin="none"
                  >
                    <VineaAutoComplete
                      value={formdata.planStatusID}
                      onChange={handleChange}
                      inlineLabel={false}
                      options={lkpPlanStatus}
                      labelVariant="body1"
                      label={t('Plan Status')}
                      name={t('Plan Status')}
                      id="planStatusID"
                      displayLabel
                      inputProps={{
                        name: 'planStatusID',
                      }}
                    />
                    {validationsErrors.planStatusID && (
                      <FormHelperText id="component-error-text">
                        {t(validationsErrors.planStatusID)}
                      </FormHelperText>
                    )}
                  </FormControl>
                </StyledBox>
                <StyledBox mt={3}>
                  <FormControl
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                    }}
                    error={!!validationsErrors.planVintageID}
                    margin="none"
                  >
                    <VineaAutoComplete
                      value={formdata.planVintageID || 0}
                      onChange={e => {
                        setRefreshVintageDates(true);
                        handleChange(e);
                      }}
                      inlineLabel={false}
                      options={lkpVintage}
                      labelVariant="body1"
                      label={t('Vintage')}
                      name={t('Plan Vintage')}
                      id="vintageID"
                      displayLabel
                      inputProps={{
                        name: 'planVintageID',
                      }}
                    />
                    {validationsErrors.planVintageID && (
                      <FormHelperText id="component-error-text">
                        {t(validationsErrors.planVintageID)}
                      </FormHelperText>
                    )}
                  </FormControl>
                </StyledBox>
                <StyledBox mt={3}>
                  <StyledFormControl
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                    }}
                    error={!!validationsErrors.businessUnitID}
                    data-testid="datePlanted-formcontrol"
                  >
                    <VineaAutoComplete
                      value={formdata.businessUnitID}
                      onChange={handleChange}
                      inlineLabel={false}
                      options={lkpBusinessUnit}
                      labelVariant="body1"
                      label={t('Business Unit')}
                      name={t('Business Unit')}
                      id="businessUnitID"
                      displayLabel
                      inputProps={{
                        name: 'businessUnitID',
                      }}
                    />
                    {formdata.errors.businessUnitID && (
                      <FormHelperText id="component-error-text">
                        {validationsErrors.businessUnitID}
                      </FormHelperText>
                    )}
                  </StyledFormControl>
                </StyledBox>
                <StyledBox mt={4}>
                  <StyledFormControl
                    error={!!validationsErrors.planStartDate}
                    data-testid="planStartDate-formcontrol"
                  >
                    <Datepicker
                      minDate={selectedVintage.fromDate}
                      id="planStartDate"
                      label={t('Plan Start Date')}
                      placeholder="DD/MM/YYYY"
                      inputFormat={viewDateFormat}
                      formatDate={formatDate}
                      variant="outlined"
                      size="small"
                      name="planStartDate"
                      value={formdata.planStartDate}
                      onChange={handleChange}
                      inputProps={{
                        'data-testid': 'planStartDate',
                        'data-name': 'planStartDate',
                      }}
                    />
                    {validationsErrors.planStartDate && (
                      <FormHelperText id="component-error-text">
                        {validationsErrors.planStartDate}
                      </FormHelperText>
                    )}
                  </StyledFormControl>
                </StyledBox>
                <StyledBox mt={4}>
                  <StyledFormControl
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                    }}
                    error={!!validationsErrors.planEndDate}
                    data-testid="planEndDate-formcontrol"
                  >
                    <Datepicker
                      minmaxDate={selectedVintage.toDate}
                      id="planEndDate"
                      label={t('Plan End Date')}
                      placeholder="DD/MM/YYYY"
                      inputFormat={viewDateFormat}
                      formatDate={formatDate}
                      variant="outlined"
                      size="small"
                      name="planEndDate"
                      value={formdata.planEndDate}
                      onChange={handleChange}
                      inputProps={{
                        'data-testid': 'planEndDate',
                        'data-name': 'planEndDate',
                      }}
                    />
                    {validationsErrors.planEndDate && (
                      <FormHelperText id="component-error-text">
                        {validationsErrors.planEndDate}
                      </FormHelperText>
                    )}
                  </StyledFormControl>
                </StyledBox>
              </Box>
            </Stack>
          </Grid>
          <Box my={2}>
            <DeleteIdentityButton
              handleOnDelete={handleOnConfirmDelete}
              identityName="plan"
              isDisabled={deleteHasFailed}
            />
          </Box>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
            }}
          >
            <Box mx={2} mt={1}>
              <SplitButton
                color="success"
                onClick={handleSave}
                variant="contained"
                minWidth={130}
                disabled={saveButtonStatus}
                data-testid="btnsuccess"
              />
            </Box>
            <Box mx={2} mt={1}>
              <VineaButton
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                {t('Close')}
              </VineaButton>
            </Box>
          </Grid>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={handleOnDialogClose}
            handleSaveChanges={handleSave}
            handleDiscardChanges={handleCancel}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ManagePlan;
