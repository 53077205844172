import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty, map, filter, get, maxBy, includes, orderBy } from 'lodash';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/lab/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import {
  VineaNovaSelectors,
  VineaNovaActions,
} from 'vineanova-redux-artifacts';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { FieldLabelInput } from '../../components/TextField';
import { VineaAlert } from '../../components/VineaAlert';
import { VineaAutoComplete } from '../../components/ComboBox';
import { usePlansHook } from '../Plans/hooks/usePlansHook';
import { Datepicker } from '../../components/Datepicker';
import {
  ClassificationIDs,
  viewDateFormat,
  dateFormat,
  JobInvoiceMethodIDs,
  JobStatusIDs,
  SiteTypeIDs,
} from '../../constants';
import {
  getBusinessUnitDashboardEnabled,
  getLookupVintage,
} from '../../redux/selectors';
import { JobVineyardStepperSchema } from './validations';
import { formatDate } from '../../constants/formatter';
import { syncValidator } from '../../utils/validator';
import CheckboxSelectionGrid from '../../components/Grid/CheckboxSelectionGrid';
import { VineaButton } from '../../components/VineaButton';
import { VineyardBlocksGridType } from './hooks/useJobsHook';
import { MetaDataType } from './interfaces/jobInterfaces';

const StyledBox = styled(Box)(({ theme }) => ({
  // marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
  minWidth: 250,
}));

export interface VineyardDataType {
  id: number;
  displayName: string;
}

interface DropdownType {
  id: number;
  key: number;
  value: string;
}

interface CreateJobVineyardProps {
  identityID: any;
  setCanProceedNext: (canProceedNext: boolean) => void;
}

const CreateJobVineyard: React.FC<CreateJobVineyardProps> = ({
  identityID,
  setCanProceedNext,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    id: false,
    businessUnitID: false,
    vineyardBlocks: false,
    scheduledStartDate: false,
    purchaseOrder: false,
    invoiceTo: false,
  });

  const {
    isLoaded: jobDataLoaded,
    isLoading: jobDataLoading,
    data: jobData,
  } = useSelector((state: any) => state.entities.identityJob);
  const today = format(new Date(), dateFormat);
  const [stepperDataSaved, setStepperDataSaved] = useState(false);
  const [updateAPITrigger, setUpdateAPITrigger] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [updateJobData, setUpdateJobData] = useState<any>({});
  const lkpBusinessUnit = useSelector(state =>
    getBusinessUnitDashboardEnabled(state),
  );
  const lkpVintage = useSelector(state => getLookupVintage(state));
  const latestVintageID = get(maxBy(lkpVintage, 'id'), 'id', 0);
  const sites = useSelector(VineaNovaSelectors.getIdentityVineyardEntityData);

  const { data: identitiesByClassificationData } = useSelector(
    (state: any) => state.entities.identitiesByClassification,
  );
  const {
    isLoaded: identitiesByClassificationLoaded,
    isLoading: identitiesByClassificationLoading,
  } = useSelector(
    VineaNovaSelectors.getIdentitiesByClassificationEntityMeta,
  ) as MetaDataType;
  const [invoiceToData, setInvoiceToData] = useState<DropdownType[]>([]);
  const {
    isLoading: updateJobLoading,
    isLoaded: updateJobLoaded,
    error: updateJobError,
  } = useSelector(VineaNovaSelectors.getIdentityJobEntityMeta) as MetaDataType;
  const {
    isLoading: jobBlocksLoading,
    isLoaded: jobBlocksLoaded,
    error: jobBlocksError,
  } = useSelector(
    VineaNovaSelectors.getJobBlocksBulkEntityMeta,
  ) as MetaDataType;

  const sitesList = useMemo(() => {
    const validSites = orderBy(
      map(
        filter(sites as object[], {
          siteTypeID: updateJobData.siteTypeID,
          businessUnitID: updateJobData.businessUnitID,
        }),
        (v: any) => {
          return {
            id: v.id,
            vineyardName: v.vineyardName,
            businessUnitID: v.businessUnitID,
          };
        },
      ),
      'vineyardName',
    );

    return validSites;
  }, [sites, updateJobData.siteTypeID, updateJobData.businessUnitID]);

  const siteName = useMemo(() => {
    if (
      updateJobData.siteTypeID === SiteTypeIDs.ORCHARD_TREES ||
      updateJobData.siteTypeID === SiteTypeIDs.ORCHARD_KIWIFRUIT
    )
      return 'Orchard';
    else return 'Vineyard';
  }, [updateJobData.siteTypeID]);

  const [selectedVineyards, setSelectedVineyards] = useState<number[]>([]);
  const { sampleBlockVintages, blockVintagesLoading, blockVintagesLoaded } =
    usePlansHook();
  const [allVineyardBlocks, setAllVineyardBlocks] = useState<
    VineyardBlocksGridType[]
  >([]);
  const [availableVineyardBlocks, setAvailableVineyardBlocks] = useState<
    VineyardBlocksGridType[]
  >([]);
  const [selectedBlocks, setSelectedBlocks] = useState<number[]>([]);

  const {
    data: existingJobBlocks,
    isLoaded: blocksForJobLoaded,
    isLoading: blocksForJobLoading,
  } = useSelector(VineaNovaSelectors.getBlocksForJobEntity) as MetaDataType;

  const hasSaved = useMemo(() => {
    if ((isEmpty(selectedBlocks) || hasChanges) && !stepperDataSaved)
      return false;
    else return true;
  }, [selectedBlocks, hasChanges, stepperDataSaved]);

  const vineyardGridDataColums = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
    },
    {
      field: 'vineyardName',
      headerName: `${siteName}s`,
      minwidth: '150px',
      flex: 0.3,
      resizable: false,
      hideable: false,
    },
  ];

  const vineyardGridXData = {
    columns: vineyardGridDataColums,
    rows: sitesList,
  };

  const blockGridDataColums = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Blocks',
      minwidth: '150px',
      flex: 0.3,
      resizable: false,
      hideable: false,
    },
  ];

  const blockGridXData = {
    columns: blockGridDataColums,
    rows: availableVineyardBlocks,
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    // @ts-ignore
    const newValue = isNaN(Number(value)) ? value : Number(value);
    setUpdateJobData({ ...updateJobData, [name]: newValue });
    setHasChanges(true);
  };

  const handleOnVineyardSelectionChange = (selectedVineyards: number[]) => {
    if (!hasSaved) setSelectedVineyards(selectedVineyards);
  };

  const handleOnBlockSelectionChange = (selectedBlocks: number[]) => {
    if (!hasSaved) {
      setSelectedBlocks(selectedBlocks);
      setHasChanges(true);
    }
  };

  const getBlocksForJob = (jobID: Number) => {
    dispatchAPI(
      VineaNovaActions.api.v1.blocksForJob.get.request({
        queryParams: {
          jobID: jobID,
        },
      }),
    );
  };

  // Update job, and insert job blocks on save
  const handleOnSave = () => {
    let validationErrors = syncValidator(JobVineyardStepperSchema)(
      updateJobData,
    );
    if (
      updateJobData?.invoiceMethodID === JobInvoiceMethodIDs.QUOTED_PAYER ||
      updateJobData?.invoiceMethodID === JobInvoiceMethodIDs.QUOTED_CONTRACT ||
      updateJobData?.invoiceMethodID === JobInvoiceMethodIDs.ACTUAL_PAYER ||
      updateJobData?.invoiceMethodID === JobInvoiceMethodIDs.ACTUAL_CONTRACT
    ) {
      if (
        !updateJobData.invoiceToIdentityID ||
        updateJobData.invoiceToIdentityID === 0
      ) {
        validationErrors = {
          ...validationErrors,
          invoiceTo: t('Please choose a payer'),
        };
      }
    }

    if (isEmpty(validationErrors)) {
      dispatchAPI(
        VineaNovaActions.api.v1.identityJob.put.request({
          postBody: {
            ...updateJobData,
            jobStatusID: get(updateJobData, 'scheduledStartDate')
              ? JobStatusIDs.SCHEDULED
              : JobStatusIDs.UNSCHEDULED,
            scheduledStartDate: updateJobData.scheduledStartDate,
            businessUnitID: Number(updateJobData.businessUnitID),
            poNumber: updateJobData.poNumber,
            invoiceToIdentityIDentityID: updateJobData.invoiceToIdentityID,
          },
        }),
      );
      dispatchAPI(
        VineaNovaActions.api.v1.jobBlocksBulk.post.request({
          postBody: {
            jobID: updateJobData.id,
            vineyardBlockIDs: selectedBlocks.join(','),
            description: updateJobData.jobName,
            dateStarted: today,
            complete: false,
          },
        }),
      );
      setUpdateAPITrigger(true);
    }
    setValidationErrors(validationErrors);
  };

  // Initial data load
  useEffect(() => {
    setUpdateJobData({});
    dispatchAPI(VineaNovaActions.api.v1.identityVineyard.get.request({}));
    dispatchAPI(
      VineaNovaActions.api.v1.sampleBlockVintages.get.request({
        queryParams: {
          SampleSetID: 0,
          RegionID: 0,
          VintageID: latestVintageID,
        },
      }),
    );
    dispatchAPI(
      VineaNovaActions.api.v1.identityJob.get.request({
        queryParams: {
          IdentityID: identityID,
        },
      }),
    );
    dispatchAPI(
      VineaNovaActions.api.v1.identitiesByClassification.get.request({
        queryParams: {
          ClassificationIDs: `${ClassificationIDs.PAYER}`,
        },
      }),
    );

    setCanProceedNext(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set job data once api has loaded
  useEffect(() => {
    if (jobDataLoaded && !jobDataLoading) {
      if (isEmpty(updateJobData)) setUpdateJobData(jobData);
      else setUpdateJobData({ ...updateJobData, id: get(jobData, 'id') });
    }
  }, [jobDataLoaded, jobDataLoading]);

  // Set invoice to options once identities by classification api has loaded
  useEffect(() => {
    if (
      identitiesByClassificationLoaded &&
      !identitiesByClassificationLoading
    ) {
      const invoiceTo = map(
        identitiesByClassificationData as any[],
        (identity: any) => {
          return {
            id: identity.id,
            key: identity.id,
            value: identity.displayName,
          };
        },
      );
      setInvoiceToData(invoiceTo);
    }
  }, [identitiesByClassificationLoaded, identitiesByClassificationLoading]);

  // Set available blocks in ui checkbox once sample blocks api has loaded
  useEffect(() => {
    if (blockVintagesLoaded && !blockVintagesLoading) {
      const blocks = map(sampleBlockVintages as any[], (block: any) => {
        return {
          name: block.blockName,
          id: block.vineyardBlockID,
          vineyardID: block.vineyardID,
        };
      });

      setAllVineyardBlocks(blocks);
    }
  }, [blockVintagesLoaded, blockVintagesLoading]);

  // Filter available blocks based on chosen vineyards
  useEffect(() => {
    const blocks = orderBy(
      filter(allVineyardBlocks, block =>
        includes(selectedVineyards, block.vineyardID),
      ),
      'name',
    );
    setAvailableVineyardBlocks(blocks);
  }, [selectedVineyards]);

  // Show snackbars based on update job and insert job blocks api responses
  useEffect(() => {
    if (
      updateAPITrigger &&
      jobBlocksLoaded &&
      !jobBlocksLoading &&
      updateJobLoaded &&
      !updateJobLoading
    ) {
      if (!jobBlocksError && !updateJobError) {
        // @ts-ignore
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        setStepperDataSaved(true);
        setCanProceedNext(true);
        getBlocksForJob(updateJobData.id);
      } else {
        // @ts-ignore
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }
      setUpdateAPITrigger(false);
    }
  }, [
    updateAPITrigger,
    jobBlocksLoaded,
    jobBlocksLoading,
    jobBlocksError,
    updateJobLoaded,
    updateJobLoading,
    updateJobError,
  ]);

  useEffect(() => {
    if (blocksForJobLoaded && !blocksForJobLoading) {
      if (!isEmpty(existingJobBlocks)) {
        const selectedVineyards = existingJobBlocks.map(
          (block: any) => block.vineyardID,
        );
        setSelectedVineyards(selectedVineyards);
        const selectedBlocks = existingJobBlocks.map(
          (block: any) => block.vineyardBlockID,
        );
        setSelectedBlocks(selectedBlocks);
      } else {
        setSelectedVineyards([]);
        setSelectedBlocks([]);
      }
    }
  }, [blocksForJobLoaded, blocksForJobLoading]);

  useEffect(() => {
    if (updateJobData.id) {
      getBlocksForJob(updateJobData.id);
    }
  }, [updateJobData.id]);

  return (
    <Paper
      elevation={0}
      data-testid="new-job-paper"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid spacing={2} data-testid="parent-grid-createjob">
        <Grid item xs={12} data-testid="grid-vineaalert-createjob">
          <VineaAlert
            isOpen={!!false}
            onExit={() => setErrorInSubmit(false)}
            children={undefined}
            alertType={'error'}
            message={''}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={8} data-testid="grid-warning-createjob">
          {warningInSubmit && (
            <Collapse in={warningInSubmit}>
              <Alert
                variant="standard"
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setWarningInSubmit(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Warning: Choose at least one block to create a New Plan
              </Alert>
            </Collapse>
          )}

          <Stack direction="row" width="100%" spacing={4} marginBottom={2}>
            <Stack direction="column">
              <StyledBox>
                <FormControl
                  sx={{
                    minWidth: 250,
                  }}
                  error={!!validationErrors.businessUnitID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={updateJobData.businessUnitID}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={lkpBusinessUnit || []}
                    labelVariant="body1"
                    label={t('Business Unit')}
                    name={t('Business Unit')}
                    id="businessUnitID"
                    inputProps={{
                      name: 'businessUnitID',
                    }}
                    error={validationErrors.businessUnitID}
                    fullWidth={false}
                    disabled={hasSaved || jobDataLoading}
                  />
                  {validationErrors.businessUnitID && (
                    <FormHelperText id="component-error-text">
                      {validationErrors.businessUnitID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <Box sx={{ mt: 2, height: 455 }}>
                <CheckboxSelectionGrid
                  gridXData={vineyardGridXData}
                  handleOnSelectionModelChange={handleOnVineyardSelectionChange}
                  selectionModel={selectedVineyards}
                  rowUnit={'vineyard'}
                  isLoading={jobDataLoading || blockVintagesLoading}
                />
              </Box>
            </Stack>
            <Stack direction="column">
              <Box sx={{ mt: 2, height: 455, minWidth: 250 }}>
                <CheckboxSelectionGrid
                  gridXData={blockGridXData}
                  handleOnSelectionModelChange={handleOnBlockSelectionChange}
                  selectionModel={selectedBlocks}
                  rowUnit={'block'}
                  isLoading={jobDataLoading || blockVintagesLoading}
                />
              </Box>
            </Stack>
            <Stack direction="column">
              <StyledFormControl
                data-testid="datepicker-formcontrol"
                error={validationErrors.scheduledStartDate}
              >
                <Datepicker
                  id="scheduledStartDate"
                  label={t('Start Date')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  name="scheduledStartDate"
                  value={updateJobData?.scheduledStartDate || null}
                  onChange={handleChange}
                  inputProps={{
                    'data-id': 'targetStartDate',
                    'data-name': 'targetStartDate',
                  }}
                  fullWidth
                  disabled={hasSaved || jobDataLoading}
                />
                {validationErrors.scheduledStartDate && (
                  <FormHelperText id="component-error-text">
                    {validationErrors.scheduledStartDate}
                  </FormHelperText>
                )}
              </StyledFormControl>
              <StyledBox>
                <FormControl
                  sx={{
                    minWidth: 250,
                  }}
                >
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="poNumber"
                    name="poNumber"
                    label={t('PO Number')}
                    size="small"
                    inlineLabel
                    value={updateJobData.poNumber}
                    onChange={handleChange}
                    error={validationErrors.purchaseOrder}
                    disabled={hasSaved || jobDataLoading}
                    onBlur={undefined}
                    classes={undefined}
                    sx={undefined}
                    rows={undefined}
                  />
                </FormControl>
              </StyledBox>
              <StyledBox>
                <FormControl
                  sx={{
                    minWidth: 250,
                  }}
                  error={!!validationErrors.invoiceTo}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={updateJobData.invoiceToIdentityID}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={invoiceToData || []}
                    labelVariant="body1"
                    label={t('Invoice To')}
                    name="invoiceToIdentityID"
                    id="invoiceToIdentityID"
                    inputProps={{
                      name: 'invoiceToIdentityID',
                    }}
                    fullWidth={false}
                    disabled={hasSaved || jobDataLoading}
                  />
                  {validationErrors.invoiceTo && (
                    <FormHelperText id="component-error-text">
                      {validationErrors.invoiceTo}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
            </Stack>
          </Stack>
          <Box>
            <VineaButton
              color="success"
              onClick={handleOnSave}
              variant="contained"
              minWidth={100}
              disabled={hasSaved || isEmpty(selectedBlocks)}
            >
              <>{t('Save')}</>
            </VineaButton>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CreateJobVineyard;
