import React from 'react';
import PropTypes from 'prop-types';
import { ManageClassifications } from '../Manage/ManageClassifications';
import CreateNewEntities from './CreateNewEntities';

const CreateNewClassification = props => {
  const { identityId } = props;

  return (
    <CreateNewEntities
      label="Manage Classifications"
      testId="manage-classifications"
    >
      <ManageClassifications identityId={identityId} />
    </CreateNewEntities>
  );
};

CreateNewClassification.propTypes = {
  identityId: PropTypes.number,
};

CreateNewClassification.defaultProps = {
  identityId: null,
};

export default CreateNewClassification;
