import { createSelector } from 'reselect';
import { format } from 'date-fns';
import { isArray } from 'lodash';
import { dateFormat } from '../../constants';

const getDefaultRates = state => state.entities.activityDefaultRates.data;

export const getDefaultRateSelector = createSelector(
  [getDefaultRates],
  defaultRates => {
    if (isArray(defaultRates)) {
      return defaultRates?.map(e => ({
        id: e.id,
        effectiveFromDate: format(new Date(e.effectiveFromDate), dateFormat),
        effectiveToDate:
          e.effectiveToDate === null
            ? ''
            : format(new Date(e.effectiveToDate), dateFormat),
        defaultCostRate: e.defaultCostRate,
        defaultChargeOutRate: e.defaultChargeOutRate,
        targetVelocity: e.targetVelocity,
        internalWorkCost: e.defaultRateNote,
        ts: e.ts,
      }));
    }
    return [];
  },
);
