/* eslint-disable import/named */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorBoundary } from 'react-error-boundary';

import { VineaButton } from '../../../components/VineaButton';
import { VineaTextField } from '../../../components/TextField';
import { DiscardDialog } from '../../../components/Dialog';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { GreenSwitch } from '../../../components/Switch';
import { formatAsViewDateFormat } from '../../../constants/formatter';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';
import { isNil } from 'lodash';

export function SitesDrawer({
  isOpen,
  handleOnClose,
  handleOnSave,
  handleOnChange,
  data = {},
  hasChanges,
  dataConnectionName,
  lkpVineyardNames,
}) {
  const { t } = useTranslation();

  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);

  const { siteName, friendlySiteName } = data;

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  const handleOnDiscard = () => {
    if (hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      handleOnClose();
    }
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={handleOnClickAway}
      >
        <Box sx={{ width: 350 }} role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography sx={{ padding: 2 }} variant="body1">
                {`${dataConnectionName} / ${siteName}`}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOnDiscard}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            {t('Edit Site')}
          </Typography>

          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Divider />

            <Box my={2}>
              <Box
                ml={2}
                my={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography variant="body2">{t(`Site Status`)}</Typography>
                </Box>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={data.isActive}
                      onChange={handleOnChange}
                      name="isActive"
                    />
                  }
                  labelPlacement="bottom"
                  label={
                    <Typography variant="caption">
                      {t(data.isActive ? 'Active' : 'Inactive')}
                    </Typography>
                  }
                />
              </Box>

              <FormControl sx={{ padding: 2, flex: 1, display: 'flex' }}>
                <VineaTextField
                  label={t('Location')}
                  name="location"
                  onChange={handleOnChange}
                  value={data.location}
                  fullWidth
                />
                {data?.errors?.location && (
                  <FormHelperText>{data?.errors?.location}</FormHelperText>
                )}
              </FormControl>

              <FormControl sx={{ padding: 2, flex: 1, display: 'flex' }}>
                <VineaAutoComplete
                  label={t('Primary Vineyard')}
                  value={data.vineyardID || 0}
                  onChange={handleOnChange}
                  options={lkpVineyardNames}
                  labelVariant="body1"
                  name="vineyardID"
                  inputProps={{
                    name: 'vineyardID',
                  }}
                />
                {data?.errors?.vineyardID && (
                  <FormHelperText>{data?.errors?.vineyardID}</FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box mt={2}>
              <FormControl sx={{ padding: 2, flex: 1, display: 'flex' }}>
                <VineaTextField
                  label={t('Name')}
                  value={data.siteName}
                  fullWidth
                  disabled
                />
              </FormControl>
              <FormControl sx={{ padding: 2, flex: 1, display: 'flex' }}>
                <VineaTextField
                  name='friendlySiteName'
                  label={t('Known As')}
                  value={data.friendlySiteName}
                  fullWidth
                  onChange={handleOnChange}
                  
                />
              </FormControl>

              <FormControl sx={{ padding: 2, flex: 1, display: 'flex' }}>
                <VineaTextField
                  label={t('Created Date')}
                  value={formatAsViewDateFormat(data.createdDate)}
                  fullWidth
                  disabled
                />
              </FormControl>

              <FormControl sx={{ padding: 2, flex: 1, display: 'flex' }}>
                <VineaTextField
                  label={t('Last Updated Date')}
                  value={formatAsViewDateFormat(data.lastUpdatedDate)}
                  fullWidth
                  disabled
                />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                margin: 10,
                marginTop: 8,
              }}
            >
              <VineaButton
                color="success"
                onClick={handleOnSave}
                variant="contained"
                minWidth={100}
              >
                {t('Save')}
              </VineaButton>
            </Box>
          </ErrorBoundary>

          <DiscardDialog
            open={discardDialogOpen}
            onClose={() => setDiscardDialogOpen(false)}
            handleSaveChanges={handleOnDiscardSaveChanges}
            handleDiscardChanges={handleOnDiscardConfirm}
          />
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
}

SitesDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteName: PropTypes.string.isRequired,
    friendlySiteName: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    location: PropTypes.string.isRequired,
    vineyardID: PropTypes.number.isRequired,
    vineyard: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
    lastUpdatedDate: PropTypes.string.isRequired,
    errors: PropTypes.shape({
      location: PropTypes.string.isRequired,
      vineyardID: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  handleOnSave: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  dataConnectionName: PropTypes.string.isRequired,
  lkpVineyardNames: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
