import {
  forwardRef,
  useReducer,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { filter, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FieldLabelInput, VineaTextField } from '../../../components/TextField';
import { ConnectionSchema } from '../../VineyardIdentity/validations';
import {
  getFormWriteData,
  getBusinessUnit,
  getLkpDataProvider,
  getLkpDataProviderType,
} from '../../../redux/selectors';
import { syncValidator } from '../../../utils/validator';
import {
  addGenericReducer,
  dataConnectionState,
  types,
} from '../../VineyardIdentity/stateReducers';
import { VineaButton } from '../../../components/VineaButton';
import logger from '../../../utils/winstonLogger';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import { VineaAutoComplete } from '../../../components/ComboBox';
import {
  sagaActionTypes,
  vineaDetails,
  apiTypes,
  dateFormat,
  viewDateFormat,
  commonGridHeaderNames,
  commonGridColumns,
} from '../../../constants';
import { formatDate } from '../../../constants/formatter';
import { Datepicker } from '../../../components/Datepicker';
import { GreenSwitch } from '../../../components/Switch';
import { providerFieldMap } from '../providerFieldMap';
import { VineaCommonGrid } from '../../../components/Grid';

const CreateNewConnection = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const {
    onSaveStepData,
    step,
    stepData,
    calculateNameToCheck,
    matchingIdentities,
  } = props;
  const [alertOpen, setAlertOpen] = useState(true);
  const [providerList, setProviderList] = useState([
    { id: 0, key: 0, value: 'Select' },
  ]);
  const dataProviderList = useSelector(state => getLkpDataProvider(state));

  const dataProviderTypeList = useSelector(state =>
    getLkpDataProviderType(state),
  );
  const formWrite = useSelector(state => state.formWrite);
  const [inSavingMode, setSaveMode] = useState(false);
  const [isIdentityTrigger, setIdentityTrigger] = useState(false);
  const [warningIndicator, setWarningIndicator] = useState(false);
  const [urlLabel, setURLLabel] = useState(t('API URL'));

  const { hasError: hasErrorFormSubmit } = useSelector(getFormWriteData);
  const businessUnitList = useSelector(state => getBusinessUnit(state));
  const [formdata, dispatch] = useReducer(
    addGenericReducer,
    dataConnectionState,
  );
  const { enqueueSnackbar } = useSnackbar();
  const orgId = useIdentityTypeId();
  const [actionTriggered, setActionTriggered] = useState(false);

  const handleTestConnection = () => {
    enqueueSnackbar('Success!', { variant: 'success' });
  };

  const { data: searchData } = useSelector(state => state.searchIdentities);

  const isCallExternal =
    dataProviderList.find(e => e.id === formdata.dataProviderID)
      ?.isCallExternal || false;

  const generateAPIKey = () => {
    dispatch({
      type: types.UPDATE,
      payload: {
        apiKey: uuidv4(),
      },
    });
  };

  useImperativeHandle(ref, () => ({
    saveForm() {
      const filteredData = filter(
        searchData,
        f =>
          f.name === formdata.connectionDescription &&
          f.dataProviderID === formdata.dataProviderID &&
          f.providerTypeID === formdata.providerTypeID,
      );
      const validationErrors = syncValidator(ConnectionSchema)(formdata);
      if (!isEmpty(validationErrors)) {
        dispatch({ type: types.ERROR, payload: validationErrors });
      } else if (!isEmpty(filteredData)) {
        setWarningIndicator(true);
      } else {
        const data = {
          IdentityTypeID: orgId,
          BusinessUnitID: formdata.businessUnitID || null,
          ConnectionDescription: formdata.connectionDescription,
          dataProviderID: formdata.dataProviderID,
          isConnectionValid: true,
          lastUpdatedDate: format(new Date(), dateFormat),
          Username: formdata.userName || null,
          Password: formdata.password || null,
          APIKey: formdata.apiKey || null,
          API_URL: formdata.apiURL || null,
          DataIngestionStartDate: formdata.dataIngestionStartDate || null,
          IsDataIngestionEnabled: formdata.isDataIngestionEnabled || null,
        };
        // save to stepper reducer
        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.dataConnection,
            methodType: apiTypes.POST,
          },
        });
        setSaveMode(true);
        setActionTriggered(true);
        setIdentityTrigger(true);
      }
    },
  }));

  useEffect(() => {
    calculateNameToCheck(formdata);
  }, [formdata.connectionDescription]);

  useEffect(() => {
    const { hasError, data, isLoaded } = formWrite;
    if (isLoaded && actionTriggered) {
      if (!hasError && !isEmpty(data) && isIdentityTrigger) {
        setIdentityTrigger(false);
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        const {
          data: { id, identityTypeID },
        } = formWrite;
        dispatchAPI({
          type: sagaActionTypes.FETCH_IDENTITIES,
          payload: {
            id,
            identityTypeId: identityTypeID,
          },
        });
      }
    }
  }, [
    actionTriggered,
    dispatchAPI,
    enqueueSnackbar,
    formWrite,
    isIdentityTrigger,
    t,
  ]);

  useEffect(() => {
    const { data: formWriteData, hasError, isLoading } = formWrite;
    if (inSavingMode && !isLoading) {
      logger.debug(formWrite);
      if (!hasError) {
        setSaveMode(false);
        onSaveStepData(
          { data: formWriteData, isSaving: false, isComplete: true },
          step,
        );
      } else {
        setSaveMode(false);
        onSaveStepData(
          {
            data: { identityId: null },
            isSaving: false,
            isComplete: false,
            hasError: true,
          },
          step,
        );
      }
    }
  }, [inSavingMode, formWrite, onSaveStepData, step]);

  useEffect(() => {
    dispatch({
      type: types.UPDATE,
      payload: {
        ...stepData,

        businessUnitID: stepData.businessUnitID,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleComponentChange = event => {
    setWarningIndicator(false);
    const {
      target: { value, name },
    } = event;
    if (name === 'isDataIngestionEnabled') {
      dispatch({
        type: types.UPDATE,
        payload: { [name]: !formdata.isDataIngestionEnabled },
      });
    } else {
      dispatch({ type: types.UPDATE, payload: { [name]: value } });
    }
    if (name === 'providerTypeID') {
      const providerOptions = filter(
        dataProviderList,
        f => f.dataProviderTypeID === value,
      );
      setProviderList(providerOptions);
    }
    if (formdata.providerTypeID !== 3) {
      //Gis Services
      setURLLabel(t('API URL'));
    } else {
      setURLLabel(t('Connection URL'));
    }
  };

  const handleOnBlur = () => {
    const validationErrors = syncValidator(ConnectionSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  return (
    <Paper elevation={0} data-testid="detail-info" sx={{ padding: 2 }}>
      <Stack direction="row" justifyContent="space-around">
        <Stack
          spacing={1}
          sx={{
            margin: theme.spacing(4),
          }}
        >
          <Box px={2}>
            {hasErrorFormSubmit && actionTriggered && (
              <Collapse in={alertOpen}>
                <Alert
                  variant="outlined"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {t('Error occured')}
                </Alert>
              </Collapse>
            )}
          </Box>

          <Grid container direction="row" spacing={4}>
            <Grid item>
              <FieldLabelInput
                id="connectionDescription"
                sx={{ width: 350 }}
                label={t('Name')}
                name="connectionDescription"
                size="small"
                inlineLabel
                value={formdata.connectionDescription}
                onChange={handleComponentChange}
                onBlur={handleOnBlur}
                error={formdata.errors.connectionDescription}
              />
            </Grid>
            <Grid item>
              <FormControl
                sx={{ minWidth: '350px' }}
                error={!!formdata.errors.businessUnitID}
                margin="none"
              >
                <VineaAutoComplete
                  value={formdata.businessUnitID || 0}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  options={businessUnitList}
                  labelVariant="body1"
                  id="businessunit"
                  name={t('Business Unit')}
                  label={t('Business Unit')}
                  inlineLabel={false}
                  inputProps={{
                    name: 'businessUnitID',
                  }}
                />
                {formdata.errors.businessUnitID && (
                  <FormHelperText id="component-error-text">
                    {t(formdata.errors.businessUnitID)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={4}>
            <Grid item>
              <FormControl
                sx={{ width: '350px' }}
                error={!!formdata.errors.providerTypeID}
                margin="none"
              >
                <VineaAutoComplete
                  value={formdata.providerTypeID || 0}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  inlineLabel={false}
                  options={dataProviderTypeList}
                  labelVariant="body1"
                  id="salutation"
                  name={t('Data Provider Type')}
                  label={t('Data Provider Type')}
                  inputProps={{
                    name: 'providerTypeID',
                  }}
                />
                {formdata.errors.providerTypeID && (
                  <FormHelperText id="component-error-text">
                    {t(formdata.errors.providerTypeID)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                sx={{ width: '350px' }}
                error={!!formdata.errors.dataProviderID}
                margin="none"
              >
                <VineaAutoComplete
                  value={formdata.dataProviderID || 0}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  inlineLabel={false}
                  options={providerList}
                  labelVariant="body1"
                  id="salutation"
                  name={t('Provider')}
                  label={t('Provider')}
                  inputProps={{
                    name: 'dataProviderID',
                  }}
                />
                {formdata.errors.dataProviderID && (
                  <FormHelperText id="component-error-text">
                    {t(formdata.errors.dataProviderID)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={4}>
            {providerFieldMap[formdata?.dataProviderID]?.userName && (
              <Grid item>
                <FieldLabelInput
                  id="username"
                  label={t('Username')}
                  sx={{ width: '350px' }}
                  name="userName"
                  size="small"
                  inlineLabel
                  value={formdata.userName}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.userName}
                  helperText={formdata.errors.userName}
                />
              </Grid>
            )}

            {providerFieldMap[formdata?.dataProviderID]?.password && (
              <Grid item>
                <VineaTextField
                  sx={{ width: '350px', marginTop: 1 }}
                  id="password"
                  type="password"
                  label={t('Password')}
                  name="password"
                  size="small"
                  inlineLabel
                  value={formdata.password}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.password}
                  helperText={formdata.errors.password}
                />
              </Grid>
            )}

            {providerFieldMap[formdata?.dataProviderID]?.apiURL && (
              <Grid item>
                <FieldLabelInput
                  sx={{ width: '350px' }}
                  id="apikey"
                  label={urlLabel}
                  name="apiURL"
                  size="small"
                  inlineLabel
                  value={formdata.apiURL}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.apiURL}
                  helperText={formdata.errors.apiURL}
                  placeholder="e.g. https://nz2.vintrace.net/abc/api/v6"
                />
              </Grid>
            )}

            {providerFieldMap[formdata?.dataProviderID]?.apiKey && (
              <Grid item>
                <FieldLabelInput
                  id="apikey"
                  label={t('API Key')}
                  sx={{ width: '350px' }}
                  name="apiKey"
                  size="small"
                  inlineLabel
                  value={formdata.apiKey}
                  onChange={handleComponentChange}
                  onBlur={handleOnBlur}
                  error={formdata.errors.apiKey}
                  helperText={formdata.errors.apiKey}
                />
              </Grid>
            )}

            {providerFieldMap[formdata?.dataProviderID]
              ?.dataIngestionStartDate && (
              <Grid item mt={1}>
                <FormControl sx={{ width: '350px' }}>
                  <Datepicker
                    label={t('Start Date')}
                    placeholder="DD/MM/YYYY"
                    inputFormat={viewDateFormat}
                    formatDate={formatDate}
                    name="dataIngestionStartDate"
                    disablePast={false}
                    value={formdata?.dataIngestionStartDate}
                    error={formdata?.errors?.startDate}
                    onChange={handleComponentChange}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            )}

            {providerFieldMap[formdata?.dataProviderID]
              ?.isDataIngestionEnabled && (
              <Grid item>
                <Stack
                  direction="row"
                  sx={{
                    width: '350px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Typography variant="body2">
                      {t('Data Ingestion Status')}
                    </Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <GreenSwitch
                        checked={!!formdata?.isDataIngestionEnabled}
                        onChange={handleComponentChange}
                        name="isDataIngestionEnabled"
                      />
                    }
                    labelPlacement="bottom"
                    label={
                      <Typography variant="caption">
                        {t(
                          formdata?.isDataIngestionEnabled
                            ? 'Enabled'
                            : 'Disabled',
                        )}
                      </Typography>
                    }
                  />
                </Stack>
              </Grid>
            )}
          </Grid>

          {(formdata.dataProviderID || null) && (
            <Box sx={{ marginLeft: 4 }}>
              {isCallExternal ? (
                <VineaButton
                  data-testid="BtnCancel"
                  autoFocus
                  onClick={handleTestConnection}
                  color="secondary"
                  variant="text"
                >
                  {t('Test Connection')}
                </VineaButton>
              ) : (
                <VineaButton
                  autoFocus
                  onClick={generateAPIKey}
                  color="secondary"
                  variant="text"
                >
                  {t('Create Key')}
                </VineaButton>
              )}
            </Box>
          )}

          {warningIndicator && (
            <TableRow>
              <TableCell colSpan={11}>
                <Alert severity="warning">{t('Field Already Exisits.')}</Alert>
              </TableCell>
            </TableRow>
          )}
        </Stack>
        <Box
          sx={{
            margin: theme.spacing(2),
          }}
        >
          <VineaCommonGrid
            heading={t(commonGridHeaderNames.SIMILAR_IDENTITIES)}
            colHeaders={commonGridColumns.SIMILAR_IDENTITIES}
            tableData={matchingIdentities}
            hasManageOption={false}
            hasActiveToggle={false}
          />
        </Box>
      </Stack>
    </Paper>
  );
});

CreateNewConnection.propTypes = {
  stepData: PropTypes.shape({
    birthDate: null,
    businessUnitID: PropTypes.number,
    createdDate: PropTypes.string,
    ethnicityID: PropTypes.number,
    firstName: PropTypes.string,
    genderID: PropTypes.number,
    id: PropTypes.number,
    identityTypeID: PropTypes.number,
    knownAs: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    nationalityID: PropTypes.number,
    preferredContactMethodTypeID: PropTypes.number,
    preferredLanguageID: PropTypes.number,
    roleTypeID: PropTypes.number,
    salutationID: PropTypes.number,
    secondaryLanguageID: PropTypes.number,
    ts: PropTypes.string,
  }),
  onSaveStepData: PropTypes.func,
  step: PropTypes.number,
  calculateNameToCheck: PropTypes.func,
  matchingIdentities: PropTypes.array,
};

CreateNewConnection.defaultProps = {
  stepData: {},
  onSaveStepData: () => {},
  step: 0,
  calculateNameToCheck: () => {},
  matchingIdentities: [],
};

export default CreateNewConnection;
