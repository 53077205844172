/**
 * @module Sagas/identityFeatures
 * @desc identityFeatures
 */

import {
  put,
  call,
  takeLatest,
  all,
  takeLeading,
  select,
} from 'redux-saga/effects';
import { get } from 'lodash';
import {
  sagaActionTypes as Types,
  reducers,
  vineaDetails,
  apiTypes,
} from '../../constants';
import { commonActionService } from './commonActionService';
import { createDocument } from '../../services/fetchCommonService';
import logger from '../../utils/winstonLogger';

/**
 * commonActionCreator
 */
export function* commonActionCreator(action) {
  const { payload = {}, type, name, isCurrentIdentity } = action;
  try {
    if (!isCurrentIdentity) {
      yield put({
        type: 'FETCHING',
        name,
      });
    }
    const response = yield call(commonActionService(type), payload, name);
    yield put({
      type: 'SUCCESS',
      payload: response,
      name,
    });
  } catch (err) {
    yield put({
      type: 'ERROR',
      payload: err,
      name,
    });
  }
}

export function* fetchIdentityRoot(action) {
  const { payload = {} } = action;
  logger.debug('fetchIdentityRoot', payload);
  const { id = null, identityTypeId = 1 } = payload;

  const { data } = yield select(state => state.identityDetails);
  const existingIdentityId = get(data, 'id', null);
  const existingIdentityTypeId = get(data, 'identityTypeID', null);
  const isCurrentIdentity =
    Number(id) === Number(existingIdentityId) &&
    Number(identityTypeId) === Number(existingIdentityTypeId);

  yield all([
    commonActionCreator({
      type: Types.FETCH_IDENTITIES,
      name: reducers.details,
      payload: { id, name: reducers.details, identityTypeId },
      isCurrentIdentity,
    }),
    commonActionCreator({
      type: Types.FETCH_IDENTITIES,
      name: reducers.relationshipType,
      payload: { id, name: reducers.relationshipType, identityTypeId },
      isCurrentIdentity,
    }),
    commonActionCreator({
      type: Types.FETCH_IDENTITIES,
      name: reducers.references,
      payload: { id, name: reducers.references, identityTypeId },
      isCurrentIdentity,
    }),
    commonActionCreator({
      type: Types.FETCH_IDENTITIES,
      name: reducers.relationship,
      payload: { id, name: reducers.relationship, identityTypeId },
      isCurrentIdentity,
    }),
    commonActionCreator({
      type: Types.FETCH_IDENTITIES,
      name: reducers.classifications,
      payload: { id, name: reducers.classifications, identityTypeId },
      isCurrentIdentity,
    }),
    commonActionCreator({
      type: Types.FETCH_IDENTITIES,
      name: reducers.contactMethods,
      payload: { id, name: reducers.contactMethods, identityTypeId },
      isCurrentIdentity,
    }),
    commonActionCreator({
      type: Types.FETCH_IDENTITIES,
      name: reducers.roles,
      payload: { id, name: reducers.roles, identityTypeId },
      isCurrentIdentity,
    }),
    commonActionCreator({
      type: Types.FETCH_ALL_IDENTITIES,
      name: reducers.repository,
      payload: { name: reducers.repository },
      isCurrentIdentity,
    }),
  ]);
}

export function* clearIdentityRoot() {
  yield put({
    type: 'CLEAR_DATA',
    name: reducers.details,
  });
  yield put({
    type: 'CLEAR_DATA',
    name: reducers.references,
  });
  yield put({
    type: 'CLEAR_DATA',
    name: reducers.classifications,
  });
  yield put({
    type: 'CLEAR_DATA',
    name: reducers.relationship,
  });
  yield put({
    type: 'CLEAR_DATA',
    name: reducers.contactMethods,
  });
  yield put({
    type: 'CLEAR_DATA',
    name: reducers.roles,
  });
}

export function* identityCreator(action) {
  const { payload = {} } = action;
  logger.debug('fetchIdentityRoot', payload);
  const { id = null, identityTypeId = 1 } = payload;

  yield all([
    commonActionCreator({
      type: Types.ADD_IDENTITIES,
      name: reducers.formWrite,
      payload: { name: reducers.details },
    }),
    commonActionCreator({
      type: Types.FETCH_ALL_IDENTITIES,
      name: reducers.repository,
      payload: { name: reducers.repository },
    }),
    commonActionCreator({
      type: Types.FETCH_IDENTITIES,
      name: reducers.relationshipType,
      payload: { id, name: reducers.relationshipType, identityTypeId },
    }),
  ]);
}

export function* fetchIdentityValues(action) {
  const { payload = {}, type, forceFetch = false } = action;
  try {
    // select identity overview details and compare existing request
    const { data } = yield select(state => state.identityOverviewDetails);
    const existingIdentityId = get(data, 'id', null);
    const { id = null } = payload;

    if (forceFetch && Number(id) !== Number(existingIdentityId)) {
      yield put({
        type: 'CLEAR_DATA',
        name: reducers.identityOverviewDetails,
      });
      yield put({
        type: 'FETCHING',
        name: reducers.identityOverviewDetails,
      });
    }
    const response = yield call(commonActionService(type), payload);
    yield put({
      type: 'SUCCESS',
      payload: response,
      name: reducers.identityOverviewDetails,
    });
  } catch (err) {
    logger.error(err);
    yield put({
      type: 'ERROR',
      payload: err,
      name: reducers.identityOverviewDetails,
    });
  }
}

export function* createNoteWithDocuments(action) {
  const { payload: { notePayload = {}, documentPayloads = [] } = {} } = action;
  const name = vineaDetails.note;

  try {
    yield put({
      type: 'FETCHING',
      name: reducers.formWrite,
    });

    const noteResponse = yield call(commonActionService(Types.FORM_SUBMIT), {
      data: notePayload,
      name,
      methodType: apiTypes.POST,
    });

    documentPayloads.forEach(documentPayload => {
      const { file, params } = documentPayload;

      if (file && params && noteResponse.id) {
        createDocument({
          file,
          params: { ...params, NoteId: noteResponse.id },
        });
      }
    });

    yield put({
      type: 'SUCCESS',
      payload: noteResponse,
      name: reducers.formWrite,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: reducers.formWrite,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(Types.FETCH_ALL_IDENTITIES, commonActionCreator),
    takeLatest(Types.FETCH_IDENTITIES, fetchIdentityRoot),
    takeLatest(Types.FETCH_ADDRESS, commonActionCreator),
    takeLeading(Types.SEARCH_IDENTITIES, commonActionCreator),
    takeLeading(Types.SEARCH_USERS, commonActionCreator),
    takeLeading(Types.SEARCH_PLAN, commonActionCreator),
    takeLeading(Types.SEARCH_SUPPLY_CONTRACT, commonActionCreator),
    takeLatest(Types.ADD_IDENTITIES, identityCreator),
    takeLatest(Types.CLEAR_IDENTITIES, clearIdentityRoot),
    takeLatest(Types.FETCH_IDENTITY_VALUES, fetchIdentityValues),
    takeLatest(Types.FETCH_VINEYARD_BLOCKS, commonActionCreator),
    takeLatest(Types.FETCH_BLOCK_VINTAGES, commonActionCreator),
    takeLatest(Types.FETCH_VINEYARD_BLOCK_ROWS, commonActionCreator),
    takeLatest(Types.SEARCH_SAMPLES, commonActionCreator),
    takeLatest(Types.FETCH_IDENTITY_NOTES, commonActionCreator),
    takeLatest(Types.FETCH_SAMPLE_HEADERS, commonActionCreator),
    takeLatest(Types.FETCH_SAMPLE_BLOCK_VINTAGES, commonActionCreator),
    takeLatest(Types.CREATE_NOTE_WITH_DOCUMENTS, createNoteWithDocuments),
  ]);
}
