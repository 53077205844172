import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format, isValid, parse, isBefore } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import shortId from 'shortid';

import { find, isNull } from 'lodash';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { RolesSchema } from '../validations';
import { syncValidator } from '../../../utils/validator';
import { validDateFormat } from '../../../utils/DateUtil';
import {
  sagaActionTypes,
  IdentityTypeIds,
  apiTypes,
  dateFormat,
} from '../../../constants';
import logger from '../../../utils/winstonLogger';
import { getLkpRoleTypeIdentityType } from '../../../redux/selectors';

const useIdRolesHook = (
  identityTypeId = IdentityTypeIds.PERSON,
  identityId,
) => {
  // state
  const [identityRoles, setIdentityRoles] = useState([]);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [seqToSave, setSeqToSave] = useState(null);
  const [previous, setPrevious] = useState([]);
  const [formLoadingStatus, setFormLoadingStatus] = useState(false);

  // dispatches;
  const dispatchAPI = useDispatch();
  //   const navigate = useNavigate();
  const { id: pathParamIdentityId = identityId } = useParams();

  // selectors
  const roleTypeList = useSelector(state =>
    getLkpRoleTypeIdentityType(state, identityTypeId),
  );

  const {
    isLoading,
    hasError,
    isLoaded,
    data: rolesData,
    error: errorMessage,
  } = useSelector(state => state.entities.identityRole);

  useEffect(() => {
    if (isLoading && actionTriggered) setFormLoadingStatus(true);
    if (!isLoading && isLoaded && !hasError && formLoadingStatus) {
      setFormLoadingStatus(false);
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
    }
  }, [
    actionTriggered,
    formLoadingStatus,
    dispatchAPI,
    isLoading,
    isLoaded,
    hasError,
  ]);

  // useEffects
  useEffect(() => {
    if (!isLoading && isLoaded && !hasError && Array.isArray(rolesData)) {
      logger.debug('rolesData');
      const newRows = rolesData.map(f => ({
        id: f.id,
        roleTypeID: f.roleTypeID,
        roleType: f.roleType,
        effectiveFromDate: f.effectiveFromDate,
        effectiveToDate: f.effectiveToDate,
        isEditMode: false,
        methodType: f.methodType || apiTypes.PUT,
        ts: f.ts,
        errors: {},
      }));
      setIdentityRoles(newRows);
    } else setIdentityRoles([]);
  }, [rolesData, isLoading, hasError, isLoaded]);

  const onAddNew = useCallback(() => {
    const newRows = identityRoles.concat([
      {
        id: shortId.generate(),
        roleTypeID: 0,
        roleType: '',
        effectiveFromDate: format(new Date(), dateFormat),
        effectiveToDate: null,
        isEditMode: true,
        errors: {},
        methodType: apiTypes.POST,
        ts: null,
      },
    ]);
    setIdentityRoles(newRows);
  }, [identityRoles]);

  /** changes to the row data */
  const handleOnChangeRowData = useCallback(
    (e, rowdata) => {
      const {
        target: { value, name },
      } = e;

      const { id } = rowdata;
      const isFromDateModified = name === 'effectiveFromDate';

      const newRowsNameValue = identityRoles.map(row => {
        if (row.id === id) {
          const { warning, ...restCols } = row;
          return {
            ...restCols,
            effectiveToDate: isFromDateModified ? null : row.effectiveToDate,
            [name]: value,
          };
        }
        return row;
      });

      let newRows = newRowsNameValue.map(row => {
        if (id === row.id) {
          const { errors, ...restCols } = row;
          const validationErrors = syncValidator(RolesSchema)(row);
          let newRowValue = {};

          if (!isEmpty(validationErrors)) {
            newRowValue = {
              ...restCols,
              errors: validationErrors,
            };
          } else {
            newRowValue = {
              ...restCols,
              errors: [],
            };
          }

          return newRowValue;
        }
        return row;
      });
      const currentRowData = newRows.find(f => f.id === id);

      const duplicateRows = newRows.filter(
        f =>
          f.roleTypeID === currentRowData.roleTypeID &&
          f.id !== currentRowData.id &&
          ((f.effectiveToDate >= currentRowData.effectiveFromDate &&
            f.effectiveFromDate <= currentRowData.effectiveFromDate &&
            currentRowData.effectiveFromDate !== null &&
            f.effectiveToDate !== null &&
            f.effectiveFromDate !== '') ||
            (f.effectiveFromDate <= currentRowData.effectiveFromDate &&
              f.effectiveToDate === null &&
              currentRowData.effectiveFromDate !== null) ||
            (f.effectiveFromDate >= currentRowData.effectiveFromDate &&
              currentRowData.effectiveToDate === null &&
              currentRowData.effectiveFromDate !== null) ||
            (f.effectiveToDate >= currentRowData.effectiveToDate &&
              f.effectiveFromDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate !== null &&
              f.effectiveFromDate !== null &&
              f.effectiveToDate !== null) ||
            (f.effectiveFromDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate === null) ||
            (f.effectiveFromDate >= currentRowData.effectiveFromDate &&
              f.effectiveToDate <= currentRowData.effectiveToDate &&
              currentRowData.effectiveToDate !== null &&
              currentRowData.effectiveFromDate !== null &&
              f.effectiveFromDate !== null &&
              f.effectiveToDate !== null)),
      );
      // update validation errors
      const hasDuplicateRowValue = duplicateRows.length > 0;
      if (hasDuplicateRowValue) {
        newRows = identityRoles.map(row => {
          if (id === row.id) {
            return {
              ...currentRowData,
              warning:
                'You cannot create a duplicate record. Please, update details or reuse the existing one.',
            };
          }
          return row;
        });
      }

      if (name && ['effectiveFromDate', 'effectiveToDate'].includes(name)) {
        const currentRow = newRows.find(f => f.id === id);
        const { effectiveFromDate, effectiveToDate } = currentRow || {};
        const fromDate = isValid(validDateFormat(effectiveFromDate))
          ? validDateFormat(effectiveFromDate)
          : null;
        const toDate = isValid(validDateFormat(effectiveToDate))
          ? validDateFormat(effectiveToDate)
          : null;
        const isBeforeStartDate = toDate && isBefore(toDate, fromDate);
        newRows = newRows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              errors: isBeforeStartDate ? { effectiveToDate: true } : {},
            };
          }
          return row;
        });
      }
      // final update
      setIdentityRoles(newRows);
    },
    [identityRoles],
  );

  const onToggleEditMode = useCallback(
    (id, rows) => {
      setPrevious(identityRoles);
      setIdentityRoles(() => {
        return rows.map(row => {
          if (row.id === id) {
            return { ...row, isEditMode: !row.isEditMode };
          }
          return row;
        });
      });
    },
    [identityRoles],
  );

  const handleRevert = useCallback(
    id => {
      let newRows;
      const currentRow = find(identityRoles, f => f.id === id);
      if (isNull(currentRow.ts)) {
        newRows = identityRoles.filter(f => f.id !== id);
        logger.debug('newRows');
        logger.debug(newRows);
      } else {
        newRows = identityRoles.map(row => {
          if (row.id === id) {
            const prevState = find(previous, f => f.id === id);
            return {
              ...row,
              roleTypeID: prevState.classificationID,
              effectiveFromDate: prevState.effectiveFromDate,
              effectiveToDate: prevState.effectiveToDate,
              warning: '',
            };
          }
          return row;
        });
        logger.debug('newRows');
        logger.debug(newRows);
      }
      setIdentityRoles(newRows);
      onToggleEditMode(id, newRows);
    },
    [identityRoles, onToggleEditMode, previous],
  );

  const handleOnSaveRow = id => {
    const dataToSave = identityRoles.find(f => f.id === id);
    const validationErrors = syncValidator(RolesSchema)(dataToSave);

    if (!isEmpty(dataToSave.errors)) {
      logger.error('cannot save duplicate data');
    } else if (!isEmpty(validationErrors)) {
      const newRows = identityRoles.map(row => {
        if (row.id === id) {
          return { ...row, errors: validationErrors };
        }
        return row;
      });
      setIdentityRoles(newRows);
    } else {
      let data = {};
      if (dataToSave.methodType === apiTypes.POST) {
        data = {
          IdentityID: pathParamIdentityId, // TODO: remove later
          RoleTypeID: dataToSave.roleTypeID,
          effectiveFromDate: dataToSave.effectiveFromDate,
          effectiveToDate: dataToSave.effectiveToDate,
        };
        dispatchAPI(
          VineaNovaActions.api.v1.identityRole.post.request({
            postBody: {
              ...data,
            },
          }),
        );
      } else {
        data = {
          ID: id,
          IdentityID: pathParamIdentityId, // TODO: remove later
          RoleTypeID: dataToSave.roleTypeID,
          effectiveFromDate: dataToSave.effectiveFromDate,
          effectiveToDate: dataToSave.effectiveToDate,
          ts: dataToSave.ts,
        };
        dispatchAPI(
          VineaNovaActions.api.v1.identityRole.put.request({
            postBody: {
              ...data,
            },
          }),
        );
      }

      setSeqToSave(id);
      setActionTriggered(true);
    }
  };

  const handleOnDeleteRow = useCallback(
    id => {
      const selectedRecord = find(identityRoles, f => f.id === id);
      if (isEmpty(selectedRecord.ts) || isNull(selectedRecord.ts)) {
        const updatedRows = identityRoles.filter(f => f.id !== id);
        setIdentityRoles(updatedRows);
      } else {
        const data = {
          ID: id,
          ts: selectedRecord.ts,
        };

        dispatchAPI(
          VineaNovaActions.api.v1.identityRole.delete.request({
            postBody: {
              ...data,
            },
          }),
        );

        setActionTriggered(true);
      }
    },
    [dispatchAPI, identityRoles],
  );

  return {
    roleTypeList,
    identityId: pathParamIdentityId,
    identityRoles,
    setIdentityRoles,
    onAddNew,
    isLoadingIdRoles: isLoading,
    isLoadedIdRoles: isLoaded,
    hasErrorIdRoles: hasError,
    errorMessage,
    handleOnChangeRowData,
    onToggleEditMode,
    handleOnSaveRow,
    handleRevert,
    setSeqToSave,
    setActionTriggered,
    actionTriggered,
    seqToSave,
    handleOnDeleteRow,
  };
};

export { useIdRolesHook };
