import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Alert } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Stack, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { getBusinessUnit, getConsumableType } from '../../../redux/selectors';
import { VineaAlert } from '../../../components/VineaAlert';
import { FieldLabelInput } from '../../../components/TextField';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { addGenericReducer, types, consumableState } from '../stateReducers';
import { syncValidator } from '../../../utils/validator';
import { ConsumableSchema } from '../validations';
import {
  apiTypes,
  commonGridColumns,
  commonGridHeaderNames,
  dateFormat,
  IdentityTypeIds,
  sagaActionTypes,
  vineaDetails,
} from '../../../constants';
import { VineaCommonGrid } from '../../../components/Grid';

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 250,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 300,
  },
}));

const CreateConsumable = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const today = format(new Date(), dateFormat);
  const { enqueueSnackbar } = useSnackbar();
  const {
    onSaveStepData,
    step,
    stepData,
    calculateNameToCheck,
    matchingIdentities,
  } = props;
  const [formdata, dispatch] = useReducer(addGenericReducer, consumableState);
  const businessUnitList = useSelector(state => getBusinessUnit(state));
  const consumableTypeList = useSelector(state => getConsumableType(state));
  const formWrite = useSelector(state => state.formWrite);

  const [alertType, setAlertType] = useState(false);
  const [inSavingMode, setSaveMode] = useState(false);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = useState(false);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [isIdentityTrigger, setIdentityTrigger] = useState(false);

  useImperativeHandle(ref, () => ({
    saveForm() {
      const validationErrors = syncValidator(ConsumableSchema)(formdata);
      if (!isEmpty(validationErrors)) {
        dispatch({ type: types.ERROR, payload: validationErrors });
      } else {
        const data = {
          identityTypeID: IdentityTypeIds.CONSUMABLE,
          consumableName: formdata.consumableName,
          ConsumableDescription: formdata.consumableDescription || '',
          consumableTypeID: formdata.consumableTypeID || '',
          businessUnitID: formdata.businessUnitID,
          CreatedDate: today,
        };
        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.consumable,
            methodType: apiTypes.POST,
          },
        });
        setSaveMode(true);
        setActionTriggered(true);
        setIdentityTrigger(true);
      }
    },
  }));

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleOnBlurOrgName = () => {
    const validationErrors = syncValidator(ConsumableSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  useEffect(() => {
    calculateNameToCheck(formdata);
  }, [formdata.consumableName]);

  useEffect(() => {
    const { data: formWriteData, hasError, isLoading } = formWrite;
    if (inSavingMode && !isLoading) {
      if (!hasError) {
        setSaveMode(false);
        onSaveStepData(
          { data: formWriteData, isSaving: false, isComplete: true },
          step,
        );
      } else {
        setSaveMode(false);
        onSaveStepData(
          {
            data: { identityId: null },
            isSaving: false,
            isComplete: false,
            hasError: true,
          },
          step,
        );
      }
    }
  }, [inSavingMode, formWrite, onSaveStepData, step]);

  useEffect(() => {
    dispatch({
      type: types.UPDATE,
      payload: {
        ...stepData,
        consumableName: stepData.consumableName,
        consumableDescription: stepData.consumableDescription,
        consumableTypeID: stepData.consumableTypeID,
        businessUnitID: stepData.businessUnitID,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { hasError, data, isLoaded } = formWrite;
    if (isLoaded && actionTriggered) {
      if (!hasError && !isEmpty(data) && isIdentityTrigger) {
        setIdentityTrigger(false);
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        setAlertType(true);
        const {
          data: { id, identityTypeID },
        } = formWrite;
        dispatchAPI({
          type: sagaActionTypes.FETCH_IDENTITIES,
          payload: {
            id,
            identityTypeId: identityTypeID,
          },
        });
      }
    }
  }, [
    formWrite,
    actionTriggered,
    isIdentityTrigger,
    enqueueSnackbar,
    t,
    dispatchAPI,
  ]);

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <VineaAlert
        isOpen={!!errorInSubmit}
        onExit={() => setErrorInSubmit(false)}
      />
      <VineaAlert
        isOpen={alertType}
        onExit={() => setAlertType(false)}
        alertType="success"
        message="Consumable Created Successfully"
      />
      <Box px={2}>
        {warningInSubmit && (
          <Collapse in={warningInSubmit}>
            <Alert
              variant="standard"
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setWarningInSubmit(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Warning: Nothing to Update
            </Alert>
          </Collapse>
        )}
      </Box>
      <Stack direction="row">
        <Grid container spacing={2} data-testid="grid-details">
          <Grid item xs={12}>
            <StyledFormBoxControl mt={1}>
              <StyledFieldLabelInput
                autoComplete="off"
                id="name"
                name="consumableName"
                label={t('Consumable Name')}
                size="small"
                inlineLabel
                value={formdata.consumableName}
                onChange={handleChange}
                onBlur={handleOnBlurOrgName}
                error={formdata.errors.consumableName}
                helperText={formdata.errors.consumableName}
              />
            </StyledFormBoxControl>
            <StyledFormBoxControl mt={1}>
              <StyledFieldLabelInput
                autoComplete="off"
                id="description"
                name="consumableDescription"
                label={t('Description')}
                size="small"
                inlineLabel
                value={formdata.consumableDescription}
                onChange={handleChange}
                onBlur={handleOnBlurOrgName}
                error={formdata.errors.consumableDescription}
                helperText={formdata.errors.consumableDescription}
              />
            </StyledFormBoxControl>
            <StyledFormBoxControl mt={1}>
              <FormControl
                sx={{
                  marginTop: theme.spacing(1),
                  minWidth: 250,
                }}
                margin="none"
                error={!!formdata.errors.consumableType}
              >
                <VineaAutoComplete
                  value={formdata.consumableTypeID || 0}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  options={consumableTypeList}
                  labelVariant="body1"
                  label={t('Consumable Type')}
                  name={t('Consumable Type')}
                  inlineLabel
                  inputProps={{
                    name: 'consumableTypeID',
                  }}
                />
                {formdata.errors.consumableType && (
                  <FormHelperText id="component-error-text">
                    {formdata.errors.consumableType}
                  </FormHelperText>
                )}
              </FormControl>
            </StyledFormBoxControl>
            <StyledFormBoxControl mt={1}>
              <FormControl
                sx={{
                  marginTop: theme.spacing(1),
                  minWidth: 250,
                }}
                margin="none"
                error={!!formdata.errors.businessUnitID}
              >
                <VineaAutoComplete
                  value={formdata.businessUnitID || 0}
                  onChange={handleChange}
                  onBlur={handleOnBlurOrgName}
                  options={businessUnitList}
                  labelVariant="body1"
                  label={t('Business Unit')}
                  name={t('Business Unit')}
                  inlineLabel
                  inputProps={{
                    name: 'businessUnitID',
                  }}
                />
                {formdata.errors.businessUnitID && (
                  <FormHelperText id="component-error-text">
                    {formdata.errors.businessUnitID}
                  </FormHelperText>
                )}
              </FormControl>
            </StyledFormBoxControl>
          </Grid>
        </Grid>
        <Box
          sx={{
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(2),
          }}
        >
          <VineaCommonGrid
            heading={t(commonGridHeaderNames.SIMILAR_IDENTITIES)}
            colHeaders={commonGridColumns.SIMILAR_IDENTITIES}
            tableData={matchingIdentities}
            hasManageOption={false}
            hasActiveToggle={false}
          />
        </Box>
      </Stack>
    </Paper>
  );
});

CreateConsumable.propTypes = {
  stepData: PropTypes.shape({
    vineyardName: PropTypes.string,
    vineyardKnownAs: PropTypes.string,
    businessUnitID: PropTypes.number,
    createdDate: PropTypes.string,
    id: PropTypes.number,
    identityTypeID: PropTypes.number,
    plantedArea: PropTypes.number,
    preferredContactMethodTypeID: PropTypes.number,
    subRegionID: PropTypes.number,
    totalVineyardArea: PropTypes.number,
    ts: PropTypes.string,
  }),
  onSaveStepData: PropTypes.func,
  step: PropTypes.number,
  calculateNameToCheck: PropTypes.func,
  matchingIdentities: PropTypes.array,
};

CreateConsumable.defaultProps = {
  stepData: {},
  onSaveStepData: () => {},
  step: 0,
  calculateNameToCheck: () => {},
  matchingIdentities: [],
};

export default CreateConsumable;
