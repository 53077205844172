import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import ilogo from '../../assets/placeholder.png';
import { VineaButton } from '../VineaButton';
import { IdentityStatusPill } from '../IdentityComponents';
import LogoUploadCrop from '../LogoUploadCrop/LogoUploadCrop.tsx';
import LogoChange from '../LogoUploadCrop/LogoChange.tsx';
import {
  doesIdentityDisplayBusinessUnit,
  doesIdentityDisplayRoles,
} from '../../hooks/useIdentityTypeId';
import { isEmpty, isNil } from 'lodash';

const OverlayDiv = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'black',
  opacity: 0.6,
}));

const DetailsDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export default function IdentityDetailsExt1({
  identityOverviewDetails,
  onClick,
  identityStatus,
  saveCroppedImage = f => f,
  imageDataUrl,
  onDeleteProfileImage = f => f,
  updateFavouriteIdentity = f => f,
  favIdentities = [],
}) {
  const {
    identityName,
    knownAs,
    description,
    id,
    businessUnit,
    identityTypeID,
    roles,
  } = identityOverviewDetails;

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [imageDisplay, setImageDisplay] = useState(false);
  const [imgOverlay, setImgOverlay] = useState(false);

  const isSelectedIdentityFavorite = React.useMemo(() => {
    return favIdentities.some(f => f.id === identityOverviewDetails.id);
  }, [favIdentities, identityOverviewDetails.id]);

  const handleOnFavClick = React.useCallback(() => {
    if (updateFavouriteIdentity) {
      // pick only id, identityTypeID and isPersonIdentity
      const favUpdateDetails = {
        id: identityOverviewDetails.id,
        identityTypeID: identityOverviewDetails.identityTypeID,
        isPersonIdentity: identityOverviewDetails.isPersonIdentity,
        isFavourite: !isSelectedIdentityFavorite,
      };
      updateFavouriteIdentity(favUpdateDetails);
    }
  }, [
    isSelectedIdentityFavorite,
    updateFavouriteIdentity,
    identityOverviewDetails,
  ]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleImageDisplay = () => {
    setImageDisplay(false);
  };

  const handleImageDialog = () => {
    if (imageDataUrl) {
      setImageDisplay(true);
    } else {
      setOpen(true);
    }
  };

  const handleOnReplaceImage = () => {
    setImageDisplay(false);
    setOpen(true);
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          boxShadow: 'none',
          minWidth: 500,
        }}
        data-id="card-rooot"
      >
        <CardMedia
          sx={{
            width: 100,
            position: 'relative',
          }}
          image={imageDataUrl || ilogo}
          title="Identity"
          data-id="card-media"
          onMouseEnter={() => {
            setImgOverlay(true);
          }}
          onMouseLeave={() => {
            setImgOverlay(false);
          }}
        >
          {imgOverlay && (
            <OverlayDiv>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '25%',
                  left: '25%',
                  color: 'white',
                }}
                variant="text"
                onClick={handleImageDialog}
                data-testid="idaddPhoto"
                size="large"
              >
                <AddPhotoAlternateIcon style={{ fontSize: 40 }} />
              </IconButton>
            </OverlayDiv>
          )}
        </CardMedia>
        <DetailsDiv data-id="card-details">
          <CardContent
            sx={{
              flex: '1 0 auto',
              paddingTop: 1,
              paddingBottom: 1,
              display: 'flex',
              gap: 1,
            }}
          >
            <Box data-testid="vineyardtitle-info">
              <Typography
                variant="h6"
                data-testid="displayname"
                nowrap={true}
                sx={{
                  maxWidth: 500,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {identityName?.length > 50
                  ? identityName?.substring(0, 50) + '...'
                  : identityName}
              </Typography>
              <Typography
                component="p"
                variant="body1"
                color="textPrimary"
                data-testid="idknownas"
                nowrap
                sx={{
                  maxWidth: 500,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {knownAs || description || ''}
              </Typography>
              <Box display="flex" flexDirection="column">
                {' '}
                <Box display="flex" flexDirection="row">
                  {' '}
                  <Typography
                    component="p"
                    variant="body1"
                    color="textPrimary"
                    data-testid="idid"
                    marginTop={1}
                  >
                    {`ID: ${id || ''}`}
                  </Typography>{' '}
                  <IconButton
                    aria-label="favourite"
                    data-testid="favbutton"
                    onClick={handleOnFavClick}
                    sx={{
                      paddingTop: 1,
                      paddingBottom: 1,
                    }}
                  >
                    {isSelectedIdentityFavorite ? (
                      <StarIcon
                        sx={{ color: 'gold' }}
                        data-testid="vinea-fav"
                      />
                    ) : (
                      <StarBorderIcon
                        sx={{ color: 'gold' }}
                        data-testid="vinea-unfav"
                      />
                    )}
                  </IconButton>
                </Box>
                {doesIdentityDisplayBusinessUnit(identityTypeID) && (
                  <Typography
                    component="p"
                    variant="body1"
                    color="textPrimary"
                    data-testid="idid"
                  >
                    {`Business Unit: ${businessUnit || ''}`}
                  </Typography>
                )}
                {!isEmpty(roles) &&
                  doesIdentityDisplayRoles(identityTypeID) && (
                    <Typography
                      component="p"
                      variant="body1"
                      color="textPrimary"
                      data-testid="idroles"
                    >
                      {`Roles: ${roles?.join(', ') || ''}`}
                    </Typography>
                  )}
              </Box>
            </Box>
          </CardContent>
          <CardActions
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Box ml={1}>
              <IdentityStatusPill
                state={identityStatus ? 'Active' : 'Inactive'}
              />
            </Box>
            <VineaButton
              color="secondary"
              variant="text"
              onClick={onClick}
              data-testid="idmanage"
            >
              {t('Manage')}
            </VineaButton>
          </CardActions>
        </DetailsDiv>
      </Card>
      {open && (
        <LogoUploadCrop
          open={open}
          onClose={handleClose}
          onSave={saveCroppedImage}
          imageDataUrl={imageDataUrl}
        />
      )}
      {imageDataUrl && imageDisplay && (
        <LogoChange
          open={imageDisplay}
          onClose={handleImageDisplay}
          imageDataUrl={imageDataUrl}
          imageReplace={handleOnReplaceImage}
          onDelete={onDeleteProfileImage}
        />
      )}
    </>
  );
}

IdentityDetailsExt1.propTypes = {
  onClick: PropTypes.func.isRequired,
  identityStatus: PropTypes.bool.isRequired,
  identityOverviewDetails: PropTypes.shape({
    id: PropTypes.number,
    identityTypeID: PropTypes.number,
    isPersonIdentity: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    knownAs: PropTypes.string,
    displayName: PropTypes.string,
    plantedArea: PropTypes.string,
    totalArea: PropTypes.string,
    birthDate: PropTypes.string,
    ethnicity: PropTypes.string,
    nationality: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    subregion: PropTypes.string,
    address: PropTypes.string,
    roles: PropTypes.string,
  }),
  saveCroppedImage: PropTypes.func.isRequired,
  imageDataUrl: PropTypes.string,
  onDeleteProfileImage: PropTypes.func.isRequired,
  updateFavouriteIdentity: PropTypes.func.isRequired,
  favIdentities: PropTypes.arrayOf(PropTypes.number).isRequired,
};

IdentityDetailsExt1.defaultProps = {
  identityOverviewDetails: {},
  imageDataUrl: null,
};
