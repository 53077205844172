import React, { useReducer, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import { isEmpty, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/lab/Alert';
import { useSnackbar } from 'notistack';
import { FormHelperText, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';

import { FieldLabelInput } from '../../../components/TextField';
import { addGenericReducer, types, consumableState } from '../stateReducers';
import { syncValidator } from '../../../utils/validator';
import { ConsumableSchema } from '../validations';
import { VineaButton } from '../../../components/VineaButton';
import { VineaAlert } from '../../../components/VineaAlert';
import {
  DeleteIdentityAlert,
  IdentityActivationSwitch,
  DeleteIdentityButton,
} from '../../../components/IdentityComponents';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import {
  apiTypes,
  dateFormat,
  IdentityTypeIds,
  reducers,
  sagaActionTypes,
  vineaDetails,
} from '../../../constants';
import { getConsumableType, getBusinessUnit } from '../../../redux/selectors';
import { SplitButton } from '../../../components/SplitButton';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { DiscardDialog } from '../../../components/Dialog';
import { statechecker } from '../../../utils/statecomparator';
import { useIdentityStatus } from '../../../hooks/useIdentityStatus';

// import logger from '../../../utils/winstonLogger';

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 300,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 350,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

export const ManageConsumables = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const today = format(new Date(), dateFormat);
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = React.useState(false);
  const [initialAPITrigger, setInitialAPITrigger] = useState(true);
  const [formLoadingState, setFormLoadingState] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [saveIndex, setSaveIndex] = useState(0);
  const identityTypeIdVal = useIdentityTypeId();
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [formWriteReloadState, setFormWriteReloadState] = useState(false);
  const consumableTypeList = useSelector(state => getConsumableType(state));
  const businessUnitList = useSelector(state => getBusinessUnit(state));
  const [deleteHasFailed, setDeleteHasFailed] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const {
    isLoading: consumableIsLoading,
    isLoaded: consumableIsLoaded,
    hasError: consumableHasError,
  } = useSelector(VineaNovaSelectors.getIdentityConsumableEntityMeta);

  const isActive = useIdentityStatus();
  const { id: pathParam } = useParams();

  const [formdata, dispatch] = useReducer(addGenericReducer, consumableState);
  const {
    isLoading,
    isLoaded,
    hasError,
    data: consumableData,
    error,
  } = useSelector(state => state.formWrite);

  let errorFromAPI = '';

  if (error) {
    errorFromAPI = error.data;
  }

  const [updateApiStatus, setUpdateApiStatus] = React.useState(false);

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleOnBlurOrgName = () => {
    const validationErrors = syncValidator(ConsumableSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  const handleSave = (event, index) => {
    setSaveIndex(index);
    const validationErrors = syncValidator(ConsumableSchema)(formdata);
    if (!isEmpty(validationErrors)) {
      dispatch({ type: types.ERROR, payload: validationErrors });
    } else if (
      consumableData.consumableName === formdata.consumableName &&
      consumableData.consumableDescription === formdata.consumableDescription &&
      consumableData.consumableTypeID === formdata.consumableTypeID &&
      consumableData.businessUnitID === formdata.businessUnitID
    ) {
      setWarningInSubmit(true);
    } else {
      setWarningInSubmit(false);
      setErrorInSubmit(false);
      const data = {
        id: consumableData.id,
        identityTypeID: IdentityTypeIds.CONSUMABLE,
        consumableName: formdata.consumableName,
        consumableDescription: formdata.consumableDescription,
        consumableTypeID: formdata.consumableTypeID,
        businessUnitID: formdata.businessUnitID,
        createdDate: today,
        ts: consumableData.ts,
      };
      dispatchAPI({
        // need to change payload
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.consumable,
          methodType: apiTypes.PUT,
        },
      });
      setUpdateApiStatus(true);
      setFormWriteReloadState(true);
    }
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleClose = () => {
    if (!saveButtonStatus) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleOnConfirmDelete = () => {
    if (pathParam) {
      setIsDeleting(true);
      dispatchAPI(
        VineaNovaActions.api.v1.identityConsumable.delete.request({
          queryParams: {
            IdentityID: pathParam,
          },
        }),
      );
    }
  };

  const handleOnToggleActive = () => {
    navigate(`/consumable/${pathParam}/managestatus`);
  };

  useEffect(() => {
    if (!isLoading && isLoaded && !isEmpty(consumableData) && !hasError) {
      const stubConsumableData = {
        ...consumableData,
      };
      const stubFormData = omit(formdata, 'errors');
      if (statechecker(stubFormData, stubConsumableData)) {
        setSaveButtonStatus(true);
      } else setSaveButtonStatus(false);
    }
  }, [isLoading, isLoaded, consumableData, hasError, formdata]);

  useEffect(() => {
    if (isLoaded && !hasError && initialAPITrigger) {
      setInitialAPITrigger(false);
      setFormLoadingState(false);
      dispatch({
        type: types.UPDATE,
        payload: {
          consumableName: consumableData.consumableName,
          consumableDescription: consumableData.consumableDescription,
          consumableTypeID: consumableData.consumableTypeID || 0,
          businessUnitID: consumableData.businessUnitID || 0,
        },
      });
    }
  }, [
    isLoaded,
    hasError,
    initialAPITrigger,
    consumableData.consumableName,
    consumableData.consumableDescription,
    consumableData.consumableTypeID,
    consumableData.businessUnitID,
    isLoading,
    formLoadingState,
  ]);

  useEffect(() => {
    if (isLoading) {
      setFormLoadingState(true);
    }
    if (!hasError && isLoaded && updateApiStatus && formLoadingState) {
      enqueueSnackbar('Success!', { variant: 'success' });
      setAlertType(true);
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
      if (saveIndex === 0) navigate(`/consumable/${pathParam}/more`);
      else if (formWriteReloadState && saveIndex === 1) {
        setFormWriteReloadState(false);
        dispatchAPI({
          type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
          payload: { id: consumableData.id, name: reducers.consumable },
        });
      }
      setFormLoadingState(false);
    } else if (hasError && isLoaded && updateApiStatus) {
      setErrorInSubmit(true);
      enqueueSnackbar(t('Error'), { variant: 'Error' });
    }
  }, [
    isLoaded,
    updateApiStatus,
    enqueueSnackbar,
    t,
    navigate,
    hasError,
    consumableData.id,
    saveIndex,
    warningInSubmit,
    isLoading,
    formLoadingState,
    dispatchAPI,
    identityTypeIdVal,
    formWriteReloadState,
  ]);

  useEffect(() => {
    if (isDeleting && !consumableIsLoading && consumableIsLoaded) {
      if (consumableHasError) {
        setDeleteHasFailed(true);
      } else {
        navigate('/consumable');
      }

      setIsDeleting(false);
    }
  }, [
    consumableIsLoaded,
    consumableIsLoading,
    consumableHasError,
    isDeleting,
    setIsDeleting,
    navigate,
  ]);

  return (
    <Paper
      elevation={0}
      data-testid="manageconsumable-detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12}>
          <Box mb={4}>
            <VineaAlert
              isOpen={!!errorInSubmit}
              onExit={() => setErrorInSubmit(false)}
            >
              <p>{errorFromAPI}</p>
            </VineaAlert>
            <VineaAlert
              isOpen={alertType}
              onExit={() => setAlertType(false)}
              alertType="success"
              message="consumable Details Updated"
            />
            <DeleteIdentityAlert
              isOpen={deleteHasFailed}
              onExit={() => setDeleteHasFailed(false)}
              identityName="consumable"
            />
          </Box>
          <Box px={2}>
            {warningInSubmit && (
              <Collapse in={warningInSubmit}>
                <Alert
                  variant="standard"
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarningInSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Warning: Nothing to Update
                </Alert>
              </Collapse>
            )}
          </Box>
          <StyledFormBoxControl mt={1}>
            <StyledFieldLabelInput
              autoComplete="off"
              id="name"
              name="consumableName"
              label={t('Consumable Name')}
              size="small"
              inlineLabel
              value={formdata.consumableName}
              onChange={handleChange}
              onBlur={handleOnBlurOrgName}
              error={formdata.errors.consumableName}
              helperText={formdata.errors.consumableName}
            />
          </StyledFormBoxControl>
          <StyledFormBoxControl mt={1}>
            <StyledFieldLabelInput
              autoComplete="off"
              id="description"
              name="consumableDescription"
              label={t('Description')}
              size="small"
              inlineLabel
              value={formdata.consumableDescription}
              onChange={handleChange}
              onBlur={handleOnBlurOrgName}
              error={formdata.errors.consumableDescription}
              helperText={formdata.errors.consumableDescription}
            />
          </StyledFormBoxControl>
          <StyledFormBoxControl mt={1}>
            <FormControl
              sx={{
                marginTop: theme.spacing(1),
                minWidth: 250,
              }}
              margin="none"
              error={!!formdata.errors.consumableType}
            >
              <VineaAutoComplete
                value={formdata.consumableTypeID || 0}
                onChange={handleChange}
                onBlur={handleOnBlurOrgName}
                options={consumableTypeList}
                labelVariant="body1"
                label={t('Consumable Type')}
                name={t('Consumable Type')}
                inlineLabel
                inputProps={{
                  name: 'consumableTypeID',
                }}
              />
              {formdata.errors.consumableType && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.consumableType}
                </FormHelperText>
              )}
            </FormControl>
          </StyledFormBoxControl>
          <StyledFormBoxControl mt={1}>
            <FormControl
              sx={{
                marginTop: theme.spacing(1),
                minWidth: 250,
              }}
              margin="none"
              error={!!formdata.errors.businessUnitID}
            >
              <VineaAutoComplete
                value={formdata.businessUnitID || 0}
                onChange={handleChange}
                onBlur={handleOnBlurOrgName}
                options={businessUnitList}
                labelVariant="body1"
                label={t('Business Unit')}
                name={t('Business Unit')}
                inlineLabel
                inputProps={{
                  name: 'businessUnitID',
                }}
              />
              {formdata.errors.businessUnitID && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.businessUnitID}
                </FormHelperText>
              )}
            </FormControl>
          </StyledFormBoxControl>
          <IdentityActivationSwitch
            handleOnToggleActive={handleOnToggleActive}
            isActive={isActive}
            identityName="consumable"
          />
          <Box my={2}>
            <DeleteIdentityButton
              handleOnDelete={handleOnConfirmDelete}
              identityName="consumable"
              isDisabled={deleteHasFailed}
            />
          </Box>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
            }}
          >
            <Box mx={2} mt={1}>
              <SplitButton
                color="success"
                onClick={handleSave}
                variant="contained"
                minWidth={130}
                disabled={saveButtonStatus}
                data-testid="btnsuccess"
              />
            </Box>
            <Box mx={2} mt={1}>
              <VineaButton
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                {t('Close')}
              </VineaButton>
            </Box>
          </Grid>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={handleOnDialogClose}
            handleSaveChanges={handleSave}
            handleDiscardChanges={handleCancel}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
