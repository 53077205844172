import { isValid, format } from 'date-fns';
import { viewDateFormat } from '../../constants';
import { isNil } from 'lodash';

export const GridValueDateFormatter = date => {
  const dateValid = isValid(new Date(date));
  return dateValid && !isNil(date)
    ? format(new Date(date), viewDateFormat)
    : date;
};

// function write price fomatter $ and 4 decimal places
export const GridValuePriceFormatter = price => {
  return `$${price.toFixed(4)}`;
};

// function write percentage fomatter % and 2 decimal places
export const GridValuePercentageFormatter = percentage => {
  return `${percentage.toFixed(2)}%`;
};

// Path: VineaNovaWebProject/src/components/Grid/GridValueDateFormatter.js
