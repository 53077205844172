import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { useSelector, useDispatch } from 'react-redux';
import { format, isBefore, isSameDay } from 'date-fns';
import { dateFormat } from '../constants';

export const useIdentityStatus = () => {
  const dispatchAPI = useDispatch();
  const { id: pathParam } = useParams();

  const { data: { inactiveDate } = {} } = useSelector(
    state => state.entities.identityDetails,
  );

  // identity is only inactive if it has a deactivation date from today or before
  let isActive = true;
  if (inactiveDate) {
    // get the two dates without time information
    const timezoneOffset =
      new Date(inactiveDate).getTimezoneOffset() * 60 * 1000;
    const today = format(new Date(), dateFormat);
    const formatted = format(
      new Date(inactiveDate).getTime() + timezoneOffset,
      dateFormat,
    );
    let isDateBefore = isBefore(new Date(formatted), new Date(today));
    let isDateSame = isSameDay(new Date(formatted), new Date(today));

    if (isDateBefore || isDateSame) {
      isActive = false;
    }
  }

  useEffect(() => {
    if (pathParam) {
      dispatchAPI(
        VineaNovaActions.api.v1.identityDetails.get.request({
          queryParams: {
            IdentityID: pathParam,
          },
        }),
      );
    }
  }, [pathParam]);

  return isActive;
};
