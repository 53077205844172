import PropTypes from 'prop-types';

export const historyPropTypes = PropTypes.shape({
  push: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
});

export const commonReducerPropTypes = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
});

export const commonRoutePropType = PropTypes.arrayOf(
  PropTypes.shape({
    path: PropTypes.string,
    name: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  }),
);

export const commonClassificationPropTypes = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      classification: PropTypes.string,
      classificationGroup: PropTypes.string,
      classificationID: PropTypes.number,
      effectiveFromDate: PropTypes.string,
      effectiveToDate: PropTypes.string,
    }),
  ),
});

export const commonRelatonshipPropTypes = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      relatesTo: PropTypes.string,
      relationshipName: PropTypes.string,
      relationshipTypeID: PropTypes.number.isRequired,
      ownerIdentityID: PropTypes.number,
      dependentIdentityID: PropTypes.number,
      effectiveFromDate: PropTypes.string,
      effectiveToDate: PropTypes.string,
      fromTo: PropTypes.string,
      comment: PropTypes.string,
      isDisplayOnPanel: PropTypes.bool,
      isActive: PropTypes.bool,
    }),
  ),
});

export const commonContactMethodPropTypes = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      contactMethodDetails: PropTypes.string,
      contactMethodType: PropTypes.string,
      effectiveFromDate: PropTypes.string,
      effectiveToDate: PropTypes.string,
    }),
  ),
});

export const commonLookupPropTypes = PropTypes.shape({
  key: PropTypes.oneOfType[(PropTypes.string, PropTypes.number)],
  value: PropTypes.oneOfType[(PropTypes.string, PropTypes.number)],
  id: PropTypes.oneOfType[(PropTypes.string, PropTypes.number)],
});
