import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { startCase } from 'lodash';

import { DeleteDialog } from '../Dialog';
import { VineaButton } from '../VineaButton';
import { IdentityTypeIds } from '../../constants';

type DeleteIdentityButtonProps = {
  handleOnDelete: () => void;
  identityName: string;
  identityTypeID: number;
  isDisabled: boolean;
};

export const DeleteIdentityButton = ({
  handleOnDelete,
  identityName = 'Identity',
  identityTypeID = 0,
  isDisabled = false,
}: DeleteIdentityButtonProps) => {
  const { t } = useTranslation();
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const captionText = useMemo(() => {
    let t = '';
    switch (identityTypeID) {
      case IdentityTypeIds.JOB:
        t =
          'Jobs can only be deleted if they have not been sent to the cloud. Deleting a job is irreversible.';
        break;
      default:
        t =
          'Items can only be deleted if there are no dependencies and no related data. Deleting an item is irreversible.';
        break;
    }

    return t;
  }, [identityTypeID]);

  const handleOnConfirmDelete = () => {
    handleOnDelete();
    setDialogIsOpen(false);
  };

  return (
    <>
      <Box px={2} sx={{ display: 'flex', width: 380, alignItems: 'center' }}>
        <Box pr={2}>
          <Typography variant="body2">
            {t(`Delete ${startCase(identityName)}`)}
          </Typography>
          <Typography variant="caption" style={{ lineHeight: '8px' }}>
            {t(`${captionText}`)}
          </Typography>
        </Box>
        <VineaButton
          variant="text"
          color="error"
          onClick={() => setDialogIsOpen(true)}
          disabled={isDisabled}
        >
          {t('Delete')}
        </VineaButton>
      </Box>

      <DeleteDialog
        onClose={() => setDialogIsOpen(false)}
        dialogHeader={t(`Delete ${identityName}?`)}
        open={dialogIsOpen}
        dialogActionTrigger={handleOnConfirmDelete}
        dialogContent={''}
        rowId={0}
      />
    </>
  );
};
