import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { actionTypes, reducers } from '../constants';

export default function useTabChangeLock() {
  const dispatch = useDispatch();

  const { data: { isLocked = false } = {} } =
    useSelector(state => state.tabChangeLock) || {};

  const setIsLocked = React.useCallback(locked => {
    dispatch({
      type: actionTypes.updateData,
      name: reducers.tabChangeLock,
      payload: { isLocked: locked },
    });
  }, []);

  const debounceLock = React.useCallback(
    debounce(() => setIsLocked(true), 1000),
    [],
  );

  // when we set the lock to true, set it back to false after 1000ms
  React.useEffect(() => {
    if (isLocked) {
      debounceLock();
    }
  }, [isLocked, debounceLock]);

  // ensure that lock is always set to false when we navigate to another screen
  // React.useEffect(() => () => setIsLocked(false), []);

  return {
    isLocked,
    setIsLocked,
  };
}
