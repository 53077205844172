export const providerFieldMap = {
  // Harvest.com
  1: { userName: true, password: true, apiKey: true },
  // Vinea block sync
  2: { userName: true, password: true, apiKey: true },
  // Vinea contract sync
  3: { userName: true, password: true, apiKey: true },
  // ESRI Arcgis API
  4: { userName: false, password: false, apiKey: false, apiURL: true },
  // Vinea Job Statistics
  5: { userName: true, password: true, apiKey: true },
  // Vintrace sampling data
  6: {
    apiURL: true,
    apiKey: true,
    dataIngestionStartDate: true,
    isDataIngestionEnabled: true,
  },
};
