import PropTypes from 'prop-types';
import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import classNames from 'classnames';
import VineaTextField from './TextField.tsx';

export const FieldLabelInput = ({
  id,
  value,
  onChange,
  onBlur,
  classes,
  disabled,
  error,
  label,
  name,
  multiline = false,
  rowsMax = 4,
  rows,
  sx,
  autoFocus = false,
  ...restProps
}) => {
  return (
    <FormControl
      className={classNames(classes.formControl)}
      error={!!error}
      margin="dense"
    >
      <VineaTextField
        autoComplete="off"
        id={id}
        size="small"
        label={label}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        multiline={multiline}
        rowsMax={rowsMax}
        rows={rows}
        sx={sx}
        autoFocus={autoFocus}
        {...restProps}
      />
      {error && (
        <FormHelperText id={`${id}-error-text`}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

FieldLabelInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  classes: PropTypes.shape({
    formControl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.node,
    ]),
    formControlLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.node,
    ]),
  }),
  error: PropTypes.string,
  label: PropTypes.string,

  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
};

FieldLabelInput.defaultProps = {
  id: 'none',
  value: '',
  onChange: f => f,
  onBlur: f => f,
  classes: {},
  error: null,
  label: '',
  name: '',

  multiline: false,
  rowsMax: 0,
  rows: 1,
  disabled: false,
};
