/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useTheme, styled } from '@mui/material/styles';
import {
  VineaNovaSelectors,
  VineaNovaActions,
} from 'vineanova-redux-artifacts';
import { get, isEmpty, first } from 'lodash';

import { FieldLabelInput } from '../../components/TextField';
import VineaButton from '../../components/VineaButton/VineaButton.tsx';
import VineaAutoComplete from '../../components/ComboBox/ComboBox.tsx';
import { SampleDetailsColumns } from './sampleDetailsColumns';
import {
  getSampleHeaderOptions,
  getSampleHeadersData,
  getSampleHeaderInstructionsMeta,
} from '../../redux/selectors';
import { GridWithFilter, GridNoRowMessage } from '../../components/Grid';
import { formatDate } from '../../constants/formatter';

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: 250,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFieldLabelInputFooter = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: 100,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormControl1 = styled(FormControl)(() => ({
  width: 250,
}));

const StyledFormControl2 = styled(FormControl)(() => ({
  width: 100,
}));

const StyledFormControlTextArea = styled(FormControl)(() => ({
  width: 350,
}));

const StyledFieldLabelTextArea = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: 350,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

export const SampleDetails = () => {
  const {
    isLoading: isMoveSampleLinesLoading,
    isLoaded: isMoveSampleLinesLoaded,
    hasError: moveSampleLinesHasError,
  } = useSelector(VineaNovaSelectors.getMoveSampleLinesEntityMeta);

  const {
    isLoading: issampleHeaderInstructionsLoading,
    isLoaded: issampleHeaderInstructionsLoaded,
    hasError: issampleHeaderInstructionsHasError,
  } = useSelector(getSampleHeaderInstructionsMeta);

  const { t } = useTranslation();
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { sampleHeaderID } = useParams();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [moveSampleLinesTrigger, setMoveSampleLinesTrigger] = useState(false);
  const [sampleLinesApiLoading, setSampleLinesApiLoading] = useState(true);
  const [formdata, setFormdata] = useState({
    vineyardName: '',
    blockFullName: '',
    requestDueDate: '',
    requestSentDate: '',
    requestCompletionDate: '',
    vintage: '',
    measure: '',
    recommendedSampleSize: 0,
    actualSampleSize: 0,
    actualMax: 0,
    actualMin: 0,
    actualStDev: 0,
    result: 0,
    status: '',
    sampleInstructions: '',
  });
  // Get all other sample headers in sample set
  const sampleHeaders = useSelector(getSampleHeaderOptions);
  const sampleSetSampleHeaderIDs = sampleHeaders?.filter(
    ({ id }) => id !== Number(sampleHeaderID),
  );

  const [moveToSampleHeaderID, setMoveToSampleHeaderID] = React.useState(0);
  const [selectedSampleLines, setSelectedSampleLines] = React.useState([]);
  const [updateApiStatus, setUpdateApiStatus] = React.useState(false);

  const sampleHeadersData = useSelector(getSampleHeadersData);
  const {
    isLoading: sampleLinesLoading,
    isLoaded: sampleLinesLoaded,
    data = [],
  } = useSelector(state =>
    VineaNovaSelectors.getSampleHeaderLinesEntity(state),
  );

  console.log('>>>>sampleHeadersData', sampleHeadersData);

  const { isMultiLineSample } = first(sampleHeadersData) || {};

  const { calculatedMeasureValue, actualMax, actualMin, actualStDev } =
    first(data) || {};

  // Set SampleHeader value when value is selected in dropdown
  const handleOnSelectHeader = evt => {
    const evtValue = evt.target.value;
    setMoveToSampleHeaderID(evtValue);
  };

  const handleOnClose = () => {
    setSelectedSampleLines([]);
    setMoveSampleLinesTrigger(false);
    navigate(-1);
  };

  const handleOnEditSampleLine = updatedRow => {
    const excludedPersonId =
      updatedRow.excludedByPersonId === 0
        ? null
        : updatedRow.excludedByPersonId;
    dispatchAPI(
      VineaNovaActions.api.v1.sampleHeaderLines.put.request({
        postBody: {
          excludedByPersonId: excludedPersonId,
          ...updatedRow,
        },
      }),
    );
    setSampleLinesApiLoading(true);
  };

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow };
    handleOnEditSampleLine(updatedRow);
    setSampleLinesApiLoading(true);
    return updatedRow;
  };

  const handleOnSave = () => {
    setSelectedSampleLines([]);
    console.log(VineaNovaActions.api.v1);
    dispatchAPI(
      VineaNovaActions.api.v1.sampleHeaderInstructions.put.request({
        postBody: {
          sampleHeaderID,
          sampleInstructions: formdata.sampleInstructions,
        },
      }),
    );
    setUpdateApiStatus(true);
  };

  useEffect(() => {
    if (
      updateApiStatus &&
      !issampleHeaderInstructionsLoading &&
      issampleHeaderInstructionsLoaded &&
      !issampleHeaderInstructionsHasError
    ) {
      enqueueSnackbar('success', {
        variant: 'success',
      });
      setUpdateApiStatus(false);
    } else if (
      updateApiStatus &&
      issampleHeaderInstructionsLoading &&
      issampleHeaderInstructionsHasError
    ) {
      enqueueSnackbar('error', { variant: 'error' });
      setUpdateApiStatus(false);
    }
  }, [
    enqueueSnackbar,
    updateApiStatus,
    issampleHeaderInstructionsLoaded,
    issampleHeaderInstructionsLoading,
    issampleHeaderInstructionsHasError,
  ]);

  // Call API to move sample lines to another sample header
  const handleOnMoveSampleData = () => {
    setMoveSampleLinesTrigger(true);
    dispatchAPI(
      VineaNovaActions.api.v1.moveSampleLines.put.request({
        postBody: {
          fromSampleHeaderID: sampleHeaderID,
          toSampleHeaderID: moveToSampleHeaderID,
          sampleDataIds: selectedSampleLines.join(','),
        },
      }),
    );
    setSelectedSampleLines([]);
  };

  const NoRowsOverlayMessage = () => (
    <Box mt={10} data-testid="sample-details-lines-norowmessage">
      <GridNoRowMessage message="Sorry, there were no records found." />
    </Box>
  );

  // Show success if hasLoaded and no error, else show error if hasError = true
  React.useEffect(() => {
    if (
      !isMoveSampleLinesLoading &&
      isMoveSampleLinesLoaded &&
      !moveSampleLinesHasError &&
      moveSampleLinesTrigger
    ) {
      enqueueSnackbar(t('Sample lines successfully moved'), {
        variant: 'Success',
      });
      setSampleLinesApiLoading(true);
      setMoveSampleLinesTrigger(false);
    } else if (moveSampleLinesHasError) {
      enqueueSnackbar(t('Error moving sample lines'), { variant: 'Error' });
    }
  }, [
    moveSampleLinesTrigger,
    isMoveSampleLinesLoading,
    isMoveSampleLinesLoaded,
    moveSampleLinesHasError,
    sampleHeaderID,
    enqueueSnackbar,
    t,
    dispatchAPI,
  ]);

  React.useEffect(() => {
    const selected = sampleHeadersData.filter(
      v => v.id === Number(sampleHeaderID),
    );
    let cols = [];

    if (selected[0].measureForm === 'BerriesPerBunchV2') {
      cols = SampleDetailsColumns('BerriesPerBunchV2');
    } else {
      cols = SampleDetailsColumns(get(selected, [0, 'measure']));
    }
    setColumns(cols);
    setFormdata(selected[0]);
    setSampleLinesApiLoading(true);
  }, [sampleHeaderID, sampleHeadersData]);

  React.useEffect(() => {
    if (!sampleLinesLoading && sampleLinesLoaded && Array.isArray(data)) {
      if (!sampleLinesApiLoading) {
        setRows(data);
      }
    }
  }, [data, sampleLinesLoaded, sampleLinesLoading, sampleLinesApiLoading]);

  // Get once put api is loaded
  React.useEffect(() => {
    if (sampleLinesApiLoading) {
      if (!sampleLinesLoading && sampleLinesLoaded) {
        dispatchAPI(
          VineaNovaActions.api.v1.sampleHeaderLines.get.request({
            queryParams: {
              SampleHeaderId: sampleHeaderID,
            },
          }),
        );
        setSampleLinesApiLoading(false);
      }
    }
  }, [
    dispatchAPI,
    sampleHeaderID,
    sampleLinesApiLoading,
    sampleLinesLoaded,
    sampleLinesLoading,
  ]);

  // Get on first load
  React.useEffect(() => {
    if (sampleHeaderID) {
      dispatchAPI(
        VineaNovaActions.api.v1.sampleHeaderLines.get.request({
          queryParams: {
            SampleHeaderId: sampleHeaderID,
          },
        }),
      );
    }
  }, [dispatchAPI, sampleHeaderID]);

  const handleOnChangeField = evt => {
    const { value, name } = evt.target;
    setFormdata(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid container spacing={0} data-testid="sample-details" direction="column">
      <Paper
        elevation={0}
        data-testid="sample-details-paper"
        sx={{
          padding: theme.spacing(2),
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} margin={3}>
          <Box mx={1}>
            <VineaButton
              variant="contained"
              color="primary"
              size="large"
              data-testid="sample-details-save-btn"
              onClick={handleOnSave}
            >
              {t('Save')}
            </VineaButton>
          </Box>
          <Box mx={1}>
            <VineaButton
              variant="outlined"
              color="secondary"
              size="large"
              data-testid="sample-details-close-btn"
              onClick={handleOnClose}
            >
              {t('Close')}
            </VineaButton>
          </Box>
        </Box>
        <Box
          borderRadius="5px"
          border="1px solid black"
          margin={3}
          data-testid="sample-details-header"
        >
          <Grid container direction="row" rowSpacing={1} p={1}>
            <Grid item xs={9}>
              <StyledFormControl1 margin="none">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="vineyardName"
                  name="vineyardName"
                  label={t('Vineyard Name')}
                  size="small"
                  inlineLabel
                  value={formdata?.vineyardName}
                  disabled={true}
                />
              </StyledFormControl1>
              <StyledFormControl1 margin="none">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="blockFullName"
                  name="blockFullName"
                  label={t('Block Section')}
                  size="small"
                  inlineLabel
                  value={formdata?.blockFullName}
                  disabled={true}
                />
              </StyledFormControl1>
              <StyledFormControl1 margin="none">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="createdate"
                  name="requestSentDate"
                  label={t('Sent Date')}
                  size="small"
                  inlineLabel
                  value={formatDate(formdata?.requestSentDate)}
                  disabled={true}
                />
              </StyledFormControl1>
              <StyledFormControl1 margin="none">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="requestDueDate"
                  name="requestDueDate"
                  label={t('Due Date')}
                  size="small"
                  inlineLabel
                  value={formatDate(formdata?.requestDueDate)}
                  disabled={true}
                />
              </StyledFormControl1>
              <StyledFormControl1 margin="none">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="requestCompletionDate"
                  name="requestCompletionDate"
                  label={t('Completion Date')}
                  size="small"
                  inlineLabel
                  value={formatDate(formdata?.requestCompletionDate)}
                  disabled={true}
                />
              </StyledFormControl1>
              <StyledFormControl1 margin="none">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="vintage"
                  name="vintage"
                  label={t('Vintage')}
                  size="small"
                  inlineLabel
                  value={formdata?.vintage === null ? '' : formdata?.vintage}
                  disabled={true}
                />
              </StyledFormControl1>
              <StyledFormControl1 margin="none">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="measure"
                  name="measure"
                  label={t('Measure')}
                  size="small"
                  inlineLabel
                  value={formdata?.measure === null ? '' : formdata?.measure}
                  disabled={true}
                />
              </StyledFormControl1>
              <StyledFormControl1 margin="none">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="recommendedSampleSize"
                  name="recommendedSampleSize"
                  label={t('Recommended Sample Size')}
                  size="small"
                  inlineLabel
                  value={
                    formdata?.recommendedSampleSize === null
                      ? 0
                      : formdata?.recommendedSampleSize
                  }
                  disabled={true}
                />
              </StyledFormControl1>
              <StyledFormControl1 margin="none">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="actualSampleSize"
                  name="actualSampleSize"
                  label={t('Actual Sample Size')}
                  size="small"
                  inlineLabel
                  value={formdata?.actualSampleSize}
                  disabled={true}
                />
              </StyledFormControl1>
              <StyledFormControl1 margin="none">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="actualMin"
                  name="actualMin"
                  label={t('Min / Max / Avg')}
                  size="small"
                  inlineLabel
                  value={
                    formdata?.actualMin +
                    ' / ' +
                    formdata?.actualMax +
                    ' / ' +
                    formdata?.result
                  }
                  disabled={true}
                />
              </StyledFormControl1>
              <StyledFormControl1 margin="none">
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="actualStDev"
                  name="actualStDev"
                  label={t('Standard Dev.')}
                  size="small"
                  inlineLabel
                  value={formdata?.actualStDev}
                  disabled={true}
                />
              </StyledFormControl1>
            </Grid>
            <Grid item xs={3} data-testid="sample-instruction-griditem">
              <StyledFormControlTextArea
                margin="none"
                data-testid="sample-instruction-formctrl"
              >
                <StyledFieldLabelTextArea
                  sx={{
                    '& .MuiInputBase-inputMultiline': { m: 1, width: '25ch' },
                  }}
                  data-testid="sample-instruction-fieldinput"
                  autoComplete="off"
                  multiline
                  size="large"
                  rows={2}
                  id="sampleInstructions"
                  name="sampleInstructions"
                  label={t('Sample Instructions')}
                  inlineLabel
                  value={
                    formdata?.sampleInstructions === null
                      ? 0
                      : formdata?.sampleInstructions
                  }
                  onChange={handleOnChangeField}
                />
              </StyledFormControlTextArea>
            </Grid>
          </Grid>
        </Box>
        <Box mx={3} sx={{ height: 450 }} data-testid="sample-details-lines">
          {formdata?.status !== 'Closed' && formdata?.status !== 'Complete' && (
            <GridWithFilter
              data={{}}
              columns={columns}
              rows={rows}
              disableColumnMenu
              disableColumnResize
              disableChildrenSorting
              onColumnHeaderEnter={() => {}}
              headerHeight={100}
              rowHeight={30}
              autoPageSize
              editMode="row"
              noRowsOverlay={NoRowsOverlayMessage}
              loading={sampleLinesLoading}
              advancedSearch={false}
              processRowUpdate={processRowUpdate}
              disableColumnSelector
              experimentalFeatures={{ newEditingApi: true }}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={newSelectionModel => {
                setSelectedSampleLines(newSelectionModel);
              }}
              selectionModel={selectedSampleLines}
            />
          )}
          {formdata?.status === 'Closed' && (
            <GridWithFilter
              data={{}}
              columns={columns}
              rows={rows}
              disableColumnMenu
              disableColumnResize
              disableChildrenSorting
              onColumnHeaderEnter={() => {}}
              headerHeight={100}
              rowHeight={30}
              autoPageSize
              noRowsOverlay={NoRowsOverlayMessage}
              loading={sampleLinesLoading}
              advancedSearch={false}
              disableColumnSelector
              onRowClick={() => {}}
              onRowDoubleClick={() => {}}
              onCellClick={() => {}}
              onCellDoubleClick={() => {}}
              isCellEditable={params => params.row.sampleRequestStatusID !== 4}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={newSelectionModel => {
                setSelectedSampleLines(newSelectionModel);
              }}
              selectionModel={selectedSampleLines}
            />
          )}
          {formdata?.status === 'Complete' && (
            <GridWithFilter
              data={{}}
              columns={columns}
              rows={rows}
              rowHeight={30}
              headerHeight={100}
              disableColumnMenu
              disableColumnResize
              disableChildrenSorting
              onColumnHeaderEnter={() => {}}
              autoPageSize
              noRowsOverlay={NoRowsOverlayMessage}
              loading={sampleLinesLoading}
              advancedSearch={false}
              disableColumnSelector
              onRowClick={() => {}}
              onRowDoubleClick={() => {}}
              onCellClick={() => {}}
              onCellDoubleClick={() => {}}
              isCellEditable={params => params.row.sampleRequestStatusID !== 3}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={newSelectionModel => {
                setSelectedSampleLines(newSelectionModel);
              }}
              selectionModel={selectedSampleLines}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: isMultiLineSample ? 'space-between' : 'flex-end',
          }}
          data-testid="sample-details-footer"
        >
          {isMultiLineSample &&
            formdata.measure !== 'Combined Bunch And Berry Weight' && (
              <Box borderRadius="5px" border="1px solid black" margin={3}>
                <Grid container direction="row">
                  <StyledFormControl2 margin="none">
                    <StyledFieldLabelInputFooter
                      autoComplete="off"
                      id="sampleMin"
                      name="sampleMin"
                      label={t('Min')}
                      size="small"
                      inlineLabel
                      value={actualMin}
                    />
                  </StyledFormControl2>
                  <StyledFormControl2 margin="none">
                    <StyledFieldLabelInputFooter
                      autoComplete="off"
                      id="sampleMax"
                      name="sampleMax"
                      label={t('Max')}
                      size="small"
                      inlineLabel
                      value={actualMax}
                    />
                  </StyledFormControl2>
                  <StyledFormControl2 margin="none">
                    <StyledFieldLabelInputFooter
                      autoComplete="off"
                      id="sampleSTD"
                      name="sampleSTD"
                      label={t('STD Dev')}
                      size="small"
                      inlineLabel
                      value={actualStDev}
                    />
                  </StyledFormControl2>
                  <StyledFormControl2 margin="none">
                    <StyledFieldLabelInputFooter
                      autoComplete="off"
                      id="sampleMean"
                      name="sampleMean"
                      label={t('Mean')}
                      size="small"
                      inlineLabel
                      value={calculatedMeasureValue}
                    />
                  </StyledFormControl2>
                </Grid>
              </Box>
            )}
          <Box borderRadius="5px" border="1px solid black" margin={3}>
            <Grid container direction="row">
              <StyledFormControl1 margin="none">
                <VineaAutoComplete
                  value={moveToSampleHeaderID}
                  onChange={handleOnSelectHeader}
                  options={sampleSetSampleHeaderIDs}
                  labelVariant="body1"
                  label={t('Select Another Header')}
                  name={t('Another Header')}
                  inlineLabel
                  inputProps={{
                    name: 'header',
                  }}
                />
              </StyledFormControl1>
              <Box mx={1} my={3}>
                <VineaButton
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={handleOnMoveSampleData}
                  disabled={
                    moveToSampleHeaderID === 0 || isEmpty(selectedSampleLines)
                  }
                >
                  {t('Move Sample Data')}
                </VineaButton>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};
