import * as Yup from 'yup';
import { isValid } from 'date-fns';

export const CropBlockVintageSchema = Yup.object().shape({
  measureDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
  measureInstanceId: Yup.string().when(['isAdd'], {
    is: isAdd => isAdd === true,
    then: Yup.string()
      .notOneOf(['Select', 0, '0'], 'Required')
      .typeError('Required')
      .required('Required'),
    otherwise: Yup.string().nullable(),
  }),
  measureValue1: Yup.string()
    .matches(/^\d*\.?\d+$/, 'Must be a numeric value')
    .nullable()
    .required('Required'),
});

export const CropSettingsSchema = Yup.object().shape({
  cropSettingOptionID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .typeError('Required')
    .required('Required'),
  cropSettingInstanceID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .typeError('Required')
    .required('Required'),
});

export const PhenologyBlockVintageSchema = Yup.object().shape({
  phenologyMeasureDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
  phenologyMeasureSetID: Yup.string().when(['isAdd'], {
    is: isAdd => isAdd === true,
    then: Yup.string()
      .notOneOf(['Select', 0, '0'], 'Required')
      .typeError('Required')
      .required('Required'),
    otherwise: Yup.string().nullable(),
  }),

  phenologyMeasureTypeID: Yup.number().required('Required'),
  phenologyMeasureID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Required')
    .typeError('Required')
    .required('Required'),

  phenologyMeasureOptionID: Yup.number().when(
    ['phenologyMeasureTypeID', 'phenologyMeasureID'],
    {
      is: (phenologyMeasureTypeID, phenologyMeasureID) =>
        phenologyMeasureTypeID === 1 && phenologyMeasureID > 0,
      then: Yup.number()
        .notOneOf(['Select', 0], 'Required')
        .typeError('Required')
        .required('Required'),
      otherwise: Yup.number().min(0).nullable(true),
    },
  ),
  phenologyMeasureValue1: Yup.string().when(
    ['phenologyMeasureTypeID', 'phenologyMeasureID'],
    {
      is: (phenologyMeasureTypeID, phenologyMeasureID) =>
        phenologyMeasureTypeID > 1 && phenologyMeasureID > 0,
      then: Yup.string()
        .matches(/^\d*\.?\d+$/, 'Must be a numeric value')
        .typeError('Required')
        .required('Required'),
      otherwise: Yup.string().nullable(),
    },
  ),
  phenologyMeasureValue2: Yup.string().when(
    ['phenologyMeasureTypeID', 'phenologyMeasureID'],
    {
      is: (phenologyMeasureTypeID, phenologyMeasureID) =>
        phenologyMeasureTypeID > 2 && phenologyMeasureID > 0,
      then: Yup.string()
        .matches(/^\d*\.?\d+$/, 'Must be a numeric value')
        .typeError('Required')
        .required('Required'),
      otherwise: Yup.string().nullable(),
    },
  ),
  phenologyMeasureValue3: Yup.string().when(
    ['phenologyMeasureTypeID', 'phenologyMeasureID'],
    {
      is: (phenologyMeasureTypeID, phenologyMeasureID) =>
        phenologyMeasureTypeID > 3 && phenologyMeasureID > 0,
      then: Yup.string()
        .matches(/^\d*\.?\d+$/, 'Must be a numeric value')
        .typeError('Required')
        .required('Required'),
      otherwise: Yup.string().nullable(),
    },
  ),
});
