import { useSelector } from 'react-redux';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { actionTypes, reducers, sagaActionTypes } from '../../constants';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

export const useSupplyContractsSearchHook = () => {
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const [filterData, setFilterData] = useState({});
  const [filterText, setFilterText] = useState();
  //@ts-ignore
  const allSupplyContracts = useSelector(
    VineaNovaSelectors.getSearchSupplyContractsEntity,
  );

  const { data: supplyContractSearchFilter = {} } = useSelector(
    //@ts-ignore
    state => state.supplyContractSearchFilter,
  );
  const defaultFilterValues = useMemo(() => {
    return {
      contractName: '',
      supplyingPartyIdentityID: null,
      purchasingPartyIdentityID: null,
      supplyContractTypeID: null,
      searchIsActive: true,
    };
  }, []);

  const onUpdateFilterData = (data: any) => {
    setFilterData(data);
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.supplyContractSearchFilter,
      payload: data,
    });
  };

  const dispatchSearchSupplyContract = () => {
    dispatchAPI({
      type: sagaActionTypes.SEARCH_SUPPLY_CONTRACT,
      name: reducers.searchSupplyContracts,
      payload: {
        ...defaultFilterValues,
      },
    });
  };

  const handleOnNewSupplyContract = () => {
    navigate('/supplycontract/newsupplycontract');
  };

  const onSearchIdentities = (searchParams: any, newFilterText: any) => {
    setFilterData(searchParams);
    dispatchAPI(
      VineaNovaActions.api.v1.searchSupplyContracts.post.request({
        postBody: {
          ...searchParams,
        },
      }),
    );

    setFilterText(newFilterText);
  };

  // update search when filter data changes
  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.searchSupplyContracts.post.request({
        postBody: {
          ...filterData,
        },
      }),
    );
  }, [filterData]);

  return {
    allSupplyContracts,
    defaultFilterValues,
    supplyContractSearchFilter,
    filterData,
    filterText,
    setFilterText,
    setFilterData,
    onUpdateFilterData,
    handleOnNewSupplyContract,
    dispatchSearchSupplyContract,
    onSearchIdentities,
  };
};
