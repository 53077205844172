import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';

import { DashboardPage, useDashboardHook } from '.';
import { TabPanel, TabView } from '../../components/TabView';
import { getAllDashboardPagesSelector } from '../../redux/selectors';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import { actionTypes, reducers } from '../../constants';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { Grid, Skeleton } from '@mui/material';

const Dashboard = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const { setIsLocked } = useTabChangeLock();
  const { data: latestChartData = {} } = useSelector(
    state => state.latestChartData,
  );
  const dispatchAPI = useDispatch();
  const {
    chartFilters,
    basicSettings,
    canDisplayDashboard,
    pageType,
    handleApplyChartFilter,
    handleApplyDashboardPageFilter,
    handleDashboardPageFilterChange,
  } = useDashboardHook();

  const pages = useSelector(getAllDashboardPagesSelector);
  const dashPageHeaders = pages.map(e => t(e.pageName));

  const { data: charts } = useSelector(state => state.latestChartData);

  const handleonTabChange = newValue => {
    setTabIndex(newValue);
    setIsLocked(true);
  };

  const handleChangeIndex = index => {
    setTabIndex(index);
  };

  // get charts for a given page, sorted by their sequence order
  const getChartsForPage = page => {
    return (
      charts?.[page.dashboardPageID]?.sort(
        ({ chartSequence: a }, { chartSequence: b }) => a - b,
      ) || []
    );
  };

  const handleUpdateChartFilter = () => {
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.latestChartData,
      payload: latestChartData,
    });
  };

  const FallbackComponent = ({ resetErrorBoundary, ...restProps }) => {
    const originalPage = useRef(tabIndex);

    useEffect(() => {
      if (tabIndex !== originalPage.current) {
        resetErrorBoundary();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabIndex]);

    return (
      <ErrorBoundaryFallback
        resetErrorBoundary={resetErrorBoundary}
        {...restProps}
      />
    );
  };

  return (
    <Box sx={{ flex: 1, display: 'flex', height: '100%' }}>
      {canDisplayDashboard ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            '&  #tabpanelcontentwrapper': { padding: 0, height: '100%' },
            '& .react-swipeable-view-container': { height: '100%' },
            '& .tabpanel': { height: '100%' },
          }}
          data-testid="tabview-dashboardpage"
        >
          <TabView
            tabHeaders={dashPageHeaders}
            onTabChange={handleonTabChange}
            onSwipe={handleChangeIndex}
            value={tabIndex}
            dataTestId="tabview-dashboard"
          >
            {pages.map((page, index) => (
              <TabPanel
                key={page.dashboardPageID}
                value={tabIndex}
                index={index}
                dir={theme.direction}
                tabpanelname={tabIndex}
                dataTestId={`tabpanel-${page.pageName}`}
              >
                <ErrorBoundary FallbackComponent={FallbackComponent}>
                  <DashboardPage
                    chartFilters={chartFilters}
                    pageData={page}
                    pageType={pageType}
                    chartsData={getChartsForPage(page)}
                    handleApplyChartFilter={handleApplyChartFilter}
                    handleApplyDashboardPageFilter={
                      handleApplyDashboardPageFilter
                    }
                    handleUpdateChartFilter={handleUpdateChartFilter}
                    handleDashboardPageFilterChange={
                      handleDashboardPageFilterChange
                    }
                    basicSettings={basicSettings}
                  />
                </ErrorBoundary>
              </TabPanel>
            ))}
          </TabView>
        </Box>
      ) : (
        [1, 2, 2, 2].map((aspectRatio, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item key={key} mb={2} mr={2}>
            <Skeleton
              variant="rectangular"
              sx={{
                height: 'calc(180px + 20vh)',
                minHeight: '100px',
                aspectRatio: `${aspectRatio}`,
              }}
            />
          </Grid>
        ))
      )}
    </Box>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
