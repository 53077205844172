/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ColorizeOutlinedIcon from '@mui/icons-material/ColorizeOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FeedIcon from '@mui/icons-material/Feed';
import AppsIcon from '@mui/icons-material/Apps';
import useMediaQuery from '@mui/material/useMediaQuery';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { VineaHooks } from 'vineanova-redux-artifacts';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';

import PopperComponent from '../Popper/popper';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/extensions
import SideBarListItem from './SidebarListItem';
import { StyledListItem } from './StyledListItem';
import useUserResponsiveLayout from '../../hooks/useUserResponsiveLayout';
import { useFeatureFlag } from '../../hooks/useFeatureConfiguration';
import { IdentityTypeIds } from '../../constants';
import { capitalize } from 'lodash';
import useIdentityTypeScreenNameHook from '../../hooks/useIdentityTypeScreenNameHook';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      top: '48px',
      height: 'calc(100% - 48px)',
      position: 'fixed',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: theme.palette.primary.dark,
      overflow: 'hidden',
      '& .MuiIconButton-label': {
        // @ts-ignore
        color: theme.palette.icon,
      },
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(8),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    menuOption: {
      fontSize: '1.5rem',
      '& a': {
        textDecoration: 'none',
      },
      '& .MuiListItemText-primary, .MuiListItemIcon-root': {
        // @ts-ignore
        color: theme.palette.icon,
      },
    },
    menuOptionSelected: {
      fontSize: '1.5rem',
      '& a': {
        textDecoration: 'none',
      },
      '& .MuiListItemText-primary, .MuiListItemIcon-root': {
        // @ts-ignore
        color: theme.palette.primary.contrastText,
      },
    },
    spacer: {
      height: 48,
    },
    drawerExpand: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'flex-end',
      '& .MddduiSvgIcon-root': {
        transform: 'rotate(-180deg)',
      },
    },
    iconRotate: {
      '& .MuiSvgIcon-root': {
        transform: 'rotate(-180deg)',
      },
    },
  }),
);

interface ISidebarProps {
  isAuthenticated: boolean;
  isVineaAdmin: boolean;
}

enum IMenuType {
  MAPS,
  IDENTITIES,
  PLANNING,
  SAMPLING,
  CONFIGURATION,
  OTHER_IDENTITIES,
  PEOPLE,
  CONTRACT,
}

const Sidebar: React.FC<ISidebarProps> = ({
  isAuthenticated,
  isVineaAdmin,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState<boolean>(false);
  const [popperOpen, setPopperOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuType, setMenuType] = useState<IMenuType>(IMenuType.IDENTITIES);

  // hooks;
  const {
    isBelowLaptop: isTabletDevice,
    isAllowedOnTablet,
    isAdministrator,
  } = useUserResponsiveLayout();

  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();

  const { data: identityTypes } = VineaHooks.useFetchlookupIdentityType({});
  const isActiveVineyardIdentityType = get(
    find(identityTypes, { id: IdentityTypeIds.VINEYARD }),
    'isActive',
    true,
  );

  const isActiveServiceProvider = get(
    find(identityTypes, { id: IdentityTypeIds.SERVICE_PROVIDER }),
    'isActive',
    true,
  );

  const isActiveOrg = get(
    find(identityTypes, { id: IdentityTypeIds.ORGANISATION }),
    'isActive',
    true,
  );

  const identityJobFeature = useFeatureFlag('identityjob');
  console.log('featureFlags', identityJobFeature);
  // @ts-ignore
  const mapsRef = React.useRef<React.HTMLButtonElement>(null);
  // @ts-ignore
  const anchorRef = React.useRef<React.HTMLButtonElement>(null);
  // @ts-ignore
  const pickerAnchorRef = React.useRef<React.HTMLButtonElement>(null);
  // @ts-ignore
  const configRef = React.useRef<React.HTMLButtonElement>(null);
  // @ts-ignore
  const menuRef = React.useRef<React.HTMLButtonElement>(null);
  // @ts-ignore
  const planningRef = React.useRef<React.HTMLButtonElement>(null);
  // @ts-ignore
  const peopleRef = React.useRef<React.HTMLButtonElement>(null);
  // @ts-ignore
  const contractRef = React.useRef<React.HTMLButtonElement>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const activeIfPathStartsWith = (pathStarts: string[]): boolean => {
    return pathStarts.some(start => location.pathname.startsWith(start));
  };

  const handleClose = (route: string): void => {
    setPopperOpen(false);
    navigate(route);
  };

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setPopperOpen(false);
    }
  };

  const closePopper = () => {
    setPopperOpen(false);
  };

  const openPopper = () => {
    setPopperOpen(true);
  };

  const openMapsPopper = () => {
    setMenuType(IMenuType.MAPS);
    openPopper();
  };

  const openVineyardsPopper = () => {
    setMenuType(IMenuType.IDENTITIES);
    openPopper();
  };

  const openPlanningPopper = () => {
    setMenuType(IMenuType.PLANNING);
    openPopper();
  };

  const openConfigurationPopper = () => {
    setMenuType(IMenuType.CONFIGURATION);
    openPopper();
  };

  const openSamplingPopper = () => {
    setMenuType(IMenuType.SAMPLING);
    openPopper();
  };

  const openOtherIdentitesPopper = () => {
    setMenuType(IMenuType.OTHER_IDENTITIES);
    openPopper();
  };

  const openPeoplePopper = () => {
    setMenuType(IMenuType.PEOPLE);
    openPopper();
  };

  const openContractPopper = () => {
    setMenuType(IMenuType.CONTRACT);
    openPopper();
  };

  const prevPopperOpen = React.useRef(popperOpen);
  React.useEffect(() => {
    if (prevPopperOpen.current === true && popperOpen === false) {
      anchorRef.current?.focus();
    }
    prevPopperOpen.current = popperOpen;
  }, [popperOpen]);

  const mapsOptions = [
    { value: t('Activity Progress'), route: '/maps/activity-progress' },
    {
      value: t(`${capitalize(vineyardTypeScreenName)} Explorer`),
      route: `/maps/${vineyardTypeScreenName}-explorer`,
    },
  ];

  const orgOptions = [
    {
      value: t(`${capitalize(vineyardTypeScreenName)}s`),
      route: `/organisation/${vineyardTypeScreenName}s`,
      active: isActiveVineyardIdentityType,
    },
    {
      value: t('Service Providers'),
      route: '/organisation/serviceproviders',
      active: isActiveServiceProvider,
    },
    {
      value: t('Other Organisations'),
      route: '/organisation/other-organisations',
      active: isActiveOrg,
    },
  ];

  const activeOrgOptions = filter(orgOptions, { active: true });

  const samplingOptions = [
    { value: t('Sampling'), route: '/samples/sampling' },
    { value: t('Tracking'), route: '/samples/tracking' },
  ];

  const planningOptions = isVineaAdmin
    ? [
        { value: t('Activities'), route: '/activity' },
        { value: t('Plans'), route: '/plans' },
        { value: t('Tasks'), route: '/tasks' },
      ]
    : [];

  const configOptions = isVineaAdmin
    ? [
        { value: t('Data Connections'), route: '/connections' },
        { value: t('Global Settings'), route: '/global' },
        { value: t('Maintain Users'), route: '/users' },
      ]
    : [
        { value: t('Data Connections'), route: '/connections' },
        { value: t('Global Settings'), route: '/global' },
      ];

  const otherIdentityOptions = [
    {
      value: t('Accommodation'),
      route: '/accommodation',
      active: get(
        find(identityTypes, { id: IdentityTypeIds.ACCOMMODATION }),
        'isActive',
        true,
      ),
    },
    {
      value: t('Consumable'),
      route: '/consumable',
      active: get(
        find(identityTypes, { id: IdentityTypeIds.CONSUMABLE }),
        'isActive',
        true,
      ),
    },
    {
      value: t('Vehicle / Equipment'),
      route: '/vehicle',
      active: get(
        find(identityTypes, { id: IdentityTypeIds.VEHICLE }),
        'isActive',
        true,
      ),
    },
  ];

  const activeOtherIdentityOptions = filter(otherIdentityOptions, {
    active: true,
  });

  const peopleOptions = [
    { value: t('Person'), route: '/person' },
    { value: t('Worker'), route: '/worker' },
  ];

  const jobOptions = [
    {
      value: t('Job'),
      route: '/job',
      active:
        get(
          find(identityTypes, { id: IdentityTypeIds.JOB }),
          'isActive',
          true,
        ) && identityJobFeature,
    },
    {
      value: t('Supply Contract'),
      route: '/supplycontract',
      active: get(
        find(identityTypes, { id: IdentityTypeIds.SUPPLY_CONTRACT }),
        'isActive',
        true,
      ),
    },
  ];
  const activeJobOptions = filter(jobOptions, {
    active: true,
  });

  const isPersonActive = get(
    find(identityTypes, { id: IdentityTypeIds.PERSON }),
    'isActive',
    true,
  );

  const contractMenuTitle = identityJobFeature
    ? 'sidebar.menu.jobsandcontracts'
    : 'sidebar.menu.supplycontracts';

  // show sidebar only if appAccessDenied is false && tablet or desktop

  if (isMobileDevice) {
    return null;
  }

  return (
    <div>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !open && classes.drawerPaperClose,
            ),
          }}
          open={open}
          data-testid="drawer"
        >
          <List>
            {!!isAuthenticated && (
              <>
                {!isTabletDevice && (
                  <SideBarListItem
                    to="/home"
                    toolTip={t('sidebar.menu.home').toString()}
                    primaryText={t('sidebar.menu.home')}
                  >
                    <HomeOutlined />
                  </SideBarListItem>
                )}
                {(!isTabletDevice || isAllowedOnTablet) && (
                  <SideBarListItem
                    to="/dashboard"
                    toolTip={t('sidebar.menu.dashboard').toString()}
                    primaryText={t('sidebar.menu.dashboard')}
                  >
                    <SpeedOutlinedIcon />
                  </SideBarListItem>
                )}

                {!isTabletDevice && (
                  <StyledListItem
                    // @ts-ignore
                    isActive={activeIfPathStartsWith(['/maps'])}
                    ref={mapsRef}
                    aria-controls={popperOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={openMapsPopper}
                    onMouseEnter={openMapsPopper}
                    onMouseLeave={closePopper}
                    data-testid="maps-menu-item"
                  >
                    <ListItemIcon>
                      <MapOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Maps')} />
                  </StyledListItem>
                )}

                {!isTabletDevice && (
                  <>
                    {activeOrgOptions.length > 0 && (
                      <StyledListItem
                        // @ts-ignore
                        isActive={activeIfPathStartsWith(['/organisation'])}
                        ref={anchorRef}
                        aria-controls={
                          popperOpen ? 'menu-list-grow' : undefined
                        }
                        aria-haspopup="true"
                        onClick={openVineyardsPopper}
                        onMouseEnter={openVineyardsPopper}
                        onMouseLeave={closePopper}
                        data-testid="organisation"
                      >
                        <ListItemIcon>
                          <BusinessOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={t('sidebar.menu.organisations')}
                        />
                      </StyledListItem>
                    )}
                    {isPersonActive && (
                      <StyledListItem
                        ref={peopleRef}
                        aria-controls={
                          popperOpen ? 'menu-list-grow' : undefined
                        }
                        aria-haspopup="true"
                        onClick={openPeoplePopper}
                        onMouseEnter={openPeoplePopper}
                        onMouseLeave={closePopper}
                        data-testid="people"
                        // @ts-ignore
                        isActive={activeIfPathStartsWith([
                          '/person',
                          '/worker',
                        ])}
                      >
                        <ListItemIcon>
                          <PeopleOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('sidebar.menu.people')} />
                      </StyledListItem>
                    )}

                    <StyledListItem
                      // @ts-ignore
                      isActive={activeIfPathStartsWith(['/planning'])}
                      ref={planningRef}
                      aria-controls={popperOpen ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={openPlanningPopper}
                      onMouseEnter={openPlanningPopper}
                      onMouseLeave={closePopper}
                      data-testid="planning-menu-item"
                    >
                      <ListItemIcon>
                        <DateRangeOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('Planning')} />
                    </StyledListItem>
                  </>
                )}
                {!isTabletDevice && (
                  <StyledListItem
                    ref={pickerAnchorRef}
                    aria-controls={popperOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={openSamplingPopper}
                    onMouseEnter={openSamplingPopper}
                    onMouseLeave={closePopper}
                    data-testid="sampling"
                    // @ts-ignore
                    isActive={activeIfPathStartsWith(['/samples'])}
                  >
                    <ListItemIcon>
                      <ColorizeOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t('sidebar.menu.samplingandtracking')}
                    />
                  </StyledListItem>
                )}

                {!isTabletDevice && activeJobOptions.length > 0 && (
                  <StyledListItem
                    ref={contractRef}
                    aria-controls={popperOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={openContractPopper}
                    onMouseEnter={openContractPopper}
                    onMouseLeave={closePopper}
                    data-testid="jobsupplycontracts_sidebar"
                    // @ts-ignore
                    isActive={activeIfPathStartsWith([
                      '/job',
                      '/supplycontract',
                    ])}
                  >
                    <ListItemIcon>
                      <FeedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t(contractMenuTitle)} />
                  </StyledListItem>
                )}
                {!isTabletDevice && (
                  <>
                    <SideBarListItem
                      to="/reporting"
                      toolTip={t('sidebar.menu.reporting')}
                      primaryText={t('sidebar.menu.reporting')}
                    >
                      <BarChartOutlinedIcon />
                    </SideBarListItem>
                    {isAdministrator && (
                      <>
                        <StyledListItem
                          ref={configRef}
                          aria-controls={
                            popperOpen ? 'menu-list-grow' : undefined
                          }
                          aria-haspopup="true"
                          onClick={openConfigurationPopper}
                          onMouseEnter={openConfigurationPopper}
                          onMouseLeave={closePopper}
                          data-testid="configuration"
                          // @ts-ignore
                          isActive={activeIfPathStartsWith([
                            '/global',
                            '/user',
                            '/connections',
                          ])}
                        >
                          <ListItemIcon>
                            <SettingsOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary={t('sidebar.menu.settings')} />
                        </StyledListItem>
                        {activeOtherIdentityOptions.length > 0 && (
                          <StyledListItem
                            ref={menuRef}
                            aria-controls={
                              popperOpen ? 'menu-list-grow' : undefined
                            }
                            aria-haspopup="true"
                            onClick={openOtherIdentitesPopper}
                            onMouseEnter={openOtherIdentitesPopper}
                            onMouseLeave={closePopper}
                            data-testid="extras"
                            // @ts-ignore
                            isActive={activeIfPathStartsWith([
                              '/accommodation',
                              '/consumable',
                              '/contract',
                              '/course',
                              '/incident',
                              '/vehicle',
                            ])}
                          >
                            <ListItemIcon>
                              <AppsIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('Extras')} />
                          </StyledListItem>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </List>
          <List
            className={classNames(
              classes.drawerExpand,
              open && classes.iconRotate,
            )}
          >
            <StyledListItem disableGutters>
              <ListItemIcon>
                <IconButton
                  className={classes.menuOption}
                  aria-label={!open ? 'show drawer' : 'close drawer'}
                  component="span"
                  onClick={() => setOpen(!open)}
                  data-testid="drawer-iconbtn"
                  size="large"
                  color="primary"
                >
                  <DoubleArrowIcon />
                </IconButton>
              </ListItemIcon>
            </StyledListItem>
          </List>
        </Drawer>
      </ClickAwayListener>
      <div onMouseEnter={openPopper} onMouseLeave={closePopper}>
        <PopperComponent
          popperOpen={popperOpen}
          current={
            menuType === IMenuType.MAPS
              ? mapsRef.current
              : menuType === IMenuType.IDENTITIES
              ? anchorRef.current
              : menuType === IMenuType.SAMPLING
              ? pickerAnchorRef.current
              : menuType === IMenuType.PLANNING
              ? planningRef.current
              : menuType === IMenuType.CONFIGURATION
              ? configRef.current
              : menuType === IMenuType.CONTRACT
              ? contractRef.current
              : menuType === IMenuType.OTHER_IDENTITIES
              ? menuRef.current
              : peopleRef.current
          }
          handleListKeyDown={handleListKeyDown}
          options={
            menuType === IMenuType.MAPS
              ? mapsOptions
              : menuType === IMenuType.IDENTITIES
              ? activeOrgOptions
              : menuType === IMenuType.SAMPLING
              ? samplingOptions
              : menuType === IMenuType.OTHER_IDENTITIES
              ? activeOtherIdentityOptions
              : menuType === IMenuType.PLANNING
              ? planningOptions
              : menuType === IMenuType.CONFIGURATION
              ? configOptions
              : menuType === IMenuType.CONTRACT
              ? activeJobOptions
              : peopleOptions
          }
          handleClose={handleClose}
          disableClickAway
        />
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Sidebar;
