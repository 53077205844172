import React, { useEffect, useReducer, useState } from 'react';
import { find, isEmpty, map, max, filter, isNil } from 'lodash';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import sortBy from 'lodash/sortBy';
import { useTheme, styled } from '@mui/material/styles';
import { VineaAlert } from '../../../components/VineaAlert';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/lab/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { VineaButton } from '../../../components/VineaButton';
import { FieldLabelInput } from '../../../components/TextField';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { usePlansHook } from '../hooks/usePlansHook';
import { Datepicker } from '../../../components/Datepicker';
import { viewDateFormat } from '../../../constants';
import { useDispatch } from 'react-redux';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import { format } from 'date-fns';
import { sagaActionTypes, vineaDetails, apiTypes } from '../../../constants';
import {
  getAllPlanBlocksSelector,
  getAvailablePlanBlocksSelector,
  getBusinessUnit,
  getIsLoadingAvailablePlanBlocks,
  getLookupVintage,
  getPlanStatus,
  getPlanType,
  getUserPreferences,
} from '../../../redux/selectors';
import { useSelector } from 'react-redux';
import {
  planState,
  addGenericReducer,
  types,
} from '../../VineyardIdentity/stateReducers';
import { PlanSchema } from '../validations';
import { formatDate } from '../../../constants/formatter';
import { syncValidator } from '../../../utils/validator';
import { dateFormat, IdentityTypeIds } from '../../../constants';
import { PlanBlocksTransfer } from '../../../components/Plan';

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: 300,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 300,
  '& .MuiFormControl-root': {
    minWidth: 300,
  },
  '& .MuiInputBase-input': {
    minWidth: 250,
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  root: {
    marginTop: theme.spacing(1),
    minWidth: 300,
  },
}));

const CreatePlan = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const [formdata, dispatch] = useReducer(addGenericReducer, planState);
  const today = format(new Date(), dateFormat);
  const lkpPlanStatus = useSelector(state => getPlanStatus(state));
  const lkpPlanType = useSelector(state => getPlanType(state));
  const lkpBusinessUnit = useSelector(state => getBusinessUnit(state));
  const lkpVintage = useSelector(getLookupVintage);
  const [warningInSubmit, setWarningInSubmit] = useState(false);
  const [selectedBlocks, setSelectedBlocks] = useState([]);

  const { basicSettings = {} } = useSelector(state =>
    getUserPreferences(state),
  );

  //
  const { blockVintagesLoading, blockVintagesVineyards } = usePlansHook();
  const initialRightBlocks = useSelector(getAllPlanBlocksSelector);
  const initialLeftBlocks = useSelector(getAvailablePlanBlocksSelector);
  const [newRightBlocks, setNewRightBlocks] = useState(initialRightBlocks);
  const [newLeftBlocks, setNewLeftBlocks] = useState(initialLeftBlocks);
  const availablePlanBlocksLoading = useSelector(
    getIsLoadingAvailablePlanBlocks,
  );
  const [vineyardGroups, setVineyardGroups] = useState([]);
  //

  const latestVintageID = max(map(lkpVintage, 'id'));

  const selectedVintage = isNil(formdata.planVintageID)
    ? find(lkpVintage, v => v?.id === latestVintageID)
    : find(lkpVintage, v => v?.id === formdata.planVintageID);

  // Call plan blocks API
  const setBlocksData = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.getAvailablePlanBlocks.get.request({
        queryParams: {
          planID: 0,
        },
      }),
    );
  };

  const handleOnCancel = () => {
    navigate(-1);
  };

  const handleOnSave = () => {
    const findDate = lkpVintage.find(obj => obj.id === formdata.planVintageID);
    // we are creating 2 new object elements without changing formdata and then assigning the start and end date of findDate to new object elements
    const validationErrors = syncValidator(PlanSchema)({
      ...formdata,
      yearStart: new Date(findDate.fromDate), // Pass in the updated value of yearStart, or an empty string if it's undefined
      yearEnd: new Date(findDate.toDate), // Pass in the updated value of yearEnd, or an empty string if it's undefined
    });
    console.log('Validation Errors', validationErrors);

    //
    // const blocksToAdd = initialLeftBlocks
    // .filter(l => newRightBlocks.some(r => r === l.vineyardBlockID))
    // .map(l => {
    //   return {
    //     VineyardBlockID: l.vineyardBlockID,
    //     PlanID: Number(pathParamId),
    //     PlanBlockNote: 'Added from UI',
    //     ActiveFromDate: format(new Date(), dateFormat),
    //     ActiveToDate: null,
    //   };
    // });

    // if (!isEmpty(blocksToAdd)) {
    // }

    //

    if (!isEmpty(validationErrors)) {
      dispatch({ type: types.ERROR, payload: validationErrors });
    } else if (isEmpty(newRightBlocks)) {
      dispatch({ type: types.ERROR, payload: validationErrors });
      setWarningInSubmit(true);
    } else {
      const data = {
        planName: formdata.planName,
        planDescription: formdata.planDescription || '',
        planTypeID: formdata.planTypeID,
        planStatusID: formdata.planStatusID,
        isLocked: formdata.isLocked,
        planStartDate: formdata.planStartDate,
        planEndDate: formdata.planEndDate,
        planVintageID: formdata.planVintageID,
        durationID: 1,
        businessUnitID: formdata.businessUnitID,
        vineyardBlockIDs: newRightBlocks,
        planBlockActiveFromDate: new Date(today),
      };
      // create plan
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.plans,
          methodType: apiTypes.POST,
        },
      });

      handleOnCancel();
    }
  };

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleOnChangeBlocks = evt => {
    const {
      target: { value, name },
    } = evt;
    setNewRightBlocks(value);
  };

  // If user changes business unit refilter blocks
  useEffect(() => {
    if (!blockVintagesLoading) {
      const filteredBlockVintageVineyards = filter(blockVintagesVineyards, {
        businessUnitID: formdata.businessUnitID,
      });
      const filteredVineyardGroups = map(
        filteredBlockVintageVineyards,
        'vineyardName',
      );
      setVineyardGroups(filteredVineyardGroups);
      setNewRightBlocks([]);
    }
  }, [formdata.businessUnitID, blockVintagesLoading]);

  // Default to latest vintage and user's business unit
  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.searchIdentity.post.request({
        postBody: {
          IdentityTypeID: IdentityTypeIds.VINEYARD,
          FindNonActive: true,
        },
      }),
    );

    formdata.planVintageID = latestVintageID;
    formdata.businessUnitID = basicSettings?.businessUnitID;
    setBlocksData();
  }, []);

  // Plan start and end dates default to start and end dates of vintage
  useEffect(() => {
    handleChange({
      target: { name: 'planStartDate', value: selectedVintage?.fromDate },
    });
    handleChange({
      target: { name: 'planEndDate', value: selectedVintage?.toDate },
    });
  }, [formdata.planVintageID]);

  // Set available plan blocks
  useEffect(() => {
    setNewLeftBlocks(initialLeftBlocks);
  }, [initialLeftBlocks]);

  // Set Available Plan Blocks and Selected Plan Blocks
  useEffect(() => {
    let vineyardNames = [];
    const names = newRightBlocks
      .concat(newLeftBlocks)
      .concat(initialRightBlocks);
    map(names, block => {
      const alreadyExists = vineyardNames.some(e => e === block.vineyardName);
      if (!alreadyExists) {
        vineyardNames.push(block.vineyardName);
      }
    });
    setVineyardGroups(vineyardNames);
  }, [newLeftBlocks, newRightBlocks, initialRightBlocks]);

  return (
    <Paper
      elevation={0}
      data-testid="new-plan"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid spacing={2} data-testid="plan-grid-details">
        <Grid item xs={12} data-testid="vineaalert">
          <VineaAlert isOpen={!!false} onExit={() => setErrorInSubmit(false)} />
        </Grid>
        <Grid item xs={12} md={12} lg={8} data-testid="grid-top-wrapper">
          {warningInSubmit && (
            <Collapse in={warningInSubmit}>
              <Alert
                variant="standard"
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setWarningInSubmit(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Warning: Choose at least one block to create a New Plan
              </Alert>
            </Collapse>
          )}
          <Stack direction="row" justifyContent="space-around" width="100%">
            <Box px={2}>
              <StyledFormBoxControl mt={1}>
                <FormControl>
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="planName"
                    name="planName"
                    label={t('Plan Name')}
                    size="small"
                    inlineLabel
                    value={formdata.planName}
                    onChange={handleChange}
                    error={formdata.errors.planName}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={1}>
                <FormControl>
                  <StyledFieldLabelInput
                    id="planDescription"
                    name="planDescription"
                    label={t('Plan Description')}
                    inlineLabel
                    minWidth="250px"
                    value={formdata.planDescription}
                    onChange={handleChange}
                    error={formdata.errors.planDescription}
                    multiline
                    rowsMax={4}
                    rows={13}
                  />
                </FormControl>
              </StyledFormBoxControl>
            </Box>
            <Box px={2}>
              <StyledBox mt={3}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.planTypeID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.planTypeID}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={lkpPlanType}
                    labelVariant="body1"
                    label={t('Plan Type')}
                    name={t('Plan Type')}
                    id="planType"
                    displayLabel
                    inputProps={{
                      name: 'planTypeID',
                    }}
                  />
                  {formdata.errors.planTypeID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.planTypeID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledBox mt={3}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.planStatusID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.planStatusID}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={lkpPlanStatus}
                    labelVariant="body1"
                    label={t('Status')}
                    name={t('Plan Status')}
                    id="planStatusID"
                    displayLabel
                    inputProps={{
                      name: 'planStatusID',
                    }}
                  />
                  {formdata.errors.planStatusID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.planStatusID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledBox mt={3}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.planVintageID}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.planVintageID || 0}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={lkpVintage}
                    labelVariant="body1"
                    label={t('Vintage')}
                    name={t('Plan Vintage')}
                    id="vintageID"
                    displayLabel
                    inputProps={{
                      name: 'planVintageID',
                    }}
                  />
                  {formdata.errors.planVintageID && (
                    <FormHelperText id="component-error-text">
                      {t(formdata.errors.planVintageID)}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledBox mt={3}>
                <StyledFormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.businessUnitID}
                  data-testid="datePlanted-formcontrol"
                >
                  <VineaAutoComplete
                    value={formdata.businessUnitID}
                    onChange={handleChange}
                    inlineLabel={false}
                    options={lkpBusinessUnit}
                    labelVariant="body1"
                    label={t('Business Unit')}
                    name={t('Business Unit')}
                    id="businessUnitID"
                    displayLabel
                    inputProps={{
                      name: 'businessUnitID',
                    }}
                  />
                  {formdata.errors.businessUnitID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.businessUnitID}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </StyledBox>
              <StyledBox mt={4}>
                <StyledFormControl
                  error={!!formdata.errors.planStartDate}
                  data-testid="planStartDate-formcontrol"
                >
                  <Datepicker
                    minDate={selectedVintage?.fromDate}
                    id="planStartDate"
                    label={t('Plan Start Date')}
                    placeholder="DD/MM/YYYY"
                    inputFormat={viewDateFormat}
                    formatDate={formatDate}
                    variant="outlined"
                    size="small"
                    name="planStartDate"
                    value={formdata.planStartDate}
                    onChange={handleChange}
                    inputProps={{
                      'data-testid': 'planStartDate',
                      'data-name': 'planStartDate',
                    }}
                  />
                  {formdata.errors.planStartDate && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.planStartDate}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </StyledBox>
              <StyledBox mt={4}>
                <StyledFormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  error={!!formdata.errors.planEndDate}
                  data-testid="planEndDate-formcontrol"
                >
                  <Datepicker
                    maxDate={selectedVintage?.toDate}
                    id="planEndDate"
                    label={t('Plan End Date')}
                    placeholder="DD/MM/YYYY"
                    inputFormat={viewDateFormat}
                    formatDate={formatDate}
                    variant="outlined"
                    size="small"
                    name="planEndDate"
                    value={formdata.planEndDate}
                    onChange={handleChange}
                    inputProps={{
                      'data-testid': 'planEndDate',
                      'data-name': 'planEndDate',
                    }}
                  />
                  {formdata.errors.planEndDate && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.planEndDate}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </StyledBox>
            </Box>
            <Grid item xs={12}>
              <StyledBox mt={1}>
                <Box ml={-1} data-testid="simple-transfer-list">
                  <PlanBlocksTransfer
                    availableBlocks={newLeftBlocks}
                    currentBlocks={initialRightBlocks}
                    isLoading={availablePlanBlocksLoading}
                    groups={vineyardGroups}
                    width={300}
                    onChange={handleOnChangeBlocks}
                  />
                </Box>
              </StyledBox>
            </Grid>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Box mx={2} mt={1}>
            <VineaButton
              color="success"
              onClick={handleOnSave}
              variant="contained"
              sx={{
                marginRight: theme.spacing(1),
                minWidth: 150,
              }}
              minWidth={100}
              data-testid="btnsave"
            >
              {t('Save')}
            </VineaButton>
            <VineaButton
              variant="outlined"
              color="secondary"
              onClick={handleOnCancel}
              data-testid="btncancel"
            >
              {t('Cancel')}
            </VineaButton>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CreatePlan;
