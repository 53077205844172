import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

export const Checkboxes = () => {
  const [checked, setChecked] = React.useState(true);
  const theme = useTheme();
  const handleChange = event => {
    setChecked(event.target.checked);
  };
  return (
    <Grid
      item
      xs={12}
      sm={8}
      sx={{
        flexGrow: 1,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
      }}
    >
      <Typography component="p" variant="h3">
        Checkboxes
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            '& > button': {
              margin: theme.spacing(1),
            },
          }}
        >
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Checkbox
            defaultChecked
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
          <Checkbox
            disabled
            inputProps={{ 'aria-label': 'disabled checkbox' }}
          />
          <Checkbox
            disabled
            checked
            inputProps={{ 'aria-label': 'disabled checked checkbox' }}
          />
          <Checkbox
            defaultChecked
            indeterminate
            inputProps={{ 'aria-label': 'indeterminate checkbox' }}
          />
          <Checkbox
            defaultChecked
            color="default"
            inputProps={{ 'aria-label': 'checkbox with default color' }}
          />
          <Checkbox
            defaultChecked
            size="small"
            inputProps={{ 'aria-label': 'checkbox with small size' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
