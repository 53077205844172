import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Stack, Typography } from '@mui/material';

export function ColourLegend({ colours = [] }) {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        position: 'absolute',
        margin: 4,
        left: 0,
        top: 0,
        zIndex: 1,
      }}
      elevation={1}
    >
      <Stack p={2} sx={{ rowGap: 2 }}>
        <Typography variant="subtitle2">{t('Legend')}</Typography>
        {colours.map(({ colour, label }) => (
          <Stack direction="row" key={colour} sx={{ alignItems: 'center' }}>
            <Box
              mr={1}
              sx={{
                backgroundColor: colour,
                height: '24px',
                width: '24px',
                border: 'solid 1px black',
                borderRadius: '12px',
              }}
            />
            {t(label)}
          </Stack>
        ))}
      </Stack>
    </Paper>
  );
}
