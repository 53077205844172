import { Component } from 'react';
import { withIdleTimer } from 'react-idle-timer';

class IdleTimerComponent extends Component {
  render() {
    const { children } = this.props;
    return children;
  }
}

export const IdleTimer = withIdleTimer(IdleTimerComponent);
