import React, { useReducer, useState, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import { isEmpty, isNil, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTheme, styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/lab/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { VineaNovaActions } from 'vineanova-redux-artifacts';

import { VineaAlert } from '../../components/VineaAlert';
import { Datepicker } from '../../components/Datepicker';
import { FieldLabelInput } from '../../components/TextField';
import {
  getSupplyContractType,
  getIdentityOverviewDetailsFromContact,
} from '../../redux/selectors';
import {
  addGenericReducer,
  supplyContractState,
  types,
} from '../VineyardIdentity/stateReducers';
import { syncValidator } from '../../utils/validator';
import { SupplyContractSchema } from '../VineyardIdentity/validations';
import {
  apiTypes,
  reducers,
  sagaActionTypes,
  vineaDetails,
  viewDateFormat,
  IdentityRoleTypes,
} from '../../constants';
import { useIdentityTypeId } from '../../hooks/useIdentityTypeId';
import { SplitButton } from '../../components/SplitButton';
import { VineaAutoComplete } from '../../components/ComboBox';
import { VineaButton } from '../../components/VineaButton';
import { DiscardDialog } from '../../components/Dialog';
import { statechecker } from '../../utils/statecomparator';
import {
  IdentityActivationSwitch,
  DeleteIdentityAlert,
  DeleteIdentityButton,
} from '../../components/IdentityComponents';
import { useIdentityStatus } from '../../hooks/useIdentityStatus';
import { formatDate } from '../../constants/formatter';
import { useSupplyContractsHook } from './useSupplyContractsHook';

const StyledFormBoxControl = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 300,
  '& .MuiFormControl-root': {
    minWidth: 300,
  },
  '& .MuiInputBase-input': {
    minWidth: 300,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 325,
  '& .MuiFormControl-root': {
    minWidth: 325,
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  root: {
    marginTop: theme.spacing(1),
    minWidth: 400,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: 400,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const ManageSupplyContract = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const identityTypeID = useIdentityTypeId();
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [formWriteReloadState, setFormWriteReloadState] = useState(false);
  const [formLoadingState, setFormLoadingState] = useState(false);
  const [saveIndex, setSaveIndex] = useState(0);
  const [deleteHasFailed, setDeleteHasFailed] = useState(false);
  const [validationsErrors, setValidationErrors] = useState({});
  const [initialAPITrigger, setInitialAPITrigger] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [updateApiStatus, setUpdateApiStatus] = useState(false);

  const [formdata, dispatch] = useReducer(
    addGenericReducer,
    supplyContractState,
  );

  const {
    isLoading,
    isLoaded,
    hasError,
    data: supplyContractData,
    error,
  } = useSelector(state => state.formWrite);

  const lkpSupplyContractType = useSelector(state =>
    getSupplyContractType(state),
  );

  const identityOverviewDetails = useSelector(state =>
    getIdentityOverviewDetailsFromContact(state),
  );
  const {
    supplyContractEmail,
    supplyContractDateSigned,
    supplyContractPhone,
    supplyContractAddress,
    supplyContractCurrentContractedArea,
    supplyContractSubRegions,
  } = identityOverviewDetails;

  const { supplyingParties, purchasingParties, primaryContacts } =
    useSupplyContractsHook();

  const isActive = useIdentityStatus();
  const { id: pathParam } = useParams();
  const hasChanges = useMemo(() => {
    return !(
      supplyContractData.contractName === formdata.contractName &&
      supplyContractData.purchasingPartyIdentityID ===
        formdata.purchasingPartyIdentityID &&
      supplyContractData.supplyingPartyIdentityID ===
        formdata.supplyingPartyIdentityID &&
      supplyContractData.supplyContractTypeID ===
        formdata.supplyContractTypeID &&
      supplyContractData.primaryContactIdentityID ===
        formdata.primaryContactIdentityID &&
      supplyContractData.dateSigned === formdata.dateSigned &&
      supplyContractData.dateEnded === formdata.dateEnded
    );
  }, [supplyContractData, formdata]);

  const handleComponentChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({
      type: types.UPDATE,
      payload: { [name]: value === 0 ? null : value },
    });
  };

  const handleOnBlur = () => {
    const validationErrors = syncValidator(SupplyContractSchema)(formdata);

    dispatch({
      type: types.ERROR,
      payload:
        !isEmpty(formdata.errors) && !isEmpty(validationErrors)
          ? validationErrors
          : {},
    });
  };

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleSave = (event, index) => {
    setSaveIndex(index);
    // we are creating 2 new object elements without changing formdata and then assigning the start and end date of findDate to new object elements
    const validationErrors = syncValidator(SupplyContractSchema)({
      ...formdata,
    });

    if (!isEmpty(validationErrors)) {
      setValidationErrors(validationErrors);
    } else if (!hasChanges) {
      setWarningInSubmit(true);
    } else {
      setWarningInSubmit(false);
      setErrorInSubmit(false);
      const data = {
        id: supplyContractData.id,
        contractName: formdata.contractName,
        purchasingPartyIdentityID: formdata.purchasingPartyIdentityID,
        supplyingPartyIdentityID: formdata.supplyingPartyIdentityID,
        supplyContractTypeID: formdata.supplyContractTypeID,
        primaryContactIdentityID: formdata.primaryContactIdentityID,
        dateSigned: new Date(formdata.dateSigned),
        dateEnded: !isNil(formdata.dateEnded)
          ? new Date(formdata.dateEnded)
          : null,
        planVintageID: formdata.planVintageID,
        businessUnitID: supplyContractData.businessUnitID,
        ts: supplyContractData.ts,
        identityTypeID: identityTypeID,
      };
      dispatchAPI({
        // need to change payload
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.supplyContract,
          methodType: apiTypes.PUT,
        },
      });
      setUpdateApiStatus(true);
      setFormWriteReloadState(true);
    }
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleClose = () => {
    if (!saveButtonStatus && hasChanges) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleOnConfirmDelete = () => {
    if (pathParam) {
      setIsDeleting(true);
      dispatchAPI(
        VineaNovaActions.api.v1.identitySupplyContract.delete.request({
          postBody: {
            id: pathParam,
            ts: supplyContractData.ts,
          },
        }),
      );
    }
  };

  const handleOnToggleActive = () => {
    navigate(`/supplycontract/${pathParam}/managestatus`);
  };

  useEffect(() => {
    if (isDeleting) {
      navigate('/supplycontract');
      setIsDeleting(false);
    }
  }, [isDeleting]);

  useEffect(() => {
    if (!isLoading && isLoaded && !isEmpty(supplyContractData) && !hasError) {
      const stubSupplyContractData = {
        ...supplyContractData,
      };
      const stubFormData = omit(formdata, 'errors');
      if (statechecker(stubFormData, stubSupplyContractData)) {
        setSaveButtonStatus(true);
      } else setSaveButtonStatus(false);
    }
  }, [isLoading, isLoaded, supplyContractData, hasError, formdata]);

  useEffect(() => {
    if (isLoaded && !hasError && initialAPITrigger) {
      setInitialAPITrigger(false);
      setFormLoadingState(false);
      dispatch({
        type: types.UPDATE,
        payload: {
          contractName: supplyContractData.contractName,
          purchasingPartyIdentityID:
            supplyContractData.purchasingPartyIdentityID,
          supplyingPartyIdentityID: supplyContractData.supplyingPartyIdentityID,
          supplyContractTypeID: supplyContractData.supplyContractTypeID,
          primaryContactIdentityID: supplyContractData.primaryContactIdentityID,
          dateSigned: supplyContractData.dateSigned,
          dateEnded: supplyContractData.dateEnded,
        },
      });
    }
  }, [
    isLoaded,
    hasError,
    initialAPITrigger,
    supplyContractData.contractName,
    supplyContractData.purchasingPartyIdentityID,
    supplyContractData.supplyingPartyIdentityID,
    supplyContractData.supplyContractTypeID,
    supplyContractData.primaryContactIdentityID,
    supplyContractData.dateSigned,
    supplyContractData.dateEnded,
    isLoading,
    formLoadingState,
  ]);

  useEffect(() => {
    if (isLoading) {
      setFormLoadingState(true);
    }
    if (!hasError && isLoaded && updateApiStatus && formLoadingState) {
      enqueueSnackbar('Success!', { variant: 'success' });
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
      setSaveButtonStatus(true);
      if (saveIndex === 0) navigate(-1);
      else if (formWriteReloadState && saveIndex === 1) {
        setFormWriteReloadState(false);
        dispatchAPI({
          type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
          payload: { id: pathParam, name: reducers.supplyContract },
        });
      }
      setFormLoadingState(false);
    } else if (hasError && isLoaded && updateApiStatus) {
      setErrorInSubmit(true);
      enqueueSnackbar(t('Error'), { variant: 'Error' });
    }
  }, [
    isLoaded,
    updateApiStatus,
    enqueueSnackbar,
    t,
    navigate,
    hasError,
    supplyContractData.id,
    saveIndex,
    warningInSubmit,
    isLoading,
    formLoadingState,
    dispatchAPI,
    formWriteReloadState,
    pathParam,
  ]);

  //Load api data initially
  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.identitiesByRole.get.request({
        queryParams: {
          RoleTypeIDs: `${IdentityRoleTypes.GROWER},${IdentityRoleTypes.WINE_COMPANY}`,
        },
      }),
    );
    dispatchAPI(
      VineaNovaActions.api.v1.identitySupplyContract.get.request({
        queryParams: { IdentityID: pathParam },
      }),
    );
  }, []);

  return (
    <Paper
      elevation={0}
      data-testid="managesupplycontract-detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid
        container
        spacing={2}
        data-testid="grid-details-managesupplycontract"
      >
        <Grid item xs={12}>
          <VineaAlert
            isOpen={!!errorInSubmit}
            onExit={() => setErrorInSubmit(false)}
          />
          <DeleteIdentityAlert
            isOpen={deleteHasFailed}
            onExit={() => setDeleteHasFailed(false)}
            identityName="supplycontract"
          />
          <Box px={2}>
            {warningInSubmit && (
              <Collapse in={warningInSubmit}>
                <Alert
                  variant="standard"
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarningInSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Warning: Nothing to Update
                </Alert>
              </Collapse>
            )}
          </Box>
        </Grid>
        <Grid container spacing={2} data-testid="grid-details">
          <Stack direction="row" justifyContent="space-around">
            <Box px={2}>
              <StyledFormBoxControl mt={1}>
                <FormControl>
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="contractName"
                    name="contractName"
                    label={t('Contract Name')}
                    size="small"
                    inlineLabel
                    value={formdata.contractName}
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    error={formdata.errors.contractName}
                    helperText={formdata.errors.contractName}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledBox mt={3}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.purchasingPartyIdentityID || 0}
                    disabled
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    options={purchasingParties}
                    labelVariant="body1"
                    inlineLabel={false}
                    id="purchasingPartyIdentityID"
                    name={t('purchasingPartyIdentityID')}
                    label={t('Purchasing Party')}
                    inputProps={{
                      name: 'purchasingPartyIdentityID',
                    }}
                  />
                </FormControl>
              </StyledBox>
              <StyledBox mt={3}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.supplyingPartyIdentityID || 0}
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    options={supplyingParties}
                    labelVariant="body1"
                    inlineLabel={false}
                    id="supplyingPartyIdentityID"
                    name={t('supplyingPartyIdentityID')}
                    label={t('Supplying Party')}
                    inputProps={{
                      name: 'supplyingPartyIdentityID',
                    }}
                  />
                </FormControl>
              </StyledBox>
              <StyledBox mt={3}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                >
                  <VineaAutoComplete
                    value={formdata.supplyContractTypeID || 0}
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    options={lkpSupplyContractType}
                    labelVariant="body1"
                    inlineLabel={false}
                    id="supplyContractTypeID"
                    name={t('supplyContractTypeID')}
                    label={t('Supply Contract Type')}
                    inputProps={{
                      name: 'supplyContractTypeID',
                    }}
                  />
                </FormControl>
              </StyledBox>
              <StyledBox mt={3}>
                <FormControl
                  sx={{
                    marginTop: theme.spacing(1),
                    minWidth: 250,
                  }}
                  margin="none"
                  error={!!formdata.errors.primaryContactIdentityID}
                >
                  <VineaAutoComplete
                    value={formdata.primaryContactIdentityID || 0}
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    options={primaryContacts}
                    labelVariant="body1"
                    inlineLabel={false}
                    id="primaryContactIdentityID"
                    name={t('primaryContactIdentityID')}
                    label={t('Primary Contact')}
                    inputProps={{
                      name: 'primaryContactIdentityID',
                    }}
                  />
                  {formdata.errors.primaryContactIdentityID && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.primaryContactIdentityID}
                    </FormHelperText>
                  )}
                </FormControl>
              </StyledBox>
              <StyledFormBoxControl mt={3}>
                <FormControl>
                  <FieldLabelInput
                    autoComplete="off"
                    disabled
                    id="supplyContractEmail"
                    name="supplyContractEmail"
                    label={t('Email')}
                    size="small"
                    inlineLabel
                    value={supplyContractEmail}
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={3}>
                <FormControl>
                  <FieldLabelInput
                    autoComplete="off"
                    disabled
                    id="supplyContractPhone"
                    name="supplyContractPhone"
                    label={t('Phone')}
                    size="small"
                    inlineLabel
                    value={supplyContractPhone}
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledBox mt={4}>
                <StyledFormControl data-testid="planStartDate-formcontrol">
                  <Datepicker
                    id="supplyContractDateSigned"
                    margin="dense"
                    fullWidth
                    label={t('Date Signed')}
                    formatDate={formatDate}
                    inputFormat={viewDateFormat}
                    value={formdata.dateSigned}
                    onChange={handleChange}
                    name="dateSigned"
                    inputProps={{
                      'data-testid': 'dateSigned',
                      'data-name': 'dateSigned',
                    }}
                  />
                </StyledFormControl>
              </StyledBox>
            </Box>
            <Box px={2}>
              {' '}
              <StyledBox mt={3}>
                <StyledFormControl data-testid="supplycontract-dateended-formcontrol">
                  <Datepicker
                    minDate={supplyContractDateSigned}
                    id="dateEnded"
                    label={t('Date Ended')}
                    placeholder="DD/MM/YYYY"
                    inputFormat={viewDateFormat}
                    formatDate={formatDate}
                    variant="outlined"
                    size="small"
                    name="dateEnded"
                    value={formdata.dateEnded}
                    onChange={handleChange}
                    inputProps={{
                      'data-testid': 'dateEnded',
                      'data-name': 'dateEnded',
                    }}
                  />
                  {validationsErrors.dateEnded && (
                    <FormHelperText id="component-error-text">
                      {validationsErrors.dateEnded}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </StyledBox>
              <StyledFormBoxControl mt={3}>
                <FormControl>
                  <FieldLabelInput
                    autoComplete="off"
                    disabled
                    id="supplyContractCurrentContractedArea"
                    name="supplyContractCurrentContractedArea"
                    label={t('Total Area')}
                    size="small"
                    inlineLabel
                    value={supplyContractCurrentContractedArea}
                    onBlur={handleOnBlur}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={3}>
                <FormControl>
                  <FieldLabelInput
                    autoComplete="off"
                    disabled
                    id="supplyContractAddress"
                    name="supplyContractAddress"
                    label={t('Address')}
                    size="small"
                    inlineLabel
                    value={supplyContractAddress}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                  />
                </FormControl>
              </StyledFormBoxControl>
              <StyledFormBoxControl mt={3}>
                <FormControl>
                  <FieldLabelInput
                    autoComplete="off"
                    disabled
                    id="supplyContractSubRegions"
                    name="supplyContractSubRegions"
                    label={t('Sub Region(s)')}
                    size="small"
                    inlineLabel
                    value={supplyContractSubRegions}
                    onChange={handleComponentChange}
                    onBlur={handleOnBlur}
                  />
                </FormControl>
              </StyledFormBoxControl>
            </Box>
          </Stack>
        </Grid>

        <Box ml={1} mb={2} style={{ flexDirection: 'column' }}>
          <Box
            sx={{
              width: '350px',
              minWidth: '250px',
            }}
            my={2}
            mr={4}
          >
            <IdentityActivationSwitch
              handleOnToggleActive={handleOnToggleActive}
              isActive={isActive}
              identityName="supplyContract"
            />
          </Box>

          <Box my={2}>
            <DeleteIdentityButton
              handleOnDelete={handleOnConfirmDelete}
              identityName="supplyContract"
              isDisabled={deleteHasFailed}
            />
          </Box>
        </Box>

        <DiscardDialog
          open={discardDialogOpen}
          onClose={handleOnDialogClose}
          handleSaveChanges={handleSave}
          handleDiscardChanges={handleCancel}
        />

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
          }}
        >
          <Box mx={2}>
            <SplitButton
              color="success"
              onClick={handleSave}
              variant="contained"
              minWidth={130}
              disabled={saveButtonStatus}
            />
          </Box>
          <Box mx={2}>
            <VineaButton
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              {t('Close')}
            </VineaButton>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ManageSupplyContract;
