import React, { useEffect, useState } from 'react';
import { isEqual, get, map, find } from 'lodash';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { ActivityMappingGrid } from '../../../components/Grid';
import { getUserPreferences } from '../../../redux/selectors';
import { ActivityMappingDrawer } from './ActivityMappingDrawer';

// eslint-disable-next-line react/prop-types
const ActivityMappingComponent = ({ pathParamId }) => {
  const { t } = useTranslation();
  //   const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const selectOption = { id: 0, key: 0, value: 'None' };
  const [selectedRow, setSelectedRow] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiTrigger, setApiTrigger] = useState(true);
  const [selectedIdentityActivityID, setSelectedIdentityActivityID] =
    useState(0);
  const [activityOptions, setActivityOptions] = useState([selectOption]);
  const dataGridColumnsPreferences = useSelector(state =>
    getUserPreferences(state),
  );
  const activeActivities = useSelector(
    VineaNovaSelectors.getSearchIdentityEntity,
  );
  const activityMappingData = useSelector(
    VineaNovaSelectors.getGetServiceProviderActivityMappingsEntityData,
  );
  const { isLoading: isMappingLoading, isLoaded: isMappingLoaded } =
    useSelector(
      VineaNovaSelectors.getGetServiceProviderActivityMappingsEntityMeta,
    );
  const { isLoading: isUpdateLoading, isLoaded: isUpdateLoaded } = useSelector(
    VineaNovaSelectors.getUpdateServiceProviderActivityMappingsEntityMeta,
  );

  const handleOnManage = e => {
    setSelectedRow(e.row);
    const identityActivity = find(activityOptions, {
      value: e.row.matchedActivityCode,
    });
    setSelectedIdentityActivityID(get(identityActivity, 'id', 0));
    setOpen(true);
  };

  const handleOnSave = () => {
    if (hasChanges) {
      dispatchAPI(
        VineaNovaActions.api.v1.updateServiceProviderActivityMappings.put.request(
          {
            postBody: {
              ActivityID: selectedRow.id,
              IdentityActivityID: selectedIdentityActivityID,
            },
          },
        ),
      );
      setOpen(false);
      setApiTrigger(true);
      setHasChanges(false);
    }
  };

  const handleOnCloseDrawer = () => {
    setOpen(false);
    setApiTrigger(true);
  };

  const handleOnChange = event => {
    const {
      target: { name, value },
    } = event;
    if (name === 'identityActivityID') {
      setSelectedIdentityActivityID(value);
    } else {
      setSelectedRow({ ...selectedRow, [name]: value });
    }

    setHasChanges(true);
  };

  // Format identity activity data for dropdown
  useEffect(() => {
    const data = map(get(activeActivities, 'data', []), act => ({
      id: act.id,
      key: act.id,
      value: act.activityCode,
    }));

    data.unshift(selectOption);
    setActivityOptions(data);
  }, [activeActivities]);

  // Get all activity mappings
  useEffect(() => {
    if (apiTrigger) {
      dispatchAPI(
        VineaNovaActions.api.v1.getServiceProviderActivityMappings.get.request({
          queryParams: { ServiceProviderID: pathParamId },
        }),
      );
    }
    setApiTrigger(false);
  }, [apiTrigger, dispatchAPI, pathParamId, isMappingLoading, isMappingLoaded]);

  // Update data grid once update is complete
  useEffect(() => {
    if (!isUpdateLoading && isUpdateLoaded) setApiTrigger(true);
  }, [isUpdateLoading, isUpdateLoaded]);

  return (
    <>
      <Box
        data-testid="grid-blocks-activity-mapping"
        sx={{ height: '100%', padding: 3 }}
      >
        <ActivityMappingGrid
          heading={t('Default Rate')}
          hasAddOption
          tableData={activityMappingData}
          pathParamId={pathParamId}
          onRowClick={handleOnManage}
          gridColumnState={dataGridColumnsPreferences.activityMappingDataGrid}
        />
      </Box>
      <ActivityMappingDrawer
        isOpen={open}
        handleOnSave={handleOnSave}
        handleOnClose={handleOnCloseDrawer}
        handleOnChange={handleOnChange}
        data={selectedRow}
        selectedIdentityActivityID={selectedIdentityActivityID}
        activityOptions={activityOptions}
        hasChanges={hasChanges}
      />
    </>
  );
};

function pathNameAreEqual(prevProps, nextProps) {
  return isEqual(prevProps.pathParamId, nextProps.pathParamId);
}

export const ActivityMapping = React.memo(
  ActivityMappingComponent,
  pathNameAreEqual,
);
