import { RenderDataGridHeader } from '../../components/Grid';

export const reportingGridColumns = () => [
  {
    field: 'reportName',
    headerName: 'Name',
    flex: 1,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
];
