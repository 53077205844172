import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import round from 'lodash/round';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import SyncIcon from '@mui/icons-material/Sync';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { ErrorBoundary } from 'react-error-boundary';
import Paper from '@mui/material/Paper';
import { syncValidator } from '../../../utils/validator';
import {
  EditActivitySchema,
  AddActivitySchema,
} from '../../../containers/Plans/validations';
import { format, isValid, parse, isBefore, isWithinInterval } from 'date-fns';
import { validDateFormat } from '../../../utils/DateUtil';
import { dateFormat, viewDateFormat } from '../../../constants';

import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { useTheme } from '@mui/material/styles';
import { VineaTextField } from '../../../components/TextField';
import { VineaButton } from '../../../components/VineaButton';
import { DiscardDialog } from '../../../components/Dialog';
import { Datepicker } from '../../../components/Datepicker';
import { GreenSwitch } from '../../../components/Switch';
import { formatDate } from '../../../constants/formatter';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';
import { filter, find, get, isArray, isEmpty, sortBy, sumBy } from 'lodash';
import {
  getAllPlanActivities,
  getIdentityOverviewDetailsFromContact,
  getDefaultRateSelector,
  getAllActivities,
} from '../../../redux/selectors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useDebounce from '../../../hooks/useDebounce';
import { Grid, TextField } from '@mui/material';
import ColorPickerBox from '../../../components/ColorPickerComponent/ColorPickerBox';
import logger from '../../../utils/winstonLogger';
import CheckboxSelectionGrid from '../../../components/Grid/CheckboxSelectionGrid';
import { FilterGridBlockActivities } from '../../../components/PlanComponents';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
  display: 'flex',
}));

export interface ActivityEditProps {
  handleOnSave: () => void;
}

export interface PlanBlocks {
  id: number;
  vineyardBlockID: number;
  vineyardBlockName: string;
}

export interface PlanBlocksActivityType {
  id: number;
  planActivityID: number;
  planBlockID: string;
}

export interface PlanBlocksGridType {
  id: number;
  name: string;
}

const ActivityEdit = ({ handleOnSave }: ActivityEditProps) => {
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const { id: planId, activityId: activitiId } = useParams();
  const isNewActivity = !activitiId;
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const today = format(new Date(), dateFormat);
  logger.debug(today);
  // Access the state passed from the previous component
  const { state } = location as any;

  logger.debug('debugging plan state', location, state);
  const theme = useTheme();
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);
  const [lockToggleTriggered, setLockToggleTriggered] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState({
    id: false,
    activityDescription: false,
    planActivityDescription: false,
    targetStartDate: false,
    targetEndDate: false,
    resourceUnits: false,
    unitCost: false,
    workIntensity: false,
    totalWorkUnits: false,
    velocity: false,
    estimatedCost: false,
    estimatedDuration: false,
    sortSequence: false,
    colourHexCode: false,
  });
  const [apiTrigger, setApiTrigger] = React.useState(false);
  const [editActivity, setEditActivity] = React.useState<any>({
    colourHexCode: '#000',
  });
  const [errorInSubmit, setErrorInSubmit] = React.useState(false);
  const [isActivityLoaded, setIsActivityLoaded] = React.useState(false);
  const [blocksData, setBlocksData] = React.useState<PlanBlocksGridType[]>([]);
  const [selectedBlocks, setSelectedBlocks] = React.useState<number[]>([]);
  const [selectBlockActivity, setSelectBlockActivity] = React.useState<any>();

  const tlkpActivities = useSelector(state => getAllActivities(state));
  const plannableActivities = filter(
    tlkpActivities,
    a => a.isPlannable === true,
  );

  const allPlanActivities = useSelector(getAllPlanActivities);
  const totalWorkUnitsData: any = useSelector(
    VineaNovaSelectors.getPlanActivityTotalWorkUnitsEntity,
  );

  const identityOverviewDetails: any = useSelector(state =>
    getIdentityOverviewDetailsFromContact(state),
  );
  const activityDefaultRateData = useSelector(getDefaultRateSelector);

  const planAcitiviesMeta = useSelector(
    VineaNovaSelectors.getPlanActivitiesEntityMeta,
  );
  const lkpDurationUnit = useSelector(
    VineaNovaSelectors.getlookupDurationEntityData,
  );
  const lkpWorkUnit = useSelector(
    VineaNovaSelectors.getlookupWorkUnitEntityData,
  );
  const lkpCostingUnit = useSelector(
    VineaNovaSelectors.getlookupCostingUnitEntityData,
  );
  const lkpResourceTypeUnit = useSelector(
    VineaNovaSelectors.getlookupResourceTypeUnitEntityData,
  );

  const getPlanBlocksData = useSelector(
    VineaNovaSelectors.getPlanBlocksEntityData,
  ) as unknown as PlanBlocks[];

  const getPlanActivityBlocksData = useSelector(
    VineaNovaSelectors.getPlanActivityBlocksEntityData,
  ) as unknown as PlanBlocksActivityType[];

  const mapLookupData = (arrayData: any, arrayId: any, arrayKey: any) => {
    if (!isArray(arrayData)) return null;
    if (isEmpty(arrayData)) return null;
    if (!arrayData) return null;
    const val = get(
      arrayData.find(f => f.id === arrayId),
      `${arrayKey}`,
      '',
    );
    return val;
  };

  const {
    durationUnitValue,
    costingUnitValue,
    workUnitPluralValue,
    workUnitValue,
    resourceUnitValue,
  } = React.useMemo(() => {
    const durationUnitValue = mapLookupData(
      lkpDurationUnit,
      editActivity.velocityDurationID,
      'duration',
    );

    const costingUnitValue = mapLookupData(
      lkpCostingUnit,
      editActivity.costingUnitID,
      'costingUnit',
    );

    const workUnitPluralValue = mapLookupData(
      lkpWorkUnit,
      editActivity.workUnitID,
      'workUnitPlural',
    );

    const workUnitValue = mapLookupData(
      lkpWorkUnit,
      editActivity.workUnitID,
      'workUnit',
    );

    const resourceUnitValue = mapLookupData(
      lkpResourceTypeUnit,
      editActivity.resourceUnitID,
      'resourceUnit',
    );

    return {
      durationUnitValue,
      costingUnitValue,
      workUnitPluralValue,
      workUnitValue,
      resourceUnitValue,
    };
  }, [editActivity]);

  useEffect(() => {
    const planActivityClicked = find(
      allPlanActivities,
      (f: any) => f.id === Number(activitiId),
    );
    if (!isEmpty(planActivityClicked)) {
      setEditActivity(planActivityClicked);
    }
    setIsActivityLoaded(true);
    // trigger the api call to get the plan blocks by id
    dispatchAPI(
      VineaNovaActions.api.v1.planBlocks.get.request({
        queryParams: {
          planID: Number(planId),
        },
      }),
    );
    dispatchAPI(VineaNovaActions.api.v1.planActivityBlocks.get.cleardata({}));
    if (Number(activitiId)) {
      dispatchAPI(
        VineaNovaActions.api.v1.planActivityBlocks.get.request({
          queryParams: {
            PlanActivityID: Number(activitiId),
          },
        }),
      );
    }
  }, [activitiId]);

  // update blocks
  useEffect(() => {
    if (!isEmpty(getPlanBlocksData)) {
      // sort Plan blocks first
      const sortedPlanBlocks = sortBy(getPlanBlocksData, 'vineyardBlockName');
      const mappedBlocks = sortedPlanBlocks.map((planBlock: any) => ({
        id: planBlock.id,
        name: planBlock.vineyardBlockName,
      }));
      setBlocksData(mappedBlocks);
    }
  }, [getPlanBlocksData]);

  // update selected blocks
  useEffect(() => {
    if (!isNewActivity && !isEmpty(getPlanActivityBlocksData)) {
      const mappedBlocks = filter(
        getPlanActivityBlocksData,
        planBlock => planBlock.id > 0,
      ).map((block: any) => block.planBlockID);
      setSelectedBlocks(mappedBlocks);
    }
  }, [isNewActivity, getPlanActivityBlocksData]);

  // Update totalworkunits when block selection changes
  useEffect(() => {
    if (isActivityLoaded) {
      const newWorkUnits = sumBy(
        filter(getPlanActivityBlocksData, planBlock =>
          selectedBlocks.includes(parseInt(planBlock.planBlockID)),
        ),
        'workUnits',
      );

      setEditActivity({
        ...editActivity,
        totalWorkUnits: newWorkUnits || 0,
      });
    }
  }, [selectedBlocks, isActivityLoaded]);

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    navigate(-1);
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    handleOnSave();
  };

  const handleOnClickLocked = (event: any) => {
    const currentLock = event.target.checked;
    if (currentLock) {
      setEditActivity({ ...editActivity, locked: currentLock });
    } else {
      setLockToggleTriggered(true);
    }
  };

  const handleOnChange = (event: any) => {
    const {
      target: { name, value },
    } = event;
    const newValue = isNaN(Number(value)) ? value : Number(value);
    setEditActivity({ ...editActivity, [name]: newValue });
  };

  const handleOnEditColourChange = (newColourHex: string) => {
    setEditActivity({ ...editActivity, colourHexCode: newColourHex });
  };

  const handleOnConfirmUnlock = () => {
    setEditActivity({ ...editActivity, locked: !editActivity?.locked });
    setLockToggleTriggered(false);
  };

  const handleOnRefreshPlanActivity = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.planBlocks.get.request({
        queryParams: {
          planID: Number(planId),
        },
      }),
    );
  };

  const handleOnSearch = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.planActivitiesByPlan.get.request({
        queryParams: {
          planID: Number(planId),
        },
      }),
    );
  };

  const handleOnSelectActivity = (activity: any) => {
    setSelectBlockActivity([activity.id]);
    setEditActivity({ ...editActivity, ...activity });
    dispatchAPI(
      VineaNovaActions.api.v1.activityDefaultRates.get.request({
        queryParams: { ActivityID: activity.id },
      }),
    );
  };

  const handleOnCreateActivity = () => {
    const current = activityDefaultRateData.find(f => {
      const frmDate = parse(f.effectiveFromDate, dateFormat, new Date());
      const dtToday = parse(today, dateFormat, new Date());
      const toDate = isValid(parse(f.effectiveToDate, dateFormat, new Date()))
        ? parse(f.effectiveToDate, dateFormat, new Date())
        : dtToday;
      const isInBetween = isWithinInterval(dtToday, {
        start: frmDate,
        end: toDate,
      });
      return isInBetween;
      ///f.effectiveFromDate <= today && today <= f.effectiveToDate,
    });

    let validationErrors = syncValidator(AddActivitySchema)(editActivity);
    console.log('validation errors', validationErrors);

    if (isEmpty(validationErrors)) {
      const fromDate = isValid(validDateFormat(editActivity.targetStartDate))
        ? validDateFormat(editActivity.targetStartDate)
        : null;
      const toDate = isValid(validDateFormat(editActivity.targetEndDate))
        ? validDateFormat(editActivity.targetEndDate)
        : null;
      const isBeforeStartDate =
        fromDate && toDate && isBefore(toDate, fromDate);

      if (isBeforeStartDate) {
        validationErrors = { targetEndDate: 'End date is before start date' };
      } else {
        const planStartDateParse = parse(
          identityOverviewDetails.planStartDate,
          viewDateFormat,
          new Date(),
        );
        const planEndDateParse = parse(
          identityOverviewDetails.planEndDate,
          viewDateFormat,
          new Date(),
        );
        const activityStartDateParse = parse(
          editActivity.targetStartDate,
          dateFormat,
          new Date(),
        );
        const activityEndDateParse = parse(
          editActivity.targetEndDate,
          dateFormat,
          new Date(),
        );

        const isBeforeStart = isBefore(
          activityStartDateParse,
          planStartDateParse,
        );
        const isBeforeEnd = isBefore(planEndDateParse, activityEndDateParse);

        if (isBeforeStart) {
          validationErrors = {
            targetStartDate: 'Please select a date within the plan range',
          };
        }

        if (isBeforeEnd) {
          validationErrors = {
            targetEndDate: 'Please select a date within the plan range',
          };
        }
      }
    }

    if (isEmpty(validationErrors)) {
      dispatchAPI(
        VineaNovaActions.api.v1.planActivities.post.request({
          postBody: {
            planID: Number(planId),
            activityID: editActivity.id,
            targetStartDate: new Date(editActivity.targetStartDate),
            targetEndDate: new Date(editActivity.targetEndDate),
            planActivityDescription: editActivity.planActivityDescription,
            sortSequence: Number(editActivity.sortSequence),
            colourHexCode: editActivity.colourHexCode,
            planActivityNote: editActivity.planActivityDescription,
            locked: editActivity.locked,
            resourceUnits: Number(editActivity.resourceUnits),
            velocity: Number(editActivity.velocity),
            unitCost: Number(editActivity.unitCost),
            totalWorkUnits: Number(editActivity.totalWorkUnits),
            workIntensity: Number(editActivity.workIntensity),
            estimatedCost:
              !isEmpty(editActivity.unitCost) &&
              !isEmpty(editActivity.totalWorkUnits) &&
              editActivity.unitCost !== 0 &&
              editActivity.totalWorkUnits !== 0
                ? round(
                    (Number(editActivity.unitCost) *
                      Number(editActivity.totalWorkUnits)) /
                      100,
                  )
                : 0,
            estimatedDuration:
              !isEmpty(editActivity.totalWorkUnits) &&
              !isEmpty(editActivity.workIntensity) &&
              !isEmpty(editActivity.velocity) &&
              editActivity.totalWorkUnits !== 0 &&
              editActivity.workIntensity !== 0 &&
              editActivity.velocity !== 0
                ? round(
                    Number(editActivity.totalWorkUnits) /
                      (Number(editActivity.velocity) *
                        Number(editActivity.workIntensity) *
                        Number(editActivity.resourceUnits)),
                    1,
                  )
                : 0,
            planBlockIDs: selectedBlocks.join(','),
          },
        }),
      );

      setApiTrigger(true);
    } else {
      setValidationErrors(validationErrors);
    }
  };

  const handleOnUpdateActivity = () => {
    let validationErrors = syncValidator(EditActivitySchema)(editActivity);
    console.log('validation errors', validationErrors);

    if (isEmpty(validationErrors)) {
      const fromDate = isValid(validDateFormat(editActivity.targetStartDate))
        ? validDateFormat(editActivity.targetStartDate)
        : null;
      const toDate = isValid(validDateFormat(editActivity.targetEndDate))
        ? validDateFormat(editActivity.targetEndDate)
        : null;
      const isBeforeStartDate =
        fromDate && toDate && isBefore(toDate, fromDate);

      if (isBeforeStartDate) {
        validationErrors = { targetEndDate: 'End date is before start date' };
      } else {
        const planStartDateParse = parse(
          identityOverviewDetails.planStartDate,
          viewDateFormat,
          new Date(),
        );
        const planEndDateParse = parse(
          identityOverviewDetails.planEndDate,
          viewDateFormat,
          new Date(),
        );
        const activityStartDateParse = parse(
          editActivity.targetStartDate,
          dateFormat,
          new Date(),
        );
        const activityEndDateParse = parse(
          editActivity.targetEndDate,
          dateFormat,
          new Date(),
        );

        const isBeforeStart = isBefore(
          activityStartDateParse,
          planStartDateParse,
        );
        const isBeforeEnd = isBefore(planEndDateParse, activityEndDateParse);

        if (isBeforeStart) {
          validationErrors = {
            targetStartDate: 'Please select a date within the plan range',
          };
        }

        if (isBeforeEnd) {
          validationErrors = {
            targetEndDate: 'Please select a date within the plan range',
          };
        }
      }
    }

    if (isEmpty(validationErrors)) {
      setErrorInSubmit(false);
      dispatchAPI(
        VineaNovaActions.api.v1.planActivities.put.request({
          postBody: {
            ...editActivity,
            targetStartDate: new Date(editActivity.targetStartDate),
            targetEndDate: new Date(editActivity.targetEndDate),
            planActivityDescription: editActivity.planActivityDescription,
            sortSequence: Number(editActivity.sortSequence),
            colourHexCode: editActivity.colourHexCode,
            planActivityNote: editActivity.planActivityDescription,
            locked: editActivity.locked,
            resourceUnits: Number(editActivity.resourceUnits),
            velocity: Number(editActivity.velocity),
            unitCost: Number(editActivity.unitCost),
            totalWorkUnits: Number(editActivity.totalWorkUnits),
            workIntensity: Number(editActivity.workIntensity),
            estimatedCost:
              !isEmpty(editActivity.unitCost) &&
              !isEmpty(editActivity.totalWorkUnits) &&
              editActivity.unitCost !== 0 &&
              editActivity.totalWorkUnits !== 0
                ? round(
                    (Number(editActivity.unitCost) *
                      Number(editActivity.totalWorkUnits)) /
                      100,
                  )
                : 0,
            estimatedDuration:
              !isEmpty(editActivity.totalWorkUnits) &&
              !isEmpty(editActivity.workIntensity) &&
              !isEmpty(editActivity.velocity) &&
              editActivity.totalWorkUnits !== 0 &&
              editActivity.workIntensity !== 0 &&
              editActivity.velocity !== 0
                ? round(
                    Number(editActivity.totalWorkUnits) /
                      (Number(editActivity.velocity) *
                        Number(editActivity.workIntensity) *
                        Number(editActivity.resourceUnits)),
                    1,
                  )
                : 0,
          },
        }),
      );

      dispatchAPI(
        VineaNovaActions.api.v1.updatePlanActivityBlocks.put.request({
          postBody: {
            planActivityID: editActivity.id,
            planBlockIDs: selectedBlocks,
          },
        }),
      );

      setApiTrigger(true);
      setValidationErrors({
        id: false,
        activityDescription: false,
        planActivityDescription: false,
        targetStartDate: false,
        targetEndDate: false,
        resourceUnits: false,
        unitCost: false,
        workIntensity: false,
        totalWorkUnits: false,
        velocity: false,
        estimatedCost: false,
        estimatedDuration: false,
        sortSequence: false,
        colourHexCode: false,
      });
    } else {
      setValidationErrors(validationErrors);
    }
  };

  const handleOnBlockSelectionChange = (selectedBlocks: number[]) => {
    setSelectedBlocks(selectedBlocks);
    setEditActivity({
      ...editActivity,
      planBlockIDs: selectedBlocks || null,
    });
  };

  const handleOnRemoveActivity = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.planActivities.delete.request({
        postBody: {
          id: editActivity.id,
          ts: editActivity.ts,
        },
      }),
    );
    setApiTrigger(true);
  };

  useEffect(() => {
    const { isLoading, error } = planAcitiviesMeta as any;
    if (apiTrigger) {
      if (!isLoading && error) {
        setErrorInSubmit(true);
        // @ts-ignore
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      } else {
        // @ts-ignore
        enqueueSnackbar(t('Success'), { variant: 'Success' });
        handleOnSearch();
        // go back
        navigate(-1);
      }
      setApiTrigger(false);
    }
    // setSeqToSave(null);
  }, [
    apiTrigger,
    enqueueSnackbar,
    t,
    navigate,
    dispatchAPI,
    planAcitiviesMeta,
  ]);

  // Grid data

  const gridDataColums = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Blocks',
      minwidth: '150px',
      flex: 0.3,
      resizable: false,
      hideable: false,
    },
  ];

  const gridXData = { columns: gridDataColums, rows: blocksData };
  logger.debug(selectedBlocks);
  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Box role="presentation">
        <Box
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          display="flex"
        >
          <Box>
            <Typography sx={{ padding: 2 }} variant="body1">
              {isNewActivity ? t('Add Plan Activity') : t('Edit Plan Activity')}
            </Typography>
          </Box>
        </Box>
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <Divider />

          <Grid container spacing={2}>
            <Grid item xs={3} sx={{ my: 2 }} data-testid="editactivityformbox">
              {isNewActivity && (
                <Box display="flex" style={{ height: 300 }}>
                  <StyledFormControl
                    data-testid="Addactivities-formcontrol"
                    error={validationErrors.id}
                  >
                    <FilterGridBlockActivities
                      handleOnRowClick={handleOnSelectActivity}
                      activityData={plannableActivities}
                      selectionModel={selectBlockActivity}
                    />
                    {validationErrors.id && (
                      <FormHelperText id="component-error-text">
                        {validationErrors.id}
                      </FormHelperText>
                    )}
                  </StyledFormControl>
                </Box>
              )}
              {!isNewActivity && (
                <StyledFormControl data-testid="activitystyledform">
                  <VineaTextField
                    data-testid="activitystyledfieldlabelinput"
                    autoComplete="off"
                    id="activity"
                    name="activityDescription"
                    label={t('Activity')}
                    disabled={!isNewActivity}
                    value={editActivity?.activityDescription || ''}
                    onChange={handleOnChange}
                    error={validationErrors.activityDescription}
                    fullWidth
                  />
                </StyledFormControl>
              )}
              <StyledFormControl sx={{ mt: 1 }}>
                <VineaTextField
                  autoComplete="off"
                  id="planActivityDescription"
                  name="planActivityDescription"
                  label={t('Plan Activity Description')}
                  disabled={!isNewActivity || editActivity?.locked}
                  value={editActivity?.planActivityDescription || ''}
                  onChange={handleOnChange}
                  error={validationErrors.planActivityDescription}
                  fullWidth
                  multiline
                  rows={4}
                />
              </StyledFormControl>
              <StyledFormControl
                data-testid="datepicker-formcontrol"
                error={validationErrors.targetStartDate}
              >
                <Datepicker
                  disabled={editActivity?.locked}
                  id="targetStartDate"
                  label={t('Start Date')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  name="targetStartDate"
                  value={editActivity?.targetStartDate || null}
                  onChange={handleOnChange}
                  inputProps={{
                    'data-id': 'targetStartDate',
                    'data-name': 'targetStartDate',
                  }}
                  fullWidth
                />
                {validationErrors.targetStartDate && (
                  <FormHelperText
                    id="component-error-text"
                    disabled={editActivity?.locked}
                  >
                    {validationErrors.targetStartDate}
                  </FormHelperText>
                )}
              </StyledFormControl>
              <StyledFormControl
                data-testid="datepicker-formcontrol"
                error={validationErrors.targetEndDate}
              >
                <Datepicker
                  disabled={editActivity?.locked}
                  id="targetEndDate"
                  label={t('End Date')}
                  placeholder="DD/MM/YYYY"
                  inputFormat={viewDateFormat}
                  formatDate={formatDate}
                  variant="outlined"
                  name="targetEndDate"
                  value={editActivity?.targetEndDate || null}
                  onChange={handleOnChange}
                  inputProps={{
                    'data-id': 'targetEndDate',
                    'data-name': 'targetEndDate',
                  }}
                  fullWidth
                />
                {validationErrors.targetEndDate && (
                  <FormHelperText
                    id="component-error-text"
                    disabled={editActivity?.locked}
                  >
                    {validationErrors.targetEndDate}
                  </FormHelperText>
                )}
              </StyledFormControl>
            </Grid>
            <Grid item xs={3} sx={{ my: 2 }} data-testid="editactivityformbox">
              <Box sx={{ mt: 2, height: 455 }}>
                <CheckboxSelectionGrid
                  gridXData={gridXData}
                  handleOnSelectionModelChange={handleOnBlockSelectionChange}
                  selectionModel={selectedBlocks}
                />
              </Box>
            </Grid>
            <Grid item xs={3} sx={{ my: 2 }} data-testid="editactivityformbox">
              <StyledFormControl>
                <VineaTextField
                  autoComplete="off"
                  id="resourceUnits"
                  name="resourceUnits"
                  label={t('Number of Resource Units')}
                  disabled={editActivity?.locked}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {resourceUnitValue}
                      </InputAdornment>
                    ),
                  }}
                  value={editActivity?.resourceUnits?.toString() || '0'}
                  onChange={handleOnChange}
                  error={validationErrors.resourceUnits}
                />
              </StyledFormControl>
              <StyledFormControl sx={{ mt: 1 }}>
                <VineaTextField
                  autoComplete="off"
                  id="unitCost"
                  key="Unit Cost"
                  name="unitCost"
                  label={t('Unit Cost')}
                  disabled={editActivity?.locked}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {costingUnitValue}/{workUnitValue}
                      </InputAdornment>
                    ),
                  }}
                  value={editActivity?.unitCost?.toString() || '0'}
                  onChange={handleOnChange}
                  error={validationErrors.unitCost}
                />
              </StyledFormControl>
              <StyledFormControl sx={{ mt: 1 }}>
                <VineaTextField
                  autoComplete="off"
                  id="workIntensity"
                  name="workIntensity"
                  label={t('Work Intensity')}
                  disabled={editActivity?.locked}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Hrs/Week</InputAdornment>
                    ),
                  }}
                  value={editActivity?.workIntensity?.toString() || '0'}
                  onChange={handleOnChange}
                  error={validationErrors.workIntensity}
                />
              </StyledFormControl>
              <StyledFormControl sx={{ mt: 1 }}>
                <VineaTextField
                  autoComplete="off"
                  id="totalWorkUnits"
                  name="totalWorkUnits"
                  label={t('Total Work Units')}
                  disabled={editActivity?.locked}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {workUnitPluralValue}
                      </InputAdornment>
                    ),
                  }}
                  value={editActivity?.totalWorkUnits?.toString() || '0'}
                  onChange={(
                    e: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >,
                  ) => {
                    handleOnChange(e);
                  }}
                  error={validationErrors.totalWorkUnits}
                />
              </StyledFormControl>

              <StyledFormControl sx={{ mt: 1 }}>
                <VineaTextField
                  autoComplete="off"
                  id="velocity"
                  name="velocity"
                  label={t('Velocity per Resource Unit')}
                  disabled={editActivity?.locked}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {workUnitPluralValue}/{durationUnitValue}
                      </InputAdornment>
                    ),
                  }}
                  value={editActivity?.velocity?.toString() || '0'}
                  onChange={handleOnChange}
                  error={validationErrors.velocity}
                />
              </StyledFormControl>
              <StyledFormControl sx={{ mt: 1 }}>
                <VineaTextField
                  autoComplete="off"
                  id="sortSequence"
                  name="sortSequence"
                  label={t('Sequence')}
                  type="number"
                  disabled={editActivity?.locked}
                  value={editActivity?.sortSequence || null}
                  onChange={handleOnChange}
                  error={validationErrors.sortSequence}
                />
              </StyledFormControl>
              <StyledFormControl
                sx={{ m: 1 }}
                data-testid="color-formcontrol"
                error={validationErrors.colourHexCode}
              >
                {!editActivity?.locked ? (
                  <>
                    <TextField
                      label="Color"
                      id="outlined-start-adornment"
                      sx={{ width: '25ch' }}
                      size="small"
                      value={editActivity?.colourHexCode || '#000'}
                      onChange={() => {}}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <ColorPickerBox
                              color={editActivity?.colourHexCode || '#000'}
                              onChange={handleOnEditColourChange}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {validationErrors.colourHexCode && (
                      <FormHelperText
                        id="component-error-text"
                        disabled={editActivity?.locked}
                      >
                        {`color is required`}
                      </FormHelperText>
                    )}
                  </>
                ) : (
                  <Typography sx={{ padding: 2 }} variant="body1">
                    {t('To edit colour, unlock the plan activity')}
                  </Typography>
                )}
              </StyledFormControl>
            </Grid>
            {lockToggleTriggered && editActivity?.locked && (
              <Box>
                <Box>
                  <Typography sx={{ padding: 2 }} variant="body1">
                    {t(
                      'By unlocking this activity, you are enabling changes to be made. Do you want to do this',
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flex: 1,
                    margin: 1.5,
                  }}
                >
                  <VineaButton
                    color="success"
                    onClick={handleOnConfirmUnlock}
                    variant="contained"
                    minWidth={150}
                    size="small"
                  >
                    {t('Yes')}
                  </VineaButton>
                  <Box mx={1} />
                  <VineaButton
                    color="secondary"
                    onClick={() => setLockToggleTriggered(false)}
                    variant="contained"
                    minWidth={150}
                    size="small"
                  >
                    {t('Cancel')}
                  </VineaButton>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ my: 2 }}
            data-testid="editactivityformbox"
          ></Grid>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flex: 1,
              gap: 2,
              mt: 1.5,
            }}
          >
            <VineaButton
              color="success"
              onClick={
                isNewActivity ? handleOnCreateActivity : handleOnUpdateActivity
              }
              variant="contained"
              minWidth={150}
            >
              {t('Save')}
            </VineaButton>
            <VineaButton
              color="secondary"
              onClick={() => navigate(-1)}
              variant="outlined"
              minWidth={150}
            >
              {t('Cancel')}
            </VineaButton>
            <VineaButton
              color="error"
              onClick={handleOnRemoveActivity}
              variant="contained"
              minWidth={150}
            >
              {t('Remove Activity')}
            </VineaButton>

            <FormControlLabel
              sx={{ padding: 0, m: 0 }}
              control={
                <GreenSwitch
                  checked={editActivity?.locked}
                  onChange={handleOnClickLocked}
                  name="locked"
                />
              }
              label={<Typography variant="caption">{t('Locked')}</Typography>}
            />

            {!editActivity?.locked && (
              <IconButton onClick={handleOnRefreshPlanActivity}>
                <SyncIcon />
              </IconButton>
            )}
          </Box>
        </ErrorBoundary>
        <DiscardDialog
          open={discardDialogOpen}
          onClose={() => setDiscardDialogOpen(false)}
          handleSaveChanges={handleOnDiscardSaveChanges}
          handleDiscardChanges={handleOnDiscardConfirm}
        />
      </Box>
    </Paper>
  );
};

export { ActivityEdit };
