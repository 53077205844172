/* eslint-disable no-undef */
/* eslint-disable import/extensions */
/* eslint-disable no-alert */
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/vinealogo.png';


const StyledLogo = styled('img')(({ theme }) => ({
  width: '30px',
  height: '30px',
  marginRight: theme.spacing(3),
  marginTop: '4px',
}));

type LandingHeaderProps = {
    login: () => void;
};

const LandingHeader = ({ login }: LandingHeaderProps) => {
  const theme = useTheme();
  const { t } = useTranslation('translation');


  // @ts-ignore
  const goToVinea = ev => {
    ev.preventDefault();
    // eslint-disable-next-line no-undef
    window.location.href = 'https://www.vinea.co.nz/';
  };

  return (
    <AppBar position="static" data-tesid="landingpage-appbar">
      <Toolbar
        sx={{
          height: '56px',
        }}
      >
        <Link to="/" data-tesid="landingpage-linktoroot">
          <StyledLogo src={logo} alt="logo" />
        </Link>
        <Typography
          variant="subtitle1"
          sx={{ flexGrow: 1, color: theme.palette.primary.contrastText }}
          data-tesid="landingpage-texttitle"
        >
          Vinea Nova
        </Typography>
        <Button color="inherit" onClick={login} data-testid="btn-login">
          {t('landingpage.login')}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          data-testid="btn-signup"
          onClick={goToVinea}
        >
          {t('landingpage.signup')}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default LandingHeader;
