/**
 * Reducer action types
 */

export const stepTypes = {
  RECEIVE: 'STEP_RECEIVE',
  UPDATE: 'STEP_UPDATE',
  ERROR: 'STEP_ERROR',
};

/**
 * Initial state for Add / Edit Vintage Screen;
 */
export const classificationState = {
  classificationID: 0,
  classificationGroupID: 0,
  effectiveFromDate: null,
  effectiveToDate: null,
  errors: {},
};

export const organisationState = {
  name: null,
  knownAs: null,
  errors: {},
};

export const initialState = {
  isComplete: false,
  isSaving: false,
  stepsHasError: false,
  data: [],
  errors: {},
};

export const stepsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case stepTypes.UPDATE:
      return {
        ...state,
        ...payload,
      };
    case stepTypes.ERROR:
      return {
        ...state,
        errors: payload.errors,
      };
    default:
      return state;
  }
};
