/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { VineaButton } from '../VineaButton';

type SplitButtonProps = {
  color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'info';
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
    selectedIndex: number,
  ) => void;
  disabled: boolean;
  variant: 'text' | 'outlined' | 'contained';
  minWidth: string | number;
  className?: string | Function | any;
  isExtraSmall: boolean;
  children?: any | React.ReactNode | string | Function;
  options?: string[];
};

function SplitButton({
  color,
  onClick,
  disabled,
  variant,
  options = ['SAVE & CLOSE', 'SAVE'],
  ...props
}: SplitButtonProps) {
  const [open, setOpen] = React.useState(false);
  const [clickControl, setClickControl] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    onClick(e, selectedIndex);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    setClickControl(true);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    // @ts-ignore next-line
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    if (clickControl) {
      // @ts-ignore next-line
      handleClick();
      setClickControl(false);
    }
  }, [clickControl, handleClick]);

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        disabled={disabled}
      >
        <VineaButton
          //@ts-ignore
          color={color}
          variant={variant}
          {...props}
          onClick={handleClick}
        >
          {t(options[selectedIndex])}
        </VineaButton>
        <VineaButton
          size="small"
          //@ts-ignore
          color={color}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          variant={variant}
        >
          <ArrowDropDownIcon />
        </VineaButton>
      </ButtonGroup>
      {/* @ts-ignore */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {t(option)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default SplitButton;
