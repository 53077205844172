import { createSelector } from 'reselect';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { uniq } from 'lodash';

import { formatAsDateFormat } from '../../constants/formatter';

const getSites = VineaNovaSelectors.getSitesEntityData;
const getSensors = VineaNovaSelectors.getSensorsEntityData;
const getSensorBlocks = VineaNovaSelectors.getSensorBlocksEntityData;
const getSensorType = VineaNovaSelectors.getlookupSensorTypeEntityData;
const getSensorStatus = VineaNovaSelectors.getlookupSensorStatusEntityData;
const getSensorTypeMeasureUnit =
  VineaNovaSelectors.getlookupSensorTypeMeasureUnitEntityData;

// Mimic naming convention of sensor statuses for sites
const getSiteStatusString = (isActive, isNew) => {
  if (isNew) return 'New/Unconfigured';
  if (isActive) return 'Active';
  return 'Ignore/Not Required';
};

export const getSiteGridSelector = createSelector([getSites], sitesData => {
  return sitesData
    .map(e => ({
      ...e,
      friendlySiteName: e.friendlySiteName || e.siteName,
      id: e.siteID,
      isActive: e.active,
      status: getSiteStatusString(e.active, e.isNew),
      vineyard: e.vineyardName,
      createdDate: e.createdDate,
      lastUpdatedDate: e.lastUpdatedDate,
    }))
    .sort((a, b) => a.siteName.localeCompare(b.siteName))
    .sort((a, b) => Number(b.active) - Number(a.active))
    .sort((a, b) => Number(b.isNew) - Number(a.isNew));
});

export const getSensorGridSelector = createSelector(
  [
    getSensors,
    getSensorType,
    getSensorStatus,
    getSensorTypeMeasureUnit,
    getSensorBlocks,
  ],
  (
    sensorsData,
    lkpSensorType,
    lkpSensorStatus,
    lkpSensorMeasureUnit,
    sensorBlocks,
  ) => {
    return sensorsData
      .map(e => ({
        ...e,
        id: e.sensorID,
        friendlySensorName: e.friendlySensorName || e.sensorName,
        sensorType:
          lkpSensorType?.find(lkp => lkp.id === e.sensorTypeID)?.sensorType ||
          '',
        sensorStatus:
          lkpSensorStatus?.find(lkp => lkp.id === e.sensorStatusID)
            ?.sensorStatus || '',
        sensorMeasureUnit:
          lkpSensorMeasureUnit?.find(lkp => lkp.id === e.unitOfMeasureID)
            ?.sensorTypeMeasureUnit || '',
        startDate: formatAsDateFormat(e.collectionStartDate),
        lastUpdatedDate: formatAsDateFormat(e.lastUpdatedDate),
        blocks: e.coverageDescription,
        blockIDs: sensorBlocks
          .filter(block => block.isCovered)
          .map(block => block.blockID),
      }))
      .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
      .sort((a, b) => {
        // Sort order: New, Active, Ignore/Not Required, Paused, Error Status
        if (a.sensorStatusID === 3) return -1;
        if (b.sensorStatusID === 3) return 1;
        return a.sensorStatusID - b.sensorStatusID;
      });
  },
);

export const getSensorBlocksGrid = createSelector(
  [getSensorBlocks],
  sensorBlocks => {
    return sensorBlocks.map(e => ({
      blockVintageID: e.blockID,
      ...e,
    }));
  },
);

// return vineyardname as groups
export const getSensorBlockGroups = createSelector(
  [getSensorBlocks],
  sensorBlocks => uniq(sensorBlocks.map(e => e.vineyardName)),
);
