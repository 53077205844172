/* eslint-disable import/no-cycle */
import React from 'react';
import logger from '../../utils/winstonLogger';
import { ManageBlocksDetails } from './ManageBlocks';

const BlockDetailsContainer = () => {
  logger.debug('BlockDetailsContainer');

  return (
    <>
      <ManageBlocksDetails />
    </>
  );
};

BlockDetailsContainer.propTypes = {};

export { BlockDetailsContainer };
