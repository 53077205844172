/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import GridWithFilter from '../Grid/GridWithFilter';
import { RenderDataGridHeader } from '../Grid';

export function DataGridChart({
  options = { columns: [] },
  series = { rows: [] },
  chartTitle,
}) {
  const {
    title: { style: titleStyle = {} } = {},
    subtitle: { text: subtitle, style: subtitleStyle = {} } = {},
  } = options;

  const gridXData = {
    columns: options.columns.map(col => ({
      ...col,
      renderData: RenderDataGridHeader,
    })),
    // MUI grids want an id column. Add it if it doesn't exist
    // Also make every row un-hideable
    rows: series.rows.map((row, id) => ({ id, hideable: false, ...row })),
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box mx={2} mt={1}>
        <Box my={1} sx={{ ...titleStyle }}>
          {chartTitle}
        </Box>
        {subtitle && (
          <Box my={1} sx={{ ...subtitleStyle }}>
            {subtitle}
          </Box>
        )}
      </Box>
      <GridWithFilter
        disableColumnPinning
        disableColumnFilter
        disableColumnSelector
        autoPageSize
        data={gridXData}
        rowHeight={30}
        advancedSearch={false}
      />
    </Box>
  );
}

DataGridChart.propTypes = {
  options: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        // A MUI datagrid column definition
        field: PropTypes.string.isRequired,
        headerName: PropTypes.string.isRequired,
      }),
    ),
    title: PropTypes.shape({
      text: PropTypes.string,
      style: PropTypes.object, // A JSS object
    }).isRequired,
    subtitle: PropTypes.shape({
      text: PropTypes.string,
      style: PropTypes.object, // A JSS object
    }).isRequired,
  }).isRequired,
  series: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  chartTitle: PropTypes.number.isRequired,
};
