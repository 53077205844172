import React, { useState, useCallback, useEffect } from 'react';
import { omit, isEmpty } from 'lodash';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { format } from 'date-fns';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import {
  reducers,
  sagaActionTypes,
  vineaDetails,
  actionTypes,
  apiTypes,
  IdentityTypeIds,
  dateFormat,
} from '../../../constants';
import {
  getIdentityNotesSelector,
  getIdentityNotesInfo,
  // getLkpKeyword,
} from '../../../redux/selectors';
import { NoteSchema } from '../validation';
import { syncValidator } from '../../../utils/validator';
import useDeepEffect from '../../../hooks/useDeepEffect';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

export const useIdentityNotesHook = () => {
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  // dispatches;
  const dispatchAPI = useDispatch();
  const { id: pathParamId, noteId } = useParams();
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  const identityTypeID = useIdentityTypeId(vineyardTypeScreenName);

  /** selectors ** */
  const {
    isLoading: formWriteLoading,
    hasError: formWriteError,
    isLoaded: formWriteLoaded,
    data: formWriteData,
  } = useSelector(state => state.formWrite);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [allNotesData, setAllNotesData] = useState([]);
  const [isNewNote, setIsNewNote] = useState(false);
  const [isStatusReviewer, setIsStatusReviewer] = useState(false);
  const [showReviewer, setShowReviewer] = useState(false);
  const [noteSelected, setNoteSelected] = useState({});
  const [saveIndex, setSaveIndex] = useState(0);
  const [saveButtonStatus, setSaveButtonStatus] = React.useState(false);

  const notesData = useSelector(getIdentityNotesSelector);

  const {
    isLoading: blocksIsLoading,
    isLoaded: blocksLoaded,
    hasError: blocksHasError,
  } = useSelector(getIdentityNotesInfo);

  const { data: { documentsToSave = [] } = {} } =
    useSelector(state => state.newNoteDocuments) || {};

  // useEffects
  useDeepEffect(() => {
    if (!blocksIsLoading && blocksLoaded && !blocksHasError) {
      setAllNotesData(notesData);
    } else setAllNotesData([]);
  }, [blocksIsLoading, blocksLoaded, blocksHasError, notesData]);

  useEffect(() => {
    let getSelectedNoteById = {};
    if (noteId === 'create') {
      getSelectedNoteById = {
        noteStatusID: 1,
        reviewer: '',
        reviewDate: '',
        subjectDate: format(new Date(), dateFormat),
      };
      setIsNewNote(true);
      setShowReviewer(false);
    } else if (
      noteId &&
      Array.isArray(allNotesData) &&
      allNotesData.length > 0
    ) {
      getSelectedNoteById = allNotesData.find(
        f => f.id === parseInt(noteId, 10),
      );
      if (
        (getSelectedNoteById.reviewer !== null &&
          getSelectedNoteById.reviewer !== '') ||
        (getSelectedNoteById.reviewDate !== null &&
          getSelectedNoteById.reviewDate !== '')
      ) {
        setShowReviewer(true);
      } else {
        setShowReviewer(false);
      }

      if (getSelectedNoteById.noteStatusID === 2) {
        setIsStatusReviewer(true);
        setShowReviewer(true);
      }
    }
    setNoteSelected(getSelectedNoteById);
  }, [noteId, allNotesData]);

  React.useEffect(() => {
    if (!blocksIsLoading && blocksLoaded && !blocksHasError) {
      const orginalStoreObj = allNotesData.find(f => f.id === noteSelected.id);
      if (compareObjects(noteSelected, orginalStoreObj, ['errors'])) {
        setSaveButtonStatus(true);
      } else setSaveButtonStatus(false);
    }
  });

  // for adding new rows
  useEffect(() => {
    if (actionTriggered) {
      if (!formWriteLoading && formWriteLoaded) {
        if (formWriteError) {
          // set form submitting has errors;
          setErrorInSubmit(true);
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else {
          enqueueSnackbar(t('Success'), { variant: 'Success' });

          if (isNewNote || saveIndex === 0) {
            if (identityTypeID === IdentityTypeIds.VINEYARD)
              navigate(
                `/organisation/${vineyardTypeScreenName}s/${pathParamId}/notes`,
              );
            else if (identityTypeID === IdentityTypeIds.PERSON)
              navigate(`/person/${pathParamId}/notes`);
            else if (identityTypeID === IdentityTypeIds.ACCOMMODATION)
              navigate(
                `/accommodation/accommodationdetails/${pathParamId}/notes`,
              );
            else if (identityTypeID === IdentityTypeIds.CONSUMABLE)
              navigate(`/consumable/consumabledetails/${pathParamId}/notes`);
            else if (identityTypeID === IdentityTypeIds.SERVICE_PROVIDER)
              navigate(`/organisation/serviceproviders/${pathParamId}/notes`);
            else if (identityTypeID === IdentityTypeIds.JOB)
              navigate(`/job/${pathParamId}/notes`);
            else if (identityTypeID === IdentityTypeIds.DATA_CONNECTION)
              navigate(`/connections/${pathParamId}/notes`);
            else if (identityTypeID === IdentityTypeIds.ORGANISATION)
              navigate(
                `/organisation/other-organisations/${pathParamId}/notes`,
              );
          }

          dispatchAPI({
            type: actionTypes.updateData,
            name: reducers.newNoteDocuments,
            payload: { documentsToSave: [] },
          });

          const newNoteSelected = {
            ...noteSelected,
            ts: formWriteData.ts,
          };

          setNoteSelected(newNoteSelected);
          dispatchAPI({
            type: sagaActionTypes.FETCH_IDENTITY_NOTES,
            name: reducers.identityNotes,
            payload: {
              id: pathParamId,
              name: reducers.identityNotes,
            },
          });
          setActionTriggered(false);
        }
      }
    }
  }, [
    t,
    enqueueSnackbar,
    actionTriggered,
    formWriteLoading,
    formWriteLoaded,
    formWriteError,
    navigate,
    saveIndex,
    pathParamId,
    isNewNote,
    formWriteData,
    noteSelected,
    dispatchAPI,
    identityTypeID,
  ]);
  /** changes to the data */
  const handleOnChange = useCallback(
    e => {
      const {
        target: { value, name },
      } = e;
      const newValue = { ...noteSelected, [name]: value };
      if (name === 'noteStatusID' && value === 2) {
        setIsStatusReviewer(true);
        setShowReviewer(true);
        setNoteSelected(newValue);
      } else if (name === 'noteStatusID' && value !== 2) {
        setIsStatusReviewer(false);
        setNoteSelected(newValue);

        if (
          (noteSelected.reviewer !== null && noteSelected.reviewer !== '') ||
          (noteSelected.reviewDate !== null && noteSelected.reviewDate !== '')
        ) {
          setShowReviewer(true);
        } else {
          setShowReviewer(false);
        }
      } else {
        setNoteSelected(newValue);
      }
    },
    [noteSelected],
  );

  /** changes to the data */
  const handleOnChangeKeyWord = useCallback(
    multiSelectState => {
      const newValue = { ...noteSelected, keywordListID: multiSelectState };
      setNoteSelected(newValue);
    },
    [noteSelected],
  );

  const handleOnBlur = () => {
    // continue validations
    const validationErrors = syncValidator(NoteSchema)(noteSelected);

    const { errors } = noteSelected;

    if (!isEmpty(errors)) {
      const newValue = {
        ...noteSelected,
        errors: validationErrors,
      };
      setNoteSelected(newValue);
    }
  };

  // for adding new rows
  const onSave = (event, index = 0) => {
    // continue validations
    const validationErrors = syncValidator(NoteSchema)(noteSelected);
    if (!isEmpty(validationErrors)) {
      const newValue = {
        ...noteSelected,
        errors: validationErrors,
      };
      setNoteSelected(newValue);
    } else {
      const dataToSubmit = {
        ...omit(noteSelected, ['errors']),
        CreatedDate: format(new Date(), dateFormat),
        SourceObjectID: pathParamId,
        NoteLinkObjectID: 1, // identity Note link object ID
        PrimaryOwner: true,
        // KeywordListID: keywordList,
      };
      setSaveIndex(index);
      dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });

      const documentPayloads = documentsToSave.map(doc => ({
        file: doc.file,
        params: {
          DocumentTypeId: doc.typeId || 1,
          DocumentDate: format(new Date(doc.date), dateFormat),
          DocumentSubject: doc.subject,
          SourceFile: doc.filename,
          DocumentStatusId: doc.statusId,
        },
      }));

      dispatchAPI({
        type: sagaActionTypes.CREATE_NOTE_WITH_DOCUMENTS,
        payload: {
          notePayload: dataToSubmit,
          documentPayloads,
          name: vineaDetails.note,
          methodType: apiTypes.POST,
        },
      });

      setActionTriggered(true);
    }
  };

  const onUpdate = (event, index) => {
    setSaveIndex(index);
    // continue validations
    const validationErrors = syncValidator(NoteSchema)(noteSelected);
    const orginalStoreObj = allNotesData.find(f => f.id === noteSelected.id);

    if (!isEmpty(validationErrors)) {
      const newValue = { ...noteSelected, errors: validationErrors };
      setNoteSelected(newValue);
    } else if (compareObjects(noteSelected, orginalStoreObj, ['errors'])) {
      enqueueSnackbar(t('No data has been modified!'), { variant: 'Info' });
    } else {
      const dataToSubmit = {
        ...omit(noteSelected, ['errors']),
        noteStatusID:
          noteSelected.noteStatusID !== 0 ? noteSelected.noteStatusID : null,
        // keywordListID: keywordList,
      };
      dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data: dataToSubmit,
          name: vineaDetails.note,
          methodType: apiTypes.PUT,
        },
      });
      setActionTriggered(true);
    }
  };

  const onDelete = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.identityNote.delete.request({
        queryParams: {
          identityNoteID: Number(noteId),
        },
      }),
    );
  };

  const compareObjects = (obj1, obj2, fields) => {
    const result = isEqual(omit(obj1, [...fields]), omit(obj2, [...fields]));
    return result;
  };

  return {
    noteSelected,
    errorInSubmit,
    isNewNote,
    isStatusReviewer,
    showReviewer,
    saveButtonStatus,
    confirmDeleteIsOpen,
    setErrorInSubmit,
    setNoteSelected,
    handleOnChange,
    handleOnChangeKeyWord,
    handleOnBlur,
    onSave,
    onUpdate,
    onDelete,
    setConfirmDeleteIsOpen,
  };
};
