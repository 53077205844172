import { createSelector } from 'reselect';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import isEmpty from 'lodash/isEmpty';
import { map } from 'lodash';

const getAllSupplyContractsData = state => state.searchSupplyContracts;
const supplyContractBlocks =
  VineaNovaSelectors.getSupplyContractBlockEntityData;
const blocksGeometryData =
  VineaNovaSelectors.getSupplyContractBlocksGeometryEntityData;

export const getAllSupplyContracts = createSelector(
  [getAllSupplyContractsData],
  supplyContractsSearchData => {
    const { data = [] } = supplyContractsSearchData;
    return {
      ...supplyContractsSearchData,
      data: isEmpty(data) ? [] : data,
    };
  },
);

export const getSupplyContractBlockGeometries = createSelector(
  [blocksGeometryData],
  data => {
    if (!isEmpty(data)) {
      const geometries = map(data, block => {
        return {
          ...block,
          blockFullName: block.blockName,
          isActive: true,
        };
      });
      return geometries;
    }
    return null;
  },
);
