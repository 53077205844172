import * as Yup from 'yup';
import { isValid } from 'date-fns';

export const ClassificationSchema = Yup.object().shape({
  classificationID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
  classificationGroupID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .required('Required'),
  effectiveFromDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
});

export const RolesSchema = Yup.object().shape({
  roleTypeID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
  effectiveFromDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
});

export const RelationshipSchema = Yup.object().shape({
  relatesTo: Yup.string().nullable().required('Required'),
  ownerIdentityID: Yup.string().nullable().required('Required'),
  effectiveFromDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
});

export const OrganisationSchema = Yup.object().shape({
  organisationName: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  organisationKnownAs: Yup.string().max(50, 'Name is Too Long').nullable(),
  businessUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select group')
    .nullable()
    .required('Required'),
});

export const VineyardSchema = Yup.object().shape({
  vineyardName: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  vineyardKnownAs: Yup.string().max(50, 'Name is Too Long').nullable(),
  totalVineyardArea: Yup.number()
    .min(
      Yup.ref('plantedArea'),
      `Total Area must be greater than or equal to Planted Area`,
    )
    .nullable(),
  plantedArea: Yup.number()
    .max(
      Yup.ref('totalVineyardArea'),
      'Planted Area must be lesser than or equal to Total Area',
    )
    .nullable(),
  subRegionID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select group')
    .nullable()
    .required('Required'),
  businessUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select group')
    .nullable()
    .required('Required'),
});

export const SupplyContractSchema = Yup.object().shape({
  contractName: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  purchasingPartyIdentityID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Please select Purchasing Party')
    .required('Required'),
  supplyingPartyIdentityID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Please select Supplying Party')
    .required('Required'),
  supplyContractTypeID: Yup.number()
    .notOneOf(['Select', 0, '0'], 'Please select Supply Contract Type')
    .required('Required'),
});

export const AccommodationSchema = Yup.object().shape({
  accommodationName: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  capacity: Yup.string()
    .notOneOf([0, '0'], 'Required')
    .nullable()
    .required('Required'),
  businessUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
});

export const ConsumableSchema = Yup.object().shape({
  consumableName: Yup.string()
    .max(200)
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  consumableDescription: Yup.string().max(200).nullable(),
  consumableTypeID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select group')
    .nullable()
    .required('Required'),
  businessUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select group')
    .nullable()
    .required('Required'),
});

export const ActivitySchema = Yup.object().shape({
  activityCode: Yup.string()
    .max(50)
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .required('Required'),
  activityDescription: Yup.string().max(200).nullable(),
  activityIsPlannable: Yup.boolean().nullable(),
  resourceUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select Resource Unit Type')
    .nullable()
    .required('Required'),
  workUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select Work Unit Type')
    .nullable()
    .required('Required'),
  costingUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select Cost Unit')
    .nullable()
    .required('Required'),
  velocityDurationID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select Duration')
    .nullable()
    .required('Required'),
  activityGroupID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select Activity Group')
    .nullable()
    .required('Required'),
  businessUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select Business Unit')
    .nullable(),
});

export const VehicleSchema = Yup.object().shape({
  vehicleName: Yup.string()
    .max(200)
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .required('Required'),
  businessUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select a business unit')
    .nullable()
    .required('Required'),
  vehicleTypeID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select a vehicle type')
    .nullable()
    .required('Required'),
  vehicleMakeID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select a vehicle make')
    .nullable()
    .required('Required'),
  vehicleModel: Yup.string()
    .max(50)
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .required('Required'),
  registrationNo: Yup.string()
    .max(10)
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .required('Required'),
  vehicleCapacity: Yup.number()
    .notOneOf([0, '0'], 'Required')
    .required('Required'),
  dateOfPurchase: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v))),
  tonnageWeight: Yup.number().typeError('Must be a number').nullable(),
  tareWeight: Yup.number().typeError('Must be a number').nullable(),
});

export const ServiceProviderSchema = Yup.object().shape({
  serviceProviderName: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  payrollSystemID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
  businessUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Please select group')
    .nullable()
    .required('Required'),
});

export const PersonSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  lastName: Yup.string()
    .matches(/^\S+(\S| )*$/, 'Must contain non-whitespace')
    .nullable()
    .required('Required'),
  businessUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
});

export const ConnectionSchema = Yup.object().shape({
  connectionDescription: Yup.string().nullable().required('Required'),
  dataProviderID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
  providerTypeID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
  businessUnitID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
});

export const ContactAddressSchema = Yup.object().shape({
  addressLine1: Yup.string().nullable().required('Required'),
  countryID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
  townID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
});

export const ReferenceSchema = Yup.object().shape({
  referenceType: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .required('Required'),
  referenceValue: Yup.string().nullable().required('Required'),
});

export const ContactSchema = Yup.object().shape({
  contactMethodTypeId: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .required('Required'),
  contactMethodDetails: Yup.string().nullable().required('Required'),
});

export const EmailSchema = Yup.object().shape({
  contactMethodDetails: Yup.string().email('Please Enter a Valid Email Format'),
});

export const PhoneSchema = Yup.object().shape({
  contactMethodDetails: Yup.number()
    .integer('Phone Number cannot contain special characters')
    .typeError('Please Enter a Valid Phone Number'),
});

export const URLSchema = Yup.object().shape({
  contactMethodDetails: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Please Enter a Valid Website URL',
    )
    .required('Required'),
});

export const DeactivationSchema = Yup.object().shape({
  deactivateFromDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .required('Required'),
  deactivationReason: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
  deactivationComment: Yup.string().max(200).nullable(),
});

export const SensorSchema = Yup.object().shape({
  startDate: Yup.string()
    .nullable()
    .test('date', '', v => isValid(new Date(v)))
    .when('blockIDs', {
      is: blockIDs => blockIDs?.length > 0,
      then: Yup.string().nullable().required('Required'),
    }),
  sensorStatusID: Yup.string()
    .notOneOf(['Select', 0, '0'], 'Required')
    .nullable()
    .required('Required'),
});
